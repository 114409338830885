import React from "react";
import { Grid, TextField, Select, MenuItem, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ReactHookFormSelect } from "../../../common/react.hook.form.select";
type Props = {};

export const PropertyDetailsForm: React.FC<{ created?: boolean }> = ({
  created,
}) => {
  const { classes } = useStyles();
  const [subPropertyType, setSubPropertyType] = useState("");
  const [parkingType, setParkingType] = useState("");
  const [petPolicy, setPetPolicy] = useState("");
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext();
  let propertyCreateTypeId = watch("propertyCreateTypeId");

  useEffect(() => {
    if (propertyCreateTypeId === "sale") {
      setValue("utilities", "");
      setValue("petPolicyId", "allowed");
      setValue("petPolicyDescription", "");
    }
  }, [propertyCreateTypeId]);

  return (
    <>
      <Grid
        container
        direction={"column"}
        display="flex"
        gap={"15px"}
        sx={{ marginTop: "10px" }}
        width="85%"
      >
        <Grid item>
          <div className={classes.formHeader}>Property Details</div>
        </Grid>
        <Grid item>
          <span className={classes.inputHeader}>
            General Information* {`(200 character limit)`}
          </span>
          <TextField
            {...register("generalInformation")}
            className={errors.generalInformation ? classes.errorBorder : ""}
            inputProps={{
              maxLength: 200,
              style: {
                paddingLeft: "5px",
                fontSize: "18px",
                color: "#707070",
                opacity: "1 ",
              },
            }}
            sx={{
              marginTop: "7px",
              input: { "&::placeholder": { opacity: 1 } },
            }}
            placeholder="Ex. Large wrap around front porch. Interior wood fireplace..."
            fullWidth
            multiline
            rows={4}
            variant="standard"
            classes={{ root: classes.filledInput }}
            InputProps={{ disableUnderline: false }}
            size="small"
          />
          {errors.generalInformation && (
            <p className={classes.errorText}>
              {String(errors.generalInformation.message)}
            </p>
          )}
        </Grid>
        {propertyCreateTypeId === "rental" ? (
          <>
            <Grid item>
              <span className={classes.inputHeader}>
                Utilities* {`(200 character limit)`}
              </span>
              <TextField
                {...register("utilities")}
                className={errors.utilities ? classes.errorBorder : ""}
                sx={{
                  marginTop: "7px",
                  input: { "&::placeholder": { opacity: 1 } },
                }}
                inputProps={{
                  maxLength: 200,
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                placeholder="Ex. Large wrap around front porch. Interior wood fireplace..."
                fullWidth
                multiline
                rows={4}
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
              {errors.utilities && (
                <p className={classes.errorText}>
                  {String(errors.utilities.message)}
                </p>
              )}
            </Grid>
            <Grid item>
              <div className={classes.inputHeader}>Pet Policy*</div>
              <ReactHookFormSelect
                control={control}
                name="petPolicyId"
                sx={{ width: "30%" }}
              >
                <MenuItem value="allowed">Allowed</MenuItem>
                <MenuItem value="not-allowed">Not-Allowed</MenuItem>
              </ReactHookFormSelect>
              {/* <Select variant='standard' value={petPolicy} onChange={(e: any) => { setPetPolicy(e.target.value) }} disableUnderline sx={{ width: '30%' }}>
            <MenuItem value="allowed">Allowed</MenuItem>
            <MenuItem value="not-allowed">Not-Allowe</MenuItem>
          </Select> */}
            </Grid>
            <Grid item>
              <span className={classes.inputHeader}>
                Pet Details* {`(200 character limit)`}
              </span>
              <TextField
                {...register("petPolicyDescription")}
                className={
                  errors.petPolicyDescription ? classes.errorBorder : ""
                }
                inputProps={{
                  maxLength: 200,
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                sx={{
                  marginTop: "7px",
                  input: { "&::placeholder": { opacity: 1 } },
                }}
                placeholder="Ex. $200 deposit for pets…"
                fullWidth
                multiline
                rows={4}
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
              {errors.petPolicyDescription && (
                <p className={classes.errorText}>
                  {String(errors.petPolicyDescription.message)}
                </p>
              )}
            </Grid>
          </>
        ) : null}
        <Grid item>
          <span className={classes.inputHeader}>
            Info Link* {`(MLS, Zillow, ETC....)`}
          </span>
          <TextField
            {...register("zlink")}
            className={errors.zlink ? classes.errorBorder : ""}
            sx={{
              marginTop: "7px",
              input: { "&::placeholder": { opacity: 1 } },
            }}
            inputProps={{
              style: {
                paddingLeft: "5px",
                fontSize: "18px",
                color: "#707070",
                opacity: "1 ",
              },
            }}
            placeholder="https://www"
            fullWidth
            variant="standard"
            classes={{ root: classes.filledInput }}
            InputProps={{ disableUnderline: false }}
            size="small"
          />
          {errors.zlink && (
            <p className={classes.errorText}>{String(errors.zlink.message)}</p>
          )}
        </Grid>
        <Grid item sx={{ display: created ? "block" : "none" }}>
          <span className={classes.inputHeader}>Instashow Id*</span>
          <TextField
            sx={{
              marginTop: "7px",
              input: { "&::placeholder": { opacity: 1 } },
            }}
            inputProps={{
              style: {
                paddingLeft: "5px",
                fontSize: "18px",
                color: "#707070",
                opacity: "1 ",
              },
              readOnly: true,
            }}
            placeholder="123456789"
            fullWidth
            variant="standard"
            classes={{ root: classes.filledInput }}
            InputProps={{ disableUnderline: false }}
            size="small"
          />
        </Grid>
        <Grid item sx={{ display: created ? "flex" : "none" }}>
          <Grid container direction="row" gap={"15px"}>
            <Grid item flex={1}>
              <span className={classes.inputHeader}>Created By:</span>
              <TextField
                sx={{
                  marginTop: "7px",
                  input: { "&::placeholder": { opacity: 1 } },
                }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                  readOnly: true,
                }}
                placeholder="123456789"
                fullWidth
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <span className={classes.inputHeader}>Listed By:</span>
              <TextField
                sx={{
                  marginTop: "7px",
                  input: { "&::placeholder": { opacity: 1 } },
                }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                  readOnly: true,
                }}
                placeholder="123456789"
                fullWidth
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <span className={classes.inputHeader}>License Number:</span>
              <TextField
                sx={{
                  marginTop: "7px",
                  input: { "&::placeholder": { opacity: 1 } },
                }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                  readOnly: true,
                }}
                placeholder="123456789"
                fullWidth
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Listing-Details-Form" })((theme) => ({
  mainContainer: {},
  formHeader: {
    fontSize: "24px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
  },
  filledInput: {
    background: "#EAEAEA",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  errorBorder: {
    border: "2px solid red",
  },
}));
