import React from "react";
import {
   BrowserRouter,
   Routes,
   Route,
   Outlet,
   Navigate,
} from "react-router-dom";
import "./App.css";
import NotFound from "./NotFound";
import { GradientProvider } from "./contexts/gradients/gradients.context";
import { ThemeProvider } from "@mui/material/styles";
import { NavProvider } from "./contexts/nav/nav.context";
import { ApiProvider } from "./contexts/api/api.context";
import { PublicRoutePaths, ProtectedRoutePaths } from "./routes/routes";
import { mainTheme } from "./styles/mainTheme";
// import Register from "./pages/register/register";
import { MainNav } from "./components/nav/main/main.nav";
import { PageContainer } from "./layouts/genric.layouts";
// import { SiteHeaderPublic } from "./components/common/site.header.public";
import { LoginMain } from "./pages/auth/login/login.main";
import { ListingsViewingMain } from "./pages/listings/viewing/non-enterprise/listings.viewing.main";
import { ForgotPasswordMain } from "./pages/auth/login/forgotPassword.main";
import { UserProvider, useUserState } from "./contexts/user/user.context";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CreateListingsProvider } from "./contexts/create-listing/create-listing.context";
import { ListingCreateMain } from "./pages/listings/create/listings.create.main";
import { SingleListingMain } from "./pages/listings/singleListing/single.listing.main";
import { SingleListingsProvider } from "./contexts/single-listing/singleListing.context";

import { PersonalInformation } from "./pages/settings/personalinformation";
import { AccountSettings } from "./pages/settings/accountsettings";
import RegisterSaaS from "./pages/register/registersaas";
import { ListingProvider } from "./contexts/listings/listings.context";
import { getStoredItem } from "./contexts/common/localstorage";
import { StorageKeys } from "./contexts/common/storeapp.types";
import { MobileOverlay } from "./components/common/mobileoverlay.component";
import WelcomeInstaShow from "./pages/register/welcometoinstashow";
import { Elements } from "@stripe/react-stripe-js";
import TokensPageMain from "./pages/tokens/tokens.main";
import { loadStripe } from "@stripe/stripe-js";
// import StripeCheckoutSession from "./pages/checkout/StripeCheckoutSession";
import { SingleListingWrapper } from "./pages/listings/singleListing/single.listing.wrapper";
import { ToursProvider } from "./contexts/tours/tours.context";
import { LocksProvider } from "./contexts/locks/locks.context";
import { TeamProvider } from "./contexts/team/team.context";
import { TeamViewMainSwitch } from "./pages/teams/team.main.view.switch";
import { TeamViewDetailsSwitch } from "./pages/teams/team.details.view.switch";
import { TourViewMainSwitch } from "./pages/tours/tours.main.view.switch";
import { TourViewDetailsSwitch } from "./pages/tours/tours.details.view.switch";
import { PartnersMain } from "./pages/partners/partners.main";
import PartnersDetailsPage from "./pages/partners/partners.details";
import { LocksViewMainSwitch } from "./pages/locks/locks.main.view.switch";
import { LocksViewDetailsSwitch } from "./pages/locks/locks.details.view.switch";
import { ListingsViewMainSwitch } from "./pages/listings/viewing/listings.main.view.switch";
import { LenderListingDetails } from "./pages/listings/viewing/lender/lender.listing.details";
import { LeadsMain } from "./pages/leads/leads.main";
import LeadsDetailsPage from "./pages/leads/leads.details";
import { DashboardViewMainSwitch } from "./pages/dashboard/dashboard.main.view.switch";
import { PartnersProvider } from "./contexts/partners/partners.context";
import { LeadsProvider } from "./contexts/leads/leads.context";
// import { ListingCreateFormContextGeneral } from "./components/listings/create/listing.create.general.form.context";
//// Root Providers will contain all context providers, including react router provider Browser Router
export const RootProviders: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   return (
      <ThemeProvider theme={mainTheme}>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <UserProvider>
               <ApiProvider>
                  <GradientProvider>
                     <SingleListingsProvider>
                        <BrowserRouter>
                           <NavProvider>{children}</NavProvider>
                        </BrowserRouter>
                     </SingleListingsProvider>
                  </GradientProvider>
               </ApiProvider>
            </UserProvider>
         </LocalizationProvider>
      </ThemeProvider>
   );
};

export const useAuth = () => {
   //// Check for acess token, if access token is null redirect the user to login
   //// Uncomment for return true in development to allow unauth
   // return true

   const { accessToken } = useUserState();

   if (accessToken !== null && accessToken !== "") {
      return accessToken;
   } else {
      const storageToken = getStoredItem(StorageKeys.access_token);
      return storageToken;
   }
};

const PublicRoutes: React.FC = () => {
   return (
      <PageContainer>
         <Outlet />
      </PageContainer>
   );
};

const ProtectedRoutes: React.FC = () => {
   /// determine if user is authenticated or not, render the login/landing page if not
   const isAuth = useAuth();

   if (!isAuth) {
      return <Navigate to="/login" />;
   } else {
      return (
         <>
            <MobileOverlay />
            <MainNav>
               <Outlet />
            </MainNav>
         </>
      );
   }
};

const stripePromise = loadStripe("pk_live_CP0lH53WSjsixpGt1SpT7AIR00r3hsFu9Z");

const App = () => {
   return (
      <Elements stripe={stripePromise}>
         <RootProviders>
            <Routes>
               {/* Public Routes */}
               <Route element={<PublicRoutes />}>
                  <Route
                     path="/"
                     element={<Navigate to={PublicRoutePaths.Login} />}
                  />
                  {/* <Route path="/register" element={<Register />} /> */}
                  <Route path="/register" element={<RegisterSaaS />} />
                  <Route
                     path={PublicRoutePaths.WelcomeToInstaShow}
                     element={<WelcomeInstaShow />}
                  />
                  <Route
                     path={PublicRoutePaths.Login}
                     element={<LoginMain />}
                  />
                  <Route
                     path={PublicRoutePaths.ForgotPasssword}
                     element={<ForgotPasswordMain />}
                  />
               </Route>
               {/* Protected Routes */}
               <Route element={<ProtectedRoutes />}>
                  <Route
                     path={ProtectedRoutePaths.Dashboard}
                     element={<DashboardViewMainSwitch />}
                  />

                  <Route
                     path={ProtectedRoutePaths.PersonalInformation}
                     element={<PersonalInformation />}
                  />

                  <Route path={ProtectedRoutePaths.AccountSettings}>
                     <Route
                        path={ProtectedRoutePaths.AccountSettings}
                        element={
                           <Navigate
                              to={`${ProtectedRoutePaths.AccountSettings}/account`}
                           />
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.AccountSettingsParam}
                        element={<AccountSettings />}
                     />
                  </Route>

                  <Route path={ProtectedRoutePaths.Listing}>
                     <Route path={ProtectedRoutePaths.ListingIdParam}>
                        <Route
                           path={ProtectedRoutePaths.ListingIdParam}
                           element={
                              <Navigate
                                 to={`${ProtectedRoutePaths.Listing}/${ProtectedRoutePaths.ListingIdParam}/general/photos`}
                              />
                           }
                        />
                        <Route
                           path={
                              ProtectedRoutePaths.ListingCreatedCurrentMainTab
                           }
                        >
                           <Route
                              path={
                                 ProtectedRoutePaths.ListingCreatedCurrentSecondaryTab
                              }
                              element={
                                 <SingleListingWrapper>
                                    <SingleListingMain />
                                 </SingleListingWrapper>
                              }
                           />
                        </Route>
                     </Route>
                  </Route>

                  <Route path={ProtectedRoutePaths.ListingCreate}>
                     <Route
                        path={ProtectedRoutePaths.ListingCreate}
                        element={
                           <Navigate
                              to={`${ProtectedRoutePaths.ListingCreate}/general/photos`}
                           />
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.ListingCreateCurrentMainTab}
                     >
                        <Route
                           path={
                              ProtectedRoutePaths.ListingCreateCurrentSecondaryTab
                           }
                           element={
                              <CreateListingsProvider>
                                 <ListingCreateMain />
                              </CreateListingsProvider>
                           }
                        />
                     </Route>
                     <Route />
                  </Route>

                  <Route path={ProtectedRoutePaths.Listings}>
                     <Route
                        path={ProtectedRoutePaths.Listings}
                        element={
                           <Navigate
                              to={`${ProtectedRoutePaths.Listings}/active`}
                           />
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.ListingTypeParam}
                        element={
                           <ListingProvider>
                              <ListingsViewMainSwitch />
                           </ListingProvider>
                        }
                     />
                     <Route
                        path={
                           ProtectedRoutePaths.Listings + "/:propertyId/details"
                        }
                        element={
                           <ListingProvider>
                              <LenderListingDetails />
                           </ListingProvider>
                        }
                     />
                  </Route>

                  <Route path={ProtectedRoutePaths.Tours}>
                     <Route
                        path={ProtectedRoutePaths.Tours}
                        element={
                           <Navigate
                              to={`${ProtectedRoutePaths.Tours}/upcoming`}
                           />
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.Tours + "/:TourType"}
                        element={
                           <ToursProvider>
                              <TourViewMainSwitch />
                           </ToursProvider>
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.Tours + "/:TourID/details"}
                        element={
                           <ToursProvider>
                              <TourViewDetailsSwitch />
                           </ToursProvider>
                        }
                     />
                  </Route>
                  {/* Locks */}
                  <Route path={ProtectedRoutePaths.Locks}>
                     <Route
                        path={ProtectedRoutePaths.Locks}
                        element={
                           <Navigate to={`${ProtectedRoutePaths.Locks}/all`} />
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.Locks + "/:LockType"}
                        element={
                           <LocksProvider>
                              <LocksViewMainSwitch />
                           </LocksProvider>
                        }
                     />
                  </Route>

                  <Route path={ProtectedRoutePaths.LockDetails}>
                     <Route path={ProtectedRoutePaths.LockDetails} />
                     <Route
                        path={ProtectedRoutePaths.LockDetails + "/:lockKey"}
                        element={
                           <LocksProvider>
                              <LocksViewDetailsSwitch />
                           </LocksProvider>
                        }
                     />
                  </Route>

                  {/* Team */}
                  <Route path={ProtectedRoutePaths.Team}>
                     <Route
                        path={ProtectedRoutePaths.Team}
                        element={
                           <TeamProvider>
                              <TeamViewMainSwitch />
                           </TeamProvider>
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.Team + "/:UserId/details"}
                        element={
                           <TeamProvider>
                              <TeamViewDetailsSwitch />
                           </TeamProvider>
                        }
                     />
                  </Route>

                  <Route
                     path={ProtectedRoutePaths.Tokens}
                     element={<TokensPageMain />}
                  />

                  <Route path={ProtectedRoutePaths.Partners}>
                     <Route
                        path={ProtectedRoutePaths.Partners}
                        element={
                           <Navigate
                              to={`${ProtectedRoutePaths.Partners}/active`}
                           />
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.Partners + "/:PartnerType"}
                        element={
                           <PartnersProvider>
                              <PartnersMain />
                           </PartnersProvider>
                        }
                     />
                     <Route
                        path={ProtectedRoutePaths.Partners + "/:UserId/details"}
                        element={
                           <PartnersProvider>
                              <PartnersDetailsPage />
                           </PartnersProvider>
                        }
                     />
                  </Route>

                  <Route path={ProtectedRoutePaths.Leads}>
                     <Route
                        path={ProtectedRoutePaths.Leads}
                        element={
                           <LeadsProvider>
                              <LeadsMain />
                           </LeadsProvider>
                        }
                     />
                     <Route
                        path={
                           ProtectedRoutePaths.Leads +
                           "/:UserId/:PartnerType/details"
                        }
                        element={
                           <LeadsProvider>
                              <LeadsDetailsPage />
                           </LeadsProvider>
                        }
                     />
                  </Route>
               </Route>

               <Route path="*" element={<NotFound />} />
            </Routes>
         </RootProviders>
      </Elements>
   );
};

export default App;
