import React from "react";
import { makeStyles } from "tss-react/mui";
import { Grid } from "@mui/material";
import { PropaneSharp } from "@mui/icons-material";

export const HeaderButton: React.FC<{
   backgroundColor: string;
   color: string;
   borderColor: string;
   text: string;
   onClick?: (e: any) => void;
   height?: string;
   width?: string;
   clickable: boolean;
}> = ({
   color,
   text,
   onClick,
   backgroundColor,
   borderColor,
   clickable,
   width,
}) => {
   const { classes } = useStyles();
   return (
      <>
         <Grid
            onClick={onClick}
            item
            className={
               clickable
                  ? `${classes.container}`
                  : `${classes.container} ${classes.noPointer}`
            }
            sx={{
               backgroundColor: `${backgroundColor}`,
               color: `${color}`,
               border: `2px solid ${borderColor}`,
               width: width ?? "110px",
            }}
         >
            <div className={classes.text}>{text}</div>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "header-button" })((theme) => ({
   container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "25px",
      letterSpacing: "0.7px",
      height: "32px",
      //width: "110px",
   },
   noPointer: {
      cursor: "default",
   },
   text: {
      fontFamily: theme.fonts.secondary.reg,
      fontSize: "16px",
      textTransform: "uppercase",
      padding: "10px",
      textWrap: "nowrap",
   },
}));
