import React from "react";
import AppBody from "../../components/body/main.body.component.jsx";
import {
   Alert,
   Avatar,
   Button,
   CircularProgress,
   Grid,
   IconButton,
   Menu,
   MenuItem,
   Snackbar,
   Tab,
   Tabs,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import RoundedButton from "../../components/common/boxlty.button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
   getPartnerDetails,
   archivePartner,
} from "../../contexts/api/api.functions";
// import { TeamMemberDetails } from "../teams/non-enterprise/teams.types.js";
import { MoreHoriz } from "@mui/icons-material";
import { useGradient } from "../../contexts/gradients/gradients.context";
import { HeaderButton } from "../../components/listings/create/forms/header.button";
import AssignLockModal from "../../components/dashboard/AssignLockModal";
import { ReactComponent as ListingsIconActive } from "../../styles/assets/logos/boxlty-logo-symbol.svg";
import { ReactComponent as ListingsIconInActive } from "../../styles/assets/logos/boxlty-logo-symbol-inactive.svg";
import { ReactComponent as LocksIconActive } from "../../styles/assets/Icons/locks-icon-active.svg";
import { ReactComponent as LocksIconInActive } from "../../styles/assets/Icons/locks-icon-default.svg";
import TabbedContainerWithSubTabs from "../../components/common/boxlty.tabbedcontainer.subtabs";
import { PartnerDetails } from "./partners.types.js";

function PartnersDetailsPage() {
   const { classes } = useStyles();
   const { primaryButtonsGradient } = useGradient();
   const [loadingUser, setLoadingUser] = useState(true);
   const { UserId } = useParams<{ UserId: string }>();
   const [userDetail, setUserDetail] = useState<PartnerDetails | null>(null);
   const [showActionsPopup, setShowActionsPopup] = useState(false);
   const [showDeletePopup, setShowDeletePopup] = useState(false);
   const [showArchivePopup, setShowArchivePopup] = useState(false);
   const [showSnackbar, setShowSnackBar] = useState(false);
   const [snackBarText, setSnackBarText] = useState("");
   const [snackBarSuccess, setSnackBarSuccess] = useState<boolean>(true);
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const exportOpen = Boolean(exportAnchorEl);
   const [assignLocksModalVisible, setAssignLocksModalVisible] =
      useState(false);
   const [typeValue, setTypeValue] = useState("1");

   const fetchUserDetails = async () => {
      if (UserId) {
         const res = await getPartnerDetails(UserId);
         if (res && res.data && res.data.status === true) {
            setUserDetail(res.data.result);
            setLoadingUser(false);
         }
      }
   };

   useEffect(() => {
      fetchUserDetails();
   }, [UserId]);

   useEffect(() => {
      if (showActionsPopup) {
         const actionsContainer = document.querySelector("#actions-container");
         actionsContainer?.addEventListener("mouseleave", handlePopup);
      }
   }, [showActionsPopup]);

   function formatDateString(dateString: string) {
      const date = new Date(dateString);
      const formattedDate = `${
         date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
   }

   const handleDeleteMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      setExportAnchorEl(event.currentTarget);
   };

   const handleDeleteMenuClose = () => {
      setExportAnchorEl(null);
   };

   function handlePopup() {
      setShowActionsPopup(!showActionsPopup);
   }

   const closeDeletePopup = () => {
      setShowDeletePopup(false);
   };

   const handleOpenDeletePopup = () => {
      setShowDeletePopup(true);
   };

   const closeArchivePopup = () => {
      setShowArchivePopup(false);
   };

   const handleArchivePartner = async () => {
      const response = await archivePartner({
         userId: UserId,
         update: userDetail?.isArchived === "1" ? "0" : "1",
      });
      if (response && response.data.status === true) {
         setShowArchivePopup(false);
         setShowSnackBar(true);
         setSnackBarSuccess(true);
         setSnackBarText(response.data.message);
         window.location.reload();
      } else {
         setShowSnackBar(true);
         setSnackBarSuccess(false);
         setSnackBarText(
            "Cannot archive Partner. Remove all associated listings and locks and try again."
         );
         closeArchivePopup();
      }
   };

   return (
      <>
         <AppBody>
            <AssignLockModal
               id={"assign-locks-modal"}
               isVisible={assignLocksModalVisible}
               openPosition={{
                  top: "0",
                  left: "0",
                  right: "0px",
                  bottom: "40vh",
               }}
               closedPosition={{
                  top: "0",
                  left: "0",
                  right: "-450px",
                  bottom: "40vh",
               }}
               refresh={true}
            />
            <div className={classes.PageContainer}>
               <Grid
                  container
                  direction="row"
                  display={"flex"}
                  sx={{
                     justifyContent: "space-between",
                     alignItems: "center",
                     height: "100%",
                     gap: "5px",
                  }}
               >
                  <Grid item>
                     <h1 className={classes.PageHeader}>
                        {/* {props.listingName ? props.listingName : "Family Home"} */}
                        {loadingUser
                           ? ""
                           : userDetail?.userName
                           ? userDetail?.userName
                           : ""}
                     </h1>
                  </Grid>
                  <Grid item className={classes.buttonContainer}>
                     <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                           setAssignLocksModalVisible(!assignLocksModalVisible);
                        }}
                        classes={{
                           endIcon: classes.startIcon,
                        }}
                        endIcon={<AddIcon />}
                        sx={{
                           borderRadius: "26px",
                           backgroundImage: `${primaryButtonsGradient}`,
                        }}
                     >
                        ASSIGN LOCKS
                     </Button>
                  </Grid>
               </Grid>
               {/* Header */}
               <Snackbar
                  open={showSnackbar}
                  autoHideDuration={4000}
                  sx={{
                     zIndex: (theme) => theme.zIndex.drawer + 2,
                     position: "absolute",
                  }}
                  onClose={() => {
                     setShowSnackBar(false);
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
               >
                  <Alert
                     onClose={() => {
                        setShowSnackBar(false);
                     }}
                     severity={snackBarSuccess ? "success" : "error"}
                     color={snackBarSuccess ? "success" : "error"}
                  >
                     {snackBarText}
                  </Alert>
               </Snackbar>

               {/* Split Container */}
               {loadingUser ? (
                  <CircularProgress />
               ) : (
                  <section className={classes.SplitContainer}>
                     {/* Main Section */}
                     <section className={classes.MainSection}>
                        <section className={classes.ThreeDots}>
                           <IconButton
                              edge="start"
                              aria-haspopup="true"
                              id="lock-delete"
                              aria-expanded={exportOpen ? "true" : undefined}
                              aria-controls={
                                 exportOpen ? "lock-delete-menu" : undefined
                              }
                              onClick={handleDeleteMenuClick}
                           >
                              <MoreHoriz fontSize="large" />
                           </IconButton>
                        </section>

                        {/* Top Row */}
                        {/* Visitor Information Section */}

                        <section className={classes.VisitorSectionContainer}>
                           {/* Visitor Info */}
                           <section className={classes.VisitorInfoSection}>
                              {/* Visitor IMG */}
                              <div className={classes.ProfileImageContainer}>
                                 {userDetail?.userProfile !== undefined &&
                                 userDetail.userProfile !== "" &&
                                 userDetail.status !== "1" ? (
                                    userDetail.userProfile !== null ? (
                                       <img
                                          className={classes.ProfileImage}
                                          src={`https://boxlty-media.s3.amazonaws.com/user-profile/${userDetail.userProfile}`}
                                          style={{ borderRadius: "15px" }}
                                          alt="Avatar"
                                       />
                                    ) : (
                                       <Avatar
                                          className={classes.ProfileImage}
                                          style={{ borderRadius: "15px" }}
                                          alt="Avatar"
                                       />
                                    )
                                 ) : (
                                    <div
                                       style={{
                                          backgroundColor: "#707070",
                                          borderRadius: "15px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "white",
                                          fontWeight: "bold",
                                       }}
                                       className={classes.ProfileImage}
                                    >
                                       INVITATION SENT
                                    </div>
                                 )}
                              </div>
                              {/* Tour Info */}
                              <section className={classes.TeamMemberInfo1}>
                                 {userDetail?.isArchived === "0" ? (
                                    <HeaderButton
                                       color="black"
                                       backgroundColor="white"
                                       borderColor="#66E0B4"
                                       text="ACTIVE"
                                       clickable={false}
                                    />
                                 ) : (
                                    <HeaderButton
                                       color="black"
                                       backgroundColor="white"
                                       borderColor="red"
                                       text="ARCHIVED"
                                       clickable={false}
                                    />
                                 )}
                                 {/* Email */}
                                 <p className={classes.VisitorInfoFieldLabel}>
                                    EMAIL
                                 </p>
                                 <p className={classes.VisitorInfoText2}>
                                    {userDetail?.email ? userDetail?.email : ""}
                                 </p>
                                 {/* Phone */}
                                 <p className={classes.VisitorInfoFieldLabel}>
                                    PHONE
                                 </p>
                                 <p className={classes.VisitorInfoText3}>
                                    {userDetail?.mobile
                                       ? userDetail?.mobile
                                       : ""}
                                 </p>
                              </section>

                              <Menu
                                 className={classes.exportMenu}
                                 open={exportOpen}
                                 id="lock-delete-menu"
                                 anchorEl={exportAnchorEl}
                                 onClose={handleDeleteMenuClose}
                                 MenuListProps={{
                                    "aria-labelledby": "lock-delete",
                                 }}
                                 anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                 }}
                                 transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                 }}
                              >
                                 <MenuItem
                                    onClick={() => {
                                       setShowArchivePopup(true);
                                    }}
                                 >
                                    {userDetail?.isArchived === "1"
                                       ? "Activate"
                                       : "Archive"}
                                 </MenuItem>
                                 <MenuItem onClick={handleOpenDeletePopup}>
                                    Delete
                                 </MenuItem>
                              </Menu>

                              {showDeletePopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          DELETE PARTNER
                                       </p>
                                       <p className={classes.PopupText}>
                                          Are you sure you want to delete{" "}
                                          {userDetail?.userName}?
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="CANCEL"
                                             listener={() => {
                                                closeDeletePopup();
                                                handleDeleteMenuClose();
                                             }}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="DELETE"
                                             listener={() => {
                                                //handleDelete();
                                                //handleDeleteMenuClose();
                                             }}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                              {showArchivePopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          {userDetail?.isArchived === "1"
                                             ? "ACTIVATE PARTNER"
                                             : "ARCHIVE PARTNER"}
                                       </p>
                                       <p className={classes.PopupText}>
                                          {userDetail?.isArchived === "1"
                                             ? "Are you sure you want to activate " +
                                               userDetail?.userName +
                                               "?"
                                             : "Are you sure you want to archive " +
                                               userDetail?.userName +
                                               "?"}
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="NO"
                                             listener={() => {
                                                closeArchivePopup();
                                                handleDeleteMenuClose();
                                             }}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="YES"
                                             listener={() => {
                                                handleArchivePartner();
                                             }}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </section>

                           {/* Scheduled section */}
                           <section className={classes.TeamMemberInfo1}>
                              {/* Phone */}
                              <p className={classes.VisitorInfoTextBold}>
                                 {userDetail?.company
                                    ? userDetail?.company
                                    : ""}
                              </p>
                              <p className={classes.VisitorInfoFieldLabel}>
                                 ADDRESS
                              </p>
                              <p className={classes.VisitorInfoText2}>
                                 {userDetail?.address
                                    ? userDetail?.address + ", "
                                    : ""}
                                 {userDetail?.city
                                    ? userDetail?.city + ", "
                                    : ""}
                                 {userDetail?.state
                                    ? userDetail?.state + ", "
                                    : ""}
                                 {userDetail?.zipcode
                                    ? userDetail?.zipcode
                                    : ""}
                              </p>
                              <p className={classes.VisitorInfoFieldLabel}>
                                 CREATED
                              </p>
                              <p className={classes.VisitorInfoText2}>
                                 {formatDateString(
                                    userDetail?.invitedDate ?? ""
                                 )}
                              </p>
                           </section>
                        </section>
                     </section>
                     <React.Fragment>
                        <section className={classes.SecondarySection}>
                           {/* Component to house those two sections */}
                           <TabbedContainerWithSubTabs
                              tabs={[
                                 {
                                    tabName: "Listings",
                                    tabIconActive: <ListingsIconActive />,
                                    tabIconInActive: <ListingsIconInActive />,
                                    tabComponent: (
                                       <div
                                          className={
                                             classes.TourDetailsOuterContainer
                                          }
                                       >
                                          <div
                                             className={
                                                classes.TourDetailsHeaderRow
                                             }
                                          >
                                             <h1
                                                className={
                                                   classes.TourDetailsInfoHeader
                                                }
                                                style={{ marginTop: "10px" }}
                                             >
                                                Listings
                                             </h1>
                                          </div>

                                          <section
                                             className={
                                                classes.ListingsContainer
                                             }
                                          >
                                             {userDetail?.propertyDetail.map(
                                                (listing, index) => (
                                                   <>
                                                      <div
                                                         key={
                                                            listing.propertyId
                                                         }
                                                         className={
                                                            classes.ListingItem
                                                         }
                                                      >
                                                         <div>
                                                            <Avatar
                                                               className={
                                                                  classes.imgPreview
                                                               }
                                                               variant="rounded"
                                                               src={`https://boxlty-media.s3.amazonaws.com/property-images/${listing.propertyImage}`}
                                                               sx={{
                                                                  width: "75px !important",
                                                                  height:
                                                                     "75px !important",
                                                               }}
                                                            />
                                                         </div>
                                                         <div
                                                            className={
                                                               classes.ListingDetails
                                                            }
                                                         >
                                                            <p
                                                               className={
                                                                  classes.ListingItemName
                                                               }
                                                            >
                                                               {
                                                                  listing.propertyNickname
                                                               }
                                                            </p>
                                                            <p
                                                               className={
                                                                  classes.ListingItemAddress
                                                               }
                                                            >
                                                               {
                                                                  listing.propertyAddress
                                                               }
                                                            </p>
                                                         </div>
                                                      </div>
                                                      <div
                                                         className={
                                                            classes.ListingSectionDividerLine
                                                         }
                                                      ></div>
                                                   </>
                                                )
                                             )}
                                          </section>
                                       </div>
                                    ),
                                 },
                                 {
                                    tabName: "Locks",
                                    tabIconActive: <LocksIconActive />,
                                    tabIconInActive: <LocksIconInActive />,
                                    tabComponent: (
                                       <div
                                          className={
                                             classes.TourDetailsOuterContainer
                                          }
                                       >
                                          <Grid
                                             container
                                             direction={"column"}
                                             display={"flex"}
                                             justifyContent="space-between"
                                          >
                                             <Grid item>
                                                <div
                                                   className={
                                                      classes.TourDetailsHeaderRow
                                                   }
                                                >
                                                   <h1
                                                      className={
                                                         classes.TourDetailsInfoHeader
                                                      }
                                                   >
                                                      Locks
                                                   </h1>
                                                   <Tabs
                                                      value={typeValue}
                                                      //onChange={handleTabChange}
                                                      classes={{
                                                         root: classes.tabsRoot,
                                                         flexContainer:
                                                            classes.tabsFlex,
                                                         indicator:
                                                            classes.tabsIndicator,
                                                      }}
                                                   >
                                                      <Tab
                                                         label={`Active (${userDetail?.totalActiveLocks})`}
                                                         disableFocusRipple
                                                         disableRipple
                                                         value="1"
                                                         onClick={() => {
                                                            // console.log(
                                                            //    "active tab"
                                                            // );
                                                            setTypeValue("1");
                                                         }}
                                                         classes={{
                                                            root: classes.tabSingleRoot,
                                                         }}
                                                      />
                                                      <Tab
                                                         label={`Inactive (${userDetail?.totalInactiveLocks})`}
                                                         disableFocusRipple
                                                         disableRipple
                                                         value="2"
                                                         onClick={() => {
                                                            // console.log(
                                                            //    "inactive tab"
                                                            // );
                                                            setTypeValue("2");
                                                         }}
                                                         classes={{
                                                            root: classes.tabSingleRoot,
                                                         }}
                                                      />
                                                   </Tabs>
                                                </div>
                                             </Grid>
                                          </Grid>
                                          <section
                                             className={
                                                classes.ListingsContainer
                                             }
                                          >
                                             {userDetail?.userLocks
                                                .filter(
                                                   (lock) =>
                                                      lock.isActive ===
                                                      typeValue
                                                )
                                                .map((lock, index) => (
                                                   <>
                                                      <div
                                                         key={lock.lockNickname}
                                                         className={
                                                            classes.ListingItem
                                                         }
                                                      >
                                                         <div
                                                            className={
                                                               classes.ListingDetails
                                                            }
                                                         >
                                                            <p
                                                               className={
                                                                  classes.LockItemName
                                                               }
                                                            >
                                                               {
                                                                  lock.lockNickname
                                                               }
                                                            </p>
                                                            <p
                                                               className={
                                                                  classes.LockItemName2
                                                               }
                                                            >
                                                               {lock.lockType ===
                                                               1
                                                                  ? "MasterLock"
                                                                  : "Igloohome"}
                                                            </p>
                                                            <p
                                                               className={
                                                                  classes.LockItemAddress
                                                               }
                                                            >
                                                               {
                                                                  lock.lockActivationCode
                                                               }
                                                            </p>
                                                         </div>
                                                      </div>
                                                      <div
                                                         className={
                                                            classes.ListingSectionDividerLine
                                                         }
                                                      ></div>
                                                   </>
                                                ))}
                                          </section>
                                       </div>
                                    ),
                                 },
                              ]}
                              leftBorderRadius={"20px"}
                              showBoxShadow={true}
                              startingTabIndex={0}
                           />
                        </section>
                     </React.Fragment>

                     {/* Chat / Listing Details Section */}
                  </section>
               )}
            </div>
         </AppBody>
      </>
   );
}

const useStyles = makeStyles({ name: "TeamDetails" })((theme) => ({
   PageContainer: {
      display: "flex",
      flexDirection: "column",
   },
   PageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginTop: "0px",
      marginBottom: "5px",
      height: "41.5px",
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
   },
   MainSection: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "70%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   MainSectionTopRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "45px",
   },
   MainInfoSection: {
      display: "flex",
      alignItems: "flex-start",
   },
   TourImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   TourInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "5px",
      fontSize: "17px",
      fontFamily: theme.fonts.secondary.bold,
   },
   TourInfoText2: {
      margin: "0",
      fontWeight: "500",
      fontSize: "14px",
      marginBottom: "5px",
   },
   TourInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorSectionContainer: {
      marginTop: "25px",
   },
   VisitorSectionText: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "22px",
      marginBottom: "5px",
   },
   VisitorSectionDividerLine: {
      width: "250px",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
   },
   VisitorInfoSection: {
      marginTop: "20px",
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "20px",
   },
   VisitorImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   VisitorInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "15px",
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "17px",
      marginTop: "15px",
   },
   VisitorInfoText2: {
      margin: "0",
      fontWeight: "500",
      marginBottom: "15px",
      fontSize: "17px",
   },
   VisitorInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorInfoTextBold: {
      fontWeight: "bold",
      margin: "0",
      fontSize: "20px",
      marginBottom: "20px",
   },
   VisitorInfoFieldLabel: {
      fontSize: "14px",
      color: theme.palette.common.lightGray,
      margin: "0",
      marginTop: "10px",
      marginBottom: "3px",
   },
   ThreeDots: {
      cursor: "pointer",
      float: "right",
   },
   PreApprovalGayText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "5px",
      fontSize: "12px",
   },
   PreApprovalLink: {
      display: "block",
      color: theme.palette.common.primary,
      fontFamily: theme.fonts.secondary.med,
      fontSize: "13px",
      textDecoration: "underline",
      marginBottom: "50px",
   },
   MiniDivider: {
      height: "2px",
      minHeight: "2px",
      // boxShadow : `0px 0px 10px 2px ${theme.palette.common.lightGray}`,
      backgroundColor: theme.palette.common.lightGray,
      width: "35px",
      marginBottom: "15px",
      marginTop: "50px",
   },
   ScheduledText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "1px",
      fontSize: "13px",
   },
   SecondarySection: {
      width: "35%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "auto",
      leftBorderRadius: "20px",
      showBoxShadow: "true",
      startingTabIndex: "0",
   },
   ProfileImage: {
      height: "150px",
      width: "150px",
      objectFit: "cover",
   },
   ProfileImageContainer: {
      marginBottom: "5px",
      height: "150px",
      width: "150px",
      overflow: "hidden",
      marginRight: "10px",
   },
   startIcon: {
      paddingBottom: "2px",
      justifySelf: "center",
   },
   TourDetailsHeaderRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      justifyContent: "space-between",
      marginLeft: "15px",
   },
   TourDetailsInfoHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "23px",
      whiteSpace: "nowrap",
      margin: "0px",
      marginRight: "15px",
   },
   TourDetailsOpenIcon: {
      height: "13px",
      width: "13px",
      cursor: "pointer",
   },
   TourDetailsOuterContainer: {
      paddingTop: "20px",
      paddingLeft: "10px",
      paddingRight: "10px",
      minHeight: "80vh",
      maxHeight: "80vh",
      overflow: "auto",
      width: "90%",
   },
   ListingSectionDividerLine: {
      width: "92%",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
      marginLeft: "15px",
      marginTop: "10px",
      marginBottom: "10px",
   },
   ActionsContainer: {
      marginLeft: "auto",
      fontSize: "30px",
      fontWeight: "bold",
      color: theme.palette.common.medGray,
      position: "relative",
      display: "inline-block",
      paddingBottom: "100px",
      paddingLeft: "125px",
   },
   ActionsButton: {
      cursor: "pointer",
      marginTop: "-25px",
   },
   ActionsPopup: {
      //display: "none",
      position: "absolute",
      backgroundColor: "white",
      border: "1px solid #ccc",
      borderRadius: "20px",
      boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
      minWidth: "125px",
      zIndex: 1,
      right: 0,
      top: "10px",
   },
   TeamMemberInfo1: {
      marginLeft: "10px",
   },
   ActionsText: {
      fontSize: "14px",
      textAlign: "left",
      paddingLeft: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      cursor: "pointer",
      "&:hover": {
         backgroundColor: theme.palette.common.lightGray,
         color: "#fff",
      },
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      minHeight: "200px",
      minWidth: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "21px",
      fontFamily: theme.fonts.secondary.bold,
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
   PopupButtonsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
   PopupButtons: {
      margin: "0 5px",
   },
   ListingsContainer: {
      marginTop: "25px",
   },
   ListingItem: {
      display: "flex",
      alignItems: "center",
   },
   ListingDetails: {
      flex: 1,
      //paddingLeft: "10px",
      paddingRight: "10px",
      marginLeft: "15px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   imgPreview: {
      marginTop: "3px",
      marginLeft: "15px",
   },
   ListingItemName: {
      fontSize: "18px",
      font: theme.fonts.primary.reg,
      fontWeight: "bold",
   },
   ListingItemAddress: {
      fontSize: "14px",
      font: theme.fonts.secondary.reg,
      color: theme.palette.common.medGray,
   },
   LockItemName: {
      fontSize: "20px",
      font: theme.fonts.primary.reg,
      fontWeight: "bold",
      marginTop: "5px",
      marginBottom: "5px",
   },
   LockItemName2: {
      fontSize: "18px",
      font: theme.fonts.primary.reg,
      //fontWeight: "bold",
      marginTop: "5px",
      marginBottom: "5px",
   },
   LockItemAddress: {
      fontSize: "16px",
      font: theme.fonts.secondary.reg,
      color: theme.palette.common.medGray,
      marginTop: "5px",
      marginBottom: "5px",
   },
   AddMemberDropdown: {
      display: "flex",
      margin: "auto",
      width: "50%",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "7px",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      fontWeight: "bold",
      position: "relative",
      bottom: "10px",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      justifyContent: "flex-end",
      //fontWeight: "bold",
      fontSize: "11px",
      position: "relative",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
}));

export default PartnersDetailsPage;
