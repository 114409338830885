import React from "react";
import AppBody from "../../../components/body/main.body.component";
import {
   Grid,
   Button,
   Snackbar,
   Alert,
   IconButton,
   Menu,
   MenuItem,
   AlertColor,
} from "@mui/material";
import { format } from "date-fns";
import { makeStyles } from "tss-react/mui";
import { DataTableComponent } from "../../../components/common/data.table.component";
import { useTheme } from "@mui/material";
import { useState } from "react";
import {
   GridColDef,
   GridRowSelectionModel,
   useGridApiRef,
   GridValueGetterParams,
} from "@mui/x-data-grid";
import { LocksPageSearchInput } from "../../../components/locks/locks.search.input";
import { useGradient } from "../../../contexts/gradients/gradients.context";
import {
   useLocksState,
   useLocksDispatch,
   LocksActions,
} from "../../../contexts/locks/locks.context";
import AddIcon from "@mui/icons-material/Add";
import AddLockModal from "../../../components/dashboard/AddLockModal";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../../../contexts/user/user.context";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DeleteLockReq } from "../../../contexts/api/api.types";
import { deleteLock } from "../../../contexts/api/api.functions";
import RoundedButton from "../../../components/common/boxlty.button";

type Props = {};

export const LocksMain: React.FC<{}> = (props: Props) => {
   const theme = useTheme();
   const { primaryButtonsGradient } = useGradient();
   const { classes } = useStyles();
   const [selected, setSelected] = useState<GridRowSelectionModel>([]);
   const [addLockModalVisible, setAddLockModalVisible] = useState(false);
   const [showDeletePopup, setShowDeletePopup] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const exportOpen = Boolean(exportAnchorEl);
   const apiRef = useGridApiRef();
   const { locks, loading } = useLocksState();
   const dispatch = useLocksDispatch();
   const navigate = useNavigate();
   const { user } = useUserState();

   const handleMenuClose = () => {
      setExportAnchorEl(null);
   };

   const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      // console.log(event.currentTarget);
      setExportAnchorEl(event.currentTarget);
   };

   const closeDeletePopup = () => {
      setShowDeletePopup(false);
   };

   const handleDeleteLocks = async () => {
      //console.log("selected locks", selected);

      const locksToDelete = locks.filter((lock) => selected.includes(lock.id));
      //console.log("locks to delete", locksToDelete);

      const promises = locksToDelete.map(async (lock) => {
         let lockDeviceId = lock.lockKey ?? "";
         const data: DeleteLockReq = {
            lockDeviceId,
         };
         const response = await deleteLock(data);
         if (!response || response.data.status === false) {
            return;
         }
      });

      await Promise.all(promises); // Wait for all promises to resolve

      // Reload the page after all asynchronous operations are complete
      window.location.reload();
   };

   const setSnackbarType = (value: AlertColor) => {
      setSnackBarType(value);
   };

   function returnStandardTimeString(time: string) {
      let timeArray = time.split(":"); // convert to array

      // fetch
      var hours = Number(timeArray[0]);
      var minutes = Number(timeArray[1]);

      // calculate
      var timeValue;

      if (hours > 0 && hours <= 12) {
         timeValue = "" + hours;
      } else if (hours > 12) {
         timeValue = "" + (hours - 12);
      } else if (hours === 0) {
         timeValue = "12";
      }

      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

      return timeValue;
   }

   //Define Columns based on model returned by API
   const gridColumns: GridColDef[] = [
      {
         field: "lockName",
         headerName: "Lock Name",
         width: 200,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               <Grid
                  item
                  style={{ textAlign: "left" }}
                  display={"flex"}
                  flexDirection={"column"}
               >
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value.lockName}
                  </span>
                  <span style={{ color: "#707070", fontSize: "16px" }}>
                     {params.value.lockId}
                  </span>
               </Grid>
            </Grid>
         ),
         valueGetter: (params: GridValueGetterParams) => {
            return {
               lockName: params.row.lockNickName,
               lockId: params.row.lockKey,
            };
         },
         valueFormatter: ({ value }) => `${value.lockName}`,
      },
      {
         field: "teamMember",
         headerName: "Team Member",
         width: 200,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid display={"flex"} direction={"column"}>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value.firstName} {params.value.lastName}
                  </span>
                  {/* <span style={{ color: "#707070", fontSize: "16px" }}>
              {params.value.address}
            </span> */}
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value.firstName} ${value.lastName}`,
         valueGetter: (params: GridValueGetterParams) => {
            return {
               firstName: params.row.teamMemberFirstName,
               lastName: params.row.teamMemberLastName,
            };
         },
      },
      {
         field: "type",
         headerName: "Type",
         width: 125,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            if (params.row.masterLockType != null) {
               return (
                  params.row.masterLockType[0].toUpperCase() +
                  params.row.masterLockType.slice(1)
               );
            } else if (params.row.iglooLockType != null) {
               return (
                  params.row.iglooLockType[0].toUpperCase() +
                  params.row.iglooLockType.slice(1)
               );
            } else {
               return ";";
            }
         },
      },
      {
         field: "listing",
         headerName: "Listing",
         width: 350,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid
                  item
                  style={{ textAlign: "left" }}
                  display={"flex"}
                  flexDirection={"column"}
               >
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value.propertyName}
                  </span>
                  <span style={{ color: "#707070", fontSize: "16px" }}>
                     {params.value.address}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value.propertyName}`,
         valueGetter: (params: GridValueGetterParams) => {
            return {
               propertyName: params.row.propertyNickname,
               address: params.row.full_address,
            };
         },
      },
      {
         field: "assigned",
         headerName: "Assigned",
         width: 175,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid
                  item
                  style={{ textAlign: "left" }}
                  display={"flex"}
                  flexDirection={"column"}
                  fontSize={"18px"}
               >
                  {params.row.assignedDate}
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => {},
         // `${format(new Date(value.date), "P")}, ${
         //    returnStandardTimeString(value.startTime) +
         //    " - " +
         //    returnStandardTimeString(value.endTime)
         // }`
         valueGetter: (params: GridValueGetterParams) => {
            return {
               date: params.row.bookingd,
               startTime: params.row.startTime,
               endTime: params.row.endTime,
            };
         },
      },
   ];

   return (
      <>
         <AppBody>
            <Snackbar
               open={snackbarOpen}
               autoHideDuration={4000}
               sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
               onClose={() => {
                  setSnackbarOpen(false);
               }}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
               <Alert
                  onClose={() => {
                     setSnackbarOpen(false);
                  }}
                  severity="success"
                  color={snackBarType}
               >
                  {snackbarMessage}
               </Alert>
            </Snackbar>
            <AddLockModal
               id={"add-lock-modal"}
               isVisible={addLockModalVisible}
               setSnackbarType={setSnackbarType}
               openPosition={{
                  top: "0",
                  left: "0",
                  right: "0px",
                  bottom: "25vh",
               }}
               closedPosition={{
                  top: "0",
                  left: "0",
                  right: "-450px",
                  bottom: "25vh",
               }}
               setPanel={setAddLockModalVisible}
               setSnackbar={setSnackbarOpen}
               setSnackbarMessage={setSnackbarMessage}
               refresh={true}
            />
            {showDeletePopup && (
               <div className={classes.ActionsPopupOverlay}>
                  <div className={classes.PopupCentered}>
                     {/* Popup content */}
                     <p className={classes.PopupHeader}>DELETE LOCKS</p>
                     <p className={classes.PopupText}>Delete selected locks?</p>
                     <div className={classes.PopupOptionsSection}>
                        <RoundedButton
                           text="CANCEL"
                           listener={closeDeletePopup}
                           boldText={true}
                        />
                        <RoundedButton
                           text="DELETE"
                           listener={handleDeleteLocks}
                           color="primary"
                           boldText={true}
                        />
                     </div>
                  </div>
               </div>
            )}
            <Grid
               container
               sx={{ height: "98%", width: "100%" }}
               className={classes.mainContainer}
               direction="column"
            >
               <Grid item>
                  <Grid
                     container
                     direction={"row"}
                     display="flex"
                     alignItems={"center"}
                     justifyContent="space-between"
                  >
                     <Grid item>
                        <span className={classes.header}>Locks</span>
                     </Grid>
                     <Grid item sx={{ width: "20%" }}>
                        <LocksPageSearchInput items={locks} />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item sx={{ height: "80%", width: "100%" }}>
                  <Grid
                     container
                     direction={"column"}
                     display="flex"
                     flexWrap={"nowrap"}
                  >
                     <Grid item sx={{ paddingBottom: "5px" }}>
                        <Grid
                           container
                           direction={"row"}
                           display={"flex"}
                           justifyContent="space-between"
                        >
                           {/* <Grid item sx={{ marginLeft: "15px" }}>
                    <Tabs
                      value={LockType}
                      classes={{
                        root: classes.tabsRoot,
                        flexContainer: classes.tabsFlex,
                        indicator: classes.tabsIndicator,
                      }}
                    >
                      <Tab
                        label={`All (${allCount})`}
                        disableFocusRipple
                        disableRipple
                        value="all"
                        onClick={() => {
                          navigate("/locks/all");
                        }}
                        classes={{ root: classes.tabSingleRoot }}
                      />
                      <Tab
                        label={`Active (${activeCount})`}
                        disableFocusRipple
                        disableRipple
                        value="active"
                        onClick={() => {
                          navigate("/locks/active");
                        }}
                        classes={{ root: classes.tabSingleRoot }}
                      />
                      <Tab
                        label={`Inactive (${inactiveCount})`}
                        disableFocusRipple
                        disableRipple
                        value="inactive"
                        onClick={() => {
                          navigate("/locks/inactive");
                        }}
                        classes={{ root: classes.tabSingleRoot }}
                      />
                    </Tabs>
                  </Grid> */}
                           <Grid item width="100%">
                              <Grid
                                 container
                                 direction="row"
                                 display={"flex"}
                                 sx={{
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    height: "100%",
                                    gap: "5px",
                                    width: "100%",
                                 }}
                              >
                                 <Grid
                                    item
                                    sx={{
                                       alignSelf: "center",
                                       justifySelf: "flex-end",
                                       display: "flex",
                                    }}
                                 >
                                    <Grid
                                       container
                                       direction="row"
                                       display={"flex"}
                                       className={classes.actionContainer}
                                    >
                                       {user?.isPrimaryOwner === 1 && (
                                          <Grid item>
                                             <span
                                                style={{
                                                   fontSize: "17px",
                                                   fontFamily:
                                                      theme.fonts.secondary.med,
                                                   color: theme.palette.common
                                                      .medGray,
                                                   display: "flex",
                                                }}
                                             >
                                                Actions
                                             </span>
                                          </Grid>
                                       )}
                                       {user?.isPrimaryOwner === 1 && (
                                          <Grid item>
                                             <IconButton
                                                disabled={
                                                   selected &&
                                                   selected.length > 0
                                                      ? false
                                                      : true
                                                }
                                                aria-haspopup="true"
                                                id="export"
                                                aria-expanded={
                                                   exportOpen
                                                      ? "true"
                                                      : undefined
                                                }
                                                aria-controls={
                                                   exportOpen
                                                      ? "export-menu"
                                                      : undefined
                                                }
                                                onClick={handleMenuClick}
                                             >
                                                <KeyboardArrowDownIcon fontSize="medium" />
                                             </IconButton>
                                             <Menu
                                                className={classes.exportMenu}
                                                open={exportOpen}
                                                id="export-menu"
                                                anchorEl={exportAnchorEl}
                                                onClose={handleMenuClose}
                                                MenuListProps={{
                                                   "aria-labelledby": "export",
                                                }}
                                                anchorOrigin={{
                                                   vertical: "bottom",
                                                   horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                   vertical: "top",
                                                   horizontal: "center",
                                                }}
                                             >
                                                <MenuItem
                                                   className={
                                                      classes.exportMenuItem
                                                   }
                                                   onClick={() => {
                                                      apiRef.current.exportDataAsCsv();
                                                   }}
                                                >
                                                   Export
                                                </MenuItem>
                                                <MenuItem
                                                   className={
                                                      classes.exportMenuItem
                                                   }
                                                   onClick={() => {
                                                      setShowDeletePopup(true);
                                                   }}
                                                >
                                                   Delete
                                                </MenuItem>
                                             </Menu>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Grid>
                                 {(user?.accountType !== 6 ||
                                    user?.isEnterpriseSuperUser === 1) && (
                                    <Grid
                                       item
                                       className={classes.buttonContainer}
                                    >
                                       <Button
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                             setAddLockModalVisible(
                                                !addLockModalVisible
                                             );
                                          }}
                                          classes={{
                                             endIcon: classes.startIcon,
                                          }}
                                          endIcon={<AddIcon />}
                                          sx={{
                                             borderRadius: "26px",
                                             backgroundImage: `${primaryButtonsGradient}`,
                                          }}
                                       >
                                          Lock
                                       </Button>
                                    </Grid>
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item display={"flex"} flex={1}>
                        <DataTableComponent
                           loading={loading}
                           onRowClick={(params) => {
                              //console.log(params.row)
                              dispatch({
                                 type: LocksActions.setLockPropertyId,
                                 payload: params.row.lockPropertyID,
                              });
                              navigate(`/lockdetails/${params.row.lockKey}`);
                           }}
                           apiRef={apiRef}
                           getRowID={(params) => params.id}
                           items={(locks && locks.length) > 0 ? locks : []}
                           selected={selected}
                           setSelected={setSelected}
                           columns={gridColumns}
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "ListingsMain" })((theme) => ({
   mainContainer: {},
   firstRow: {},
   secondRow: {},
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "38px",
      letterSpacing: ".25px",
      fontWeight: "bold",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      marginTop: "-15px",
      fontWeight: "bold",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: "bold",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "7px",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
      position: "relative",
      bottom: "7px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   listingsCellAddress: {
      color: "#707070",
      fontSize: "14px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   createdDate: {
      color: "#707070",
      fontSize: "14px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "14px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   startIcon: {
      paddingBottom: "2px",
      justifySelf: "center",
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      minHeight: "200px",
      minWidth: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
}));
