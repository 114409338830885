import React from "react";
import { Grid, TextField, Select, MenuItem } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import axios from "axios";
import { ConstructionOutlined, ErrorRounded } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { ReactHookFormSelect } from "../../../common/react.hook.form.select";
export const ListingDetailsForm: React.FC<{
  setActive?: (value: boolean) => void;
}> = ({ setActive }) => {
  const { classes } = useStyles();
  const [subPropertyType, setSubPropertyType] = useState("");
  const [parkingType, setParkingType] = useState("");
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const propertyCreateTypeId = watch("propertyCreateTypeId");

  const methods = useFormContext();
  const contextPrice = methods.watch("price");
  const [propertyPrice, setPropertyPrice] = useState("");
  useEffect(() => {
    methods.setValue("price", propertyPrice);
  }, [propertyPrice]);
  useEffect(() => {
    setPropertyPrice(contextPrice);
  }, [contextPrice]);

  useEffect(() => {
    //console.log(errors);
  }, [errors]);

  return (
    <>
      <Grid
        container
        direction={"column"}
        display="flex"
        gap={"15px"}
        sx={{ marginTop: "10px" }}
      >
        <Grid item>
          <div className={classes.formHeader}>Listing Details</div>
        </Grid>
        <Grid item>
          <div className={classes.inputHeader}>Price*</div>
          <Grid
            container
            direction="row"
            gap={"3px"}
            alignItems="flex-end"
            display={"flex"}
          >
            <Grid item>
              <span style={{ fontSize: "24px" }}>$</span>
            </Grid>
            <Grid item>
              <TextField
                {...register("price")}
                className={errors.price ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                placeholder="0"
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item>
              {propertyCreateTypeId === "rental" ? (
                <span style={{ fontSize: "18px" }}>/ month</span>
              ) : null}
            </Grid>
            <Grid item className={classes.errorText} sx={{ paddingLeft: '7px'}}>
                {errors.price ? String(errors.price.message) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            width={"85%"}
            display={"flex"}
            alignItems="flex-end"
            gap={"15px"}
          >
            <Grid item flex={2}>
              <div className={classes.inputHeader}>Address*</div>
              <TextField
                {...register("address")}
                className={errors.address ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                placeholder="Street Address"
                fullWidth
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>Apt/Unit</div>
              <TextField
                {...register("addressLine2")}
                className={errors.addressLine2 ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                placeholder="Apt/Unit"
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            width={"85%"}
            alignItems="flex-end"
            gap={"10px"}
          >
            <Grid item flex={1}>
              <div className={classes.inputHeader}>City*</div>
              <TextField
                {...register("city")}
                className={errors.city ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                placeholder="City"
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>State*</div>
              <TextField
                {...register("state")}
                className={errors.state ? classes.errorBorder : ""}
                placeholder="State"
                variant="standard"
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>Zip Code*</div>
              <TextField
                {...register("zipCode")}
                className={errors.zipCode ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                placeholder="Zip"
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            width={"85%"}
            maxWidth={"85%"}
            alignItems="flex-end"
            gap={"10px"}
          >
            <Grid item flex={1}>
              <div className={classes.inputHeader}>Property Type*</div>
              <ReactHookFormSelect
                name="propertyTypeId"
                control={control}
                sx={{ width: "80%" }}
              >
                <MenuItem value="single-family">Single Family</MenuItem>
                <MenuItem value="multi-family">Multi-Family</MenuItem>
                <MenuItem value="townhome">Townhome</MenuItem>
                <MenuItem value="apartment">Apartment</MenuItem>
              </ReactHookFormSelect>
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>{`Lot Size (Acres)`}*</div>
              <TextField
                placeholder="0"
                {...register("lotSize")}
                className={errors.lotSize ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>Square Ft*</div>
              <TextField
                placeholder="0"
                {...register("sqFoot")}
                className={errors.sqFoot ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            width={"85%"}
            maxWidth="85%"
            alignItems="flex-end"
            gap={"10px"}
          >
            <Grid item flex={0.5}>
              <div className={classes.inputHeader}>Bathrooms*</div>
              <TextField
                placeholder="0"
                {...register("bathrooms")}
                className={errors.bathrooms ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                fullWidth
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={0.5}>
              <div className={classes.inputHeader}>Bedrooms*</div>
              <TextField
                placeholder="0"
                {...register("bedrooms")}
                className={errors.bedrooms ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                fullWidth
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>Type of Parking*</div>
              <ReactHookFormSelect
                control={control}
                name="parkingTypeId"
                sx={{ width: "80%" }}
              >
                <MenuItem value="dedicated-garage">Dedicated Garage</MenuItem>
                <MenuItem value="on-street">On-Street</MenuItem>
                <MenuItem value="none">None</MenuItem>
              </ReactHookFormSelect>
              {/* <Select variant='standard' value={parkingType} onChange={(e: any) => { setParkingType(e.target.value) }} disableUnderline sx={{ width: '80%' }}>
                                <MenuItem value='Dedicated Garage'>Dedicated Garage</MenuItem>
                                <MenuItem value="On-Street">On-Street</MenuItem>
                                <MenuItem value="None">None</MenuItem>

                            </Select> */}
            </Grid>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>Nbr of spaces*</div>
              <TextField
                placeholder="0"
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                {...register("parkingSpace")}
                className={errors.parkingSpace ? classes.errorBorder : ""}
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          maxWidth={"85%"}
          width={"84%"}
          display="flex"
          sx={{ marginBottom: "10px" }}
        >
          <Grid container direction={"row"} display="flex" width={"100%"}>
            <Grid item flex={1}>
              <div className={classes.inputHeader}>
                {`Property Headline* (75 Character Limit)`}
              </div>
              <TextField
                placeholder="Ex. Large family home in rural neighborhood..."
                {...register("propertyHeadline")}
                className={errors.propertyHeadline ? classes.errorBorder : ""}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  maxLength: 75,
                  style: {
                    paddingLeft: "5px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                }}
                fullWidth
                multiline
                rows={2}
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="small"
              />
              {errors.propertyHeadline && (
                <p className={classes.errorText}>
                  {String(errors.propertyHeadline.message)}
                </p>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Listing-Details-Form" })((theme) => ({
  mainContainer: {},
  formHeader: {
    fontSize: "24px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
    letterSpacing: ".35px",
  },
  filledInput: {
    background: "#EAEAEA",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  errorBorder: {
    border: "2px solid red",
  },
}));
