import react from "react";
import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import defaultTourImage from "../../styles/assets/images/defaultTourImage@2x.png";

interface Tour {
  propertyImageUrl: string;
  propertyName: string;
  propertyAddress: string;
  startTime: string;
  endTime: string;
}

// function formatTimeWithoutSeconds(time: string | number | Date) {
//   const date = new Date(time);
//   const hours = date.getHours();
//   const minutes = date.getMinutes();

//   // Use template literals to format the time
//   return `${hours}:${minutes}`;
// }

function returnStandardTimeString(time: string) {
  let timeArray = time.split(":"); // convert to array

  // fetch
  var hours = Number(timeArray[0]);
  var minutes = Number(timeArray[1]);

  // calculate
  var timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours === 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
  timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

  return timeValue;
}

function TourElement(props: Tour) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {}, []);

  return (
    <>
      <section className={classes.TourELementContainer}>
        {/* Img name and adderss */}
        <div className={classes.TourElementSideContainer}>
          <img
            className={classes.TourElementImage}
            src={
              props.propertyImageUrl
                ? `https://boxlty-media.s3.amazonaws.com/property-images/${props.propertyImageUrl}`
                : defaultTourImage
            }
            alt="property thumbnail"
          />
          <div className={classes.TourElementInfo}>
            <p className={classes.TourelementName}>{props.propertyName}</p>
            <p className={classes.TourelementAddress}>
              {props.propertyAddress}
            </p>
          </div>
        </div>
        {/* Scheduled Time */}
        <div className={classes.TourElementSideContainer}>
          <p className={classes.TourElmentScheduledTime}>
            {returnStandardTimeString(props.startTime) +
              " - " +
              returnStandardTimeString(props.endTime)}
          </p>
        </div>
      </section>
    </>
  );
}

const useStyles = makeStyles({ name: "TourElement" })((theme) => ({
  TourELementContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px",
    paddingLeft: "0px",
  },
  TourElementSideContainer: {
    display: "flex",
    alignItems: "center",
  },
  TourElementImage: {
    height: "50px",
    width: "50px",
    objectFit: "fill",
    borderRadius: "10px",
    marginRight: "15px",
  },
  TourElementInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  TourelementName: {
    margin: 0,
    marginBottom: "5px",
    color: theme.palette.common.black,
    fontSize: "13px",
  },
  TourelementAddress: {
    margin: 0,
    color: theme.palette.common.black,
    opacity: 0.5,
    fontSize: "12px",
  },
  TourElmentScheduledTime: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "center",
  },
}));

export default TourElement;
