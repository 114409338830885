import React from 'react'
import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid } from '@mui/material'
import { INavElement } from './nav.types'
import { mainTheme } from '../../../styles/mainTheme'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import { SportsRugbySharp } from '@mui/icons-material'
import { useNavState, useNavDispatch } from '../../../contexts/nav/nav.context'

export const MainNavItem: React.FC<INavElement> = ({ icon, activeIcon, display, url, external }) => {
    const location = useLocation()
    const [active, setActive] = useState(false);
    const { open } = useNavState()
    const { classes } = useStyles({ "bgColor": active ? '#F5F6F6' : 'black', "opacity": active ? '100%' : "30%", "titleColor": active ? "black" : "#FFFFFF", "active": active, "open": open })
    const navigate = useNavigate()
    const dispatch = useNavDispatch()
    const path = location.pathname.split("/");

    const navItemClickHandler = () => {
        if (external) {
            window.open(url, '_blank')
        } else {
            navigate(url)
        }
    }


    useEffect(() => {
        //console.log(location)
        //console.log(path)
    }, [location])

    

    useEffect(() => {
        if (`/${path[1]}` === url) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [location.pathname])


    return (
        <Grid item className={classes.mainContainer} onClick={navItemClickHandler} >
            <Grid container direction='row' className={classes.subContainer} >
                    <Grid item sx={{ color: active ? '#D44293' : '#A2A2A2', maxWidth: '17px', maxHeight: '24px' }}>
                        {icon}
                    </Grid>
                    <Grid item className={classes.title}  >
                        <div>{display}</div>
                    </Grid>
            </Grid>
        </Grid>
    )
}



const useStyles = makeStyles<{ bgColor: string, opacity: string, titleColor: string, active: boolean, open: boolean }>({ name: "NavItem" })((theme, { bgColor, opacity, titleColor, active, open }) => ({
    mainContainer: {
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    subContainer: {
        alignItems: 'center',
        justifyContent: 'left',
        gap: '22px',
        display: 'flex',
        backgroundColor: bgColor,
        borderRadius: '25px 0px 0px 25px',
        padding: '10px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: '20px',
        flexWrap: 'nowrap',
        '&:hover': {
            backgroundColor: active ? '#F5F6F6' : 'rgba(245, 246, 246, 0.3)',
            cursor: 'pointer',

        },

    },
    title: {
        color: titleColor,
        opacity: '100%',
        fontWeight: 'bold',
        fontFamily: theme.fonts.secondary.bold,
        fontSize: '24px',
        visibility: open ? 'visible' : 'hidden',
        

    }
}))