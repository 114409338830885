import React from "react";
import { Grid, IconButton, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TeamTabForm } from "../forms/team.tab.form";
import { HeaderButton } from "../forms/header.button";
import { User } from "../forms/user.select.component";
import { useState, useEffect } from "react";
import { UserProfileComponent } from "../forms/user.profile.component";
import { UserSelectComponent } from "../forms/user.select.component";
import testAvatar1 from "../../../styles/assets/images/test-avatar.jpeg";
import testAvatar2 from "../../../styles/assets/images/test-avatar3.jpeg";
import { kebabCase } from "lodash";
import {
   getUserInviteList,
   getUserTeamMembers,
   getUserProfile,
   getListingFull,
   getTeamMembersAdmin,
} from "../../../../contexts/api/api.functions";

import { TeamMemberTeamTab, Invite, TeamMember } from "../forms/forms.types";
import { getPropertyInviteList } from "../../../../contexts/api/api.functions";
import {
   CreateListingActions,
   useCreateListingDispatch,
   useCreateListingState,
} from "../../../../contexts/create-listing/create-listing.context";
import { useUserState } from "../../../../contexts/user/user.context";
export const ListingsTeamsTab: React.FC<{}> = ({}) => {
   const { classes } = useStyles();
   const [selectedUser, setSelectedUser] = useState<TeamMemberTeamTab | null>(
      null
   );
   const [assigned, setAssigned] = useState<TeamMember[]>([]);
   const [users, setUsers] = useState<TeamMemberTeamTab[]>([]);
   const [invites, setInvites] = useState<Invite[]>([]);
   const { propertyId } = useCreateListingState();
   const { user } = useUserState();
   const dispatch = useCreateListingDispatch();

   useEffect(() => {
      dispatch({
         type: CreateListingActions.setFeature,
         payload: { file: "" },
      });
      dispatch({ type: CreateListingActions.setPhotos, payload: [] });
   }, []);

   useEffect(() => {
      dispatch({
         type: CreateListingActions.setFeaturePhotoPath,
         payload: null,
      });
      dispatch({ type: CreateListingActions.setPhotoPaths, payload: [] });
   }, []);

   const grabTeamMembersList = async () => {
      if (user && user.accountType) {
         if (
            (user.accountType === 5 && user.isSuperAdmin === 1) ||
            user.isPrimaryOwner === 1
         ) {
            const res = await getTeamMembersAdmin();
            if (res && res.data && res.data.status === true) {
               // console.log(res.data.result);

               if (user && user.userId) {
                  const users = res.data.result.members.filter(
                     (member: any) => member.userId !== user.userId
                  );
                  setUsers([...users]);
               }
            } else {
               // console.log("could not fetch users");
            }
         } else if (
            user.accountType === 5 &&
            user.isPrimaryOwner === 1 &&
            user.isEnterpriseSuperUser === 0
         ) {
            const res = await getUserTeamMembers();
            if (res && res.data && res.data.status === true) {
               // console.log(res.data.result);

               if (user && user.userId) {
                  const users = res.data.result.members.filter(
                     (member: any) =>
                        member.userId !== user.userId &&
                        member.isPrimaryManager !== "1"
                  );
                  setUsers([...users]);
               }
            } else {
               // console.log("could not fetch users");
            }
         } else {
            const res = await getUserTeamMembers();
            if (res && res.data && res.data.status === true) {
               // console.log(res.data.result);

               if (user && user.userId) {
                  const users = res.data.result.members.filter(
                     (member: any) =>
                        member.userId !== user.userId &&
                        member.isPrimaryManager !== "1"
                  );
                  setUsers([...users]);
               }
            } else {
               // console.log("could not fetch users");
            }
         }
      }
   };

   const grabPropertyInviteList = async () => {
      // console.log("calling get property invite list")
      if (propertyId) {
         const res = await getPropertyInviteList(propertyId);
         if (res && res.data && res.data.status === true) {
            const filteredArr = res.data.result.filter(
               (invite: any) => invite.isUserInvitedForThisProperty === 1
            );
            setInvites([...filteredArr]);
         } else {
            // console.log("could not fetch invites");
         }
      } else {
         // console.log("No Property Id")
      }
   };

   const grabAssignedMembersList = async () => {
      if (propertyId) {
         const res = await getListingFull(parseInt(propertyId));
         console.log(res);
         if (res && res.data && res.data.status === true) {
            if (
               res.data.result.PropertyDetail &&
               res.data.result.PropertyDetail.teamMembers &&
               res.data.result.PropertyDetail.teamMembers.length > 0
            ) {
               const assignedMembers: TeamMember[] =
                  res.data.result.PropertyDetail.teamMembers.map(
                     (user: any) => {
                        return {
                           userId: user.userId,
                           fullName: user.userName,
                           email: user.userEmail,
                           phone: user.userMobile,
                           profileImage: user.userProfile,
                        };
                     }
                  );
               setAssigned([...assignedMembers]);
            }
         }
      }
   };

   useEffect(() => {
      grabTeamMembersList();
      grabPropertyInviteList();
      grabAssignedMembersList();
   }, []);

   const setUserForProfileView = (id: string) => {
      // console.log(id);
      if (users) {
         const user = users.find((user) => user.userId.toString() === id);
         if (user) {
            setSelectedUser(user);
         }
      } else {
         return;
      }
   };

   return (
      <>
         <Grid container direction="column" className={classes.mainContainer}>
            <Grid item>
               <Grid
                  container
                  direction="row"
                  display={"flex"}
                  justifyContent="space-between"
                  // Only need this margin if menu icon button is hidden
                  sx={{ marginBottom: "20px" }}
               >
                  <Grid item>
                     <HeaderButton
                        color="black"
                        backgroundColor="white"
                        borderColor="#707070"
                        text="DRAFT"
                        clickable={true}
                     />
                  </Grid>
                  {/* Removed in create listing, still in edit listing */}
                  {/* <Grid item>
              <IconButton edge="start" disabled>
                <MoreHorizIcon fontSize="large" />
              </IconButton>
            </Grid> */}
               </Grid>
            </Grid>
            <Grid item>
               <Grid
                  container
                  direction={"row"}
                  display="flex"
                  justifyContent={"space-between"}
               >
                  <Grid item flex={1.1}>
                     {/* Team Tab Form */}
                     <TeamTabForm
                        users={users}
                        assignedMembers={assigned}
                        grabAssignedMembersList={grabAssignedMembersList}
                        grabTeamMemberList={grabTeamMembersList}
                        getInviteList={grabPropertyInviteList}
                        setSelectedUserForProfile={setUserForProfileView}
                        invites={invites}
                     />
                  </Grid>
                  <Grid item flex={1}>
                     <UserProfileComponent user={selectedUser} />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Team-Tab" })((theme) => ({
   mainContainer: {
      paddingTop: "20px",
      paddingLeft: "15px",
      paddingRight: "10px",
      paddingBottom: "15px",
   },
   inputHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginBottom: "7px",
      textTransform: "uppercase",
   },
}));
