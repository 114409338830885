import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import TourDetailsPage from "./non-enterprise/tours.details";
import LenderTourDetailsPage from "./lender/lender.tours.details";
import TourPrimaryManagerAdminDetailsPage from "./non-lender/tours.pm.admin.details";
export const TourViewDetailsSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         return <TourPrimaryManagerAdminDetailsPage />;
      //// Enterprise Lender
      case 6:
         if (user?.isEnterpriseSuperUser == 1) {
            return <LenderTourDetailsPage />;
         }
         return <TourDetailsPage />;

      //// Non-Enterprise
      default:
         return <TourDetailsPage />;
   }
};
