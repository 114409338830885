import React from "react";
import { makeStyles } from "tss-react/mui";
import { Autocomplete, TextField, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import {
   SingleListingActions,
   useSingleListingDispatch,
} from "../../../contexts/single-listing/singleListing.context";

type IInputSearchProps = {
   items: any[];
};

export const ListingsPageSearchInput: React.FC<IInputSearchProps> = ({
   items,
}) => {
   const { classes } = useStyles();
   const navigate = useNavigate();
   const singleListingDispatch = useSingleListingDispatch();

   const filterOptions = createFilterOptions({
      matchFrom: "any",
      stringify: (option: any) => option.nickName + " " + option.address,
   });

   const dataHandleRowClick = (listingId: number, favoriteId: number) => {
      singleListingDispatch({
         type: SingleListingActions.setPropertyId,
         payload: listingId,
      });
      singleListingDispatch({
         type: SingleListingActions.setFavoriteId,
         payload: favoriteId,
      });
      navigate(`/listings/listing/${listingId}/general/photos`);
   };

   return (
      <>
         <Autocomplete
            options={items}
            getOptionLabel={(items) => items.nickName}
            filterOptions={filterOptions}
            renderOption={(props, option, index) => {
               return (
                  <li
                     {...props}
                     key={`${index}-${option.id}`}
                     onClick={() => {
                        dataHandleRowClick(
                           Number(option.id),
                           Number(option.id)
                        );
                     }}
                  >
                     <Grid item display={"flex"} flexDirection={"column"}>
                        <span
                           style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              overflowX: "auto",
                              maxWidth: "175px",
                              letterSpacing: "0.45px",
                           }}
                        >
                           {`${option.nickName}` ?? "Property"}
                        </span>
                        <span className={classes.emailText}>
                           {option.address}
                        </span>
                     </Grid>
                  </li>
               );
            }}
            renderInput={(params) => (
               <TextField
                  {...params}
                  placeholder="Search Listings"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                     ...params.InputProps,
                     startAdornment: (
                        <SearchIcon style={{ color: "#a2a2a2" }} />
                     ),
                  }}
                  sx={{
                     "&.MuiTextField-root": { borderRadius: "25px" },
                     "&:.MuiTextField-input": { borderRadius: "26px" },
                  }}
               />
            )}
            sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: "26px" } }}
         />
      </>
   );
};

const useStyles = makeStyles({})((theme) => ({
   root: {},
   emailText: {
      color: "#707070",
      fontSize: "14px",
   },
}));
