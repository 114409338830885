import React from "react";
import { makeStyles } from "tss-react/mui";
import {
  Autocomplete,
  OutlinedInput,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import { classnames } from "tss-react/tools/classnames";
import { TeamMember } from "../../contexts/team/team.context.types";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Navigate, useNavigate } from "react-router-dom";

type IInputSearchProps = {
  items: TeamMember[];
};

export const TeamPageSearchInput: React.FC<IInputSearchProps> = ({ items }) => {
  const [searchValue, setSearchValue] = useState<TeamMember | null>(null);
  const { classes } = useStyles();
  const navigate = useNavigate();

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: TeamMember) => option.firstName + " " + option.lastName + " " + option.email, //TODO email filter
  });

  return (
    <>
      <Autocomplete
        options={items}
        filterOptions={filterOptions}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.userId}
              onClick={() => {
                navigate(`/team/${option.userId}/details`);
              }}
            >
              <Grid item display={"flex"} flexDirection={"column"}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    overflowX: "auto",
                    maxWidth: "175px",
                    letterSpacing: "0.45px",
                  }}
                >
                  {`${option.firstName} ${option.lastName}` ?? "John Doe"}
                </span>
                <span className={classes.emailText}>
                  {option.email}
                </span>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Team"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon style={{ color: "#a2a2a2" }} />,
            }}
            sx={{
              "&.MuiTextField-root": { borderRadius: "25px" },
              "&:.MuiTextField-input": { borderRadius: "26px" },
            }}
          />
        )}
        sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: "26px" } }}
      />
    </>
  );
};

const useStyles = makeStyles({})((theme) => ({
  root: {},
  emailText: {
    color: "#707070",
    fontSize: "14px",
  },
}));
