import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Divider, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  activateProperty,
  deleteProperty,
  makePropertyDraft,
} from "../../../../contexts/api/api.functions";
import {
  SingleListingActions,
  useSingleListingDispatch,
  useSingleListingState,
} from "../../../../contexts/single-listing/singleListing.context";
import { CreatedAdvancedNoticeForm } from "../forms/created.advanced.notice.form";
import { AvailabilityTabTopForm } from "../../create/forms/availability.tab.top.form";
import { CustomAvailabilityForm } from "../../create/forms/custom.availability.form";
import { HeaderButton } from "../../create/forms/header.button";
import { useUserState } from "../../../../contexts/user/user.context";
type Props = {};

export interface TimeSlot {
  start: number;
  end: number;
}

export const CreatedListingAvailabilityTab: React.FC<{
  setActive: (value: boolean) => void;
}> = ({ setActive }) => {
  const { classes } = useStyles();
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [intervalSet, setIntervalSet] = useState(false);
  const { setValue, watch } = useFormContext();
  const { user } = useUserState();
  const [difference, setDifference] = useState(0);
  const [timeSlotArr, setTimeSlotArr] = useState<TimeSlot[]>([]);
  const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
  const { listingType } = useSingleListingState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useSingleListingDispatch();
  const navigate = useNavigate();
  const contextTimeInterval = watch("timeInterval");
  const contextUnavailableDays = watch("unavailableDates");
  const setStartTime = (value: any) => {
    setStart(value);
  };

  const setEndTime = (value: any) => {
    setEnd(value);
  };

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch({ type: SingleListingActions.setFeature, payload: { file: "" } });
    dispatch({ type: SingleListingActions.setPhotos, payload: [] });
  }, []);

  useEffect(() => {
    dispatch({ type: SingleListingActions.setFeaturePhotoPath, payload: null });
    dispatch({ type: SingleListingActions.setPhotoPaths, payload: null });
  }, []);

  const calculateHours = () => {
    if (!start || !end) {
      return;
    }
    let startHour = dayjs(start).get("hour");
    let endHour = dayjs(end).get("hour");
    let timeSlots: TimeSlot[] = [];
    for (var i = startHour; i < endHour; i++) {
      const timeSlot: TimeSlot = { start: i, end: i + 1 };
      timeSlots.push(timeSlot);
    }
    setTimeSlotArr([...timeSlots]);
  };

  const handleDelete = async () => {
    if (ListingIdParam === undefined) {
      return;
    } else {
      const res = await deleteProperty({ pId: parseInt(ListingIdParam) })
        .then(() => {
          navigate("/listings/active");
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  };

  const handleArchive = async () => {
    if (ListingIdParam === undefined) {
      return;
    } else {
      if (!listingType || listingType === "") {
        return;
      }
      if (listingType === "archive") {
        handleMenuClose();
        return;
      }
      const res = await activateProperty({
        pId: parseInt(ListingIdParam),
        status: 0,
      })
        .then(() => {
          navigate("/listings/archived");
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  const handleDraft = async () => {
    if (ListingIdParam === undefined) {
      return;
    } else {
      if (!listingType || listingType === "") {
        return;
      }
      if (listingType === "draft") {
        handleMenuClose();
        return;
      }
      const res = await makePropertyDraft({ pId: parseInt(ListingIdParam) })
        .then(() => {
          navigate("/listings/draft");
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  useEffect(() => {
    calculateHours();
  }, [start, end]);

  useEffect(() => {
    if (
      contextTimeInterval &&
      contextTimeInterval.length >= 2 &&
      !intervalSet
    ) {
      let start = dayjs(new Date())
        .set("hour", 7 + contextTimeInterval[0])
        .minute(0)
        .second(0);
      setStartTime(start);
      let end = dayjs(new Date())
        .set("hour", 8 + contextTimeInterval[contextTimeInterval.length - 1])
        .minute(0)
        .second(0);
      setEndTime(end);
      setIntervalSet(true);
    }
  }, [contextTimeInterval, intervalSet]);

  return (
    <>
      <Grid container direction="column" className={classes.mainContainer}>
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            justifyContent="space-between"
            // Only need this margin if menu icon button is hidden
            // sx={{ marginBottom: '20px'}}
          >
            <Grid item>
              <HeaderButton
                color="black"
                backgroundColor="white"
                borderColor="#707070"
                text={listingType !== "" ? listingType : "draft"}
                clickable={false}
              />
            </Grid>

            <Grid item sx={{ paddingRight: "5px" }}>
              <IconButton
                edge="start"
                id="type-selection-button"
                aria-controls={open ? "type-selection-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreHorizIcon fontSize="large" />
              </IconButton>
              <Menu
                id="type-selection-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{ "aria-labelledby": "type-selection-button" }}
                sx={{ postion: "relative", right: "60px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: 95,
                }}
              >
                <MenuItem onClick={handleDraft}>Draft</MenuItem>
                {user?.idVerify === 1 ? (
                  <MenuItem onClick={handleArchive}>Archive</MenuItem>
                ) : null}
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {/* Availability Tab Top Form */}
          <AvailabilityTabTopForm
            setActive={setActive}
            setStart={setStartTime}
            setEnd={setEndTime}
            start={start}
            end={end}
          />
        </Grid>
        <Grid item sx={{ marginTop: "10px" }}>
          <Divider sx={{ borderBottomWidth: "2px" }} />
        </Grid>
        <Grid item sx={{ marginTop: "25px" }}>
          {/* Custom Availability Form */}
          <CustomAvailabilityForm
            timeSlots={timeSlotArr}
            setActive={setActive}
          />
        </Grid>
        <Grid item sx={{ position: "relative", bottom: "25px" }}>
          <Divider sx={{ borderBottomWidth: "2px" }} />
        </Grid>
        <Grid item>
          <CreatedAdvancedNoticeForm setActive={setActive} />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Availability-Tab" })((theme) => ({
  mainContainer: {
    paddingTop: "20px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "15px",
    overflowY: "auto",
    flexWrap: "nowrap",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
  },
}));
