import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Avatar, IconButton } from '@mui/material'
import { User } from './user.select.component'
import TestAvatar from '../../../../styles/assets/images/test-avatar.jpeg'
import { useTheme } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react'
import { Invite } from './forms.types'
import { removeAssignedTeamMember, removeInvite } from "../../../../contexts/api/api.functions";
import { ReactComponent as CancelIcon} from '../../../../styles/assets/logos/cancel-icon.svg'
export const PendingInviteComponent: React.FC<{ invite: Invite, handleRemove: (email: string) => void}> = ({ invite, handleRemove }) => {
    const { classes } = useStyles()
    const theme = useTheme()
    const [showCancel, setShowCancel] = useState(false)

    return (
        <>
            <Grid container direction={'row'} alignItems='center' justifyContent={'space-between'} display='flex' className={classes.mainContainer} onMouseEnter={() => { setShowCancel(true)}} onMouseLeave={() => { setShowCancel(false)}}>
                <Grid item >
                    <Grid container direction={'row'} display='flex'>
                        <Grid item sx={{ paddingRight: '10px' }}>
                            <Avatar sx={{ fontSize: '12px', backgroundColor: theme.palette.common.darkGray}}>
                                SENT
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Grid container direction={'column'}>
                                <Grid item className={classes.name}>
                                    <span style={{ marginRight: '5px' }}>{invite.firstName}</span>
                                    <span>{invite.lastName}</span>
                                </Grid>
                                <Grid item>
                                    <span>{invite.email}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <span style={{ borderRadius: '50%', backgroundColor: 'white', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', visibility: showCancel ? 'visible' : 'hidden', }} onClick={() => { handleRemove(invite.email)}}>
                        <CloseOutlinedIcon fontSize='small' style={{ color:'red'}} />
                    </span>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles({ name: "user-invite-component" })((theme) => ({
    mainContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '5px',
        width: '90%',
        height: '65px',
        textOverflow: 'ellipsis',
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: '5px',
        '&:hover': {
            backgroundColor: '#EAEAEA',
            cursor: 'pointer'
        }
    },
    name: {
        fontSize: '18px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
    },
    
}))