import React from "react";
import {
  TextField,
  Button,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTheme } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { useState, useEffect } from "react";
import { ListingDetailsForm } from "../forms/listing.details.form";
import { PropertyDetailsForm } from "../forms/property.details.form";
import { AccessExitForm } from "../forms/access.exit.instructions.form";
import { HeaderButton } from "../forms/header.button";
import { useFormContext } from "react-hook-form";
import { ReactHookFormSelect } from "../../../common/react.hook.form.select";
import {
  useCreateListingState,
  useCreateListingDispatch,
  CreateListingActions,
} from "../../../../contexts/create-listing/create-listing.context";

export const ListingsGeneralTab: React.FC<{}> = ({}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [listingType, setListingType] = useState("");
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const utilities = watch("utilities");
  const dispatch = useCreateListingDispatch();
  const contextNickname = watch("nickName");
  const petPolicyDescription = watch("petPolicyDescription");

  useEffect(() => {
    dispatch({ type: CreateListingActions.setFeature, payload: { file: "" } });
    dispatch({ type: CreateListingActions.setPhotos, payload: [] });
  }, []);

  useEffect(() => {
    dispatch({ type: CreateListingActions.setFeaturePhotoPath, payload: null });
    dispatch({ type: CreateListingActions.setPhotoPaths, payload: [] });
  }, []);

  return (
    <>
      <Grid
        container
        className={classes.mainContainer}
        direction="column"
        display={"flex"}
        flexWrap="nowrap"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            justifyContent="space-between"
            // Only need this margin if menu icon button is hidden
            sx={{ marginBottom: "20px" }}
          >
            <Grid item>
              <HeaderButton
                color="black"
                backgroundColor="white"
                borderColor="#707070"
                text="DRAFT"
                clickable={false}
              />
            </Grid>
            {/* Removed in create listing, still in edit listing */}
            {/* <Grid item sx={{ paddingRight: "5px" }}>
              <IconButton edge="start" disabled>
                <MoreHorizIcon fontSize="large" />
              </IconButton>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={"column"}
            sx={{
              display: "flex",
              height: "700px",
              flexWrap: "nowrap",
              overflowY: "scroll",
            }}
          >
            <Grid item sx={{ marginBottom: "10px", width: "85%" }}>
              <div className={classes.inputHeader}>Property Nickname*</div>
              <TextField
                {...register("nickName")}
                className={errors.nickName ? classes.errorBorder : ""}
                fullWidth
                onKeyUp={(e) => {
                  dispatch({
                    type: CreateListingActions.setNickName,
                    payload: contextNickname,
                  });
                }}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                  maxLength: 30,
                }}
                placeholder="Property Nickname"
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="medium"
              />
              {errors.nickName && (
                <p className={classes.errorText}>
                  {String(errors.nickName.message)}
                </p>
              )}
            </Grid>

            <Grid item>
              <div className={classes.inputHeader}>Listing Type*</div>
              <ReactHookFormSelect
                control={control}
                name="propertyCreateTypeId"
                disableUnderline={true}
                variant="standard"
              >
                <MenuItem value="rental">Rental</MenuItem>
                <MenuItem value="sale">For Sale</MenuItem>
              </ReactHookFormSelect>
            </Grid>
            <Divider
              sx={{
                borderBottomWidth: "2.5px",
                marginTop: "10px",
                width: "85%",
              }}
            />
            <Grid item>
              {/* Listing Details Form */}
              <ListingDetailsForm />
            </Grid>
            <Divider
              sx={{
                borderBottomWidth: "2.5px",
                marginTop: "10px",
                width: "85%",
              }}
            />
            <Grid item>
              {/* Property Details Form */}
              <PropertyDetailsForm />
            </Grid>
            <Divider
              sx={{
                borderBottomWidth: "2.5px",
                marginTop: "10px",
                width: "85%",
              }}
            />
            <Grid item sx={{ paddingBottom: "15px" }}>
              {/* Access/Exit Instructions */}
              <AccessExitForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "General-Tab" })((theme) => ({
  mainContainer: {
    paddingTop: "20px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "30px",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
    letterSpacing: ".35px",
  },
  filledInput: {
    background: "#EAEAEA",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  errorBorder: {
    border: "2px solid red",
  },
}));
