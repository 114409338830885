import React, { useContext, useRef, useEffect, Dispatch, createContext } from "react";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { IAction } from "../common/storebase.types";

export interface INavState {
    open: boolean;
}

export enum NavActions {
    setOpen = "setOpen",
    setClose = "setClose"
}


const reducer = (state: INavState, action: IAction): INavState => {
    const newState = { ...state };
    switch (action.type) {
      case NavActions.setOpen:
        newState.open = true;
        break;
      case NavActions.setClose:
        newState.open = false;
        break;
      default:
        return state;
    }
  
    return newState;
  };


const initialState = {
    open: true
}

export const NavStateContext: any = createContext({})
export const NavDispatchContext: any = createContext({})
export const NavStateRefContext: any = createContext({})
export const useNavState = (): INavState => useContext(NavStateContext)
export const useNavDispatch = (): Dispatch<IAction> => useContext(NavDispatchContext)
export const useNavStateRef = (): React.MutableRefObject<INavState> => useContext(NavStateRefContext)

export const NavProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch, navStateRef] = useEnhancedReducer(
        reducer,
        initialState,
        (state) => ({ ...state })
      );

    return (
        <NavStateContext.Provider value={state}>
            <NavDispatchContext.Provider value={dispatch}>
                <NavStateRefContext.Provider value={navStateRef}>
                {children}
                </NavStateRefContext.Provider>
            </NavDispatchContext.Provider>
        </NavStateContext.Provider>
    )
}