import React from "react";
import AppBody from "../../../../components/body/main.body.component";
import { makeStyles } from "tss-react/mui";
import { Avatar, CircularProgress, Grid, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { ReactComponent as GalleryIconActive } from "../../../../styles/assets/Icons/gallery.svg";
import { ReactComponent as GalleryIconInActive } from "../../../../styles/assets/Icons/gallery-inactive.svg";
import { ReactComponent as ToursIconActive } from "../../../../styles/assets/Icons/tours-icon-active.svg";
import { ReactComponent as ToursIconInActive } from "../../../../styles/assets/Icons/tours-icon-grey.svg";
import {
   getLenderTours,
   getListing,
   getPropertyMembers,
} from "../../../../contexts/api/api.functions";
import { HeaderButton } from "../../../../components/listings/create/forms/header.button";
import TabbedContainerWithSubTabs from "../../../../components/common/boxlty.tabbedcontainer.subtabs";
import { ToursFilter1, ToursFilter2 } from "../../../../contexts/api/api.types";
import { useParams } from "react-router-dom";

type Props = {};

interface TourDetailsInfoComponent {
   propertySalePrice?: string;
   propertyAddress: string;
   propertyBedrooms?: number;
   propertyBathrooms?: number;
   propertySize?: number;
   propertyParkingSpace?: number;
   propertyId?: string;
   propertyHeadline?: string;
   generalInformation?: string;
   propertyCreateType?: string;
   //this is an int in the db -- either make api call to get string value here or in parent component
   propertyParkingType?: string;
   parkingTypeDetails?: string;
   lotSize?: number;
   listedBy?: string;
   licenseNo?: string;
   propertyCity?: string;
   propertyState?: string;
   propertyZipCode?: string;
   propertyName?: string;
   propertyStatus?: string;
}

interface ToursTabDetails {
   bookingDate: string;
   startTime: string;
   endTime: string;
   propertyName: string;
   propertyAddress: string;
   propertyFeaturePhoto: string;
}

interface PropertyMember {
   userId: number;
   firstName: string;
   lastName: string;
   userImage: string;
}

export const LenderListingDetails: React.FC<{}> = (props: Props) => {
   const { classes } = useStyles();
   const { propertyId } = useParams<{ propertyId: string }>();
   const [loading, setLoading] = useState(true);
   const [property, setProperty] = useState<TourDetailsInfoComponent | null>(
      null
   );
   const [photoIds, setPhotoIds] = useState<Array<string>>([]);
   const [propertyStatus, setPropertyStatus] = useState("");
   const [propertyMembers, setPropertyMembers] = useState<
      Array<PropertyMember>
   >([]);
   const [typeValue, setTypeValue] = useState("upcoming");
   const [upcomingTours, setUpcomingTours] = useState<Array<ToursTabDetails>>(
      []
   );
   const [completedTours, setCompletedTours] = useState<Array<ToursTabDetails>>(
      []
   );

   function returnStandardTimeString(time: string) {
      let timeArray = time.split(":"); // convert to array

      // fetch
      var hours = Number(timeArray[0]);
      var minutes = Number(timeArray[1]);

      // calculate
      var timeValue;

      if (hours > 0 && hours <= 12) {
         timeValue = "" + hours;
      } else if (hours > 12) {
         timeValue = "" + (hours - 12);
      } else if (hours === 0) {
         timeValue = "12";
      }

      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

      return timeValue;
   }

   const fetchPropertyDetail = async () => {
      if (propertyId !== "" && typeof propertyId === "string") {
         const res = await getListing(parseInt(propertyId));
         if (res.data && res.data.propertySalePrice === null) {
            res.data.propertySalePrice = 0;
         }
         if (
            res &&
            res.data &&
            res.data.status === true &&
            res.data.result.length > 0
         ) {
            setProperty(res.data.result[0].screen1[0]);
            const photos = res.data.result[0].screen2;
            const featurePhoto =
               photos.length > 0 ? [photos[0].propertyFeaturePhoto] : [];
            const propertyPhotos = photos.map(
               (item: { propertyPhoto: any }) => item.propertyPhoto
            );
            const photoIdsArray = featurePhoto.concat(propertyPhotos);
            setPhotoIds(photoIdsArray);

            const status = res.data.result[0].screen1[0].propertyStatus;
            switch (status) {
               case "1":
                  setPropertyStatus("active");
                  break;
               case "2":
                  setPropertyStatus("draft");
                  break;
               case "3":
                  setPropertyStatus("archive");
                  break;
               default:
                  break;
            }
            const res2 = await getPropertyMembers(propertyId);
            setPropertyMembers(res2.data.result.members);
            setLoading(false);
         } else {
            setLoading(false);
         }
      }
   };

   const getTourTabDetails = async () => {
      const dataAll1 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.UPCOMING,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const res1 = await getLenderTours(dataAll1);
      let data1 = res1.data.result.data;
      if (data1) {
         const tours1 = data1.filter(
            (tour: any) => tour.propertyId.toString() === propertyId
         );
         setUpcomingTours(tours1);
      }

      const dataAll4 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.PAST60DAYS,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };

      const res2 = await getLenderTours(dataAll4);
      let data2 = res2.data.result.data;
      if (data2) {
         const tours2 = data2.filter(
            (tour: any) => tour.propertyId.toString() === propertyId
         );
         setCompletedTours(tours2);
      }
   };

   useEffect(() => {
      fetchPropertyDetail();
      getTourTabDetails();
   }, [propertyId]);

   function stringWithCommas(x: string) {
      return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   return (
      <>
         <AppBody>
            <div className={classes.PageContainer}>
               {/* Header */}
               <h1 className={classes.PageHeader}>
                  {/* {props.listingName ? props.listingName : "Family Home"} */}
                  {loading
                     ? ""
                     : property?.propertyName
                     ? property.propertyName
                     : ""}
               </h1>
               {/* Split Container */}
               {loading ? (
                  <CircularProgress />
               ) : (
                  <section className={classes.SplitContainer}>
                     {/* Main Section */}
                     <section className={classes.MainSection}>
                        <div className={classes.InnerItemContainer}>
                           <div className={classes.InnerItemSideLeft}>
                              <HeaderButton
                                 color="black"
                                 backgroundColor="white"
                                 borderColor="#707070"
                                 text={propertyStatus}
                                 clickable={false}
                                 width="200px"
                              />
                              <section className={classes.PriceSection}>
                                 {/* price */}
                                 <h2 className={classes.PriceHeader}>
                                    {property?.propertySalePrice
                                       ? `$${stringWithCommas(
                                            property.propertySalePrice
                                         )}`
                                       : ""}
                                 </h2>
                                 {/* address */}
                                 <p className={classes.AddressText}>
                                    {property?.propertyAddress &&
                                       property?.propertyCity &&
                                       property?.propertyState &&
                                       property?.propertyZipCode && (
                                          <>
                                             {property.propertyAddress}
                                             <br />
                                             {`${property.propertyCity}, ${property.propertyState} ${property.propertyZipCode}`}
                                          </>
                                       )}
                                 </p>
                              </section>

                              {/* listing stats section */}
                              <section className={classes.StatsSection}>
                                 {/* listing stats baths.beds.garage.etc */}
                                 <p className={classes.StatsText}>
                                    {(property?.propertyBedrooms
                                       ? property.propertyBedrooms
                                       : "") +
                                       " Bedrooms • " +
                                       (property?.propertyBathrooms
                                          ? property.propertyBathrooms
                                          : "") +
                                       " baths • " +
                                       (property?.propertySize
                                          ? `${property.propertySize} sq. ft.`
                                          : "")}
                                 </p>

                                 {/* car garage */}
                                 <p className={classes.StatsText}>
                                    {property?.propertyParkingSpace
                                       ? `${property.propertyParkingSpace} car occupancy`
                                       : ""}
                                 </p>
                                 {/* boxlty id */}
                                 <p className={classes.StatsText}>
                                    {property?.propertyId
                                       ? `Boxlty ID: ${property.propertyId}`
                                       : ""}
                                 </p>
                              </section>

                              <div className={classes.DividerLine}></div>
                              {/* Property Title Section */}
                              <section className={classes.PropertyTitleSection}>
                                 {/* Divider */}

                                 {/* Listing Title */}
                                 <h2 className={classes.PropertyTitleText}>
                                    {property?.propertyHeadline
                                       ? property.propertyHeadline
                                       : ""}
                                 </h2>
                                 {/* Divider */}
                              </section>
                              <div className={classes.DividerLine}></div>

                              {/* Property Details Parent */}
                              <section
                                 className={classes.PropertyDetailsParent}
                              >
                                 {/* parking details section */}
                                 <section className={classes.DetailsSection}>
                                    <h4 className={classes.DetailsHeader}>
                                       Property Details
                                    </h4>
                                    <p className={classes.DetailsText}>
                                       {property?.generalInformation
                                          ? property.generalInformation
                                          : ""}
                                    </p>
                                 </section>

                                 {/* property type section */}
                                 <section className={classes.DetailsSection}>
                                    <h4 className={classes.DetailsHeader}>
                                       Property Type
                                    </h4>
                                    <p className={classes.DetailsText}>
                                       {" "}
                                       {property?.propertyCreateType
                                          ? property.propertyCreateType
                                          : ""}
                                    </p>
                                 </section>

                                 {/* parking type section*/}
                                 <section className={classes.DetailsSection}>
                                    <h4 className={classes.DetailsHeader}>
                                       Type of parking
                                    </h4>
                                    <p className={classes.DetailsText}>
                                       {" "}
                                       {property?.propertyParkingType
                                          ? property.propertyParkingType
                                          : ""}
                                    </p>
                                    <p className={classes.DetailsTextSub}>
                                       {" "}
                                       {property?.propertyParkingSpace
                                          ? `${property.propertyParkingSpace} car occupancy`
                                          : ""}
                                    </p>
                                 </section>

                                 {/* lot size section */}
                                 <section className={classes.DetailsSection}>
                                    <h4 className={classes.DetailsHeader}>
                                       Lot Size
                                    </h4>
                                    <p className={classes.DetailsText}>
                                       {(property?.propertySize
                                          ? stringWithCommas(
                                               property.propertySize.toString()
                                            )
                                          : "") + " sq. ft."}
                                    </p>
                                 </section>

                                 {/* Listed By */}
                                 <section className={classes.DetailsSection}>
                                    <p className={classes.DetailsText}>
                                       Listed by {true ? "Owner" : ""}
                                    </p>
                                 </section>

                                 {/* license number */}
                                 <section className={classes.DetailsSection}>
                                    <h4 className={classes.DetailsHeader}>
                                       License Number
                                    </h4>
                                    <p className={classes.DetailsText}>
                                       {property?.licenseNo
                                          ? property.licenseNo
                                          : ""}
                                    </p>
                                 </section>
                              </section>
                           </div>
                           <div className={classes.InnerItemSideRight}>
                              <div className={classes.PriceHeader}>
                                 Property Team
                              </div>
                              <Grid
                                 container
                                 direction={"column"}
                                 maxWidth={"100%"}
                                 gap={"5px"}
                              >
                                 {propertyMembers !== undefined ? (
                                    propertyMembers.map((member, index) => (
                                       <Grid
                                          item
                                          flexDirection={"row"}
                                          display={"flex"}
                                          alignItems={"center"}
                                       >
                                          <Avatar
                                             src={`https://boxlty-media.s3.amazonaws.com/user-profile/${member.userImage}`}
                                             style={{ marginRight: "5px" }}
                                             alt={member.firstName}
                                          />
                                          <span>
                                             {member.firstName}{" "}
                                             {member.lastName}
                                          </span>
                                       </Grid>
                                    ))
                                 ) : (
                                    <span>No property members</span>
                                 )}
                              </Grid>
                           </div>
                        </div>
                     </section>

                     <React.Fragment>
                        <section className={classes.SecondarySection}>
                           {/* Component to house those two sections */}
                           <TabbedContainerWithSubTabs
                              tabs={[
                                 {
                                    tabName: "Gallery",
                                    tabIconActive: <GalleryIconActive />,
                                    tabIconInActive: <GalleryIconInActive />,
                                    tabComponent: (
                                       <div
                                          style={{
                                             display: "flex",
                                             flexDirection: "column",
                                          }}
                                       >
                                          <h1
                                             className={
                                                classes.SecondarySectionHeader
                                             }
                                          >
                                             Gallery
                                          </h1>
                                          {photoIds.map((photoId, index) => (
                                             <img
                                                key={index}
                                                src={`https://boxlty-media.s3.amazonaws.com/property-images/${photoId}`}
                                                alt="Property"
                                                className={
                                                   classes.PhotoContainer
                                                }
                                             />
                                          ))}
                                       </div>
                                    ),
                                 },
                                 {
                                    tabName: "Tours",
                                    tabIconActive: <ToursIconActive />,
                                    tabIconInActive: <ToursIconInActive />,
                                    tabComponent: (
                                       <div
                                          className={
                                             classes.TourDetailsOuterContainer
                                          }
                                       >
                                          <Grid
                                             container
                                             direction={"column"}
                                             display={"flex"}
                                             justifyContent="space-between"
                                          >
                                             <Grid item>
                                                <div
                                                   className={
                                                      classes.TourDetailsHeaderRow
                                                   }
                                                >
                                                   <h1
                                                      className={
                                                         classes.TourDetailsInfoHeader
                                                      }
                                                   >
                                                      Tours
                                                   </h1>
                                                   <Tabs
                                                      value={typeValue}
                                                      //onChange={handleTabChange}
                                                      classes={{
                                                         root: classes.tabsRoot,
                                                         flexContainer:
                                                            classes.tabsFlex,
                                                         indicator:
                                                            classes.tabsIndicator,
                                                      }}
                                                   >
                                                      <Tab
                                                         label={`Upcoming (${upcomingTours.length})`}
                                                         disableFocusRipple
                                                         disableRipple
                                                         value="upcoming"
                                                         onClick={() => {
                                                            // console.log(
                                                            //    "upcoming tab"
                                                            // );
                                                            setTypeValue(
                                                               "upcoming"
                                                            );
                                                         }}
                                                         classes={{
                                                            root: classes.tabSingleRoot,
                                                         }}
                                                      />
                                                      <Tab
                                                         label={`Completed (${completedTours.length})`}
                                                         disableFocusRipple
                                                         disableRipple
                                                         value="completed"
                                                         onClick={() => {
                                                            // console.log(
                                                            //    "completed tab"
                                                            // );
                                                            setTypeValue(
                                                               "completed"
                                                            );
                                                         }}
                                                         classes={{
                                                            root: classes.tabSingleRoot,
                                                         }}
                                                      />
                                                   </Tabs>
                                                </div>
                                             </Grid>
                                          </Grid>
                                          <section
                                             className={
                                                classes.ListingsContainer
                                             }
                                          >
                                             {typeValue === "upcoming"
                                                ? upcomingTours.map((tour) => (
                                                     <div>
                                                        <div
                                                           className={
                                                              classes.ListingItemName
                                                           }
                                                           style={{
                                                              marginLeft:
                                                                 "15px",
                                                           }}
                                                        >
                                                           {tour.bookingDate} |{" "}
                                                           {returnStandardTimeString(
                                                              tour.startTime
                                                           )}{" "}
                                                           -{" "}
                                                           {returnStandardTimeString(
                                                              tour.endTime
                                                           )}
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingItem
                                                           }
                                                        >
                                                           <div>
                                                              <Avatar
                                                                 className={
                                                                    classes.imgPreview
                                                                 }
                                                                 variant="rounded"
                                                                 src={`https://boxlty-media.s3.amazonaws.com/property-images/${tour.propertyFeaturePhoto}`}
                                                                 sx={{
                                                                    width: "75px !important",
                                                                    height:
                                                                       "75px !important",
                                                                 }}
                                                              />
                                                           </div>
                                                           <div
                                                              className={
                                                                 classes.ListingDetails
                                                              }
                                                           >
                                                              <p
                                                                 className={
                                                                    classes.ListingItemName
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyName
                                                                 }
                                                              </p>
                                                              <p
                                                                 className={
                                                                    classes.ListingItemAddress
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyAddress
                                                                 }
                                                              </p>
                                                           </div>
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingSectionDividerLine
                                                           }
                                                        ></div>
                                                     </div>
                                                  ))
                                                : completedTours.map((tour) => (
                                                     <div>
                                                        <div
                                                           className={
                                                              classes.ListingItemName
                                                           }
                                                           style={{
                                                              marginLeft:
                                                                 "15px",
                                                           }}
                                                        >
                                                           {tour.bookingDate} |{" "}
                                                           {returnStandardTimeString(
                                                              tour.startTime
                                                           )}{" "}
                                                           -{" "}
                                                           {returnStandardTimeString(
                                                              tour.endTime
                                                           )}
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingItem
                                                           }
                                                        >
                                                           <div>
                                                              <Avatar
                                                                 className={
                                                                    classes.imgPreview
                                                                 }
                                                                 variant="rounded"
                                                                 src={`https://boxlty-media.s3.amazonaws.com/property-images/${tour.propertyFeaturePhoto}`}
                                                                 sx={{
                                                                    width: "75px !important",
                                                                    height:
                                                                       "75px !important",
                                                                 }}
                                                              />
                                                           </div>
                                                           <div
                                                              className={
                                                                 classes.ListingDetails
                                                              }
                                                           >
                                                              <p
                                                                 className={
                                                                    classes.ListingItemName
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyName
                                                                 }
                                                              </p>
                                                              <p
                                                                 className={
                                                                    classes.ListingItemAddress
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyAddress
                                                                 }
                                                              </p>
                                                           </div>
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingSectionDividerLine
                                                           }
                                                        ></div>
                                                     </div>
                                                  ))}
                                          </section>
                                       </div>
                                    ),
                                 },
                                 //Hide tab options for now not part of MVP
                                 //   {
                                 //     tabName: "Activity",
                                 //     tabIconActive: <AnalyticsIconActive />,
                                 //     tabIconInActive: <AnalyticsIconInActive />,
                                 //     tabComponent: (
                                 //       <div >
                                 //         <div >
                                 //           <h1 >
                                 //           Activity
                                 //           </h1>
                                 //         </div>

                                 //       </div>
                                 //     ),
                                 //   },
                                 //   {
                                 //     tabName: "Analytics",
                                 //     tabIconActive: <ActiviyIconActive />,
                                 //     tabIconInActive: <ActivityIconInActive />,
                                 //     tabComponent: (
                                 //       <div >
                                 //         <div >
                                 //           <h1 >
                                 //             Analytics
                                 //           </h1>
                                 //         </div>

                                 //       </div>
                                 //     ),
                                 //   },
                              ]}
                              leftBorderRadius={"20px"}
                              showBoxShadow={true}
                              startingTabIndex={0}
                           />
                        </section>
                     </React.Fragment>
                  </section>
               )}
            </div>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "LocksDetails" })((theme) => ({
   mainContainer: {},
   firstRow: {},
   secondRow: {},
   imageStyle: {
      width: "148px",
      height: "148px",
      borderRadius: "10%",
      marginTop: "20px",
      marginLeft: "20px",
   },
   textBlock: {
      marginLeft: "36px",
      display: "flex",
      flexDirection: "column",
   },
   ThreeDots: {
      cursor: "pointer",
   },
   textBlockLower: {
      marginLeft: "36px",
      display: "flex",
      flexDirection: "column",
      marginTop: "21px",
   },
   imageBlock: {
      marginTop: "20px",
      marginLeft: "36px",
   },
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "38px",
      letterSpacing: ".25px",
   },
   graySubFont: {
      color: "#707070",
      fontSize: "14px",
      fontFamily: theme.fonts.primary.reg,
      marginTop: "15px",
      marginLeft: "2px",
   },
   mainText: {
      fontSize: "18px",
      fontFamily: theme.fonts.primary.reg,
      marginTop: "7px",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      fontWeight: "bold",
      position: "relative",
      bottom: "10px",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      justifyContent: "flex-end",
      //fontWeight: "bold",
      fontSize: "11px",
      position: "relative",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "7px",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   listingsCellAddress: {
      color: "#707070",
      fontSize: "14px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "14px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   MainSection: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "70%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   MainSectionRight: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "30%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
   },
   PageContainer: {
      display: "flex",
      flexDirection: "column",
   },
   SecondarySection: {
      width: "40%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "auto",
      leftBorderRadius: "20px",
      showBoxShadow: "true",
   },
   PageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginTop: "-5px",
      marginBottom: "15px",
      fontSize: "40px",
   },
   Carrot: {
      color: "#707070",
      height: "10px",
      width: "10px",
      cursor: "pointer",
      //transition: `all ${transitionTime} linear`,
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      height: "200px",
      width: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
   },
   TourDetailsOuterContainer: {
      paddingTop: "20px",
      paddingLeft: "20px",
      marginRight: "5px",
      minHeight: "80vh",
      maxHeight: "80vh",
      overflow: "hidden",
   },
   TourDetailsHeaderRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
   },
   TourDetailsInfoHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "23px",
      whiteSpace: "nowrap",
      margin: "0px",
      marginRight: "15px",
   },
   TourDetailsOpenIcon: {
      height: "13px",
      width: "13px",
      cursor: "pointer",
   },
   PriceSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "10px",
   },
   PriceHeader: {
      fontSize: "22px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      margin: "0px",
      marginBottom: "10px",
   },
   AddressText: {
      fontSize: "18px",
      //maxWidth: "100px",
      margin: "0px",
      //fontWeight: "500",
      fontFamily: theme.fonts.primary.reg,
      marginBottom: "20px",
   },
   StatsSection: {
      marginBottom: "15px",
   },
   StatsText: {
      fontSize: "18px",
      //fontWeight: "500",
      fontFamily: theme.fonts.secondary.reg,
      margin: "0px",
      marginBottom: "2px",
   },
   DividerLine: {
      height: "2px",
      //minHeight: "0.5px",
      //maxHeight: "0.5px",
      backgroundColor: theme.palette.common.lightGray,
      width: "50%",
      marginTop: "5px",
      marginBottom: "5px",
   },
   PropertyTitleSection: {
      //marginBottom: "5px",
      //marginTop: "5px",
   },
   PropertyTitleText: {
      fontSize: "22px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      //margin: "0px",
      //marginTop: "5px",
      //marginBottom: "5px",
   },
   DetailsSection: {
      marginBottom: "20px",
      marginTop: "20px",
   },
   DetailsHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      margin: "0px",
      marginBottom: "3px",
      fontSize: "18px",
   },
   DetailsText: {
      fontSize: "16px",
      maxWidth: "90%",
      overflowY: "auto",
      margin: "0px",
      //fontWeight: "500",
      fontFamily: theme.fonts.primary.reg,
   },
   DetailsTextSub: {
      fontSize: "17px",
      maxWidth: "90%",
      overflowY: "auto",
      margin: "0px",
      //fontWeight: "100",
      marginTop: "3px",
      fontFamily: theme.fonts.primary.reg,
   },
   PropertyDetailsParent: {
      //maxHeight: "35vh",
      //overflowY: "auto",
   },
   PhotoContainer: {
      height: "165px",
      width: "80%",
      paddingLeft: "20px",
      paddingBottom: "20px",
      //paddingRight: "20px",
   },
   SecondarySectionHeader: {
      fontSize: "24px",
      marginLeft: "20px",
   },
   InnerItemContainer: {
      width: "95%",
      display: "flex",
      alignItems: "flex-start",
      paddingLeft: "10px",
      paddingTop: "15px",
      paddingRight: "10px",
   },
   InnerItemSideLeft: {
      width: "75%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
   },
   InnerItemSideRight: {
      width: "25%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
   },
   ListingsContainer: {
      marginTop: "25px",
      marginRight: "40px",
   },
   ListingItem: {
      display: "flex",
      alignItems: "center",
   },
   ListingDetails: {
      flex: 1,
      paddingLeft: "10px",
      paddingRight: "10px",
   },
   imgPreview: {
      marginTop: "3px",
      marginLeft: "15px",
   },
   ListingItemName: {
      fontSize: "18px",
      font: theme.fonts.primary.reg,
      fontWeight: "bold",
   },
   ListingItemAddress: {
      fontSize: "14px",
      font: theme.fonts.secondary.reg,
      color: theme.palette.common.medGray,
   },
   ListingSectionDividerLine: {
      width: "100%",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
      marginTop: "15px",
      marginBottom: "15px",
      marginLeft: "15px",
   },
}));
