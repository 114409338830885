import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";

interface BoxltyCheckboxProps {
   id: string;
   onClick?: (...args: any[]) => void;
   onChange?: (...args: any[]) => void;
   checked: boolean;
   disabled: boolean;
   height?: string;
   width?: string;
}

function BoxltyCheckbox(props: BoxltyCheckboxProps) {
   const theme = useTheme();
   const [checked, setChecked] = useState(props.checked);

   useEffect(() => {
      setChecked(props.checked);
      let InnerCheckbox = document.querySelector(
         "#" + props.id + "-inner"
      ) as HTMLElement;
      if (checked) {
         InnerCheckbox.style.backgroundColor = theme.palette.common.primary;
      } else {
         InnerCheckbox.style.backgroundColor = "transparent";
      }
   }, [checked, props.checked, props.id, theme.palette.common.primary]);

   function hexToRgb(hex: string) {
      // Remove the '#' symbol if present
      hex = hex.replace("#", "");

      // If the hex value is shorthand (e.g., #abc), expand it to the full format (e.g., #aabbcc)
      if (hex.length === 3) {
         hex = hex
            .split("")
            .map((char) => char + char)
            .join("");
      }

      // Extract the red, green, and blue components from the hex value
      const red = parseInt(hex.slice(0, 2), 16);
      const green = parseInt(hex.slice(2, 4), 16);
      const blue = parseInt(hex.slice(4, 6), 16);

      // Return the RGB representation as an object
      return { r: red, g: green, b: blue };
   }

   const rgbLightGray = hexToRgb(theme.palette.common.lightGray);

   function clickHandler() {
      //since set state is async and we can't pass in a callback, call on click with param !checked
      //then set checked to !checked
      if (props != null && props.onClick != null) {
         props.onClick(!checked);
      }
      //we may have to manually set the input value as well
      let input = document.querySelector("#" + props.id) as HTMLInputElement;
      input.checked = !checked;
      if (props.onChange) props.onChange();
      setChecked(!checked);
   }

   const useStyles = makeStyles({ name: "BoxltyCheckbox" })((theme) => ({
      CheckboxContainer: {
         border: "0.5px solid",
         borderColor:
            "rgba(" +
            rgbLightGray.r +
            "," +
            rgbLightGray.g +
            "," +
            rgbLightGray.b +
            ",0.9)",
         padding: "1.75px",
         borderRadius: "3px",
         height: props.height ? props.height : "9px",
         width: props.width ? props.width : "9px",
         margin: "3px",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         cursor: "pointer",
      },
      HiddenCheckbox: {
         height: "0px",
         width: "0px",
         margin: "0px",
         padding: "0px",
         visibility: "hidden",
         display: "none",
      },
      InnerCheckbox: {
         transition: "all 0.2s linear",
         backgroundColor: "white",
         height: "100%",
         width: "100%",
         borderRadius: "2px",
         marginLeft: "0.5px",
         marginTop: "0.29px",
      },
   }));

   let { classes } = useStyles();

   return (
      <div
         className={classes.CheckboxContainer}
         onClick={() => {
            if (!props.disabled) {
               clickHandler();
            }
         }}
      >
         <input
            id={props.id}
            type="checkbox"
            className={classes.HiddenCheckbox}
            defaultChecked={checked}
         />
         <div
            id={props.id + "-inner"}
            className={classes.InnerCheckbox}
            data-checked={checked}
         ></div>
      </div>
   );
}

export default BoxltyCheckbox;
