import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Alert, Snackbar, useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";
import CodeInput from "./CodeInput";
import { sendEmailVerificationCode } from "../../contexts/api/api.functions";

interface RegisterVerifyProps {
  email: string;
  defaultValue: string;
  onPreviousStep: (step: number) => void;
  onSubmitCode: (code: string) => void;
  nextStepOption1: number;
  backStepOption1: number;
  skippable: boolean; //Only for testing register, TODO remove later
  snackBarOpen: boolean;
  setSnackBarOpen: (open: boolean) => void;
}

function RegisterVerifyComponent(props: RegisterVerifyProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [code, setCode] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [clearInput, setClearInput] = useState(false);

  useEffect(() => {
    if (
      code === "" &&
      props.defaultValue !== "" &&
      props.defaultValue != null
    ) {
      let defaultValid = props.defaultValue.length === 6;
      setNextButtonDisabled(!defaultValid);
      setCode(props.defaultValue);
    }
    if (props.skippable) {
      setNextButtonDisabled(false);
    }
  }, [code, props.defaultValue]);

  async function validateCode() {
    let isValid = true;
    if (code.length === 6) {
      //some sort of api call to check the code here
      //let isValid = await verifyCode(code);
    } else {
      isValid = false;
    }
    return isValid;
  }

  async function nextStep() {
    if (await validateCode()) {
      props.onSubmitCode(code);
    }

    if (props.skippable) {
      props.onSubmitCode(code);
    }
  }

  function disableNext() {
    setNextButtonDisabled(true);
  }

  function getCode(_code: string) {
    setCode(_code);
    if (_code.length === 6) setNextButtonDisabled(false);
  }

  return (
    <section className={classes.registerVerifycontainer}>
      <Snackbar
        open={props.snackBarOpen}
        autoHideDuration={4000}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        onClose={() => {
          props.setSnackBarOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            props.setSnackBarOpen(false);
          }}
          severity="error"
          color="error"
        >
          Verification code invalid
        </Alert>
      </Snackbar>
      <h2 className={classes.registerVerifyHeader}>Enter the six digit code</h2>
      <h2 className={classes.registerVerifyHeader}>
        sent to you at: {props.email}
      </h2>
      <div className={classes.codeInputSection}>
        <CodeInput
          id="register-code-input"
          defaultValue={props.defaultValue}
          onGetCode={getCode}
          onBackspace={disableNext}
          clearValue={clearInput}
        />
        <div className={classes.spacer}></div>
        <RoundedButton
          listener={() => {
            sendEmailVerificationCode({ input: props.email });
            setResendDisabled(true);
            setClearInput(true);
          }}
          boldText={false}
          showShadow={false}
          showArrow={false}
          arrowDirection={"left"}
          color="transparent"
          text="RESEND"
          disabled={resendDisabled}
        />
      </div>
      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          disabled={nextButtonDisabled}
          listener={nextStep}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterVerifyComponent" })((theme) => ({
  registerVerifycontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },
  codeInputSection: {
    marginTop: "50px",
    marginBottom: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  spacer: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  registerVerifyHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
    margin: "0",
    textAlign: "center",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerVerifyInput: {
    border: "none",
    outline: "none",
    width: "80%",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
  },
  registerDividerLine: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.darkGray,
    marginBottom: "100px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

export default RegisterVerifyComponent;
