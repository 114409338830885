export function generateUUID() {
    let values =
        [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
            'a', 'b', 'c', 'd', 'e',
            'f', 'g', 'h', 'i', 'j',
            'k', 'l', 'm', 'n', 'o',
            'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y',
            'z'
        ];
    let UUID = '';
    values.forEach((x) => { UUID += values[Math.floor(Math.random() * (values.length - 1))].toString() });
    return UUID;
}

/**
 * Greets the given name.
 * @param {string} name - The name to greet.
 * @returns {string} A greeting message.
 */
export function cron(ms: number, callback: Function | void) {
    (() => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (callback) callback();
                resolve(null);
            }, ms ? ms : 1)
        })
    })().then(() => {
        cron(ms, callback);
    })
}

export function fadeOut(element: HTMLElement | null, timeMs: number, opacityLevel: number | void, callback: Function | void) {
    if (!element) return;
    element.animate([{ opacity: opacityLevel ? opacityLevel : 1 }, { opacity: 0 }], { duration: timeMs });
    setTimeout(() => {
        element.style.display = 'none';
        if (callback) callback();
    }, timeMs - 100)
}

export function fadeIn(element: HTMLElement | null, timeMs: number, opacityLevel: number | void, displayType: string | void, callback: Function | void) {
    if (!element) return;
    element.style.display = displayType ? displayType : 'flex';
    element.animate([{ opacity: 0 }, { opacity: opacityLevel ? opacityLevel : 1 }], { duration: timeMs });
    setTimeout(() => {
        element.style.opacity = (opacityLevel ? opacityLevel.toString() : '1')
        if (callback) callback();
    }, timeMs - 100)
}

interface position {
    top: string,
    left: string,
    right: string,
    bottom: string
}

/**
 * Smoothly sransitions an element from position a to position b
 * @param {string} name - The name to greet.
 * @returns {string} A greeting message.
 */
export function moveElement(element: HTMLElement | null, timeMs: number, startPosition: position, endPosition: position, callback: Function | void) {
    if (!element) return;
    //animate top
    if (endPosition.top != startPosition.top) element.animate([{ top: startPosition.top }, { top: endPosition.top }], { duration: timeMs });
    //animate left
    if (endPosition.left != startPosition.left) element.animate([{ left: startPosition.left }, { left: endPosition.left }], { duration: timeMs });
    //animate right
    if (endPosition.right != startPosition.right) element.animate([{ right: startPosition.right }, { right: endPosition.right }], { duration: timeMs });
    //animate bottom
    if (endPosition.bottom != startPosition.bottom) element.animate([{ bottom: startPosition.bottom }, { bottom: endPosition.bottom }], { duration: timeMs });
    setTimeout(() => {
        if (endPosition.top != startPosition.top) element.style.top = endPosition.top;
        if (endPosition.left != startPosition.left) element.style.left = endPosition.left;
        if (endPosition.right != startPosition.right) element.style.right = endPosition.right;
        if (endPosition.bottom != startPosition.bottom) element.style.bottom = endPosition.bottom;
        if (callback) callback();
    }, timeMs - 100)
}





