import React, { useEffect, useState } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as OpenIcon } from "../../styles/assets/Icons/open.svg";
import { getListing } from "../../contexts/api/api.functions";
import { useToursState } from "../../contexts/tours/tours.context";
import { useNavigate } from "react-router-dom";
import {
  useSingleListingDispatch,
  SingleListingActions,
} from "../../contexts/single-listing/singleListing.context";
interface TourDetailsInfoComponent {
  listPrice?: string;
  address?: string;
  beds?: number;
  bath?: number;
  sqft?: number;
  cars?: number;
  boxltyId?: string;
  propertyTitle?: string;
  propertyDetails?: string;
  propertyType?: string;
  //this is an int in the db -- either make api call to get string value here or in parent component
  parkingType?: string;
  parkingTypeDetails?: string;
  lotSize?: number;
  listedBy?: string;
  licenseNumber?: string;
}

const TourDetailsInfo: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useSingleListingDispatch();
  const [property, setProperty] = useState<any | null>(null);
  const [propertyError, setPropertyError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { tourDetailsPropertyId, tourDetailsCompanyName } = useToursState();
  const fetchPropertyDetail = async () => {
    if (tourDetailsPropertyId) {
      const res = await getListing(tourDetailsPropertyId);

      if (res && res.data && res.data.status === true) {
        setProperty(res.data.result[0].screen1[0]);

        setLoading(false);
      } else {
        setPropertyError("Could not load property details");
        setLoading(false);
      }
    }
  };

  function stringWithCommas(x: string) {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    fetchPropertyDetail();
  }, [tourDetailsPropertyId]);

  if (loading) {
    return (
      <div style={{ position: "relative", top: "15px", left: "30px" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <section className={classes.TourDetailsOuterContainer}>
        {/* Tour Info component */}
        <div className={classes.TourDetailsHeaderRow}>
          <h1 className={classes.TourDetailsInfoHeader}>Listing Details</h1>
          <OpenIcon
            className={classes.TourDetailsOpenIcon}
            onClick={() => {
              dispatch({
                type: SingleListingActions.setPropertyId,
                payload: tourDetailsPropertyId,
              });
              // Navigate to the specified URL
              navigate(
                `/listings/listing/${tourDetailsPropertyId}/general/photos`
              );
            }}
          />
        </div>

        {/* price section */}
        <section className={classes.PriceSection}>
          {/* price */}
          <h2 className={classes.PriceHeader}>
            {property.propertySalePrice
              ? `$ ${stringWithCommas(property.propertySalePrice)}`
              : ""}
          </h2>
          {/* address */}
          <p className={classes.AddressText}>
            {" "}
            {property.propertyAddress &&
            property.propertyCity &&
            property.propertyState &&
            property.propertyZipCode
              ? `${property.propertyAddress}, ${property.propertyCity}, ${property.propertyState} ${property.propertyZipCode}`
              : ""}
          </p>
        </section>

        {/* listing stats section */}
        <section className={classes.StatsSection}>
          {/* listing stats baths.beds.garage.etc */}
          <p className={classes.StatsText}>
            {(property.propertyBedrooms ? property.propertyBedrooms : "") +
              " Bedrooms • " +
              (property.propertyBathrooms ? property.propertyBathrooms : "") +
              " baths • " +
              (property.propertySize ? `${property.propertySize} sq. ft.` : "")}
          </p>

          {/* car garage */}
          <p className={classes.StatsText}>
            {property.propertyParkingSpace
              ? `${property.propertyParkingSpace} car occupancy`
              : ""}
          </p>
          {/* boxlty id */}
          <p className={classes.StatsText}>
            {property.propertyId ? `Boxlty ID: ${property.propertyId}` : ""}
          </p>
        </section>

        {/* Property Title Section */}
        <section className={classes.PropertyTitleSection}>
          {/* Divider */}
          <div className={classes.DividerLine}></div>
          {/* Listing Title */}
          <h2 className={classes.PropertyTitleText}>
            {property.propertyHeadline ? property.propertyHeadline : ""}
          </h2>
          {/* Divider */}
          <div className={classes.DividerLine}></div>
        </section>

        {/* Property Details Parent */}
        <section className={classes.PropertyDetailsParent}>
          {/* parking details section */}
          <section className={classes.DetailsSection}>
            <h4 className={classes.DetailsHeader}>Property Details</h4>
            <p className={classes.DetailsText}>
              {property.generalInformation ? property.generalInformation : ""}
            </p>
          </section>

          {/* property type section */}
          <section className={classes.DetailsSection}>
            <h4 className={classes.DetailsHeader}>Property Type</h4>
            <p className={classes.DetailsText}>
              {" "}
              {property.propertyCreateType ? property.propertyCreateType : ""}
            </p>
          </section>

          {/* parking type section*/}
          <section className={classes.DetailsSection}>
            <h4 className={classes.DetailsHeader}>Type of parking</h4>
            <p className={classes.DetailsText}>
              {" "}
              {property.propertyParkingType ? property.propertyParkingType : ""}
            </p>
            <p className={classes.DetailsTextSub}>
              {" "}
              {property.propertyParkingSpace
                ? `${property.propertyParkingSpace} car occupancy`
                : ""}
            </p>
          </section>

          {/* lot size section */}
          <section className={classes.DetailsSection}>
            <h4 className={classes.DetailsHeader}>Lot Size</h4>
            <p className={classes.DetailsText}>
              {(property.propertySize
                ? stringWithCommas(property.propertySize)
                : "") + " sq. ft."}
            </p>
          </section>

          {/* Listed By */}
          <section className={classes.DetailsSection}>
            <p className={classes.DetailsText}>
              Listed by {tourDetailsCompanyName ? tourDetailsCompanyName : ""}
            </p>
          </section>

          {/* license number */}
          <section className={classes.DetailsSection}>
            <h4 className={classes.DetailsHeader}>License Number</h4>
            <p className={classes.DetailsText}>
              {property.licenseNo ? property.licenseNo : ""}
            </p>
          </section>
        </section>
      </section>
    </>
  );
};

const useStyles = makeStyles({ name: "tourDetailsInfoComponent" })((theme) => ({
  TourDetailsOuterContainer: {
    paddingTop: "20px",
    paddingLeft: "20px",
    marginRight: "5px",
    minHeight: "80vh",
    maxHeight: "80vh",
    overflow: "hidden",
  },
  TourDetailsHeaderRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  TourDetailsInfoHeader: {
    fontFamily: theme.fonts.secondary.bold,
    fontSize: "23px",
    whiteSpace: "nowrap",
    margin: "0px",
    marginRight: "15px",
  },
  TourDetailsOpenIcon: {
    height: "13px",
    width: "13px",
    cursor: "pointer",
  },
  PriceSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  PriceHeader: {
    fontSize: "18px",
    fontFamily: theme.fonts.secondary.bold,
    margin: "0px",
    marginBottom: "10px",
  },
  AddressText: {
    fontSize: "12px",
    maxWidth: "100px",
    margin: "0px",
    fontWeight: "500",
    fontFamily: theme.fonts.primary.bold,
    marginBottom: "20px",
  },
  StatsSection: {
    marginBottom: "15px",
  },
  StatsText: {
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: theme.fonts.secondary.light,
    margin: "0px",
    marginBottom: "2px",
  },
  DividerLine: {
    height: "0.5px",
    minHeight: "0.5px",
    maxHeight: "0.5px",
    backgroundColor: theme.palette.common.darkGray,
    width: "75%",
  },
  PropertyTitleSection: {
    marginBottom: "20px",
  },
  PropertyTitleText: {
    fontSize: "16px",
    letterSpacing: "-0.5px",
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    margin: "0px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  DetailsSection: {
    marginBottom: "20px",
  },
  DetailsHeader: {
    fontFamily: theme.fonts.secondary.bold,
    margin: "0px",
    marginBottom: "3px",
    fontSize: "14px",
  },
  DetailsText: {
    fontSize: "12px",
    maxWidth: "90%",
    overflowY: "auto",
    margin: "0px",
    fontWeight: "500",
    fontFamily: theme.fonts.primary.bold,
  },
  DetailsTextSub: {
    fontSize: "11px",
    maxWidth: "90%",
    overflowY: "auto",
    margin: "0px",
    fontWeight: "100",
    marginTop: "3px",
    fontFamily: theme.fonts.primary.light,
  },
  PropertyDetailsParent: {
    maxHeight: "35vh",
    overflowY: "auto",
  },
}));

export default TourDetailsInfo;
