import react, { SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material';
import ModalTransparentLayer from '../../../common/ModalTransparentLayer';
import { fadeIn, fadeOut, moveElement } from '../../../../utils/genericUtils';
import { Button } from '@mui/material';
import { Select, MenuItem } from '@mui/material'
import { setConstantValue } from 'typescript';
import EastIcon from '@mui/icons-material/East';
import { CreateListingActions, useCreateListingDispatch, useCreateListingState } from '../../../../contexts/create-listing/create-listing.context';
import { SettingsPowerRounded } from '@mui/icons-material';
import { createPropertyStepFourSchedule } from '../../../../contexts/api/api.functions';

interface position {
  top: string,
  left: string,
  right: string,
  bottom: string
}


interface ActionButtonModalProps {
  id?: string,
  isVisible: boolean,
  setIsVisible: React.Dispatch<SetStateAction<boolean>>
  openPosition: position,
  closedPosition: position,
  value: string,
  setValue: React.Dispatch<SetStateAction<string>>,
  setPreApproval: React.Dispatch<SetStateAction<boolean>>
}

function PreApprovalConflictModal(props: ActionButtonModalProps) {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(props.isVisible);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const dispatch = useCreateListingDispatch()
  const { advancedNotice, propertyId, availabilityStatus } = useCreateListingState()
  const addLockTransparentModalId = 'add-lock-transparent-layer';
  const TransparentLayerTransitionTime = 0.3;
  const ModalTransitionTime = 0.5;
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
      return
    }
    toggleModal(props.isVisible);
    toggleTransparentLayer(props.isVisible);
    // if (isInitialRender && props.isVisible === false) toggle(false);
  }, [props.isVisible])


  useEffect(() => {
    // console.log(modalVisible)
  }, [modalVisible])
  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggle(mode?: boolean | void) {
    if (mode != null) {
      toggleModal(mode);
      toggleTransparentLayer(mode);
      setModalVisible(mode);

    } else {
      toggleModal();
      toggleTransparentLayer();
    }
  }


  const convertWeekdaysForApi = (weekDays: number[]): number[] => {
    let apiDays = [];
    for (var i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === 0) {
        apiDays.push(1)
      }
      if (weekDays[i] === 1) {
        apiDays.push(2)
      }
      if (weekDays[i] === 2) {
        apiDays.push(3)
      }
      if (weekDays[i] === 3) {
        apiDays.push(4)
      }
      if (weekDays[i] === 4) {
        apiDays.push(5)
      }
      if (weekDays[i] === 5) {
        apiDays.push(6)
      }
      if (weekDays[i] === 6) {
        apiDays.push(7)
      }

    }
    return apiDays
  }





  const changeAvailabilityStatus = async () => {
    if (!propertyId) {
      return
    }
    const body = {
      propertyId: propertyId!,
      stepId: 4,
      noticeTime: parseInt(advancedNotice),
      isForceUpdate: 0,
      unavaiableDates: availabilityStatus.unavailableDates,
      isRecure: availabilityStatus.weekDays.length === 7 && availabilityStatus.weekDays.length < 1 ? "3" : "0",
      weekDays: convertWeekdaysForApi(availabilityStatus.weekDays),
      timeInterval: availabilityStatus.timeInterval
    }
    const res = await createPropertyStepFourSchedule(body)
      .then((res) => {
        return
      })
      .catch((err) => {
        //// can add some sort of error handling here later, snackbar?
        return
      })
  }


  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleTransparentLayer(mode: boolean | void) {
    //get transparent layer by id
    let transparentLayer: HTMLElement = document.querySelector('#' + addLockTransparentModalId) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      return;
    }
    //manual mode not passed
    if (props.isVisible) {
      fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
    } else {
      fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleModal(mode: boolean | void) {
    let modal: HTMLElement = document.querySelector('#' + props.id) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      //slide element on page
      moveElement(modal, ModalTransitionTime * 1000, props.closedPosition, props.openPosition);
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      //slide element off page
      moveElement(modal, ModalTransitionTime * 1000, props.openPosition, props.closedPosition);
      return;
    }

    if (props.isVisible) {
      //slide element off page
      moveElement(modal, ModalTransitionTime * 1000, props.openPosition, props.closedPosition);
    } else {
      //slide element on page
      moveElement(modal, ModalTransitionTime * 1000, props.closedPosition, props.openPosition);
    }
  }



  const useStyles = makeStyles({ name: 'AddLockModal' })((theme) => ({
    ModalComponentContainer: {
      overflow: 'hidden'
    },
    AddLockModal: {
      transition: `right ${ModalTransitionTime}s linear`,
      position: 'absolute',
      zIndex: 25,
      backgroundColor: 'white',
      //backgroundColor : 'darkgray',
      borderRadius: '20px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      bottom: props.closedPosition.bottom,
      right: props.closedPosition.right,
      padding: '20px',
      minHeight: '225px',
      minWidth: '350px',
      maxWidth: '400px'
    },
    modalHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalSubHeader: {
      margin: 0,
      marginBottom: '10px',
      fontSize: '18px'
    },
    headerText: {
      margin: '0',
      textAlign: 'left',
      width: '98%',
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: '26px'
    },
    exitX: {
      marginLeft: 'auto',
      fontSize: '25px',
      cursor: 'pointer',
      color: theme.palette.common.lightGray
    },
    UnderlinedButtonContainer: {
      display: 'flex',
      height: '55px',
      flexDirection: 'column',
      marginRight: '10px',
      marginLeft: '10px',
    },
    ButtonSubLine: {
      width: '90px',
      height: '1px',
      maxHeight: '1px',
      minHeight: '1px',
      backgroundColor: theme.palette.common.darkGray
    },
    AddItemRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '11px'
    },
    AddItemRowText: {
      width: '150px',
      marginRight: '50px',
      marginBottom: '2px',
      marginTop: '2px'
    },
    ModalFieldRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    ModalInputBox: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '19px',
      marginRight: '10px',
    },
    ModalInput: {
      fontFamily: theme.fonts.secondary.light,
      border: 'none',
      outline: 'none',
      width: '100%'
    },
    InputSubLine: {
      width: '100%',
      height: '1px',
      maxHeight: '1px',
      minHeight: '1px',
      backgroundColor: theme.palette.common.darkGray
    },
    HalfWidthInput: {
      width: '50%'
    },
    FullWidthInput: {
      width: '100%'

    },
    SeventyFiveWidthInput: {
      width: '75%'


    },
    instaShow: {
      fontSize: '17px',
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    TwentyFiveWidthInput: {
      width: '25%'

    },
    AddItemOuterContainer: {
      marginBottom: '45px'
    },
    AssignToListingText: {
      fontWeight: '100',
      margin: '0',
      marginBottom: '10px'
    },
    AssignToListingButtonOuterContainer: {
      width: '120px'
    },
    SaveSection: {
      marginTop: '70px',
      marginBottom: '10px',
      marginLeft: '15px',
      display: 'flex',
      flexDirection: 'row'
    },
    AssignToListingHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    continueButton: {
      backgroundImage: `linear-gradient(270deg, #D63F94 0%, #B8519E 100%)`
    },
    cancelButton: {
      backgroundColor: 'white',
      color: theme.palette.common.medGray,
      border: `1px solid ${theme.palette.common.medGray}`,
      '&:hover': {
        backgroundColor: 'white',
        color: theme.palette.common.medGray,
        border: `1px solid ${theme.palette.common.medGray}`,
      }
    }
  }));

  let { classes } = useStyles();

  return (<>
    {/* Component Container */}
    <section className={classes.ModalComponentContainer}>
      {/* TransparentLayer */}
      <ModalTransparentLayer id={addLockTransparentModalId} transitionTime={TransparentLayerTransitionTime} />
      {/* ModdalConatiner */}
      <section id={props.id} className={classes.AddLockModal}>
        {/* header row */}
        <div className={classes.modalHeader}>
          <h2 className={classes.headerText}>Conflict</h2>
          <span className={classes.exitX} onClick={() => {
            props.setPreApproval(false)
            props.setIsVisible(false)
          }}>&times;</span>
        </div>
        {/* sub header */}
        <p className={classes.modalSubHeader} style={{ marginTop: '10px' }}>Pre-Approval is unavailable with ‘InstaShow’ as the Advanced Notice.</p>

        <p className={classes.modalSubHeader} style={{ marginTop: '25px' }}>Please modify your selection.</p>

        <Select variant="standard" sx={{ marginTop: '15px', marginLeft: '15px' }} disableUnderline value={props.value} className={classes.instaShow} onChange={(e) => {
          props.setValue(e.target.value)
          dispatch({ type: CreateListingActions.setAdvancedNotice, payload: e.target.value })
        }}>
          <MenuItem value="1" className={classes.instaShow}>{`InstaShow`}&reg;</MenuItem>
          <MenuItem value="4">1 Hour</MenuItem>
          <MenuItem value="5">2 Hours</MenuItem>
          <MenuItem value="6">3 Hours</MenuItem>
          <MenuItem value="7">4 Hours</MenuItem>
          <MenuItem value="8">5 Hours</MenuItem>
          <MenuItem value="9">6 Hours</MenuItem>
          <MenuItem value="10">12 Hours</MenuItem>
          <MenuItem value="18">24 Hours</MenuItem>
          <MenuItem value="11">36 Hours</MenuItem>
        </Select>

        {/* Save Section */}
        <section className={classes.SaveSection} style={{ gap: '20px' }}>
          <Button variant='contained' size='small' endIcon={<EastIcon />} classes={{ root: classes.continueButton }} onClick={() => {
            changeAvailabilityStatus()
            if (advancedNotice !== "0") {
              props.setIsVisible(false)
            } else if (advancedNotice === "0") {
              props.setPreApproval(false)
              props.setIsVisible(false)
            }
          }}>
            Continue
          </Button>
          <Button variant='contained' disableElevation size='small' classes={{ root: classes.cancelButton }} onClick={() => {
            props.setPreApproval(false)
            props.setIsVisible(false)
          }}>
            Cancel
          </Button>
        </section>
      </section>

    </section>
  </>)
}


export default PreApprovalConflictModal;