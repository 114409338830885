import React from "react";
import { useState, useEffect } from 'react'
import { Grid, TextField, Button, IconButton, Checkbox, FormControl, FormGroup, FormControlLabel, Box, Select, MenuItem, Accordion, AccordionSummary, Divider } from '@mui/material'
import { makeStyles } from "tss-react/mui";
import BoxltyCheckbox from "../../../common/boxlty.checkbox";
import { useTheme } from "@mui/material";
import { DateCalendar, TimePicker } from "@mui/x-date-pickers";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { TimeSelectionComponent } from './time.selection.component'
import { format } from "date-fns";
import { TimeSlot } from "../primary-tabs/listings.availability.tab";
import { MultiDayCalendar } from "./multi.data.calendar.component";
import { add } from "date-fns";
import { CustomCalendar } from "../../../common/custom-calendar/custom.calendar";
import { useFormContext } from "react-hook-form";
import { AvailabilityObject } from "../../../common/custom-calendar/custom.calendar";
import { generateAvailabilityObjectforEveryday, calculateDaysAll } from "./generate.dates.util";
import { getAllDatesForSpecificWeekdaysInCurrentAndNextMonth } from "./generate.dates.util";
import { UnavailableObject } from "../../../../contexts/api/api.types";
import { isSameDay } from "date-fns";
import { remove } from "lodash";
export const CustomAvailabilityForm: React.FC<{ timeSlots: TimeSlot[], setActive?: (value: boolean) => void }> = ({ timeSlots, setActive }) => {
    const { classes } = useStyles()
    const theme = useTheme()
    const [everyDay, setEveryDay] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [removedDays, setRemovedDays] = useState<UnavailableObject[]>([])
    const [unavailableDays, setUnavailableDays] = useState<UnavailableObject[]>([])
    const [calendarState, setCalendarState] = useState<AvailabilityObject[]>([])
    const [currentSelectedDay, setCurrentSelectedDay] = useState<AvailabilityObject>({ date: new Date(), timeSlots: timeSlots })
    const { formState, watch, setValue } = useFormContext()
    const weekDays = watch("weekDays")
    const unavailableDates = watch("unavailableDates")
    const interval = watch("timeInterval")

    const mapSlotsToNumbers = (slots: TimeSlot[]) => {
        let slotArr = []
        for (var i = 0; i < slots.length; i++) {
            if (slots[i].start === 8 && slots[i].end === 9) {
                slotArr.push(1)

            }
            if (slots[i].start === 9 && slots[i].end === 10) {
                slotArr.push(2)

            }
            if (slots[i].start === 10 && slots[i].end === 11) {
                slotArr.push(3)

            }
            if (slots[i].start === 11 && slots[i].end === 12) {
                slotArr.push(4)

            }
            if (slots[i].start === 12 && slots[i].end === 13) {
                slotArr.push(5)

            }
            if (slots[i].start === 13 && slots[i].end === 14) {
                slotArr.push(6)

            }
            if (slots[i].start === 14 && slots[i].end === 15) {
                slotArr.push(7)

            }
            if (slots[i].start === 15 && slots[i].end === 16) {
                slotArr.push(8)

            }
            if (slots[i].start === 16 && slots[i].end === 17) {
                slotArr.push(9)

            }
            if (slots[i].start === 17 && slots[i].end === 18) {
                slotArr.push(10)

            }
            if (slots[i].start === 18 && slots[i].end === 19) {
                slotArr.push(11)

            }
            if (slots[i].start === 19 && slots[i].end === 20) {
                slotArr.push(12)

            }
        }
        return slotArr
    }

    const mapNumbersToSlots = (numbers: Array<number>) => {
        let slots: TimeSlot[] = [];
        for (var i = 0; i < numbers.length; i++) {
            if (numbers[i] === 1) {
                slots.push({ start: 8, end: 9 })
            }
            if (numbers[i] === 2) {
                slots.push({ start: 9, end: 10 })
            }
            if (numbers[i] === 3) {
                slots.push({ start: 10, end: 11 })
            }
            if (numbers[i] === 4) {
                slots.push({ start: 11, end: 12 })
            }
            if (numbers[i] === 5) {
                slots.push({ start: 12, end: 13 })
            }
            if (numbers[i] === 6) {
                slots.push({ start: 13, end: 14 })
            }
            if (numbers[i] === 7) {
                slots.push({ start: 14, end: 15 })
            }
            if (numbers[i] === 8) {
                slots.push({ start: 15, end: 16 })
            }
            if (numbers[i] === 9) {
                slots.push({ start: 16, end: 17 })
            }
            if (numbers[i] === 10) {
                slots.push({ start: 17, end: 18 })
            }
            if (numbers[i] === 11) {
                slots.push({ start: 18, end: 19 })
            }
            if (numbers[i] === 12) {
                slots.push({ start: 19, end: 20 })
            }
        }
        return slots
    }

    const returnDateString = (date: Date) => {
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset * 60 * 1000))
        return date.toISOString().split('T')[0]
    }


    const calculateUnavailableDates = () => {
        const days1: UnavailableObject[] = calendarState.filter(day => day.timeSlots.length < timeSlots.length).map(day => {
            return { date: returnDateString(day.date), slots: mapSlotsToNumbers(day.timeSlots) }
        });
        setUnavailableDays([...days1])

    }


    //// Generating days for the calendar state
    useEffect(() => {
        setCalendarState([])
        const dates = getAllDatesForSpecificWeekdaysInCurrentAndNextMonth(weekDays);
        const days: AvailabilityObject[] = dates.map((date) => {
            return { date: date, timeSlots: timeSlots }
        })
        //// loading the days that are set in the context from getListing call 
        if (unavailableDates.length > 0) {
            let contextDays = unavailableDates.map((day: any) => {
                const newDate = new Date(`${day.date}T00:00:00`);

                return { date: newDate, timeSlots: mapNumbersToSlots(day.slots) }
            })
            let newDayState = [...days];
            contextDays.forEach((contextDay: any) => {
                let existingDay = newDayState.find((day) => {
                    if (isSameDay(day.date, contextDay.date)) {
                        return day
                    }
                })
                if (existingDay) {
                    const index = newDayState.indexOf(existingDay);
                    newDayState[index] = contextDay;
                }

            })
            setCalendarState([...newDayState])
        } else {
            setCalendarState([...days])
        }
    }, [weekDays, timeSlots])

    useEffect(() => {
        calculateUnavailableDates()
    }, [calendarState])

    useEffect(() => {
        const value = mapSlotsToNumbers(timeSlots);
        setValue('timeInterval', value)
    }, [timeSlots])

    useEffect(() => {
        const value1 = [...unavailableDays];
        const value2 = [...removedDays];
        const value = value1.concat([...value2])
        setValue('unavailableDates', value)
    }, [unavailableDays, removedDays])




    useEffect(() => {
        // console.log(interval)
        // console.log("this is the interval")
    }, [interval])




    return (
        <>
            <Grid container direction='column' display={'flex'} width={"100%"} sx={{ marginBottom: '10px' }}>
                <Grid item>
                    <Grid container direction='row' display={'flex'} justifyContent={'space-between'} >
                        <Grid item>
                            <Grid container direction='row' display={'flex'} alignItems='center' gap={'10px'}>
                                <Grid item>
                                    <div className={classes.formHeader}>Custom Availability</div>
                                </Grid>
                                <Grid item>
                                    <InfoOutlinedIcon sx={{ cursor: 'pointer', color: theme.palette.common.primary }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item display={'flex'} sx={{ position: 'relative', bottom: '10px' }}>
                            <IconButton onClick={() => { setExpanded(!expanded) }}>
                                {expanded ? <ExpandLess fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.centerContainer} sx={{ marginTop: '15px', height: 'fit-content', display: 'flex' }}>
                    <Grid container className={classes.centerSubContainer} display='flex' justifyContent={'space-between'} sx={{ height: expanded ? 'fit-content' : '0px', overflow: 'hidden', transition: 'height 0.4s' }}>
                        <Grid item className={classes.calendarContainer} flex={1} sx={{ display: expanded ? 'flex' : 'none' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <div>Select the day you would like to edit.</div>
                                </Grid>
                                <Grid item>
                                    {/* calendar */}

                                    <CustomCalendar state={calendarState} timeSlots={timeSlots} setState={setCalendarState} currentDay={currentSelectedDay} setCurrentDay={setCurrentSelectedDay} setActive={setActive} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.timeContainer} flex={1} sx={{ display: expanded ? 'flex' : 'none' }}>
                            <Grid container direction={'column'} gap='15px' >
                                <Grid item>
                                    <div>Select the times you want the property to be available.</div>
                                </Grid>
                                <Grid item alignSelf='center'>
                                    <div style={{ fontWeight: 'bold' }}>{format(currentSelectedDay.date ? currentSelectedDay.date : new Date(), 'PPPP')}</div>
                                </Grid>
                                <Grid item display={'flex'} justifyContent='center' alignSelf={'center'}>
                                    <TimeSelectionComponent timeSlots={timeSlots} calendarState={calendarState} setCalendarState={setCalendarState} currentSelectedDay={currentSelectedDay} setCurrentSelectedDay={setCurrentSelectedDay} unavailableDays={unavailableDays} setUnavailableDays={setUnavailableDays} removedDays={removedDays} setRemovedDays={setRemovedDays} />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles({ name: "Custom-Availability-Form" })((theme) => ({
    mainContainer: {

    },
    formHeader: {
        fontSize: '20px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",

    },
    inputHeader: {
        fontFamily: theme.fonts.secondary.reg,
        color: '#A2A2A2',
        marginBottom: '7px',
        textTransform: 'uppercase'
    },
    filledInput: {
        background: '#EAEAEA'
    },
    labelComponent: {
        marginLeft: 0,
        marginRight: 0
    },
    checkboxLabel: {
        marginTop: '5px'
    },
    centerContainer: {
        marginTop: '15px',
        height: 'fit-content',
        display: 'flex',
        '@media screen and (max-width: 1420px)': {
            marginTop: '0px'
        }
    },
    centerSubContainer: {
        flexDirection: 'row',
        '@media screen and (max-width: 1420px)': {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '25px'
        }
    },
    calendarContainer: {

    },
    timeContainer: {

    }
}))

