import React from 'react'
import { useState, useEffect } from 'react'
import { useGradient } from '../../../../contexts/gradients/gradients.context'
import { Select, MenuItem, Grid } from '@mui/material'
import { Lock } from './forms.types'
import { makeStyles } from 'tss-react/mui'



export const LockSelectComponent: React.FC<{ locks: Lock[], selected: string, setSelected: (value: string) => void }> = ({ locks, setSelected, selected }) => {
    const [internalSelected, setInternalSelected] = useState('0')
    const { inProgressGradient } = useGradient()
    const { classes } = useStyles()
  
  
    return (
    <>
        <Select variant='standard' value={selected} fullWidth disableUnderline onChange={(e) => { setSelected(e.target.value) }}>
                <MenuItem value={"0"} classes={{ selected: classes.selected }}>Select Lock</MenuItem>
                {locks.map((lock, index) => (
                        <MenuItem value={lock.id.toString()} key={lock.id} sx={{ '&:hover': { background: `${inProgressGradient} !important` } }}>
                            <Grid container direction={'row'} alignItems='center' display='flex' className={classes.mainContainer}>                               
                                <Grid item>
                                    <Grid container direction={'column'}>
                                        <Grid item className={classes.name}>
                                            <span>
                                                {lock.lockNickName}
                                            </span>
                                        </Grid>
                                        <Grid item>
                                            <span>
                                                {/* Some number in the XD do not know what this is yet */}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MenuItem>
                ))}
            </Select>
    </>
  )
}


const useStyles = makeStyles({ name: "user-select-component" })((theme) => ({
    mainContainer: {
        padding: '5px'
    },
    name: {
        fontSize: '18px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
    },
    menuItem: {

    },
    selected: {
        backgroundColor: 'white !important'
    },

}))