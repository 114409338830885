import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import TeamPrimaryManagerAdminDetailsPage from "./super-admin/primary-manager/teams.pm.admin.details";
import TeamDetailsPage from "./non-enterprise/teams.details";

export const TeamViewDetailsSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         return <TeamPrimaryManagerAdminDetailsPage />;
      //// Enterprise Lender
      case 6:
         return <TeamDetailsPage />;
      //// Non-Enterprise
      default:
         return <TeamDetailsPage />;
   }
};
