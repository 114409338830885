import React from 'react'
import AppBody from '../../../components/body/main.body.component'
import { Grid, Paper } from '@mui/material'
import { ListingsCreateMiddleContainer } from '../../../components/listings/create/listings.middle.container'
import { ListingsCreateTop } from '../../../components/listings/create/listings.create.top'
import { ListingsCreateRightContainer } from '../../../components/listings/create/listings.right.container'
import { PhotosTab} from '../../../components/listings/create/secondary-tabs/photos.tab'
import CreateListingBody from '../../../components/body/create.listing.body'
import { MiddleFormSwitch } from '../../../components/listings/create/middle.form.switch'
import { BottomSubmitComponent } from '../../../components/listings/create/bottom.submit.component'
import { SecondaryTabsComponent } from '../../../components/listings/create/secondary.tabs.component'
interface IListingsCreateProps {

}

export const ListingCreateMain: React.FC<IListingsCreateProps> = ({ }) => {
    return (
        <>
            <CreateListingBody >
                <MiddleFormSwitch />
                {/* <Grid item display={'flex'} sx={{ paddingLeft: '20px'}}>
                    <ListingsCreateTop />
                </Grid>
                <Grid item display={'flex'} flex={1} sx={{ paddingLeft: '20px'}}>
                    <Grid container direction={'row'}  display='flex' gap={'10px'} >
                        <Grid item flex={1.5} >
                            
                            <ListingsCreateMiddleContainer>
                                <MiddleFormSwitch />
                                
                            </ListingsCreateMiddleContainer>
                        </Grid>
                        <Grid item flex={1} >
                            
                            
                            <ListingsCreateRightContainer>
                                <PhotosTab />
                            </ListingsCreateRightContainer>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <BottomSubmitComponent active={true}/>
                </Grid> */}
            </CreateListingBody>
        </>
    )
}

