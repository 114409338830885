import React from "react";
import { Grid } from "@mui/material";
import { useGradient } from "../../contexts/gradients/gradients.context";
import { makeStyles } from "tss-react/mui";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateLockName } from "../../contexts/api/api.functions";
import { useLocksState } from "../../contexts/locks/locks.context";
import { LockDetails } from "./locks.types";

export const BottomSubmitLockComponent: React.FC<{
   active: boolean;
   onSaveOrCancelClick: (event: React.MouseEvent<HTMLElement>) => void;
   lockNickName: string;
   LockDetails: LockDetails | null;
}> = ({ active, onSaveOrCancelClick, lockNickName, LockDetails }) => {
   const { inProgressGradient } = useGradient();
   const { classes } = useStyles();
   const theme = useTheme();
   const navigate = useNavigate();
   const { locks, loading, lockKey } = useLocksState();
   const body = {
      lockManufacturer:
         LockDetails?.lockManufacturerId !== null
            ? LockDetails?.lockManufacturerId
            : 0,
      iglooLockTypeId:
         LockDetails?.iglooLockTypeId !== null
            ? LockDetails?.iglooLockTypeId
            : 0,
      masterLockTypeId: Number(
         LockDetails?.masterLockTypeId !== null
            ? LockDetails?.masterLockTypeId
            : 0
      ),
      lockDeviceId:
         LockDetails?.lockDeviceId !== null ? LockDetails?.lockDeviceId : "",
      lockActivationCode:
         LockDetails?.lockActivationCode !== null
            ? LockDetails?.lockActivationCode
            : "",
      lockNickname: lockNickName,
   };

   return (
      <>
         <Grid container direction={"row"}>
            <Grid
               item
               sx={{
                  background: `${inProgressGradient}`,
                  display: active ? "flex" : "none",
                  height: "100px",
                  left: "225px",
               }}
               className={classes.mainContainer}
            >
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     position: "relative",
                     right: "125px",
                     gap: "20px",
                  }}
               >
                  <Button
                     variant="contained"
                     onClick={(event) => {
                        updateLockName(body);
                        onSaveOrCancelClick(event);
                     }}
                     classes={{
                        root: classes.saveActive,
                        disabled: classes.saveDisabled,
                     }}
                  >
                     SAVE CHANGES
                  </Button>
                  <Button
                     variant="contained"
                     onClick={() => window.location.reload()}
                     classes={{ root: classes.cancelActive }}
                  >
                     CANCEL
                  </Button>
               </div>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "bottom-submit-component" })((theme) => ({
   mainContainer: {
      width: "100%",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      bottom: 0,
   },
   saveActive: {
      fontFamily: theme.fonts.secondary.med,
      backgroundColor: "white",
      color: "black",
      "&:hover": {
         backgroundColor: "#3A6250",
         color: "white",
      },
   },
   saveDisabled: {},
   cancelActive: {
      fontFamily: theme.fonts.secondary.med,
      backgroundColor: "#A2A2A2",
      color: "white",
      "&:hover": {
         backgroundColor: "#A2A2A2",
         color: "white",
      },
   },
   cancelDisabled: {},
}));
