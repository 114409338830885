export const returnPropertyTypeString = (value: number) => {
    switch (value) {
      case 1:
        return 'single-family'
      case 2:
        return 'multi-family'
      case 3:
        return 'townhome'
      case 4:
        return 'apartment'
      default:
        return 'single-family'
    }
  }

 export const returnPropertyTypeNumber = (value: string) => {
    switch (value) {
      case 'single-family':
        return '1'

      case 'multi-family':
        return '2'
      case 'townhome':
        return '3'
      case 'apartment':
        return '4'
      default:
        return '1'
    }
  }

export  const returnPetPolicyNumber = (value: string) => {
    switch (value) {
      case 'allowed':
        return '1'
      case 'not-allowed':
        return '4'
      default:
        return '4'
    }
  }

export  const returnPetPolicyString = (value: string) => {
    switch (value) {
      case '1':
        return 'allowed'
      case '4':
        return 'not-allowed'
      default:
        return 'not-allowed'
    }
  }

export  const returnParkingTypeString = (value: string) => {
    switch (value) {
      case '1':
        return 'dedicated-garage'
      case '2':
        return 'on-street'
      case '3':
        return 'none'
      default:
        return 'on-street'
    }
  }


export const returnParkingTypeNumber = (value: string) => {
    switch (value) {
      case 'dedicated-garage':
        return '1'
      case 'on-street':
        return '2'
      case 'none':
        return '3'
      default:
        return '2'
    }
  }