import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { ILocksState } from "../locks/locks.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useUserState } from "../user/user.context";
import {
   getUserLockList,
   getAdminUserLockList,
   lenderGetLocks,
} from "../api/api.functions";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
import { useParams } from "react-router-dom";

export enum LocksActions {
   setLocks = "setLocks",
   setLenderLocksData = "setLenderLocksData",
   setError = "setError",
   setLoading = "setLoading",
   setNonLenderLockData = "setNonLenderLockData",
   setLockPropertyId = "setLockPropertyId",
   setLockCompanyName = "setLockCompanyname",
   setLockNickName = "setLockNickName",
}

const initialState = {
   locks: [],
   lenderLocksData: {
      data: [],
      totalCount: 0,
      totalActiveCount: 0,
      totalInactiveCount: 0,
      totalUnassignedCount: 0,
   },
   loading: false,
   error: "",
   lockTypeId: 0,
   lockKey: "",
   nonLenderLocksData: {
      data: [],
      totalCount: 0,
      totalActiveCount: 0,
      totalInactiveCount: 0,
      totalUnassignedCount: 0,
   },
   companyCode: 0,
   lockNickName: "",
   activeStatus: "",
   iglooLockTypeId: "",
   iglooLockType: "",
   masterLockTypeId: "",
   masterLockType: "",
   lockPropertyID: 0,
   propertyOwnerCompanyName: "",
};

const reducer = (state: ILocksState, action: IAction): ILocksState => {
   const newState = { ...state };
   switch (action.type) {
      case LocksActions.setLocks:
         newState.locks = action.payload;
         break;
      case LocksActions.setLenderLocksData:
         newState.lenderLocksData = action.payload;
         break;
      case LocksActions.setError:
         newState.error = action.payload;
         break;
      case LocksActions.setNonLenderLockData:
         newState.nonLenderLocksData = action.payload;
         break;
      case LocksActions.setLoading:
         newState.loading = action.payload;
         break;
      case LocksActions.setLockPropertyId:
         newState.lockPropertyID = action.payload;
         break;
      case LocksActions.setLockCompanyName:
         newState.propertyOwnerCompanyName = action.payload;
         break;
      case LocksActions.setLockNickName:
         newState.lockNickName = action.payload;
         break;
      default:
         return state;
   }

   return newState;
};

export const LocksStateContext: any = React.createContext({});
export const LocksDispatchContext: any = React.createContext({});
export const LocksStateRefContext: any = React.createContext({});

export const useLocksState = (): ILocksState => useContext(LocksStateContext);
export const useLocksDispatch = (): Dispatch<IAction> =>
   useContext(LocksDispatchContext);
export const useLocksStateRef = (): React.MutableRefObject<ILocksState> =>
   useContext(LocksStateRefContext);

const middleware = [logger("User")];

export const LocksProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, userStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );
   const { LockType } = useParams<{ LockType: string }>();
   const { user } = useUserState();

   const getUserLocks = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         dispatch({ type: LocksActions.setLoading, payload: true });
         if (user && user.userId) {
            if (user?.isEnterpriseSuperUser) {
               if (user?.accountType === 5) {
                  let filter: Number = 4;
                  switch (LockType) {
                     case "all":
                        filter = 1;
                        break;
                     case "active":
                        filter = 2;
                        break;
                     case "inactive":
                        filter = 3;
                        break;
                     case "unassigned":
                     default:
                        break;
                  }
                  const res = await getAdminUserLockList(filter);
                  if (res.data.message !== "Data Found") {
                     dispatch({
                        type: LocksActions.setNonLenderLockData,
                        payload: [],
                     });
                  } else {
                     dispatch({
                        type: LocksActions.setNonLenderLockData,
                        payload: res.data.result,
                     });
                  }
               } else if (user?.accountType === 6) {
                  //("get lender locks", LockType);
                  switch (LockType) {
                     case "all":
                        const res1 = await lenderGetLocks(1);
                        dispatch({
                           type: LocksActions.setLenderLocksData,
                           payload: res1.data.result,
                        });
                        break;
                     case "active":
                        //console.log("active tab", LockType);
                        const res2 = await lenderGetLocks(2);
                        dispatch({
                           type: LocksActions.setLenderLocksData,
                           payload: res2.data.result,
                        });
                        break;
                     case "inactive":
                        const res3 = await lenderGetLocks(3);
                        dispatch({
                           type: LocksActions.setLenderLocksData,
                           payload: res3.data.result,
                        });
                        break;
                     case "unassigned":
                        const res4 = await lenderGetLocks(4);
                        dispatch({
                           type: LocksActions.setLenderLocksData,
                           payload: res4.data.result,
                        });
                        break;
                  }
               }
            } else {
               const res = await getUserLockList();
               dispatch({
                  type: LocksActions.setLocks,
                  payload: res.data.result,
               });
            }
         } else {
            dispatch({
               type: LocksActions.setError,
               payload: "Could not load locks.",
            });
            dispatch({ type: LocksActions.setLoading, payload: false });
         }
         dispatch({ type: LocksActions.setLoading, payload: false });
      }
   };

   useEffect(() => {
      getUserLocks();
   }, [user, user?.userId, LockType]);

   return (
      <LocksStateContext.Provider value={state}>
         <LocksDispatchContext.Provider value={dispatch}>
            <LocksStateRefContext.Provider value={userStateRef}>
               {children}
            </LocksStateRefContext.Provider>
         </LocksDispatchContext.Provider>
      </LocksStateContext.Provider>
   );
};
