import React from "react";
import { useState, useEffect } from "react";
import { BottomSubmitComponent } from "../create/bottom.submit.component";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { CreatedListingGeneralTab } from "./primary-tabs/created.listing.general.tab";
import { CreatedListingHardwareTab } from "./primary-tabs/created.listing.hardware.tab";
import { CreatedListingAvailabilityTab } from "./primary-tabs/created.listing.availability.tab";
import { CreatedListingTeamsTab } from "./primary-tabs/created.listing.team.tab";
import { CreatedListingPreApprovalTab } from "./primary-tabs/created.listing.pre-approval.tab";
import { CreatedListingFormContextGeneral } from "./listing.created.general.form.context";
import { CreatedListingFormContextAvilability } from "./listing.created.availability.form.context";
import { CreatedListingFormContextHardware } from "./listing.created.hardware.form.context";
import { CreatedListingFormContextApproval } from "./listing.created.preapproval.form";
import { CreatedListingFormContextTeam } from "./listing.created.team.form.context";
import { ListingsCreatedTop } from "./listing.created.top";
import { ListingsCreateMiddleContainer } from "../create/listings.middle.container";
import { ListingsCreateRightContainer } from "../create/listings.right.container";
import { CreatedPhotosTab } from "./secondary-tabs/created.photos.tab";
type Props = {};

export const CreatedMiddleFormSwitch: React.FC<{}> = ({}) => {
   const { CreatedCurrentMainTab } = useParams<{
      CreatedCurrentMainTab: string;
   }>();
   const { CreatedCurrentSecondaryTab } = useParams<{
      CreatedCurrentSecondaryTab: string;
   }>();
   const [active, setActive] = useState(false);

   const setActiveFunc = (value: boolean) => {
      setActive(value);
   };

   switch (CreatedCurrentMainTab) {
      case "general":
         return (
            <>
               <CreatedListingFormContextGeneral setActive={setActiveFunc}>
                  <Grid item display={"flex"} sx={{ paddingLeft: "20px" }}>
                     <ListingsCreatedTop setActive={setActiveFunc} />
                  </Grid>
                  <Grid
                     item
                     display={"flex"}
                     flex={1}
                     sx={{ paddingLeft: "20px" }}
                  >
                     <Grid
                        container
                        direction={"row"}
                        display="flex"
                        gap={"10px"}
                     >
                        <Grid item flex={1.5}>
                           {/* Middle container */}
                           <ListingsCreateMiddleContainer>
                              <CreatedListingGeneralTab
                                 setActive={setActiveFunc}
                              />
                           </ListingsCreateMiddleContainer>
                        </Grid>
                        <Grid item flex={1}>
                           {/* Right container */}

                           <ListingsCreateRightContainer>
                              <CreatedPhotosTab setActive={setActiveFunc} />
                           </ListingsCreateRightContainer>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item>
                     <BottomSubmitComponent active={active} />
                  </Grid>
               </CreatedListingFormContextGeneral>
            </>
         );
      case "team":
         return (
            <>
               <CreatedListingFormContextTeam setActive={setActiveFunc}>
                  <Grid item display={"flex"} sx={{ paddingLeft: "20px" }}>
                     <ListingsCreatedTop setActive={setActiveFunc} />
                  </Grid>
                  <Grid
                     item
                     display={"flex"}
                     flex={1}
                     sx={{ paddingLeft: "20px" }}
                  >
                     <Grid
                        container
                        direction={"row"}
                        display="flex"
                        gap={"10px"}
                     >
                        <Grid item flex={1.5}>
                           {/* Middle container */}
                           <ListingsCreateMiddleContainer>
                              <CreatedListingTeamsTab
                                 setActive={setActiveFunc}
                              />
                           </ListingsCreateMiddleContainer>
                        </Grid>
                        <Grid item flex={1}>
                           {/* Right container */}

                           <ListingsCreateRightContainer>
                              <CreatedPhotosTab setActive={setActiveFunc} />
                           </ListingsCreateRightContainer>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item>
                     <BottomSubmitComponent active={active} />
                  </Grid>
               </CreatedListingFormContextTeam>
            </>
         );
      case "availability":
         return (
            <>
               <CreatedListingFormContextAvilability setActive={setActiveFunc}>
                  <Grid item display={"flex"} sx={{ paddingLeft: "20px" }}>
                     <ListingsCreatedTop setActive={setActiveFunc} />
                  </Grid>
                  <Grid
                     item
                     display={"flex"}
                     flex={1}
                     sx={{ paddingLeft: "20px" }}
                  >
                     <Grid
                        container
                        direction={"row"}
                        display="flex"
                        gap={"10px"}
                     >
                        <Grid item flex={1.5}>
                           {/* Middle container */}
                           <ListingsCreateMiddleContainer>
                              <CreatedListingAvailabilityTab
                                 setActive={setActiveFunc}
                              />
                           </ListingsCreateMiddleContainer>
                        </Grid>
                        <Grid item flex={1}>
                           {/* Right container */}

                           <ListingsCreateRightContainer>
                              <CreatedPhotosTab setActive={setActiveFunc} />
                           </ListingsCreateRightContainer>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item>
                     <BottomSubmitComponent active={active} />
                  </Grid>
               </CreatedListingFormContextAvilability>
            </>
         );
      case "hardware":
         return (
            <>
               <CreatedListingFormContextHardware setActive={setActiveFunc}>
                  <Grid item display={"flex"} sx={{ paddingLeft: "20px" }}>
                     <ListingsCreatedTop setActive={setActiveFunc} />
                  </Grid>
                  <Grid
                     item
                     display={"flex"}
                     flex={1}
                     sx={{ paddingLeft: "20px" }}
                  >
                     <Grid
                        container
                        direction={"row"}
                        display="flex"
                        gap={"10px"}
                     >
                        <Grid item flex={1.5}>
                           {/* Middle container */}
                           <ListingsCreateMiddleContainer>
                              <CreatedListingHardwareTab
                                 setActive={setActiveFunc}
                              />
                           </ListingsCreateMiddleContainer>
                        </Grid>
                        <Grid item flex={1}>
                           {/* Right container */}

                           <ListingsCreateRightContainer>
                              <CreatedPhotosTab setActive={setActiveFunc} />
                           </ListingsCreateRightContainer>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item>
                     <BottomSubmitComponent active={active} />
                  </Grid>
               </CreatedListingFormContextHardware>
            </>
         );
      case "pre-approval":
         return (
            <>
               <CreatedListingFormContextApproval setActive={setActiveFunc}>
                  <Grid item display={"flex"} sx={{ paddingLeft: "20px" }}>
                     <ListingsCreatedTop setActive={setActiveFunc} />
                  </Grid>
                  <Grid
                     item
                     display={"flex"}
                     flex={1}
                     sx={{ paddingLeft: "20px" }}
                  >
                     <Grid
                        container
                        direction={"row"}
                        display="flex"
                        gap={"10px"}
                     >
                        <Grid item flex={1.5}>
                           {/* Middle container */}
                           <ListingsCreateMiddleContainer>
                              <CreatedListingPreApprovalTab
                                 setActive={setActiveFunc}
                              />
                           </ListingsCreateMiddleContainer>
                        </Grid>
                        <Grid item flex={1}>
                           {/* Right container */}

                           <ListingsCreateRightContainer>
                              <CreatedPhotosTab setActive={setActiveFunc} />
                           </ListingsCreateRightContainer>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item>
                     <BottomSubmitComponent active={active} />
                  </Grid>
               </CreatedListingFormContextApproval>
            </>
         );
      default:
         return (
            <>
               <div>404 not found</div>
            </>
         );
   }
};
