import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";
import { ReactComponent as InfoIcon } from "../../styles/assets/Icons/info-main-icon-active.svg";
import { ReactComponent as CloseIcon } from "../../styles/assets/Icons/close-button-2.svg";

function RegisterRequestLicenseComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [answer, setAnswer] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [displayInfoPanel, setDisplayInfoPanel] = useState(false);

  useEffect(() => {
    //if(props.defaultValue) setYes();
    //else setNo()
  }, [props.defaultValue]);

  function setYes() {
    (document.querySelector("#yes") as HTMLBaseElement).style.background =
      "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)";
    (document.querySelector("#yes span") as HTMLBaseElement).style.color =
      "white";

    (document.querySelector("#no") as HTMLBaseElement).style.background =
      "initial";
    (document.querySelector("#no span") as HTMLBaseElement).style.color =
      "initial";

    setAnswer(true);
    setNextButtonDisabled(false);
  }

  function setNo() {
    (document.querySelector("#no") as HTMLBaseElement).style.background =
      "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)";
    (document.querySelector("#no span") as HTMLBaseElement).style.color =
      "white";

    (document.querySelector("#yes") as HTMLBaseElement).style.background =
      "initial";
    (document.querySelector("#yes span") as HTMLBaseElement).style.color =
      "initial";
    setAnswer(false);
    setNextButtonDisabled(false);
  }

  function nextStep() {
    if (props.onSubmitRequestLicense)
      props.onSubmitRequestLicense(
        answer,
        props.nextStepOption1,
        props.nextStepOption2
      );
  }

  return (
    <section className={classes.registerRequestLicensecontainer}>
      <h2 className={classes.registerRequestLicenseHeader}>
        Do you legally need to display a<br />
        license number with your listing?
        <InfoIcon
          className={classes.infoIcon}
          onClick={() => {
            setDisplayInfoPanel(true);
          }}
        />
        <div
          className={`${classes.infoPanel} ${
            displayInfoPanel ? classes.displayInfoHeader : ""
          }`}
        >
          <div className={classes.headerContainer}>
            <p className={classes.infoPanelHeader}>
              Real Estate License Number
            </p>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => {
                setDisplayInfoPanel(false);
              }}
            />
          </div>
          <Divider className={classes.dividerLine}></Divider>
          <p className={classes.infoPanelBodyText}>
            Some states require real estate agents / brokers
            <br />
            to display their real estate license number on all
            <br />
            their listings.
          </p>
        </div>
      </h2>
      <div className={classes.outerInputBox}>
        <RoundedButton
          id={"yes"}
          listener={setYes}
          boldText={true}
          showShadow={true}
          showArrow={false}
          arrowDirection={"right"}
          color="white"
          text="YES"
        />

        <RoundedButton
          id={"no"}
          listener={setNo}
          boldText={true}
          showShadow={true}
          showArrow={false}
          arrowDirection={"right"}
          color="white"
          text="NO"
        />
      </div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          listener={nextStep}
          disabled={nextButtonDisabled}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterRequestLicenseComponent" })(
  (theme) => ({
    registerRequestLicensecontainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      marginTop: "10%",
      "@media (max-height: 660px)": {
        marginTop: "4%",
      },
    },

    registerRequestLicenseHeader: {
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      marginBottom: "75px",
      textAlign: "center",
      position: "relative",
    },
    requestLicenseSubtext: {
      fontSize: "12px",
      fontFamily: theme.fonts.primary.light,
      maxWidth: "310px",
      marginBottom: "20px",
    },
    requestLicenseLink: {
      color: theme.palette.common.primary,
    },
    checkboxcontainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    IAgreeText: {
      margin: 0,
      fontWeight: "bold",
      fontSize: "13px",
      fontFamily: theme.fonts.secondary.bold,
    },
    outerInputBox: {
      height: "70px",
      display: "flex",
      alignItems: "center",
    },
    registerInputBoxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    registerButtonsContainer: {
      marginTop: "120px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    },
    registerMailIcon: {
      height: "10px",
      width: "auto",
      marginRight: "5px",
    },
    registerRequestLicenseInput: {
      border: "none",
      outline: "none",
      width: "80%",
      height: "20px",
      cursor: "text",
      backgroundColor: "transparent",
    },
    registerDividerLine: {
      height: "1px",
      width: "100%",
      backgroundColor: theme.palette.common.darkGray,
      marginBottom: "100px",
    },
    registerButtonSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    infoIcon: {
      position: "absolute",
      right: -30,
      bottom: 7,
      cursor: "pointer",
    },
    infoPanel: {
      width: "350px",
      height: "auto",
      display: "none",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "15px",
      position: "absolute",
      right: -420,
      bottom: -100,
      boxShadow: "1px 1px 10px 1px #00000014",
      paddingLeft: "15px",
      paddingRight: "15px",
      "@media (max-width: 1165px)": {
        right: -35,
        bottom: -150,
      },
    },
    infoPanelHeader: {
      color: "black",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginBottom: "8px",
      marginTop: "20px",
      textAlign: "left",
      alignSelf: "baseline",
    },
    displayInfoHeader: {
      display: "flex !important",
    },
    dividerLine: {
      // display: "flex !important",
      // height: "1px",
      width: "97%",
      borderBottomWidth: "2px",
      borderBottomColor: "#A2A2A2",
      opacity: 1,
    },
    infoPanelBodyText: {
      color: "black",
      fontFamily: theme.fonts.primary.med,
      fontWeight: "normal",
      fontSize: "15px",
      marginBottom: "90px",
      marginTop: "20px",
      marginLeft: "7px",
      textAlign: "left",
      alignSelf: "baseline",
    },
    headerContainer: {
      position: "relative",
      alignSelf: "baseline",
    },
    closeIcon: {
      position: "absolute",
      right: -70,
      bottom: 30,
      cursor: "pointer",
    },
  })
);

export default RegisterRequestLicenseComponent;
