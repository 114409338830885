import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as BlueToothLock } from "../../../../styles/assets/Icons/bluetooth-lock.svg";
import { ReactComponent as InstructionsIcon } from "../../../../styles/assets/Icons/instructions-icon.svg";
import { ReactComponent as BlueToothLockBlack } from "../../../../styles/assets/Icons/bluetooth-lock-black.svg";
import { ReactComponent as InstructionsIconBlack } from "../../../../styles/assets/Icons/instructions-icon-black.svg";
import { AccessChoice } from "../primary-tabs/listings.hardware.tab";

export const ListingLockInstructionsComponent: React.FC<{
   children?: React.ReactNode;
   setAccessChoiceInstructions: () => void;
   setAccessChoiceLock: () => void;
   accessChoice: AccessChoice | null;
}> = ({
   children,
   setAccessChoiceInstructions,
   setAccessChoiceLock,
   accessChoice,
}) => {
   const { classes } = useStyles();

   return (
      <>
         <Grid
            container
            direction="row"
            display={"flex"}
            className={classes.mainContainer}
         >
            <Grid item>
               <Grid
                  container
                  direction={"column"}
                  display={"flex"}
                  alignItems={"center"}
                  className={
                     accessChoice === AccessChoice.INSTRUCTIONS
                        ? classes.buttonContainerActive
                        : classes.buttonContainer
                  }
                  sx={{
                     paddingTop: "35px",
                  }}
                  onClick={() => {
                     setAccessChoiceInstructions();
                  }}
               >
                  <Grid item>
                     {accessChoice === AccessChoice.INSTRUCTIONS ? (
                        <InstructionsIconBlack
                           style={{ overflow: "visible" }}
                        />
                     ) : (
                        <InstructionsIcon style={{ overflow: "visible" }} />
                     )}
                  </Grid>
                  <Grid
                     item
                     alignItems={"center"}
                     justifyContent={"center"}
                     display={"flex"}
                     sx={{
                        color:
                           accessChoice === AccessChoice.INSTRUCTIONS
                              ? "black"
                              : "#A2A2A2",
                        fontSize: "16px",
                        width: "108px",
                        textAlign: "center",
                     }}
                  >
                     Instructions in Listing
                  </Grid>
               </Grid>
            </Grid>
            <Grid item>
               <Grid
                  container
                  direction={"column"}
                  display={"flex"}
                  alignItems={"center"}
                  className={
                     accessChoice === AccessChoice.LOCK
                        ? classes.buttonContainerActive
                        : classes.buttonContainer
                  }
                  sx={{
                     paddingTop: "21px",
                  }}
                  onClick={() => {
                     setAccessChoiceLock();
                  }}
               >
                  <Grid item>
                     {accessChoice === AccessChoice.LOCK ? (
                        <BlueToothLockBlack />
                     ) : (
                        <BlueToothLock />
                     )}
                  </Grid>
                  <Grid
                     item
                     alignItems={"center"}
                     justifyContent={"center"}
                     display={"flex"}
                     sx={{
                        color:
                           accessChoice === AccessChoice.LOCK
                              ? "black"
                              : "#A2A2A2",
                        fontSize: "16px",
                        width: "108px",
                        textAlign: "center",
                     }}
                  >
                     Add InstaShow Lock
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Listings-Lock-Instructions-Component" })(
   (theme) => ({
      mainContainer: {
         gap: "30px",
      },
      buttonContainer: {
         height: "150px",
         width: "150px",
         borderRadius: "25px",
         cursor: "pointer",
         gap: "5px",
         boxShadow: "6px 6px 12px #00000014",
      },
      buttonContainerActive: {
         height: "150px",
         width: "150px",
         borderRadius: "25px",
         cursor: "pointer",
         gap: "5px",
         border: "2px solid #D44293",
      },
   })
);
