import react from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { JsxElement, isJsxElement } from "typescript";

//positiive denotes a buy + or spending - of tokens
interface TokenHistory {
  id: string;
  text: string;
  positive: boolean;
  tokenAmount: number;
}

interface TokensHistoryListProps {
  data: TokenHistory[];
}

function TokensHistoryList(props: TokensHistoryListProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {}, [props.data]);

  function formatTokenHistory() {
    let elements: any = [];
    props.data.map((tokenHistory, index) => {
      elements.push(
        <li className={classes.TokenHistoryListItemOuter}>
          <div className={classes.TokenHistoryListItemTextRow}>
            <p className={classes.TokenHistoryDescriptionText}>
              {tokenHistory.positive ? "Tokens Purchased" : "Tokens Spent"}
            </p>
            <p
              className={
                tokenHistory.positive
                  ? [
                      classes.TokenHistoryPositive,
                      classes.TokenHistoryDescriptionText,
                    ].join(" , ")
                  : [
                      classes.TokenHistoryNegative,
                      classes.TokenHistoryDescriptionText,
                    ].join(" , ")
              }
            >
              {tokenHistory.positive
                ? "+" + tokenHistory.tokenAmount
                : "-" + tokenHistory.tokenAmount}
            </p>
          </div>
        </li>
      );
    });
    return elements;
  }

  return (
    <>
      {/*  */}
      <ul className={classes.TokenHistoryList}>{formatTokenHistory()}</ul>
    </>
  );
}

const useStyles = makeStyles({ name: "TokensHistoryList" })((theme) => ({
  TokenHistoryList: {
    listStyle: "none",
    padding: "0",
    margin: " 0",
  },
  TokenHistoryListItemOuter: {
    marginBottom: "5px",
    fontSize: "12px",
  },
  TokenHistoryListItemTextRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  TokenHistoryDescriptionText: {
    fontWeight: 100,
    fontSize: "12px",
    margin: "0",
    marginBottom: "10px",
  },
  TokenHistoryPositive: {
    color: "#6ad4a6",
    fontWeight: "500",
  },
  TokenHistoryNegative: {
    color: "red",
    fontWeight: "500",
  },
}));

export default TokensHistoryList;
