import React, { useReducer } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { mainTheme } from "../../../../styles/mainTheme";
import { FileUpload } from "../../../common/file.upload.component";
import { useState, useEffect } from "react";
import { PreviewImage } from "../../../common/preview.image";
import { ArrayPhotoComponent } from "./created.additional.photos";
import { SingleListingActions, useSingleListingDispatch, useSingleListingState } from "../../../../contexts/single-listing/singleListing.context";
import { Action } from "@remix-run/router";
import { S3DirectoryName } from "../../../../contexts/api/api.types";
import axios from 'axios'
import { useParams } from "react-router-dom";
import { createPropertyStepTwoPhotos, generatePreSignedUrl } from "../../../../contexts/api/api.functions";
const { v4: uuidv4 } = require('uuid');

export interface Photo {
  file: any;
}

export type PhotoAction =
  | { type: "add"; payload?: undefined }
  | { type: "remove"; payload: number }
  | { type: "update"; payload: { index: number; data: Photo } }
  | { type: "sort"; payload?: undefined };

export interface PhotoState {
  photos: Array<Photo>;
}

const reducer = (state: PhotoState, action: PhotoAction) => {
  switch (action.type) {
    case "add":
      const newPhotos: Array<Photo> = [
        ...state.photos,
        {
          file: "",
        },
      ];
      return { photos: newPhotos };

    case "remove":
      if (state.photos.length === 1) {
        state.photos[action.payload].file = ""
        let removed;
        removed = [...state.photos];

        return {
          photos: removed,
        };
      }


      state.photos.splice(action.payload, 1);
      let removed;
      removed = [...state.photos];
      //console.log("remove")
      return {
        photos: removed,
      };
    case "update":
      let updateIndex = state.photos[action.payload.index];
      //console.log(updateIndex);
      let update = action.payload.data;
      //console.log(update);
      state.photos.splice(action.payload.index, 1, update);
      let updatedArr = [...state.photos];
      //console.log("update")
      return {
        photos: updatedArr,
      };
    case "sort":
      let currentArr = state.photos;
      currentArr.sort((a, b) => {
        if (a.file !== "" && b.file !== "") {
          return 0;
        }

        if (a.file === "") {
          return 1;
        }

        if (b.file === "") {
          return -1;
        }

        return 0;
      });
      //console.log("sort")
      let newArr = [...currentArr];
      return {
        photos: newArr,
      };
  }
};

export const CreatedPhotosTab: React.FC<{ setActive: (value: boolean) => void }> = ({ setActive }) => {
  const { classes } = useStyles();
  const [feature, setFeature] = useState<any>("");
  const [error, setError] = useState("");
  const { featurePhotoPath, photoPaths, photos, featurePhoto, propertyId } = useSingleListingState();
  const dispatch = useSingleListingDispatch();
  const { ListingIdParam } = useParams<{ ListingIdParam: string }>()
  const [photosArray, updatePhotos] = useReducer(reducer, {
    photos: [
      {
        file: "",
      },
      {
        file: "",
      },
    ],
  });

  useEffect(() => {
    if (feature !== "") {
      dispatch({
        type: "setFeature",
        payload: { file: feature },
      });
    } else {
      return;
    }
  }, [feature]);

  useEffect(() => {
    const arr = photosArray.photos.filter((photo) => photo.file !== "");
    dispatch({
      type: "setPhotos",
      payload: [...arr],
    });
  }, [photosArray]);



  const handleAddPhotoFile = (photo: Photo, index: number) => {
    setActive(true)
    // if (feature === "") {
    //     setError("You must first add a feature photo.")
    //     return
    // }
    updatePhotos({ type: "update", payload: { index: index, data: photo } });


    // if (index === 0) {
    //   updatePhotos({ type: "sort" });

    //   return;
    // } else {

    if (photosArray.photos.length - 1 === index) {
      updatePhotos({ type: "add" });
      updatePhotos({ type: "sort" });
    } else {
      updatePhotos({ type: "sort" });

      return;
    }
    
    //console.log("calling updatePhotos()")
  };

  const removePhoto = (index: number) => {
    setActive(true)
    updatePhotos({
      type: "remove",
      payload: index,
    });
    updatePhotos({
      type: "sort",
    });
    let addedPhotos = photosArray.photos.filter((photo) => photo.file !== "");

    //console.log(addedPhotos + "added photos");
    dispatch({
      type: "setPhotos",
      payload: [...addedPhotos],
    });
    
    //console.log("calling removePhoto()")
  };


  const handleRemovePhotoFromContext = (key: string) => {
    const savedPhotos = photoPaths;
    if (savedPhotos && savedPhotos.length > 0) {
      const match = savedPhotos.find(photoKey => photoKey === key);
      if (match) {
        const index = savedPhotos.indexOf(match);
        if (index !== -1) {
          savedPhotos.splice(index, 1)
          dispatch({ type: SingleListingActions.setPhotoPaths, payload: savedPhotos })
        }
      }

    }
  }




  return (
    <>
      <Grid
        container
        className={classes.mainContainer}
        direction={"column"}
        gap="20px"
        display="flex"
      >
        <Grid item>
          <div className={classes.tabTitle}>Photos</div>
        </Grid>
        <Grid item>
          <Grid container direction={"column"} display="flex">
            <Grid item>
              <div className={classes.subHeader}>FEATURE PHOTO</div>
            </Grid>
            <Grid item>
              {featurePhotoPath ? <PreviewImage
                showMenu={true}
                height="210px"
                width="501.59px"
                clearImage={() => {
                  setFeature("");
                  dispatch({
                    type: SingleListingActions.setFeaturePhotoPath,
                    payload: null
                  })
                  dispatch({
                    type: SingleListingActions.setFeature,
                    payload: null
                  })
                  setActive(true)
                }}
                file={`https://boxlty-media.s3.amazonaws.com/property-images/${featurePhotoPath}`}
              /> : feature ? (
                <PreviewImage
                  showMenu={true}
                  height="210px"
                  width="501.59px"
                  clearImage={() => {
                    setFeature("");
                  }}
                  file={URL.createObjectURL(feature)}
                />
              ) : (
                <FileUpload
                  id="feature"
                  file={feature}
                  setFile={setFeature}
                  height="180px"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            display={"flex"}
            gap="10px"
            sx={{ overflowY: "auto" }}
          >
            <Grid
              item
              justifySelf={"center"}
              display="flex"
              alignSelf={"center"}
            >
              <div className={classes.subHeader}>
                {`ADDITIONAL PHOTOS (WILL SHOW IN ORDER)`}
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                display={"flex"}
                justifyContent="space-between"
                gap={"50px"}
                sx={{
                  flexWrap: "wrap",
                  maxHeight: "300px",
                  overflowY: "scroll",
                }}
              >
                {photoPaths !== null && photoPaths.length > 0 ? photoPaths.map((photo, index) => (
                  <PreviewImage
                    key={index}
                    height="155px"
                    width="205px"
                    showMenu={true}
                    file={`https://boxlty-media.s3.amazonaws.com/property-images/${photo}`}
                    clearImage={() => {
                      handleRemovePhotoFromContext(photo)
                      setActive(true)
                    }}
                  />
                )) : null}
                {photosArray.photos.map((photo, index) =>
                  photo.file !== "" ? (
                    <PreviewImage
                      key={index}
                      height="155px"
                      width="205px"
                      showMenu={true}
                      file={URL.createObjectURL(photosArray.photos[index].file)}
                      clearImage={() => removePhoto(index)}
                    />
                  ) : (
                    <FileUpload
                      id={index}
                      key={index}
                      file={photo.file}
                      setFile={(e) => handleAddPhotoFile({ file: e }, index)}
                      height="125px"
                      width="175px"
                    />
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Photos-Tab" })((theme) => ({
  mainContainer: {
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  tabTitle: {
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    fontSize: "32px",
  },
  subHeader: {
    color: "#A2A2A2",
    fontSize: "14px",
    marginBottom: "5px",
  },
}));
