import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const CreateListingBody:React.FC<{ children: React.ReactNode }> = ({children}) => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      className={classes.mainBodyContainer}
      direction={"column"}
    >
      {children}
    </Grid>
  );
};

const useStyles = makeStyles({ name: "Create-Listing-Body" })((theme) => ({
  mainBodyContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    borderTopLeftRadius: "25px",
    paddingRight: "20px",
    paddingTop: '20px',
    paddingBottom: '125px',
    backgroundColor: "#F5F6F6",
    flexDirection : 'column',
    overflowY: 'auto',
    flexWrap: 'nowrap'
  },
}));

export default CreateListingBody;