import React from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import RegisterBusinessTierPlanCardComponent from "./register-businesstier-component";

function RegisterBusinessTierStepComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  useEffect(() => {}, []);

  return (
    <section className={classes.registerPhonecontainer}>
      <h2 className={classes.registerPhoneHeader}>
        Which Business tier is right for you?
      </h2>
      <Grid container direction={"row"} justifyContent="center">
        <RegisterBusinessTierPlanCardComponent
          StatusName="Business Tier 1"
          ActiveListings="1"
          CostPerMonth="$49.99"
          CostPerMonthYear="$39.99"
          MonthChosen={true}
          SetChosenPlan={props.onSubmitChoosePlan}
          NextStepOption1={props.nextStepOption1}
          MonthlyOption={true}
          ListingNumbers="1-5 Active Listings"
        />
        <RegisterBusinessTierPlanCardComponent
          StatusName="Business Tier 2"
          ActiveListings="1"
          CostPerMonth="$99.99"
          CostPerMonthYear="$89.99"
          MonthChosen={true}
          SetChosenPlan={props.onSubmitChoosePlan}
          NextStepOption1={props.nextStepOption1}
          MonthlyOption={true}
          ListingNumbers="6-9 Active Listings"
        />
      </Grid>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterPhoneComponent" })((theme) => ({
  registerPhonecontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },

  registerPhoneHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
  },
  outerInputBox: {
    height: "45px",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "145px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerPhoneInput: {
    border: "none",
    outline: "none",
    width: "80%",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: theme.palette.common.lightGray, // Replace this with your desired color
    },
  },
  registerDividerLine: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: "100px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  invalidInput: {
    "&::placeholder": {
      color: "red", // Replace this with your desired color
    },
  },
}));

export default RegisterBusinessTierStepComponent;
