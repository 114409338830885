import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useGradient } from "../../../contexts/gradients/gradients.context";
import UpdateStripePaymentMethodComponent from "../../stripe/UpdatePaymentMethodComponent";
import { useNavigate } from "react-router-dom";

interface PlanDetailsProps {
   StatusName: string;
   MonthChosen: boolean;
   PaymentCardType: string;
   onUpgradeClick?: (event: React.MouseEvent<HTMLElement>) => void;
   onChangePaymentClick?: (event: React.MouseEvent<HTMLElement>) => void;
   FinsishedLoading: boolean;
}

function PlanDetailsComponent(props: PlanDetailsProps) {
   const { classes } = useStyles();
   const { primaryButtonsGradient } = useGradient();
   const [availableTokens, setAvailableTokens] = useState(0);
   const [changeMethodClicked, setChangeMethodClicked] = useState(false);
   const [card_on_file, setCardOnFile] = useState<any>(null);
   const ENV = process.env.REACT_APP_ENV;
   const navigate = useNavigate();

   useEffect(() => {
      GetDefaultPaymentMethod();
   }, [props.StatusName, props.MonthChosen, changeMethodClicked]);

   function GetDefaultPaymentMethod() {
      //get payment intent, on change -> get new payment intent for new token amount
      let request = new XMLHttpRequest();
      request.open(
         "GET",
         ENV === "production"
            ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/default-payment-method`
            : ENV === "staging"
            ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/default-payment-method`
            : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/default-payment-method`
      );
      const token =
         (sessionStorage.getItem("access_token")
            ? sessionStorage.getItem("access_token")
            : localStorage.getItem("access_token")) ?? "";
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET_DEV;
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("access_token", token);
      request.setRequestHeader("is_website", "true");
      if (clientSecret != null)
         request.setRequestHeader("client_secret", clientSecret);
      request.send();
      request.onload = () => {
         let response = JSON.parse(request.response);
         if (response.success) {
            //console.log("setting crad on file");
            setCardOnFile(response.default_payment_method);
         } else {
         }
      };
   }

   const planLookup: any = {
      monthly: {
         free: 0.0,
         plus: 29.99,
         "business Tier 1": 49.99,
         "business Tier 2": 99.99,
         enterprise: 0.0,
      },
      yearly: {
         free: 0.0,
         plus: 19.99,
         "business Tier 1": 39.99,
         "business Tier 2": 89.99,
         enterprise: 0.0,
      },
   };

   return (
      <>
         <h5 className={classes.SectionTitle}>Plan details</h5>

         {props.FinsishedLoading && (
            <>
               <div className={classes.SectionPlanRow}>
                  <h3 className={classes.AccountPlanText}>
                     {props.StatusName}
                     {"  "}
                     {props.StatusName !== "free" ? (
                        <span className={classes.FrequencySubText}>
                           paid {props.MonthChosen ? "monthly" : "yearly"}
                        </span>
                     ) : (
                        <></>
                     )}
                  </h3>
                  <h3 className={classes.PlanPriceText}>
                     {"$" +
                        planLookup[props.MonthChosen ? "monthly" : "yearly"][
                           props.StatusName
                        ].toFixed(2)}
                     <span className={classes.MonthlyText}>{"/mo"}</span>
                  </h3>
               </div>

               <div className={classes.SectionDivider}></div>

               {props.StatusName !== "free" && (
                  <h3 className={classes.SectionSubTitle}>Payment Details</h3>
               )}
               <div className={classes.SectionPlanRow}>
                  {props.StatusName === "free" && (
                     <p className={classes.AvailableTokensText}>
                        Available tokens :{" "}
                        {availableTokens.toString().length === 2
                           ? availableTokens
                           : "0" + availableTokens}
                     </p>
                  )}
                  {props.StatusName !== "free" && (
                     <h3 className={classes.CardTypeText}>
                        {card_on_file != null
                           ? card_on_file.card.brand.toUpperCase()
                           : props.PaymentCardType}
                        {"  "}
                        <span className={classes.FrequencySubText}>
                           {card_on_file != null
                              ? "ending in " + card_on_file.card.last4
                              : "No Default Payment Set"}
                        </span>
                     </h3>
                  )}
                  {props.StatusName === "free" && (
                     <span
                        className={classes.PurchaseTokensText}
                        onClick={() => {
                           navigate("/tokens");
                        }}
                     >
                        Purchase tokens
                     </span>
                  )}
                  {props.StatusName !== "free" && (
                     <span
                        className={classes.PurchaseTokensText}
                        onClick={() => {
                           //navigate to stripe

                           setChangeMethodClicked(true);
                        }}
                     >
                        Change payment method
                     </span>
                  )}
               </div>

               <div className={classes.SectionDivider}></div>

               {changeMethodClicked ? (
                  <section className={classes.UpdatePaymentMethodContainer}>
                     <UpdateStripePaymentMethodComponent
                        onCancel={() => {
                           setChangeMethodClicked(false);
                        }}
                     />
                  </section>
               ) : (
                  <></>
               )}

               <div
                  className={classes.UpgradeButtonContainer}
                  onClick={props.onUpgradeClick}
               >
                  <Button
                     variant="contained"
                     size="small"
                     endIcon={
                        props.StatusName === "free" ? (
                           <ArrowUpwardIcon
                              style={{
                                 marginLeft: "-15px",
                                 padding: "0",
                                 height: "25px",
                              }}
                              sx={{ margin: "0", padding: "0", height: "50%" }}
                           />
                        ) : (
                           ""
                        )
                     }
                     sx={{
                        height: "36px",
                        width: "124px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        borderRadius: "26px",
                        backgroundImage: `${primaryButtonsGradient}`,
                     }}
                  >
                     {props.StatusName === "free" ? (
                        <p className={classes.UpgradeButtonText}>Upgrade</p>
                     ) : (
                        <p className={classes.ChangePlanButtonText}>
                           Change Plan
                        </p>
                     )}
                  </Button>
               </div>
            </>
         )}
      </>
   );
}

const useStyles = makeStyles({ name: "PlanDetails" })((theme) => ({
   Section: {
      backgroundColor: "white",
      padding: "5px",
      paddingTop: "8px",
      paddingLeft: "15px",
      borderRadius: "15px",
      minWidth: "400px",
      minHeight: "100px",
      marginTop: "15px",
      marginBottom: "15px",
      boxShadow: "2px 3px 15px -5px black",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "600px",
   },
   SectionTitle: {
      margin: "0",
      fontWeight: "500",
      fontFamily: theme.fonts.primary.med,
      fontSize: "16px",
      marginBottom: "5px",
   },
   SectionSubTitle: {
      margin: "0",
      fontWeight: "500",
      fontFamily: theme.fonts.primary.med,
      fontSize: "16px",
      marginBottom: "5px",
      color: theme.palette.common.medGray,
   },
   SectionPlanRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "5px",
      marginBottom: "5px",
   },
   MonthlyText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: theme.fonts.secondary.reg,
   },
   AccountPlanText: {
      textTransform: "capitalize",
      margin: "0",
      fontSize: "22px",
   },
   PlanPriceText: {
      margin: "0",
      marginLeft: "auto",
      marginRight: "20px",
      fontSize: "28px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
   },
   SectionDivider: {
      backgroundColor: theme.palette.common.lightGray,
      height: "1px",
      minHeight: "1px",
      maxHeight: "1px",
      width: "97%",
      marginBottom: "5px",
      marginTop: "5px",
   },
   AvailableTokensText: {
      fontSize: "16px",
      margin: "0",
      color: "#707070",
   },
   CardTypeText: {
      fontSize: "16px",
      margin: "0",
      color: theme.palette.common.black,
   },
   PurchaseTokensText: {
      fontFamily: theme.fonts.primary.reg,
      color: theme.palette.common.primary,
      fontSize: "14px",
      margin: "0",
      marginLeft: "auto",
      marginRight: "20px",
      cursor: "pointer",
   },
   UpgradeButtonContainer: {
      marginTop: "10px",
      marginLeft: "auto",
      marginRight: "10px",
      marginBottom: "10px",
   },
   UpgradeButtonText: {
      margin: "0",
      marginTop: "3px",
      display: "flex",
      alignItems: "center",
      justifyContent: " center",
      textAlign: "center",
      height: "100%",
      fontSize: "16px",
      width: "100%",
      fontFamily: theme.fonts.secondary.light,
      letterSpacing: "0.5px",
      marginRight: "5px",
   },
   ChangePlanButtonText: {
      margin: "0",
      marginTop: "3px",
      display: "flex",
      alignItems: "center",
      justifyContent: " center",
      textAlign: "center",
      height: "100%",
      fontSize: "13px",
      width: "100%",
      fontFamily: theme.fonts.secondary.light,
      letterSpacing: "0.5px",
      marginRight: "0px",
   },
   NoInfoText: {
      margin: "0",
      fontSize: "16px",
      fontWeight: "100",
      color: theme.palette.common.medGray,
      fontFamily: theme.fonts.primary.reg,
   },
   FrequencySubText: {
      fontSize: "16px",
      fontWeight: "normal",
      color: theme.palette.common.medGray,
      // textTransform: "lowercase",
   },
   UpdatePaymentMethodContainer: {
      marginLeft: "auto",
      maxHeight: "500px",
      width: "100%",
      height: "900px",
      overflowY: "auto",
      overflowX: "hidden",
   },
}));

export default PlanDetailsComponent;
