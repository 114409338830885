import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
   Alert,
   Divider,
   Grid,
   IconButton,
   Menu,
   MenuItem,
   useTheme,
   AlertColor,
   Backdrop,
   CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
   activateProperty,
   deleteProperty,
   getUserLockList,
   makePropertyDraft,
   createPropertyStepFiveAssignLock,
   getListing,
   generatePreSignedUrl,
} from "../../../../contexts/api/api.functions";
import {
   SingleListingActions,
   useSingleListingDispatch,
   useSingleListingState,
} from "../../../../contexts/single-listing/singleListing.context";
import { ReactComponent as AddLock } from "../../../../styles/assets/Icons/qa-add-lock.svg";
import { BoxltyButtonV2 } from "../../../common/boxlty.button.v2";
import { FileUpload } from "../../../common/file.upload.component";
import { PreviewImage } from "../../../common/preview.image";
import { Lock } from "../../create/forms/forms.types";
import { HeaderButton } from "../../create/forms/header.button";
import { LockSelectComponent } from "../../create/forms/lock.select.component";
import AddLockModal from "../../../dashboard/AddLockModal";
import { Snackbar } from "@mui/material";
import { ManageModal } from "../../create/forms/manage.in-app.modal";
import { S3DirectoryName } from "../../../../contexts/api/api.types";
import axios from "axios";
import { useUserState } from "../../../../contexts/user/user.context";
import RoundedButton from "../../../common/boxlty.button";
import { mainTheme } from "../../../../styles/mainTheme";
import { AccessChoice } from "../../create/primary-tabs/listings.hardware.tab";
import { ReactComponent as InstructionsIcon } from "../../../../styles/assets/Icons/instructions-icon.svg";
import { ListingLockInstructionsComponent } from "../../create/forms/listing.lock.instructions.component";
const { v4: uuidv4 } = require("uuid");

type Props = {};

export const CreatedListingHardwareTab: React.FC<{
   setActive: (value: boolean) => void;
}> = ({ setActive }) => {
   const { classes } = useStyles();
   const [lockLocationFile, setLockLocationFile] = useState<File | null>(null);
   const [doorLocationFile, setDoorLocationFile] = useState<File | null>(null);
   const [addLockModalVisible, setAddLockModalVisible] = useState(false);
   const [addCameraModalVisible, setAddCameraModalVisible] = useState(false);
   const [manageModalOpen, setManageModalOpen] = useState(false);
   const [accessChoiceState, setAccessChoiceState] =
      useState<AccessChoice | null>(null);
   const [accessChoiceErrorOpen, setAccessChoiceErrorOpen] = useState(false);
   const { watch, setValue } = useFormContext();
   const { user } = useUserState();
   const [locks, setLocks] = useState<Lock[]>([]);
   const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
   const [selectedLock, setSelectedLock] = useState<string>("0");
   const [fullSelectedLock, setFullSelectedLock] = useState<Lock | null>();
   const [message, setMessage] = useState<string>("");
   const [backdropOpen, setBackdropOpen] = useState(false);
   const [createdSnackBarOpen, setCreatedSnackBarOpen] = useState(false);
   const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const dispatch = useSingleListingDispatch();
   const navigate = useNavigate();
   const { lockImagePath, doorImagePath, listingType } =
      useSingleListingState();
   const theme = useTheme();
   const [showUnassignLockPopup, setShowUnassignLockPopup] = useState(false);

   useEffect(() => {
      dispatch({
         type: SingleListingActions.setFeature,
         payload: { file: "" },
      });
      dispatch({ type: SingleListingActions.setPhotos, payload: [] });
   }, []);

   useEffect(() => {
      dispatch({
         type: SingleListingActions.setFeaturePhotoPath,
         payload: null,
      });
      dispatch({ type: SingleListingActions.setPhotoPaths, payload: null });
   }, []);
   // const values = watch()
   const fetchAssociatedLocks = async () => {
      const res = await getUserLockList();
      if (res && res.data && res.data.status === true) {
         setLocks(res.data.result);
      }
   };

   const handleClick = (e: any) => {
      setAnchorEl(e.currentTarget);
   };

   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   const contextLockKey = watch("lockKey");
   const contextLockTypeId = watch("lockTypeId");
   const contextIglooLockTypeId = watch("igloolockTypeId");
   const contextMasterLockTypeId = watch("masterLockTypeId");
   const contextAccessChoice = watch("accessChoice");

   const handleDelete = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         const res = await deleteProperty({ pId: parseInt(ListingIdParam) })
            .then(() => {
               navigate("/listings/active");
            })
            .catch((error) => {
               //console.log(error)
            });
      }
   };

   const handleArchive = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         if (!listingType || listingType === "") {
            return;
         }
         if (listingType === "archive") {
            handleMenuClose();
            return;
         }
         const res = await activateProperty({
            pId: parseInt(ListingIdParam),
            status: 0,
         })
            .then(() => {
               navigate("/listings/archived");
            })
            .catch((err) => {
               //console.log(err)
            });
      }
   };

   const setAccessChoiceInstructions = () => {
      setAccessChoiceState(AccessChoice.INSTRUCTIONS);
      setActive(true);
   };

   const setAccessChoiceLock = () => {
      setAccessChoiceState(AccessChoice.LOCK);
      setActive(true);
   };

   const handleDraft = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         if (!listingType || listingType === "") {
            return;
         }
         if (listingType === "draft") {
            handleMenuClose();
            return;
         }
         const res = await makePropertyDraft({ pId: parseInt(ListingIdParam) })
            .then(() => {
               navigate("/listings/draft");
            })
            .catch((err) => {
               // console.log(err)
            });
      }
   };

   const handleSaveDeletePhoto = async (lock: boolean) => {
      if (!ListingIdParam) {
         return;
      }
      if (contextAccessChoice === null) {
         setAccessChoiceErrorOpen(true);
         return;
      }

      let newLockImageKey: string | null = null;
      let newDoorImageKey: string | null = null;
      let lockImgKey: string = uuidv4();
      let doorImgKey: string = uuidv4();
      if (contextAccessChoice === AccessChoice.LOCK) {
         if (lockLocationFile) {
            const lockImageRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: lockImgKey,
            });
            if (
               lockImageRequest &&
               lockImageRequest.data &&
               lockImageRequest.data.status === true
            ) {
               const lockImgUrl = lockImageRequest.data.result;
               await axios
                  .put(lockImgUrl, lockLocationFile)
                  .then(() => {
                     newLockImageKey = lockImgKey;
                  })
                  .catch((error) => {});
            }
         }
         if (doorLocationFile) {
            const doorImageRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: doorImgKey,
            });
            if (
               doorImageRequest &&
               doorImageRequest.data &&
               doorImageRequest.data.status === true
            ) {
               const lockImgUrl = doorImageRequest.data.result;
               await axios
                  .put(lockImgUrl, lockLocationFile)
                  .then(() => {
                     newDoorImageKey = doorImgKey;
                  })
                  .catch((error) => {});
            }
         }
      }
      let body: any;
      if (contextAccessChoice === AccessChoice.LOCK) {
         body = {
            stepId: 5,
            propertyId: ListingIdParam,
            lockTypeId: contextLockTypeId.toString(),
            lockKey: contextLockKey,
            doorImg: lock ? doorImagePath : "",
            lockImg: lock ? "" : lockImagePath,
            igloolockTypeId: contextIglooLockTypeId
               ? contextIglooLockTypeId
               : "",
            masterlockTypeId: contextMasterLockTypeId
               ? contextMasterLockTypeId
               : "",
            accessChoice: 1,
         };
      } else {
         body = {
            stepId: 5,
            propertyId: ListingIdParam,
            lockTypeId: "",
            lockKey: "",
            doorImg: "",
            lockImg: "",
            igloolockTypeId: "",
            masterlockTypeId: "",
            accessChoice: 0,
         };
      }

      const res = await createPropertyStepFiveAssignLock(body);
      if (res && res.data && res.data.status === true) {
         return;
      } else {
         return;
      }
   };

   useEffect(() => {
      if (contextLockKey) {
         const full = locks.filter((lock) => {
            const key = lock.lockKey;
            return key === contextLockKey;
         });
         if (full && full.length > 0) {
            //console.log(full[0])
            setSelectedLock(full[0].id.toString());
         }
      }
   }, [contextLockKey, locks]);

   useEffect(() => {
      if (contextAccessChoice !== null) {
         setAccessChoiceState(contextAccessChoice);
      }
   }, [contextAccessChoice]);

   useEffect(() => {
      if (accessChoiceState !== null) {
         setValue("accessChoice", accessChoiceState);
      }
   }, [accessChoiceState]);

   useEffect(() => {
      if (selectedLock !== "0") {
         const full = locks.filter((lock) => {
            const id = lock.id.toString();
            return id === selectedLock;
         });
         if (full && full.length === 1) {
            setFullSelectedLock(full[0]);
         }
      } else {
         //console.log("no lock selected");
         setFullSelectedLock(null);
      }
   }, [selectedLock]);

   useEffect(() => {
      if (lockLocationFile !== null) {
         dispatch({
            type: SingleListingActions.setFeature,
            payload: { file: "" },
         });
         dispatch({ type: SingleListingActions.setPhotos, payload: [] });
      }
   }, []);

   useEffect(() => {
      dispatch({
         type: SingleListingActions.setFeaturePhotoPath,
         payload: null,
      });
      dispatch({ type: SingleListingActions.setPhotoPaths, payload: null });
   }, []);
   // const values = watch()

   useEffect(() => {
      if (contextLockKey) {
         const full = locks.filter((lock) => {
            const key = lock.lockKey;
            return key === contextLockKey;
         });
         if (full && full.length > 0) {
            //console.log(full[0])
            setSelectedLock(full[0].id.toString());
         }
      }
   }, [contextLockKey, locks]);

   useEffect(() => {
      if (selectedLock !== "0") {
         const full = locks.filter((lock) => {
            const id = lock.id.toString();
            return id === selectedLock;
         });
         if (full && full.length === 1) {
            setFullSelectedLock(full[0]);
         }
      } else {
         //console.log("no lock selected");
         setFullSelectedLock(null);
      }
   }, [selectedLock]);

   useEffect(() => {
      if (lockLocationFile !== null) {
         dispatch({
            type: SingleListingActions.setLockImage,
            payload: lockLocationFile,
         });
         setActive(true);
      }
   }, [lockLocationFile]);

   useEffect(() => {
      if (doorLocationFile !== null) {
         dispatch({
            type: SingleListingActions.setDoorImage,
            payload: doorLocationFile,
         });
         setActive(true);
      }
   }, [doorLocationFile]);

   useEffect(() => {
      if (fullSelectedLock) {
         setValue("lockTypeId", fullSelectedLock.lockTypeId);
         setValue("lockKey", fullSelectedLock.lockKey);
         if (fullSelectedLock.iglooLockTypeId) {
            setValue("iglooLockTypeId", fullSelectedLock.iglooLockTypeId);
         }
         if (fullSelectedLock.masterLockTypeId) {
            setValue("masterLockTypeId", fullSelectedLock.masterLockTypeId);
         }
      } else {
         //console.log("unassign lock");
         //set lockTypeId to 0 to unassign
         setValue("lockTypeId", 0);
         setValue("lockKey", "");
         setValue("iglooLockTypeId", "");
         setValue("masterLockTypeId", "");
      }
   }, [fullSelectedLock]);

   const setSelectedLockFunc = (value: string) => {
      setSelectedLock(value);
      setActive(true);
   };

   const handleLockSelected = (value: string) => {
      if (value == "0" && listingType == "active") {
         setShowUnassignLockPopup(true);
      } else {
         setSelectedLock(value);
         setActive(true);
      }
   };

   const setAddALockVisible = (value: boolean) => {
      setAddALockVisible(value);
   };

   const setSnackbar = (value: boolean) => {
      setCreatedSnackBarOpen(value);
   };

   const setSnackbarMessage = (value: string) => {
      setMessage(value);
   };

   const setSnackbarType = (value: AlertColor) => {
      setSnackBarType(value);
   };

   useEffect(() => {
      fetchAssociatedLocks();
   }, []);

   return (
      <>
         <Grid
            display={"flex"}
            direction="column"
            container
            className={classes.mainContainer}
         >
            {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}

        >
          <CircularProgress color="primary" />
        </Backdrop> */}
            <Snackbar
               open={createdSnackBarOpen}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               autoHideDuration={6000}
               onClose={() => {
                  setCreatedSnackBarOpen(false);
               }}
            >
               <Alert
                  severity={snackBarType}
                  onClose={() => {
                     setCreatedSnackBarOpen(false);
                  }}
               >
                  {message}
               </Alert>
            </Snackbar>
            <AddLockModal
               id={"add-lock-modal-create-listing"}
               isVisible={addLockModalVisible}
               refresh={false}
               setPanel={setAddALockVisible}
               setSnackbarType={setSnackbarType}
               setSnackbar={setSnackbar}
               setSnackbarMessage={setSnackbarMessage}
               fetch={fetchAssociatedLocks}
               openPosition={{
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "20vh",
               }}
               closedPosition={{
                  top: "0",
                  left: "0",
                  right: "-450px",
                  bottom: "20vh",
               }}
            />
            <Grid item>
               <Grid
                  container
                  direction="row"
                  display={"flex"}
                  justifyContent="space-between"
                  // Only need this margin if menu icon button is hidden
                  // sx={{ marginBottom: "5px" }}
               >
                  <Grid item>
                     <HeaderButton
                        color="black"
                        backgroundColor="white"
                        borderColor="#707070"
                        text={listingType !== "" ? listingType : "draft"}
                        clickable={false}
                     />
                  </Grid>

                  <Grid item sx={{ paddingRight: "5px" }}>
                     <IconButton
                        edge="start"
                        id="type-selection-button"
                        aria-controls={open ? "type-selection-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                     >
                        <MoreHorizIcon fontSize="large" />
                     </IconButton>
                     <Menu
                        id="type-selection-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                           "aria-labelledby": "type-selection-button",
                        }}
                        sx={{ postion: "relative", right: "60px" }}
                        anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "right",
                        }}
                        transformOrigin={{
                           vertical: "top",
                           horizontal: 95,
                        }}
                     >
                        <MenuItem onClick={handleDraft}>Draft</MenuItem>
                        {user?.idVerify === 1 ? (
                           <MenuItem onClick={handleArchive}>Archive</MenuItem>
                        ) : null}
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                     </Menu>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item className={classes.accessChoiceHeaderContainer}>
               <div className={classes.accessChoiceHeaderText}>
                  How will visitors access your listing?
               </div>
            </Grid>
            <Grid
               item
               className={classes.listingLockInstructionsComponentContainer}
            >
               <ListingLockInstructionsComponent
                  setAccessChoiceInstructions={setAccessChoiceInstructions}
                  setAccessChoiceLock={setAccessChoiceLock}
                  accessChoice={accessChoiceState}
               />
            </Grid>
            {accessChoiceState === AccessChoice.LOCK ? (
               <Grid item sx={{ paddingRight: "40px" }}>
                  <Grid
                     container
                     direction="row"
                     justifyContent={"space-between"}
                     display="flex"
                     flexWrap="nowrap"
                  >
                     {locks.length > 0 ? (
                        <Grid item>
                           <Grid
                              container
                              direction={"column"}
                              display="flex"
                              gap={"45px"}
                           >
                              <Grid item>
                                 <Grid
                                    container
                                    direction="column"
                                    display="flex"
                                    gap={"10px"}
                                 >
                                    <Grid item>
                                       <div className={classes.existingHeader}>
                                          Lock
                                       </div>
                                    </Grid>
                                    <Grid item>
                                       <LockSelectComponent
                                          locks={locks}
                                          selected={selectedLock}
                                          setSelected={handleLockSelected}
                                       />
                                    </Grid>
                                 </Grid>
                                 {showUnassignLockPopup && (
                                    <div
                                       className={classes.ActionsPopupOverlay}
                                    >
                                       <div className={classes.PopupCentered}>
                                          {/* Popup content */}
                                          <p className={classes.PopupHeader}>
                                             UNASSIGN LOCK
                                          </p>
                                          <p className={classes.PopupText}>
                                             You are about to unassign this
                                             lock. If this lock is on an active
                                             listing, the listing will become
                                             deactivated and moved to a Draft
                                             state.
                                          </p>
                                          <div
                                             className={
                                                classes.PopupOptionsSection
                                             }
                                          >
                                             <RoundedButton
                                                text="CANCEL"
                                                listener={() => {
                                                   setShowUnassignLockPopup(
                                                      false
                                                   );
                                                }}
                                                boldText={true}
                                             />
                                             <RoundedButton
                                                text="UNASSIGN"
                                                listener={() => {
                                                   setSelectedLock("0");
                                                   setActive(true);
                                                   dispatch({
                                                      type: SingleListingActions.setListingType,
                                                      payload: "draft",
                                                   });
                                                   setShowUnassignLockPopup(
                                                      false
                                                   );
                                                }}
                                                color="primary"
                                                boldText={true}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 )}
                              </Grid>
                              <Grid item>
                                 {(user?.accountType !== 6 ||
                                    user?.isEnterpriseSuperUser === 1) && (
                                    <BoxltyButtonV2
                                       text="ADD A NEW LOCK"
                                       endIcon={<AddIcon fontSize="small" />}
                                       width="175px"
                                       backgroundColor="white"
                                       borderColor={
                                          theme.palette.common.lightGray
                                       }
                                       textColor={
                                          theme.palette.common.lightGray
                                       }
                                       disabled={false}
                                       onClick={() => {
                                          //setManageModalOpen(true)
                                          setAddLockModalVisible(
                                             !addLockModalVisible
                                          );
                                       }}
                                    />
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     ) : (
                        <Grid item>
                           {(user?.accountType !== 6 ||
                              user?.isEnterpriseSuperUser === 1) && (
                              <IconButton
                                 disableRipple
                                 disableFocusRipple
                                 onClick={() => {
                                    setAddLockModalVisible(
                                       !addLockModalVisible
                                    );
                                 }}
                              >
                                 <AddLock />
                              </IconButton>
                           )}
                        </Grid>
                     )}
                     <Grid item>
                        <Grid
                           container
                           direction={"row"}
                           display="flex"
                           gap={"30px"}
                        >
                           <Grid item>
                              <Grid
                                 container
                                 direction={"column"}
                                 display="flex"
                              >
                                 <Grid item>
                                    <div
                                       className={classes.inputHeader}
                                    >{`LOCK LOCATION*`}</div>
                                 </Grid>
                                 <Grid item>
                                    {lockImagePath !== null ? (
                                       <img
                                          height="155px"
                                          width="155px"
                                          src={`https://boxlty-media.s3.amazonaws.com/property-images/${lockImagePath}`}
                                       />
                                    ) : lockLocationFile !== null ? (
                                       <PreviewImage
                                          showMenu={false}
                                          clearImage={() => {
                                             setLockLocationFile(null);
                                          }}
                                          height="155px"
                                          width="155px"
                                          file={URL.createObjectURL(
                                             lockLocationFile
                                          )}
                                       />
                                    ) : (
                                       <FileUpload
                                          id="lock-location"
                                          height="125px"
                                          width="125px"
                                          file={lockLocationFile}
                                          setFile={setLockLocationFile}
                                       />
                                    )}
                                 </Grid>
                                 <Grid
                                    item
                                    sx={{
                                       display: "flex",
                                       alignSelf: "center",
                                    }}
                                 >
                                    <div
                                       className={classes.inputSubHeader}
                                       onClick={() => {
                                          setLockLocationFile(null);
                                          dispatch({
                                             type: SingleListingActions.setLockImage,
                                             payload: null,
                                          });
                                          dispatch({
                                             type: SingleListingActions.setLockImagePath,
                                             payload: null,
                                          });
                                          handleSaveDeletePhoto(true);
                                       }}
                                    >
                                       Delete Image
                                    </div>
                                 </Grid>
                              </Grid>
                           </Grid>
                           <Grid item>
                              <Grid
                                 container
                                 direction={"column"}
                                 display="flex"
                              >
                                 <Grid item>
                                    <div
                                       className={classes.inputHeader}
                                    >{`DOOR LOCATION`}</div>
                                 </Grid>
                                 <Grid item>
                                    {doorImagePath !== null ? (
                                       <img
                                          height="155px"
                                          width="155px"
                                          src={`https://boxlty-media.s3.amazonaws.com/property-images/${doorImagePath}`}
                                       />
                                    ) : doorLocationFile !== null ? (
                                       <PreviewImage
                                          showMenu={false}
                                          clearImage={() => {
                                             setDoorLocationFile(null);
                                          }}
                                          height="155px"
                                          width="155px"
                                          file={URL.createObjectURL(
                                             doorLocationFile
                                          )}
                                       />
                                    ) : (
                                       <FileUpload
                                          id="lock-location"
                                          height="125px"
                                          width="125px"
                                          file={doorLocationFile}
                                          setFile={setDoorLocationFile}
                                       />
                                    )}
                                 </Grid>
                                 <Grid
                                    item
                                    sx={{
                                       display: "flex",
                                       alignSelf: "center",
                                    }}
                                 >
                                    <div
                                       className={classes.inputSubHeader}
                                       onClick={() => {
                                          setDoorLocationFile(null);
                                          dispatch({
                                             type: SingleListingActions.setDoorImage,
                                             payload: null,
                                          });
                                          dispatch({
                                             type: SingleListingActions.setDoorImagePath,
                                             payload: null,
                                          });
                                          handleSaveDeletePhoto(false);
                                       }}
                                    >
                                       Delete Image
                                    </div>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            ) : null}
            <Grid item sx={{ marginTop: "15px", width: "94%" }}>
               <Divider sx={{ borderBottomWidth: "2px" }} />
            </Grid>
            {/* <Grid item sx={{ marginTop: '15px' }}>
          <IconButton disableFocusRipple disableRipple onClick={() => { setAddCameraModalVisible(!addCameraModalVisible)}}>
            <AddCamera />
          </IconButton>
          <AddCameraModal id={'add-camera-modal-create-listing'}
            isVisible={addCameraModalVisible}
            openPosition={{ top: '0', left: '0', right: '0px', bottom: '20vh' }}
            closedPosition={{ top: '0', left: '0', right: '-350px', bottom: '20vh' }} />
        </Grid> */}
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Listing-Hardware-Tab" })((theme) => ({
   mainContainer: {
      paddingTop: "20px",
      paddingLeft: "15px",
      paddingRight: "10px",
      paddingBottom: "15px",
   },
   inputHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginBottom: "7px",
      textTransform: "uppercase",
      fontSize: "14px",
   },
   inputSubHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginTop: "8px",
      fontSize: "12px",
      textDecoration: "underline",
      cursor: "pointer",
   },
   existingHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "20px",
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      height: "200px",
      width: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "10px",
      marginRight: "10px",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
   },
   accessChoiceHeaderContainer: {
      marginBottom: "20px",
   },
   accessChoiceHeaderText: {
      fontSize: "22px",
      fontFamily: mainTheme.fonts.primary.bold,
      fontWeight: "bold",
   },
   listingLockInstructionsComponentContainer: {
      marginBottom: "40px",
   },
}));
