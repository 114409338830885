import React from "react";
import { makeStyles } from "tss-react/mui";
import lockIcon from "../../styles/assets/logos/lock.png";
import { Alert, Snackbar, useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";
import { useState, useEffect } from "react";

function RegisterPasswordComponent(props: any) {
   const { classes } = useStyles();
   const theme = useTheme();

   const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
   const [validationMessage, setValidationMessage] = useState("");

   useEffect(() => {
      if (props.defaultValue !== "" && props.defaultValue != null) {
         let defaultValid = validatePassword(props.defaultValue);
         setNextButtonDisabled(!defaultValid);
      }
   }, [props.defaultValue]);

   function setNextToActive(
      event:
         | React.ChangeEvent<HTMLInputElement>
         | React.KeyboardEvent<HTMLInputElement>
   ) {
      let passwordInput: HTMLInputElement = document.querySelector(
         "#register-password-input"
      ) as HTMLInputElement;
      let confirmPasswordInput: HTMLInputElement = document.querySelector(
         "#register-confirm-password-input"
      ) as HTMLInputElement;

      if ((event.target as HTMLInputElement).value.length >= 8) {
         //set button to active

         if (
            passwordInput.value.length >= 8 &&
            confirmPasswordInput.value.length >= 8
         ) {
            setNextButtonDisabled(false);
         }
      } else {
         //set to inactive
         setNextButtonDisabled(true);
      }
   }

   function validatePassword(password: string) {
      let isValid: boolean = true;
      let passwordInput: HTMLInputElement = document.querySelector(
         "#register-password-input"
      ) as HTMLInputElement;
      let confirmPasswordInput: HTMLInputElement = document.querySelector(
         "#register-confirm-password-input"
      ) as HTMLInputElement;
      let line1: HTMLElement = document.querySelector(
         "#divider-line-1"
      ) as HTMLInputElement;
      let line2: HTMLElement = document.querySelector(
         "#divider-line-2"
      ) as HTMLInputElement;

      // Define the criteria for the password
      const minLength = 8;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const specialCharRegex = /[!@#$%^&*]/;

      // Validate the password length
      if (password.length < minLength) {
         isValid = false;
         passwordInput.value = "";
         confirmPasswordInput.value = "";
         setValidationMessage("Must be 8+ characters long");

         line1.style.backgroundColor = "red";
         line2.style.backgroundColor = "red";
         return isValid;
      }

      // Validate the presence of an uppercase letter
      if (!uppercaseRegex.test(password)) {
         isValid = false;
         passwordInput.value = "";
         confirmPasswordInput.value = "";
         setValidationMessage("Must contain one uppercase letter");
         passwordInput.classList.add(classes.invalidInput);
         confirmPasswordInput.classList.add(classes.invalidInput);
         line1.style.backgroundColor = "red";
         line2.style.backgroundColor = "red";
         return isValid;
      }

      // Validate the presence of a lowercase letter
      if (!lowercaseRegex.test(password)) {
         isValid = false;
         passwordInput.value = "";
         confirmPasswordInput.value = "";
         setValidationMessage("Must contain one lowercase letter");
         line1.style.backgroundColor = "red";
         line2.style.backgroundColor = "red";
         return isValid;
      }

      // Validate the presence of a special character
      if (!specialCharRegex.test(password)) {
         isValid = false;
         passwordInput.value = "";
         confirmPasswordInput.value = "";
         setValidationMessage("Must contain one special character");
         passwordInput.classList.add(classes.invalidInput);
         confirmPasswordInput.classList.add(classes.invalidInput);
         line1.style.backgroundColor = "red";
         line2.style.backgroundColor = "red";
         return isValid;
      }

      // Validate the password and confirm password match
      if (password !== confirmPasswordInput.value) {
         isValid = false;
         passwordInput.value = "";
         confirmPasswordInput.value = "";
         setValidationMessage("Passwords do not match");
         passwordInput.classList.add(classes.invalidInput);
         confirmPasswordInput.classList.add(classes.invalidInput);
         line1.style.backgroundColor = "red";
         line2.style.backgroundColor = "red";
         return isValid;
      }

      // Reset the styling if the password is valid
      line1.style.backgroundColor = theme.palette.common.darkGray;
      line2.style.backgroundColor = theme.palette.common.darkGray;

      return isValid;
   }

   function nextStep() {
      let password: string = (
         document.querySelector("#register-password-input") as HTMLInputElement
      ).value;
      if (validatePassword(password)) {
         if (props.onSubmitPassword)
            props.onSubmitPassword(password, props.nextStepOption1);
      }
   }

   return (
      <section className={classes.registerpasswordcontainer}>
         <Snackbar
            open={props.snackBarOpen}
            autoHideDuration={4000}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            onClose={() => {
               props.setSnackBarOpen(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert
               onClose={() => {
                  props.setSnackBarOpen(false);
               }}
               severity="error"
               color="error"
            >
               {props.responseMessage}
            </Alert>
         </Snackbar>

         <h2 className={classes.registerpasswordHeader}>
            Create your account password
         </h2>
         <p className={classes.registerHeaderText}>
            Password must contain at least eight characters, including one
            uppercase, one lowercase, and one special character.
         </p>

         <div className={classes.outerInputBox}>
            <div className={classes.registerInputBoxContainer}>
               <img
                  className={classes.registerMailIcon}
                  src={lockIcon}
                  alt=""
               />
               <input
                  onChange={(e) => {
                     setNextToActive(e);
                  }}
                  onKeyDown={(e) => {
                     setNextToActive(e);
                  }}
                  className={classes.registerpasswordInput}
                  type="password"
                  id="register-password-input"
                  placeholder="Password"
                  defaultValue={props.defaultValue}
               />
            </div>
            <div
               id="divider-line-1"
               className={classes.registerDividerLine}
            ></div>
         </div>

         <div className={classes.outerInputBox}>
            <div className={classes.registerInputBoxContainer}>
               <img
                  className={classes.registerMailIcon}
                  src={lockIcon}
                  alt=""
               />
               <input
                  onChange={(e) => {
                     setNextToActive(e);
                  }}
                  onKeyDown={(e) => {
                     setNextToActive(e);
                  }}
                  className={classes.registerpasswordInput}
                  type="password"
                  id="register-confirm-password-input"
                  placeholder="Retype Password"
                  defaultValue={props.defaultValue}
               />
            </div>
            <div
               id="divider-line-2"
               className={classes.registerDividerLine}
            ></div>
         </div>

         <p className={classes.validationMessage}>{validationMessage}</p>

         <div className={classes.registerButtonsContainer}>
            <RoundedButton
               id={"back"}
               listener={() => {
                  props.onPreviousStep(props.backStepOption1);
               }}
               boldText={false}
               showShadow={false}
               showArrow={true}
               arrowDirection={"left"}
               color="transparent"
               text="BACK"
            />

            <RoundedButton
               id={"next"}
               disabled={nextButtonDisabled}
               listener={nextStep}
               boldText={false}
               showShadow={true}
               showArrow={true}
               arrowDirection={"right"}
               color="primary"
               text="NEXT"
            />
         </div>
      </section>
   );
}

const useStyles = makeStyles({ name: "RegisterpasswordComponent" })(
   (theme) => ({
      registerpasswordcontainer: {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         margin: "auto",
         marginTop: "10%",
         "@media (max-height: 660px)": {
            marginTop: "4%",
         },
      },
      registerpasswordHeader: {
         fontWeight: "bold",
         fontFamily: theme.fonts.secondary.bold,
      },
      registerHeaderText: {
         marginBottom: "30px",
         width: "40%",
         textAlign: "center",
      },
      outerInputBox: {
         height: "61px",
      },
      registerInputBoxContainer: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      registerButtonsContainer: {
         marginTop: "75px",
         display: "flex",
         alignItems: "center",
         marginBottom: "20px",
      },
      registerMailIcon: {
         height: "14px",
         width: "auto",
         marginRight: "5px",
      },
      registerpasswordInput: {
         border: "none",
         outline: "none",
         width: "80%",
         height: "20px",
         cursor: "text",
         backgroundColor: "transparent",
         fontSize: "16px",
         "&::placeholder": {
            fontSize: "16px",
            fontWeight: "bold",
            color: theme.palette.common.lightGray, // Replace this with your desired color
         },
      },
      registerDividerLine: {
         height: "1px",
         width: "100%",
         backgroundColor: theme.palette.common.darkGray,
         marginBottom: "100px",
         marginTop: "3px",
      },
      registerButtonSection: {
         display: "flex",
         alignItems: "center",
         justifyContent: "space-evenly",
      },
      invalidInput: {
         "&::placeholder": {
            color: "red", // Replace this with your desired color
         },
      },
      validationMessage: {
         color: "red",
         margin: 0,
         height: "19px",
      },
   })
);

export default RegisterPasswordComponent;
