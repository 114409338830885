import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import mailIcon from "../../styles/assets/logos/mail-icon.svg";
import { Alert, Snackbar } from "@mui/material";
import RoundedButton from "../common/boxlty.button";
import { verifyEmail } from "../../contexts/api/api.functions";

function RegisterEmailComponent(props: any) {
   const { classes } = useStyles();
   const [emailButtonDisabled, setEmailButtonDisabled] = useState(true);
   const [forceUpdate, setForceUpdate] = useState(1);

   const validDomains = [".com", ".io", ".org", ".net", ".edu", ".gov"];

   useEffect(() => {
      if (props.defaultValue != "" && props.defaultValue != null) {
         let defaultValid = validateEmail(props.defaultValue);
         setEmailButtonDisabled(!defaultValid);
      }
   }, [props.defaultValue, forceUpdate]);

   function setNextToActive(
      event:
         | React.ChangeEvent<HTMLInputElement>
         | React.KeyboardEvent<HTMLInputElement>
   ) {
      //min email length a@b.io = 6 chars
      if ((event.target as HTMLInputElement).value.length >= 6) {
         //set button to active
         setEmailButtonDisabled(false);
      } else {
         //set to inactive
         setEmailButtonDisabled(true);
      }
   }

   function validateEmail(email?: string) {
      if (email === "" || email === null || email === undefined) {
         //return true because the button disabled calls this on load
         return true;
      }

      let isValid: boolean = true;
      let emailInput: HTMLInputElement = document.querySelector(
         "#register-email-input"
      ) as HTMLInputElement;
      let line: HTMLElement = document.querySelector(
         "#divider-line"
      ) as HTMLInputElement;

      if (!email.includes("@")) {
         isValid = false;
         let validationMessage = "Invalid Email";
         emailInput.value = "";
         emailInput.placeholder = validationMessage;
         emailInput.classList.add(classes.invalidInput);
         line.style.backgroundColor = "red";
      }

      let match = false;
      validDomains.forEach((domain) => {
         let provided_domain = "." + email.split(".").pop();
         if (provided_domain === domain) {
            match = true;
         }
      });

      if (!match) {
         isValid = false;
         let validationMessage = "Invalid Email Domain";
         emailInput.value = "";
         emailInput.placeholder = validationMessage;
         emailInput.classList.add(classes.invalidInput);
         line.style.backgroundColor = "red";
      }

      if (email.charAt(email.indexOf("@") + 1) === ".") {
         isValid = false;
         let validationMessage = "Invalid Email";
         emailInput.value = "";
         emailInput.placeholder = validationMessage;
         emailInput.classList.add(classes.invalidInput);
         line.style.backgroundColor = "red";
      }
      if (isValid) emailInput.classList.remove(classes.invalidInput);
      return isValid;
   }

   async function nextStep() {
      props.setSnackBarOpen(false);
      let email: string = (
         document.querySelector("#register-email-input") as HTMLInputElement
      ).value;
      if (validateEmail(email)) {
         let res = await verifyEmail(email.trim().replaceAll(" ", ""));
         if (
            res !== null &&
            res !== undefined &&
            res.data !== null &&
            res.data !== undefined &&
            res.data.result !== null &&
            res.data.result !== undefined
         ) {
            if (res.data.result["@result"] === 1) {
               props.setSnackBarOpen(true);
            } else if (
               res.data.result["@result"] === 0 &&
               props.onSubmitEmail
            ) {
               props.onSubmitEmail(email.trim().replaceAll(" ", ""));
            }
         }
      }
   }

   return (
      <section className={classes.registerEmailcontainer}>
         <Snackbar
            open={props.snackBarOpen}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert severity="error" color="error">
               This email address is already in use. Please continue to the{" "}
               <a href="/">Log In</a> screen or use another email address to
               create your account.
            </Alert>
         </Snackbar>
         <h2 className={classes.registerEmailHeader}>What's your email?</h2>

         <div className={classes.outerInputBox}>
            <div className={classes.registerInputBoxContainer}>
               <img
                  className={classes.registerMailIcon}
                  src={mailIcon}
                  alt=""
               />
               <input
                  onChange={(e) => {
                     setNextToActive(e);
                  }}
                  onKeyDown={(e) => {
                     setNextToActive(e);
                  }}
                  className={classes.registerEmailInput}
                  type="email"
                  id="register-email-input"
                  placeholder="Email"
                  defaultValue={props.defaultValue}
               />
            </div>
            <div
               id="divider-line"
               className={classes.registerDividerLine}
            ></div>
         </div>

         <div className={classes.registerButtonsContainer}>
            <RoundedButton
               id={"email-button"}
               disabled={emailButtonDisabled}
               listener={nextStep}
               boldText={false}
               showShadow={true}
               showArrow={true}
               arrowDirection={"right"}
               color="primary"
               text="CONTINUE"
            />
         </div>
      </section>
   );
}

const useStyles = makeStyles({ name: "RegisterEmailComponent" })((theme) => ({
   registerEmailcontainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      // height: '100%',
      marginTop: "10%",
      // minHeight: '100vh'
      "@media (max-height: 660px)": {
         marginTop: "4%",
      },
   },
   registerEmailHeader: {
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      marginBottom: "75px",
   },
   outerInputBox: {
      height: "70px",
   },
   registerInputBoxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   registerButtonsContainer: {
      marginTop: "120px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
   },
   registerMailIcon: {
      height: "14px",
      width: "auto",
      marginRight: "5px",
   },
   registerEmailInput: {
      border: "none",
      outline: "none",
      width: "80%",
      minWidth: "275px",
      height: "20px",
      cursor: "text",
      backgroundColor: "transparent",
      "&::placeholder": {
         color: theme.palette.common.lightGray, // Replace this with your desired color
         fontWeight: "bold",
      },
      boxSizing: "border-box",
      fontSize: "16px",
   },
   registerDividerLine: {
      height: "1px",
      width: "100%",
      backgroundColor: theme.palette.common.lightGray,
      marginBottom: "100px",
      marginTop: "3px",
   },
   registerButtonSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
   },
   invalidInput: {
      "&::placeholder": {
         color: "red", // Replace this with your desired color
      },
   },
}));

export default RegisterEmailComponent;
