// AccountSettingsAccount.jsx
import { useEffect, useState } from "react";
import { Alert, AlertColor, Grid, Snackbar, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { FileUpload } from "../../components/common/file.upload.component";
import { PreviewImage } from "../../components/common/preview.image";
import { HeaderButton } from "../../components/listings/create/forms/header.button";
import { useUserState } from "../../contexts/user/user.context";
import RoundedButton from "../../components/common/boxlty.button";
import RoundedButtonWhite from "../../components/common/boxlty.button.white";
import { updateCompanyDetails } from "../../contexts/api/api.functions";
import {
   generatePreSignedUrl,
   uploadCompanyLogo,
} from "../../contexts/api/api.functions";
import { S3DirectoryName } from "../../contexts/api/api.types";
import axios from "axios";
const { v4: uuidv4 } = require("uuid");

interface AccountSettingsProps {
   role: string;
   accountType: string;
   companyName: string;
   companyLicense: string;
   companyId: string;
}

const AccountTab = () => {
   const { classes } = useStyles();
   const [accountStatus, setAccountStatus] = useState("");
   const [role, setRole] = useState("");
   const { user } = useUserState();
   const [logoFile, setLogoFile] = useState<any>("");
   const [editActive, setEditActive] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarStatus, setSnackbarStatus] = useState<AlertColor>("success");
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [logoFilePath, setLogoFilePath] = useState("");
   const [logoRetrieved, setLogoRetrieved] = useState(false);
   const [userData, setUserData] = useState<AccountSettingsProps>({
      role: "",
      accountType: "",
      companyName: "",
      companyLicense: "",
      companyId: "",
   });

   const [originalUserData, setOriginalUserData] =
      useState<AccountSettingsProps>({
         role: "",
         accountType: "",
         companyName: "",
         companyLicense: "",
         companyId: "",
      });

   useEffect(() => {
      let accountType = "";
      switch (user?.accountType) {
         case 1:
            setAccountStatus("Free");
            accountType = "Free";
            break;
         case 2:
            setAccountStatus("Plus");
            accountType = "Plus";
            break;
         case 3:
            setAccountStatus("Business Tier 1");
            accountType = "Business Tier 1";
            break;
         case 4:
            setAccountStatus("Business Tier 2");
            accountType = "Business Tier 2";
            break;
         case 5:
            setAccountStatus("Enterprise Property Manager");
            accountType = "Enterprise Property Manager";
            break;
         case 6:
            setAccountStatus("Enterprise Lender");
            accountType = "Enterprise Lender";
      }

      //isRenter = roleId
      let accountRole = "";
      switch (user?.isRenter) {
         case 1:
            setRole("Tenant");
            accountRole = "Tenant";
            break;
         case 2:
            if (user?.isPrimaryOwner === 1) {
               if (
                  (user?.accountType === 5 || user?.accountType === 6) &&
                  user?.isEnterpriseSuperUser
               ) {
                  accountRole = "Super Admin";
               } else {
                  accountRole = "Primary Manager";
               }
            } else {
               setRole("Manager");
               accountRole = "Manager";
            }
            break;
         default:
            setRole("");
            break;
      }
      const newUserData = {
         role: accountRole,
         accountType: accountType,
         companyName: user?.companyName,
         companyLicense: user?.companyLicense,
         companyId: user?.companyId,
      };
      setUserData(newUserData);
      setOriginalUserData(newUserData);

      if (
         user?.companyLogo != null &&
         user?.companyLogo != "" &&
         !logoRetrieved
      ) {
         console.log("logo from stored");
         setLogoFilePath(user?.companyLogo);
         setLogoRetrieved(true);
      }
   }, [user]);

   useEffect(() => {
      if (logoFile !== "") {
         //console.log("logo uploaded: ", logoFile);
         handleUploadCompanyLogo();
      }
   }, [logoFile]);
   const handleUploadCompanyLogo = async () => {
      let logoImgKey: string = uuidv4();
      const companyLogoRequest = await generatePreSignedUrl({
         directory: S3DirectoryName.COMPANY_LOGO,
         fileName: logoImgKey,
      });
      if (
         companyLogoRequest &&
         companyLogoRequest.data &&
         companyLogoRequest.data.status === true
      ) {
         const logoImgUrl = companyLogoRequest.data.result;
         await axios
            .put(logoImgUrl, logoFile)
            .then(() => {})
            .catch((error) => {
               //console.log("error uploading company logo");
            });
         const data = {
            companyLogo: logoImgKey,
         };
         const res = await uploadCompanyLogo(data);
         //console.log(res);
         setLogoFilePath(logoImgKey);
         window.location.reload();
      }
   };

   const handleEditProfile = () => setEditActive(!editActive);

   const handleCancelEdit = () => {
      setUserData(originalUserData);
      setEditActive(false);
   };

   const handleSaveEdit = async () => {
      //api here
      let data = {
         companyName: userData.companyName,
         companyLicense: userData.companyLicense,
         companyContact: user?.companyContact,
      };
      const response = await updateCompanyDetails(data);
      // console.log(response);
      if (response.data.status == true) {
         setSnackbarOpen(true);
         setSnackbarMessage("Successfully updated company details");
         setSnackbarStatus("success");
         setOriginalUserData(userData);
         setEditActive(false);
      } else {
         setSnackbarOpen(true);
         setSnackbarMessage("Error while updating company details");
         setSnackbarStatus("error");
         setEditActive(false);
      }
   };

   const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      field: keyof AccountSettingsProps
   ) => {
      setUserData({ ...userData, [field]: e.target.value });
   };

   const handleDeleteLogo = async () => {
      let data = {
         companyLogo: "",
      };
      const res = await uploadCompanyLogo(data).then(() => {
         window.location.reload();
      });
   };

   return (
      <section className={classes.DashboardBlockedSection}>
         <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            onClose={() => {
               setSnackbarOpen(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert
               onClose={() => {
                  setSnackbarOpen(false);
               }}
               severity={snackbarStatus}
               color={snackbarStatus}
            >
               {snackbarMessage}
            </Alert>
         </Snackbar>
         <div className={classes.InnerItemContainer}>
            <div className={classes.InnerItemSideLeft}>
               <div className={classes.InnerItemSideSubSection}>
                  {user?.idVerify ? (
                     <HeaderButton
                        color="black"
                        backgroundColor="white"
                        borderColor="#66E0B4"
                        text="VERIFIED"
                        clickable={false}
                     />
                  ) : (
                     <HeaderButton
                        color="black"
                        backgroundColor="white"
                        borderColor="lightgray"
                        text="UNVERIFIED"
                        width="115px"
                        clickable={false}
                     />
                  )}
               </div>
               <div className={classes.InfoContainer}>
                  <div className={classes.InnerItemSideSubSection}>
                     <h2 className={classes.InnerItemsSideSubHeader}>ROLE</h2>
                     <h2 className={classes.InnerItemsSideSubText}>
                        {userData.role}
                     </h2>
                  </div>

                  <div className={classes.InnerItemSideSubSection}>
                     <h2 className={classes.InnerItemsSideSubHeader}>
                        TYPE OF ACCOUNT
                     </h2>
                     <h2 className={classes.InnerItemsSideSubText}>
                        {userData.accountType}
                     </h2>
                  </div>

                  <div className={classes.InnerItemSideSubSection}>
                     <h2 className={classes.InnerItemsSideSubHeader}>
                        COMPANY NAME
                     </h2>
                     {editActive ? (
                        <input
                           type="text"
                           value={userData.companyName}
                           onChange={(e) => handleInputChange(e, "companyName")}
                           className={classes.InnerItemsSideSubText}
                        />
                     ) : (
                        <h2 className={classes.InnerItemsSideSubText}>
                           {userData.companyName}
                        </h2>
                     )}
                  </div>

                  <div className={classes.InnerItemSideSubSection}>
                     <h2 className={classes.InnerItemsSideSubHeader}>
                        COMPANY LICENSE NUMBER
                     </h2>
                     {editActive ? (
                        <input
                           type="text"
                           value={userData.companyLicense}
                           onChange={(e) =>
                              handleInputChange(e, "companyLicense")
                           }
                           className={classes.InnerItemsSideSubText}
                        />
                     ) : (
                        <h2 className={classes.InnerItemsSideSubText}>
                           {userData.companyLicense}
                        </h2>
                     )}
                  </div>

                  <div className={classes.InnerItemSideSubSection}>
                     <h2 className={classes.InnerItemsSideSubHeader}>
                        COMPANY ID
                     </h2>
                     <h2 className={classes.InnerItemsSideSubText}>
                        {userData.companyId}
                     </h2>
                  </div>
               </div>
               {(user?.accountType == 5 || user?.accountType == 6) && (
                  <div className={classes.editButtons}>
                     {!editActive ? (
                        <RoundedButton
                           text="EDIT"
                           listener={handleEditProfile}
                           boldText={true}
                           color="primary"
                        />
                     ) : (
                        <RoundedButtonWhite
                           text="CANCEL"
                           listener={handleCancelEdit}
                           boldText={true}
                        />
                     )}
                     {editActive && (
                        <RoundedButton
                           text="SAVE"
                           listener={handleSaveEdit}
                           color="primary"
                           boldText={true}
                        />
                     )}
                  </div>
               )}
            </div>
            {/* These are the two enterprise account statuses */}
            {(user?.accountType === 5 || user?.accountType === 6) &&
               user?.isEnterpriseSuperUser == "1" && (
                  <div className={classes.InnerItemSideRight}>
                     <Grid item>
                        <Grid container direction={"column"} display="flex">
                           <Grid
                              item
                              sx={{ display: "block", alignSelf: "center" }}
                           >
                              <div
                                 className={classes.inputHeader}
                                 style={{ textAlign: "center" }}
                              >{`UPLOAD LOGO`}</div>
                              <div
                                 className={classes.inputSubHeader}
                                 style={{
                                    marginBottom: "10px",
                                    textAlign: "center",
                                 }}
                              >
                                 {"(150p x 50p recommended)"}
                              </div>
                           </Grid>
                           <Grid item>
                              {logoFilePath !== "" ? (
                                 <PreviewImage
                                    height="50px"
                                    width="150px"
                                    file={`https://boxlty-media.s3.amazonaws.com/company-logos/${logoFilePath}`}
                                    showMenu={false}
                                    clearImage={() => {
                                       setLogoFile("");
                                    }}
                                 />
                              ) : (
                                 <FileUpload
                                    id="logo"
                                    height="125px"
                                    width="125px"
                                    file={logoFile}
                                    setFile={setLogoFile}
                                 />
                              )}
                           </Grid>
                           <Grid
                              item
                              sx={{ display: "flex", alignSelf: "center" }}
                           >
                              <div
                                 className={classes.inputSubHeader}
                                 onClick={() => {
                                    //setLogoFile("");
                                    //setLogoFilePath("");
                                    handleDeleteLogo();
                                 }}
                              >
                                 Delete Image
                              </div>
                           </Grid>
                        </Grid>
                     </Grid>
                  </div>
               )}
         </div>
      </section>
   );
};

const useStyles = makeStyles({ name: "DashboardStyles" })((theme) => ({
   DashboardOuterLayout: {
      display: "flex",
      alignItems: "flex-start",
      //border: '1px dashed orange',
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
   },
   ItemOneContainer: {
      //border: '1px dashed red',
      width: "80%",
      height: "100%",
   },
   ItemTwoContainer: {
      marginLeft: "auto",
   },
   DashboardTitle: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "40px",
      letterSpacing: "1px",
      fontWeight: "bold",
      margin: 0,
      marginBottom: "20px",
   },
   DashboardBlockedSection: {
      backgroundColor: "white",
      padding: "5px",
      marginTop: "10px",
      marginBottom: "30px",
      borderRadius: "15px",
      height: "100%",
      width: "60%",
      maxWidth: "1200px",
      minWidth: "300px",
      boxShadow: "6px 6px 12px #00000014;",
   },
   InnerItemContainer: {
      width: "95%",
      display: "flex",
      alignItems: "flex-start",
      paddingLeft: "20px",
      paddingTop: "15px",
      paddingRight: "20px",
   },
   InnerItemSideLeft: {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
   },
   InnerItemSideRight: {
      width: "60%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
   },
   InnerItemSideMainHeader: {
      margin: "5px",
      fontFamily: theme.fonts.secondary.primary_extralight,
      fontSize: "14px",
   },
   greenBorderText: {
      border: "2px",
      borderColor: "#66E0B4",
      borderStyle: "solid",
      borderRadius: "25px",
      padding: "8px" /* Add padding for spacing between text and border */,
      display: "inline-block" /* Make sure the border wraps around the text */,
   },
   InnerItemSideMainSubheader: {
      margin: "5px",
      fontFamily: theme.fonts.secondary.primary_extralight,
   },
   InnerItemSideSubSection: {
      marginLeft: "5px",
      marginTop: "15px",
      //marginBottom: "15px",
   },
   InnerItemsSideSubHeader: {
      fontSize: "14px",
      color: "#a2a2a2",
      marginBottom: "0px",
      fontWeight: "normal",
   },
   InnerItemsSideSubText: {
      fontSize: "18px",
      marginTop: "2px",
      marginBottom: "2px",
      fontWeight: "normal",
   },
   ProfileImageContainer: {
      marginTop: "5px",
      marginLeft: "auto",
      marginBottom: "5px",
      height: "150px",
      width: "150px",
      overflow: "hidden",
   },
   ProfileImage: {
      height: "150px",
      width: "150px",
      objectFit: "cover",
   },
   PhoneNumListing: {
      marginTop: "-10px",
      color: "#a2a2a2",
   },
   CalendarContainer: {
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      transform: "scale(0.9)",
      position: "relative",
      bottom: "15px",
      left: "35px",
   },
   ItemTwoInnerCointainer: {
      paddingLeft: "20px",
      width: "100%",
   },
   ActionButtonsConatiner: {
      marginTop: "40px",
      display: "flex",
      flexDirection: "column",
      justifyItems: "space-evenly",
   },
   subHeader: {
      color: "#A2A2A2",
      fontSize: "14px",
      marginBottom: "5px",
   },
   inputHeader: {
      color: "#A2A2A2",
      marginBottom: "7px",
      textTransform: "uppercase",
      fontSize: "14px",
   },
   inputSubHeader: {
      color: "#A2A2A2",
      marginTop: "8px",
      fontSize: "12px",
      textDecoration: "underline",
      cursor: "pointer",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   ThreeDots: {
      cursor: "pointer",
      float: "right",
   },
   InfoContainer: {
      height: "250px",
   },
   editButtons: {
      display: "flex",
      marginTop: "50px",
      marginLeft: "-10px",
   },
}));

export default AccountTab;
