import { makeStyles } from "tss-react/mui";
import RoundedButton from "../common/boxlty.button";
import { useState, useEffect } from "react";
import BoxltyCheckbox from "../common/boxlty.checkbox";

function RegisterQuestionsComponent(props: any) {
   const { classes } = useStyles();
   const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
   const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
   const [checkedBox1, setCheckedBox1] = useState(props.defaultValue[0]);
   const [checkedBox2, setCheckedBox2] = useState(props.defaultValue[1]);
   const [checkedBox3, setCheckedBox3] = useState(props.defaultValue[2]);
   const [checkedBox4, setCheckedBox4] = useState(props.defaultValue[3]);
   const [checkedBox5, setCheckedBox5] = useState(props.defaultValue[4]);

   function validateQuestions(answers: boolean[]) {
      let isValid = false;
      answers.forEach((answer) => {
         if (answer) isValid = true;
      });
      return isValid;
   }

   useEffect(() => {
      if (props.defaultValue !== "" && props.defaultValue != null) {
         let defaultValid = validateQuestions(props.defaultValue);
         setNextButtonDisabled(!defaultValid);
      }
   }, [props.defaultValue]);

   const onCheckboxChangeCount = (checkboxId: string) => {
      // Check if the checkbox is already selected
      if (selectedCheckboxes.includes(checkboxId)) {
         setSelectedCheckboxes(
            selectedCheckboxes.filter((id) => id !== checkboxId)
         );
      } else {
         if (selectedCheckboxes.length >= 3) {
            return;
         }
         // If not at the maximum, add the checkbox to the array
         setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
      }
      // Call the onCheckboxChange function to update the next button state
      //onCheckboxChange();
   };

   function onCheckboxChange() {
      if (selectedCheckboxes.length >= 3) {
         return;
      }

      let questionOne: boolean = (
         document.querySelector("#question-1-checkbox") as HTMLInputElement
      ).checked;
      let questionTwo: boolean = (
         document.querySelector("#question-2-checkbox") as HTMLInputElement
      ).checked;
      let questionThree: boolean = (
         document.querySelector("#question-3-checkbox") as HTMLInputElement
      ).checked;
      let questionFour: boolean = (
         document.querySelector("#question-4-checkbox") as HTMLInputElement
      ).checked;
      let questionFive: boolean = (
         document.querySelector("#question-5-checkbox") as HTMLInputElement
      ).checked;
      let answers = [
         questionOne,
         questionTwo,
         questionThree,
         questionFour,
         questionFive,
      ];
      let valid = validateQuestions(answers);
      setNextButtonDisabled(!valid);
   }

   function nextStep() {
      let questionOne: boolean = (
         document.querySelector("#question-1-checkbox") as HTMLInputElement
      ).checked;
      let questionTwo: boolean = (
         document.querySelector("#question-2-checkbox") as HTMLInputElement
      ).checked;
      let questionThree: boolean = (
         document.querySelector("#question-3-checkbox") as HTMLInputElement
      ).checked;
      let questionFour: boolean = (
         document.querySelector("#question-4-checkbox") as HTMLInputElement
      ).checked;
      let questionFive: boolean = (
         document.querySelector("#question-5-checkbox") as HTMLInputElement
      ).checked;
      let answers = [
         questionOne,
         questionTwo,
         questionThree,
         questionFour,
         questionFive,
      ];
      if (validateQuestions(answers)) {
         if (props.onSubmitQuestions)
            props.onSubmitQuestions(answers, props.nextStepOption1);
      }
   }

   return (
      <section className={classes.registerQuestionsContainer}>
         <h2 className={classes.registerQuestionsHeader}>
            What best describes you?
         </h2>

         <div className={classes.outerInputBox}>
            <div className={classes.checkboxContainer}>
               <BoxltyCheckbox
                  onChange={() => {
                     onCheckboxChangeCount("question-1-checkbox");
                     onCheckboxChange();
                  }}
                  id="question-1-checkbox"
                  checked={checkedBox1}
                  disabled={
                     selectedCheckboxes.length >= 3 &&
                     !selectedCheckboxes.includes("question-1-checkbox")
                  }
                  onClick={(checked) => {
                     setCheckedBox1(checked);
                  }}
               />
               <h4 className={classes.QuestionText}>
                  I am a homeowner ready to sell.
               </h4>
            </div>

            <div className={classes.checkboxContainer}>
               <BoxltyCheckbox
                  onChange={() => {
                     //onCheckboxChange();
                     onCheckboxChangeCount("question-2-checkbox");
                     onCheckboxChange();
                  }}
                  id="question-2-checkbox"
                  checked={checkedBox2}
                  disabled={
                     selectedCheckboxes.length >= 3 &&
                     !selectedCheckboxes.includes("question-2-checkbox")
                  }
                  onClick={(checked) => {
                     setCheckedBox2(checked);
                  }}
               />
               <h4 className={classes.QuestionText}>
                  I am a private landlord with properties to rent.
               </h4>
            </div>

            <div className={classes.checkboxContainer}>
               <BoxltyCheckbox
                  onChange={() => {
                     //onCheckboxChange();
                     onCheckboxChangeCount("question-3-checkbox");
                     onCheckboxChange();
                  }}
                  id="question-3-checkbox"
                  checked={checkedBox3}
                  disabled={
                     selectedCheckboxes.length >= 3 &&
                     !selectedCheckboxes.includes("question-3-checkbox")
                  }
                  onClick={(checked) => {
                     setCheckedBox3(checked);
                  }}
               />
               <h4 className={classes.QuestionText}>
                  I am a property management company.
               </h4>
            </div>

            <div className={classes.checkboxContainer}>
               <BoxltyCheckbox
                  onChange={() => {
                     onCheckboxChangeCount("question-4-checkbox");
                     onCheckboxChange();
                  }}
                  id="question-4-checkbox"
                  checked={checkedBox4}
                  disabled={
                     selectedCheckboxes.length >= 3 &&
                     !selectedCheckboxes.includes("question-4-checkbox")
                  }
                  onClick={(checked) => {
                     setCheckedBox4(checked);
                  }}
               />
               <h4 className={classes.QuestionText}>
                  I am a real estate investor / flipper.
               </h4>
            </div>

            <div className={classes.checkboxContainer}>
               <BoxltyCheckbox
                  onChange={() => {
                     onCheckboxChangeCount("question-5-checkbox");
                     onCheckboxChange();
                  }}
                  id="question-5-checkbox"
                  checked={checkedBox5}
                  disabled={
                     selectedCheckboxes.length >= 3 &&
                     !selectedCheckboxes.includes("question-5-checkbox")
                  }
                  onClick={(checked) => {
                     setCheckedBox5(checked);
                  }}
               />

               <h4 className={classes.QuestionText}>
                  I am a real estate agent / realtor.
               </h4>
            </div>
         </div>

         <div className={classes.registerButtonsContainer}>
            <RoundedButton
               id={"back"}
               listener={() => {
                  props.onPreviousStep(props.backStepOption1);
               }}
               boldText={false}
               showShadow={false}
               showArrow={true}
               arrowDirection={"left"}
               color="transparent"
               text="BACK"
            />

            <RoundedButton
               id={"next"}
               listener={nextStep}
               disabled={nextButtonDisabled}
               boldText={false}
               showShadow={true}
               showArrow={true}
               arrowDirection={"right"}
               color="primary"
               text="NEXT"
            />
         </div>
      </section>
   );
}

const useStyles = makeStyles({ name: "RegisterQuestionsComponent" })(
   (theme) => ({
      registerQuestionsContainer: {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         margin: "auto",
         marginTop: "10%",
         "@media (max-height: 660px)": {
            marginTop: "4%",
         },
      },

      registerQuestionsHeader: {
         fontWeight: "bold",
         fontFamily: theme.fonts.secondary.bold,
         marginBottom: "45px",
         textAlign: "center",
      },
      questionsSubtext: {
         fontSize: "12px",
         fontFamily: theme.fonts.primary.light,
         maxWidth: "310px",
         marginBottom: "20px",
      },
      questionsLink: {
         color: theme.palette.common.primary,
      },
      checkboxContainer: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         marginTop: "5px",
         marginBottom: "5px",
      },
      QuestionText: {
         margin: 0,
         marginLeft: "5px",
         fontWeight: "400",
         fontSize: "16px",
         fontFamily: theme.fonts.secondary.light,
      },
      outerInputBox: {
         height: "70px",
         display: "flex",
         flexDirection: "column",
         alignItems: "flex-start",
      },
      registerInputBoxContainer: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      registerButtonsContainer: {
         marginTop: "150px",
         display: "flex",
         alignItems: "center",
         marginBottom: "20px",
      },
      registerMailIcon: {
         height: "10px",
         width: "auto",
         marginRight: "5px",
      },
      registerQuestionsInput: {
         border: "none",
         outline: "none",
         width: "80%",
         height: "20px",
         cursor: "text",
         backgroundColor: "transparent",
      },
      registerDividerLine: {
         height: "1px",
         width: "100%",
         backgroundColor: theme.palette.common.darkGray,
         marginBottom: "100px",
      },
      registerButtonSection: {
         display: "flex",
         alignItems: "center",
         justifyContent: "space-evenly",
      },
      checkbox: {
         "&:focus": {
            outline: "none",
         },
      },
   })
);

export default RegisterQuestionsComponent;
