import React, { createContext, useContext } from "react";
import { IGradients } from "./gradients.types";

const permenantState: IGradients = {
    primaryButtonsGradient: 'linear-gradient(270deg, #D63F94 0%, #B8519E 100%)',
    inProgressGradient: 'linear-gradient(270deg, #60E0BD 0%, #64E29A 100%)',
    pendingGradient: 'linear-gradient(270deg, #E2BA61FC 0%, #F5B87C 100%)',
    scheduledGradient: 'linear-gradient(270deg, #7CE7F5FC 0%, #61D7E2 100%)'
}


export const UseGradientContext: any = createContext({});
export const useGradient = (): any => useContext(UseGradientContext);


export const GradientProvider: React.FC<{ children: React.ReactNode}> = ({ children}) => {



    return (
        <UseGradientContext.Provider value={permenantState}>
            {children}
        </UseGradientContext.Provider>
    )
}