import React from 'react'
import { Grid, Paper } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { classnames } from 'tss-react/tools/classnames'

type Props = {}

export const ListingsCreateRightContainer: React.FC<{ children: React.ReactNode }> = ({ children}) => {
  const { CurrentMainTab } = useParams<{ CurrentMainTab: string }>()
  const { CurrentSecondaryTab } = useParams<{ CurrentSecondaryTab: string }>()
  const { classes } = useStyles()

  return (
    <>
        <Paper elevation={2} className={classes.paperContainer}>
          {children}
        </Paper>
    </>
  )
}


const useStyles = makeStyles({ name: "Listings-Right-Container"})((theme) => ({
  mainContainer: {
      paddingLeft: '10px',
      height: '100%',
      border: '1px solid red',
      
  },
  pageHeader: {
      fontSize: '36px',
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textDecoration: 'underline'
  },
  sectionContainer: {

  },
  formContainer: {
      display: 'flex',
      height: '100%',
      
  },
  paperContainer: {
    height: '800px',
    display: 'flex',
    maxWidth: '100%',
    borderRadius: '26px',
    flexWrap: 'nowrap',
    overflowY: 'auto'
  }
  

}))