export enum PublicRoutePaths {
   Login = "/login",
   ForgotPasssword = "/forgotpassword",
   Tours = "/tours",
   WelcomeToInstaShow = "/welcomeinstashow",
}

export enum ProtectedRoutePaths {
   Dashboard = "/dashboard",

   /// View All Listing
   Listings = "/listings",
   ListingTypeParam = ":ListingType",

   //// Single Listing
   Listing = "/listings/listing",
   ListingIdParam = ":ListingIdParam",
   ListingCreatedCurrentMainTab = ":CreatedCurrentMainTab",
   ListingCreatedCurrentSecondaryTab = ":CreatedCurrentSecondaryTab",

   //// Create New Listing
   ListingCreate = "/listings/create",
   ListingCreateCurrentMainTab = ":CurrentMainTab",
   ListingCreateCurrentSecondaryTab = ":CurrentSecondaryTab",

   Tours = "/tours",
   Team = "/team",
   Tokens = "/tokens",
   TourType = ":TourType",
   TourDetails = ":TourID",

   Partners = "/partners",
   PartnerType = ":PartnerType",

   PersonalInformation = "/personalinfo",
   AccountSettings = "/settings",
   AccountSettingsParam = ":SettingsType",

   Locks = "/locks",
   LockDetails = "/lockdetails",
   LockType = ":LockType",
   LockId = "/:LockId",

   Leads = "/leads",
}
