import react from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import ModalTransparentLayer from "../../common/ModalTransparentLayer";
import { fadeIn, fadeOut, moveElement } from "../../../utils/genericUtils";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RoundedButton from "../../common/boxlty.button";
import Amex from "../../../styles/assets/Icons/AMEX-small.svg";
import Discover from "../../../styles/assets/Icons/Discover-small.svg";
import MasterCard from "../../../styles/assets/Icons/Mastercard-small.svg";
import Visa from "../../../styles/assets/Icons/Visa-small.svg";

interface position {
  top: string;
  left: string;
  right: string;
  bottom: string;
}

interface ActionButtonModalProps {
  id?: string;
  isVisible: boolean;
  openPosition: position;
  closedPosition: position;
}

function AddNewCardModal(props: ActionButtonModalProps) {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(props.isVisible);
  const [isInitialRender, setIsInitialRender] = useState(true);

  // State variables to store input field values and validation status
  const [inputValues, setInputValues] = useState({
    cardNumber: "",
    expDate: "",
    cvc: "",
    nameOnCard: "",
    countryOrRegion: "United States of America",
    zip: "",
  });

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const paymentTransparentModalId = "payment-transparent-layer";
  const TransparentLayerTransitionTime = 0.3;
  const ModalTransitionTime = 0.5;
  useEffect(() => {
    if (!isInitialRender) toggle();
    setIsInitialRender(false);
    if (isInitialRender && props.isVisible == true) toggle(true);
  }, [props.isVisible, modalVisible]);

  useEffect(() => {
    validateInputs();
  }, [inputValues]);

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggle(mode?: boolean | void) {
    if (mode != null) {
      toggleModal(mode);
      toggleTransparentLayer(mode);
      setModalVisible(mode);
    } else {
      toggleModal();
      toggleTransparentLayer();
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleTransparentLayer(mode: boolean | void) {
    //get transparent layer by id
    let transparentLayer: HTMLElement = document.querySelector(
      "#" + paymentTransparentModalId
    ) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      return;
    }
    //manual mode not passed
    if (modalVisible) {
      fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
    } else {
      fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleModal(mode: boolean | void) {
    let modal: HTMLElement = document.querySelector(
      "#" + props.id
    ) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      //slide element on page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.closedPosition,
        props.openPosition
      );
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      //slide element off page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.openPosition,
        props.closedPosition
      );
      return;
    }

    if (modalVisible) {
      //slide element off page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.openPosition,
        props.closedPosition
      );
    } else {
      //slide element on page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.closedPosition,
        props.openPosition
      );
    }
  }

  // Function to validate input fields
  function validateInputs() {
    const isCardNumberValid = /^[0-9]{16}$/.test(inputValues.cardNumber);
    const isExpDateValid = /^[0-9]{2}\/[0-9]{2}$/.test(inputValues.expDate);
    const isCvcValid = /^[0-9]{3}$/.test(inputValues.cvc);
    const isNameOnCardValid = inputValues.nameOnCard.trim() !== "";
    const isCountryOrRegionValid = inputValues.countryOrRegion.trim() !== "";
    const isZipValid = /^[0-9]{5}$/.test(inputValues.zip);
    // console.log(isCardNumberValid, inputValues.cardNumber);
    // console.log(isExpDateValid, inputValues.expDate);
    // console.log(isCvcValid, inputValues.cvc);
    // console.log(isNameOnCardValid, inputValues.nameOnCard);
    // console.log(isZipValid, inputValues.zip);

    // Enable the "Save Card" button if all fields are valid
    setSaveButtonDisabled(
      !(
        isCardNumberValid &&
        isExpDateValid &&
        isCvcValid &&
        isNameOnCardValid &&
        isCountryOrRegionValid &&
        isZipValid
      )
    );
  }

  const useStyles = makeStyles({ name: "NewCardModal" })((theme) => ({
    ModalComponentContainer: {
      overflow: "hidden",
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    AddMemberModal: {
      transition: `right ${ModalTransitionTime}s linear`,
      position: "absolute",
      zIndex: 25,
      backgroundColor: "white",
      // opacity : '0.7',
      borderRadius: "20px",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      bottom: props.closedPosition.bottom,
      right: props.closedPosition.right,
      padding: "20px",
      minHeight: "400px",
      minWidth: "350px",
      maxWidth: "400px",
    },
    modalHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: "10px",
    },
    modalSubHeader: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "flex-start",
      marginBottom: "10px",
    },
    headerText: {
      margin: "0",
      textAlign: "left",
      width: "98%",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    subHeaderText: {
      margin: "0",
      textAlign: "left",
      width: "fitcontent",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    exitX: {
      marginLeft: "auto",
      fontSize: "25px",
      cursor: "pointer",
      color: theme.palette.common.lightGray,
    },
    ModalFieldRow: {
      display: "flex",
      //alignItems: "center",
      justifyContent: "flex-start",
    },
    ModalInputBox: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "19px",
      marginRight: "10px",
    },
    ModalInput: {
      fontFamily: theme.fonts.secondary.reg,
      border: "none",
      outline: "none",
      width: "100%",
      fontSize: "14px",
    },
    InputSubLine: {
      width: "100%",
      height: "1px",
      maxHeight: "1px",
      minHeight: "1px",
      backgroundColor: theme.palette.common.darkGray,
    },
    HalfWidthInput: {
      width: "50%",
    },
    FullWidthInput: {
      width: "100%",
    },
    SeventyFiveWidthInput: {
      width: "75%",
    },
    TwentyFiveWidthInput: {
      width: "25%",
    },
    AssignLocksSubtext: {
      whiteSpace: "nowrap",
      fontSize: "13px",
      fontFamily: theme.fonts.primary.med,
      color: theme.palette.common.darkGray,
      margin: "0",
      marginLeft: "10px",
    },
    SaveSection: {
      marginTop: "50px",
      marginBottom: "10px",
    },
    EmailRow: {
      fontFamily: theme.fonts.primary.med,
      fontSize: "18px",
      marginTop: "30px",
    },
    CardInfo: {
      fontFamily: theme.fonts.primary.bold,
      fontWeight: "bold",
      fontSize: "18px",
      marginTop: "30px",
      display: "flex",
      flexDirection: "row",
    },
    CardInfoHeader: {
      fontWeight: "bold",
      fontSize: "18px",
      marginRight: "30px",
    },
  }));

  let { classes } = useStyles();

  return (
    <>
      {/* Component Container */}
      <section className={classes.ModalComponentContainer}>
        {/* TransparentLayer */}
        <ModalTransparentLayer
          id={paymentTransparentModalId}
          transitionTime={TransparentLayerTransitionTime}
        />

        {/* ModdalConatiner */}
        <section id={props.id} className={classes.AddMemberModal}>
          {/* header row */}
          <div className={classes.modalHeader}>
            <h2 className={classes.headerText}>Add New Card</h2>
            <span
              className={classes.exitX}
              onClick={() => {
                toggle(false);
              }}
            >
              &times;
            </span>
          </div>

          <div className={classes.EmailRow}>Email: emailname@email.com</div>

          <div className={classes.CardInfo}>
            <p className={classes.CardInfoHeader}>Card Information</p>
            <img src={Visa} height={"60px"} width={"40px"} />
            <img src={MasterCard} height={"60px"} width={"40px"} />
            <img src={Amex} height={"60px"} width={"40px"} />
            <img src={Discover} height={"60px"} width={"40px"} />
          </div>

          {/* Field Rows */}

          {/* Row 1 */}
          <div className={classes.ModalFieldRow}>
            <div
              className={[classes.ModalInputBox, classes.FullWidthInput].join(
                " "
              )}
            >
              <input
                id="card-input"
                className={classes.ModalInput}
                type="text"
                placeholder="CARD NUMBER"
                value={inputValues.cardNumber}
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    cardNumber: e.target.value,
                  });
                  //validateInputs();
                }}
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div>
          {/* Row 2 */}
          <div className={classes.ModalFieldRow}>
            <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                id="exp-input"
                className={classes.ModalInput}
                type="text"
                placeholder="EXP DATE"
                value={inputValues.expDate}
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    expDate: e.target.value,
                  });
                  //validateInputs();
                }}
              />
              <div className={classes.InputSubLine}></div>
            </div>

            <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                id="cvc-input"
                className={classes.ModalInput}
                type="text"
                placeholder="CVC"
                value={inputValues.cvc}
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    cvc: e.target.value,
                  });
                  //validateInputs();
                }}
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div>

          {/* Row 3 */}
          <div className={classes.ModalFieldRow}>
            <div
              className={[classes.ModalInputBox, classes.FullWidthInput].join(
                " "
              )}
            >
              <input
                id="name-input"
                className={classes.ModalInput}
                type="text"
                placeholder="NAME ON CARD"
                value={inputValues.nameOnCard}
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    nameOnCard: e.target.value,
                  });
                  //validateInputs();
                }}
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div>

          {/* Row 3 */}
          <div className={classes.ModalFieldRow}>
            <div
              className={[
                classes.ModalInputBox,
                classes.SeventyFiveWidthInput,
              ].join(" ")}
            >
              <input
                id="country-input"
                className={classes.ModalInput}
                type="text"
                placeholder="COUNTRY OR REGION"
                value="United States of America"
                disabled={true}
              />
              <div className={classes.InputSubLine}></div>
            </div>

            <div
              className={[
                classes.ModalInputBox,
                classes.TwentyFiveWidthInput,
              ].join(" ")}
            >
              <input
                id="input-zip"
                className={classes.ModalInput}
                type="text"
                placeholder="ZIP"
                value={inputValues.zip}
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    zip: e.target.value,
                  });
                  //validateInputs();
                }}
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div>

          {/* Save Section */}
          <section className={classes.SaveSection}>
            <RoundedButton
              color={"primary"}
              listener={() => {}}
              showArrow={false}
              showShadow={false}
              text={"SAVE CARD"}
              disabled={saveButtonDisabled}
            />
          </section>
        </section>
      </section>
    </>
  );
}

export default AddNewCardModal;
