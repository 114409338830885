import React from "react";
import { makeStyles } from "tss-react/mui";
import {
  Autocomplete,
  OutlinedInput,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import { classnames } from "tss-react/tools/classnames";
import { Lock } from "../../contexts/locks/locks.context.types";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Navigate, useNavigate } from "react-router-dom";

type IInputSearchProps = {
  items: Lock[];
};

export const LocksPageSearchInput: React.FC<IInputSearchProps> = ({
  items,
}) => {
  const [searchValue, setSearchValue] = useState<Lock | null>(null);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: Lock) => option.lockNickName,
  });

  return (
    <>
      <Autocomplete
        options={items}
        filterOptions={filterOptions}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.lockKey}
              onClick={() => {
                navigate(`/lockdetails/${option.lockKey}`);
              }}
            >
              {option.lockNickName}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Locks"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon style={{ color: "#a2a2a2" }} />,
            }}
            sx={{
              "&.MuiTextField-root": { borderRadius: "25px" },
              "&:.MuiTextField-input": { borderRadius: "26px" },
            }}
          />
        )}
        sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: "26px" } }}
      />
    </>
  );
};

const useStyles = makeStyles({})((theme) => ({
  root: {},
  emailText: {
    color: "#707070",
    fontSize: "14px",
  },
}));
