import React from "react";
import AppBody from "../../components/body/main.body.component";
import { Grid, Tabs, Tab } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import AccountTab from "./accounttab";
import PasswordTab from "./passwordtab";
import BillingTab from "./billingsettings";
import { useUserState } from "../../contexts/user/user.context";

type Props = {};

export const AccountSettings: React.FC<{}> = (props: Props) => {
   const { classes } = useStyles();
   const { TourType } = useParams<{ TourType: string }>();
   const { SettingsType } = useParams<{ SettingsType: string }>();
   const [selected, setSelected] = useState<GridRowSelectionModel>([]);
   const navigate = useNavigate();
   const { user } = useUserState();

   useEffect(() => {}, [selected]);

   return (
      <>
         <AppBody>
            <Grid
               container
               sx={{ height: "100%", width: "100%" }}
               className={classes.mainContainer}
               direction="column"
            >
               <Grid item>
                  <Grid
                     container
                     direction={"row"}
                     display="flex"
                     alignItems={"center"}
                     justifyContent="space-between"
                  >
                     <Grid item>
                        <span className={classes.header}>Account Settings</span>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item sx={{ height: "80%" }}>
                  <Grid
                     container
                     direction={"column"}
                     display="flex"
                     height={"100%"}
                  >
                     <Grid item sx={{ paddingBottom: "5px" }}>
                        <Grid
                           container
                           direction={"row"}
                           display={"flex"}
                           justifyContent="space-between"
                        >
                           <Grid item sx={{ marginLeft: "15px" }}>
                              <Tabs
                                 value={SettingsType}
                                 classes={{
                                    root: classes.tabsRoot,
                                    flexContainer: classes.tabsFlex,
                                    indicator: classes.tabsIndicator,
                                 }}
                              >
                                 {/* Account Tab */}
                                 <Tab
                                    label="ACCOUNT"
                                    disableFocusRipple
                                    disableRipple
                                    value="account"
                                    onClick={() => {
                                       navigate("/settings/account");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 {/* Password Tab */}
                                 <Tab
                                    label="PASSWORD"
                                    disableFocusRipple
                                    disableRipple
                                    value="password"
                                    onClick={() => {
                                       navigate("/settings/password");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 {/* Notifications Tab */}
                                 {/* <Tab
                        label="NOTIFICATIONS"
                        disableFocusRipple
                        disableRipple
                        value="notifications"
                        onClick={() => {
                          navigate("/settings/notifications");
                        }}
                        classes={{ root: classes.tabSingleRoot }}
                      /> */}
                                 {/* Block Users */}
                                 {/* <Tab
                        label="BLOCK USERS"
                        disableFocusRipple
                        disableRipple
                        value="block-users"
                        onClick={() => {
                          navigate("/settings/block-users");
                        }}
                        classes={{ root: classes.tabSingleRoot }}
                      /> */}
                                 {/* Billing */}
                                 {user?.isPrimaryOwner &&
                                    user?.accountType !== 5 &&
                                    user?.accountType !== 6 && (
                                       <Tab
                                          label="BILLING"
                                          disableFocusRipple
                                          disableRipple
                                          value="billing"
                                          onClick={() => {
                                             navigate("/settings/billing");
                                          }}
                                          classes={{
                                             root: classes.tabSingleRoot,
                                          }}
                                       />
                                    )}
                              </Tabs>
                           </Grid>
                        </Grid>
                        <Grid
                           item
                           sx={{ padding: "0px 20px 20px 0px" }}
                           height={
                              SettingsType === "billing" ? "1100px" : "600px"
                           }
                        >
                           {SettingsType === "account" && <AccountTab />}
                           {SettingsType === "password" && <PasswordTab />}
                           {SettingsType === "notifications" && <PasswordTab />}
                           {SettingsType === "block-users" && <PasswordTab />}
                           {SettingsType === "billing" &&
                              user?.isPrimaryOwner &&
                              user?.accountType !== 5 &&
                              user?.accountType !== 6 && <BillingTab />}
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "ListingsMain" })((theme) => ({
   mainContainer: {},
   firstRow: {},
   secondRow: {},
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "38px",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      display: "flex",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   listingsCellAddress: {
      color: "#707070",
      fontSize: "14px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   createdDate: {
      color: "#707070",
      fontSize: "14px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "14px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
}));
