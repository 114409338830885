import { Grid, SxProps } from '@mui/material';
import React from 'react';


export const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Grid container sx={PageContainerSx} display={'flex'} direction='column'>
            {children}
        </Grid>
    )
}


export const PageContainerRow: React.FC<{ children: React.ReactNode }> = ({ children}) => {
    return (
        <Grid container direction="row" sx={PageContainerRowSx}>
            {children}
        </Grid>
    )
}


export const PageBodyContainer: React.FC<{ children: React.ReactNode, title: string, sx: SxProps}> = ({ children, title, sx}) => {
    
    
    return (
        <Grid item sx={sx}>
            <div>{title}</div>
            <Grid container>
                {children}
            </Grid>
        </Grid>
    )
}


export const PageContainerFull: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    return (
        <div style={{ width: '100%', height: '100%'}}>
            <Grid container sx={{ border: '1px solid red'}}>
                <Grid item>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}


const PageContainerSx = {
    height: '100vh',
    width: '100%',
}

const PageContainerRowSx = {
    height: '100%',
    widht: '100%'
}

