import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as RentIcon } from "../../styles/assets/Icons/rent-icon-dark.svg";
import { ReactComponent as ManageIcon } from "../../styles/assets/Icons/manage-icon.svg";
import RoundedButton from "../common/boxlty.button";
import EastIcon from "@mui/icons-material/East";
import StarIcon from "../../styles/assets/Icons/star.svg";

interface PlanCardProps {
  StatusName: string;
  StatusDescription: string;
  ActiveListings: string;
  CostPerMonth: string;
  CostPerMonthYear: string;
  ButtonText: string;
  CurrentPlan?: string;
  MonthChosen: boolean;
  SetMonthChosen: Function;
  NextStepOption1: number;
  SetChosenPlan: Function;
  MonthlyOption: boolean;
  SetBackdrop?: (active: boolean) => void;
}

function sendEmail() 
{
  window.location.assign("mailto:hello@instashow.app");
}


function RegisterPlanCardComponent(props: PlanCardProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {}, []);

  let elementsToRender: JSX.Element[] = [];
  if (props.StatusName === "Plus") {
    // Render elements for condition 1
    elementsToRender = [
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Pre-Approval for tours
      </p>,
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> 1 additional team member
      </p>,
    ];
  } else if (props.StatusName === "Business") {
    // Render elements for condition 2
    elementsToRender = [
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Pre-Approval for tours
      </p>,
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Team members (up to 4)
      </p>,
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Team chat
      </p>,
    ];
  } else if (props.StatusName === "Enterprise") {
    // Render elements for condition 2
    elementsToRender = [
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Pre-Approval for tours
      </p>,
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Team members (5+)
      </p>,
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Team chat
      </p>,
      <p className={`${classes.CardText} ${classes.ArrowText}`}>
        <span className={classes.Arrow}>➜</span> Multiple team groups
      </p>,
    ];
  }

  const replaceWithSubscript = (text: string) => {
    return text
      .replace(/&reg;/gi, `<sup class=${classes.NoLineHeight}>&reg;</sup>`)
      .replace(/®/gi, `<sup class=${classes.NoLineHeight}>&reg;</sup>`);
  };

  return (
    <Grid
      container
      direction={"column"}
      justifyContent="center"
      className={`${classes.CardContainer} ${
        props.StatusName == "Plus" ? classes.CardContainerPopular : ""
      }`}
    >
      {props.StatusName === "Plus" && (
        <div className={classes.CardBackground}>
          <div className={classes.PopularText}>
            <img src={StarIcon} alt="Star" />
            <span> MOST POPULAR </span>
            <img src={StarIcon} alt="Star" />
          </div>
        </div>
      )}

      <Grid
        container
        direction={"column"}
        justifyContent="center"
        className={classes.HeaderContainer}
      >
        <h2 className={classes.TitleText}>{props.StatusName}</h2>
      </Grid>
      <Grid
        container
        direction={"column"}
        className={classes.CardBodyContainer}
      >
        <p
          className={`${classes.CardText} ${classes.CardTextBold} ${classes.DescriptionText}`}
          dangerouslySetInnerHTML={{
            __html: replaceWithSubscript(props.StatusDescription),
          }}
        ></p>
        {/* <p className={classes.CardText}>
          Active listings: {props.ActiveListings}
        </p>
        <p className={classes.CardText}>
          {props.MonthChosen && (
            <text className={classes.CostText}>{props.CostPerMonth}</text>
          )}
          {!props.MonthChosen && (
            <text className={classes.CostText}>{props.CostPerMonthYear}</text>
          )}
          /mo
        </p> */}
        <div className={classes.ListingsTeamsText}>
          <p>Active listings: {props.ActiveListings}</p>
          {props.StatusName == "Enterprise" && <p>Teams of 5+</p>}
        </div>
        {/* {props.StatusName == "Enterprise" && <p>Teams of 5+</p>} */}

        <div
          className={`${classes.PriceContainer} ${
            props.StatusName == "Enterprise" ? classes.HiddenText : ""
          }`}
        >
          <p
            className={`${classes.StartsAt} ${
              props.StatusName === "Free" ? classes.HiddenText : ""
            }`}
          >
            STARTS AT
          </p>
          <div className={classes.CostContainer}>
            <p className={classes.CostStartText}>
              {props.MonthChosen && (
                <span className={classes.CostText}>{props.CostPerMonth}</span>
              )}
              {!props.MonthChosen && (
                <span className={classes.CostText}>
                  {props.CostPerMonthYear}
                </span>
              )}
              {props.StatusName === "Free"
                ? "/mo     "
                : props.CurrentPlan === "free" ||
                  props.CurrentPlan === "undefined"
                ? "/mo*    "
                : "/mo     "}
            </p>

            <p
              className={`${classes.BilledAnnually} ${
                props.StatusName === "Free"
                  ? classes.HiddenText
                  : props.MonthlyOption == true
                  ? classes.HiddenText
                  : ""
              }`}
            >
              Billed Annually
            </p>
          </div>
          <p
            className={`${classes.DaysFree} ${
              props.StatusName === "Free" ||
              (props.CurrentPlan !== "free" &&
                props.CurrentPlan !== "undefined")
                ? classes.HiddenText
                : ""
            }`}
          >
            *First 7 days free
          </p>
          {/* {props.StatusName == "Free" ? (
            <p className={classes.DaysFree}></p>
          ) : props.CurrentPlan !== "free" ? (
            <p className={classes.DaysFree}></p>
          ) : (
            <p className={classes.DaysFree}>*First 7 days free</p>
          )} */}
        </div>
        <button
          className={
            props.CurrentPlan?.toLowerCase() ==
              props.StatusName.toLowerCase() && props.StatusName == "Enterprise"
              ? classes.RoundedButtonDisabledE
              : props.CurrentPlan?.toLowerCase() ==
                props.StatusName.toLowerCase()
              ? classes.RoundedButtonDisabled
              : props.StatusName != "Enterprise"
              ? classes.RoundedButton
              : classes.RoundedButtonE
          }
          disabled={
            props.CurrentPlan?.toLowerCase() === props.StatusName.toLowerCase()
          }
          //className={classes.RoundedButton}
          onClick={() => {
            if (props.StatusName === "Business" && props.SetBackdrop) {
              props.SetBackdrop(true);
            } else if (props.StatusName === "Enterprise") {
              sendEmail();
            } else {
              props.SetChosenPlan(
                props.StatusName,
                props.MonthChosen,
                props.NextStepOption1
              );
            }
          }}
        >
          <div className={classes.InnerButton}>
            <span className={classes.ButtonText}>{props.ButtonText}</span>
          </div>
        </button>
        <p className={`${classes.CardText} ${classes.ArrowText}`}>
          <span className={classes.Arrow}>➜</span> Smart lock integration
        </p>
        <p className={`${classes.CardText} ${classes.ArrowText}`}>
          <span className={classes.Arrow}>➜</span> Camera integration
        </p>
        <p className={`${classes.CardText} ${classes.ArrowText}`}>
          <span className={classes.Arrow}>➜</span> Visitor chat
        </p>
        <p className={`${classes.CardText} ${classes.ArrowText}`}>
          <span className={classes.Arrow}>➜</span> Tour fee
        </p>
        {elementsToRender.map((element) => element)}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({ name: "RegisterWelcomeInstaShowComponent" })(
  (theme) => ({
    TitleText: {
      alignSelf: "center",
      fontSize: "1.8rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textAlign: "center",
    },
    DetailsText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    NextButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "85px",
    },
    HeaderContainer: {
      backgroundColor: "black",
      borderTopLeftRadius: "25px",
      borderTopRightRadius: "25px",
      color: "white",
      zIndex: "1",
    },
    CardBodyContainer: {
      backgroundColor: "white",
      height: "560px",
      borderBottomRightRadius: "25px",
      borderBottomLeftRadius: "25px",
      boxShadow: "6px 6px 12px #00000014",
      alignItems: "center",
    },
    CardContainer: {
      width: "330px",
      margin: "1.5rem",
      position: "relative",
      marginTop: "4.0rem",
    },
    CardContainerPopular: {
      border: "3px solid #64E29A",
      borderRadius: "25px",
    },
    CardBackground: {
      backgroundColor: "#64E29A",
      borderTopLeftRadius: "25px",
      borderTopRightRadius: "25px",
      width: "324px",
      height: "75px",
      position: "absolute",
      top: "-40px",
      left: "-3px",
      zIndex: "0",
      border: "3px solid #64E29A",
      textAlign: "center",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "14px",
      justifyContent: "center",
    },
    PopularText: {
      marginTop: "10px",
    },
    RoundedButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background:
        "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)",
      borderRadius: "10px",
      padding: "1px",
      width: "90%",
      cursor: "pointer",
      height: "46px",
      color: "white",
      transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
      marginBottom: "0.4rem",
    },
    RoundedButtonE: {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      justifyContent: "center",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background:
        "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)",
      borderRadius: "10px",
      padding: "1px",
      width: "90%",
      cursor: "pointer",
      height: "46px",
      color: "white",
      transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
      marginBottom: "0.4rem",
    },
    RoundedButtonDisabled: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #aaaaaa",
      //borderColor: theme.palette.primary.main,
      backgroundColor: "transparent",
      borderRadius: "10px",
      padding: "1px",
      width: "90%",
      cursor: "pointer",
      height: "46px",
      color: "#aaaaaa",
      transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
      marginBottom: "0.4rem",
    },
    RoundedButtonDisabledE: {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      justifyContent: "center",
      border: "1px solid #aaaaaa",
      //borderColor: theme.palette.primary.main,
      backgroundColor: "transparent",
      borderRadius: "10px",
      padding: "1px",
      width: "90%",
      cursor: "pointer",
      height: "46px",
      color: "#aaaaaa",
      transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
      marginBottom: "0.4rem",
    },
    InnerButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "11px",
      paddingRight: "11px",
    },
    ButtonText: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "19px",
      fontWeight: "bold",
      letterSpacing: "1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    CardText: {
      alignSelf: "baseline",
      marginLeft: "6%",
      marginRight: "6%",
      marginTop: "0.5rem",
      whiteSpace: "pre-wrap",
    },
    DescriptionText: {
      alignSelf: "baseline",
      marginLeft: "6%",
      marginTop: "0.5rem",
      whiteSpace: "pre-wrap",
      height: "30px",
    },
    ListingsTeamsText: {
      alignSelf: "baseline",
      marginLeft: "6%",
      whiteSpace: "pre-wrap",
      height: "40px",
    },
    CardTextPrice: {
      alignSelf: "baseline",
      marginLeft: "6%",
      marginTop: "0.5rem",
      whiteSpace: "pre-wrap",
      marginBottom: "0",
    },
    CostStartText: {
      alignSelf: "baseline",
      marginTop: "0",
      whiteSpace: "pre-wrap",
      marginBottom: "0",
    },
    CardTextBold: {
      fontWeight: "bold",
    },
    CostText: {
      fontSize: "2.5rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    CostContainer: {
      display: "flex",
    },
    ArrowText: {
      marginTop: ".1rem !important",
    },
    Arrow: {
      color: "#39b54a",
    },
    StartsAt: {
      marginBottom: "0",
      fontSize: "10px",
      fontFamily: theme.fonts.primary.reg,
      color: theme.palette.common.medGray,
      alignSelf: "baseline",
    },
    DaysFree: {
      fontSize: "12px",
      fontStyle: "italic",
      color: theme.palette.common.medGray,
      marginTop: "0",
      alignSelf: "baseline",
    },
    BilledAnnually: {
      fontSize: "12px",
      color: theme.palette.common.medGray,
      marginTop: "0",
      alignSelf: "baseline",
    },
    PriceContainer: {
      marginTop: "0px",
      marginBottom: "10px",
      alignSelf: "baseline",
      height: "90px",
    },
    HiddenText: {
      visibility: "hidden",
    },
    NoLineHeight: {
      lineHeight: "0px",
    },
  })
);

export default RegisterPlanCardComponent;
