import React from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createPropertyStepSixUserInvite, getUserInviteList } from "../../../contexts/api/api.functions";
import { useCreateListingState, useCreateListingDispatch, CreateListingActions } from "../../../contexts/create-listing/create-listing.context";
import { Alert, Snackbar } from '@mui/material'
import { useState, useEffect } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useTheme } from "@mui/material";
import { S3DirectoryName } from "../../../contexts/api/api.types";
import axios from "axios";
import { getListing, generatePreSignedUrl, createPropertyStepTwoPhotos } from "../../../contexts/api/api.functions";
const { v4: uuidv4 } = require('uuid');
export interface UserInvite {
  emailId: string
  propertyId: string
}

export const createListingTeamDefaultValues = {
  photosSubmitted: false
};

export const ListingCreateFormContextTeam: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const theme = useTheme()
  const [successOpen, setSuccessOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [photoErrorOpen, setPhotoErrorOpen] = useState(false)
  const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false)
  const [photosSubmitted, setPhotosSubmitted] = useState(false)
  const { propertyId, photos, featurePhoto, featurePhotoPath, photoPaths } = useCreateListingState()
  const dispatch = useCreateListingDispatch()
  const schema = yup.object().shape({
    photosSubmitted: yup.boolean().required()
  });

  const methods = useForm({
    defaultValues: createListingTeamDefaultValues,
    resolver: yupResolver(schema),
  });

  const handleSubmitFunction = async (data: any) => {
    if (!featurePhotoPath) {
      if (!featurePhoto) {
        setPhotoErrorOpen(true)
        return
      } else if (featurePhoto && featurePhoto.file === "") {
        setPhotoErrorOpen(true)
        return
      }
    }
    if (photoPaths && photoPaths.length === 0) {
      if (photos.length === 0) {
        setPhotoErrorOpen(true)
        return
      }
    }
    if (featurePhotoPath && photoPaths && photoPaths.length > 0 && photos.length === 0) {
      const bodyPhotos = {
        propertyId: propertyId!.toString(),
        stepId: 2,
        mainImg: featurePhotoPath,
        images: [...photoPaths]
      }
      const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos)
      if (resPhoto && resPhoto.data && resPhoto.data.status === true) {
        setBackdropOpen(false)
        setSuccessOpen(true)
        return
      } else {
        setBackdropOpen(false)
        setError("We could not update the photos, please try again.")
        setErrorOpen(true)
        return
      }
    } else if (featurePhoto.file !== "" && photos.length > 0) {
      if (!propertyId) {
        return
      }
      setBackdropOpen(true)
      let featureKey: string = uuidv4();
      let photosKeyArr: Array<string> = [];
      if (featurePhotoPath === null) {
        const featureRequest = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: featureKey });
        if (featureRequest && featureRequest.data && featureRequest.data.status === true) {
          const featureUrl = featureRequest.data.result;
          await axios.put(featureUrl, featurePhoto.file)
            .then((response) => {
              // console.log(response)

            })
            .catch((error) => {
              setBackdropOpen(false)
              setError("We could not update the photos, please try again.")
              setErrorOpen(true)
              return
            })
        }
      }
      await Promise.all(
        photos.map(async (photo) => {
          let photoKey = uuidv4()

          const res1 = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: photoKey })
          if (res1 && res1.data && res1.data.status === true) {
            photosKeyArr.push(photoKey);
            const photoUrl = res1.data.result;
            await axios.put(photoUrl, photo.file)
              .then(() => {
                // console.log(`Uploaded file ${photoKey}`)
              })
              .catch((error) => {
                setBackdropOpen(false)
                setError("We could not update the photos, please try again.")
                setErrorOpen(true)
                return
              })
          }
        })
      )

      const body4 = {
        propertyId: propertyId.toString(),
        stepId: 2,
        mainImg: featureKey,
        images: [...photosKeyArr]
      }
      const res4 = await createPropertyStepTwoPhotos(body4)
      if (res4 && res4.data && res4.data.status && res4.data.status === true) {
        setBackdropOpen(false)
        setSuccessOpen(true)
      } else {
        setBackdropOpen(false)
        setError("We could not update the photos, please try again.")
        setErrorOpen(true)
        return
      }
    } else if (featurePhotoPath && photos.length > 0) {
      if (!propertyId) {
        return
      }
      setBackdropOpen(true)
      let photosKeyArr: Array<string> = [];
      await Promise.all(
        photos.map(async (photo) => {
          let photoKey = uuidv4()

          const res1 = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: photoKey })
          if (res1 && res1.data && res1.data.status === true) {
            photosKeyArr.push(photoKey);
            const photoUrl = res1.data.result;
            await axios.put(photoUrl, photo.file)
              .then(() => {
                // console.log(`Uploaded file ${photoKey}`)
              })
              .catch((error) => {
                setBackdropOpen(false)
                setError("We could not update the photos, please try again.")
                setErrorOpen(true)
                return
              })
          }
        })
      )
      let body4: any;
      if (photoPaths) {
        body4 = {
          propertyId: propertyId.toString(),
          stepId: 2,
          mainImg: featurePhotoPath,
          images: [...photoPaths, ...photosKeyArr]
        }
      } else {
        body4 = {
          propertyId: propertyId.toString(),
          stepId: 2,
          mainImg: featurePhotoPath,
          images: [...photosKeyArr]
        }
      }
      const res4 = await createPropertyStepTwoPhotos(body4)
      if (res4 && res4.data && res4.data.status && res4.data.status === true) {
        setBackdropOpen(false)
        setSuccessOpen(true)
      } else {
        setBackdropOpen(false)
        setError("We could not update the photos, please try again.")
        setErrorOpen(true)
        return
      }
    } else if (featurePhoto.file !== "" && photoPaths && propertyId !== null) {

      let featureKey: string = uuidv4();
      const featureRequest = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: featureKey });
      if (featureRequest && featureRequest.data && featureRequest.data.status === true) {
        const featureUrl = featureRequest.data.result;
        await axios.put(featureUrl, featurePhoto.file)
          .then((response) => {
            // console.log(response)

          })
          .catch((error) => {
            setBackdropOpen(false)
            setError("We could not update the photos, please try again.")
            setErrorOpen(true)
            return
          })
      }
      const propertyIdValue = propertyId;
      const body5 = {
        propertyId: propertyIdValue,
        stepId: 2,
        mainImg: featureKey,
        images: [...photoPaths]
      }
      const res5 = await createPropertyStepTwoPhotos(body5)
      if (res5 && res5.data && res5.data.status && res5.data.status === true) {
        setBackdropOpen(false)
        setSuccessOpen(true)
        return
      } else {
        setBackdropOpen(false)
        setError("We could not update the photos, please try again.")
        setErrorOpen(true)
        return
      }
    } else {
      setBackdropOpen(false)
      setError("We could not update the photos, please try again.")
      setErrorOpen(true)
      return
    }


  }




  const fetchAndUpdatePhotos = async () => {
    if (!propertyId) {
      return
    }
    const res = await getListing(parseInt(propertyId));
    if (res && res.data && res.data.status && res.data.status === true) {
      if (res.data.result[0].screen7[0].propertyId === parseInt(propertyId)) {
        if (res.data.result[0].screen2 && res.data.result[0].screen2.length > 0) {
          if (res.data.result[0].screen2[0].propertyFeaturePhoto && res.data.result[0].screen2[0].propertyFeaturePhoto !== null || "") {
            const value = res.data.result[0].screen2[0].propertyFeaturePhoto;
            dispatch({ type: CreateListingActions.setFeaturePhotoPath, payload: value })
          }
          if (res.data.result[0].screen2[0].propertyPhoto !== null || "") {
            const paths = [];
            for (var i = 0; i < res.data.result[0].screen2.length; i++) {
              const value = res.data.result[0].screen2[i].propertyPhoto;
              paths.push(value)
            }
            dispatch({ type: CreateListingActions.setPhotoPaths, payload: [...paths] })
          }
        }
      }
    }
  }


  useEffect(() => {
    if (propertyId) {
      fetchAndUpdatePhotos()
    } else {
      return
    }
  }, [propertyId])


  const onSubmit = (data: any) => {

    handleSubmitFunction(data)
    setBackdropOpen(false)
    // console.log("done running submit function")
  };

  return (
    <FormProvider {...methods}>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessOpen(false);
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessOpen(false);
          }}
        >
          Photos Saved Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={propertyIdErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPropertyIdErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPropertyIdErrorOpen(false);
          }}
        >
          No Property Id, if you already created a property go back to the main listings page to edit or create another property.
        </Alert>
      </Snackbar>
      <Snackbar
        open={photoErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPhotoErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPhotoErrorOpen(false);
          }}
        >
          Feature photo and at least one additional photo required.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorOpen(false);
          }}
        >
          {error ? error : "Something went wrong, please try again."}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}

      >
        <CircularProgress color="primary" />
      </Backdrop>
      <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};