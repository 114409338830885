import { IUserState } from "./user.context.types";
import { Middleware } from "../common/enhancereducer.hook";
import { IAction } from "../common/storebase.types";
import {
  clearStoredItem,
  getStoredItem,
  setStoredItem,
} from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
import { UserActions } from "./user.context";
import jwtdecode from "jwt-decode";

export const userLoginSession: Middleware<IUserState, IAction> =
  (state, dispatch) => (next) => (action) => {
    if (action.type === UserActions.loginSession) {
      if (action.payload) {
        const userId = action.payload.userId;
        const accessToken = action.payload.accessToken;
        const refreshToken = action.payload.refreshToken;
        dispatch({ type: UserActions.userId, payload: userId });
        dispatch({ type: UserActions.accessToken, payload: accessToken });
        dispatch({ type: UserActions.refreshToken, payload: refreshToken });
        setStoredItem(StorageKeys.access_token, accessToken, true);
        setStoredItem(StorageKeys.refresh_token, refreshToken, true);
        setStoredItem(StorageKeys.userId, userId, true);
      }
    }
    return next(action);
  };

export const userLogin: Middleware<IUserState, IAction> =
  (state, dispatch) => (next) => (action) => {
    if (action.type === UserActions.login) {
      if (action.payload) {
        const userId = action.payload.userId;
        const accessToken = action.payload.accessToken;
        const refreshToken = action.payload.refreshToken;
        dispatch({ type: UserActions.userId, payload: userId });
        dispatch({ type: UserActions.accessToken, payload: accessToken });
        dispatch({ type: UserActions.refreshToken, payload: refreshToken });
        setStoredItem(StorageKeys.access_token, accessToken, false);
        setStoredItem(StorageKeys.refresh_token, refreshToken, false);
        setStoredItem(StorageKeys.userId, userId, false)
      }
    }
    return next(action);
  };

export const userLogout: Middleware<IUserState, IAction> =
  (state, dispatch) => (next) => (action) => {
    if (action.type === UserActions.logout) {
      try {
        clearStoredItem(StorageKeys.access_token);
        clearStoredItem(StorageKeys.refresh_token);
        clearStoredItem(StorageKeys.userId);
        dispatch({ type: UserActions.user, payload: null });
        dispatch({ type: UserActions.accessToken, payload: null });
        dispatch({ type: UserActions.refreshToken, payload: null });
        dispatch({ type: UserActions.userId, payload: null })
      } catch (error) {
        //console.log("Error removing stored token");
      }
    }

    return next(action);
  };
