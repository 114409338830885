import { DirectionsRailwayFilledOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import mailIcon from "../../styles/assets/logos/mail-icon.svg";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";

function RegisterInvitedComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();

  function validateInvited() {
    return true;
  }

  function nextStep() {
    if (props.onSubmitInvited)
      props.onSubmitInvited(true, props.nextStepOption1);
  }

  return (
    <section className={classes.registerInvitedcontainer}>
      <h2 className={classes.registerInvitedHeader}>
        You must be invited by the primary manager of the Property Team
      </h2>

      <div className={classes.outerInputBox}></div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"continue"}
          disabled={false}
          listener={nextStep}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="CONTINUE WITHOUT INVITE"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterInvitedComponent" })((theme) => ({
  registerInvitedcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },

  registerInvitedHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
    textAlign: "center",
  },
  invitedSubtext: {
    fontSize: "12px",
    fontFamily: theme.fonts.primary.light,
    maxWidth: "310px",
    marginBottom: "20px",
  },
  invitedLink: {
    color: theme.palette.common.primary,
  },
  checkboxcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  IAgreeText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "13px",
    fontFamily: theme.fonts.secondary.bold,
  },
  outerInputBox: {
    height: "70px",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerInvitedInput: {
    border: "none",
    outline: "none",
    width: "80%",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
  },
  registerDividerLine: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.darkGray,
    marginBottom: "100px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

export default RegisterInvitedComponent;
