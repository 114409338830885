import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import { ToursMain } from "./non-enterprise/tours.main";
import { ToursPrimaryManagerAdminMain } from "./non-lender/tours.pm.admin.main";
import { LenderToursMain } from "./lender/lender.tours.main";
export const TourViewMainSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         return <ToursPrimaryManagerAdminMain />;
      //// Enterprise Lender
      case 6:
         if (user?.isEnterpriseSuperUser == 1) {
            return <LenderToursMain />;
         }
         return <ToursMain />;

      //// Non-Enterprise
      default:
         return <ToursMain />;
   }
};
