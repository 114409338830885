import { makeStyles } from "tss-react/mui";
import RoundedButton from "../common/boxlty.button";
import { useState, useEffect } from "react";
import BoxltyCheckbox from "../common/boxlty.checkbox";

function RegisterTermsComponent(props: any) {
   const { classes } = useStyles();

   const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
   const [checkedBox, setCheckedBox] = useState(props.defaultValue);
   useEffect(() => {
      if (props.defaultValue) setNextButtonDisabled(false);
   }, [props.defaultValue]);

   function validateTerms(terms: boolean) {
      return terms;
   }

   function nextStep() {
      let terms: boolean = (
         document.querySelector("#register-terms-input") as HTMLInputElement
      ).checked;
      if (validateTerms(terms)) {
         if (props.onSubmitTerms) props.onSubmitTerms(terms);
      }
   }

   return (
      <section className={classes.registerTermsContainer}>
         <h2 className={classes.registerTermsHeader}>
            Accept InstaShow's Terms & Conditions
         </h2>

         <div className={classes.outerInputBox}>
            <p className={classes.termsSubtext}>
               By checking the box below, I have reviewed and agree to the{" "}
               <a
                  className={classes.termsLink}
                  target="_blank"
                  href="https://instashow.app/terms-of-service/"
                  rel="noreferrer"
               >
                  terms & conditions.
               </a>
            </p>

            <div className={classes.checkboxContainer}>
               <h4 className={classes.IAgreeText}>I Agree</h4>
               <BoxltyCheckbox
                  id="register-terms-input"
                  checked={checkedBox}
                  disabled={false}
                  onClick={(checked) => {
                     setCheckedBox(checked);
                     if (checked) setNextButtonDisabled(false);
                     else setNextButtonDisabled(true);
                  }}
               />
            </div>
         </div>

         <div className={classes.registerButtonsContainer}>
            {/* <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        /> */}

            <RoundedButton
               id={"next"}
               disabled={nextButtonDisabled}
               listener={nextStep}
               boldText={false}
               showShadow={true}
               showArrow={true}
               arrowDirection={"right"}
               color="primary"
               text="NEXT"
            />
         </div>
      </section>
   );
}

const useStyles = makeStyles({ name: "RegisterTermsComponent" })((theme) => ({
   registerTermsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      marginTop: "10%",
      "@media (max-height: 660px)": {
         marginTop: "4%",
      },
   },
   registerTermsHeader: {
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      marginBottom: "35px",
      textAlign: "center",
   },
   termsSubtext: {
      fontSize: "16px",
      fontFamily: theme.fonts.primary.light,
      maxWidth: "310px",
      marginBottom: "20px",
      textAlign: "center",
   },
   termsLink: {
      color: theme.palette.common.primary,
   },
   checkboxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   IAgreeText: {
      margin: 0,
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: theme.fonts.secondary.bold,
      marginRight: "5px",
   },
   outerInputBox: {
      height: "70px",
   },
   registerInputBoxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   registerButtonsContainer: {
      marginTop: "160px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
   },
   registerMailIcon: {
      height: "10px",
      width: "auto",
      marginRight: "5px",
   },
   registerTermsInput: {
      border: "none",
      outline: "none",
      width: "80%",
      height: "20px",
      cursor: "text",
      backgroundColor: "transparent",
   },
   registerDividerLine: {
      height: "1px",
      width: "100%",
      backgroundColor: theme.palette.common.darkGray,
      marginBottom: "100px",
   },
   registerButtonSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
   },
}));

export default RegisterTermsComponent;
