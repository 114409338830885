import React from "react";
import {
   Select,
   MenuItem,
   Grid,
   TextField,
   Button,
   Divider,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import { UserSelectComponent } from "./user.select.component";
import { UserInviteComponent } from "./user.invite.component";
import { PendingInviteComponent } from "./pending.invite.component";
import { classnames } from "tss-react/tools/classnames";
import { AssignedMemberComponent } from "./assigned.members.component";
import { User } from "./user.select.component";
import { useFormContext } from "react-hook-form";
import { Invite, TeamMember } from "./forms.types";
import {
   createPropertyStepSixUserInvite,
   getUserInviteList,
} from "../../../../contexts/api/api.functions";
import { useCreateListingState } from "../../../../contexts/create-listing/create-listing.context";
import { userInviteSaasFlow } from "../../../../contexts/api/api.functions";
import { useTheme } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import {
   removeTeamMemberFromListing,
   removeInvite,
} from "../../../../contexts/api/api.functions";
export const TeamTabForm: React.FC<{
   users: User[];
   invites: Invite[];
   assignedMembers: TeamMember[];
   grabAssignedMembersList: () => void;
   grabTeamMemberList: () => void;
   getInviteList: () => void;
   setSelectedUserForProfile: (id: string) => void;
}> = ({
   users,
   setSelectedUserForProfile,
   invites,
   getInviteList,
   assignedMembers,
   grabAssignedMembersList,
}) => {
   const [teamMember, setTeamMember] = useState("Name or Email");
   const [selected, setSelected] = useState<string[]>([]);
   const [backdropOpen, setBackdropOpen] = useState(false);
   const [errorOpen, setErrorOpen] = useState(false);
   const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false);
   const [error, setError] = useState<null | string>(null);
   const { classes } = useStyles();
   const { register, watch } = useFormContext();
   const { propertyId } = useCreateListingState();
   const methods = useFormContext();

   const handleRemoveSelected = (id: string) => {
      const currentArr = [...selected];
      const index = currentArr.indexOf(id);
      //console.log(index);
      currentArr.splice(index, 1);
      const newArr = currentArr;
      //console.log(newArr);
      setSelected(newArr);
   };

   const setSelectedValue = (value: string) => {
      const currentArr = [...selected];
      const alreadySelected = currentArr.find((userId) => userId === value);
      if (alreadySelected) {
         return;
      }
      currentArr.push(value);
      setSelected(currentArr);
   };

   const handleInviteUsers = async () => {
      if (propertyId === null) {
         setPropertyIdErrorOpen(true);
         setBackdropOpen(false);
         return;
      }
      const inviteArr = selected.map((user) => {
         return user;
      });
      const body = {
         property: propertyId!,
         invites: [...inviteArr],
         assign: [],
      };
      const res = await userInviteSaasFlow(body);
      if (res && res.data && res.data.status && res.data.status === true) {
         if (res.data.result && res.data.result.enterpriseUpdate === 0) {
            setError(res.data.message);
            setErrorOpen(true);
            return;
         }
         if (res.data.result && res.data.result.enterpriseUpdate === 1) {
            setError(res.data.message);
            setErrorOpen(true);
            return;
         }
         if (res.data.result && res.data.result.userInvited === 1) {
            setError(res.data.message);
            setErrorOpen(true);
            setSelected([]);
            return;
         }
      }
      setSelected([]);
   };

   const handleRemovePendingAndReFetch = (email: string) => {
      setBackdropOpen(true);
      removeInvite({ input: email }).then(() => {
         getInviteList();
         grabAssignedMembersList();
         setBackdropOpen(false);
      });
   };

   const handleInviteAndReFetch = () => {
      setBackdropOpen(true);
      handleInviteUsers().then(() => {
         setSelected([]);
         getInviteList();
         grabAssignedMembersList();
         setBackdropOpen(false);
      });
   };

   const handleRemoveAssignedAndReFetch = (pId: string, userId: string) => {
      setBackdropOpen(true);
      removeTeamMemberFromListing({ pId: pId, teamMemberId: userId }).then(
         () => {
            getInviteList();
            grabAssignedMembersList();
            setBackdropOpen(false);
         }
      );
   };

   return (
      <>
         <Grid
            container
            direction="column"
            display={"flex"}
            width={"100%"}
            className={classes.mainContainer}
         >
            <Backdrop
               sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
               }}
               open={backdropOpen}
            >
               <CircularProgress color="primary" />
            </Backdrop>
            <Snackbar
               open={errorOpen}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               autoHideDuration={6000}
               onClose={() => {
                  setErrorOpen(false);
               }}
            >
               <Alert
                  severity="error"
                  onClose={() => {
                     setErrorOpen(false);
                  }}
               >
                  {error ? error : "Something went wrong."}
               </Alert>
            </Snackbar>
            <Snackbar
               open={propertyIdErrorOpen}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               autoHideDuration={6000}
               onClose={() => {
                  setPropertyIdErrorOpen(false);
               }}
            >
               <Alert
                  severity="error"
                  onClose={() => {
                     setPropertyIdErrorOpen(false);
                  }}
               >
                  No Property Id, if you already created a property go back to
                  the main listings page to edit or create another property.
               </Alert>
            </Snackbar>
            <Grid item sx={{ marginBottom: "10px" }}>
               <div
                  style={{
                     marginBottom: "5px",
                     color: "black",
                     fontSize: "16px",
                  }}
               >
                  Invite a team member to manage this listing.
               </div>
               <UserSelectComponent
                  users={users}
                  setSelected={setSelectedValue}
               />
            </Grid>
            {users
               .filter((user) => selected.indexOf(user.email) !== -1)
               .map((user, index) => (
                  <UserInviteComponent
                     key={index}
                     user={user}
                     handleRemove={handleRemoveSelected}
                     setSelectedUserForProfile={setSelectedUserForProfile}
                  />
               ))}
            <Grid
               item
               sx={{ width: "30%", marginTop: "15px" }}
               display="flex"
               justifyContent={"flex-end"}
            >
               <Button
                  variant="contained"
                  onClick={() => {
                     handleInviteAndReFetch();
                  }}
                  disabled={selected.length < 1 ? true : false}
               >
                  Invite
               </Button>
            </Grid>
            <Grid item sx={{ marginTop: "20px" }}>
               {invites
                  .filter((invite) => invite.inviteStatus === 1)
                  .map((invite, index) => (
                     <>
                        <PendingInviteComponent
                           key={index}
                           invite={invite}
                           handleRemove={() => {
                              handleRemovePendingAndReFetch(invite.email);
                           }}
                        />
                     </>
                  ))}
            </Grid>
            <Grid item sx={{ width: "60%", marginTop: "15px" }}>
               <Divider sx={{ borderBottomWidth: "2px" }} />
            </Grid>
            <Grid item sx={{ marginTop: "20px", paddingLeft: "10px" }}>
               <div className={classes.assignedMembers}>Assigned Members:</div>
            </Grid>
            <Grid item sx={{ marginTop: "20px" }}>
               {assignedMembers.length > 0
                  ? assignedMembers.map((member, index) => (
                       <>
                          <AssignedMemberComponent
                             key={index}
                             teamMember={member}
                             handleRemove={() => {
                                if (propertyId === null) {
                                   setPropertyIdErrorOpen(true);
                                   setBackdropOpen(false);
                                   return;
                                }
                                handleRemoveAssignedAndReFetch(
                                   propertyId,
                                   member.userId.toString()
                                );
                             }}
                          />
                       </>
                    ))
                  : null}
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "team-tab-form" })((theme) => ({
   mainContainer: {
      maxHeight: "600px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "nowrap",
   },
   assignedMembers: {
      fontSize: "18px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
   },
}));
