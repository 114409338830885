
import React, { createContext, useContext, useMemo } from 'react'
import { useUserState, useUserDispatch } from '../user/user.context'

import { createFuncs } from './api.functions'

export type IAPI = ReturnType<typeof createFuncs>

const ApiContext: any = createContext({})

export const useApi = (): IAPI => useContext(ApiContext)

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
const {accessToken } = useUserState()
const userDispatch = useUserDispatch()

  const funcs = useMemo(() => {
    return createFuncs()
  }, [accessToken])


  return <ApiContext.Provider value={funcs}>{children}</ApiContext.Provider>
}