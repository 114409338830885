import React, { useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
   createPropertyStepFiveAssignLock,
   generatePreSignedUrl,
   createPropertyStepTwoPhotos,
} from "../../../contexts/api/api.functions";
import {
   CreateListingActions,
   useCreateListingDispatch,
   useCreateListingState,
} from "../../../contexts/create-listing/create-listing.context";
import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import {
   CreatePropertyRequest5AssignLock,
   S3DirectoryName,
} from "../../../contexts/api/api.types";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material";
import { getListing } from "../../../contexts/api/api.functions";
import { AccessChoice } from "./primary-tabs/listings.hardware.tab";
const { v4: uuidv4 } = require("uuid");

// export interface CreatePropertyRequest5AssignLock {
//   stepId: number;
//   propertyId: string;
//   lockTypeId: string;
//   lockKey: string;
//   doorImg: string;
//   lockImg: string;
//   igloolockTypeId: string;
//   masterlockTypeId: string;
// }

export const createListingHardwareDefaultValues = {
   lockTypeId: "1",
   lockKey: "1",
   doorImg: "",
   lockImg: "",
   igloolockTypeId: "",
   masterLockTypeId: "",
   accessChoice: null,
};

export const ListingCreateFormContextHardware: React.FC<{
   children: React.ReactNode;
}> = ({ children }) => {
   const [successOpen, setSuccessOpen] = useState(false);
   const [error, setError] = useState<string | null>(null);
   const [errorOpen, setErrorOpen] = useState(false);
   const [accessChoiceErrorOpen, setAccessChoiceErrorOpen] = useState(false);
   const [lockImageErrorOpen, setLockImageErrorOpen] = useState(false);
   const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false);
   const [backdropOpen, setBackdropOpen] = useState(false);
   const [photoErrorOpen, setPhotoErrorOpen] = useState(false);
   const theme = useTheme();
   const {
      nickName,
      propertyId,
      doorImage,
      lockImage,
      featurePhoto,
      featurePhotoPath,
      photoPaths,
      photos,
   } = useCreateListingState();
   const dispatch = useCreateListingDispatch();
   const schema = yup.object().shape({
      lockTypeId: yup.string().required("Lock Type is required"),
      lockKey: yup.string(),
      doorImg: yup.string(),
      lockImg: yup.string(),
      igloolockTypeId: yup.string(),
      masterLockTypeId: yup.string(),
      accessChoice: yup.number().nullable(),
   });

   const methods = useForm({
      defaultValues: createListingHardwareDefaultValues,
      resolver: yupResolver(schema),
   });

   const handleSubmitFunction = async (data: any) => {
      if (propertyId === null) {
         setPropertyIdErrorOpen(true);
         return;
      }
      console.log(data.accessChoice);
      if (data.accessChoice === null) {
         setAccessChoiceErrorOpen(true);
         return;
      }
      //console.log("lock key: ", data.lockKey);
      if (!lockImage) {
         if (data.accessChoice === AccessChoice.LOCK) {
            setLockImageErrorOpen(true);
            return;
         }
      }

      if (photoPaths && photoPaths.length === 0) {
         if (photos.length === 0) {
            setPhotoErrorOpen(true);
            return;
         }
      }
      if (!featurePhotoPath) {
         if (!featurePhoto) {
            setPhotoErrorOpen(true);
            return;
         } else if (featurePhoto && featurePhoto.file === "") {
            setPhotoErrorOpen(true);
            return;
         }
      }
      setBackdropOpen(true);
      let doorImgKey: string = uuidv4();
      let lockImgKey: string = uuidv4();

      if (data.accessChoice === AccessChoice.LOCK) {
         if (doorImage) {
            const doorImageRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: doorImgKey,
            });
            if (
               doorImageRequest &&
               doorImageRequest.data &&
               doorImageRequest.data.status === true
            ) {
               const doorImgUrl = doorImageRequest.data.result;
               await axios
                  .put(doorImgUrl, doorImage)
                  .then(() => {})
                  .catch((error) => {
                     setBackdropOpen(false);
                     setError(
                        "We could not update the photos, please try again."
                     );
                     setErrorOpen(true);
                     return;
                  });
            }
         }
         const lockImageRequest = await generatePreSignedUrl({
            directory: S3DirectoryName.PROPERTY,
            fileName: lockImgKey,
         });
         if (
            lockImageRequest &&
            lockImageRequest.data &&
            lockImageRequest.data.status === true
         ) {
            const lockImgUrl = lockImageRequest.data.result;
            await axios
               .put(lockImgUrl, lockImage)
               .then(() => {})
               .catch((error) => {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
               });
         }
      }
      let body: CreatePropertyRequest5AssignLock;
      if (data.accessChoice === AccessChoice.INSTRUCTIONS) {
         body = {
            stepId: 5,
            propertyId: propertyId!.toString(),
            lockTypeId: "",
            lockKey: "",
            doorImg: "",
            lockImg: "",
            igloolockTypeId: "",
            masterlockTypeId: "",
            accessChoice: 0,
         };
      } else {
         body = {
            stepId: 5,
            propertyId: propertyId!.toString(),
            lockTypeId: data.lockTypeId,
            lockKey: data.lockKey,
            doorImg: doorImage ? doorImgKey : "",
            lockImg: lockImgKey,
            igloolockTypeId: data.igloolockTypeId,
            masterlockTypeId: data.masterlockTypeId,
            accessChoice: 1,
         };
      }

      const res = await createPropertyStepFiveAssignLock(body);
      if (res && res.data && res.data.status === true) {
         if (res.data.result.lockAssigned == 1) {
            setError("Lock is already assigned to another listing");
            setErrorOpen(true);
            return;
         }
         if (
            featurePhotoPath &&
            photoPaths &&
            photoPaths.length > 0 &&
            photos.length === 0
         ) {
            const bodyPhotos = {
               propertyId: propertyId!.toString(),
               stepId: 2,
               mainImg: featurePhotoPath,
               images: [...photoPaths],
            };
            const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos);
            if (resPhoto && resPhoto.data && resPhoto.data.status === true) {
               setBackdropOpen(false);
               setSuccessOpen(true);
               return;
            } else {
               setBackdropOpen(false);
               setError("We could not update the photos, please try again.");
               setErrorOpen(true);
               return;
            }
         } else if (featurePhoto.file !== "" && photos.length > 0) {
            if (!propertyId) {
               return;
            }
            let featureKey: string = uuidv4();
            let photosKeyArr: Array<string> = [];
            if (featurePhotoPath === null) {
               const featureRequest = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: featureKey,
               });
               if (
                  featureRequest &&
                  featureRequest.data &&
                  featureRequest.data.status === true
               ) {
                  const featureUrl = featureRequest.data.result;
                  await axios
                     .put(featureUrl, featurePhoto.file)
                     .then((response) => {
                        // console.log(response)
                     })
                     .catch((error) => {
                        setBackdropOpen(false);
                        setError(
                           "We could not update the photos, please try again."
                        );
                        setErrorOpen(true);
                        return;
                     });
               }
            }
            await Promise.all(
               photos.map(async (photo) => {
                  let photoKey = uuidv4();

                  const res1 = await generatePreSignedUrl({
                     directory: S3DirectoryName.PROPERTY,
                     fileName: photoKey,
                  });
                  if (res1 && res1.data && res1.data.status === true) {
                     photosKeyArr.push(photoKey);
                     const photoUrl = res1.data.result;
                     await axios
                        .put(photoUrl, photo.file)
                        .then(() => {
                           // console.log(`Uploaded file ${photoKey}`)
                        })
                        .catch((error) => {
                           setBackdropOpen(false);
                           setError(
                              "We could not update the photos, please try again."
                           );
                           setErrorOpen(true);
                           return;
                        });
                  }
               })
            );

            const body4 = {
               propertyId: propertyId.toString(),
               stepId: 2,
               mainImg: featureKey,
               images: [...photosKeyArr],
            };
            const res4 = await createPropertyStepTwoPhotos(body4);
            if (
               res4 &&
               res4.data &&
               res4.data.status &&
               res4.data.status === true
            ) {
               setBackdropOpen(false);
               setSuccessOpen(true);
            } else {
               setBackdropOpen(false);
               setError("We could not update the photos, please try again.");
               setErrorOpen(true);
               return;
            }
         } else if (featurePhotoPath && photos.length > 0) {
            if (!propertyId) {
               return;
            }
            setBackdropOpen(true);
            let photosKeyArr: Array<string> = [];
            await Promise.all(
               photos.map(async (photo) => {
                  let photoKey = uuidv4();

                  const res1 = await generatePreSignedUrl({
                     directory: S3DirectoryName.PROPERTY,
                     fileName: photoKey,
                  });
                  if (res1 && res1.data && res1.data.status === true) {
                     photosKeyArr.push(photoKey);
                     const photoUrl = res1.data.result;
                     await axios
                        .put(photoUrl, photo.file)
                        .then(() => {
                           // console.log(`Uploaded file ${photoKey}`)
                        })
                        .catch((error) => {
                           setBackdropOpen(false);
                           setError(
                              "We could not update the photos, please try again."
                           );
                           setErrorOpen(true);
                           return;
                        });
                  }
               })
            );
            let body4: any;
            if (photoPaths) {
               body4 = {
                  propertyId: propertyId.toString(),
                  stepId: 2,
                  mainImg: featurePhotoPath,
                  images: [...photoPaths, ...photosKeyArr],
               };
            } else {
               body4 = {
                  propertyId: propertyId.toString(),
                  stepId: 2,
                  mainImg: featurePhotoPath,
                  images: [...photosKeyArr],
               };
            }
            const res4 = await createPropertyStepTwoPhotos(body4);
            if (
               res4 &&
               res4.data &&
               res4.data.status &&
               res4.data.status === true
            ) {
               setBackdropOpen(false);
               setSuccessOpen(true);
               return;
            } else {
               setBackdropOpen(false);
               setError("We could not update the photos, please try again.");
               setErrorOpen(true);
               return;
            }
         } else if (featurePhoto.file !== "" && photoPaths) {
            let featureKey: string = uuidv4();
            const featureRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: featureKey,
            });
            if (
               featureRequest &&
               featureRequest.data &&
               featureRequest.data.status === true
            ) {
               const featureUrl = featureRequest.data.result;
               await axios
                  .put(featureUrl, featurePhoto.file)
                  .then((response) => {
                     // console.log(response)
                  })
                  .catch((error) => {
                     setBackdropOpen(false);
                     setError(
                        "We could not update the photos, please try again."
                     );
                     setErrorOpen(true);
                     return;
                  });
            }
            const body5 = {
               propertyId: propertyId.toString(),
               stepId: 2,
               mainImg: featureKey,
               images: [...photoPaths],
            };
            const res5 = await createPropertyStepTwoPhotos(body5);
            if (
               res5 &&
               res5.data &&
               res5.data.status &&
               res5.data.status === true
            ) {
               setBackdropOpen(false);
               setSuccessOpen(true);
               return;
            } else {
               setBackdropOpen(false);
               setError("We could not update the photos, please try again.");
               setErrorOpen(true);
               return;
            }
         } else {
            setBackdropOpen(false);
            setSuccessOpen(true);
         }
      } else {
         setError("We could not update the property, please try again.");
         setBackdropOpen(false);
         setErrorOpen(true);
         return;
      }
   };

   const fetchAndUpdateValues = async () => {
      if (propertyId) {
         setBackdropOpen(true);
         const res = await getListing(parseInt(propertyId));
         if (res && res.data && res.data.status && res.data.status === true) {
            if (
               res.data.result[0].screen1[0].propertyId === parseInt(propertyId)
            ) {
               if (res.data.result[0].screen5[0].propertyLockType) {
                  const value =
                     res.data.result[0].screen5[0].propertyLockType.toString();
                  methods.setValue("lockTypeId", value);
               }

               if (res.data.result[0].screen5[0].propertyLockKey) {
                  const value = res.data.result[0].screen5[0].propertyLockKey;
                  methods.setValue("lockKey", value);
               }

               if (
                  res.data.result[0].screen5[0].propertyLockImg &&
                  res.data.result[0].screen5[0].propertyLockImg !== ""
               ) {
                  const value = res.data.result[0].screen5[0].propertyLockImg;
                  dispatch({
                     type: CreateListingActions.setLockImagePath,
                     payload: value,
                  });
               } else {
                  dispatch({
                     type: CreateListingActions.setLockImagePath,
                     payload: null,
                  });
               }

               if (
                  res.data.result[0].screen5[0].propertyDoorImg &&
                  res.data.result[0].screen5[0].propertyDoorImg !== ""
               ) {
                  const value = res.data.result[0].screen5[0].propertyDoorImg;
                  // console.log(value)
                  dispatch({
                     type: CreateListingActions.setDoorImagePath,
                     payload: value,
                  });
               } else {
                  dispatch({
                     type: CreateListingActions.setDoorImagePath,
                     payload: null,
                  });
               }

               if (
                  res.data.result[0].screen5[0] &&
                  res.data.result[0].screen5[0].accessChoice &&
                  res.data.result[0].screen5[0].accessChoice !== ""
               ) {
                  if (res.data.result[0].screen5[0].accessChoice === "0") {
                     const value1 = parseInt(
                        res.data.result[0].screen5[0].accessChoice
                     );
                     methods.setValue("accessChoice", value1);
                  }

                  if (res.data.result[0].screen5[0].accessChoice === "1") {
                     const value2 = parseInt(
                        res.data.result[0].screen5[0].accessChoice
                     );
                     methods.setValue("accessChoice", value2);
                  }
               }

               if (
                  res.data.result[0].screen2 &&
                  res.data.result[0].screen2.length > 0
               ) {
                  if (
                     res.data.result[0].screen2[0].propertyFeaturePhoto !==
                        null ||
                     ""
                  ) {
                     const value =
                        res.data.result[0].screen2[0].propertyFeaturePhoto;
                     dispatch({
                        type: CreateListingActions.setFeaturePhotoPath,
                        payload: value,
                     });
                  }

                  if (
                     res.data.result[0].screen2[0].propertyPhoto !== null ||
                     ""
                  ) {
                     const paths = [];
                     for (
                        var i = 0;
                        i < res.data.result[0].screen2.length;
                        i++
                     ) {
                        const value =
                           res.data.result[0].screen2[i].propertyPhoto;
                        paths.push(value);
                     }
                     dispatch({
                        type: CreateListingActions.setPhotoPaths,
                        payload: [...paths],
                     });
                  }
               }
            }
         }
         setBackdropOpen(false);
      } else {
         return;
      }
   };

   useEffect(() => {
      fetchAndUpdateValues();
   }, [propertyId]);

   const onSubmit = (data: any) => {
      handleSubmitFunction(data).then(() => {
         setBackdropOpen(false);
      });
      // console.log('finished running submit')
   };

   return (
      <FormProvider {...methods}>
         <Snackbar
            open={successOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setSuccessOpen(false);
            }}
         >
            <Alert
               severity="success"
               onClose={() => {
                  setSuccessOpen(false);
               }}
            >
               Hardware Tab Saved Successfully!
            </Alert>
         </Snackbar>
         <Snackbar
            open={errorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setErrorOpen(false);
               }}
            >
               {error ? error : "Something went wrong, please try again."}
            </Alert>
         </Snackbar>
         <Snackbar
            open={accessChoiceErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setAccessChoiceErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setAccessChoiceErrorOpen(false);
               }}
            >
               Access choice is required, please select an option and try again.
            </Alert>
         </Snackbar>
         <Snackbar
            open={lockImageErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setLockImageErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setLockImageErrorOpen(false);
               }}
            >
               Lock location image is required, please add one and try again.
            </Alert>
         </Snackbar>
         <Snackbar
            open={photoErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setPhotoErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setPhotoErrorOpen(false);
               }}
            >
               Feature photo and at least one additional photo required.
            </Alert>
         </Snackbar>
         <Snackbar
            open={propertyIdErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setPropertyIdErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setPropertyIdErrorOpen(false);
               }}
            >
               No Property Id, if you already created a property go back to the
               main listings page to edit or create another property.
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
         >
            <CircularProgress color="primary" />
         </Backdrop>
         <form
            style={{ width: "100%" }}
            onSubmit={methods.handleSubmit(onSubmit)}
         >
            {children}
         </form>
      </FormProvider>
   );
};
