import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";

function RegisterCompanyComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    if (props.defaultValue !== "" && props.defaultValue != null) {
      //console.log(props.defaultValue);
      let defaultValid =
        validateCompanyName(props.defaultValue[0]) &&
        validateLicenseNumber(props.defaultValue[1]) &&
        validatePhone(props.defaultValue[2]);
      setNextButtonDisabled(!defaultValid);
    }
  }, [props.defaultValue]);

  function setNextToActive(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) {
    let companyName: string = (
      document.querySelector("#register-company-name-input") as HTMLInputElement
    ).value;
    let companyLicenseNumber: string = (
      document.querySelector(
        "#register-company-license-input"
      ) as HTMLInputElement
    ).value;
    let companyPhone: string = (
      document.querySelector(
        "#register-company-phone-input"
      ) as HTMLInputElement
    ).value;
    if (
      companyName.length > 0 &&
      companyLicenseNumber.length >= 4 &&
      companyPhone.length >= 10
    ) {
      //set button to active
      setNextButtonDisabled(false);
    } else {
      //set to inactive

      setNextButtonDisabled(true);
    }
  }

  function validateCompanyName(company: string) {
    if (company === "" || company === null || company === undefined) {
      // Return true because the button disabled calls this on load
      return false;
    }

    let isValid = true;
    let companyInput: HTMLInputElement = document.querySelector(
      "#register-company-name-input"
    ) as HTMLInputElement;
    let line: HTMLElement = document.querySelector(
      "#divider-line"
    ) as HTMLInputElement;

    if (company.length === 0) {
      isValid = false;
      let validationMessage = "Company Name is required";
      companyInput.value = "";
      companyInput.placeholder = validationMessage;
      companyInput.style.color = "red"; // Apply styling for validation message
      companyInput.classList.add(classes.invalidInput);
      line.style.backgroundColor = "red";
    } else {
      line.style.backgroundColor = theme.palette.common.darkGray;
    }

    return isValid;
  }

  function validatePhone(phone: string) {
    if (phone === "" || phone === null || phone === undefined) {
      // Return true because the button disabled calls this on load
      return false;
    }

    let isValid: boolean = true;
    let phoneInput: HTMLInputElement = document.querySelector(
      "#register-company-phone-input"
    ) as HTMLInputElement;
    let line: HTMLElement = document.querySelector(
      "#divider-line"
    ) as HTMLInputElement;

    // Check if the phone number contains any letters
    if (/[a-zA-Z]/.test(phone)) {
      isValid = false;
      let validationMessage = "Invalid Phone Number";
      phoneInput.value = "";
      phoneInput.placeholder = validationMessage;
      phoneInput.classList.add(classes.invalidInput);
      line.style.backgroundColor = "red";
    }
    return isValid;
  }

  function validateLicenseNumber(licenseNumber: string) {
    if (
      licenseNumber === "" ||
      licenseNumber === null ||
      licenseNumber === undefined
    ) {
      // Return true because the button disabled calls this on load
      return false;
    }

    let isValid: boolean = true;
    let licenseInput: HTMLInputElement = document.querySelector(
      "#register-company-license-input"
    ) as HTMLInputElement;
    let line: HTMLElement = document.querySelector(
      "#divider-line"
    ) as HTMLInputElement;

    // Check if the license number contains any non-digit characters
    if (!/^[a-zA-Z0-9.]+$/.test(licenseNumber) || licenseNumber.length < 4) {
      isValid = false;
      let validationMessage = "Invalid License Number";
      licenseInput.value = "";
      licenseInput.placeholder = validationMessage;
      licenseInput.style.color = "red"; // Apply styling for validation message
      licenseInput.classList.add(classes.invalidInput);
      line.style.backgroundColor = "red";
    } else {
      line.style.backgroundColor = theme.palette.common.darkGray;
    }

    return isValid;
  }

  function nextStep() {
    let companyName: string = (
      document.querySelector("#register-company-name-input") as HTMLInputElement
    ).value;
    let companyLicenseNumber: string = (
      document.querySelector(
        "#register-company-license-input"
      ) as HTMLInputElement
    ).value;
    let companyPhone: string = (
      document.querySelector(
        "#register-company-phone-input"
      ) as HTMLInputElement
    ).value;
    if (
      validateCompanyName(companyName) &&
      validateLicenseNumber(companyLicenseNumber) &&
      validatePhone(companyPhone)
    ) {
      //remove any spaces
      if (props.onSubmitCompany)
        props.onSubmitCompany(
          [companyName, companyLicenseNumber, companyPhone],
          props.nextStepOption1
        );
    }
  }
  const onKeyDownCheck = (event: any) => {
    // Allow backspace (key code 8)
    if (event.keyCode === 8) {
      return;
    }
    const validInputPattern = /^[a-zA-Z0-9.]+$/;
    const inputValue = event.target.value + event.key;
    if (!validInputPattern.test(inputValue)) {
      event.preventDefault();
    }
  };

  return (
    <section className={classes.registerCompanycontainer}>
      <h2 className={classes.registerCompanyHeader}>Company Information</h2>

      <div className={classes.outerInputBox}>
        <div className={classes.registerInputBoxContainer}>
          <input
            onChange={(e) => {
              setNextToActive(e);
            }}
            onKeyDown={(e) => {
              setNextToActive(e);
            }}
            className={classes.registerCompanyInput}
            type="email"
            id="register-company-name-input"
            placeholder="Company name"
            defaultValue={props.defaultValue[0]}
          />
        </div>
        <div id="divider-line" className={classes.registerDividerLine}></div>
      </div>

      <div className={classes.outerInputBox}>
        <div className={classes.registerInputBoxContainer}>
          <input
            onChange={(e) => {
              setNextToActive(e);
            }}
            onKeyDown={(e) => {
              onKeyDownCheck(e);
            }}
            className={classes.registerCompanyInput}
            type="text"
            id="register-company-license-input"
            maxLength={12}
            placeholder="Company license number"
            defaultValue={props.defaultValue[1]}
          />
        </div>
        <div id="divider-line" className={classes.registerDividerLine}></div>
      </div>

      <div className={classes.outerInputBox}>
        <div className={classes.registerInputBoxContainer}>
          <input
            onChange={(e) => {
              setNextToActive(e);
            }}
            onKeyDown={(e) => {
              onKeyDownCheck(e);
            }}
            className={classes.registerCompanyInput}
            type="emai"
            id="register-company-phone-input"
            maxLength={10}
            placeholder="Company phone number"
            defaultValue={props.defaultValue[2]}
          />
        </div>
        <div id="divider-line" className={classes.registerDividerLine}></div>
      </div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          disabled={nextButtonDisabled}
          listener={nextStep}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterCompanyComponent" })((theme) => ({
  registerCompanycontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },

  registerCompanyHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
  },
  outerInputBox: {
    height: "45px",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "55px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerCompanyInput: {
    border: "none",
    outline: "none",
    width: "200px",
    paddingLeft: "5px",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: theme.palette.common.lightGray, // Replace this with your desired color
      fontWeight: "bold",
    },
    fontSize: "16px",
  },
  registerDividerLine: {
    height: "0.5px",
    width: "100%",
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: "100px",
    marginTop: "3px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  invalidInput: {
    "&::placeholder": {
      color: "red", // Replace this with your desired color
    },
  },
}));

export default RegisterCompanyComponent;
