import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AlertColor, MenuItem, Select, useTheme } from "@mui/material";
import ModalTransparentLayer from "../common/ModalTransparentLayer";
import { fadeIn, fadeOut, moveElement } from "../../utils/genericUtils";
import RoundedButton from "../common/boxlty.button";
import {
  addTeamMemberByEmailPhoneNumber,
  addTeamMemberWithProperty,
  getCouponDetails,
  getUserPropertyList,
} from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";

interface position {
  top: string;
  left: string;
  right: string;
  bottom: string;
}

interface ListingType {
  propertyId: string;
  nickName: string;
}

interface AddCouponModalProps {
  id?: string;
  isVisible: boolean;
  openPosition: position;
  closedPosition: position;
  setPanel: (value: boolean) => void;
  setSnackbar: (value: boolean) => void;
  setSnackbarMessage: (value: string) => void;
  setSnackbarType: (value: AlertColor) => void;
  refresh: boolean;
  onCouponApplied: (x: string, y: number, z: string) => void;
}

function AddCouponModal(props: AddCouponModalProps) {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(props.isVisible);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [coupon, setCoupon] = useState("");
  const { user } = useUserState();
  const addMemberTransparentModalId = "add-member-transparent-layer";
  const TransparentLayerTransitionTime = 0.3;
  const ModalTransitionTime = 0.5;
  useEffect(() => {
    if (!isInitialRender) toggle();
    setIsInitialRender(false);
    if (isInitialRender && props.isVisible == true) toggle(true);
  }, [props.isVisible, modalVisible]);

  useEffect(() => {
    if (coupon != "") {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [coupon]);

  // useEffect(() => {
  //   //console.log("t or f", saveButtonDisabled);
  // }, [saveButtonDisabled]);

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggle(mode?: boolean | void) {
    if (mode != null) {
      toggleModal(mode);
      toggleTransparentLayer(mode);
      setModalVisible(mode);
    } else {
      toggleModal();
      toggleTransparentLayer();
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleTransparentLayer(mode: boolean | void) {
    //get transparent layer by id
    let transparentLayer: HTMLElement = document.querySelector(
      "#" + addMemberTransparentModalId
    ) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      return;
    }
    //manual mode not passed
    if (modalVisible) {
      fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
    } else {
      fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleModal(mode: boolean | void) {
    let modal: HTMLElement = document.querySelector(
      "#" + props.id
    ) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      //slide element on page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.closedPosition,
        props.openPosition
      );
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      //slide element off page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.openPosition,
        props.closedPosition
      );
      return;
    }

    if (modalVisible) {
      //slide element off page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.openPosition,
        props.closedPosition
      );
    } else {
      //slide element on page
      moveElement(
        modal,
        ModalTransitionTime * 1000,
        props.closedPosition,
        props.openPosition
      );
    }
  }

  const useStyles = makeStyles({ name: "AddMemberModal" })((theme) => ({
    ModalComponentContainer: {
      overflow: "hidden",
      "@media (max-width: 600px)": {
        display: "none",
      },
    },
    AddMemberModal: {
      transition: `right ${ModalTransitionTime}s linear`,
      position: "absolute",
      zIndex: 25,
      backgroundColor: "white",
      // opacity : '0.7',
      borderRadius: "20px",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      bottom: props.closedPosition.bottom,
      right: props.closedPosition.right,
      padding: "20px",
      minHeight: "260px",
      minWidth: "300px",
      maxWidth: "400px",
    },
    modalHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: "10px",
    },
    modalSubHeader: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "flex-start",
      marginBottom: "10px",
    },
    headerText: {
      margin: "0",
      textAlign: "left",
      width: "98%",
      fontFamily: theme.fonts.secondary.bold,
    },
    subHeaderText: {
      margin: "0",
      textAlign: "left",
      width: "fitcontent",
      fontFamily: theme.fonts.secondary.bold,
    },
    exitX: {
      marginLeft: "auto",
      fontSize: "25px",
      cursor: "pointer",
      color: theme.palette.common.lightGray,
    },
    ModalFieldRow: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "space-evenly",
    },
    ModalInputBox: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "19px",
      marginRight: "10px",
    },
    ModalInput: {
      border: "none",
      outline: "none",
      width: "100%",
      color: theme.palette.common.darkGray,
      fontFamily: theme.fonts.secondary.med,
      fontWeight: "100",
      textTransform: "none",
      marginTop: "10px",
      fontSize: "14px",
    },
    InputSubLine: {
      width: "100%",
      height: "2px",
      maxHeight: "2px",
      minHeight: "1px",
      backgroundColor: theme.palette.common.darkGray,
    },
    HalfWidthInput: {
      width: "50%",
    },
    FullWidthInput: {
      width: "100%",
    },
    SeventyFiveWidthInput: {
      width: "75%",
    },
    TwentyFiveWidthInput: {
      width: "25%",
    },
    AssignLocksSubtext: {
      whiteSpace: "nowrap",
      fontSize: "13px",
      fontFamily: theme.fonts.primary.med,
      color: theme.palette.common.darkGray,
      margin: "0",
      marginLeft: "10px",
    },
    SaveSection: {
      marginTop: "100px",
      marginBottom: "10px",
    },
    ModalDescription: {
      marginRight: "70px",
      marginBottom: "25px",
    },
  }));

  let { classes } = useStyles();

  return (
    <>
      {/* Component Container */}
      <section className={classes.ModalComponentContainer}>
        {/* TransparentLayer */}
        <ModalTransparentLayer
          id={addMemberTransparentModalId}
          transitionTime={TransparentLayerTransitionTime}
        />

        {/* ModdalConatiner */}
        <section id={props.id} className={classes.AddMemberModal}>
          {/* header row */}
          <div className={classes.modalHeader}>
            <h2 className={classes.headerText}>Coupon Code</h2>
            <span
              className={classes.exitX}
              onClick={() => {
                toggle(false);
              }}
            >
              &times;
            </span>
          </div>
          <div className={classes.ModalDescription}>
            Enter a valid coupon code below to receive your special discount.
          </div>

          {/* Field Rows */}

          {/* Row 1 */}

          {/* Row 2 */}
          <div className={classes.ModalFieldRow}>
            <div
              className={[classes.ModalInputBox, classes.FullWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="string"
                placeholder="Coupon Code"
                onChange={async (e) => {
                  setCoupon(e.target.value);
                }}
              />
              <div className={classes.InputSubLine}></div>
            </div>

            {/* <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="phone"
                placeholder="Phone"
              />
              <div className={classes.InputSubLine}></div>
            </div> */}
          </div>

          {/* Save Section */}
          <section className={classes.SaveSection}>
            <RoundedButton
              color={"primary"}
              listener={async () => {
                const res = await getCouponDetails(coupon);
                if (res.data.status) {
                  //console.log(res);
                  props.setSnackbarMessage(`Coupon applied`);
                  props.setSnackbar(true);
                  props.setSnackbarType("success");
                  props.onCouponApplied(
                    res.data.result.discountType,
                    res.data.result.discount,
                    res.data.result.coupon
                  );
                  if (props.refresh) {
                    window.location.reload();
                  } else {
                    toggle(false);
                  }
                } else {
                  props.setSnackbarMessage(res.data.message);
                  props.setSnackbar(true);
                  props.setSnackbarType("error");
                  toggle(false);
                }
              }}
              showArrow={false}
              showShadow={false}
              text={"APPLY"}
              disabled={saveButtonDisabled}
            />
          </section>
        </section>
      </section>
    </>
  );
}

export default AddCouponModal;
