import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ListingsGeneralTab } from './primary-tabs/listings.general.tab'
import { ListingsTeamsTab } from './primary-tabs/listings.team.tab'
import { ListingsHardwareTab } from './primary-tabs/listings.hardware.tab'
import { ListingsAvailabilityTab } from './primary-tabs/listings.availability.tab'
import { ListingsPreApprovalTab } from './primary-tabs/listings.pre-approval.tab'
import { ListingsCreateTop } from './listings.create.top'
import { ListingsCreateMiddleContainer } from './listings.middle.container'
import { ListingsCreateRightContainer } from './listings.right.container'
import { PhotosTab } from './secondary-tabs/photos.tab'
import { BottomSubmitComponent } from './bottom.submit.component'
import { ListingCreateFormContextGeneral } from './listing.create.general.form.context'
import { ListingCreateFormContextTeam } from './listing.create.team.form.context'
import { ListingCreateFormContextAvilability } from './listing.create.availability.form.context'
import { ListingCreateFormContextHardware } from './listing.create.hardware.form.context'
import { ListingCreateFormContextApproval } from './listing.create.approval.form.context'
import { Grid } from '@mui/material'

type Props = {}

export const MiddleFormSwitch: React.FC<{}> = ({ }) => {
    const { CurrentMainTab } = useParams<{ CurrentMainTab: string }>()
    switch (CurrentMainTab) {
        case 'general':
            return (
                <ListingCreateFormContextGeneral>
                    <Grid item display={'flex'} sx={{ paddingLeft: '20px' }}>
                        <ListingsCreateTop />
                    </Grid>
                    <Grid item display={'flex'} flex={1} sx={{ paddingLeft: '20px' }}>
                        <Grid container direction={'row'} display='flex' gap={'10px'} >
                            <Grid item flex={1.5} >
                                {/* Middle container */}
                                <ListingsCreateMiddleContainer>
                                    <ListingsGeneralTab />
                                </ListingsCreateMiddleContainer>
                            </Grid>
                            <Grid item flex={1} >
                                {/* Right container */}
                                <ListingsCreateRightContainer>
                                    <PhotosTab />
                                </ListingsCreateRightContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BottomSubmitComponent active={true} />
                    </Grid>
                </ListingCreateFormContextGeneral>
            )
        case 'team':
            return (
                <ListingCreateFormContextTeam>
                    <Grid item display={'flex'} sx={{ paddingLeft: '20px' }}>
                        <ListingsCreateTop />
                    </Grid>
                    <Grid item display={'flex'} flex={1} sx={{ paddingLeft: '20px' }}>
                        <Grid container direction={'row'} display='flex' gap={'10px'} >
                            <Grid item flex={1.5} >
                                {/* Middle container */}
                                <ListingsCreateMiddleContainer>
                                    <ListingsTeamsTab />
                                </ListingsCreateMiddleContainer>
                            </Grid>
                            <Grid item flex={1} >
                                {/* Right container */}
                                <ListingsCreateRightContainer>
                                    <PhotosTab />
                                </ListingsCreateRightContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BottomSubmitComponent active={true} />
                    </Grid>
                </ListingCreateFormContextTeam>
            )
        case 'availability':
            return (
                <ListingCreateFormContextAvilability>
                    <Grid item display={'flex'} sx={{ paddingLeft: '20px' }}>
                        <ListingsCreateTop />
                    </Grid>
                    <Grid item display={'flex'} flex={1} sx={{ paddingLeft: '20px' }}>
                        <Grid container direction={'row'} display='flex' gap={'10px'} >
                            <Grid item flex={1.5} >
                                {/* Middle container */}
                                <ListingsCreateMiddleContainer>
                                    <ListingsAvailabilityTab />
                                </ListingsCreateMiddleContainer>
                            </Grid>
                            <Grid item flex={1} >
                                {/* Right container */}
                                <ListingsCreateRightContainer>
                                    <PhotosTab />
                                </ListingsCreateRightContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BottomSubmitComponent active={true} />
                    </Grid>
                </ListingCreateFormContextAvilability>
            )
        case 'hardware':
            return (
                <ListingCreateFormContextHardware>
                    <Grid item display={'flex'} sx={{ paddingLeft: '20px' }}>
                        <ListingsCreateTop />
                    </Grid>
                    <Grid item display={'flex'} flex={1} sx={{ paddingLeft: '20px' }}>
                        <Grid container direction={'row'} display='flex' gap={'10px'} >
                            <Grid item flex={1.5} >
                                {/* Middle container */}
                                <ListingsCreateMiddleContainer>
                                    <ListingsHardwareTab />
                                </ListingsCreateMiddleContainer>
                            </Grid>
                            <Grid item flex={1} >
                                {/* Right container */}
                                <ListingsCreateRightContainer>
                                    <PhotosTab />
                                </ListingsCreateRightContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BottomSubmitComponent active={true} />
                    </Grid>
                </ListingCreateFormContextHardware>
            )
        case 'pre-approval':
            return (
                <ListingCreateFormContextApproval>
                    <Grid item display={'flex'} sx={{ paddingLeft: '20px' }}>
                        <ListingsCreateTop />
                    </Grid>
                    <Grid item display={'flex'} flex={1} sx={{ paddingLeft: '20px' }}>
                        <Grid container direction={'row'} display='flex' gap={'10px'} >
                            <Grid item flex={1.5} >
                                {/* Middle container */}
                                <ListingsCreateMiddleContainer>
                                    <ListingsPreApprovalTab />
                                </ListingsCreateMiddleContainer>
                            </Grid>
                            <Grid item flex={1} >
                                {/* Right container */}
                                <ListingsCreateRightContainer>
                                    <PhotosTab />
                                </ListingsCreateRightContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <BottomSubmitComponent active={true} />
                    </Grid>
                </ListingCreateFormContextApproval>
            )
        default:
            return (
                <>
                    <div>404 not found</div>
                </>
            )
    }
}