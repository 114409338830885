import { ReactElement, useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import carrotSvg from "./../../styles/assets/Icons/carrot.svg";
// import { getNotifications } from '../../contexts/api/api.functions';
import {
  useUserDispatch,
  useUserState,
  UserActions,
} from "../../contexts/user/user.context";
import { fadeIn, fadeOut } from "../../utils/genericUtils";
import { cron } from "../../utils/genericUtils";
import AlertsPopupComponent from "./AlertsPopupComponent";
import AlertsSelfiePopupComponent from "./AlertsSelfiePopupComponent";
import AlertsLockPopupComponent from "./AlertsLockPopupComponent";
import { AlertResponse } from "../../contexts/api/api.types";
import AlertTextItem from "./AlertTextItem";
import { getTourDetails } from "../../contexts/api/api.functions";

const minHeight = "35px";
let maxHeight = "600px";
const transitionTime = "0.3s";

interface alertProps {
  alertCount: number;
  alertItems: AlertResponse[];
  setAlertCount: React.Dispatch<React.SetStateAction<number>>;
}

interface alert {
  id: string;
  profilePictureUrl: string;
  newProfilePictureUrl: string;
  firstName: string;
  lastName: string;
  date: Date;
  startTime: Date;
  endTime: Date;
}

export enum AlertTypes{
 OwnerRescheduleRequest = 6,
 VisitorRescheduleRequest = 27,
 LockErrorRequest = 32,
 SelfieImageRequest = 1
}

function AlertsComponent(props: alertProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [alerts, setAlerts] = useState<alert[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [selfieImageUrl, setSelfieImageUrl] = useState("");
  const [newSelfieImageUrl, setNewSelfieImageUrl] = useState("");
  const [propertyAccessId, setPropertyAccessId] = useState(0);
  const [popupElements, setPopupElements] = useState<ReactElement<any, string | React.JSXElementConstructor<any>>[]>([]);



  useEffect(() => {
    //maxHeight = 40 * props.alertCount + 70 + "px";
    formatPopupElements();

    //setup cron that periodically gets alerts every minute?
    let second = 1000;
    let minute = second * 60;
    cron(minute, () => {
      //get alerts here
    });
  }, [props.alertCount]);

  function toggleAlerts() {
    let expandTarget: HTMLElement | null = document.querySelector(
      "#alerts-expand-target"
    );
    let alertsCarrot: HTMLElement | null =
      document.querySelector("#alerts-arrow");
    let transparentLayer: HTMLElement | null = document.querySelector(
      "#alerts-transparent-layer"
    );
    let alertsPopupContainer: HTMLElement | null = document.querySelector(
      "#alerts-popup-container"
    );

    if (expandTarget) {
      if (expanded) {
        //fade out transparent layer
        fadeOut(
          transparentLayer,
          Number.parseFloat(transitionTime) * 1500,
          0.5
        );
        //fade out alerts popup
        fadeOut(
          alertsPopupContainer,
          Number.parseFloat(transitionTime) * 500,
          0
        );
        //set closed
        setExpanded(false);
        hideAllAlertPopups();
        //point arrow down
        expandTarget.style.maxHeight = minHeight;
        if (alertsCarrot) alertsCarrot.style.transform = "rotate(0deg)";
        //set color of shelf to transparent
        let shelf: HTMLElement = document.querySelector(
          "#alerts-shelf"
        ) as HTMLElement;
        setTimeout(() => {
          shelf.style.backgroundColor = "transparent";
        }, Number.parseFloat(transitionTime) + 500);
      } else {
        //fade out transparent layer
        fadeIn(
          transparentLayer,
          Number.parseFloat(transitionTime) * 1500,
          0.5,
          "flex"
        );
        //fade in alerts popup
        fadeIn(
          alertsPopupContainer,
          Number.parseFloat(transitionTime) * 500,
          1,
          "flex"
        );
        //set open
        setExpanded(true);
        //point arrow up
        expandTarget.style.maxHeight = maxHeight;
        if (alertsCarrot) alertsCarrot.style.transform = "rotate(180deg)";
        //set color of shelf to transparent
        let shelf: HTMLElement = document.querySelector(
          "#alerts-shelf"
        ) as HTMLElement;
        setTimeout(() => {
          shelf.style.backgroundColor = "white";
        }, 0);
      }
    }
  }

  function formatPopupElements() {
    let elements: ReactElement[] = [];
    if (props.alertItems.length === 0) {
    } else {
      let index: number = 0;
      props.alertItems.forEach(async (alert) => {
        switch (alert.notificationTypeId) {
          case AlertTypes.OwnerRescheduleRequest: //owner reschedule request
          var newSelfieRequestImage = await getNewSelfieRequestImage(alert.notificationBookingId.toString());
            elements.push(
              <AlertsPopupComponent
                id={`alert-${alert.notificationId}`}
                alertIndex={index}
                //profilePictureUrl={alert.pr}
                profilePictureUrl={newSelfieRequestImage}
                firstName={alert.notificationSenderFirstName}
                lastName={alert.notificationSenderLastName}
                date={new Date(alert.bookingDate)}
                startTime={alert.bookingFrom}
                endTime={alert.bookingTo}
                key={`alerts-${index}`}
              />
            );
            break;
          case AlertTypes.VisitorRescheduleRequest: //visitor reschedule request
          var newSelfieRequestImage = await getNewSelfieRequestImage(alert.notificationBookingId.toString());

            elements.push(
              <AlertsPopupComponent
                id={`alert-${alert.notificationId}`}
                alertIndex={index}
                //profilePictureUrl={alert.pr}
                profilePictureUrl={newSelfieRequestImage}
                firstName={alert.notificationSenderFirstName}
                lastName={alert.notificationSenderLastName}
                date={new Date(alert.bookingDate)}
                startTime={alert.bookingFrom}
                endTime={alert.bookingTo}
                key={`alerts-${index}`}
              />
            );
            break;
          case AlertTypes.LockErrorRequest: //lock error
            elements.push(
              <AlertsLockPopupComponent
                id={`alert-${alert.notificationId}`}
                alertIndex={index}
                //profilePictureUrl={alert.pr}
                lockName={"lock name placeholder"}
                key={`alerts-${index}`}
              />
            );
            break;
          case AlertTypes.SelfieImageRequest: //selfie image review request
            var oldSelfieRequestImage = await getOldSelfieRequestImage(alert.notificationBookingId.toString());
            var newSelfieRequestImage = await getNewSelfieRequestImage(alert.notificationBookingId.toString());
            var newPropertyAcessId = await getNewSelfieRequestImageId(alert.notificationBookingId.toString());
            elements.push(
              <AlertsSelfiePopupComponent
                id={`alert-${alert.notificationId}`}
                alertIndex={index}
                profilePictureUrl={`https://boxlty-media.s3.amazonaws.com/user-profile/${oldSelfieRequestImage}`}
                newProfilePictureUrl={`https://boxlty-media.s3.amazonaws.com/user-profile/${newSelfieRequestImage}`}
                firstName={alert.notificationSenderFirstName}
                lastName={alert.notificationSenderLastName}
                bookingId={Number(alert.notificationBookingId)}
                propertyAccessId={newPropertyAcessId}
                key={`alerts-${index}`}
              />
            );

            break;
        }
        index++;
      });
    }
    setPopupElements(elements);
  }

  async function getOldSelfieRequestImage(notificationBookingId :string){

    var res = await getTourDetails(notificationBookingId);

    if (res && res.data && res.data.status === true) {
      if(res.data.result.pendingRequests.length > 0)
      {
        return res.data.result.pendingRequests[0].userProfile ?? "";
      }
    }

    return "";
  }
  
  async function getNewSelfieRequestImage(notificationBookingId: string){

    var res = await getTourDetails(notificationBookingId);
    
    if (res && res.data && res.data.status === true) {

      if(res.data.result.pendingRequests.length > 0)
      {
        return res.data.result.pendingRequests[0].userSelfie ?? "";
      }
    }

    return "";
  }

  async function getNewSelfieRequestImageId(notificationBookingId: string){

    var res = await getTourDetails(notificationBookingId);
    
    if (res && res.data && res.data.status === true) {

      if(res.data.result.pendingRequests.length > 0)
      {
        return res.data.result.pendingRequests[0].id;
      }
    }

    return "";
  }

  function hideAllAlertPopups() {
    alerts.forEach((alert) => {
      let id = "#" + alert.id;
      let alertPopup = document.querySelector(id) as HTMLElement;
      if (alertPopup) alertPopup.style.display = "none";
    });
  }

  return (
    <>
      {/* Alerts container */}
      <section
        id="alerts-expand-target"
        className={classes.ExpandableContainer}
      >
        <section
          className={classes.AlertsComponentContainer}
          onClick={toggleAlerts}
        >
          {/* Alerts Count */}
          <div className={classes.AlertcountCircle}>{props.alertCount}</div>
          {/* Alerts Text */}
          <p className={classes.AlertsText}>ALERTS</p>
          <img
            id="alerts-arrow"
            className={classes.Carrot}
            src={carrotSvg}
            alt="down arrow click to expand"
          />
        </section>

        {/* Expandable shelf */}
        <section
          id="alerts-shelf"
          className={classes.ExpandShelf}
          data-expand-shelf
        >
          {props.alertItems.map((item, index) => (
            <>
              <AlertTextItem
                alertItem={item}
                key={index}
                setAlertCount={props.setAlertCount}
                alertCount={props.alertCount}
                alerts={props.alertItems}
              />
            </>
          ))}
        </section>
      </section>

      {/* transparent black section illusion of layer in between */}
      <section
        id="alerts-transparent-layer"
        className={classes.TransparentLayer}
      ></section>
      {/* Floating Alerts Components Go here if expanded */}
      <section id="alerts-popup-container">{popupElements}</section>
    </>
  );
}

const alertRed = "#EC394B";

const useStyles = makeStyles({ name: "AlertsComponent" })((theme) => ({
  TransparentLayer: {
    display: "none",
    backgroundColor: "rgba(0,0,0,0.8)",
    position: "fixed",
    opacity: "0.5",
    top: 0,
    left: 0,
    zIndex: "20",
    minHeight: "100vh",
    minWidth: "100vw",
    transition: `opacity ${transitionTime} linear`,
  },
  AlertPopupsContainer: {
    display: "none",
    transition: `opacity ${transitionTime} linear`,
    opacity: 0,
  },
  ExpandableContainer: {
    maxHeight: minHeight,
    transition: `all ${transitionTime} linear`,
    overflow: "hidden",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    position: "absolute",
    right: "50px",
    zIndex: "25",
    
  },
  ExpandShelf: {
    backgroundColor: "transparent",
    borderRadius: "15px",
    width: "170px",
    maxHeight: "250px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    //border: '0.1px solid lightgray',
    position: "relative",
    zIndex: "1",
    bottom: "30px",
    paddingTop: "35px",
    paddingBottom: "5px",
  },
  AlertsComponentContainer: {
    padding: "10px",
    width: "150px",
    height: "15px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: alertRed,
    position: "relative",
    zIndex: "2",
    cursor: "pointer",
  },
  AlertcountCircle: {
    backgroundColor: "white",
    padding: "5px",
    color: alertRed,
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "100%",
    height: "10px",
    width: "10px",
    boxShadow: "0px 0px 5px -1px black",
  },
  AlertsText: {
    color: "white",
    fontWeight: "bold",
    fontSize: "13px",
    fontFamily: theme.fonts.secondary.bold,
    letterSpacing: "1px",
  },
  Carrot: {
    color: "white",
    height: "10px",
    width: "10px",
    cursor: "pointer",
    transition: `all ${transitionTime} linear`,
  },
}));

export default AlertsComponent;
