import React from "react";
import AppBody from "../../../components/body/main.body.component.jsx";
import {
   Alert,
   Avatar,
   Button,
   CircularProgress,
   Grid,
   IconButton,
   Menu,
   MenuItem,
   Radio,
   Snackbar,
   useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import RoundedButton from "../../../components/common/boxlty.button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
   userInviteSaasFlow,
   getPropertyMembers,
   getTeamMemberDetails,
   getTeamMembers,
   removeMemberFromTeam,
   removeTeamMemberInvite,
   removeTeamMemberFromListing,
} from "../../../contexts/api/api.functions";
import { TeamMemberDetails } from "./teams.types.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
   MemberAssignedProperty,
   SassFlowUserInvite,
} from "../../../contexts/api/api.types.js";
import { useUserState } from "../../../contexts/user/user.context";
import { MoreHoriz } from "@mui/icons-material";
import { useGradient } from "../../../contexts/gradients/gradients.context";

function TeamDetailsPage() {
   const { classes } = useStyles();
   const theme = useTheme();
   const { primaryButtonsGradient } = useGradient();
   const [loadingUser, setLoadingUser] = useState(true);
   const { UserId } = useParams<{ UserId: string }>();
   const [userDetail, setUserDetail] = useState<TeamMemberDetails | null>(null);
   const [showActionsPopup, setShowActionsPopup] = useState(false);
   const [showDeletePopup, setShowDeletePopup] = useState(false);
   const [showCancelPopup, setShowCancelPopup] = useState(false);
   const [showResetPopup, setShowResetPopup] = useState(false);
   const [showSnackbar, setShowSnackBar] = useState(false);
   const [snackBarText, setSnackBarText] = useState("");
   const [snackBarSuccess, setSnackBarSuccess] = useState<boolean>(true);
   const [selectedListing, setSelectedListing] = useState<null | number>(null);
   const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const actionsOpen = Boolean(actionsAnchorEl);
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const exportOpen = Boolean(exportAnchorEl);
   const [showAddMemberPopup, setShowAddMemberPopup] = useState(false);
   const [showRemoveMemberPopup, setShowRemoveMemberPopup] = useState(false);
   const [
      showRemoveAndReassignMemberPopup,
      setShowRemoveAndReassignMemberPopup,
   ] = useState(false);
   const [teamMembers, setTeamMembers] = useState<TeamMemberDetails[]>([]);
   const [addToListingMembers, setAddToListingMembers] = useState<
      TeamMemberDetails[]
   >([]);
   const [reassignListingMembers, setReassignListingMembers] = useState<
      TeamMemberDetails[]
   >([]);
   const { user } = useUserState();

   const fetchUserDetails = async () => {
      if (UserId) {
         const res = await getTeamMemberDetails(UserId);
         console.log(res);
         if (res && res.data && res.data.status === true) {
            setUserDetail(res.data.result);
            console.log(res.data.result);
            const res2 = await getTeamMembers();
            if (res2 && res2.data.status === true) {
               setTeamMembers(res2.data.result.members);
            }
            setLoadingUser(false);
         }
      }
   };

   useEffect(() => {
      fetchUserDetails();
   }, [UserId]);

   // useEffect(() => {
   //   //console.log("set user details", userDetail);
   // }, [userDetail]);

   useEffect(() => {
      if (showActionsPopup) {
         const actionsContainer = document.querySelector("#actions-container");
         actionsContainer?.addEventListener("mouseleave", handlePopup);
      }
   }, [showActionsPopup]);

   // useEffect(() => {}, [showSnackbar, snackBarText]);

   // useEffect(() => {
   //   //console.log("new selected state ====>", selectedListing);
   // }, [selectedListing]);

   // useEffect(() => {
   // }, [actionsAnchorEl]);

   useEffect(() => {
      const selectDropdown = document.getElementById("add-members-dropdown");

      addToListingMembers.forEach((item) => {
         const option = document.createElement("option");
         option.value = item.email; // Use userId as the value
         option.text = item.userName; // Display the name in the dropdown
         selectDropdown?.appendChild(option);
      });
   }, [addToListingMembers]);

   useEffect(() => {
      const selectDropdown = document.getElementById(
         "reassign-members-dropdown"
      );

      reassignListingMembers.forEach((item) => {
         const option = document.createElement("option");
         option.value = item.email; // Use userId as the value
         option.text = item.userName; // Display the name in the dropdown
         selectDropdown?.appendChild(option);
      });
   }, [reassignListingMembers]);

   function formatDateString(dateString: string) {
      const date = new Date(dateString);
      const formattedDate = `${
         date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
   }

   const handleDeleteMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      setExportAnchorEl(event.currentTarget);
   };

   const handleDeleteMenuClose = () => {
      setExportAnchorEl(null);
   };

   function handlePopup() {
      //console.log("popup function before", showActionsPopup);
      setShowActionsPopup(!showActionsPopup);

      ////console.log("popup function after", showActionsPopup);
   }

   const closeDeletePopup = () => {
      setShowDeletePopup(false);
   };

   const handleOpenDeletePopup = () => {
      setShowDeletePopup(true);
   };

   const handleDelete = async () => {
      //console.log("removing team member");
      const response = await removeMemberFromTeam({
         input: userDetail?.email.toString(),
      });
      if (response && response.data.status === true) {
         // Password update was successful
         setShowDeletePopup(false);
         setShowSnackBar(true);
         setSnackBarSuccess(true);
         setSnackBarText(response.data.message);
      } else {
         // Password update failed, handle the error
         console.error("Removing team member failed: ", response.data);
         setShowSnackBar(true);
         setSnackBarSuccess(false);
         setSnackBarText(
            "Removing team member failed: " + response.data.message
         );
      }
   };

   const closeCancelPopup = () => {
      setShowCancelPopup(false);
   };

   const handleCancel = async () => {
      //console.log("canceling team member invite ");
      const response = await removeTeamMemberInvite({
         input: userDetail?.email.toString(),
      });
      if (response && response.data.status === true) {
         // Password update was successful
         // //console.log("Password updated successfully");
         setShowCancelPopup(false);
         setShowSnackBar(true);
         setSnackBarSuccess(true);
         setSnackBarText(response.data.message);
      } else {
         // Password update failed, handle the error
         console.error("Cancel team member invite failed: ", response.data);
         setShowSnackBar(true);
         setSnackBarSuccess(false);
         setSnackBarText(
            "Cancel team member invite failed: " + response.data.message
         );
      }
   };

   const closeResetPopup = () => {
      setShowResetPopup(false);
   };

   const handleReset = () => {};

   const handleCheckboxChange = (propertyId: number) => {
      //console.log("checkbox hit ====>", propertyId);
      if (propertyId === selectedListing) {
         setSelectedListing(null);
      } else {
         setSelectedListing(propertyId);
      }
   };

   const handleMenuClick: React.MouseEventHandler<HTMLButtonElement> = (
      event
   ) => {
      setActionsAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setActionsAnchorEl(null);
   };

   const handleAddClick = async () => {
      var assignedMembers: any[] = [];
      const res1 = await getPropertyMembers(selectedListing?.toString() ?? "");
      if (res1.data.status !== false) {
         assignedMembers = res1.data.result.members;
      }
      var availableMembers = teamMembers.filter(
         (item2: { userId: any }) =>
            !assignedMembers.some((item1) => item1.memberId === item2.userId)
      );
      availableMembers = availableMembers.filter(
         (item) => item.userId.toString() !== UserId
      );
      //console.log("available members ===>", availableMembers);
      setAddToListingMembers(availableMembers);
      setShowAddMemberPopup(true);
      handleClose();
   };

   const addMemberToListing = async () => {
      const addMemberDropdown = document.getElementById(
         "add-members-dropdown"
      ) as HTMLSelectElement;
      const selectedMember = addMemberDropdown.value;
      //console.log("member from selection", selectedMember);
      if (selectedMember === "") {
         alert("Please select a team member.");
      } else {
         let invites = [selectedMember];
         let property = selectedListing?.toString() ?? "";
         const data: SassFlowUserInvite = {
            property,
            invites,
            assign: [],
         };
         ////console.log(data);
         const res = await userInviteSaasFlow(data);
         //console.log(res);
         closePopup();
         if (res && res.data.status === true) {
            setShowSnackBar(true);
            setSnackBarSuccess(true);
            setSnackBarText(res.data.message);
         } else {
            setShowSnackBar(true);
            setSnackBarSuccess(false);
            setSnackBarText(
               "Error ocurred while trying to add member to listing. " +
                  (res ? res.data.message : "")
            );
         }
      }
   };

   const removeMemberFromListing = async () => {
      let pId = selectedListing?.toString() ?? "";
      let teamMemberId = UserId ?? "";
      const data: MemberAssignedProperty = {
         pId,
         teamMemberId,
      };
      const res = await removeTeamMemberFromListing(data);
      //console.log(res);
      closePopup();
      if (res && res.data.success === true) {
         setShowSnackBar(true);
         setSnackBarSuccess(true);
         setSnackBarText("Successfully removed member from listing");
      } else {
         setShowSnackBar(true);
         setSnackBarSuccess(false);
         setSnackBarText(
            "Error ocurred while trying to remove member from listing"
         );
      }
   };

   const removeFromListingAndReassign = async () => {
      let pId = selectedListing?.toString() ?? "";
      let teamMemberId = UserId ?? "";
      const dataRemove: MemberAssignedProperty = {
         pId,
         teamMemberId,
      };
      await removeTeamMemberFromListing(dataRemove);
      //console.log(res);

      const addMemberDropdown = document.getElementById(
         "reassign-members-dropdown"
      ) as HTMLSelectElement;
      const selectedMember = addMemberDropdown.value;
      //console.log(selectedMember);
      if (selectedMember === "") {
         alert("Please select a team member.");
      } else {
         let invites = [selectedMember];
         let property = selectedListing?.toString() ?? "";
         const dataAssign: SassFlowUserInvite = {
            property,
            invites,
            assign: [],
         };
         ////console.log(data);
         const res2 = await userInviteSaasFlow(dataAssign);
         //console.log(res2);
         closePopup();
         if (res2 && res2.data.status === true) {
            setShowSnackBar(true);
            setSnackBarSuccess(true);
            setSnackBarText(
               "Successfully removed member and reassigned listing"
            );
         } else {
            setShowSnackBar(true);
            setSnackBarSuccess(false);
            setSnackBarText(
               "Error ocurred while trying to remove and reassign listing. " +
                  (res2 ? res2.data.message : "")
            );
         }
      }
   };

   const closePopup = () => {
      setShowAddMemberPopup(false);
      setShowRemoveMemberPopup(false);
      setShowRemoveAndReassignMemberPopup(false);
   };

   const handleRemoveClick = async () => {
      //console.log("remove clicked ===> listing selected ===>", selectedListing);
      const res = await getPropertyMembers(selectedListing?.toString() ?? "");
      if (res.data.status === false || res.data.result.members <= 1) {
         var assignedMembers: any[] = [];
         if (res.data.status !== false) {
            assignedMembers = res.data.result.members;
         }
         // console.log(
         //   "no other members assigned, must reassign listing to new member"
         // );
         var availableMembers = teamMembers.filter(
            (item2: { userId: any }) =>
               !assignedMembers.some((item1) => item1.memberId === item2.userId)
         );
         availableMembers = availableMembers.filter(
            (item) => item.userId.toString() !== UserId
         );
         setReassignListingMembers(availableMembers);
         setShowRemoveAndReassignMemberPopup(true);
      } else {
         setShowRemoveMemberPopup(true);
      }
      handleClose();
   };

   return (
      <>
         <AppBody>
            <div className={classes.PageContainer}>
               {/* Header */}
               <h1 className={classes.PageHeader}>
                  {/* {props.listingName ? props.listingName : "Family Home"} */}
                  {loadingUser
                     ? ""
                     : userDetail?.userName
                     ? userDetail?.userName
                     : ""}
               </h1>
               <Snackbar
                  open={showSnackbar}
                  autoHideDuration={4000}
                  sx={{
                     zIndex: (theme) => theme.zIndex.drawer + 2,
                     position: "absolute",
                  }}
                  onClose={() => {
                     setShowSnackBar(false);
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
               >
                  <Alert
                     onClose={() => {
                        setShowSnackBar(false);
                     }}
                     severity={snackBarSuccess ? "success" : "error"}
                     color={snackBarSuccess ? "success" : "error"}
                  >
                     {snackBarText}
                  </Alert>
               </Snackbar>

               {/* Split Container */}
               {loadingUser ? (
                  <CircularProgress />
               ) : (
                  <section className={classes.SplitContainer}>
                     {/* Main Section */}
                     <section className={classes.MainSection}>
                        {userDetail?.userId !== user.userId &&
                           user.isPrimaryOwner === 1 &&
                           userDetail?.isSuperAdmin === "0" && (
                              <section className={classes.ThreeDots}>
                                 <IconButton
                                    edge="start"
                                    aria-haspopup="true"
                                    id="lock-delete"
                                    aria-expanded={
                                       exportOpen ? "true" : undefined
                                    }
                                    aria-controls={
                                       exportOpen
                                          ? "lock-delete-menu"
                                          : undefined
                                    }
                                    onClick={handleDeleteMenuClick}
                                 >
                                    <MoreHoriz fontSize="large" />
                                 </IconButton>
                              </section>
                           )}
                        {/* Top Row */}
                        {/* Visitor Information Section */}

                        <section className={classes.VisitorSectionContainer}>
                           {/* Visitor Info */}
                           <section className={classes.VisitorInfoSection}>
                              {/* Visitor IMG */}
                              <div className={classes.ProfileImageContainer}>
                                 {userDetail?.userProfile !== undefined &&
                                 userDetail.userProfile !== "" &&
                                 userDetail.status !== "1" ? (
                                    userDetail.userProfile !== null ? (
                                       <img
                                          className={classes.ProfileImage}
                                          src={`https://boxlty-media.s3.amazonaws.com/user-profile/${userDetail.userProfile}`}
                                          style={{ borderRadius: "15px" }}
                                          alt="Avatar"
                                       />
                                    ) : (
                                       <Avatar
                                          className={classes.ProfileImage}
                                          style={{ borderRadius: "15px" }}
                                          alt="Avatar"
                                       />
                                    )
                                 ) : (
                                    <div
                                       style={{
                                          backgroundColor: "#707070",
                                          borderRadius: "15px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "white",
                                          fontWeight: "bold",
                                       }}
                                       className={classes.ProfileImage}
                                    >
                                       INVITATION SENT
                                    </div>
                                 )}
                              </div>
                              {/* Tour Info */}
                              <section className={classes.TeamMemberInfo1}>
                                 {[1, 2, 3, 4].includes(
                                    userDetail?.roleId!
                                 ) && (
                                    <p className={classes.VisitorInfoText1}>
                                       {userDetail?.roleId === 1 && "TENANT"}
                                       {userDetail?.roleId === 2 &&
                                       userDetail?.isSuperAdmin === "1"
                                          ? "SUPER ADMIN"
                                          : userDetail?.isPrimaryManager === "1"
                                          ? "PRIMARY MANAGER"
                                          : "MANAGER"}
                                       {userDetail?.roleId === 3 && "ADMIN"}
                                       {userDetail?.roleId === 4 &&
                                          "SUPER ADMIN"}
                                    </p>
                                 )}
                                 {/* Email */}
                                 <p className={classes.VisitorInfoFieldLabel}>
                                    EMAIL
                                 </p>
                                 <p className={classes.VisitorInfoText2}>
                                    {userDetail?.email ? userDetail?.email : ""}
                                 </p>
                                 {/* Phone */}
                                 <p className={classes.VisitorInfoFieldLabel}>
                                    PHONE
                                 </p>
                                 <p className={classes.VisitorInfoText3}>
                                    {userDetail?.mobile
                                       ? userDetail?.mobile
                                       : ""}
                                 </p>
                              </section>

                              <Menu
                                 className={classes.exportMenu}
                                 open={exportOpen}
                                 id="lock-delete-menu"
                                 anchorEl={exportAnchorEl}
                                 onClose={handleDeleteMenuClose}
                                 MenuListProps={{
                                    "aria-labelledby": "lock-delete",
                                 }}
                                 anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                 }}
                                 transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                 }}
                              >
                                 {userDetail?.status === "0" ? (
                                    <MenuItem onClick={handleOpenDeletePopup}>
                                       Delete
                                    </MenuItem>
                                 ) : (
                                    <MenuItem
                                       onClick={() => setShowCancelPopup(true)}
                                    >
                                       Cancel
                                    </MenuItem>
                                 )}
                              </Menu>

                              {showDeletePopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          DELETE TEAM MEMBER
                                       </p>
                                       <p className={classes.PopupText}>
                                          Delete {userDetail?.userName}?
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="CANCEL"
                                             listener={() => {
                                                closeDeletePopup();
                                                handleDeleteMenuClose();
                                             }}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="DELETE"
                                             listener={() => {
                                                handleDelete();
                                                handleDeleteMenuClose();
                                             }}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                              {showCancelPopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          CANCEL INVITE
                                       </p>
                                       <p className={classes.PopupText}>
                                          Cancel {userDetail?.userName}'s
                                          Invitation?
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="NO"
                                             listener={() => {
                                                closeCancelPopup();
                                                handleDeleteMenuClose();
                                             }}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="YES"
                                             listener={() => {
                                                handleCancel();
                                                handleDeleteMenuClose();
                                             }}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                              {showResetPopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          RESET PASSWORD
                                       </p>
                                       <p className={classes.PopupText}>
                                          Reset {userDetail?.userName}'s
                                          password?
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="CANCEL"
                                             listener={closeResetPopup}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="RESET"
                                             listener={handleReset}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                              {showAddMemberPopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          Add Team Member
                                       </p>
                                       <p className={classes.PopupText}>
                                          Choose Team Member
                                       </p>
                                       <select
                                          className={classes.AddMemberDropdown}
                                          id="add-members-dropdown"
                                       ></select>
                                       <div
                                          className={
                                             classes.PopupButtonsSection
                                          }
                                       >
                                          <div className={classes.PopupButtons}>
                                             <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                   closePopup();
                                                }}
                                                sx={{
                                                   borderRadius: "26px",
                                                   backgroundImage: `${primaryButtonsGradient}`,
                                                }}
                                             >
                                                CANCEL
                                             </Button>
                                          </div>
                                          <div className={classes.PopupButtons}>
                                             <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                   addMemberToListing();
                                                }}
                                                classes={{
                                                   endIcon: classes.startIcon,
                                                }}
                                                endIcon={<AddIcon />}
                                                sx={{
                                                   borderRadius: "26px",
                                                   backgroundImage: `${primaryButtonsGradient}`,
                                                }}
                                             >
                                                ADD
                                             </Button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              )}
                              {showRemoveMemberPopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          REMOVE
                                       </p>
                                       <p className={classes.PopupText}>
                                          Remove {userDetail?.userName} from
                                          selected listings?
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="CANCEL"
                                             listener={closePopup}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="REMOVE"
                                             listener={removeMemberFromListing}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                              {showRemoveAndReassignMemberPopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          REMOVE AND REASSIGN
                                       </p>
                                       <p className={classes.PopupText}>
                                          To remove the Team Member from the
                                          selected listings, another Team Member
                                          must be assigned.
                                       </p>
                                       <select
                                          className={classes.AddMemberDropdown}
                                          id="reassign-members-dropdown"
                                       ></select>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="CANCEL"
                                             listener={closePopup}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="REMOVE and Reassign"
                                             listener={
                                                removeFromListingAndReassign
                                             }
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </section>

                           {/* Scheduled section */}
                           <section className={classes.TeamMemberInfo1}>
                              {/* Email */}
                              {userDetail?.isPrimaryManager !== "1" && (
                                 <div>
                                    <p
                                       className={classes.VisitorInfoFieldLabel}
                                    >
                                       ADDED BY
                                    </p>
                                    <p className={classes.VisitorInfoText2}>
                                       {userDetail?.inviteeFirstName
                                          ? userDetail?.inviteeFirstName
                                          : ""}{" "}
                                       {userDetail?.inviteeLastName
                                          ? userDetail.inviteeLastName
                                          : ""}
                                    </p>
                                 </div>
                              )}
                              {/* Phone */}
                              <p className={classes.VisitorInfoFieldLabel}>
                                 COMPANY
                              </p>
                              <p className={classes.VisitorInfoText3}>
                                 {userDetail?.company
                                    ? userDetail?.company
                                    : ""}
                              </p>
                              {userDetail?.isPrimaryManager !== "1" && (
                                 <div>
                                    <div className={classes.MiniDivider}></div>
                                    <p className={classes.ScheduledText}>
                                       Added on{" "}
                                       {formatDateString(
                                          userDetail?.invitedDate ?? ""
                                       )}
                                    </p>
                                 </div>
                              )}
                           </section>
                        </section>
                     </section>

                     <section className={classes.SecondarySection}>
                        {/* Component to house those two sections */}

                        <div className={classes.TourDetailsOuterContainer}>
                           <div className={classes.TourDetailsHeaderRow}>
                              <h1 className={classes.TourDetailsInfoHeader}>
                                 Listings
                              </h1>
                           </div>
                           <Grid
                              container
                              direction="row"
                              display={"flex"}
                              justifyContent={"right"}
                              className={classes.actionContainer}
                           >
                              <Grid item>
                                 {user?.isPrimaryOwner === 1 && (
                                    <div
                                       style={{
                                          fontSize: "17px",
                                          fontFamily: theme.fonts.secondary.med,
                                          color: theme.palette.common.medGray,
                                          display: "flex",
                                          marginTop: "-35px",
                                       }}
                                    >
                                       Actions
                                       <IconButton
                                          disabled={selectedListing == null}
                                          aria-haspopup="true"
                                          id="action"
                                          aria-expanded={
                                             actionsOpen ? "true" : undefined
                                          }
                                          aria-controls={
                                             actionsOpen
                                                ? "action-menu"
                                                : undefined
                                          }
                                          onClick={handleMenuClick}
                                          style={{ marginTop: "-8px" }}
                                       >
                                          <KeyboardArrowDownIcon fontSize="medium" />
                                       </IconButton>
                                    </div>
                                 )}
                              </Grid>
                              <Grid item>
                                 <Menu
                                    className={classes.exportMenu}
                                    open={actionsOpen}
                                    id="action-menu"
                                    anchorEl={actionsAnchorEl}
                                    onClose={handleClose}
                                    MenuListProps={{
                                       "aria-labelledby": "action",
                                    }}
                                    anchorOrigin={{
                                       vertical: "bottom",
                                       horizontal: "left",
                                    }}
                                    transformOrigin={{
                                       vertical: "top",
                                       horizontal: "center",
                                    }}
                                 >
                                    <MenuItem
                                       className={classes.exportMenuItem}
                                       onClick={handleAddClick}
                                    >
                                       Add
                                    </MenuItem>
                                    <MenuItem
                                       className={classes.exportMenuItem}
                                       onClick={handleRemoveClick}
                                    >
                                       Remove
                                    </MenuItem>
                                 </Menu>
                              </Grid>
                           </Grid>

                           <section className={classes.ListingsContainer}>
                              {userDetail?.propertyDetail.map(
                                 (listing, index) => (
                                    <>
                                       <div
                                          key={listing.propertyId}
                                          className={classes.ListingItem}
                                       >
                                          <Radio
                                             checked={
                                                listing.propertyId ===
                                                selectedListing
                                             }
                                             onChange={() =>
                                                handleCheckboxChange(
                                                   listing.propertyId
                                                )
                                             }
                                             value={listing.propertyId.toString()}
                                             name="property-radio-group" // This should be a unique name for the radio group
                                             color="primary" // You can use a different color if needed
                                          />
                                          <div>
                                             <Avatar
                                                className={classes.imgPreview}
                                                variant="rounded"
                                                src={`https://boxlty-media.s3.amazonaws.com/property-images/${listing.propertyImage}`}
                                                sx={{
                                                   width: "75px !important",
                                                   height: "75px !important",
                                                }}
                                             />
                                          </div>
                                          <div
                                             className={classes.ListingDetails}
                                          >
                                             <p
                                                className={
                                                   classes.ListingItemName
                                                }
                                             >
                                                {listing.propertyNickname}
                                             </p>
                                             <p
                                                className={
                                                   classes.ListingItemAddress
                                                }
                                             >
                                                {listing.propertyAddress}
                                             </p>
                                          </div>
                                       </div>
                                       <div
                                          className={
                                             classes.ListingSectionDividerLine
                                          }
                                       ></div>
                                    </>
                                 )
                              )}
                           </section>
                        </div>
                     </section>

                     {/* Chat / Listing Details Section */}
                  </section>
               )}
            </div>
         </AppBody>
      </>
   );
}

const useStyles = makeStyles({ name: "TeamDetails" })((theme) => ({
   PageContainer: {
      display: "flex",
      flexDirection: "column",
   },
   PageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginTop: "0px",
      marginBottom: "5px",
      height: "41.5px",
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
   },
   MainSection: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "70%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   MainSectionTopRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "45px",
   },
   MainInfoSection: {
      display: "flex",
      alignItems: "flex-start",
   },
   TourImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   TourInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "5px",
      fontSize: "17px",
      fontFamily: theme.fonts.secondary.bold,
   },
   TourInfoText2: {
      margin: "0",
      fontWeight: "500",
      fontSize: "14px",
      marginBottom: "5px",
   },
   TourInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorSectionContainer: {
      marginTop: "25px",
   },
   VisitorSectionText: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "22px",
      marginBottom: "5px",
   },
   VisitorSectionDividerLine: {
      width: "250px",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
   },
   VisitorInfoSection: {
      marginTop: "20px",
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "20px",
   },
   VisitorImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   VisitorInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "15px",
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "17px",
      marginTop: "15px",
   },
   VisitorInfoText2: {
      margin: "0",
      fontWeight: "500",
      marginBottom: "15px",
      fontSize: "17px",
   },
   VisitorInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorInfoFieldLabel: {
      fontSize: "14px",
      color: theme.palette.common.lightGray,
      margin: "0",
      marginTop: "10px",
      marginBottom: "3px",
   },
   ThreeDots: {
      cursor: "pointer",
      float: "right",
   },
   PreApprovalGayText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "5px",
      fontSize: "12px",
   },
   PreApprovalLink: {
      display: "block",
      color: theme.palette.common.primary,
      fontFamily: theme.fonts.secondary.med,
      fontSize: "13px",
      textDecoration: "underline",
      marginBottom: "50px",
   },
   MiniDivider: {
      height: "2px",
      minHeight: "2px",
      // boxShadow : `0px 0px 10px 2px ${theme.palette.common.lightGray}`,
      backgroundColor: theme.palette.common.lightGray,
      width: "35px",
      marginBottom: "15px",
      marginTop: "50px",
   },
   ScheduledText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "1px",
      fontSize: "13px",
   },
   SecondarySection: {
      width: "25%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "20px",
      backgroundColor: "white",
      borderRadius: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   ProfileImage: {
      height: "150px",
      width: "150px",
      objectFit: "cover",
   },
   ProfileImageContainer: {
      marginBottom: "5px",
      height: "150px",
      width: "150px",
      overflow: "hidden",
      marginRight: "10px",
   },
   startIcon: {
      paddingBottom: "2px",
      justifySelf: "center",
   },
   TourDetailsHeaderRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      justifyContent: "space-between",
   },
   TourDetailsInfoHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "23px",
      whiteSpace: "nowrap",
      margin: "0px",
      marginRight: "15px",
   },
   TourDetailsOpenIcon: {
      height: "13px",
      width: "13px",
      cursor: "pointer",
   },
   TourDetailsOuterContainer: {
      paddingTop: "20px",
      paddingLeft: "10px",
      paddingRight: "10px",
      minHeight: "80vh",
      maxHeight: "80vh",
      overflow: "auto",
   },
   ListingSectionDividerLine: {
      width: "100%",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
   },
   ActionsContainer: {
      marginLeft: "auto",
      fontSize: "30px",
      fontWeight: "bold",
      color: theme.palette.common.medGray,
      position: "relative",
      display: "inline-block",
      paddingBottom: "100px",
      paddingLeft: "125px",
   },
   ActionsButton: {
      cursor: "pointer",
      marginTop: "-25px",
   },
   ActionsPopup: {
      //display: "none",
      position: "absolute",
      backgroundColor: "white",
      border: "1px solid #ccc",
      borderRadius: "20px",
      boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
      minWidth: "125px",
      zIndex: 1,
      right: 0,
      top: "10px",
   },
   TeamMemberInfo1: {
      marginLeft: "10px",
   },
   ActionsText: {
      fontSize: "14px",
      textAlign: "left",
      paddingLeft: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      cursor: "pointer",
      "&:hover": {
         backgroundColor: theme.palette.common.lightGray,
         color: "#fff",
      },
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      minHeight: "200px",
      minWidth: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "21px",
      fontFamily: theme.fonts.secondary.bold,
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
   PopupButtonsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
   PopupButtons: {
      margin: "0 5px",
   },
   ListingsContainer: {
      marginTop: "25px",
   },
   ListingItem: {
      display: "flex",
      alignItems: "center",
   },
   ListingDetails: {
      flex: 1,
      paddingLeft: "10px",
      paddingRight: "10px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   imgPreview: {
      marginTop: "3px",
      marginLeft: "15px",
   },
   ListingItemName: {
      fontSize: "18px",
      font: theme.fonts.primary.reg,
      fontWeight: "bold",
   },
   ListingItemAddress: {
      fontSize: "14px",
      font: theme.fonts.secondary.reg,
      color: theme.palette.common.medGray,
   },
   AddMemberDropdown: {
      display: "flex",
      margin: "auto",
      width: "50%",
   },
}));

export default TeamDetailsPage;
