import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { useEffect, useState } from "react";
import { getLenderDashboard } from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";
import da from "date-fns/locale/da";

const chartSetting = {
   sx: {
      [`.${axisClasses.tickLabel}`]: {
         //transform: "translate(-20px, 0)",
      },
   },
};
const dataset = [{}];

const valueFormatter = (value: string) => {
   if (value !== undefined) {
      let item = value.split(":");
      let name = item[1];

      // Function to split a long word into chunks of 9 characters
      const splitLongWord = (word: string) => {
         let chunks = [];
         for (let i = 0; i < word.length; i += 8) {
            chunks.push(word.substring(i, i + 8));
         }
         return chunks.join("\n");
      };

      // Split the name into words
      let words = name.split(" ");
      let formattedName = "";
      let currentLine = "";

      words.forEach((word) => {
         // If adding the next word exceeds the 9 character limit or it's a single long word
         if (
            (currentLine + word).length > 9 ||
            (words.length === 1 && word.length > 9)
         ) {
            if (currentLine.length === 0) {
               // If the currentLine is empty and the word is long, split the word
               formattedName +=
                  (formattedName ? "\n" : "") + splitLongWord(word);
            } else {
               // Add the current line to formattedName and reset currentLine
               formattedName += (formattedName ? "\n" : "") + currentLine;
               currentLine = word.length > 9 ? splitLongWord(word) : word;
            }
         } else {
            // Add word to currentLine, include a space if it's not the first word
            currentLine += (currentLine.length > 0 ? " " : "") + word;
         }
      });

      // Add any remaining text to formattedName
      if (currentLine.length > 0 && !formattedName.includes(currentLine)) {
         formattedName += (formattedName ? "\n" : "") + currentLine;
      }

      return formattedName;
   } else {
      return "";
   }
};

interface HorizontalBarsProps {
   dataKey: string; // This restricts dataKey to be one of the keys in DataPoint
   dataSet: any;
}

export default function HorizontalBars({
   dataKey,
   dataSet,
}: HorizontalBarsProps) {
   const chartWidth = 800;
   const chartHeight = 300;
   return (
      <BarChart
         dataset={dataSet.length == 0 ? dataset : dataSet}
         yAxis={[
            { scaleType: "band", dataKey: "Name", max: 20, valueFormatter },
         ]}
         xAxis={[
            {
               tickInterval: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
               max: 20,
            },
         ]}
         margin={{ left: 60, bottom: 25, top: 25 }}
         bottomAxis={{ disableLine: true }}
         series={[{ dataKey: dataKey }]}
         layout="horizontal"
         colors={["#64e29a", "#60e0bd"]}
         //colors={["linear-gradient(#64e29a,#60e0bd)"]}
         width={chartWidth}
         height={chartHeight}
         {...chartSetting}
      ></BarChart>
   );
}

// import React from "react";
// //@ts-ignore
// import CanvasJSReact from "@canvasjs/react-charts";
// import { ThemeContext } from "@emotion/react";

// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const HorizontalBars = () => {
//    // Custom height and width
//    const chartWidth = 800;
//    const chartHeight = 300;

//    // Data for the chart
//    const options = {
//       animationEnabled: true,
//       axisX: {
//          tickThickness: 0, // Hide x-axis ticks
//          lineThickness: 0, // Hide x-axis line
//       },
//       axisY: {
//          tickThickness: 0, // Hide y-axis ticks
//          lineThickness: 0, // Hide y-axis line
//          gridColor: "white",
//          labelFontColor: "white",
//       },
//       width: chartWidth, // Set custom width
//       height: chartHeight, // Set custom height
//       data: [
//          {
//             type: "bar",
//             dataPoints: [
//                { y: 3, label: "Partner 1", color: "#64e29a" },
//                { y: 7, label: "Partner 2", color: "#64e29a" },
//                { y: 5, label: "Partner 3", color: "#64e29a" },
//                { y: 3, label: "Partner 4", color: "#64e29a" },
//                { y: 9, label: "Partner 5", color: "#64e29a" },
//             ],
//             indexLabel: "{y}",
//             indexLabelFontColor: "black", // Custom color for the indexLabel text
//             indexLabelFontFamily: "Arial",
//             indexLabelFontSize: 14, // Custom font size for the indexLabel text
//             indexLabelPlacement: "inside",
//          },
//       ],
//    };

//    return (
//       <div>
//          <CanvasJSChart options={options} />
//       </div>
//    );
// };

// export default HorizontalBars;
