import react, { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material';
import defaultProfileImg from '../../styles/assets/Icons/alerts-profile-pic.png'
import RoundedButton from '../common/boxlty.button';
import { number } from 'yup';
import { Margin, Padding } from '@mui/icons-material';

interface AlertsPopupComponentProps {
    id: string,
    alertIndex: number,
    profilePictureUrl: string,
    firstName: string,
    lastName: string,
    date: Date,
    startTime: String,
    endTime: String,
    hidden?: boolean,
}

let width = '275px';
let height = '200px';

function AlertsPopupComponent(props: AlertsPopupComponentProps) {
    const { classes } = useStyles();
    const theme = useTheme();

    useEffect(() => {
        setFloatingPosition();
    }, [])

    window.addEventListener('resize', () => {
        setFloatingPosition();
    })

    function setFloatingPosition() {
        try {
            let thisComponent = document.querySelector('#' + props.id) as HTMLElement;
            let alertsComponent = document.querySelector('#alerts-expand-target') as HTMLElement;
            let thisWidth = Number.parseFloat(width);
            let alertsWidth = Number.parseFloat(window.getComputedStyle(alertsComponent).width);

            //set right position - alerts component position
            let rect = alertsComponent.getBoundingClientRect()
            thisComponent.style.left = (rect.right - (alertsWidth / 3 + thisWidth + thisWidth / 2)) + 'px';

            //align vertically with clicked option
            let alertOptionsContainer: HTMLElement = document.querySelector('section[data-expand-shelf]') as HTMLElement;
            let alertOptions: NodeListOf<HTMLElement> = alertOptionsContainer.querySelectorAll('div');
            let selectedOption = alertOptions[props.alertIndex];

            let optionRect = selectedOption.getBoundingClientRect();
            thisComponent.style.top = optionRect.top + 'px';
        } catch (error) {

        }
    }

    function hideAlertComponent() {
        //console.log('#' + props.id)
        let thisComponent = document.querySelector('#' + props.id) as HTMLElement;
        //console.log(thisComponent);
        thisComponent.style.display = 'none';
    }

    function showAlertComponent() {
        setFloatingPosition();
        let thisComponent = document.querySelector('#' + props.id) as HTMLElement;
        thisComponent.style.display = 'none';
    }

    function getFormattedDateHeader(date: Date | null) {
        if (date == null) date = new Date();
        let months = [
            'January',
            'Febuary',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]
        let Month = months[date.getMonth()];
        let Day = date.getDate();
        let Year = date.getFullYear();
        return Month + ' ' + Day + ', ' + Year;
    }

    /*
    The format of this component is subject to change for different types of notifications
    the only example so far is a request for a tour notification
    */
    return (<>

        <section id={props.id} className={classes.alertsPopupComponent}>
            {/* exit button row */}
            <div className={classes.alertPopupComponentExitRow}>
                <span className={classes.ExitX} onClick={hideAlertComponent}>&times;</span>
            </div>
            {/* content row */}
            <section className={classes.contentRow}>
                <section className={classes.AlertFromRow}>
                    <img className={classes.AlertFromImg} src={props.profilePictureUrl ? props.profilePictureUrl : defaultProfileImg} />
                    <div className={classes.AlertFromRow}>
                        <p className={classes.NameText}>{props.firstName}</p>
                        <p className={classes.NameText}>{props.lastName}</p>
                    </div>
                </section>
                {/* This part could vary depending on the type of alert/notificaiton coming back */}
                {/* Time Information */}
                <section className={classes.TimeOfTourSection}>
                    <h4 className={classes.TimeOfTourHeader}>Requested Time of Tour:</h4>
                    <p className={classes.ScheduledText}>{getFormattedDateHeader(props.date)}</p>
                    <p className={classes.ScheduledText}>{props.startTime + ' - ' + props.endTime}</p>
                </section>

                {/* buttons */}
                <section className={classes.ButtonsSection}>
                    <RoundedButton
                        text={'APPROVE'}
                        color={'primary'}
                        showArrow={false}
                        showShadow={true}
                        marginLeft={'0px'}
                        marginRight={'2px'}
                    />

                    <RoundedButton
                        text={'DENY'}
                        color={'transparent'}
                        showArrow={false}
                        showShadow={false}
                        marginLeft={'2px'}
                        marginRight={'2px'}
                    />

                    {/* <RoundedButton
                        text={'CHAT'}
                        color={'transparent'}
                        showArrow={false}
                        showShadow={false}
                        marginLeft={'2px'}
                        marginRight={'0px'}
                    /> */}
                </section>

            </section>
        </section>
    </>)

}


// CSS

const useStyles = makeStyles({ name: 'AlertsPopupComponent' })((theme) => ({
    alertsPopupComponent: {
        display: 'none',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: '15px',
        position: 'absolute',
        overflow: 'hidden',
        maxHeight: height,
        minHeight: height,
        maxWidth: width,
        minWidth: width,
        padding: '0px',
        right: '0',
        zIndex: 20

    },
    alertPopupComponentExitRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '96%',
        height: '25px'
    },
    ExitX: {
        cursor: 'pointer',
        fontSize: '25px',
        fontWeight: '100',
        
    },
    contentRow: {
        paddingLeft: '25px'
    },
    AlertFromRow: {
        display: 'flex',
        alignItems: 'center',
        height: '45px'

    },
    AlertFromImg: {
        borderRadius: '100%',
        marginRight: '15px',
        height: '45px',
        width: '45px',
        minWidth: '45px',
        minHeight: '45px',
        objectFit: 'fill',

    },
    NameText: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginLeft: '2px',
        marginRight: '2px'
    },
    TimeOfTourSection: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        height: '65px'
    
    },
    TimeOfTourHeader: {
        fontWeight: 'bold',
        margin: '0',
        fontFamily: theme.fonts.primary.bold
    },
    ScheduledText: {
        fontWeight: 'med',
        marginBottom: '1px',
        marginTop: '1px'
    },
    ButtonsSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        maxWidth: '200px'
    }
}));


export default AlertsPopupComponent;

