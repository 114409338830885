import React, { ReactElement, useEffect, useState, useRef } from "react";
import { makeStyles } from 'tss-react/mui'
import { useTheme } from "@mui/material";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { fadeIn, moveElement } from "../../utils/genericUtils";

interface position {
    top: string,
    left: string,
    right: string,
    bottom: string
}

interface Tab {
    tabName: string,
    tabIconActive: ReactJSXElement,
    tabIconInActive: ReactJSXElement,
    tabComponent: ReactElement
}

interface TabbedContainerProps {
    tabs: Tab[],
    outerBackgroundColor?: string,
    backgroundColor?: string,
    leftBorderRadius?: string,
    showBoxShadow?: boolean,
    startingTabIndex?: number
}

function TabbedContainer(props: TabbedContainerProps) {
    const theme = useTheme();

    const initialRender = useRef<boolean>(true);
    const [defaultTabSet,setDefaultTabSet] = useState(false);
    const activeTabIndex = useRef<number>(0);
    const [tabs] = useState<Tab[]>(props.tabs);
    const indicatorPosition = useRef<position>({ top: '0px', left: '0px', right: '0px', bottom: '0px' })
    const [forceRender, setForceRender] = useState(true);

    const indicatorTransitionTime = 0.25;
    const indicatorYBuffer = 0;

    useEffect(() => {
        //small delay to allow components to render before positioning indicator
        setTimeout(() => {
            setTabIndicatorPosition(initialRender.current? 0.5 : void(null));
            if (initialRender.current) (document.querySelector('#app-body') as HTMLElement).addEventListener('scroll', () => { setTabIndicatorPosition(0.0001) });
            if (initialRender.current) (document.querySelector('#app-body') as HTMLElement).addEventListener('resize', () => setTabIndicatorPosition(0.0001));
            if (!defaultTabSet && initialRender.current == true && (props.startingTabIndex != null && props.startingTabIndex != 0)) activeTabIndex.current = props.startingTabIndex; setDefaultTabSet(true);

        }, initialRender.current? 1000 : 50)
    }, [forceRender,defaultTabSet])

    function setTabIndicatorPosition(transitionTime: number | void) {
        //Move Tab Indicator to selected index
        let tabIndicator: HTMLElement = document.querySelector('#tab-indicator') as HTMLElement;
        let selectedTab: HTMLElement = document.querySelector('#tab-' + activeTabIndex.current) as HTMLElement;
        if (tabIndicator == null || selectedTab == null) return;
        //get dimensions of the element
        let dimensions = selectedTab.getBoundingClientRect();
        let positionY = dimensions.top + indicatorYBuffer + 'px';
        //move tab indicator to center of selected tab
        let toPosition: position = {
            top: positionY,
            left: '0px',
            right: '0px',
            bottom: '0px'
        }
        moveElement(tabIndicator, (transitionTime != null && transitionTime != undefined) ? (transitionTime * 1000) : (indicatorTransitionTime * 1000), indicatorPosition.current, toPosition, () => {
            indicatorPosition.current = toPosition;
            if (initialRender.current) fadeIn(tabIndicator, transitionTime ? (transitionTime * 1000) : (indicatorTransitionTime * 1000)); initialRender.current = false;
        });
    }

    function onTabClicked(tabIndex: number) {
        activeTabIndex.current = tabIndex;
        setForceRender(!forceRender);
    }

    function formatTabs() {
        let tabElements: ReactJSXElement[] = [];
        tabs.map((tab, tabIndex) => {
            if (activeTabIndex.current == tabIndex) {
                tabElements.push(
                    <div onClick={() => { onTabClicked(tabIndex) }} id={'tab-' + tabIndex} className={[classes.TabIcon, 'tabbed-component-tab'].join(' ')}>
                        {tab.tabIconActive}
                    </div>
                );
            } else {
                tabElements.push(
                    <div onClick={() => { onTabClicked(tabIndex) }} id={'tab-' + tabIndex} className={[classes.TabIcon, 'tabbed-component-tab'].join(' ')}>
                        {tab.tabIconInActive}
                    </div>
                );
            }
        })
        return tabElements;
    }


    let useStyles = makeStyles({ name: "TabbedContainer" })((theme) => ({
        TabbedContainerOuter: {
            backgroundColor: props.outerBackgroundColor ? props.backgroundColor : 'transparent',
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            height: '100%',
            minWidth : '350px'
        },
        TabbedContainerInnerLeft: {
            backgroundColor: props.backgroundColor ? props.backgroundColor : 'white',
            display: 'flex',
            alignItems: 'flex-start',
            width: '80%',
            height: '100%',
            minHeight: '500px',
            borderTopLeftRadius: props.leftBorderRadius ? props.leftBorderRadius : '0px',
            borderBottomLeftRadius: props.leftBorderRadius ? props.leftBorderRadius : '0px',
            boxShadow: props.showBoxShadow ? `0px 0px 10px 0px ${theme.palette.common.lightGray}` : 'none',
            overflow : 'hidden'
        },
        TabbedContainerInnerRight: {
            backgroundColor: props.outerBackgroundColor ? props.outerBackgroundColor : 'transparent',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '20%',
            height: '100%'

        },
        TabIndicator: {
            opacity: initialRender.current === true ? '0' : '1',
            position: 'absolute',
            backgroundColor: props.backgroundColor ? props.backgroundColor : 'white',
            width: '45px',
            height: '35px',
            padding: '5px',
            borderTopRightRadius: '45%',
            borderBottomRightRadius: '45%',
            boxShadow: `5px 2px 5px 0px ${theme.palette.common.lightGray}`,
            zIndex: 0,
            transition: `all ${indicatorTransitionTime}s linear`
            //box-shadow: 5px 2px 5px 0px #A2A29F;

        },
        TabIcon: {
            marginTop: '0px',
            marginLeft: '15px',
            marginBottom: '10px',
            zIndex: 1,
            width: '25px',
            height: '25px',
            padding: '5px',
            paddingTop: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            backgroundColor: 'transparent',
            '&:hover': {
                //backgroundColor : theme.palette.common.darkGray
                filter: 'brightness(1.2)'
            },
            cursor: 'pointer',
        }
    }));

    const { classes } = useStyles();

    return (

        <div className={classes.TabbedContainerOuter}>
            {/* left side houses the dynamic component */}
            <section className={classes.TabbedContainerInnerLeft}>
                {tabs[activeTabIndex.current].tabComponent}
            </section>

            {/* right side contains the dyanmic tabs */}
            <section className={classes.TabbedContainerInnerRight}>

                {/* floating Tab Pointer */}
                <div id="tab-indicator" className={classes.TabIndicator}></div>

                {/* dynamic list of tab options */}
                {formatTabs()}

            </section>

        </div>
    )


}



export default TabbedContainer