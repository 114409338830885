import React from "react";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { TimeSlot } from "../primary-tabs/listings.availability.tab";
import { useState, useEffect } from "react";
export const TimeBubble: React.FC<{
  timeSlots: TimeSlot[];
  startTime: number;
  endTime: number;
  handleClick: (start: number, end: number) => void;
}> = ({ timeSlots, startTime, endTime, handleClick }) => {
  const { classes } = useStyles();
  const [active, setActive] = useState(false);

  const determineIfActive = () => {
    if (timeSlots.length < 1) {
      return;
    } else {
      const compTime = timeSlots.filter(
        (timeSlot) => timeSlot.start === startTime && timeSlot.end === endTime
      );

      if (compTime.length > 0) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  };

  useEffect(() => {
    //console.log(timeSlots);
  }, [timeSlots]);

  useEffect(() => {
    determineIfActive();
  }, [timeSlots, startTime, endTime]);

  switch (active) {
    case true:
      return (
        <>
          <Grid
            item
            className={classes.containerActive}
            onClick={() => {
              handleClick(startTime, endTime);
              setActive(false);
            }}
          >
            <div className={classes.bubble}>
              {`${startTime > 12 ? startTime % 12 : startTime}-${
                endTime > 12 ? endTime % 12 : endTime
              }${endTime < 12 ? "a" : "p"}`}
            </div>
          </Grid>
        </>
      );
    case false:
      return (
        <>
          <Grid
            item
            className={classes.containerNotActive}
            onClick={() => {
              handleClick(startTime, endTime);
              setActive(true);
            }}
          >
            <div className={classes.bubble}>
              &nbsp;&nbsp;
              {`${startTime > 12 ? startTime % 12 : startTime}-${
                endTime > 12 ? endTime % 12 : endTime
              }${endTime < 12 ? "a" : "p"}`}
              &nbsp;&nbsp;
            </div>
          </Grid>
        </>
      );
    default:
      return (
        <>
          <Grid
            item
            className={classes.containerDefault}
            onClick={() => {
              handleClick(startTime, endTime);
            }}
          >
            <div className={classes.bubble}>
              {`${startTime > 12 ? startTime % 12 : startTime}-${
                endTime > 12 ? endTime % 12 : endTime
              }${endTime < 12 ? "a" : "p"}`}
            </div>
          </Grid>
        </>
      );
  }
};

const useStyles = makeStyles({ name: "Time Bubble" })((theme) => ({
  containerActive: {
    backgroundColor: "#39C689",
    height: "35px",
    width: "75px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    color: "white",
    cursor: "pointer",
    fontWeight: "bold",
  },
  containerNotActive: {
    background: "white",
    height: "35px",
    width: "75px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    color: "#A2A2A2",
    fontWeight: "bold",
    textDecoration: "line-through",
    cursor: "pointer",
  },
  containerDefault: {
    color: "blue",
  },
  bubble: {},
  line: {},
}));
