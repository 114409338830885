import React, { useEffect } from "react";
import {
   Grid,
   Tabs,
   Tab,
   Button,
   Input,
   TextField,
   Snackbar,
   Alert,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { HeaderButton } from "./forms/header.button";
import { useTheme } from "@mui/material";
import { ListingPreviewModal } from "../preview/listing.preview.modal";
import { Listing } from "../../../pages/listings/viewing/non-enterprise/listing.types";
import { useFormContext } from "react-hook-form";
import { Photo } from "../create/secondary-tabs/photos.tab";
import {
   useCreateListingStateRef,
   useCreateListingState,
   useCreateListingDispatch,
   CreateListingActions,
} from "../../../contexts/create-listing/create-listing.context";
import { createListingDefaultValues } from "./listing.create.general.form.context";
import { CreatePropertyGeneral } from "../../../contexts/create-listing/create-listing.types";
import { useUserState } from "../../../contexts/user/user.context";
export interface PreviewListingObject {
   nickName: string;
   propertyCreateTypeId: string;
   price: string;
   address: string;
   city: string;
   state: string;
   zipCode: string;
   propertyTypeId: string;
   lotSize: string;
   sqFoot: string;
   bathrooms: string;
   bedrooms: string;
   parkingTypeId: string;
   parkingSpace: string;
   propertyHeadline: string;
   generalInformation: string;
   utilities: string;
   petPolicyId: string;
   petPolicyDescription: string;
   zlink: string;
   accessInstruction: string;
   exitInstruction: string;
   postTourInstruction: string;
   propertyId: string;
}

export const ListingsCreateTop: React.FC<{}> = ({}) => {
   const { classes } = useStyles();
   const { CurrentMainTab } = useParams<{ CurrentMainTab: string }>();
   const { CurrentSecondaryTab } = useParams<{ CurrentSecondaryTab: string }>();
   const [showInput, setShowInput] = useState(false);
   const { user } = useUserState();
   const [previewOpen, setPreviewOpen] = useState(false);
   const [preview, setPreview] = useState<CreatePropertyGeneral>(
      createListingDefaultValues
   );
   const [previewPhotos, setPreviewPhotos] = useState<string[]>([]);
   const [tabErrorOpen, setTabErrorOpen] = useState(false);
   const navigate = useNavigate();
   const theme = useTheme();
   const {
      register,
      watch,
      getValues,
      formState: { errors, isSubmitting },
   } = useFormContext();
   const { nickName, previewObject, featurePhotoPath, photoPaths, propertyId } =
      useCreateListingState();
   const dispatch = useCreateListingDispatch();

   const handleClick = () => {
      setShowInput(true);
   };

   const handleClickAway = () => {
      setShowInput(false);
   };

   const handleModalClose = () => {
      setPreviewOpen(false);
   };

   const handleSetOpen = () => {
      const values = previewObject;
      setPreview(values);

      setPreviewOpen(true);
   };

   const setSavedPhotos = () => {
      let photosArr: Array<string> = [];
      if (featurePhotoPath) {
         photosArr.push(featurePhotoPath);
      }
      if (photoPaths && photoPaths.length > 0) {
         for (let i = 0; i < photoPaths.length; i++) {
            photosArr.push(photoPaths[i]);
         }
      }
      const fullArr: Array<string> = [...photosArr];
      setPreviewPhotos(fullArr);
   };

   const handleDisabledClick = () => {
      if (!propertyId) {
         setTabErrorOpen(true);
         return;
      }
   };

   useEffect(() => {
      setSavedPhotos();
   }, [photoPaths, featurePhotoPath]);

   return (
      <>
         <Grid
            container
            direction="row"
            display="flex"
            justifyContent={"space-between"}
         >
            <ListingPreviewModal
               open={previewOpen}
               created={false}
               photosArray={[...previewPhotos]}
               onClose={handleModalClose}
               listing={preview}
            />
            <Grid item>
               <Grid container direction="column">
                  <Grid item width={"700px"}>
                     <TextField
                        variant="standard"
                        fullWidth
                        inputProps={{
                           style: {
                              fontSize: "36px",
                              fontFamily: theme.fonts.secondary.bold,
                              fontWeight: "bold",
                              cursor: "text",
                           },
                        }}
                        InputProps={{ disableUnderline: true, readOnly: true }}
                        value={nickName}
                        onChange={(e: any) => {
                           return;
                        }}
                     />
                  </Grid>
                  <Grid item sx={{ marginBottom: "10px" }}>
                     <Tabs
                        value={CurrentMainTab}
                        classes={{
                           root: classes.tabsRoot,
                           flexContainer: classes.tabsFlex,
                           indicator: classes.tabsIndicator,
                        }}
                     >
                        <Tab
                           label="GENERAL"
                           value="general"
                           disableFocusRipple
                           disableRipple
                           onClick={() =>
                              navigate(
                                 `/listings/create/general/${CurrentSecondaryTab}`
                              )
                           }
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label="TEAM"
                           value="team"
                           disableFocusRipple
                           disableRipple
                           onClick={() =>
                              propertyId
                                 ? navigate(
                                      `/listings/create/team/${CurrentSecondaryTab}`
                                   )
                                 : handleDisabledClick()
                           }
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label="AVAILABILITY"
                           value="availability"
                           disableFocusRipple
                           disableRipple
                           onClick={() =>
                              propertyId
                                 ? navigate(
                                      `/listings/create/availability/${CurrentSecondaryTab}`
                                   )
                                 : handleDisabledClick()
                           }
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label="HARDWARE"
                           value="hardware"
                           disableFocusRipple
                           disableRipple
                           onClick={() =>
                              propertyId
                                 ? navigate(
                                      `/listings/create/hardware/${CurrentSecondaryTab}`
                                   )
                                 : handleDisabledClick()
                           }
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label={`PRE-APPROVAL & FEES`}
                           value="pre-approval"
                           disableFocusRipple
                           disableRipple
                           onClick={() =>
                              propertyId
                                 ? navigate(
                                      `/listings/create/pre-approval/${CurrentSecondaryTab}`
                                   )
                                 : handleDisabledClick()
                           }
                           classes={{ root: classes.tabSingleRoot }}
                        />
                     </Tabs>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item sx={{ paddingRight: "20px" }}>
               <Grid container direction={"row"} gap={"15px"}>
                  {user?.idVerify === 1 ? (
                     <Grid item>
                        <Button
                           variant="contained"
                           classes={{ disabled: classes.activateDisabled }}
                           disableElevation
                           disabled={true}
                        >
                           Activate
                        </Button>
                     </Grid>
                  ) : null}
                  <Grid item>
                     <Button
                        variant="contained"
                        onClick={() => {
                           handleSetOpen();
                        }}
                        disableElevation
                        disabled={false}
                        classes={{
                           root: classes.previewRoot,
                           disabled: classes.previewDisabled,
                        }}
                     >
                        PREVIEW
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Snackbar
               open={tabErrorOpen}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               autoHideDuration={4000}
               onClose={() => {
                  setTabErrorOpen(false);
               }}
            >
               <Alert
                  severity="error"
                  onClose={() => {
                     setTabErrorOpen(false);
                  }}
               >
                  Please “Save Changes” before continuing.
               </Alert>
            </Snackbar>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Listings-Create-Top" })((theme) => ({
   pageHeader: {
      fontSize: "36px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textDecoration: "underline",
   },
   activateDisabled: {
      backgroundColor: "#F5F6F6 !important",
      border: "1px solid rgba(0, 0, 0, 0.26) !important",
   },
   previewRoot: {
      backgroundColor: "#F5F6F6",
      border: "1px solid #707070",
      color: "#707070",
      "&:hover": {
         backgroundColor: "#F5F6F6",
         border: "1px solid #707070",
         color: "#707070",
      },
   },
   previewDisabled: {
      backgroundColor: "#F5F6F6 !important",
      border: "1px solid rgba(0, 0, 0, 0.26) !important",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      display: "flex",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
}));
