import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import { DashboardMain } from "./dashboard.main";
import { LenderDashboardMain } from "./lender.dashboard.main";

export const DashboardViewMainSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         return <DashboardMain date={new Date()} />;
      //// Enterprise Lender
      case 6:
         if (user?.isEnterpriseSuperUser == 1) {
            return <LenderDashboardMain />;
         }
         return <DashboardMain date={new Date()} />;

      //// Non-Enterprise
      default:
         return <DashboardMain date={new Date()} />;
   }
};
