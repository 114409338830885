import React from 'react'
import { Grid } from '@mui/material'
import { Select, MenuItem, Avatar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useState } from 'react'
import { useGradient } from '../../../../contexts/gradients/gradients.context'
export interface User {
    userId: number
    email: string,
    firstName: string,
    lastName: string,
    isPrimaryManager: boolean,
    mobile: string
    userName: string;
    userImage: string;
}

export const UserSelectComponent: React.FC<{ users: User[], setSelected: (value: string) => void }> = ({ users, setSelected }) => {
    const { classes } = useStyles()
    const [internalSelected, setInternalSelected] = useState('0')
    const { inProgressGradient } = useGradient()
    const handleSelection = (value: string) => {

    }

    return (
        <>
            <Select variant='standard' value={internalSelected} disableUnderline onChange={(e) => { setSelected(e.target.value) }}>
                <MenuItem value="0" classes={{ selected: classes.selected }}>Name or Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
                
                {users.map((user, index) => (
                        <MenuItem value={user.email} key={user.email} sx={{ '&:hover': { background: `${inProgressGradient} !important` } }}>
                            <Grid container direction={'row'} alignItems='center' display='flex' className={classes.mainContainer}>
                                <Grid item sx={{ paddingRight: '10px' }}>
                                    <Avatar src={`https://boxlty-media.s3.amazonaws.com/user-profile/${user.userImage}`} />
                                </Grid>
                                <Grid item>
                                    <Grid container direction={'column'}>
                                        <Grid item className={classes.name}>
                                            <span style={{ marginRight: '5px' }}>{user.firstName}</span>
                                            <span>{user.lastName}</span>
                                        </Grid>
                                        <Grid item>
                                            <span>{user.email}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MenuItem>
                ))}
            </Select>
        </>
    )
}

const useStyles = makeStyles({ name: "user-select-component" })((theme) => ({
    mainContainer: {
        padding: '5px'
    },
    name: {
        fontSize: '18px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
    },
    menuItem: {

    },
    selected: {
        backgroundColor: 'white !important'
    },

}))