import React from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
   returnParkingTypeNumber,
   returnPropertyTypeString,
   returnPropertyTypeNumber,
   returnPetPolicyNumber,
   returnPetPolicyString,
   returnParkingTypeString,
} from "./utils";
import {
   createPropertyStepSixUserInvite,
   getListingFull,
   getUserInviteList,
} from "../../../contexts/api/api.functions";
import {
   useSingleListingState,
   useSingleListingDispatch,
   SingleListingActions,
} from "../../../contexts/single-listing/singleListing.context";
import { Alert, Snackbar } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material";
import { S3DirectoryName } from "../../../contexts/api/api.types";
import axios from "axios";
import {
   getListing,
   generatePreSignedUrl,
   createPropertyStepTwoPhotos,
} from "../../../contexts/api/api.functions";
const { v4: uuidv4 } = require("uuid");
export interface UserInvite {
   emailId: string;
   propertyId: string;
}

export const createListingTeamDefaultValues = {
   photosSubmitted: false,
};

export const CreatedListingFormContextTeam: React.FC<{
   children: React.ReactNode;
   setActive: (value: boolean) => void;
}> = ({ children, setActive }) => {
   const theme = useTheme();
   const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
   const [successOpen, setSuccessOpen] = useState(false);
   const [error, setError] = useState<null | string>(null);
   const [errorOpen, setErrorOpen] = useState(false);
   const [photoErrorOpen, setPhotoErrorOpen] = useState(false);
   const [backdropOpen, setBackdropOpen] = useState(false);
   const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false);
   const [photosSubmitted, setPhotosSubmitted] = useState(false);
   const { propertyId, photos, featurePhoto, featurePhotoPath, photoPaths } =
      useSingleListingState();
   const dispatch = useSingleListingDispatch();
   const schema = yup.object().shape({
      photosSubmitted: yup.boolean().required(),
   });

   const methods = useForm({
      defaultValues: createListingTeamDefaultValues,
      resolver: yupResolver(schema),
   });

   const handleSubmitFunction = async (data: any) => {
      if (!ListingIdParam) {
         return;
      }
      if (!featurePhotoPath) {
         if (!featurePhoto) {
            setPhotoErrorOpen(true);
            return;
         } else if (featurePhoto && featurePhoto.file === "") {
            setPhotoErrorOpen(true);
            return;
         }
      }
      if (photoPaths && photoPaths.length === 0) {
         if (photos.length === 0) {
            setPhotoErrorOpen(true);
            return;
         }
      }
      if (
         featurePhotoPath &&
         photoPaths &&
         photoPaths.length > 0 &&
         photos.length === 0
      ) {
         const bodyPhotos = {
            propertyId: ListingIdParam,
            stepId: 2,
            mainImg: featurePhotoPath,
            images: [...photoPaths],
         };
         const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos);
         if (resPhoto && resPhoto.data && resPhoto.data.status === true) {
            setBackdropOpen(false);
            setSuccessOpen(true);
            return;
         } else {
            setBackdropOpen(false);
            setError("We could not update the photos, please try again.");
            setErrorOpen(true);
         }
      } else if (featurePhoto.file !== "" && photos.length > 0) {
         if (!ListingIdParam) {
            return;
         }
         setBackdropOpen(true);
         let featureKey: string = uuidv4();
         let photosKeyArr: Array<string> = [];
         if (featurePhotoPath === null) {
            const featureRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: featureKey,
            });
            if (
               featureRequest &&
               featureRequest.data &&
               featureRequest.data.status === true
            ) {
               const featureUrl = featureRequest.data.result;
               await axios
                  .put(featureUrl, featurePhoto.file)
                  .then((response) => {
                     // console.log(response)
                  })
                  .catch((error) => {
                     // console.log(error)
                  });
            }
         }
         await Promise.all(
            photos.map(async (photo) => {
               let photoKey = uuidv4();

               const res1 = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: photoKey,
               });
               if (res1 && res1.data && res1.data.status === true) {
                  photosKeyArr.push(photoKey);
                  const photoUrl = res1.data.result;
                  await axios
                     .put(photoUrl, photo.file)
                     .then(() => {
                        // console.log(`Uploaded file ${photoKey}`)
                     })
                     .catch((error) => {
                        // console.log(error)
                     });
               }
            })
         );

         const body4 = {
            propertyId: ListingIdParam,
            stepId: 2,
            mainImg: featureKey,
            images: [...photosKeyArr],
         };
         const res4 = await createPropertyStepTwoPhotos(body4);
         if (
            res4 &&
            res4.data &&
            res4.data.status &&
            res4.data.status === true
         ) {
            setBackdropOpen(false);
            setSuccessOpen(true);
            return;
         } else {
            setBackdropOpen(false);
            setError("We could not update the photos, please try again.");
            setErrorOpen(true);
            return;
         }
      } else if (featurePhotoPath && photos.length > 0) {
         if (!ListingIdParam) {
            return;
         }
         setBackdropOpen(true);
         let photosKeyArr: Array<string> = [];
         await Promise.all(
            photos.map(async (photo) => {
               let photoKey = uuidv4();

               const res1 = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: photoKey,
               });
               if (res1 && res1.data && res1.data.status === true) {
                  photosKeyArr.push(photoKey);
                  const photoUrl = res1.data.result;
                  await axios
                     .put(photoUrl, photo.file)
                     .then(() => {
                        // console.log(`Uploaded file ${photoKey}`)
                     })
                     .catch((error) => {
                        // console.log(error)
                     });
               }
            })
         );
         let body4: any;
         if (photoPaths) {
            body4 = {
               propertyId: ListingIdParam,
               stepId: 2,
               mainImg: featurePhotoPath,
               images: [...photoPaths, ...photosKeyArr],
            };
         } else {
            body4 = {
               propertyId: ListingIdParam,
               stepId: 2,
               mainImg: featurePhotoPath,
               images: [...photosKeyArr],
            };
         }
         const res4 = await createPropertyStepTwoPhotos(body4);
         if (
            res4 &&
            res4.data &&
            res4.data.status &&
            res4.data.status === true
         ) {
            setBackdropOpen(false);
            setSuccessOpen(true);
            return;
         } else {
            setBackdropOpen(false);
            setError("We could not update the photos, please try again.");
            setErrorOpen(true);
         }
      } else if (featurePhoto.file !== "" && photoPaths) {
         let featureKey: string = uuidv4();
         const featureRequest = await generatePreSignedUrl({
            directory: S3DirectoryName.PROPERTY,
            fileName: featureKey,
         });
         if (
            featureRequest &&
            featureRequest.data &&
            featureRequest.data.status === true
         ) {
            const featureUrl = featureRequest.data.result;
            await axios
               .put(featureUrl, featurePhoto.file)
               .then((response) => {
                  // console.log(response)
               })
               .catch((error) => {
                  // console.log(error)
               });
         }
         const body5 = {
            propertyId: ListingIdParam,
            stepId: 2,
            mainImg: featureKey,
            images: [...photoPaths],
         };
         const res5 = await createPropertyStepTwoPhotos(body5);
         if (
            res5 &&
            res5.data &&
            res5.data.status &&
            res5.data.status === true
         ) {
            setBackdropOpen(false);
            setSuccessOpen(true);
            return;
         }
      } else {
         setBackdropOpen(false);
         setError("We could not update the property, please try again.");
         setErrorOpen(true);
         return;
      }
   };

   const fetchStatusListing = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         const res = await getListingFull(parseInt(ListingIdParam));
         if (res && res.data && res.data.status && res.data.status === true) {
            if (
               res.data.result &&
               res.data.result.PropertyDetail &&
               res.data.result.PropertyDetail.propertyStatus
            ) {
               const value = res.data.result.PropertyDetail.propertyStatus;
               //console.log(value)
               //console.log("this is the value")
               if (value === "1") {
                  dispatch({
                     type: SingleListingActions.setListingType,
                     payload: "active",
                  });
                  return;
               }
               if (value === "2") {
                  dispatch({
                     type: SingleListingActions.setListingType,
                     payload: "draft",
                  });
                  return;
               }
               if (value === "3") {
                  dispatch({
                     type: SingleListingActions.setListingType,
                     payload: "archive",
                  });
                  return;
               }
               return;
            }
         }
      }
   };

   useEffect(() => {
      fetchStatusListing();
   }, [ListingIdParam]);

   const setPreviewValues = (res: any) => {
      if (res && res.data && res.data.status && res.data.status === true) {
         let nickName: string = "";
         let propertyCreateTypeId: string = "";
         let price: string = "";
         let address: string = "";
         let addressLine2: string = "";
         let city: string = "";
         let state: string = "";
         let zipCode: string = "";
         let propertyTypeId: string = "";
         let lotSize: string = "";
         let sqFoot: string = "";
         let bathrooms: string = "";
         let bedrooms: string = "";
         let parkingTypeId: string = "";
         let parkingSpace: string = "";
         let propertyHeadline: string = "";
         let generalInformation: string = "";
         let utilities: string = "";
         let petPolicyId: string = "";
         let petPolicyDescription: string = "";
         let zlink: string = "";
         let accessInstruction: string = "";
         let exitInstruction: string = "";
         let postTourInstruction: string = "";
         let propertyId: string = "";

         if (res.data.result[0].screen1[0].propertyName) {
            const value = res.data.result[0].screen1[0].propertyName;
            nickName = value;
         }
         if (res.data.result[0].screen1[0].propertyCreateTypeId) {
            const value =
               res.data.result[0].screen1[0].propertyCreateTypeId === 1
                  ? "rental"
                  : "sale";
            propertyCreateTypeId = value;
         }
         if (res.data.result[0].screen1[0].propertySalePrice) {
            const value = res.data.result[0].screen1[0].propertySalePrice;
            price = value;
         }
         if (res.data.result[0].screen1[0].propertyAddress) {
            const value = res.data.result[0].screen1[0].propertyAddress;
            address = value;
         }

         if (res.data.result[0].screen1[0].apt) {
            const value = res.data.result[0].screen1[0].apt;
            addressLine2 = value;
         }
         if (res.data.result[0].screen1[0].propertyCity) {
            const value = res.data.result[0].screen1[0].propertyCity;
            city = value;
         }
         if (res.data.result[0].screen1[0].propertyState) {
            const value = res.data.result[0].screen1[0].propertyState;
            state = value;
         }
         if (res.data.result[0].screen1[0].propertyZipCode) {
            const value = res.data.result[0].screen1[0].propertyZipCode;
            zipCode = value;
         }
         if (res.data.result[0].screen1[0].propertyTypeId) {
            const value = returnPropertyTypeString(
               res.data.result[0].screen1[0].propertyTypeId
            );
            propertyTypeId = value;
         }
         if (res.data.result[0].screen1[0].propertyLotSize) {
            const value = res.data.result[0].screen1[0].propertyLotSize;
            lotSize = value;
         }
         if (res.data.result[0].screen1[0].propertySize) {
            const value = res.data.result[0].screen1[0].propertySize;
            sqFoot = value;
         }
         if (res.data.result[0].screen1[0].propertyBathrooms) {
            const value = res.data.result[0].screen1[0].propertyBathrooms;
            bathrooms = value;
         }
         if (res.data.result[0].screen1[0].propertyBedrooms) {
            const value = res.data.result[0].screen1[0].propertyBedrooms;
            bedrooms = value;
         }
         if (res.data.result[0].screen1[0].propertyParkingTypeId) {
            const value = returnParkingTypeString(
               res.data.result[0].screen1[0].propertyParkingTypeId
            );
            parkingTypeId = value;
         }
         if (res.data.result[0].screen1[0].propertyParkingSpace) {
            const value =
               res.data.result[0].screen1[0].propertyParkingSpace.toString();
            parkingSpace = value;
         }
         if (res.data.result[0].screen1[0].propertyHeadline) {
            const value = res.data.result[0].screen1[0].propertyHeadline;
            propertyHeadline = value;
         }
         if (res.data.result[0].screen1[0].generalInformation) {
            const value = res.data.result[0].screen1[0].generalInformation;
            generalInformation = value;
         }
         if (res.data.result[0].screen1[0].propertyUtilities) {
            const value = res.data.result[0].screen1[0].propertyUtilities;
            utilities = value;
         }
         if (res.data.result[0].screen1[0].propertyPetPolicyId) {
            const value = returnPetPolicyString(
               res.data.result[0].screen1[0].propertyPetPolicyId
            );
            petPolicyId = value;
         }
         if (res.data.result[0].screen1[0].propertyPetPolicyDesc) {
            const value = res.data.result[0].screen1[0].propertyPetPolicyDesc;
            petPolicyDescription = value;
         }
         if (res.data.result[0].screen1[0].propertyZLink) {
            const value = res.data.result[0].screen1[0].propertyZLink;
            zlink = value;
         }
         const previewObject = {
            nickName: nickName,
            propertyCreateTypeId: propertyCreateTypeId,
            price: price,
            address: address,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zipCode: zipCode,
            propertyTypeId: propertyTypeId,
            lotSize: lotSize,
            sqFoot: sqFoot,
            bathrooms: bathrooms,
            bedrooms: bedrooms,
            parkingTypeId: parkingTypeId,
            parkingSpace: parkingSpace,
            propertyHeadline: propertyHeadline,
            generalInformation: generalInformation,
            utilities: utilities,
            petPolicyId: petPolicyId,
            petPolicyDescription: petPolicyDescription,
            zlink: zlink,
            accessInstruction: accessInstruction,
            exitInstruction: exitInstruction,
            postTourInstruction: postTourInstruction,
            propertyId: ListingIdParam,
         };
         dispatch({
            type: SingleListingActions.setPreview,
            payload: previewObject,
         });
      }
   };

   const validateForActivation = (res: any) => {
      if (
         res.data.result[0].screen1 &&
         res.data.result[0].screen1.length > 0 &&
         res.data.result[0].screen4 &&
         res.data.result[0].screen4.weekDays &&
         res.data.result[0].screen4.weekDays.length > 0 &&
         res.data.result[0].screen5 &&
         res.data.result[0].screen5.length > 0 &&
         res.data.result[0].screen5[0].accessChoice !== "" &&
         res.data.result[0].screen7 &&
         res.data.result[0].screen7.length > 0 &&
         res.data.result[0].screen7[0].propertyPaymentAccessOption !== ""
      ) {
         if (
            res.data.result[0].screen5[0].accessChoice &&
            res.data.result[0].screen5[0].accessChoice === "0"
         ) {
            dispatch({
               type: SingleListingActions.setCanActivate,
               payload: true,
            });
         } else if (
            res.data.result[0].screen5[0].accessChoice &&
            res.data.result[0].screen5[0].accessChoice === "1" &&
            res.data.result[0].screen5[0].propertyLockKey !== ""
         ) {
            dispatch({
               type: SingleListingActions.setCanActivate,
               payload: true,
            });
         } else {
            dispatch({
               type: SingleListingActions.setCanActivate,
               payload: false,
            });
         }
      } else {
         dispatch({
            type: SingleListingActions.setCanActivate,
            payload: false,
         });
      }
   };

   const fetchAndUpdatePhotos = async () => {
      if (!ListingIdParam) {
         return;
      }
      const res = await getListing(parseInt(ListingIdParam));
      if (res && res.data && res.data.status && res.data.status === true) {
         ///// Check to see whether or not the listing can be activate or not
         validateForActivation(res);
         // if (res.data.result[0].screen1 && res.data.result[0].screen1.length > 0 && res.data.result[0].screen4 && res.data.result[0].screen4.weekDays && res.data.result[0].screen4.weekDays.length > 0 && res.data.result[0].screen5 && res.data.result[0].screen5.length > 0 && res.data.result[0].screen5[0].propertyLockKey !== "" && res.data.result[0].screen7 && res.data.result[0].screen7.length > 0 && res.data.result[0].screen7[0].propertyDocumentReq !== "0") {
         //   dispatch({
         //     type: SingleListingActions.setCanActivate,
         //     payload: true
         //   })
         // } else {
         //   dispatch({
         //     type: SingleListingActions.setCanActivate,
         //     payload: false
         //   })
         // }

         if (
            res.data.result[0].screen7[0].propertyId ===
            parseInt(ListingIdParam)
         ) {
            setPreviewValues(res);
            if (res.data.result[0] && res.data.result[0].screen1) {
               if (res.data.result[0].screen1[0].propertyName) {
                  const value = res.data.result[0].screen1[0].propertyName;
                  dispatch({
                     type: SingleListingActions.setNickName,
                     payload: value,
                  });
               }
            }
            if (
               res.data.result[0].screen2 &&
               res.data.result[0].screen2.length > 0
            ) {
               if (
                  (res.data.result[0].screen2[0].propertyFeaturePhoto &&
                     res.data.result[0].screen2[0].propertyFeaturePhoto !==
                        null) ||
                  ""
               ) {
                  const value =
                     res.data.result[0].screen2[0].propertyFeaturePhoto;
                  dispatch({
                     type: SingleListingActions.setFeaturePhotoPath,
                     payload: value,
                  });
               }
               if (res.data.result[0].screen2[0].propertyPhoto !== null || "") {
                  const paths = [];
                  for (var i = 0; i < res.data.result[0].screen2.length; i++) {
                     const value = res.data.result[0].screen2[i].propertyPhoto;
                     paths.push(value);
                  }
                  dispatch({
                     type: SingleListingActions.setPhotoPaths,
                     payload: [...paths],
                  });
               }
            }
         }
      }
   };

   useEffect(() => {
      if (ListingIdParam) {
         fetchAndUpdatePhotos();
      } else {
         return;
      }
   }, [propertyId]);

   const onSubmit = (data: any) => {
      handleSubmitFunction(data).then(() => {
         fetchAndUpdatePhotos();
      });
      // dispatch({ type: SingleListingActions.setFeature, payload: { file: ""}})
      // dispatch({ type: SingleListingActions.setPhotos, payload: []})
      // console.log("done running submit function")
   };

   return (
      <FormProvider {...methods}>
         <Snackbar
            open={successOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setSuccessOpen(false);
            }}
         >
            <Alert
               severity="success"
               onClose={() => {
                  setSuccessOpen(false);
               }}
            >
               Photos Saved Successfully!
            </Alert>
         </Snackbar>
         <Snackbar
            open={photoErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setPhotoErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setPhotoErrorOpen(false);
               }}
            >
               Feature photo and at least one additional photo required.
            </Alert>
         </Snackbar>
         <Snackbar
            open={propertyIdErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setPropertyIdErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setPropertyIdErrorOpen(false);
               }}
            >
               No Property Id, if you already created a property go back to the
               main listings page to edit or create another property.
            </Alert>
         </Snackbar>
         <Snackbar
            open={errorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setErrorOpen(false);
               }}
            >
               {error ? error : "Something went wrong, please try again."}
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
         >
            <CircularProgress color="primary" />
         </Backdrop>
         <form
            style={{ width: "100%" }}
            onSubmit={methods.handleSubmit(onSubmit)}
         >
            {children}
         </form>
      </FormProvider>
   );
};
