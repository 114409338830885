// import react, { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
// import { useTheme } from "@mui/material";
import TourElement from "./TourElement";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ToursResponse } from "../../contexts/api/api.types";

interface ToursListProps {
  tours: ToursResponse[];
}

function ScheduledToursList(props: ToursListProps) {
  const { classes } = useStyles();
  //   const theme = useTheme();

  useEffect(() => {}, [props.tours]);

  function formatTours() {
    let elements: ReactJSXElement[] = [];
    // eslint-disable-next-line array-callback-return
    props.tours.map((tour, index) => {
      let _tour = (
        <TourElement
          key={index}
          propertyImageUrl={tour.propertyFeaturePhoto}
          propertyName={tour.propertyName}
          propertyAddress={tour.propertyAddress}
          startTime={tour.startTime}
          endTime={tour.endTime}
        />
      );
      elements.push(_tour);
      elements.push(
        <div key={`${index}-line`} className={classes.dividerLine}></div>
      );
    });
    //remove last div
    elements.pop();
    return elements;
  }

  return (
    <>
      <section className={classes.ScheduledToursContainer}>
        {props.tours.length === 0 ? (
          <div className={classes.noData}>No tours scheduled</div>
        ) : (
          <></>
        )}
        {formatTours()}
      </section>
    </>
  );
}

const useStyles = makeStyles({ name: "RegisterEmailComponent" })((theme) => ({
  ScheduledToursContainer: {
    width: "90%",
    height: "310px",
    maxHeight: "310px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "10px",
    /* Custom scrollbar styles for webkit-based browsers */
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.common.darkGray,
      borderRadius: "8px",
      height: "5px",
    },
    /* Custom scrollbar styles for Firefox */
    scrollbarWidth: "thin",
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.common.white}`,
    "&::-moz-scrollbar-thumb": {
      backgroundColor: theme.palette.common.darkGray,
      borderRadius: "20px",
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.common.lightGray, // Control the background color of the track here
      borderRadius: "20px",
    },
  },
  dividerLine: {
    height: "0.5px",
    margin: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.lightGray,
  },
  noData: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: theme.fonts.secondary.med,
    fontSize: "1.2rem",
  },
}));

export default ScheduledToursList;
