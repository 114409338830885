import { ChangeEvent, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AlertColor, MenuItem, Select, useTheme } from "@mui/material";
import ModalTransparentLayer from "../common/ModalTransparentLayer";
import { fadeIn, fadeOut, moveElement } from "../../utils/genericUtils";
import RoundedButton from "../common/boxlty.button";
import {
   addAssociatedLock,
   getLenderPartners,
   lenderAssignLock,
} from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";
import { LenderAssignLockReq } from "../../contexts/api/api.types";

interface position {
   top: string;
   left: string;
   right: string;
   bottom: string;
}

interface Partner {
   userId: string;
   name: string;
}

interface ActionButtonModalProps {
   id?: string;
   isVisible: boolean;
   openPosition: position;
   closedPosition: position;
   setPanel: (value: boolean) => void;
   setSnackbar: (value: boolean) => void;
   setSnackbarMessage: (value: string) => void;
   setSnackbarType: (value: AlertColor) => void;
   refresh: boolean;
   fetch?: () => void;
}

function AddLockPartnerModal(props: ActionButtonModalProps) {
   const theme = useTheme();
   const [modalVisible, setModalVisible] = useState(props.isVisible);
   const [isInitialRender, setIsInitialRender] = useState(true);
   const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
   const [lockManufacturer, setLockManufacturer] = useState(0);
   const [lockType, setLockType] = useState(0);
   const [lockNickname, setLockNickname] = useState("");
   const [deviceId, setDeviceId] = useState("");
   const [activationCode, setActivationCode] = useState("");
   const { user } = useUserState();
   const [userIdArray, setUserIdArray] = useState<Partner[]>([]);
   const [partner, setPartner] = useState<string>("");

   const reset = () => {
      setLockManufacturer(0);
      setLockType(0);
      setLockNickname("");
      setDeviceId("");
      setActivationCode("");
   };

   useEffect(() => {
      const getUserIds = () => {
         getLenderPartners().then((res) => {
            if (
               res &&
               res.data &&
               res.data.result &&
               res.data.result.members &&
               res.data.result.members.length > 0
            ) {
               let userIdArray: Partner[] = [];
               res.data.result.members.forEach(function (value: any) {
                  if (value.userId !== undefined && value.userId !== null) {
                     const partnerInstance: Partner = {
                        userId: value.userId.toString(),
                        name:
                           value.userName.trim() !== ""
                              ? value.userName
                              : value.email,
                     };
                     userIdArray.push(partnerInstance);
                  }
               });
               setUserIdArray(userIdArray);
            }
         });
      };
      getUserIds();
   }, [user?.userId]);

   const addLockTransparentModalId = "add-lock-transparent-layer";
   const TransparentLayerTransitionTime = 0.3;
   const ModalTransitionTime = 0.5;
   useEffect(() => {
      if (!isInitialRender) toggle();
      setIsInitialRender(false);
      if (isInitialRender && props.isVisible === true) toggle(true);
   }, [props.isVisible, modalVisible]);

   useEffect(() => {
      if (
         lockManufacturer != null &&
         lockType != null &&
         lockNickname !== "" &&
         deviceId !== "" &&
         (activationCode !== "" || lockManufacturer === 2)
      ) {
         setSaveButtonDisabled(false);
      } else {
         setSaveButtonDisabled(true);
      }
   }, [lockManufacturer, lockType, lockNickname, deviceId, activationCode]);

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggle(mode?: boolean | void) {
      if (mode != null) {
         toggleModal(mode);
         toggleTransparentLayer(mode);
         setModalVisible(mode);
      } else {
         toggleModal();
         toggleTransparentLayer();
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleTransparentLayer(mode: boolean | void) {
      //get transparent layer by id
      let transparentLayer: HTMLElement = document.querySelector(
         "#" + addLockTransparentModalId
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      }
      //manual mode not passed
      if (modalVisible) {
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      } else {
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleModal(mode: boolean | void) {
      let modal: HTMLElement = document.querySelector(
         "#" + props.id
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
         return;
      }

      if (modalVisible) {
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
      } else {
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
      }
   }

   async function validateInput() {
      // Regular expression for matching email addresses
      if (lockManufacturer === 0 || lockType === 0) {
         return false;
      }

      if (lockManufacturer === 1 && activationCode.length === 0) {
         //console.log("inside if")
         return false;
      }

      if (lockNickname.length === 0 || deviceId.length === 0) {
         return false;
      }

      return true;
   }

   const handleDeviceIdInputChange = (
      e: ChangeEvent<HTMLInputElement>,
      master: boolean
   ) => {
      if (master === true) {
         let inputValue = e.target.value.toUpperCase();
         const alphanumericRegex = /^[a-zA-Z0-9]*$/;

         if (alphanumericRegex.test(inputValue)) {
            setDeviceId(inputValue);
         }
      } else {
         let inputValue = e.target.value;
         const alphanumericRegex = /^[a-zA-Z0-9]*$/;

         if (alphanumericRegex.test(inputValue)) {
            setDeviceId(inputValue);
         }
      }

      // You can optionally provide feedback to the user about invalid characters
   };

   const handleActivationCodeInputChange = (
      e: ChangeEvent<HTMLInputElement>
   ) => {
      const inputValue = e.target.value;
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;

      if (alphanumericRegex.test(inputValue)) {
         setActivationCode(inputValue);
      }
      // You can optionally provide feedback to the user about invalid characters
   };

   const useStyles = makeStyles({ name: "AddLockModal" })((theme) => ({
      ModalComponentContainer: {
         overflow: "hidden",
         "@media (max-width: 600px)": {
            display: "none",
         },
      },
      AddLockModal: {
         transition: `right ${ModalTransitionTime}s linear`,
         position: "absolute",
         zIndex: 25,
         backgroundColor: "white",
         //backgroundColor : 'darkgray',
         borderRadius: "20px",
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
         bottom: props.closedPosition.bottom,
         right: props.closedPosition.right,
         padding: "20px",
         minHeight: "450px",
         minWidth: "300px",
         maxWidth: "400px",
      },
      modalHeader: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      modalSubHeader: {
         margin: 0,
         marginBottom: "10px",
         fontWeight: "500",
         fontSize: "12px",
      },
      headerText: {
         margin: "0",
         textAlign: "left",
         width: "98%",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      exitX: {
         marginLeft: "auto",
         fontSize: "25px",
         cursor: "pointer",
         color: theme.palette.common.lightGray,
      },
      UnderlinedButtonContainer: {
         display: "flex",
         height: "55px",
         flexDirection: "column",
         marginRight: "10px",
         marginLeft: "10px",
      },
      ButtonSubLine: {
         width: "90px",
         // height: "1px",
         // maxHeight: "1px",
         // minHeight: "1px",
         // backgroundColor: theme.palette.common.darkGray,
      },
      AddItemRow: {
         display: "flex",
         alignItems: "center",
         marginBottom: "11px",
      },
      AddItemRowText: {
         width: "150px",
         marginRight: "50px",
         marginBottom: "2px",
         marginTop: "2px",
      },
      AddItemRowTextLarge: {
         width: "150px",
         marginRight: "50px",
         marginBottom: "2px",
         marginTop: "20px",
         fontSize: "18px",
      },
      ModalFieldRow: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-start",
      },
      ModalInputBox: {
         display: "flex",
         flexDirection: "column",
         marginBottom: "19px",
         marginRight: "10px",
      },
      ModalInput: {
         fontFamily: theme.fonts.secondary.light,
         border: "none",
         outline: "none",
         width: "100%",
      },
      InputSubLine: {
         width: "100%",
         height: "1px",
         maxHeight: "1px",
         minHeight: "1px",
         backgroundColor: theme.palette.common.darkGray,
      },
      AssignToListingSelector: {
         width: "100px",
         marginLeft: "25px",
      },
      HalfWidthInput: {
         width: "50%",
      },
      FullWidthInput: {
         width: "100%",
      },
      SeventyFiveWidthInput: {
         width: "75%",
      },
      TwentyFiveWidthInput: {
         width: "25%",
      },
      AddItemOuterContainer: {
         marginBottom: "45px",
      },
      AssignToListingText: {
         fontWeight: "100",
         margin: "0",
         marginBottom: "10px",
      },
      AssignToListingButtonOuterContainer: {
         width: "120px",
      },
      SaveSection: {
         marginTop: "75px",
         marginBottom: "10px",
      },
      AssignToListingHeader: {
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      helperText: {
         marginTop: "6px",
         fontSize: "14px",
         width: "100%",
         fontWeight: "bold",
      },
   }));

   let { classes } = useStyles();

   return (
      <>
         {/* Component Container */}
         <section className={classes.ModalComponentContainer}>
            {/* TransparentLayer */}
            <ModalTransparentLayer
               id={addLockTransparentModalId}
               transitionTime={TransparentLayerTransitionTime}
            />
            {/* ModalContainer */}
            <section id={props.id} className={classes.AddLockModal}>
               {/* header row */}
               <div className={classes.modalHeader}>
                  <h2 className={classes.headerText}>Add Lock</h2>
                  <span
                     className={classes.exitX}
                     onClick={() => {
                        toggle(false);
                     }}
                  >
                     &times;
                  </span>
               </div>
               {/* sub header */}
               <p className={classes.modalSubHeader}>
                  Select a lock manufacturer and type. Lock specific fields will
                  generate based on your selection.
               </p>

               {/* Section to contain the add item inputs */}
               <section className={classes.AddItemOuterContainer}>
                  {/* Add manufacturer row */}
                  <section className={classes.AddItemRow}>
                     <h5 className={classes.AddItemRowText}>Manufacturer*</h5>
                     {/* Add Lock Button */}
                     <div className={classes.UnderlinedButtonContainer}>
                        <Select
                           size="small"
                           variant="standard"
                           value={lockManufacturer}
                           // startIcon={<AddIcon />}
                           sx={{
                              marginLeft: "-30px",
                              color: theme.palette.common.darkGray,
                              fontFamily: theme.fonts.secondary.med,
                              fontWeight: "100",
                              textTransform: "none",
                              marginTop: "10px",
                           }}
                           onChange={(e) => {
                              setLockManufacturer(Number(e.target.value));
                           }}
                        >
                           <MenuItem value={1}>MasterLock</MenuItem>
                           <MenuItem value={2}>IglooHome</MenuItem>
                        </Select>
                        <div className={classes.ButtonSubLine}></div>
                     </div>
                  </section>

                  {/* Add type row */}
                  <section className={classes.AddItemRow}>
                     <h5 className={classes.AddItemRowText}>Type*</h5>
                     {/* Add Lock Button */}
                     <div className={classes.UnderlinedButtonContainer}>
                        <Select
                           size="small"
                           variant="standard"
                           // startIcon={<AddIcon />}
                           sx={{
                              marginLeft: "-30px",
                              color: theme.palette.common.darkGray,
                              fontFamily: theme.fonts.secondary.med,
                              fontWeight: "100",
                              textTransform: "none",
                              marginTop: "10px",
                           }}
                           value={lockType}
                           onChange={(e) => {
                              setLockType(Number(e.target.value));
                           }}
                        >
                           <MenuItem value={1}>Lock Box</MenuItem>
                           <MenuItem value={2}>Deadbolt</MenuItem>
                        </Select>
                        <div className={classes.ButtonSubLine}></div>
                     </div>
                  </section>
               </section>

               {/* Field Rows */}
               {/* TO DO LATER -- ASSIGN IDS AND IMPLEMENT LOGIC */}

               {/* Row 1 */}
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <input
                        className={classes.ModalInput}
                        value={lockNickname}
                        type="text"
                        placeholder="Lock Nickname (20 character limit)"
                        maxLength={20}
                        onChange={(e) => {
                           setLockNickname(e.target.value);
                        }}
                     />
                     <div className={classes.InputSubLine}></div>
                  </div>
               </div>

               {/* Row 2 */}
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.HalfWidthInput,
                     ].join(" ")}
                  >
                     <input
                        className={classes.ModalInput}
                        type="text"
                        placeholder="Device ID*"
                        value={deviceId}
                        onChange={(e) => {
                           handleDeviceIdInputChange(
                              e,
                              lockManufacturer === 1 ? true : false
                           );
                        }}
                     />
                     <div className={classes.InputSubLine}></div>
                     {lockManufacturer === 2 ? (
                        <div className={classes.helperText}>
                           *Igloo Lock Device Id is case sensitive.
                        </div>
                     ) : null}
                  </div>
               </div>

               {/* Row 3 */}
               <div className={classes.ModalFieldRow}>
                  {lockManufacturer === 1 && (
                     <div
                        className={[
                           classes.ModalInputBox,
                           classes.HalfWidthInput,
                        ].join(" ")}
                     >
                        <input
                           className={classes.ModalInput}
                           type="text"
                           placeholder="Activation Code"
                           value={activationCode}
                           onChange={handleActivationCodeInputChange}
                        />
                        <div className={classes.InputSubLine}></div>
                     </div>
                  )}
               </div>

               <div className={classes.AddItemRowTextLarge}>
                  Assign to Partner
               </div>
               <span
                  style={{
                     color: theme.palette.common.medGray,
                     fontSize: "12px",
                  }}
               >
                  (optional)
               </span>
               <div className={classes.AssignToListingSelector}>
                  <div className={classes.UnderlinedButtonContainer}>
                     <Select
                        size="small"
                        variant="standard"
                        value={partner}
                        // startIcon={<AddIcon />}
                        sx={{
                           marginLeft: "-30px",
                           color: theme.palette.common.darkGray,
                           fontFamily: theme.fonts.secondary.med,
                           fontWeight: "100",
                           textTransform: "none",
                           marginTop: "10px",
                        }}
                        onChange={(e) => {
                           setPartner(e.target.value as string);
                        }}
                     >
                        {userIdArray.map((item) => (
                           <MenuItem key={item.userId} value={item.userId}>
                              {item.name}
                           </MenuItem>
                        ))}
                     </Select>
                     <div className={classes.ButtonSubLine}></div>
                  </div>
               </div>

               {/* Assign to listing section */}

               {/* Save Section */}
               <section className={classes.SaveSection}>
                  <RoundedButton
                     color={"primary"}
                     listener={async () => {
                        const valid = await validateInput();
                        if (valid) {
                           const res = await addAssociatedLock({
                              propertyId: null,
                              nickName: lockNickname,
                              activationCode: activationCode.toUpperCase(),
                              companyCode: user?.companyId,
                              lockTypeId: lockType.toString(),
                              lockKey: deviceId.toUpperCase(),
                              iglooLockType:
                                 lockManufacturer === 2
                                    ? lockType.toString()
                                    : "",
                              masterLockType:
                                 lockManufacturer === 1
                                    ? lockType.toString()
                                    : "",
                           });
                           if (res && res.data && res.data.status) {
                              if (res.data.status && partner !== "") {
                                 let assignData: LenderAssignLockReq;
                                 assignData = {
                                    lockKey: deviceId.toUpperCase(),
                                    assignTo: parseInt(partner),
                                 };
                                 const res2 = await lenderAssignLock(
                                    assignData
                                 );
                                 //console.log(res2);
                                 if (res2.data.status) {
                                    props.setSnackbarMessage(
                                       `Lock ${lockNickname} created.`
                                    );
                                    props.setSnackbarType("success");
                                    props.setSnackbar(true);
                                    if (props.refresh) {
                                       window.location.reload();
                                    } else {
                                       if (props.fetch) {
                                          reset();
                                          props.fetch();
                                          toggle(false);
                                       } else {
                                          toggle(false);
                                       }
                                    }
                                 }
                              } else {
                                 props.setSnackbarMessage(
                                    `Lock ${lockNickname} created.`
                                 );
                                 props.setSnackbarType("success");
                                 props.setSnackbar(true);
                                 toggle(false);
                                 window.location.reload();
                              }
                           } else {
                              props.setSnackbarMessage(
                                 "Lock creation failed. Please try again later."
                              );
                              props.setSnackbarType("error");
                              props.setSnackbar(true);
                              toggle(false);
                           }
                        }
                     }}
                     showArrow={false}
                     showShadow={false}
                     text={"SAVE LOCK"}
                     type="button"
                     disabled={saveButtonDisabled}
                  />
               </section>
            </section>
         </section>
      </>
   );
}

export default AddLockPartnerModal;
