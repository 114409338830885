import { DirectionsRailwayFilledOutlined } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import mailIcon from "../../styles/assets/logos/mail-icon.svg";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";

function RegisterAssociatedComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [answer, setAnswer] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {}, [props.defaultValue]);

  function setYes() {
    (document.querySelector("#yes") as HTMLBaseElement).style.background =
      "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)";
    (document.querySelector("#yes span") as HTMLBaseElement).style.color =
      "white";

    (document.querySelector("#no") as HTMLBaseElement).style.background =
      "initial";
    (document.querySelector("#no span") as HTMLBaseElement).style.color =
      "initial";

    setAnswer(true);
    setNextButtonDisabled(false);
  }

  function setNo() {
    (document.querySelector("#no") as HTMLBaseElement).style.background =
      "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)";
    (document.querySelector("#no span") as HTMLBaseElement).style.color =
      "white";

    (document.querySelector("#yes") as HTMLBaseElement).style.background =
      "initial";
    (document.querySelector("#yes span") as HTMLBaseElement).style.color =
      "initial";
    setAnswer(false);
    setNextButtonDisabled(false);
  }

  function nextStep() {
    if (props.onSubmitAssociated)
      props.onSubmitAssociated(
        answer,
        props.nextStepOption1,
        props.nextStepOption2
      );
  }

  return (
    <section className={classes.registerAssociatedcontainer}>
      <h2 className={classes.registerAssociatedHeader}>
        Are you associated with a Property Team currently using InstaShow?
      </h2>

      <div className={classes.outerInputBox}>
        <RoundedButton
          id={"yes"}
          listener={setYes}
          boldText={true}
          showShadow={true}
          showArrow={false}
          arrowDirection={"right"}
          color="white"
          text="YES"
        />

        <RoundedButton
          id={"no"}
          listener={setNo}
          boldText={true}
          showShadow={true}
          showArrow={false}
          arrowDirection={"right"}
          color="white"
          text="NO"
        />
      </div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          listener={nextStep}
          disabled={nextButtonDisabled}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterAssociatedComponent" })(
  (theme) => ({
    registerAssociatedcontainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      marginTop: "10%",
      "@media (max-height: 660px)": {
        marginTop: "4%",
      },
    },

    registerAssociatedHeader: {
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      marginBottom: "75px",
      textAlign: "center",
    },
    associatedSubtext: {
      fontSize: "12px",
      fontFamily: theme.fonts.primary.light,
      maxWidth: "310px",
      marginBottom: "20px",
    },
    associatedLink: {
      color: theme.palette.common.primary,
    },
    checkboxcontainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    IAgreeText: {
      margin: 0,
      fontWeight: "bold",
      fontSize: "13px",
      fontFamily: theme.fonts.secondary.bold,
    },
    outerInputBox: {
      height: "70px",
      display: "flex",
      alignItems: "center",
    },
    registerInputBoxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    registerButtonsContainer: {
      marginTop: "120px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px"
    },
    registerMailIcon: {
      height: "10px",
      width: "auto",
      marginRight: "5px",
    },
    registerAssociatedInput: {
      border: "none",
      outline: "none",
      width: "80%",
      height: "20px",
      cursor: "text",
      backgroundColor: "transparent",
    },
    registerDividerLine: {
      height: "1px",
      width: "100%",
      backgroundColor: theme.palette.common.darkGray,
      marginBottom: "100px",
    },
    registerButtonSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
  })
);

export default RegisterAssociatedComponent;
