import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import { LocksDetails } from "./non-enterprise/locks.details";
import { LocksDetailsAdminNonLender } from "./enterprise/non-lender/locks.details.admin.non-lender";
import { LenderLocksDetails } from "./lender/lender.locks.details";

export const LocksViewDetailsSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         if (user?.isEnterpriseSuperUser === 1) {
            return <LocksDetailsAdminNonLender />;
         }
         return <LocksDetails />;
      //// Enterprise Lender
      case 6:
         if (user?.isEnterpriseSuperUser === 1) {
            return <LenderLocksDetails />;
         }
         return <LocksDetails />;

      //// Non-Enterprise
      default:
         return <LocksDetails />;
   }
};
