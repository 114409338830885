import React from "react";
import { Grid, Modal, Box, Button, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Carousel from "nuka-carousel";
import { ReactComponent as InstaShow } from "../../../styles/assets/Icons/instashow.svg";
import { ReactComponent as Time } from "../../../styles/assets/Icons/time.svg";
import TestProperty1 from "../../../styles/assets/images/photo-of-home.jpeg";
import TestProperty2 from "../../../styles/assets/images/test-property-2.jpg";
import { PreviewListingDetails } from "./preview.listing.details";

import { ReactComponent as Close } from "../../../styles/assets/Buttons/close-button-red.svg";
import { ReactComponent as Favorite } from "../../../styles/assets/Buttons/favorite.svg";
import { Photo } from "../create/secondary-tabs/photos.tab";
import { CreatePropertyGeneral } from "../../../contexts/create-listing/create-listing.types";
import {
  favoriteProperty,
  removeFavoriteProperty,
} from "../../../contexts/api/api.functions";
import { FavPropertyReq } from "../../../contexts/api/api.types";
import { ReactComponent as FavoritePink } from "../../../styles/assets/Buttons/favorite-pink.svg";
import { FavoriteOutlined } from "@mui/icons-material";

interface IPreviewModalProps {
  open: boolean;
  onClose: () => void;
  listing: CreatePropertyGeneral;
  photosArray: Array<string>;
  created: boolean;
}

export const ListingPreviewModal: React.FC<IPreviewModalProps> = ({
  open,
  onClose,
  listing,
  photosArray,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [favoriteId, setFavoriteId] = useState(0);

  //console.log(photosArray);

  const handleFavorite = async () => {
    if (favoriteId == 0) {
      let pId = listing.propertyId ?? "";
      let isVisitStatus = false;
      let isFavorite = 1;
      //console.log("pId", pId);
      const data: FavPropertyReq = {
        pId,
        isVisitStatus,
        isFavorite,
      };
      const res = await favoriteProperty(data);
      //console.log("fav res ===> ", res);
      setFavoriteId(res.data.result.favoriteId);
    } else {
      let id = listing.favoriteId;
      //("favorite id", id);
      const res = await removeFavoriteProperty(id);
      //console.log("rem fav res ===> ", res);
      setFavoriteId(0);
    }
  };

  useEffect(() => {
    if (listing.favoriteId !== undefined) {
      setFavoriteId(listing.favoriteId ?? 0);
    }
  }, [listing.favoriteId]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        disableScrollLock
        sx={{ paddingBottom: "100px", overflowY: "scroll" }}
      >
        <Box className={classes.modalContainer}>
          <Grid
            container
            direction="column"
            display={"flex"}
            className={classes.mainContainer}
          >
            <Grid item>
              {/* Carasousel  */}
              {photosArray.length > 0 ? (
                <Carousel
                  afterSlide={(currentIndex) => {
                    setCurrentPhotoIndex(currentIndex);
                  }}
                  defaultControlsConfig={{ pagingDotsStyle: { fill: "white" } }}
                  renderCenterLeftControls={() => null}
                  renderCenterRightControls={() => null}
                  renderTopLeftControls={() => (
                    <IconButton
                      sx={{ position: "relative", top: "30px" }}
                      onClick={onClose}
                    >
                      <Close />
                    </IconButton>
                  )}
                  renderTopRightControls={() => (
                    <IconButton
                      sx={{ position: "relative", top: "30px", right: "5px" }}
                      onClick={handleFavorite}
                    >
                      {favoriteId != 0 ? <FavoritePink /> : <Favorite />}
                    </IconButton>
                  )}
                  renderBottomRightControls={() => (
                    <div
                      style={{
                        backgroundColor: "white",
                        width: "50px",
                        position: "relative",
                        bottom: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {`${currentPhotoIndex + 1}/${photosArray.length}`}
                    </div>
                  )}
                >
                  {photosArray.map((photo, index) => (
                    <img
                      key={index}
                      src={`https://boxlty-media.s3.amazonaws.com/property-images/${photo}`}
                      height="225px"
                      width={"100%"}
                      style={{ objectFit: "fill" }}
                    />
                  ))}
                </Carousel>
              ) : (
                <div style={{ height: "225px" }}></div>
              )}
            </Grid>
            <Grid item className={classes.instashowSection}>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
                gap="10px"
              >
                <Grid item>
                  <Button size="small" variant="contained">
                    See it now
                  </Button>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      color: "white",
                      fontFamily: theme.fonts.secondary.med,
                      fontSize: "16px",
                    }}
                  >{`With Instashow`}</span>
                  <span
                    style={{
                      color: "white",
                      fontFamily: theme.fonts.secondary.med,
                      fontSize: "12px",
                      position: "relative",
                      bottom: "1px",
                    }}
                  >
                    &reg;
                  </span>
                </Grid>
                <Grid item>
                  <InstaShow height={"25px"} width={"25px"} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              display={"flex"}
              sx={{
                backgroundColor: "white",
                borderTopLeftRadius: "25px",
                height: "100%",
              }}
              flex={1}
            >
              <Grid
                container
                direction={"column"}
                display={"flex"}
                height="100%"
                width="100%"
              >
                <Grid item className={classes.scheduleTourSection}>
                  <Button
                    disableElevation
                    size="small"
                    endIcon={<Time />}
                    variant="contained"
                    classes={{
                      root: classes.scheduleButtonReg,
                      disabled: classes.scheduleButtonDisabled,
                    }}
                  >
                    Schedule a Self Tour
                  </Button>
                </Grid>
                <Grid item className={classes.infoSection} flex={1}>
                  <PreviewListingDetails listing={listing} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({})((theme) => ({
  modalContainer: {
    position: "absolute" as "absolute",
    top: "15%",
    left: "40%",
    transform: "translate(-50%,, -50%)",
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // bottom: 0,
    backgroundColor: "background.paper",
    height: 800,
    width: 400,
  },
  mainContainer: {
    backgroundColor: "black",
    // borderTopLeftRadius: '25px',
    // borderTopRightRadius: '25px',
    borderRadius: "25px",
    height: "750px",
    width: "500px",
    overflowY: "scroll",
    display: "flex",
    flexWrap: "nowrap",
  },
  instashowSection: {
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  scheduleTourSection: {
    borderTopLeftRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "white",
  },
  scheduleButtonReg: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.primary.main}`,
    fontFamily: theme.fonts.secondary.med,
    color: "black",
    ":hover": {
      backgroundColor: "white",
      border: `1px solid ${theme.palette.primary.main}`,
      fontFamily: theme.fonts.secondary.med,
      color: "black",
    },
  },
  scheduleButtonDisabled: {},
  infoSection: {
    backgroundColor: "#F5F6F6",
    paddingTop: "20px",
    paddingLeft: "20px",
    display: "flex",
    borderTopLeftRadius: "25px",
    boxShadow: "inset 0px 0px 12px 0px #00000029",
    opacity: 1,
  },
}));
