import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  activateProperty,
  deleteProperty,
  makePropertyDraft,
} from "../../../../contexts/api/api.functions";
import {
  useSingleListingDispatch,
  useSingleListingState,
  SingleListingActions,
} from "../../../../contexts/single-listing/singleListing.context";
import { ReactHookFormSelect } from "../../../common/react.hook.form.select";
import { AccessExitForm } from "../../create/forms/access.exit.instructions.form";
import { HeaderButton } from "../../create/forms/header.button";
import { ListingDetailsForm } from "../../create/forms/listing.details.form";
import { PropertyDetailsForm } from "../../create/forms/property.details.form";
import { useUserState } from "../../../../contexts/user/user.context";
export const CreatedListingGeneralTab: React.FC<{
  setActive: (value: boolean) => void;
}> = ({ setActive }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { user } = useUserState();
  const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useSingleListingDispatch();
  const { listingType } = useSingleListingState();
  const contextNickname = watch("nickName");

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch({ type: SingleListingActions.setFeature, payload: { file: "" } });
    dispatch({ type: SingleListingActions.setPhotos, payload: [] });
  }, []);

  useEffect(() => {
    dispatch({ type: SingleListingActions.setFeaturePhotoPath, payload: null });
    dispatch({ type: SingleListingActions.setPhotoPaths, payload: null });
  }, []);

  const handleDelete = async () => {
    if (ListingIdParam === undefined) {
      return;
    } else {
      const res = await deleteProperty({ pId: parseInt(ListingIdParam) })
        .then(() => {
          navigate("/listings/active");
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  };

  const handleArchive = async () => {
    if (ListingIdParam === undefined) {
      return;
    } else {
      if (!listingType || listingType === "") {
        return;
      }
      if (listingType === "archive") {
        handleMenuClose();
        return;
      }
      const res = await activateProperty({
        pId: parseInt(ListingIdParam),
        status: 0,
      })
        .then(() => {
          navigate("/listings");
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const handleDraft = async () => {
    if (ListingIdParam === undefined) {
      return;
    } else {
      if (!listingType || listingType === "") {
        return;
      }
      if (listingType === "draft") {
        handleMenuClose();
        return;
      }
      const res = await makePropertyDraft({ pId: parseInt(ListingIdParam) })
        .then(() => {
          navigate("/listings");
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.mainContainer}
        direction="column"
        display={"flex"}
        flexWrap="nowrap"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            justifyContent="space-between"
          >
            <Grid item>
              <HeaderButton
                color="black"
                backgroundColor="white"
                borderColor="#707070"
                text={listingType !== "" ? listingType : "draft"}
                clickable={false}
              />
            </Grid>
            <Grid item sx={{ paddingRight: "5px" }}>
              <IconButton
                edge="start"
                id="type-selection-button"
                aria-controls={open ? "type-selection-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreHorizIcon fontSize="large" />
              </IconButton>
              <Menu
                id="type-selection-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{ "aria-labelledby": "type-selection-button" }}
                sx={{ postion: "relative", right: "60px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: 95,
                }}
              >
                <MenuItem onClick={handleDraft}>Draft</MenuItem>
                {user?.idVerify === 1 ? (
                  <MenuItem onClick={handleArchive}>Archive</MenuItem>
                ) : null}
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              height: "700px",
              flexWrap: "nowrap",
              overflowY: "scroll",
            }}
          >
            <Grid item sx={{ marginBottom: "10px", width: "85%" }}>
              <div className={classes.inputHeader}>Property Nickname*</div>
              <TextField
                {...register("nickName")}
                className={errors.nickName ? classes.errorBorder : ""}
                fullWidth
                onKeyUp={(e) => {
                  dispatch({
                    type: SingleListingActions.setNickName,
                    payload: contextNickname,
                  });
                  setActive(true);
                }}
                sx={{ input: { "&::placeholder": { opacity: 1 } } }}
                inputProps={{
                  style: {
                    paddingLeft: "5px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "18px",
                    color: "#707070",
                    opacity: "1 ",
                  },
                  maxLength: 30,
                }}
                placeholder="Property Nickname"
                variant="standard"
                classes={{ root: classes.filledInput }}
                InputProps={{ disableUnderline: false }}
                size="medium"
              />
              {errors.nickName && (
                <p className={classes.errorText}>
                  {String(errors.nickName.message)}
                </p>
              )}
            </Grid>
            <Grid item>
              <div className={classes.inputHeader}>Listing Type*</div>
              <ReactHookFormSelect
                control={control}
                name="propertyCreateTypeId"
                disableUnderline={true}
                variant="standard"
              >
                <MenuItem value="rental">Rental</MenuItem>
                <MenuItem value="sale">For Sale</MenuItem>
              </ReactHookFormSelect>
            </Grid>
            <Divider
              sx={{
                borderBottomWidth: "2.5px",
                marginTop: "10px",
                width: "85%",
              }}
            />
            <Grid item>
              {/* Listing Details Form */}
              <ListingDetailsForm setActive={setActive}/>
            </Grid>
            <Divider
              sx={{
                borderBottomWidth: "2.5px",
                marginTop: "10px",
                width: "85%",
              }}
            />
            <Grid item>
              {/* Property Details Form */}
              <PropertyDetailsForm created={false} />
            </Grid>
            <Divider
              sx={{
                borderBottomWidth: "2.5px",
                marginTop: "10px",
                width: "85%",
              }}
            />
            <Grid item sx={{ paddingBottom: "15px" }}>
              {/* Access/Exit Instructions */}
              <AccessExitForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "General-Tab" })((theme) => ({
  mainContainer: {
    paddingTop: "20px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "30px",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
  },
  filledInput: {
    background: "#EAEAEA",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  errorBorder: {
    border: "2px solid red",
  },
}));
