import react from 'react';
import { useEffect, useState} from 'react';
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material';
import { useStaticPicker } from '@mui/x-date-pickers/internals';


interface ModalTransparentLayerProps{
    id : string,
    transitionTime : number | 1
}

function ModalTransparentLayer(props: ModalTransparentLayerProps) {
    const theme = useTheme();

    useEffect(() => {

    }, [])

    let useStyles = makeStyles({ name: 'ModalTransparentLayer' })((theme) => ({
        TransparentLayer:{
            position : 'absolute',
            left : 0,
            top : 0,
            display : 'none',
            opacity : 1,
            zIndex : 20,
            backgroundColor : 'rgba(0,0,0,0.5)',
            transition : `opacity ${props.transitionTime}s linear`,
            height : '100vh',
            width : '100vw'
        }
    }));

    const { classes } = useStyles();

    return (<>
    {/* A Transparent layer with fade  */}
    <section id={props.id} className={classes.TransparentLayer}></section>
    </>);




}


export default ModalTransparentLayer;

