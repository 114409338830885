import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as RentIcon } from "../../styles/assets/Icons/rent-icon.svg";
import { ReactComponent as ManageIcon } from "../../styles/assets/Icons/manage-icon.svg";
import { ReactComponent as AppStore } from "../../styles/assets/Icons/app-store-full.svg";
import { ReactComponent as PlayStore } from "../../styles/assets/Icons/google-play-full.svg";
import { getStoredItem } from "../../contexts/common/localstorage";
import { StorageKeys } from "../../contexts/common/storeapp.types";
import { getUserProfile } from "../../contexts/api/api.functions";
import { UserActions, useUserDispatch } from "../../contexts/user/user.context";

function RegisterWelcomeInstaShowComponent(props: any) {
   const { classes } = useStyles();
   const dispatch = useUserDispatch();

   useEffect(() => {
      getFullUser();
      props.submitAdditionalData();
   }, [props.defaultValue]);

   const getFullUser = async () => {
      if (
         getStoredItem(StorageKeys.access_token) &&
         getStoredItem(StorageKeys.userId)
      ) {
         const userId = getStoredItem(StorageKeys.userId);
         if (userId) {
            const res = await getUserProfile({ userId: userId });
            if (res && res.data && res.data.status === true) {
               dispatch({ type: UserActions.user, payload: res.data.result });
               // console.log("full user loaded");
               // console.log("getFullUserCall");
            }
         }
      }
   };

   const handleClick = (url: string) => {
      window.open(url, "_blank");
   };

   if (props.whyUseBoxlty === "buy") {
      return (
         <div className={classes.BodyContainer}>
            <Grid
               container
               direction={"column"}
               justifyContent="center"
               alignItems="center"
            >
               <RentIcon className={classes.RentIcon} />
               <h1 className={classes.TitleText}>Welcome to InstaShow!</h1>
               <h3 className={classes.DetailsText}>
                  Download and log in to the mobile app
                  <br />
                  to start showing listings
               </h3>
               <Grid container direction={"row"} justifyContent="center">
                  <AppStore
                     className={classes.StoreIcon}
                     onClick={() => {
                        handleClick(
                           "https://apps.apple.com/us/app/boxlty-self-tours/id1480841099"
                        );
                     }}
                  />
                  <PlayStore
                     className={classes.StoreIcon}
                     onClick={() => {
                        handleClick(
                           "https://play.google.com/store/apps/details?id=com.boxlty.ta"
                        );
                     }}
                  />
               </Grid>
               {/* <p className={classes.orText}>OR</p>
          <button
            className={classes.RoundedButton}
            onClick={() => {
              props.setRegistrationStep(props.nextStepOption1);
            }}
          >
            <div className={classes.InnerButton}>
              <span className={classes.ButtonText}>CONTINUE TO DESKTOP</span>
            </div>
          </button> */}
               <p className={classes.DownloadText}>
                  Download the mobile app to login and complete your
                  verification. Show and share properties
                  <br />
                  with the most secure Self-Touring app on the market!
               </p>
            </Grid>
         </div>
      );
   } else {
      return (
         <div className={classes.BodyContainer}>
            <Grid
               container
               direction={"column"}
               justifyContent="center"
               alignItems="center"
            >
               <ManageIcon className={classes.RentIcon} />
               <h1 className={classes.TitleText}>Welcome to InstaShow!</h1>
               <h3 className={classes.DetailsText}>
                  To complete your account setup,
                  <br />
                  download the InstaShow mobile app
               </h3>
               <Grid container direction={"row"} justifyContent="center">
                  <AppStore
                     className={classes.StoreIcon}
                     onClick={() => {
                        handleClick(
                           "https://apps.apple.com/us/app/boxlty-self-tours/id1480841099"
                        );
                     }}
                  />
                  <PlayStore
                     className={classes.StoreIcon}
                     onClick={() => {
                        handleClick(
                           "https://play.google.com/store/apps/details?id=com.boxlty.ta"
                        );
                     }}
                  />
               </Grid>
               <p className={classes.orText}>OR</p>
               <button
                  className={classes.RoundedButton}
                  onClick={() => {
                     props.setRegistrationStep(props.nextStepOption1);
                  }}
               >
                  <div className={classes.InnerButton}>
                     <span className={classes.ButtonText}>
                        CONTINUE TO DESKTOP
                     </span>
                  </div>
               </button>
               {/* <p className={classes.DownloadText}>
          Download the mobile app to login and complete your verification. Show
          and share properties
          <br />
          with the most secure Self-Touring app on the market!
        </p> */}
            </Grid>
         </div>
      );
   }
}

const useStyles = makeStyles({ name: "RegisterWelcomeInstaShowComponent" })(
   (theme) => ({
      BodyContainer: {
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         flexDirection: "column",
         marginTop: "10%",
         "@media (max-height: 660px)": {
            marginTop: "4%",
         },
      },
      RentIcon: {
         height: "70px",
         width: "auto",
      },
      TitleText: {
         alignSelf: "center",
         fontSize: "2.3rem",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
         textAlign: "center",
      },
      DetailsText: {
         alignSelf: "center",
         textAlign: "center",
         fontSize: "1.3rem",
         color: "#D63F94",
      },
      StoreIcon: {
         height: "45px",
         width: "auto",
         cursor: "pointer",
         margin: "10px",
         // overflow: "visible",
      },
      DownloadText: {
         marginTop: "4rem",
         alignSelf: "center",
         textAlign: "center",
         fontSize: ".85rem",
         color: "#707070",
      },
      orText: {
         marginTop: "1.5rem",
         alignSelf: "center",
         textAlign: "center",
         fontSize: "1.7rem",
         color: "black",
         fontWeight: "500",
      },
      RoundedButton: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         border: "1px solid",
         borderColor: theme.palette.primary.main,
         background:
            "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)",
         borderRadius: "10px",
         padding: "1px",
         width: "18rem",
         cursor: "pointer",
         height: "46px",
         color: "white",
         transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
         marginBottom: "0.4rem",
      },
      InnerButton: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         paddingLeft: "11px",
         paddingRight: "11px",
      },
      ButtonText: {
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
         fontSize: "19px",
         letterSpacing: "1px",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
   })
);

export default RegisterWelcomeInstaShowComponent;
