import { WidthFull } from '@mui/icons-material';
import React from 'react'
import { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@mui/material'
export const PreviewImage: React.FC<{ height: string, file: any, width: string, clearImage: () => void, showMenu: boolean }> = ({ width, height, file, clearImage, showMenu }) => {
    const { classes } = useStyles()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div style={{ height: height, width: width }} className={classes.mainContainer}>

            <img className={classes.image} height={'100%'} width={'100%'} style={{ overflow: 'hidden', objectFit: 'cover' }} src={file} />
            {showMenu ? (
                <>
                    <span className={classes.overlay} id="preview" onClick={handleClick} aria-controls={open ? 'preview-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}>
                        <MoreHorizIcon style={{ color: 'white' }} />
                    </span>
                    <Menu open={open} onClose={handleClose} anchorEl={anchorEl} id="preview-menu" MenuListProps={{
                        'aria-labelledby': 'preview'
                    }} anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}>
                        
                        <MenuItem onClick={() => {
                            clearImage();
                            setAnchorEl(null)
                        }}>
                            Delete
                        </MenuItem>
                    </Menu>
                </>) : null}
        </div>
    )
}


const useStyles = makeStyles({ name: "Preview-Image-Component" })((theme) => ({
    mainContainer: {
        position: 'relative',
    },
    image: {
        display: 'block',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        cursor: 'pointer',
    }
}))


