import React from "react";
import AppBody from "../../../components/body/main.body.component.jsx";
import { CircularProgress, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import TabbedContainer from "../../../components/common/boxlty.tabbedcontainer";

import { ReactComponent as ListingsIconActive } from "../../../styles/assets/logos/boxlty-logo-symbol.svg";
import { ReactComponent as ListingsIconInActive } from "../../../styles/assets/logos/boxlty-logo-symbol-inactive.svg";
import TourDetailsInfo from "../../../components/tours/tours.details.info";
import {
   useToursDispatch,
   ToursActions,
} from "../../../contexts/tours/tours.context";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTourDetails } from "../../../contexts/api/api.functions";
import { TourDetail } from "../non-enterprise/tour.types";
import { format } from "date-fns";
import RoundedButtonTours from "../../../components/common/boxlty.button.tours";

interface option {
   text: string;
   listener: () => void;
}

interface TourDetailsMenuProps {
   options: option[];
}

interface TourDetailsPageProps {
   listingName?: string;
   status?: string;
   listingImgUrl?: string;
   tourDate?: Date;
   tourStartTime?: Date;
   tourEndTime?: Date;
   address?: string;
   visitorImgUrl?: string;
   visitorName?: string;
   visitorEmail?: string;
   visitorPhone?: string;
   scheduledDate?: Date;
   rescheduledDate?: Date;
}

function LenderTourDetailsPage(props: TourDetailsPageProps) {
   const { classes } = useStyles();
   const [loadingTour, setLoadingTour] = useState(true);
   const { TourID } = useParams<{ TourID: string }>();
   const [tourDetail, setTourDetail] = useState<TourDetail | null>(null);
   const [propertyDetailGeneral, setPropertyDetailGeneral] = useState<
      any | null
   >(null);
   const dispatch = useToursDispatch();

   const fetchTourDetails = async () => {
      if (TourID) {
         const res = await getTourDetails(TourID);
         if (res && res.data && res.data.status === true) {
            // console.log(res.data);
            setTourDetail(res.data.result);
            dispatch({
               type: ToursActions.setToursDetailsPropertyId,
               payload: res.data.result.propertyId,
            });
            dispatch({
               type: ToursActions.setTourDetailsCompanyName,
               payload: res.data.result.propertyOwnerCompanyName,
            });
            setLoadingTour(false);
         }
      }
   };

   useEffect(() => {
      fetchTourDetails();
   }, [TourID]);

   function returnStatusString(status: string) {
      switch (status) {
         case "2":
            return "SCHEDULED";
         case "1":
            return "PENDING RESCHEDULE";
         case "5":
            return "IN-PROGRESS";
         case "4":
            return "CANCELLED";
         case "8":
            return "RESCHEDULED";
         case "6":
            return "COMPLETED";
         case "3":
            return "DECLINED";
         case "7":
            return "SELFIE IN REVIEW";
         default:
            return "";
      }
   }

   function returnStatusColor(status: string) {
      switch (status) {
         case "2":
            return "#7CE7F5";
         case "1":
            return "#E2BA61";
         case "5":
            return "#64E29A";
         case "4":
            return "#EC394B";
         case "8":
            return "#707070";
         case "6":
            return "#505050";
         case "3":
            return "#707070";
         case "7":
            return "#707070";
         default:
            return "#707070";
      }
   }

   function returnStandardTimeString(time: string) {
      let timeArray = time.split(":"); // convert to array

      // fetch
      var hours = Number(timeArray[0]);
      var minutes = Number(timeArray[1]);

      // calculate
      var timeValue;

      if (hours > 0 && hours <= 12) {
         timeValue = "" + hours;
      } else if (hours > 12) {
         timeValue = "" + (hours - 12);
      } else if (hours === 0) {
         timeValue = "12";
      }

      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

      return timeValue;
   }

   function formatTourDate(dateString: string) {
      const date = new Date(`${dateString}T00:00:00`);
      let days = [
         "Monday",
         "Tuesday",
         "Wednesday",
         "Thursday",
         "Friday",
         "Saturday",
         "Sunday",
      ];
      let months = [
         "January",
         "Febuary",
         "March",
         "April",
         "May",
         "June",
         "July",
         "August",
         "September",
         "October",
         "November",
         "December",
      ];

      let DayOfWeek = days[date.getDay() - 1];
      let Month = months[date.getMonth()];
      let DateVal = date.getDate();

      return DayOfWeek + ", " + Month + " " + DateVal;
   }

   return (
      <>
         <AppBody>
            <div className={classes.PageContainer}>
               {/* Header */}
               <h1 className={classes.PageHeader}>
                  {/* {props.listingName ? props.listingName : "Family Home"} */}
                  {loadingTour
                     ? ""
                     : tourDetail?.propertyName
                     ? tourDetail?.propertyName
                     : ""}
               </h1>

               {/* Split Container */}
               <section className={classes.SplitContainer}>
                  {/* Main Section */}
                  <section className={classes.MainSection}>
                     {/* Top Row */}
                     {loadingTour ? (
                        <CircularProgress />
                     ) : (
                        <React.Fragment>
                           <section className={classes.MainSectionTopRow}>
                              {/* Reschedule Pending ui */}
                              <RoundedButtonTours
                                 disabled={true}
                                 text={
                                    tourDetail?.bookingStatusIdNew
                                       ? returnStatusString(
                                            tourDetail?.bookingStatusIdNew
                                         )
                                       : ""
                                 }
                                 color={
                                    tourDetail?.bookingStatusIdNew
                                       ? returnStatusColor(
                                            tourDetail?.bookingStatusIdNew
                                         )
                                       : "#EC394B"
                                 }
                                 showArrow={false}
                                 showShadow={false}
                                 marginLeft="0px"
                                 marginRight="0px"
                              />
                              {/* <TourDetailsTopMenu
                    options={[
                      { text: "Request Reschedule", listener: listener1 },
                      { text: "Cancel Tour", listener: listener2 },
                    ]}
                  /> */}
                           </section>

                           {/* Main Tour info section */}
                           <section className={classes.MainInfoSection}>
                              {/* Tour IMG */}
                              <img
                                 className={classes.TourImage}
                                 src={`https://boxlty-media.s3.amazonaws.com/property-images/${tourDetail?.propertyFeaturePhoto}`}
                              />
                              {/* Tour Info */}
                              <section>
                                 {/* date */}
                                 <h3 className={classes.TourInfoText1}>
                                    {tourDetail?.bookingFormattedDate
                                       ? formatTourDate(
                                            tourDetail.bookingFormattedDate
                                         )
                                       : ""}
                                 </h3>
                                 {/* time */}
                                 <p className={classes.TourInfoText2}>
                                    {tourDetail?.startTime && tourDetail.endTime
                                       ? returnStandardTimeString(
                                            tourDetail.startTime
                                         ) +
                                         " - " +
                                         returnStandardTimeString(
                                            tourDetail.endTime
                                         )
                                       : ""}
                                 </p>
                                 {/* address */}
                                 <p className={classes.TourInfoText3}>
                                    {tourDetail?.propertyAddress
                                       ? tourDetail?.propertyAddress
                                       : ""}
                                 </p>
                                 <p className={classes.TourInfoText3}>
                                    {tourDetail?.propertyCity &&
                                    tourDetail?.propertyState &&
                                    tourDetail?.propertyZipCode
                                       ? `${tourDetail?.propertyCity}, ${tourDetail?.propertyState} ${tourDetail?.propertyZipCode}`
                                       : ""}
                                 </p>
                              </section>
                           </section>

                           {/* Visitor Information Section */}
                           <section className={classes.VisitorSectionContainer}>
                              <h2 className={classes.VisitorSectionText}>
                                 Visitor Information
                              </h2>
                              <div
                                 className={classes.VisitorSectionDividerLine}
                              ></div>

                              {/* Visitor Info */}
                              <section className={classes.VisitorInfoSection}>
                                 {/* Visitor IMG */}
                                 <img
                                    className={classes.VisitorImage}
                                    src={`https://boxlty-media.s3.amazonaws.com/user-profile/${tourDetail?.visitorProfile}`}
                                 />
                                 {/* Tour Info */}
                                 <section>
                                    {/* Name */}
                                    <h3 className={classes.VisitorInfoText1}>
                                       {tourDetail?.visitorFirtName &&
                                       tourDetail?.visitorLastName
                                          ? `${tourDetail.visitorFirtName} ${tourDetail.visitorLastName}`
                                          : ""}
                                    </h3>
                                    {/* Email */}
                                    <p
                                       className={classes.VisitorInfoFieldLabel}
                                    >
                                       EMAIL
                                    </p>
                                    <p className={classes.VisitorInfoText2}>
                                       {tourDetail?.visitorEmail
                                          ? tourDetail?.visitorEmail
                                          : ""}
                                    </p>
                                    {/* Phone */}
                                    <p
                                       className={classes.VisitorInfoFieldLabel}
                                    >
                                       PHONE
                                    </p>
                                    <p className={classes.VisitorInfoText3}>
                                       {tourDetail?.visitorMobile
                                          ? tourDetail?.visitorMobile
                                          : ""}
                                    </p>
                                 </section>
                              </section>
                              {/* PRE approval section */}

                              <p className={classes.PreApprovalGayText}>
                                 PRE-APPROVAL DOCUMENTS FOR DOWNLOAD
                              </p>
                              <a
                                 className={classes.PreApprovalLink}
                                 href={`https://boxlty-media.s3.amazonaws.com/user-documents/${tourDetail?.visitorDoc}`}
                                 download={true}
                              >
                                 Pre-approval-doc.pdf
                              </a>

                              {/* format(new Date(`${params.row.bookingd}T00:00:00`), 'P') */}
                              {/* Scheduled section */}
                              <div className={classes.MiniDivider}></div>
                              <p className={classes.ScheduledText}>
                                 Scheduled on{" "}
                                 {tourDetail?.bookingScheduledDate
                                    ? format(
                                         new Date(
                                            `${tourDetail.bookingScheduledDate}T00:00:00`
                                         ),
                                         "P"
                                      )
                                    : ""}
                              </p>
                              {tourDetail?.isBookingRescheduled === 1 ? (
                                 <p className={classes.ScheduledText}>
                                    Rescheduled on{" "}
                                    {tourDetail?.bookingFormattedDate
                                       ? format(
                                            new Date(
                                               `${tourDetail.bookingFormattedDate}T00:00:00`
                                            ),
                                            "P"
                                         )
                                       : new Date().toLocaleDateString()}
                                 </p>
                              ) : null}
                           </section>
                        </React.Fragment>
                     )}
                  </section>
                  {/* Chat / Listing Details Section */}
                  <section className={classes.SecondarySection}>
                     {/* Component to house those two sections */}
                     <TabbedContainer
                        tabs={[
                           {
                              tabName: "Listing",
                              tabIconActive: <ListingsIconActive />,
                              tabIconInActive: <ListingsIconInActive />,
                              tabComponent: <TourDetailsInfo />,
                           },
                        ]}
                        leftBorderRadius={"20px"}
                        showBoxShadow={true}
                        startingTabIndex={0}
                     />
                  </section>
               </section>
            </div>
         </AppBody>
      </>
   );
}

const useStyles = makeStyles({ name: "ListingsMain" })((theme) => ({
   PageContainer: {
      display: "flex",
      flexDirection: "column",
   },
   PageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginTop: "0px",
      marginBottom: "5px",
      height: "41.5px",
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
   },
   MainSection: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "70%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   MainSectionTopRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "45px",
   },
   MainInfoSection: {
      display: "flex",
      alignItems: "flex-start",
   },
   TourImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   TourInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "5px",
      fontSize: "17px",
      fontFamily: theme.fonts.secondary.bold,
   },
   TourInfoText2: {
      margin: "0",
      fontWeight: "500",
      fontSize: "14px",
      marginBottom: "5px",
   },
   TourInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorSectionContainer: {
      marginTop: "25px",
   },
   VisitorSectionText: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "22px",
      marginBottom: "5px",
   },
   VisitorSectionDividerLine: {
      width: "250px",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
   },
   VisitorInfoSection: {
      marginTop: "20px",
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "20px",
   },
   VisitorImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   VisitorInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "5px",
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "17px",
   },
   VisitorInfoText2: {
      margin: "0",
      fontWeight: "500",
      marginBottom: "5px",
      fontSize: "17px",
   },
   VisitorInfoText3: {
      fontWeight: "500",
      margin: "0",
      fontSize: "17px",
   },
   VisitorInfoFieldLabel: {
      fontSize: "14px",
      color: theme.palette.common.lightGray,
      margin: "0",
      marginTop: "10px",
      marginBottom: "3px",
   },
   PreApprovalGayText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "5px",
      fontSize: "12px",
   },
   PreApprovalLink: {
      display: "block",
      color: theme.palette.common.primary,
      fontFamily: theme.fonts.secondary.med,
      fontSize: "13px",
      textDecoration: "underline",
      marginBottom: "50px",
   },
   MiniDivider: {
      height: "2px",
      minHeight: "2px",
      // boxShadow : `0px 0px 10px 2px ${theme.palette.common.lightGray}`,
      backgroundColor: theme.palette.common.lightGray,
      width: "35px",
      marginTop: "100px",
      marginBottom: "15px",
   },
   ScheduledText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "1px",
      fontSize: "13px",
   },
   SecondarySection: {
      width: "35%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "auto",
   },
}));

export default LenderTourDetailsPage;
