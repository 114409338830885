import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import AppBody from "../../components/body/main.body.component";

//status = buy or sell
//plan 1 = plus, 2 = business tier 1, 3 =  business tier 2
//isMonthly bool
interface StripeCheckoutSessionProps {
   status: string;
   plan: number;
   isMonthly: boolean;
   upgrade?: boolean;
   submitAdditionalData?: (isFree: boolean) => Promise<any>;
}

function StripeCheckoutSession(props: StripeCheckoutSessionProps) {
   const { classes } = useStyles();
   const ENV = process.env.REACT_APP_ENV;

   useEffect(() => {
      const fetchData = async () => {
         if (props.plan === 1) {
            if (props.submitAdditionalData) {
               await props.submitAdditionalData(true).then((res) => {
                  if (props.status === "buy") {
                     window.location.href = "/welcomeinstashow?status=buy";
                  } else {
                     window.location.href = "/welcomeinstashow?status=sell";
                  }
               });
            }
         } else if (props.plan !== 1 && props.plan !== 0) {
            if (props.submitAdditionalData) {
               try {
                  await props.submitAdditionalData(false);
               } catch (error) {
                  console.error(error);
               }
            }
            await GetCheckoutSession();
         } else {
            return;
         }
      };

      // const determineFree = async () => {
      //    if (props.plan === 1) {
      //       if (props.submitAdditionalData) {
      //          await props.submitAdditionalData(true).then((res) => {
      //             if (props.status === "buy") {
      //                window.location.href = "/welcomeinstashow?status=buy";
      //             } else {
      //                window.location.href = "/welcomeinstashow?status=sell";
      //             }
      //          });
      //       }
      //    }
      // };
      //
      //determinFree()
      fetchData();
   }, []);

   function GetCheckoutSession() {
      let request = new XMLHttpRequest();
      request.open(
         "POST",
         ENV === "production"
            ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/subscribe-to-instashow-plan`
            : ENV === "staging"
            ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/subscribe-to-instashow-plan`
            : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/subscribe-to-instashow-plan`
      );
      const token =
         (sessionStorage.getItem("access_token")
            ? sessionStorage.getItem("access_token")
            : localStorage.getItem("access_token")) ?? "";
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET_DEV;
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("access_token", token);
      request.setRequestHeader("is_website", "true");
      request.setRequestHeader("Access-Control-Allow-Origin", "*");
      if (clientSecret != null)
         request.setRequestHeader("client_secret", clientSecret);
      //sample payload
      request.send(
         JSON.stringify({
            status: props.status,
            plan: props.plan,
            billing_frequency: props.isMonthly
               ? "billed_monthly"
               : "billed_annually",
            upgrade: props.upgrade ?? false,
         })
      );
      request.onload = () => {
         let response = JSON.parse(request.response);
         if (response.success) {
            window.location.href = response.redirect_url;
         } else {
            // console.log("Failed!");
         }
      };
   }

   return (
      <>
         <AppBody>
            <section className={classes.registerCheckoutContainer}>
               <h2 className={classes.registerCheckoutHeader}>
                  Checkout in Progress
               </h2>
            </section>
         </AppBody>
      </>
   );
}

const useStyles = makeStyles({ name: "StripeCheckoutSession" })((theme) => ({
   registerCheckoutContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      marginTop: "10%",
      "@media (max-height: 660px)": {
         marginTop: "4%",
      },
   },
   registerCheckoutHeader: {
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      marginBottom: "75px",
   },
}));

export default StripeCheckoutSession;
