import React from "react";
import { Grid, IconButton, Button, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AvailabilityTabTopForm } from "../forms/availability.tab.top.form";
import { CustomAvailabilityForm } from "../forms/custom.availability.form";
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdvancedNoticeForm } from "../forms/advanced.notice.form";
import { HeaderButton } from "../forms/header.button";
import { useFormContext } from "react-hook-form";
import {
  CreateListingActions,
  useCreateListingDispatch,
  useCreateListingState,
} from "../../../../contexts/create-listing/create-listing.context";

type Props = {};

export interface TimeSlot {
  start: number;
  end: number;
}

export const ListingsAvailabilityTab: React.FC<{}> = (props: Props) => {
  const { classes } = useStyles();
  const [start, setStart] = useState<Dayjs | null>(
    dayjs(new Date()).set("hour", 8).minute(0).second(0)
  );
  const [end, setEnd] = useState<Dayjs | null>(
    dayjs(new Date()).set("hour", 20).minute(0).second(0)
  );
  const [intervalSet, setIntervalSet] = useState(false);
  const { setValue, watch } = useFormContext();
  const [difference, setDifference] = useState(0);
  const [timeSlotArr, setTimeSlotArr] = useState<TimeSlot[]>([]);
  //const contextTimeInterval = watch("timeInterval");
  const contextUnavailableDays = watch("unavailableDates");
  const { availabilityStatus } = useCreateListingState();
  const dispatch = useCreateListingDispatch();
  const setStartTime = (value: any) => {
    setStart(value);
  };

  const setEndTime = (value: any) => {
    setEnd(value);
  };

  useEffect(() => {
    dispatch({ type: CreateListingActions.setFeature, payload: { file: "" } });
    dispatch({ type: CreateListingActions.setPhotos, payload: [] });
  }, []);

  useEffect(() => {
    dispatch({ type: CreateListingActions.setFeaturePhotoPath, payload: null });
    dispatch({ type: CreateListingActions.setPhotoPaths, payload: [] });
  }, []);

  const calculateHours = () => {
    if (!start || !end) {
      return;
    }
    let startHour = dayjs(start).get("hour");
    let endHour = dayjs(end).get("hour");
    let timeSlots: TimeSlot[] = [];
    for (var i = startHour; i < endHour; i++) {
      const timeSlot: TimeSlot = { start: i, end: i + 1 };
      timeSlots.push(timeSlot);
    }
    setTimeSlotArr([...timeSlots]);
  };

  useEffect(() => {
    calculateHours();
  }, [start, end]);

  useEffect(() => {
    if (
      availabilityStatus &&
      availabilityStatus.timeInterval.length >= 2 &&
      !intervalSet
    ) {
      let start = dayjs(new Date())
        .set("hour", 7 + availabilityStatus.timeInterval[0])
        .minute(0)
        .second(0);
      setStartTime(start);
      let end = dayjs(new Date())
        .set(
          "hour",
          8 +
            availabilityStatus.timeInterval[
              availabilityStatus.timeInterval.length - 1
            ]
        )
        .minute(0)
        .second(0);
      setEndTime(end);
      setIntervalSet(true);
    }
  }, [availabilityStatus, intervalSet]);

  return (
    <>
      <Grid container direction="column" className={classes.mainContainer}>
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            justifyContent="space-between"
            // Only need this margin if menu icon button is hidden
            sx={{ marginBottom: "20px" }}
          >
            <Grid item>
              <HeaderButton
                color="black"
                backgroundColor="white"
                borderColor="#707070"
                text="DRAFT"
                clickable={false}
              />
            </Grid>
            {/* Removed in create listing, still in edit listing */}
            {/* <Grid item>
              <IconButton edge="start" disabled>
                <MoreHorizIcon fontSize="large" />
              </IconButton>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item>
          {/* Availability Tab Top Form */}
          <AvailabilityTabTopForm
            setStart={setStartTime}
            setEnd={setEndTime}
            start={start}
            end={end}
          />
        </Grid>
        <Grid item sx={{ marginTop: "10px" }}>
          <Divider sx={{ borderBottomWidth: "2px" }} />
        </Grid>
        <Grid item sx={{ marginTop: "15px" }}>
          {/* Custom Availability Form */}
          <CustomAvailabilityForm timeSlots={timeSlotArr} />
        </Grid>
        <Grid item sx={{ position: "relative", bottom: "25px" }}>
          <Divider sx={{ borderBottomWidth: "2px" }} />
        </Grid>
        <Grid item>
          <AdvancedNoticeForm />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Availability-Tab" })((theme) => ({
  mainContainer: {
    paddingTop: "20px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "15px",
    overflowY: "auto",
    flexWrap: "nowrap",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
  },
}));
