import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import { TeamsMain } from "./non-enterprise/teams.main";
import { TeamsPrimaryManagerAdminMain } from "./super-admin/primary-manager/teams.pm.admin.main";

export const TeamViewMainSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         return <TeamsPrimaryManagerAdminMain />;
      //// Enterprise Lender
      case 6:
         return <TeamsMain />;

      //// Non-Enterprise
      default:
         return <TeamsMain />;
   }
};
