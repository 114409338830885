import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from '@mui/material';



function ArrowLeftSVG(props : any) {

    const { classes } = useStyles();
    const theme = useTheme();

    return (
        <>
            <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg"  xlinkHref="http://www.w3.org/1999/xlink" width="33.951" height="22.576" viewBox="0 0 33.951 22.576">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_1024" data-name="Rectangle 1024" width="33.951" height="22.577" transform="translate(8 -1)" fill="currentColor" />
                    </clipPath>
                </defs>
                <g id="Group_2949" data-name="Group 2949" transform="translate(41.951 21.576) rotate(180)">
                    <g id="Group_2947" data-name="Group 2947" clipPath="url(#clip-path)">
                        <path id="Path_301" data-name="Path 301" d="M40.68,9.632h0L31.374.325a.931.931,0,1,0-1.312,1.312l7.715,7.725H9.931a.931.931,0,0,0,0,1.861H37.777l-7.715,7.715a.931.931,0,1,0,1.312,1.312l9.307-9.307a.931.931,0,0,0,0-1.312" transform="translate(0 0.001)" fill="currentColor" />
                    </g>
                </g>
            </svg>

        </>
    )

}

const useStyles = makeStyles({ name: 'RegisterEmailComponent' })((theme) => ({
    svg:{
        height: '11px',
        width : 'auto'
    }
}));


export default ArrowLeftSVG;