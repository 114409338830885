/* eslint-disable */
import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const AppBody = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Grid
      id="app-body"
      container
      className={classes.mainBodyContainer}
      direction={"column"}
    >
      {children}
    </Grid>
  );
};

const useStyles = makeStyles({ name: "AppBody" })((theme) => ({
  mainBodyContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    borderTopLeftRadius: "25px",
    padding: "20px",
    backgroundColor: "#F5F6F6",
    flexDirection: "column",
    overflow: "auto",
    flexWrap: "nowrap",
  },
}));

export default AppBody;
