import react from "react";
import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";

interface ActionButtonProps {
  iconSrc: any,
  onClick?: ()=> void 
}

function ActionButton(props: ActionButtonProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {

  }, []);


  return (
    <>
      <button className={classes.OuterActionButton} onClick={props.onClick ? props.onClick : ()=>{}}>
        <img
          className={classes.ActionButtonIcon}
          src={props.iconSrc}
          alt="action button"
        />
      </button>
    </>
  );
}

const actionButtonSize = '125px';
const useStyles = makeStyles({ name: 'ActionButton' })((theme) => ({
  OuterActionButton:{
    border : 'none',
    outline : 'none',
    backgroundColor : 'transparent'
  },
  ActionButtonContainer: {
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: ('0px 0px 10px 100px' + theme.palette.common.lightGray),
    height: actionButtonSize,
    width: actionButtonSize,
    minHeight: actionButtonSize,
    minWidth: actionButtonSize,
    maxHeight: actionButtonSize,
    maxWidth: actionButtonSize,
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(0.85)'
    },
    marginTop: '10px',
    marginBottom: '10px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ActionButtonIcon: {
    height: "135px",
    width: "135px",
    marginLeft: "10px",
    marginBottom: "-10px",
    objectFit: "fill",
    cursor: 'pointer',
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.85)",
    },
    // boxShadow: ('0px 0px 10px 1px' + theme.palette.common.lightGray),
    // borderRadius: '20px',

  }
}));

export default ActionButton;
