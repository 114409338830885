import { DirectionsRailwayFilledOutlined } from "@mui/icons-material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import lockIcon from "../../styles/assets/logos/lock.png";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";
import { useState, useEffect } from "react";

function RegisterPhoneComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    if (props.defaultValue != "" && props.defaultValue != null) {
      let defaultValid = validatePhone(props.defaultValue);
      setNextButtonDisabled(!defaultValid);
    }
  }, [props.defaultValue]);

  function validatePhone(phone: string) {
    if (phone == "" || phone == null || phone == undefined) {
      // Return true because the button disabled calls this on load
      return true;
    }

    let isValid: boolean = true;
    let phoneInput: HTMLInputElement = document.querySelector(
      "#register-phone-input"
    ) as HTMLInputElement;
    let line: HTMLElement = document.querySelector(
      "#divider-line"
    ) as HTMLInputElement;

    // Check if the phone number contains any letters
    if (/[a-zA-Z]/.test(phone)) {
      isValid = false;
      let validationMessage = "Invalid Phone Number";
      phoneInput.value = "";
      phoneInput.placeholder = validationMessage;
      phoneInput.classList.add(classes.invalidInput);
      line.style.backgroundColor = "red";
    }

    return isValid;
  }

  function setNextToActive(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) {
    // console.log((event.target as HTMLInputElement).value);
    //min email length a@b.io = 6 chars
    if (
      (event.target as HTMLInputElement).value.length >= 10 &&
      (event.target as HTMLInputElement).value.length <= 11
    ) {
      //set button to active
      setNextButtonDisabled(false);
    } else {
      //set to inactive
      setNextButtonDisabled(true);
    }
  }

  const onKeyDownCheck = (event: any) => {
    // Allow backspace (key code 8)
    if (event.keyCode === 8) {
      return;
    }

    // Define a regular expression pattern that allows only positive numbers.
    const validInputPattern = /^\d*$/;

    // Get the input value from the event's key and current input value.
    const inputValue = event.target.value + event.key;

    // Check if the input value matches the valid pattern.
    if (!validInputPattern.test(inputValue)) {
      event.preventDefault();
    }
  };

  function nextStep() {
    let phone: string = (
      document.querySelector("#register-phone-input") as HTMLInputElement
    ).value;
    if (validatePhone(phone)) {
      if (props.onSubmitPhone) props.onSubmitPhone(phone);
    }
  }

  return (
    <section className={classes.registerPhonecontainer}>
      <h2 className={classes.registerPhoneHeader}>Enter your phone number</h2>

      <div className={classes.outerInputBox}>
        <div className={classes.registerInputBoxContainer}>
          <img className={classes.registerMailIcon} src={lockIcon} alt="" />
          <input
            onChange={(e) => {
              setNextToActive(e);
            }}
            onKeyDown={(e) => {
              onKeyDownCheck(e);
            }}
            className={classes.registerPhoneInput}
            type="tel"
            id="register-phone-input"
            maxLength={10}
            placeholder="Phone number"
            defaultValue={props.defaultValue}
          />
        </div>
        <div id="divider-line" className={classes.registerDividerLine}></div>
      </div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          listener={nextStep}
          disabled={nextButtonDisabled}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterPhoneComponent" })((theme) => ({
  registerPhonecontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },

  registerPhoneHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
  },
  outerInputBox: {
    height: "45px",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "145px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "14px",
    width: "auto",
    marginRight: "5px",
  },
  registerPhoneInput: {
    border: "none",
    outline: "none",
    width: "80%",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: theme.palette.common.lightGray, // Replace this with your desired color
      fontWeight: "bold",
    },
    fontSize: "16px",
  },
  registerDividerLine: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: "100px",
    marginTop: "3px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  invalidInput: {
    "&::placeholder": {
      color: "red", // Replace this with your desired color
    },
  },
}));

export default RegisterPhoneComponent;
