import React, { useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
   returnParkingTypeNumber,
   returnPropertyTypeString,
   returnPropertyTypeNumber,
   returnPetPolicyNumber,
   returnPetPolicyString,
   returnParkingTypeString,
} from "./utils";
import {
   createPropertyStepFiveAssignLock,
   generatePreSignedUrl,
   createPropertyStepTwoPhotos,
   getListingFull,
} from "../../../contexts/api/api.functions";
import {
   SingleListingActions,
   useSingleListingState,
   useSingleListingDispatch,
} from "../../../contexts/single-listing/singleListing.context";
import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { S3DirectoryName } from "../../../contexts/api/api.types";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material";
import { getListing } from "../../../contexts/api/api.functions";
import { useParams } from "react-router-dom";
import { AccessChoice } from "../create/primary-tabs/listings.hardware.tab";
const { v4: uuidv4 } = require("uuid");

export const createListingHardwareDefaultValues = {
   lockTypeId: "1",
   lockKey: "1",
   doorImg: "",
   lockImg: "",
   igloolockTypeId: "",
   masterLockTypeId: "",
   accessChoice: null,
};

export const CreatedListingFormContextHardware: React.FC<{
   children: React.ReactNode;
   setActive: (value: boolean) => void;
}> = ({ children, setActive }) => {
   const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
   const [successOpen, setSuccessOpen] = useState(false);
   const [successMessage, setSuccessMessage] = useState("");
   const [errorOpen, setErrorOpen] = useState(false);
   const [error, setError] = useState<null | string>(null);
   const [photoErrorOpen, setPhotoErrorOpen] = useState(false);
   const [lockImageErrorOpen, setLockImageErrorOpen] = useState(false);
   const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false);
   const [accessChoiceErrorOpen, setAccessChoiceErrorOpen] = useState(false);
   const [backdropOpen, setBackdropOpen] = useState(false);
   const theme = useTheme();
   const {
      nickName,
      propertyId,
      doorImage,
      lockImage,
      doorImagePath,
      lockImagePath,
      featurePhoto,
      featurePhotoPath,
      photoPaths,
      photos,
   } = useSingleListingState();
   const dispatch = useSingleListingDispatch();
   const schema = yup.object().shape({
      lockTypeId: yup.string().required("Lock Type is required"),
      lockKey: yup.string(),
      doorImg: yup.string(),
      lockImg: yup.string(),
      igloolockTypeId: yup.string(),
      masterLockTypeId: yup.string(),
      accessChoice: yup.number().nullable(),
   });

   const methods = useForm({
      defaultValues: createListingHardwareDefaultValues,
      resolver: yupResolver(schema),
   });

   const handleSubmitFunction = async (data: any) => {
      if (ListingIdParam === undefined) {
         setPropertyIdErrorOpen(true);
         return;
      }
      if (data.accessChoice === null) {
         setAccessChoiceErrorOpen(true);
         return;
      }
      if (photoPaths && photoPaths.length === 0) {
         if (photos.length === 0) {
            setPhotoErrorOpen(true);
            return;
         }
      }
      if (!featurePhotoPath) {
         if (!featurePhoto) {
            setPhotoErrorOpen(true);
            return;
         } else if (featurePhoto && featurePhoto.file === "") {
            setPhotoErrorOpen(true);
            return;
         }
      }
      if (lockImagePath) {
         if (doorImagePath) {
            let body: any;
            if (data.accessChoice === AccessChoice.INSTRUCTIONS) {
               body = {
                  stepId: 5,
                  propertyId: ListingIdParam,
                  lockTypeId: "",
                  lockKey: "",
                  doorImg: "",
                  lockImg: "",
                  igloolockTypeId: "",
                  masterlockTypeId: "",
                  accessChoice: 0,
               };
            } else {
               body = {
                  stepId: 5,
                  propertyId: ListingIdParam,
                  lockTypeId: data.lockTypeId,
                  lockKey: data.lockKey,
                  doorImg: doorImagePath,
                  lockImg: lockImagePath,
                  igloolockTypeId: data.igloolockTypeId,
                  masterlockTypeId: data.masterlockTypeId,
                  accessChoice: 1,
               };
            }

            const res = await createPropertyStepFiveAssignLock(body);
            if (res && res.data && res.data.status === true) {
               if (res.data.result.lockAssigned === 1) {
                  //console.log("lock in use");
                  setError("Lock is already assigned to another listing");
                  setErrorOpen(true);
                  setBackdropOpen(false);
                  return;
               }
               if (
                  featurePhotoPath &&
                  photoPaths &&
                  photoPaths.length > 0 &&
                  photos.length === 0
               ) {
                  const bodyPhotos = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featurePhotoPath,
                     images: [...photoPaths],
                  };
                  const resPhoto = await createPropertyStepTwoPhotos(
                     bodyPhotos
                  );
                  if (
                     resPhoto &&
                     resPhoto.data &&
                     resPhoto.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessMessage("Hardware Tab saved succesfully.");
                     setSuccessOpen(true);
                     return;
                  } else {
                     setBackdropOpen(false);
                     setErrorOpen(true);
                     return;
                  }
               } else if (featurePhoto.file !== "" && photos.length > 0) {
                  if (!ListingIdParam) {
                     return;
                  }
                  let featureKey: string = uuidv4();
                  let photosKeyArr: Array<string> = [];
                  if (featurePhotoPath === null) {
                     const featureRequest = await generatePreSignedUrl({
                        directory: S3DirectoryName.PROPERTY,
                        fileName: featureKey,
                     });
                     if (
                        featureRequest &&
                        featureRequest.data &&
                        featureRequest.data.status === true
                     ) {
                        const featureUrl = featureRequest.data.result;
                        await axios
                           .put(featureUrl, featurePhoto.file)
                           .then((response) => {
                              // console.log(response)
                           })
                           .catch((error) => {
                              // console.log(error)
                           });
                     }
                  }
                  await Promise.all(
                     photos.map(async (photo) => {
                        let photoKey = uuidv4();

                        const res1 = await generatePreSignedUrl({
                           directory: S3DirectoryName.PROPERTY,
                           fileName: photoKey,
                        });
                        if (res1 && res1.data && res1.data.status === true) {
                           photosKeyArr.push(photoKey);
                           const photoUrl = res1.data.result;
                           await axios
                              .put(photoUrl, photo.file)
                              .then(() => {
                                 // console.log(`Uploaded file ${photoKey}`)
                              })
                              .catch((error) => {
                                 // console.log(error)
                              });
                        }
                     })
                  );

                  const body4 = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featureKey,
                     images: [...photosKeyArr],
                  };
                  const res4 = await createPropertyStepTwoPhotos(body4);
                  if (
                     res4 &&
                     res4.data &&
                     res4.data.status &&
                     res4.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessMessage("Hardware Tab saved succesfully.");
                     setSuccessOpen(true);
                  }
               } else if (featurePhotoPath && photos.length > 0) {
                  if (!ListingIdParam) {
                     return;
                  }
                  setBackdropOpen(true);
                  let photosKeyArr: Array<string> = [];
                  await Promise.all(
                     photos.map(async (photo) => {
                        let photoKey = uuidv4();

                        const res1 = await generatePreSignedUrl({
                           directory: S3DirectoryName.PROPERTY,
                           fileName: photoKey,
                        });
                        if (res1 && res1.data && res1.data.status === true) {
                           photosKeyArr.push(photoKey);
                           const photoUrl = res1.data.result;
                           await axios
                              .put(photoUrl, photo.file)
                              .then(() => {
                                 //(`Uploaded file ${photoKey}`);
                              })
                              .catch((error) => {
                                 //console.log(error);
                              });
                        }
                     })
                  );
                  let body4: any;
                  if (photoPaths) {
                     body4 = {
                        propertyId: ListingIdParam,
                        stepId: 2,
                        mainImg: featurePhotoPath,
                        images: [...photoPaths, ...photosKeyArr],
                     };
                  } else {
                     body4 = {
                        propertyId: ListingIdParam,
                        stepId: 2,
                        mainImg: featurePhotoPath,
                        images: [...photosKeyArr],
                     };
                  }
                  const res4 = await createPropertyStepTwoPhotos(body4);
                  if (
                     res4 &&
                     res4.data &&
                     res4.data.status &&
                     res4.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessMessage("Hardware Tab saved succesfully.");
                     setSuccessOpen(true);
                     return;
                  } else {
                     setBackdropOpen(false);
                     setError(
                        "We could not update the photos, please try again."
                     );
                     setErrorOpen(true);
                     return;
                  }
               } else if (featurePhoto.file !== "" && photoPaths) {
                  let featureKey: string = uuidv4();
                  const featureRequest = await generatePreSignedUrl({
                     directory: S3DirectoryName.PROPERTY,
                     fileName: featureKey,
                  });
                  if (
                     featureRequest &&
                     featureRequest.data &&
                     featureRequest.data.status === true
                  ) {
                     const featureUrl = featureRequest.data.result;
                     await axios
                        .put(featureUrl, featurePhoto.file)
                        .then((response) => {
                           // console.log(response)
                        })
                        .catch((error) => {
                           // console.log(error)
                        });
                  }
                  const body5 = {
                     propertyId: ListingIdParam!,
                     stepId: 2,
                     mainImg: featureKey,
                     images: [...photoPaths],
                  };
                  const res5 = await createPropertyStepTwoPhotos(body5);
                  if (
                     res5 &&
                     res5.data &&
                     res5.data.status &&
                     res5.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessOpen(true);
                     return;
                  }
               } else {
                  setBackdropOpen(false);
                  setSuccessMessage("Hardware Tab saved succesfully.");
                  setSuccessOpen(true);
               }
            } else {
               setBackdropOpen(false);
               setError("We could not update the property, please try again.");
               setErrorOpen(true);
               return;
            }
         } else {
            let doorImageValue: string = "";
            let doorImgKey: string = uuidv4();
            if (doorImage) {
               const doorImageRequest = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: doorImgKey,
               });
               if (
                  doorImageRequest &&
                  doorImageRequest.data &&
                  doorImageRequest.data.status === true
               ) {
                  const doorImgUrl = doorImageRequest.data.result;
                  await axios
                     .put(doorImgUrl, doorImage)
                     .then(() => {
                        doorImageValue = doorImgKey;
                     })
                     .catch((error) => {});
               }
            }

            let body: any;
            if (data.accessChoice === AccessChoice.INSTRUCTIONS) {
               body = {
                  stepId: 5,
                  propertyId: ListingIdParam,
                  lockTypeId: "",
                  lockKey: "",
                  doorImg: "",
                  lockImg: "",
                  igloolockTypeId: "",
                  masterlockTypeId: "",
                  accessChoice: 0,
               };
            } else {
               body = {
                  stepId: 5,
                  propertyId: ListingIdParam,
                  lockTypeId: data.lockTypeId,
                  lockKey: data.lockKey,
                  doorImg: doorImageValue,
                  lockImg: lockImagePath,
                  igloolockTypeId: data.igloolockTypeId,
                  masterlockTypeId: data.masterlockTypeId,
                  accessChoice: 1,
               };
            }
            /// Old request updated to have accessChoice
            // const body = {
            //    stepId: 5,
            //    propertyId: ListingIdParam,
            //    lockTypeId: data.lockTypeId,
            //    lockKey: data.lockKey,
            //    doorImg: doorImageValue,
            //    lockImg: lockImagePath,
            //    igloolockTypeId: data.igloolockTypeId,
            //    masterlockTypeId: data.masterlockTypeId,
            // };

            const res = await createPropertyStepFiveAssignLock(body);
            if (res && res.data && res.data.status === true) {
               if (res.data.result.lockAssigned == 1) {
                  setError("Lock is already assigned to another listing");
                  setBackdropOpen(false);
                  setErrorOpen(true);
                  return;
               }
               if (
                  featurePhotoPath &&
                  photoPaths &&
                  photoPaths.length > 0 &&
                  photos.length === 0
               ) {
                  const bodyPhotos = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featurePhotoPath,
                     images: [...photoPaths],
                  };
                  const resPhoto = await createPropertyStepTwoPhotos(
                     bodyPhotos
                  );
                  if (
                     resPhoto &&
                     resPhoto.data &&
                     resPhoto.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessMessage("Hardware Tab saved succesfully.");
                     setSuccessOpen(true);
                     return;
                  } else {
                     setBackdropOpen(false);
                     setError(
                        "We could not update the photos, please try again."
                     );
                     setErrorOpen(true);
                     return;
                  }
               } else if (featurePhoto.file !== "" && photos.length > 0) {
                  if (!ListingIdParam) {
                     return;
                  }
                  let featureKey: string = uuidv4();
                  let photosKeyArr: Array<string> = [];
                  if (featurePhotoPath === null) {
                     const featureRequest = await generatePreSignedUrl({
                        directory: S3DirectoryName.PROPERTY,
                        fileName: featureKey,
                     });
                     if (
                        featureRequest &&
                        featureRequest.data &&
                        featureRequest.data.status === true
                     ) {
                        const featureUrl = featureRequest.data.result;
                        await axios
                           .put(featureUrl, featurePhoto.file)
                           .then((response) => {
                              // console.log(response)
                           })
                           .catch((error) => {
                              // console.log(error)
                           });
                     }
                  }
                  await Promise.all(
                     photos.map(async (photo) => {
                        let photoKey = uuidv4();

                        const res1 = await generatePreSignedUrl({
                           directory: S3DirectoryName.PROPERTY,
                           fileName: photoKey,
                        });
                        if (res1 && res1.data && res1.data.status === true) {
                           photosKeyArr.push(photoKey);
                           const photoUrl = res1.data.result;
                           await axios
                              .put(photoUrl, photo.file)
                              .then(() => {
                                 // console.log(`Uploaded file ${photoKey}`)
                              })
                              .catch((error) => {
                                 // console.log(error)
                              });
                        }
                     })
                  );

                  const body4 = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featureKey,
                     images: [...photosKeyArr],
                  };
                  const res4 = await createPropertyStepTwoPhotos(body4);
                  if (
                     res4 &&
                     res4.data &&
                     res4.data.status &&
                     res4.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessMessage("Hardware Tab saved succesfully.");
                     setSuccessOpen(true);
                  }
               } else if (featurePhotoPath && photos.length > 0) {
                  if (!ListingIdParam) {
                     return;
                  }
                  setBackdropOpen(true);
                  let photosKeyArr: Array<string> = [];
                  await Promise.all(
                     photos.map(async (photo) => {
                        let photoKey = uuidv4();

                        const res1 = await generatePreSignedUrl({
                           directory: S3DirectoryName.PROPERTY,
                           fileName: photoKey,
                        });
                        if (res1 && res1.data && res1.data.status === true) {
                           photosKeyArr.push(photoKey);
                           const photoUrl = res1.data.result;
                           await axios
                              .put(photoUrl, photo.file)
                              .then(() => {
                                 // console.log(`Uploaded file ${photoKey}`)
                              })
                              .catch((error) => {
                                 // console.log(error)
                              });
                        }
                     })
                  );
                  let body4: any;
                  if (photoPaths) {
                     body4 = {
                        propertyId: ListingIdParam,
                        stepId: 2,
                        mainImg: featurePhotoPath,
                        images: [...photoPaths, ...photosKeyArr],
                     };
                  } else {
                     body4 = {
                        propertyId: ListingIdParam,
                        stepId: 2,
                        mainImg: featurePhotoPath,
                        images: [...photosKeyArr],
                     };
                  }
                  const res4 = await createPropertyStepTwoPhotos(body4);
                  if (
                     res4 &&
                     res4.data &&
                     res4.data.status &&
                     res4.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessMessage("Hardware Tab saved succesfully.");
                     setSuccessOpen(true);
                     return;
                  }
               } else if (featurePhoto.file !== "" && photoPaths) {
                  let featureKey: string = uuidv4();
                  const featureRequest = await generatePreSignedUrl({
                     directory: S3DirectoryName.PROPERTY,
                     fileName: featureKey,
                  });
                  if (
                     featureRequest &&
                     featureRequest.data &&
                     featureRequest.data.status === true
                  ) {
                     const featureUrl = featureRequest.data.result;
                     await axios
                        .put(featureUrl, featurePhoto.file)
                        .then((response) => {
                           // console.log(response)
                        })
                        .catch((error) => {
                           // console.log(error)
                        });
                  }
                  const body5 = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featureKey,
                     images: [...photoPaths],
                  };
                  const res5 = await createPropertyStepTwoPhotos(body5);
                  if (
                     res5 &&
                     res5.data &&
                     res5.data.status &&
                     res5.data.status === true
                  ) {
                     setBackdropOpen(false);
                     setSuccessOpen(true);
                     return;
                  } else {
                     setBackdropOpen(false);
                     setError(
                        "We could not update the photos, please try again."
                     );
                     setErrorOpen(true);
                     return;
                  }
               } else {
                  setBackdropOpen(false);
                  setSuccessMessage("Hardware Tab saved succesfully.");
                  setSuccessOpen(true);
                  return;
               }
            } else {
               setBackdropOpen(false);
               setError("We could not update the property, please try again.");
               setErrorOpen(true);
               return;
            }
         }
      } else {
         if (!lockImage) {
            if (data.accessChoice === AccessChoice.LOCK) {
               setLockImageErrorOpen(true);
               return;
            }
         }
         setBackdropOpen(true);
         let doorImgKey: string = uuidv4();
         let lockImgKey: string = uuidv4();

         if (data.accessChoice === AccessChoice.LOCK) {
            if (doorImage) {
               const doorImageRequest = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: doorImgKey,
               });
               if (
                  doorImageRequest &&
                  doorImageRequest.data &&
                  doorImageRequest.data.status === true
               ) {
                  const doorImgUrl = doorImageRequest.data.result;
                  await axios
                     .put(doorImgUrl, doorImage)
                     .then(() => {})
                     .catch((error) => {});
               }
            }
            const lockImageRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: lockImgKey,
            });
            if (
               lockImageRequest &&
               lockImageRequest.data &&
               lockImageRequest.data.status === true
            ) {
               const lockImgUrl = lockImageRequest.data.result;
               await axios
                  .put(lockImgUrl, lockImage)
                  .then(() => {})
                  .catch((error) => {});
            }
         }
         let body: any;
         if (data.accessChoice === AccessChoice.INSTRUCTIONS) {
            body = {
               stepId: 5,
               propertyId: ListingIdParam,
               lockTypeId: "",
               lockKey: "",
               doorImg: "",
               lockImg: "",
               igloolockTypeId: "",
               masterlockTypeId: "",
               accessChoice: 0,
            };
         } else {
            body = {
               stepId: 5,
               propertyId: ListingIdParam,
               lockTypeId: data.lockTypeId,
               lockKey: data.lockKey,
               doorImg: doorImage ? doorImgKey : "",
               lockImg: lockImgKey,
               igloolockTypeId: data.igloolockTypeId,
               masterlockTypeId: data.masterlockTypeId,
               accessChoice: 1,
            };
         }

         // Old Request, updated to include AccessChoice
         // const body = {
         //    stepId: 5,
         //    propertyId: ListingIdParam,
         //    lockTypeId: data.lockTypeId,
         //    lockKey: data.lockKey,
         //    doorImg: doorImage ? doorImgKey : "",
         //    lockImg: lockImgKey,
         //    igloolockTypeId: data.igloolockTypeId,
         //    masterlockTypeId: data.masterlockTypeId,
         // };

         const res = await createPropertyStepFiveAssignLock(body);
         if (res && res.data && res.data.status === true) {
            if (res.data.result.lockAssigned == 1) {
               setError("Lock is already assigned to another listing");
               setBackdropOpen(false);
               setErrorOpen(true);
               return;
            }
            if (
               featurePhotoPath &&
               photoPaths &&
               photoPaths.length > 0 &&
               photos.length === 0
            ) {
               const bodyPhotos = {
                  propertyId: ListingIdParam,
                  stepId: 2,
                  mainImg: featurePhotoPath,
                  images: [...photoPaths],
               };
               const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos);
               if (resPhoto && resPhoto.data && resPhoto.data.status === true) {
                  setBackdropOpen(false);
                  setSuccessMessage("Hardware Tab saved succesfully.");
                  setSuccessOpen(true);
                  return;
               } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
               }
            } else if (featurePhoto.file !== "" && photos.length > 0) {
               if (!ListingIdParam) {
                  return;
               }
               let featureKey: string = uuidv4();
               let photosKeyArr: Array<string> = [];
               if (featurePhotoPath === null) {
                  const featureRequest = await generatePreSignedUrl({
                     directory: S3DirectoryName.PROPERTY,
                     fileName: featureKey,
                  });
                  if (
                     featureRequest &&
                     featureRequest.data &&
                     featureRequest.data.status === true
                  ) {
                     const featureUrl = featureRequest.data.result;
                     await axios
                        .put(featureUrl, featurePhoto.file)
                        .then((response) => {
                           // console.log(response)
                        })
                        .catch((error) => {
                           // console.log(error)
                        });
                  }
               }
               await Promise.all(
                  photos.map(async (photo) => {
                     let photoKey = uuidv4();

                     const res1 = await generatePreSignedUrl({
                        directory: S3DirectoryName.PROPERTY,
                        fileName: photoKey,
                     });
                     if (res1 && res1.data && res1.data.status === true) {
                        photosKeyArr.push(photoKey);
                        const photoUrl = res1.data.result;
                        await axios
                           .put(photoUrl, photo.file)
                           .then(() => {
                              // console.log(`Uploaded file ${photoKey}`)
                           })
                           .catch((error) => {
                              // console.log(error)
                           });
                     }
                  })
               );

               const body4 = {
                  propertyId: ListingIdParam,
                  stepId: 2,
                  mainImg: featureKey,
                  images: [...photosKeyArr],
               };
               const res4 = await createPropertyStepTwoPhotos(body4);
               if (
                  res4 &&
                  res4.data &&
                  res4.data.status &&
                  res4.data.status === true
               ) {
                  setBackdropOpen(false);
                  setSuccessMessage("Hardware Tab Saved Succesfully");
                  setSuccessOpen(true);
               } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
               }
            } else if (featurePhotoPath && photos.length > 0) {
               if (!ListingIdParam) {
                  return;
               }
               setBackdropOpen(true);
               let photosKeyArr: Array<string> = [];
               await Promise.all(
                  photos.map(async (photo) => {
                     let photoKey = uuidv4();

                     const res1 = await generatePreSignedUrl({
                        directory: S3DirectoryName.PROPERTY,
                        fileName: photoKey,
                     });
                     if (res1 && res1.data && res1.data.status === true) {
                        photosKeyArr.push(photoKey);
                        const photoUrl = res1.data.result;
                        await axios
                           .put(photoUrl, photo.file)
                           .then(() => {
                              //console.log(`Uploaded file ${photoKey}`)
                           })
                           .catch((error) => {
                              //console.log(error)
                           });
                     }
                  })
               );
               let body4: any;
               if (photoPaths) {
                  body4 = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featurePhotoPath,
                     images: [...photoPaths, ...photosKeyArr],
                  };
               } else {
                  body4 = {
                     propertyId: ListingIdParam,
                     stepId: 2,
                     mainImg: featurePhotoPath,
                     images: [...photosKeyArr],
                  };
               }
               const res4 = await createPropertyStepTwoPhotos(body4);
               if (
                  res4 &&
                  res4.data &&
                  res4.data.status &&
                  res4.data.status === true
               ) {
                  setBackdropOpen(false);
                  setSuccessMessage("Hardware Tab saved succesfully.");
                  setSuccessOpen(true);
                  return;
               } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
               }
            } else if (featurePhoto.file !== "" && photoPaths) {
               let featureKey: string = uuidv4();
               const featureRequest = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: featureKey,
               });
               if (
                  featureRequest &&
                  featureRequest.data &&
                  featureRequest.data.status === true
               ) {
                  const featureUrl = featureRequest.data.result;
                  await axios
                     .put(featureUrl, featurePhoto.file)
                     .then((response) => {
                        // console.log(response)
                     })
                     .catch((error) => {
                        // console.log(error)
                     });
               }
               const body5 = {
                  propertyId: ListingIdParam,
                  stepId: 2,
                  mainImg: featureKey,
                  images: [...photoPaths],
               };
               const res5 = await createPropertyStepTwoPhotos(body5);
               if (
                  res5 &&
                  res5.data &&
                  res5.data.status &&
                  res5.data.status === true
               ) {
                  setBackdropOpen(false);
                  setSuccessMessage("Hardware Tab saved succesfully.");
                  setSuccessOpen(true);
                  return;
               } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
               }
            } else {
               setBackdropOpen(false);
               setSuccessMessage("Hardware Tab saved succesfully.");
               setSuccessOpen(true);
               return;
            }
         } else {
            setBackdropOpen(false);
            setError("We could not update the property, please try again.");
            setErrorOpen(true);
            return;
         }
      }
   };

   const fetchStatusListing = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         const res = await getListingFull(parseInt(ListingIdParam));
         if (res && res.data && res.data.status && res.data.status === true) {
            if (
               res.data.result &&
               res.data.result.PropertyDetail &&
               res.data.result.PropertyDetail.propertyStatus
            ) {
               const value = res.data.result.PropertyDetail.propertyStatus;
               // console.log(value)
               // console.log("this is the value")
               if (value === "1") {
                  dispatch({
                     type: SingleListingActions.setListingType,
                     payload: "active",
                  });
                  return;
               }
               if (value === "2") {
                  dispatch({
                     type: SingleListingActions.setListingType,
                     payload: "draft",
                  });
                  return;
               }
               if (value === "3") {
                  dispatch({
                     type: SingleListingActions.setListingType,
                     payload: "archive",
                  });
                  return;
               }
               return;
            }
         }
      }
   };

   useEffect(() => {
      fetchStatusListing();
   }, [ListingIdParam]);

   const setPreviewValues = (res: any) => {
      if (res && res.data && res.data.status && res.data.status === true) {
         let nickName: string = "";
         let propertyCreateTypeId: string = "";
         let price: string = "";
         let address: string = "";
         let addressLine2: string = "";
         let city: string = "";
         let state: string = "";
         let zipCode: string = "";
         let propertyTypeId: string = "";
         let lotSize: string = "";
         let sqFoot: string = "";
         let bathrooms: string = "";
         let bedrooms: string = "";
         let parkingTypeId: string = "";
         let parkingSpace: string = "";
         let propertyHeadline: string = "";
         let generalInformation: string = "";
         let utilities: string = "";
         let petPolicyId: string = "";
         let petPolicyDescription: string = "";
         let zlink: string = "";
         let accessInstruction: string = "";
         let exitInstruction: string = "";
         let postTourInstruction: string = "";
         let propertyId: string = "";

         if (res.data.result[0].screen1[0].propertyName) {
            const value = res.data.result[0].screen1[0].propertyName;
            nickName = value;
         }
         if (res.data.result[0].screen1[0].propertyCreateTypeId) {
            const value =
               res.data.result[0].screen1[0].propertyCreateTypeId === 1
                  ? "rental"
                  : "sale";
            propertyCreateTypeId = value;
         }
         if (res.data.result[0].screen1[0].propertySalePrice) {
            const value = res.data.result[0].screen1[0].propertySalePrice;
            price = value;
         }
         if (res.data.result[0].screen1[0].propertyAddress) {
            const value = res.data.result[0].screen1[0].propertyAddress;
            address = value;
         }

         if (res.data.result[0].screen1[0].apt) {
            const value = res.data.result[0].screen1[0].apt;
            addressLine2 = value;
         }
         if (res.data.result[0].screen1[0].propertyCity) {
            const value = res.data.result[0].screen1[0].propertyCity;
            city = value;
         }
         if (res.data.result[0].screen1[0].propertyState) {
            const value = res.data.result[0].screen1[0].propertyState;
            state = value;
         }
         if (res.data.result[0].screen1[0].propertyZipCode) {
            const value = res.data.result[0].screen1[0].propertyZipCode;
            zipCode = value;
         }
         if (res.data.result[0].screen1[0].propertyTypeId) {
            const value = returnPropertyTypeString(
               res.data.result[0].screen1[0].propertyTypeId
            );
            propertyTypeId = value;
         }
         if (res.data.result[0].screen1[0].propertyLotSize) {
            const value = res.data.result[0].screen1[0].propertyLotSize;
            lotSize = value;
         }
         if (res.data.result[0].screen1[0].propertySize) {
            const value = res.data.result[0].screen1[0].propertySize;
            sqFoot = value;
         }
         if (res.data.result[0].screen1[0].propertyBathrooms) {
            const value = res.data.result[0].screen1[0].propertyBathrooms;
            bathrooms = value;
         }
         if (res.data.result[0].screen1[0].propertyBedrooms) {
            const value = res.data.result[0].screen1[0].propertyBedrooms;
            bedrooms = value;
         }
         if (res.data.result[0].screen1[0].propertyParkingTypeId) {
            const value = returnParkingTypeString(
               res.data.result[0].screen1[0].propertyParkingTypeId
            );
            parkingTypeId = value;
         }
         if (res.data.result[0].screen1[0].propertyParkingSpace) {
            const value =
               res.data.result[0].screen1[0].propertyParkingSpace.toString();
            parkingSpace = value;
         }
         if (res.data.result[0].screen1[0].propertyHeadline) {
            const value = res.data.result[0].screen1[0].propertyHeadline;
            propertyHeadline = value;
         }
         if (res.data.result[0].screen1[0].generalInformation) {
            const value = res.data.result[0].screen1[0].generalInformation;
            generalInformation = value;
         }
         if (res.data.result[0].screen1[0].propertyUtilities) {
            const value = res.data.result[0].screen1[0].propertyUtilities;
            utilities = value;
         }
         if (res.data.result[0].screen1[0].propertyPetPolicyId) {
            const value = returnPetPolicyString(
               res.data.result[0].screen1[0].propertyPetPolicyId
            );
            petPolicyId = value;
         }
         if (res.data.result[0].screen1[0].propertyPetPolicyDesc) {
            const value = res.data.result[0].screen1[0].propertyPetPolicyDesc;
            petPolicyDescription = value;
         }
         if (res.data.result[0].screen1[0].propertyZLink) {
            const value = res.data.result[0].screen1[0].propertyZLink;
            zlink = value;
         }
         const previewObject = {
            nickName: nickName,
            propertyCreateTypeId: propertyCreateTypeId,
            price: price,
            address: address,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zipCode: zipCode,
            propertyTypeId: propertyTypeId,
            lotSize: lotSize,
            sqFoot: sqFoot,
            bathrooms: bathrooms,
            bedrooms: bedrooms,
            parkingTypeId: parkingTypeId,
            parkingSpace: parkingSpace,
            propertyHeadline: propertyHeadline,
            generalInformation: generalInformation,
            utilities: utilities,
            petPolicyId: petPolicyId,
            petPolicyDescription: petPolicyDescription,
            zlink: zlink,
            accessInstruction: accessInstruction,
            exitInstruction: exitInstruction,
            postTourInstruction: postTourInstruction,
            propertyId: ListingIdParam,
         };
         dispatch({
            type: SingleListingActions.setPreview,
            payload: previewObject,
         });
      }
   };

   const validateForActivation = (res: any) => {
      if (
         res.data.result[0].screen1 &&
         res.data.result[0].screen1.length > 0 &&
         res.data.result[0].screen4 &&
         res.data.result[0].screen4.weekDays &&
         res.data.result[0].screen4.weekDays.length > 0 &&
         res.data.result[0].screen5 &&
         res.data.result[0].screen5.length > 0 &&
         res.data.result[0].screen5[0].accessChoice !== "" &&
         res.data.result[0].screen7 &&
         res.data.result[0].screen7.length > 0 &&
         res.data.result[0].screen7[0].propertyPaymentAccessOption !== ""
      ) {
         if (
            res.data.result[0].screen5[0].accessChoice &&
            res.data.result[0].screen5[0].accessChoice === "0"
         ) {
            dispatch({
               type: SingleListingActions.setCanActivate,
               payload: true,
            });
         } else if (
            res.data.result[0].screen5[0].accessChoice &&
            res.data.result[0].screen5[0].accessChoice === "1" &&
            res.data.result[0].screen5[0].propertyLockKey !== ""
         ) {
            dispatch({
               type: SingleListingActions.setCanActivate,
               payload: true,
            });
         } else {
            dispatch({
               type: SingleListingActions.setCanActivate,
               payload: false,
            });
         }
      } else {
         dispatch({
            type: SingleListingActions.setCanActivate,
            payload: false,
         });
      }
   };

   const fetchAndUpdateValues = async () => {
      if (ListingIdParam) {
         setBackdropOpen(true);
         const res = await getListing(parseInt(ListingIdParam));
         if (res && res.data && res.data.status && res.data.status === true) {
            if (
               res.data.result[0].screen1[0].propertyId ===
               parseInt(ListingIdParam)
            ) {
               setPreviewValues(res);

               ///// Check to see whether or not the listing can be activate or not
               validateForActivation(res);
               // console.log("calling validate function");
               // if (
               //    res.data.result[0].screen1 &&
               //    res.data.result[0].screen1.length > 0 &&
               //    res.data.result[0].screen4 &&
               //    res.data.result[0].screen4.weekDays &&
               //    res.data.result[0].screen4.weekDays.length > 0 &&
               //    res.data.result[0].screen5 &&
               //    res.data.result[0].screen5.length > 0 &&
               //    res.data.result[0].screen5[0].propertyLockKey !== "" &&
               //    res.data.result[0].screen7 &&
               //    res.data.result[0].screen7.length > 0 &&
               //    res.data.result[0].screen7[0].propertyDocumentReq !== "0"
               // ) {
               //    dispatch({
               //       type: SingleListingActions.setCanActivate,
               //       payload: true,
               //    });
               // } else {
               //    dispatch({
               //       type: SingleListingActions.setCanActivate,
               //       payload: false,
               //    });
               // }
               if (
                  res.data.result[0].screen5[0] &&
                  res.data.result[0].screen5[0].accessChoice &&
                  res.data.result[0].screen5[0].accessChoice !== ""
               ) {
                  if (res.data.result[0].screen5[0].accessChoice === "0") {
                     const value1 = parseInt(
                        res.data.result[0].screen5[0].accessChoice
                     );
                     methods.setValue("accessChoice", value1);
                     // console.log(value1);
                     // console.log("Value that was set as context value ^^^^^");
                  }

                  if (res.data.result[0].screen5[0].accessChoice === "1") {
                     const value2 = parseInt(
                        res.data.result[0].screen5[0].accessChoice
                     );
                     methods.setValue("accessChoice", value2);
                  }
               }

               if (res.data.result[0].screen5[0].propertyLockType) {
                  const value =
                     res.data.result[0].screen5[0].propertyLockType.toString();
                  methods.setValue("lockTypeId", value);
               }

               if (res.data.result[0].screen5[0].propertyLockKey) {
                  const value = res.data.result[0].screen5[0].propertyLockKey;
                  methods.setValue("lockKey", value);
               }
               // console.log("lock image lock image");
               if (
                  res.data.result[0].screen5[0].propertyLockImg &&
                  res.data.result[0].screen5[0].propertyLockImg !== ""
               ) {
                  // console.log("setting lock image path");
                  const value = res.data.result[0].screen5[0].propertyLockImg;
                  // console.log(value);
                  dispatch({
                     type: SingleListingActions.setLockImagePath,
                     payload: value,
                  });
               } else {
                  dispatch({
                     type: SingleListingActions.setLockImagePath,
                     payload: null,
                  });
               }

               if (
                  res.data.result[0].screen5[0].propertyDoorImg &&
                  res.data.result[0].screen5[0].propertyDoorImg !== ""
               ) {
                  const value = res.data.result[0].screen5[0].propertyDoorImg;
                  // console.log(value)
                  dispatch({
                     type: SingleListingActions.setDoorImagePath,
                     payload: value,
                  });
               } else {
                  dispatch({
                     type: SingleListingActions.setDoorImagePath,
                     payload: null,
                  });
               }

               if (
                  res.data.result[0].screen2 &&
                  res.data.result[0].screen2.length > 0
               ) {
                  if (
                     res.data.result[0].screen2[0].propertyFeaturePhoto !==
                        null ||
                     ""
                  ) {
                     const value =
                        res.data.result[0].screen2[0].propertyFeaturePhoto;
                     dispatch({
                        type: SingleListingActions.setFeaturePhotoPath,
                        payload: value,
                     });
                  }

                  if (
                     res.data.result[0].screen2[0].propertyPhoto !== null ||
                     ""
                  ) {
                     const paths = [];
                     for (
                        var i = 0;
                        i < res.data.result[0].screen2.length;
                        i++
                     ) {
                        const value =
                           res.data.result[0].screen2[i].propertyPhoto;
                        paths.push(value);
                     }
                     dispatch({
                        type: SingleListingActions.setPhotoPaths,
                        payload: [...paths],
                     });
                  }
               }
            }
         }
         setBackdropOpen(false);
      } else {
         return;
      }
   };

   useEffect(() => {
      fetchAndUpdateValues();
   }, [ListingIdParam]);

   useEffect(() => {
      if (methods.formState.isDirty === true) {
         setActive(true);
      }
   }, [methods.formState.isDirty]);

   const onSubmit = (data: any) => {
      handleSubmitFunction(data).then(() => {
         fetchAndUpdateValues();
         // dispatch({ type: SingleListingActions.setFeature, payload: { file: "" } })
         // dispatch({ type: SingleListingActions.setPhotos, payload: [] })
      });
      // console.log('finished running submit')
   };

   return (
      <FormProvider {...methods}>
         <Snackbar
            open={successOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setSuccessOpen(false);
            }}
         >
            <Alert
               severity="success"
               onClose={() => {
                  setSuccessOpen(false);
               }}
            >
               {successMessage}
            </Alert>
         </Snackbar>
         <Snackbar
            open={errorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setErrorOpen(false);
               }}
            >
               {error ? error : "Something went wrong."}
            </Alert>
         </Snackbar>
         <Snackbar
            open={accessChoiceErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setLockImageErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setLockImageErrorOpen(false);
               }}
            >
               Access choice is required, please select an option and try again.
            </Alert>
         </Snackbar>
         <Snackbar
            open={lockImageErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setLockImageErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setLockImageErrorOpen(false);
               }}
            >
               Lock location image is required, please add one and try again.
            </Alert>
         </Snackbar>
         <Snackbar
            open={photoErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setPhotoErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setPhotoErrorOpen(false);
               }}
            >
               Feature photo and at least one additional photo required.
            </Alert>
         </Snackbar>
         <Snackbar
            open={propertyIdErrorOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={() => {
               setPropertyIdErrorOpen(false);
            }}
         >
            <Alert
               severity="error"
               onClose={() => {
                  setPropertyIdErrorOpen(false);
               }}
            >
               No Property Id, if you already created a property go back to the
               main listings page to edit or create another property.
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
         >
            <CircularProgress color="primary" />
         </Backdrop>
         <form
            style={{ width: "100%" }}
            onSubmit={methods.handleSubmit(onSubmit)}
         >
            {children}
         </form>
      </FormProvider>
   );
};
