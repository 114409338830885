import React from 'react'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { classnames } from 'tss-react/tools/classnames'

type ButtonV2Props = {
    text: string
    textColor?: string
    backgroundColor?: string
    borderColor?: string
    width: string
    endIcon?: any
    startIcon?: any
    onClick: () => void;
    disabled: boolean;
}

export const BoxltyButtonV2: React.FC<ButtonV2Props> = ({ text, textColor, backgroundColor, disabled, borderColor, endIcon, startIcon, onClick, width }) => {
    const { classes } = useStyles()



    return (
        <>
            <Button onClick={onClick} endIcon={endIcon} startIcon={startIcon} disableElevation disabled={disabled} variant='contained' classes={{ root: classes.root }} sx={{ width: width, backgroundColor: backgroundColor, border: `2px solid ${borderColor}`, color: textColor, '&:hover': { backgroundColor: backgroundColor } }}>
                {text}
            </Button>
        </>
    )
}


const useStyles = makeStyles({ name: 'Boxlty-Button-v2' })((theme) => ({
    root: {
        height: '35px',
        borderRadius: '26px',
        fontSize: '14px'
    }
}))