import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
   AlertColor,
   Box,
   Checkbox,
   Chip,
   FormControl,
   InputLabel,
   ListItemText,
   MenuItem,
   OutlinedInput,
   Select,
   SelectChangeEvent,
   useTheme,
} from "@mui/material";
import ModalTransparentLayer from "../common/ModalTransparentLayer";
import { fadeIn, fadeOut, moveElement } from "../../utils/genericUtils";
import RoundedButton from "../common/boxlty.button";
import {
   addTeamMemberWithProperty,
   userInviteSaasFlow,
   getUserPropertyList,
   getUserLockList,
   lenderInviteSaasFlow,
} from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";
import { LenderInviteSaasFlow } from "../../contexts/api/api.types";

interface position {
   top: string;
   left: string;
   right: string;
   bottom: string;
}

interface LockType {
   lockKey: string;
   nickName: string;
}

interface ActionButtonModalProps {
   id?: string;
   isVisible: boolean;
   openPosition: position;
   closedPosition: position;
   setPanel: (value: boolean) => void;
   setSnackbar: (value: boolean) => void;
   setSnackbarStatus: (value: AlertColor) => void;
   setSnackbarMessage: (value: string) => void;
   refresh: boolean;
}

function AddPartnerModal(props: ActionButtonModalProps) {
   const theme = useTheme();
   const [modalVisible, setModalVisible] = useState(props.isVisible);
   const [isInitialRender, setIsInitialRender] = useState(true);
   const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
   const [inviteParterOpen, setInvitePartnerOpen] = useState(false);
   const [email, setEmail] = useState("");
   const [companyName, setCompanyName] = useState("");
   const [locksArray, setLocksArray] = useState<LockType[]>([]);
   const [locksSelected, setLocksSelected] = useState<LockType[]>([]);
   const { user } = useUserState();
   const addMemberTransparentModalId = "add-member-transparent-layer";
   const TransparentLayerTransitionTime = 0.3;
   const ModalTransitionTime = 0.5;
   useEffect(() => {
      if (!isInitialRender) toggle();
      setIsInitialRender(false);
      if (isInitialRender && props.isVisible == true) toggle(true);
   }, [props.isVisible, modalVisible]);

   useEffect(() => {
      const getUserIds = () => {
         getUserLockList().then((res) => {
            if (
               res &&
               res.data &&
               res.data.result &&
               res.data.result.length > 0
            ) {
               let userIdArray: LockType[] = [];
               res.data.result.forEach(function (value: any) {
                  if (value.lockKey !== undefined && value.lockKey !== null) {
                     const lockInstance: LockType = {
                        lockKey: value.lockKey.toString(),
                        nickName: value.lockNickName,
                     };
                     userIdArray.push(lockInstance);
                  }
               });
               setLocksArray(userIdArray);
               //console.log(userIdArray);
            }
         });
      };
      getUserIds();
   }, [user?.userId]);

   useEffect(() => {
      if (email !== "") {
         setSaveButtonDisabled(false);
      } else {
         setSaveButtonDisabled(true);
      }
   }, [email]);

   // useEffect(() => {
   //   //console.log("t or f", saveButtonDisabled);
   // }, [saveButtonDisabled]);

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggle(mode?: boolean | void) {
      if (mode != null) {
         toggleModal(mode);
         toggleTransparentLayer(mode);
         setModalVisible(mode);
      } else {
         toggleModal();
         toggleTransparentLayer();
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleTransparentLayer(mode: boolean | void) {
      //get transparent layer by id
      let transparentLayer: HTMLElement = document.querySelector(
         "#" + addMemberTransparentModalId
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      }
      //manual mode not passed
      if (modalVisible) {
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      } else {
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleModal(mode: boolean | void) {
      let modal: HTMLElement = document.querySelector(
         "#" + props.id
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
         return;
      }

      if (modalVisible) {
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
      } else {
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
      }
   }

   async function validateInput(input: string) {
      // Regular expression for matching email addresses
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Regular expression for matching phone numbers (basic example)
      const phoneRegex = /^[0-9]{10}$/;

      if (emailRegex.test(input) || phoneRegex.test(input)) {
         return true;
      }
      return false;
   }

   const handleChange = (event: SelectChangeEvent<string[]>) => {
      const selectedKeys = event.target.value as string[]; // cast to string array

      // Update locksSelected by filtering locksArray based on selected keys
      setLocksSelected(
         locksArray.filter((lock) => selectedKeys.includes(lock.lockKey))
      );
   };

   const renderValue = (selected: LockType[]) => {
      return (
         <Box style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {selected.map((lock) => (
               <Chip key={lock.lockKey} label={lock.nickName} />
            ))}
         </Box>
      );
   };

   const invitePartnerAndAssignLocks = async () => {
      //console.log("removinng member from listing ===>", selectedListing);
      let requestBody: LenderInviteSaasFlow;
      const locksArray = locksSelected.map((lock: LockType) => {
         return lock.lockKey;
      });
      requestBody = {
         lockKey: locksArray,
         companyName: companyName,
         contactInput: email,
         expectedRole: 1,
         pmUserId: user?.userId,
      };
      const res = await lenderInviteSaasFlow(requestBody);
      //console.log(res);
      if (res.data.result.enterpriseUpdate == 2) {
         closePopup();
         props.setSnackbarMessage(`Invite sent to ${email}.`);
         props.setSnackbarStatus("success");
         props.setSnackbar(true);
         if (props.refresh) {
            window.location.reload();
         } else {
            toggle(false);
         }
      } else if (
         res.data.result.enterpriseUpdate == 1 ||
         res.data.result.enterpriseUpdate == 0
      ) {
         //requires updates
         closePopup();
         props.setSnackbarStatus("error");
         props.setSnackbarMessage(res.data.message);
         props.setSnackbar(true);
      } else if (res.data.result.userInvited == 1) {
         closePopup();
         props.setSnackbarStatus("error");
         props.setSnackbarMessage(res.data.message);
         props.setSnackbar(true);
      } else {
         if (res.data.message) {
            closePopup();
            props.setSnackbarStatus("error");
            props.setSnackbarMessage(res.data.message);
            props.setSnackbar(true);
            // toggle(false);
         } else {
            closePopup();
            props.setSnackbarStatus("error");
            props.setSnackbarMessage(
               "Invite send failed. Please try again later."
            );
            props.setSnackbar(true);
            // toggle(false);
         }
      }
      // setEmail("");
      // setCompanyName("");
      // setLocksSelected([]);
      // closePopup();
      // setTimeout(() => {
      //    window.location.reload();
      // }, 1000);
   };

   const closePopup = () => {
      setInvitePartnerOpen(false);
   };

   const useStyles = makeStyles({ name: "AddMemberModal" })((theme) => ({
      ModalComponentContainer: {
         overflow: "hidden",
         "@media (max-width: 600px)": {
            display: "none",
         },
      },
      AddMemberModal: {
         transition: `right ${ModalTransitionTime}s linear`,
         position: "absolute",
         zIndex: 25,
         backgroundColor: "white",
         // opacity : '0.7',
         borderRadius: "20px",
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
         bottom: props.closedPosition.bottom,
         right: props.closedPosition.right,
         padding: "20px",
         minHeight: "260px",
         minWidth: "400px",
         maxWidth: "400px",
      },
      modalHeader: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-start",
         marginBottom: "10px",
      },
      modalSubHeader: {
         display: "flex",
         alignItems: "baseline",
         justifyContent: "flex-start",
         marginBottom: "10px",
      },
      headerText: {
         margin: "0",
         textAlign: "left",
         width: "98%",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      subHeaderText: {
         margin: "0",
         textAlign: "left",
         width: "fitcontent",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      exitX: {
         marginLeft: "auto",
         fontSize: "25px",
         cursor: "pointer",
         color: theme.palette.common.lightGray,
      },
      ModalFieldRow: {
         display: "flex",
         alignItems: "center",
         // justifyContent: "space-evenly",
      },
      ModalInputBox: {
         display: "flex",
         flexDirection: "column",
         marginBottom: "19px",
         marginRight: "10px",
      },
      ModalInput: {
         border: "none",
         outline: "none",
         width: "100%",
         color: theme.palette.common.darkGray,
         fontFamily: theme.fonts.secondary.med,
         fontWeight: "100",
         textTransform: "none",
         marginTop: "10px",
         fontSize: "14px",
         paddingLeft: "2px",
         "& .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input": {
            paddingBottom: "0px !important",
         },
      },
      SelectLabel: {
         fontSize: "14px",
         color: "rgba(0, 0, 0, 0.6) !important",
         fontFamily: theme.fonts.secondary.med,
         fontWeight: "100",
         left: "-11px",
      },
      InputSubLine: {
         width: "100%",
         height: "1px",
         maxHeight: "1px",
         minHeight: "1px",
         backgroundColor: theme.palette.common.darkGray,
      },
      HalfWidthInput: {
         width: "50%",
      },
      FullWidthInput: {
         width: "100%",
      },
      SeventyFiveWidthInput: {
         width: "75%",
      },
      TwentyFiveWidthInput: {
         width: "25%",
      },
      AssignLocksSubtext: {
         whiteSpace: "nowrap",
         fontSize: "13px",
         fontFamily: theme.fonts.primary.med,
         color: theme.palette.common.darkGray,
         margin: "0",
         marginLeft: "10px",
      },
      SaveSection: {
         marginTop: "150px",
         marginBottom: "10px",
      },
      ActionsPopupOverlay: {
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 9998, // Adjust z-index as needed
      },
      PopupCentered: {
         top: "50%",
         left: "50%",
         //transform: "translate(-50%, -50%)",
         zIndex: 9999,
         minHeight: "200px",
         minWidth: "400px",
         backgroundColor: "white",
         borderRadius: "25px",
      },
      PopupHeader: {
         textAlign: "center",
         fontWeight: "bold",
         fontSize: "21px",
         fontFamily: theme.fonts.secondary.bold,
      },
      PopupText: {
         textAlign: "center",
         fontSize: "14px",
         marginLeft: "auto",
         marginRight: "auto",
         width: "50%",
      },
      PopupOptionsSection: {
         display: "flex",
         marginTop: "50px",
         justifyContent: "center",
         marginBottom: "25px",
      },
      PopupButtonsSection: {
         display: "flex",
         marginTop: "50px",
         justifyContent: "center",
         marginBottom: "25px",
      },
   }));

   let { classes } = useStyles();

   return (
      <>
         {/* Component Container */}
         <section className={classes.ModalComponentContainer}>
            {/* TransparentLayer */}
            <ModalTransparentLayer
               id={addMemberTransparentModalId}
               transitionTime={TransparentLayerTransitionTime}
            />

            {/* ModdalConatiner */}
            <section id={props.id} className={classes.AddMemberModal}>
               {/* header row */}
               <div className={classes.modalHeader}>
                  <h2 className={classes.headerText}>Add Partner</h2>
                  <span
                     className={classes.exitX}
                     onClick={() => {
                        toggle(false);
                     }}
                  >
                     &times;
                  </span>
               </div>

               {/* Field Rows */}

               {/* Row 1 */}
               {/* <div className={classes.ModalFieldRow}>
            <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="First Name"
              />
              <div className={classes.InputSubLine}></div>
            </div>

            <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="Last Name"
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div> */}
               {/* Row 2 */}
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <input
                        className={classes.ModalInput}
                        type="email"
                        placeholder="Email"
                        onChange={async (e) => {
                           setEmail(e.target.value);
                        }}
                     />
                     <div className={classes.InputSubLine}></div>
                  </div>
               </div>
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <input
                        className={classes.ModalInput}
                        type="name"
                        placeholder="Company Name"
                        onChange={async (e) => {
                           setCompanyName(e.target.value);
                        }}
                     />
                     <div className={classes.InputSubLine}></div>
                  </div>
               </div>
               <div
                  className={classes.ModalFieldRow}
                  style={{ marginTop: "20px" }}
               >
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <FormControl sx={{ width: "300px" }}>
                        <InputLabel id="locks-selected-label">
                           Assign Locks
                        </InputLabel>
                        <Select
                           labelId="locks-selected-label"
                           //size="small"
                           variant="standard"
                           multiple
                           value={locksSelected.map((lock) => lock.lockKey)} // Pass only lockKey for value
                           onChange={handleChange}
                           input={<OutlinedInput label="Assign Locks" />}
                           renderValue={() => renderValue(locksSelected)} // Call renderValue with locksSelected
                        >
                           {locksArray.map((lock, index) => (
                              <MenuItem key={index} value={lock.lockKey}>
                                 <Checkbox
                                    checked={locksSelected.some(
                                       (selectedLock) =>
                                          selectedLock.lockKey == lock.lockKey
                                    )}
                                 />
                                 <ListItemText primary={lock.nickName} />
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <p style={{ fontSize: "12px", marginTop: "2px" }}>
                        (Optional)
                     </p>
                     {/* <div className={classes.InputSubLine}></div> */}
                  </div>
               </div>

               {/* Save Section */}
               <section className={classes.SaveSection}>
                  <RoundedButton
                     color={"primary"}
                     listener={async () => {
                        const valid = await validateInput(email);
                        if (valid) {
                           setInvitePartnerOpen(true);
                        }
                     }}
                     showArrow={false}
                     showShadow={false}
                     text={"SEND INVITE"}
                     disabled={saveButtonDisabled}
                  />

                  {inviteParterOpen && (
                     <div className={classes.ActionsPopupOverlay}>
                        <div className={classes.PopupCentered}>
                           {/* Popup content */}
                           <p className={classes.PopupHeader}>INVITE PARTNER</p>
                           <p className={classes.PopupText}>
                              By continuing, you are inviting this person to
                              your InstaShow account.
                           </p>
                           <div className={classes.PopupOptionsSection}>
                              <RoundedButton
                                 text="CANCEL"
                                 listener={closePopup}
                                 boldText={true}
                              />
                              <RoundedButton
                                 text="INVITE"
                                 listener={invitePartnerAndAssignLocks}
                                 color="primary"
                                 boldText={true}
                              />
                           </div>
                        </div>
                     </div>
                  )}
               </section>
            </section>
         </section>
      </>
   );
}

export default AddPartnerModal;
