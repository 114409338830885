import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Avatar, IconButton } from '@mui/material'
import { useCreateListingState, useCreateListingStateRef } from '../../../../contexts/create-listing/create-listing.context';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react'
import { Invite, TeamMember } from './forms.types'
export const AssignedMemberComponent: React.FC<{ teamMember: TeamMember, handleRemove: (teamMemberId: number, propertyId: number) => void}> = ({ teamMember, handleRemove }) => {
    const { classes } = useStyles()
    const [showCancel, setShowCancel] = useState(false)
    const { propertyId } = useCreateListingState()
    return (
        <>
            <Grid container direction={'row'} alignItems='center' justifyContent={'space-between'} display='flex' className={classes.mainContainer} onMouseEnter={() => { setShowCancel(true)}} onMouseLeave={() => { setShowCancel(false)}}>
                <Grid item >
                    <Grid container direction={'row'} display='flex'>
                        <Grid item sx={{ paddingRight: '10px' }}>
                            <Avatar src={`https://boxlty-media.s3.amazonaws.com/user-profile/${teamMember.profileImage}`} />
                        </Grid>
                        <Grid item>
                            <Grid container direction={'column'}>
                                <Grid item className={classes.name}>
                                    <span>{teamMember.fullName ? teamMember.fullName : ""}</span>
                                </Grid>
                                <Grid item>
                                    <span>{teamMember.email}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <span style={{ borderRadius: '50%', backgroundColor: 'white', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', visibility: showCancel ? 'visible' : 'hidden', }} onClick={() => { handleRemove(teamMember.userId, parseInt(propertyId!))}}>
                        <CloseOutlinedIcon fontSize='small' style={{ color:'red'}} />
                    </span>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles({ name: "user-invite-component" })((theme) => ({
    mainContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '5px',
        width: '90%',
        textOverflow: 'ellipsis',
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: '5px',
        '&:hover': {
            backgroundColor: '#EAEAEA',
            cursor: 'pointer'
        }
    },
    name: {
        fontSize: '18px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
    },
    
}))