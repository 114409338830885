import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Autocomplete, OutlinedInput, Box, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react'
import { classnames } from 'tss-react/tools/classnames';
import { Tour } from '../../contexts/tours/tours.context.types'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Navigate, useNavigate } from 'react-router-dom';

type IInputSearchProps = {
  items: Tour[]
}

export const ToursPageSearchInput: React.FC<IInputSearchProps> = ({ items }) => {
  const [searchValue, setSearchValue] = useState<Tour | null>(null)
  const { classes } = useStyles()
  const navigate = useNavigate()
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: Tour) => option.propertyName,
  });

  return (
    <>
      <Autocomplete
        options={items}
        filterOptions={filterOptions}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.bookingId} onClick={() => { navigate(`/tours/${option.bookingId}/details`)}}>
              {option.propertyName}
            </li>
          )
        }}
        
        renderInput={(params) => <TextField {...params} placeholder="Search Tours" variant='outlined' fullWidth size='small' InputProps={{ ...params.InputProps, startAdornment: (<SearchIcon  style={{ color: '#a2a2a2',  }} />) }} sx={{ '&.MuiTextField-root': { borderRadius: "25px" }, '&:.MuiTextField-input': { borderRadius: '26px', } }} />}
        sx={{ "& .MuiAutocomplete-inputRoot": { borderRadius: "26px" } }}
      />
    </>
  )
}


const useStyles = makeStyles({})((theme) => ({
  root: {

  }
}))