import { DirectionsRailwayFilledOutlined } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import mailIcon from "../../styles/assets/logos/mail-icon.svg";
import { Grid, useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";

function RegisterAreYouRealtorComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [answer, setAnswer] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {}, [props.defaultValue]);

  function setYes() {
    // (document.querySelector("#yes") as HTMLBaseElement).style.background =
    //   "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)";
    // (document.querySelector("#yes span") as HTMLBaseElement).style.color =
    //   "white";

    // (document.querySelector("#no") as HTMLBaseElement).style.background =
    //   "initial";
    // (document.querySelector("#no span") as HTMLBaseElement).style.color =
    //   "initial";

    setAnswer(true);
    setNextButtonDisabled(false);
    nextStep(true);
  }

  function setNo() {
    // (document.querySelector("#no") as HTMLBaseElement).style.background =
    //   "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)";
    // (document.querySelector("#no span") as HTMLBaseElement).style.color =
    //   "white";

    // (document.querySelector("#yes") as HTMLBaseElement).style.background =
    //   "initial";
    // (document.querySelector("#yes span") as HTMLBaseElement).style.color =
    //   "initial";
    setAnswer(false);
    setNextButtonDisabled(false);
    nextStep(false);
  }

  function nextStep(clicked: boolean) {
    if (props.onSubmitIsRealtor)
      props.onSubmitIsRealtor(
        clicked,
        props.nextStepOption1,
        props.nextStepOption2
      );
  }

  return (
    <div className={classes.BodyContainer}>
      <Grid container direction={"column"} justifyContent="center">
        <h2 className={classes.TitleText}>Are you a Realtor<sup>®</sup>?</h2>
        <Grid
          container
          direction={"column"}
          justifyContent="center"
          alignItems="center"
        >
          <p className={classes.DescriptionText}>
            Are you a licensed Realtor<sup>®</sup> planning to use <br />
            InstaShow as a showing or buying agent?
          </p>

          {/* <div className={classes.outerInputBox}>
            <RoundedButton
              id={"yes"}
              listener={setYes}
              boldText={true}
              showShadow={true}
              showArrow={false}
              arrowDirection={"right"}
              color="white"
              text="YES"
            />

            <RoundedButton
              id={"no"}
              listener={setNo}
              boldText={true}
              showShadow={true}
              showArrow={false}
              arrowDirection={"right"}
              color="white"
              text="NO"
            />
          </div> */}

          <div className={classes.registerButtonsContainer}>
            {/* <RoundedButton
              id={"back"}
              listener={() => {
                props.onPreviousStep(props.backStepOption1);
              }}
              boldText={false}
              showShadow={false}
              showArrow={true}
              arrowDirection={"left"}
              color="transparent"
              text="BACK"
            />

            <RoundedButton
              id={"next"}
              listener={nextStep}
              disabled={nextButtonDisabled}
              boldText={false}
              showShadow={true}
              showArrow={true}
              arrowDirection={"right"}
              color="primary"
              text="NEXT"
            /> */}
            <RoundedButton
              id={"yes"}
              listener={setYes}
              boldText={true}
              showShadow={true}
              showArrow={false}
              arrowDirection={"right"}
              color="white"
              text="YES"
            />

            <RoundedButton
              id={"no"}
              listener={setNo}
              boldText={true}
              showShadow={true}
              showArrow={false}
              arrowDirection={"right"}
              color="white"
              text="NO"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  name: "RegisterRequestCompanyAssociateComponent",
})((theme) => ({
  BodyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },
  TitleText: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "50px !important",
    margin: "0",
    textAlign: "center",
  },
  RoundedButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    background:
      "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)",
    borderRadius: "10px",
    padding: "1px",
    width: "7rem",
    cursor: "pointer",
    height: "46px",
    color: "white",
    transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
    marginBottom: "0.4rem",
  },
  InnerButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "11px",
    paddingRight: "11px",
  },
  ButtonText: {
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    fontSize: "19px",
    letterSpacing: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerRequestLicensecontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    height: "100%",
    marginTop: "10%",
    minHeight: "100vh",
  },

  registerRequestLicenseHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
  },
  requestLicenseSubtext: {
    fontSize: "12px",
    fontFamily: theme.fonts.primary.light,
    maxWidth: "310px",
    marginBottom: "20px",
  },
  requestLicenseLink: {
    color: theme.palette.common.primary,
  },
  checkboxcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  IAgreeText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "13px",
    fontFamily: theme.fonts.secondary.bold,
  },
  outerInputBox: {
    height: "70px",
    display: "flex",
    alignItems: "center",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "120px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerRequestLicenseInput: {
    border: "none",
    outline: "none",
    width: "80%",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
  },
  registerDividerLine: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.darkGray,
    marginBottom: "100px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  DescriptionText: {
    // marginTop: "4rem",
    textAlign: "left",
    color: "black",
    lineHeight: "22px",
  },
}));

export default RegisterAreYouRealtorComponent;
