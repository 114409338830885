import React from "react";
import { useState, useEffect } from "react";
import AppBody from "../../components/body/main.body.component.jsx";
import { makeStyles } from "tss-react/mui";
import {
   addPhoneNumberToListing,
   getUserProfile,
   updateUserEmailPhone,
} from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";
import BoxltyCheckbox from "../../components/common/boxlty.checkbox";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { HeaderButton } from "../../components/listings/create/forms/header.button";
import { ReactComponent as VerifiedBadge } from "../../styles/assets/Icons/verified-badge.svg";
import RoundedButton from "../../components/common/boxlty.button";
import RoundedButtonWhite from "../../components/common/boxlty.button.white";

interface PersonalInformationProps {
   name: string;
   email: string;
   phone: string;
   addressOne: string;
   city: string;
   state: string;
   zip: string;
   dateOfBirth: string;
   imageUrl: string;
   addNumberToListings: boolean;
   // Add any other properties you need
}

export const PersonalInformation: React.FC = () => {
   const { classes } = useStyles();
   const { user } = useUserState();
   const [editActive, setEditActive] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarStatus, setSnackbarStatus] = useState<AlertColor>("success");
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [isAddPhoneListingChecked, setIsAddPhoneListingChecked] =
      useState(false);

   // Define state variables to store user profile data
   const [userData, setUserData] = useState<PersonalInformationProps>({
      name: "",
      email: "",
      phone: "",
      addressOne: "",
      city: "",
      state: "",
      zip: "",
      dateOfBirth: "",
      imageUrl: "",
      addNumberToListings: false,
   });

   const [originalUserData, setOriginalUserData] =
      useState<PersonalInformationProps>({
         name: "",
         email: "",
         phone: "",
         addressOne: "",
         city: "",
         state: "",
         zip: "",
         dateOfBirth: "",
         imageUrl: "",
         addNumberToListings: false,
      });

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await getUserProfile({
               userId: user?.userId.toString(),
            });
            const userProfileData = response.data.result;
            // Update the state with the retrieved user profile data
            const fullName =
               userProfileData.firstName + " " + userProfileData.lastName;
            const newUserData = {
               name: capitalizeText(fullName),
               email: userProfileData.emailId,
               phone: userProfileData.phone,
               addressOne: capitalizeText(userProfileData.address),
               city: capitalizeText(userProfileData.city),
               state: userProfileData.state,
               zip: userProfileData.zipcode,
               dateOfBirth: formatDate(userProfileData.dob),
               imageUrl: userProfileData.userImage,
               addNumberToListings:
                  userProfileData.isContactHidden === "0" ? true : false,
            };
            setIsAddPhoneListingChecked(
               userProfileData.isContactHidden === "0"
            );
            setUserData(newUserData);
            setOriginalUserData(newUserData);
         } catch (error) {
            console.error("Error fetching user profile:", error);
         }
      };

      fetchData(); // Call the async function immediately
   }, [user]);

   const handleEditProfile = () => setEditActive(!editActive);

   const handleCancelEdit = () => {
      setUserData(originalUserData);
      setEditActive(false);
   };

   const handleSaveEdit = async () => {
      let data = {
         email: userData.email,
         phone: userData.phone,
      };
      const response = await updateUserEmailPhone(data);
      if (response.data.status === true) {
         setSnackbarOpen(true);
         setSnackbarMessage("Successfully updated user information");
         setSnackbarStatus("success");
         setOriginalUserData(userData);
         setEditActive(false);
      } else {
         setSnackbarOpen(true);
         setSnackbarMessage("Error while updating user information");
         setSnackbarStatus("error");
         setEditActive(false);
      }
   };

   const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      field: keyof PersonalInformationProps
   ) => {
      setUserData({ ...userData, [field]: e.target.value });
   };

   const addNumberToListing = async (status: number) => {
      //0 = not checked, 1 = checked
      await addPhoneNumberToListing({ status: status });
   };

   function formatDate(inputDate: string) {
      if (inputDate === "") {
         return "N/A";
      }
      const dateParts = inputDate.split("-");
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];

      const months = [
         "January",
         "February",
         "March",
         "April",
         "May",
         "June",
         "July",
         "August",
         "September",
         "October",
         "November",
         "December",
      ];

      const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
         day,
         10
      )}, ${year}`;
      return formattedDate;
   }

   function capitalizeText(text: string) {
      const words = text.toLowerCase().split(" ");
      const capitalizedWords = words.map((word) => {
         return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
   }

   function formatPhoneNumber(phoneNumber: string) {
      const cleaned = phoneNumber.replace(/\D/g, "");
      const isValidNumber = /^[0-9]{10,11}$/.test(cleaned);

      if (isValidNumber) {
         if (cleaned.length === 10) {
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(
               3,
               6
            )}-${cleaned.slice(6)}`;
         } else if (cleaned.length === 11) {
            return `${cleaned[0]}(${cleaned.slice(1, 4)}) ${cleaned.slice(
               4,
               7
            )}-${cleaned.slice(7)}`;
         }
      }
      return "N/A";
   }

   return (
      <>
         <AppBody>
            {!user?.idVerify && (
               <Snackbar
                  open={true}
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               >
                  <Alert severity="warning" color="warning">
                     Your account is unverified. Please access the mobile app to
                     verify.
                  </Alert>
               </Snackbar>
            )}
            <Snackbar
               open={snackbarOpen}
               autoHideDuration={4000}
               sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
               onClose={() => {
                  setSnackbarOpen(false);
               }}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
               <Alert
                  onClose={() => {
                     setSnackbarOpen(false);
                  }}
                  severity={snackbarStatus}
                  color={snackbarStatus}
               >
                  {snackbarMessage}
               </Alert>
            </Snackbar>
            <section className={classes.DashboardOuterLayout}>
               {/* item 1 */}
               <section className={classes.ItemOneContainer}>
                  <h1 className={classes.DashboardTitle}>
                     Personal Information
                  </h1>

                  <section className={classes.DashboardBlockedSection}>
                     <div className={classes.InnerItemContainer}>
                        <div className={classes.InnerItemSideLeft}>
                           {user?.idVerify ? (
                              <HeaderButton
                                 color="black"
                                 backgroundColor="white"
                                 borderColor="#66E0B4"
                                 text="VERIFIED"
                                 clickable={false}
                              />
                           ) : (
                              <HeaderButton
                                 color="black"
                                 backgroundColor="white"
                                 borderColor="lightgray"
                                 text="UNVERIFIED"
                                 width="115px"
                                 clickable={false}
                              />
                           )}
                           <div className={classes.NameContainer}>
                              {" "}
                              <h2 className={classes.InnerItemSideMainHeader}>
                                 {userData.name}
                              </h2>
                              {user?.idVerify === 1 && <VerifiedBadge />}
                           </div>
                           <div className={classes.InfoContainer}>
                              <div className={classes.InnerItemSideSubSection}>
                                 <h2
                                    className={classes.InnerItemsSideSubHeader}
                                 >
                                    EMAIL
                                 </h2>
                                 {editActive ? (
                                    <input
                                       type="text"
                                       value={userData.email}
                                       onChange={(e) =>
                                          handleInputChange(e, "email")
                                       }
                                       className={classes.InnerItemsSideSubText}
                                    />
                                 ) : (
                                    <h2
                                       className={classes.InnerItemsSideSubText}
                                    >
                                       {userData.email}
                                    </h2>
                                 )}
                              </div>

                              <div className={classes.InnerItemSideSubSection}>
                                 <h2
                                    className={classes.InnerItemsSideSubHeader}
                                 >
                                    PHONE
                                 </h2>
                                 {editActive ? (
                                    <input
                                       type="text"
                                       value={userData.phone}
                                       onChange={(e) =>
                                          handleInputChange(e, "phone")
                                       }
                                       className={classes.InnerItemsSideSubText}
                                    />
                                 ) : (
                                    <div className={classes.PhoneContainer}>
                                       <h2
                                          className={
                                             classes.InnerItemsSideSubText
                                          }
                                       >
                                          {formatPhoneNumber(userData.phone)}
                                       </h2>
                                       <div className={classes.PhoneNumListing}>
                                          <div
                                             className={
                                                classes.checkboxContainer
                                             }
                                          >
                                             <BoxltyCheckbox
                                                id={"checkbox"}
                                                checked={
                                                   isAddPhoneListingChecked
                                                }
                                                height={"11px"}
                                                width={"11px"}
                                                disabled={false}
                                                onChange={async () => {
                                                   setIsAddPhoneListingChecked(
                                                      !isAddPhoneListingChecked
                                                   );
                                                   await addNumberToListing(
                                                      isAddPhoneListingChecked
                                                         ? 1
                                                         : 0
                                                   );
                                                }}
                                             />
                                             <h4
                                                className={classes.QuestionText}
                                             >
                                                Add phone number to listing
                                             </h4>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                              </div>

                              <div className={classes.InnerItemSideSubSection}>
                                 <h2
                                    className={classes.InnerItemsSideSubHeader}
                                 >
                                    ADDRESS
                                 </h2>
                                 <h2 className={classes.InnerItemsSideSubText}>
                                    {userData.addressOne}
                                 </h2>
                                 {/* <h2 className={classes.InnerItemsSideSubText}>Address 2</h2> */}
                                 <h2 className={classes.InnerItemsSideSubText}>
                                    {userData.city}
                                    {userData.state.length > 0 ||
                                    userData.zip.length > 1
                                       ? ","
                                       : ""}{" "}
                                    {userData.state}{" "}
                                    {userData.zip.length > 1
                                       ? userData.zip
                                       : ""}
                                 </h2>
                              </div>

                              <div className={classes.InnerItemSideSubSection}>
                                 <h2
                                    className={classes.InnerItemsSideSubHeader}
                                 >
                                    DATE OF BIRTH
                                 </h2>
                                 <h2 className={classes.InnerItemsSideSubText}>
                                    {/* TODO better way to check this*/}
                                    {userData.dateOfBirth !== "undefined NaN, "
                                       ? userData.dateOfBirth
                                       : ""}
                                 </h2>
                              </div>
                           </div>
                           {(user?.accountType === 5 ||
                              user?.accountType === 6) && (
                              <div className={classes.editButtons}>
                                 {!editActive ? (
                                    <RoundedButton
                                       text="EDIT"
                                       listener={handleEditProfile}
                                       boldText={true}
                                       color="primary"
                                    />
                                 ) : (
                                    <RoundedButtonWhite
                                       text="CANCEL"
                                       listener={handleCancelEdit}
                                       boldText={true}
                                    />
                                 )}
                                 {editActive && (
                                    <RoundedButton
                                       text="SAVE"
                                       listener={handleSaveEdit}
                                       color="primary"
                                       boldText={true}
                                    />
                                 )}
                              </div>
                           )}
                        </div>
                        <div className={classes.InnerItemSideRight}>
                           <div className={classes.ProfileImageContainer}>
                              {userData.imageUrl !== undefined &&
                                 userData.imageUrl !== "" && (
                                    <img
                                       className={classes.ProfileImage}
                                       src={`https://boxlty-media.s3.amazonaws.com/user-profile/${userData.imageUrl}`}
                                       alt="Profile"
                                       style={{ borderRadius: "15px" }}
                                    />
                                 )}
                           </div>
                        </div>
                     </div>
                  </section>
               </section>
            </section>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "DashboardStyles" })((theme) => ({
   DashboardOuterLayout: {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
   },
   ItemOneContainer: {
      width: "80%",
      height: "100%",
   },
   DashboardTitle: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "40px",
      letterSpacing: "1px",
      fontWeight: "bold",
      margin: 0,
      marginBottom: "20px",
   },
   DashboardBlockedSection: {
      backgroundColor: "white",
      padding: "5px",
      marginTop: "30px",
      marginBottom: "30px",
      borderRadius: "15px",
      height: "80%",
      width: "75%",
      maxWidth: "1200px",
      minWidth: "300px",
      boxShadow: "6px 6px 12px #00000014;",
   },
   InnerItemContainer: {
      width: "95%",
      display: "flex",
      alignItems: "flex-start",
      paddingLeft: "20px",
      paddingTop: "15px",
      paddingRight: "20px",
   },
   InnerItemSideLeft: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
   },
   InnerItemSideRight: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
   },
   InnerItemSideMainHeader: {
      margin: "5px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "20px",
   },
   InnerItemSideSubSection: {
      marginLeft: "5px",
      marginTop: "15px",
   },
   InnerItemsSideSubHeader: {
      fontSize: "14px",
      color: "#a2a2a2",
      marginBottom: "0px",
      fontWeight: "normal",
   },
   InnerItemsSideSubText: {
      fontSize: "18px",
      marginTop: "2px",
      marginBottom: "2px",
      fontWeight: "normal",
   },
   ProfileImageContainer: {
      marginTop: "5px",
      marginLeft: "auto",
      marginBottom: "5px",
      height: "150px",
      width: "150px",
      overflow: "hidden",
   },
   ProfileImage: {
      height: "150px",
      width: "150px",
      objectFit: "cover",
   },
   PhoneNumListing: {
      color: "#a2a2a2",
      fontSize: "18px",
      marginLeft: "2rem",
      "@media (max-width: 1000px)": {
         marginLeft: "-2.2rem",
      },
   },
   checkboxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "0px",
      marginBottom: "5px",
   },
   QuestionText: {
      margin: 0,
      marginLeft: "5px",
      fontWeight: "100",
      fontSize: "18px",
      fontFamily: theme.fonts.primary.reg,
      color: theme.palette.common.medGray,
   },
   PhoneContainer: {
      display: "flex",
      // width: "80%",
      width: "28rem",
      "@media (max-width: 1000px)": {
         marginTop: "4%",
         width: "20rem",
      },
   },
   NameContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
   },
   editButtons: {
      display: "flex",
      marginTop: "50px",
      marginLeft: "-10px",
   },
   InfoContainer: {
      height: "250px",
   },
}));
