import React, { useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { S3DirectoryName } from "../../../contexts/api/api.types";
import {
  activateProperty,
  createPropertyStepOneGeneral,
  createPropertyStepSevenApproval,
  createPropertyStepThreeInstructions,
  createPropertyStepTwoPhotos,
} from "../../../contexts/api/api.functions";
import {
  useCreateListingState,
  useCreateListingDispatch,
  CreateListingActions,
} from "../../../contexts/create-listing/create-listing.context";
import {
  geocode,
  RequestType,
  setDefaults,
  setKey,
  setLanguage,
  setRegion,
} from "react-geocode";
import { Backdrop, CircularProgress } from "@mui/material";
import { makePropertyDraft } from "../../../contexts/api/api.functions";
import { MotionPhotosOffRounded } from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { generatePreSignedUrl } from "../../../contexts/api/api.functions";
import axios from "axios";
import { getListing } from "../../../contexts/api/api.functions";
const { v4: uuidv4 } = require("uuid");

export const createListingDefaultValues = {
  nickName: "",
  propertyCreateTypeId: "rental",
  price: "",
  address: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  propertyTypeId: "single-family",
  lotSize: "",
  sqFoot: "",
  bathrooms: "",
  bedrooms: "",
  parkingTypeId: "dedicated-garage",
  parkingSpace: "",
  propertyHeadline: "",
  generalInformation: "",
  utilities: "",
  petPolicyId: "allowed",
  petPolicyDescription: "",
  zlink: "",
  accessInstruction: "",
  exitInstruction: "",
  postTourInstruction: "",
  propertyId: "",
  lat: "",
  long: "",
};

export const ListingCreateFormContextGeneral: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [photoErrorOpen, setPhotoErrorOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false);
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  setKey(apiKey!);
  setLanguage("en");
  setRegion("en");
  const { propertyId, featurePhoto, photos, featurePhotoPath, photoPaths } =
    useCreateListingState();
  const dispatch = useCreateListingDispatch();
  const schema = yup.object().shape({
    nickName: yup
      .string()
      .min(3, "Min. 3 characters")
      .max(30, "Property Nickname cannot be longer than 30 characters.")
      .required(),
    propertyCreateTypeId: yup.string().required("Property Type is required"),
    price: yup
      .string()
      .min(3, "Minimum of 3 characters")
      .max(24)
      .trim()
      .matches(/^[0-9]*$/, "Price can only contain numbers.")
      .required("Price is required"),
    address: yup
      .string()
      .min(6, "Minimum of 6 characters")
      .max(50)
      .required("Address is required"),
    addressLine2: yup.string().max(12),
    city: yup.string().min(3, "Minimum of 3 characters").max(50).required(),
    state: yup.string().min(2, "Minimum of 2 characters").max(20).required(),
    zipCode: yup.string().min(5, "Minimum of 5 characters").max(5).required(),
    propertyTypeId: yup.string().required(),
    lotSize: yup.string().min(1).max(20).required(),
    sqFoot: yup.string().min(1).max(20).required(),
    bathrooms: yup.string().min(1).max(20).required(),
    bedrooms: yup.string().min(1).max(20).required(),
    parkingTypeId: yup.string().required(),
    parkingSpace: yup.string().min(1).max(20).required(),
    propertyHeadline: yup
      .string()
      .min(5, "Min. 5 characters")
      .max(75, "Max. 75 characters")
      .required(),
    generalInformation: yup
      .string()
      .min(5, "Min. 5 characters")
      .max(200, "Max. 200 characters")
      .required(),
    utilities: yup.string().when("propertyCreateTypeId", {
      is: "rental",
      then: (schema) =>
        schema
          .min(5, "Min. 5 characters")
          .max(200, "Max. 200 characters")
          .required(),
      otherwise: (schema) => schema.min(0).notRequired(),
    }),
    petPolicyId: yup.string().required(),
    petPolicyDescription: yup.string().when("propertyCreateTypeId", {
      is: "rental",
      then: (schema) =>
        schema
          .min(5, "Min. 5 characters")
          .max(200, "Max. 200 characters")
          .required(),
      otherwise: (schema) => schema.min(0).notRequired(),
    }),
    zlink: yup
      .string()
      .min(5, "Min. 5 characters")
      .max(50, "Max. 50 characters")
      .required(),
    accessInstruction: yup
      .string()
      .min(5, "Min. 5 characters")
      .max(200, "Max. 200 characters")
      .required(),
    exitInstruction: yup
      .string()
      .min(5, "Min. 5 characters")
      .max(200, "Max. 200 characters")
      .required(),
    postTourInstruction: yup
      .string()
      .min(5, "Min. 5 characters")
      .max(200, "Max. 200 characters")
      .required(),
    propertyId: yup.string(),
    lat: yup.string(),
    long: yup.string(),
  });

  const returnPropertyTypeString = (value: number) => {
    switch (value) {
      case 1:
        return "single-family";
      case 2:
        return "multi-family";
      case 3:
        return "townhome";
      case 4:
        return "apartment";
      default:
        return "single-family";
    }
  };

  const returnPropertyTypeNumber = (value: string) => {
    switch (value) {
      case "single-family":
        return "1";

      case "multi-family":
        return "2";
      case "townhome":
        return "3";
      case "apartment":
        return "4";
      default:
        return "1";
    }
  };

  const returnPetPolicyNumber = (value: string) => {
    switch (value) {
      case "allowed":
        return "1";
      case "not-allowed":
        return "4";
      default:
        return "4";
    }
  };

  const returnPetPolicyString = (value: string) => {
    switch (value) {
      case "1":
        return "allowed";
      case "4":
        return "not-allowed";
      default:
        return "not-allowed";
    }
  };

  const returnParkingTypeString = (value: string) => {
    switch (value) {
      case "1":
        return "dedicated-garage";
      case "2":
        return "on-street";
      case "3":
        return "none";
      default:
        return "on-street";
    }
  };

  const returnParkingTypeNumber = (value: string) => {
    switch (value) {
      case "dedicated-garage":
        return "1";
      case "on-street":
        return "2";
      case "none":
        return "3";
      default:
        return "2";
    }
  };

  const methods = useForm({
    defaultValues: createListingDefaultValues,
    resolver: yupResolver(schema),
  });

  const general = methods.watch();

  const [preview, setPreview] = useState<any>({});

  const fetchAndUpdateValues = async () => {
    if (propertyId) {
      setBackdropOpen(true);
      const res = await getListing(parseInt(propertyId));
      if (res && res.data && res.data.status && res.data.status === true) {
        if (res.data.result[0].screen1[0].propertyId === parseInt(propertyId)) {
          //// res.data.result[0].screen1[0].${propertyToSet}
          if (res.data.result[0].screen1[0].propertyName) {
            const value = res.data.result[0].screen1[0].propertyName;
            methods.setValue("nickName", value);
            dispatch({
              type: CreateListingActions.setNickName,
              payload: value,
            });
          }
          ///NEEDS ADDRESSED PROPERTY CREATE TYPE ID AND PROPERTY TYPE ID ARE COMING BACK FLIPPED
          if (res.data.result[0].screen1[0].propertyTypeId) {
            const value =
              res.data.result[0].screen1[0].propertyTypeId === 1
                ? "rental"
                : "sale";
            methods.setValue("propertyCreateTypeId", value);
          }
          if (res.data.result[0].screen1[0].propertySalePrice) {
            const value = res.data.result[0].screen1[0].propertySalePrice;
            methods.setValue("price", value);
          }
          if (res.data.result[0].screen1[0].propertyAddress) {
            const value = res.data.result[0].screen1[0].propertyAddress;
            methods.setValue("address", value);
          }
          if (res.data.result[0].screen1[0].apt) {
            const value = res.data.result[0].screen1[0].apt;
            methods.setValue("addressLine2", value);
          }
          if (res.data.result[0].screen1[0].propertyCity) {
            const value = res.data.result[0].screen1[0].propertyCity;
            methods.setValue("city", value);
          }
          if (res.data.result[0].screen1[0].propertyState) {
            const value = res.data.result[0].screen1[0].propertyState;
            methods.setValue("state", value);
          }
          if (res.data.result[0].screen1[0].propertyZipCode) {
            const value = res.data.result[0].screen1[0].propertyZipCode;
            methods.setValue("zipCode", value);
          }
          ///NEEDS ADDRESSED PROPERTY CREATE TYPE ID AND PROPERTY TYPE ID ARE COMING BACK FLIPPED
          if (res.data.result[0].screen1[0].propertyCreateTypeId) {
            const value = returnPropertyTypeString(
              res.data.result[0].screen1[0].propertyCreateTypeId
            );
            methods.setValue("propertyTypeId", value);
          }
          if (res.data.result[0].screen1[0].propertyLotSize) {
            const value = res.data.result[0].screen1[0].propertyLotSize;
            methods.setValue("lotSize", value);
          }
          if (res.data.result[0].screen1[0].propertySize) {
            const value = res.data.result[0].screen1[0].propertySize;
            methods.setValue("sqFoot", value);
          }
          if (res.data.result[0].screen1[0].propertyBathrooms) {
            const value = res.data.result[0].screen1[0].propertyBathrooms;
            methods.setValue("bathrooms", value);
          }
          if (res.data.result[0].screen1[0].propertyBedrooms) {
            const value = res.data.result[0].screen1[0].propertyBedrooms;
            methods.setValue("bedrooms", value);
          }
          if (res.data.result[0].screen1[0].propertyParkingTypeId) {
            const value = returnParkingTypeString(
              res.data.result[0].screen1[0].propertyParkingTypeId
            );
            methods.setValue("parkingTypeId", value);
          }
          if (res.data.result[0].screen1[0].propertyParkingSpace) {
            const value =
              res.data.result[0].screen1[0].propertyParkingSpace.toString();
            methods.setValue("parkingSpace", value);
          }
          if (res.data.result[0].screen1[0].propertyHeadline) {
            const value = res.data.result[0].screen1[0].propertyHeadline;
            methods.setValue("propertyHeadline", value);
          }
          if (res.data.result[0].screen1[0].generalInformation) {
            const value = res.data.result[0].screen1[0].generalInformation;
            methods.setValue("generalInformation", value);
          }
          if (res.data.result[0].screen1[0].propertyUtilities) {
            const value = res.data.result[0].screen1[0].propertyUtilities;
            methods.setValue("utilities", value);
          }
          if (res.data.result[0].screen1[0].propertyPetPolicyId) {
            const value = returnPetPolicyString(
              res.data.result[0].screen1[0].propertyPetPolicyId
            );
            methods.setValue("petPolicyId", value);
          }
          if (res.data.result[0].screen1[0].propertyPetPolicyDesc) {
            const value = res.data.result[0].screen1[0].propertyPetPolicyDesc;
            methods.setValue("petPolicyDescription", value);
          }
          if (res.data.result[0].screen1[0].propertyZLink) {
            const value = res.data.result[0].screen1[0].propertyZLink;
            methods.setValue("zlink", value);
          }
          if (
            res.data.result[0].screen3[0].propertyId === parseInt(propertyId)
          ) {
            if (res.data.result[0].screen3[0].propertyAccessInst) {
              const value = res.data.result[0].screen3[0].propertyAccessInst;
              methods.setValue("accessInstruction", value);
            }
            if (res.data.result[0].screen3[0].propertyExitInst) {
              const value = res.data.result[0].screen3[0].propertyExitInst;
              methods.setValue("exitInstruction", value);
            }
            if (res.data.result[0].screen3[0].propertyPostTourInst) {
              const value = res.data.result[0].screen3[0].propertyPostTourInst;
              methods.setValue("postTourInstruction", value);
            }
          }
          if (
            res.data.result[0].screen2 &&
            res.data.result[0].screen2.length > 0
          ) {
            if (
              (res.data.result[0].screen2[0].propertyFeaturePhoto &&
                res.data.result[0].screen2[0].propertyFeaturePhoto !== null) ||
              ""
            ) {
              const value = res.data.result[0].screen2[0].propertyFeaturePhoto;
              dispatch({
                type: CreateListingActions.setFeaturePhotoPath,
                payload: value,
              });
            }
            if (res.data.result[0].screen2[0].propertyPhoto !== null || "") {
              const paths = [];
              for (var i = 0; i < res.data.result[0].screen2.length; i++) {
                const value = res.data.result[0].screen2[i].propertyPhoto;
                paths.push(value);
              }
              dispatch({
                type: CreateListingActions.setPhotoPaths,
                payload: [...paths],
              });
            }
          }
        }
      }
      setBackdropOpen(false);
    } else {
      return;
    }
  };

  useEffect(() => {
    fetchAndUpdateValues();
  }, [propertyId]);

  const handleSubmitFunction = async (data: any) => {
    ////// propertyId exists so this is editing the already saved listing
    if (propertyId) {
      if (photoPaths && photoPaths.length === 0) {
        if (photos.length === 0) {
          setPhotoErrorOpen(true);
          return;
        }
      }
      if (!featurePhotoPath) {
        if (!featurePhoto) {
          setPhotoErrorOpen(true);
          return;
        } else if (featurePhoto && featurePhoto.file === "") {
          setPhotoErrorOpen(true);
          return;
        }
      }
      setBackdropOpen(true);
      dispatch({ type: CreateListingActions.setPreview, payload: data });
      let fullAdress: string;
      if (data.addressLine2 === "") {
        fullAdress = `${data.address} ${data.addressLine2}, ${data.city}, ${data.state} ${data.zipCode}`;
      } else {
        fullAdress = `${data.address}, ${data.city}, ${data.state} ${data.zipCode}`;
      }
      await geocode(RequestType.ADDRESS, fullAdress)
        .then(async (response) => {
          if (response.status !== "OK") {
            methods.setError("address", {
              type: "custom",
              message: "Could not get Lat and Lng for this address",
            });
            setError(
              "We could not geolocate this address, please change the address and try again."
            );
            setBackdropOpen(false);
            setErrorOpen(true);
            return;
          }
          if (
            response.status === "OK" &&
            response.results[0] &&
            response.results[0].partial_match
          ) {
            methods.setError("address", {
              type: "custom",
              message: "Could not get Lat and Lng for this address",
            });
            setError(
              "We could not geolocate this address, please change the address and try again."
            );
            setBackdropOpen(false);
            setErrorOpen(true);
            return;
          }
          // console.log(response);
          const { lat, lng } = response.results[0].geometry.location;
          const propertyTypeIdString = returnPropertyTypeNumber(
            data.propertyTypeId
          );
          const propertyParkingType = returnParkingTypeNumber(
            data.parkingTypeId
          );
          const petPolicyType = returnPetPolicyNumber(data.petPolicyId);
          const body = {
            propertyId: propertyId,
            nickName: data.nickName,
            propertyCreatetypeId:
              data.propertyCreateTypeId === "rental" ? "1" : "2", ///// 1 = Rent, 2 = Sell
            price: data.price,
            address: data.address,
            apt: data.addressLine2,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            propertyTypeId: propertyTypeIdString,
            lotSize: data.lotSize,
            sqFoot: data.sqFoot,
            bathrooms: data.bathrooms,
            bedrooms: data.bedrooms,
            parkingTypeId: propertyParkingType,
            parkingSpace: data.parkingSpace,
            propertyHeadline: data.propertyHeadline,
            generalInformation: data.generalInformation,
            utilities: data.utilities,
            petPolicyId: petPolicyType,
            petPolicyDescription: data.petPolicyDescription,
            zlink: data.zlink,
            lat: lat.toString(),
            long: lng.toString(),
            stepId: 1,
          };
          // console.log(body);
          const res = await createPropertyStepOneGeneral(body);
          if (res && res.data && res.data.status === true) {
            dispatch({
              type: CreateListingActions.setPropertyId,
              payload: res.data.result.pId.toString(),
            });
            const body3 = {
              stepId: 3,
              propertyId: res.data.result.pId.toString(),
              accessInstruction: data.accessInstruction,
              exitInstruction: data.exitInstruction,
              postTourInstruction: data.postTourInstruction,
            };
            const res3 = await createPropertyStepThreeInstructions(body3);

            if (res3 && res3.data && res3.data.status === true) {
              if (
                featurePhotoPath &&
                photoPaths &&
                photoPaths.length > 0 &&
                photos.length === 0
              ) {
                const bodyPhotos = {
                  propertyId: propertyId!.toString(),
                  stepId: 2,
                  mainImg: featurePhotoPath,
                  images: [...photoPaths],
                };
                const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos);
                if (
                  resPhoto &&
                  resPhoto.data &&
                  resPhoto.data.status === true
                ) {
                  setBackdropOpen(false);
                  setSuccessOpen(true);
                  return;
                } else {
                  setBackdropOpen(false);
                  return;
                }
              } else if (featurePhoto.file !== "" && photos.length > 0) {
                if (!propertyId) {
                  return;
                }
                let featureKey: string = uuidv4();
                let photosKeyArr: Array<string> = [];
                if (featurePhotoPath === null) {
                  const featureRequest = await generatePreSignedUrl({
                    directory: S3DirectoryName.PROPERTY,
                    fileName: featureKey,
                  });
                  if (
                    featureRequest &&
                    featureRequest.data &&
                    featureRequest.data.status === true
                  ) {
                    const featureUrl = featureRequest.data.result;
                    await axios
                      .put(featureUrl, featurePhoto.file)
                      .then((response) => {
                        // console.log(response)
                      })
                      .catch((error) => {
                        // console.log(error)
                      });
                  }
                }
                await Promise.all(
                  photos.map(async (photo) => {
                    let photoKey = uuidv4();

                    const res1 = await generatePreSignedUrl({
                      directory: S3DirectoryName.PROPERTY,
                      fileName: photoKey,
                    });
                    if (res1 && res1.data && res1.data.status === true) {
                      photosKeyArr.push(photoKey);
                      const photoUrl = res1.data.result;
                      await axios
                        .put(photoUrl, photo.file)
                        .then(() => {
                          // console.log(`Uploaded file ${photoKey}`)
                        })
                        .catch((error) => {
                          // console.log(error)
                        });
                    }
                  })
                );

                const body4 = {
                  propertyId: propertyId.toString(),
                  stepId: 2,
                  mainImg: featureKey,
                  images: [...photosKeyArr],
                };
                const res4 = await createPropertyStepTwoPhotos(body4);
                if (
                  res4 &&
                  res4.data &&
                  res4.data.status &&
                  res4.data.status === true
                ) {
                  setBackdropOpen(false);
                  setSuccessOpen(true);
                  return;
                } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
                }
              } else if (featurePhotoPath && photos.length > 0) {
                if (!propertyId) {
                  return;
                }
                let photosKeyArr: Array<string> = [];
                await Promise.all(
                  photos.map(async (photo) => {
                    let photoKey = uuidv4();

                    const res1 = await generatePreSignedUrl({
                      directory: S3DirectoryName.PROPERTY,
                      fileName: photoKey,
                    });
                    if (res1 && res1.data && res1.data.status === true) {
                      photosKeyArr.push(photoKey);
                      const photoUrl = res1.data.result;
                      await axios
                        .put(photoUrl, photo.file)
                        .then(() => {
                          // console.log(`Uploaded file ${photoKey}`)
                        })
                        .catch((error) => {
                          setBackdropOpen(false);
                          setError(
                            "We could not update the photos, please try again."
                          );
                          setErrorOpen(true);
                          return;
                        });
                    }
                  })
                );
                let body4: any;
                if (photoPaths) {
                  body4 = {
                    propertyId: propertyId.toString(),
                    stepId: 2,
                    mainImg: featurePhotoPath,
                    images: [...photoPaths, ...photosKeyArr],
                  };
                } else {
                  body4 = {
                    propertyId: propertyId.toString(),
                    stepId: 2,
                    mainImg: featurePhotoPath,
                    images: [...photosKeyArr],
                  };
                }
                const res4 = await createPropertyStepTwoPhotos(body4);
                if (
                  res4 &&
                  res4.data &&
                  res4.data.status &&
                  res4.data.status === true
                ) {
                  setBackdropOpen(false);
                  setSuccessOpen(true);
                  return;
                } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
                }
              } else if (
                featurePhoto.file !== "" &&
                photoPaths &&
                propertyId !== null
              ) {
                let featureKey: string = uuidv4();
                const featureRequest = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: featureKey,
                });
                if (
                  featureRequest &&
                  featureRequest.data &&
                  featureRequest.data.status === true
                ) {
                  const featureUrl = featureRequest.data.result;
                  await axios
                    .put(featureUrl, featurePhoto.file)
                    .then((response) => {})
                    .catch((error) => {
                      setBackdropOpen(false);
                      setError(
                        "We could not update the photos, please try again."
                      );
                      setErrorOpen(true);
                      return;
                    });
                }
                const propertyIdValue = propertyId;
                const body5 = {
                  propertyId: propertyIdValue,
                  stepId: 2,
                  mainImg: featureKey,
                  images: [...photoPaths],
                };
                const res5 = await createPropertyStepTwoPhotos(body5);
                if (
                  res5 &&
                  res5.data &&
                  res5.data.status &&
                  res5.data.status === true
                ) {
                  setBackdropOpen(false);
                  setSuccessOpen(true);
                  return;
                } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
                }
              }
            } else {
              setBackdropOpen(false);
              setError("We could not update the property, please try again.");
              setErrorOpen(true);
              return;
            }
          } else {
            setBackdropOpen(false);
            setError("We could not update the property, please try again.");
            setErrorOpen(true);
            return;
          }
        })
        .catch((error: any) => {
          setBackdropOpen(false);
          setError("We could not update the property, please try again.");
          setErrorOpen(true);
          return;
        });
    } else {
      ///// Property does not yet exist
      if (featurePhoto.file === "" || photos.length === 0) {
        dispatch({
          type: CreateListingActions.setFeaturePhotoError,
          payload: true,
        });
        setPhotoErrorOpen(true);
        return;
      }
      setBackdropOpen(true);
      dispatch({ type: CreateListingActions.setPreview, payload: data });
      let fullAdress: string;
      if (data.addressLine2 === "") {
        fullAdress = `${data.address} ${data.addressLine2}, ${data.city}, ${data.state} ${data.zipCode}`;
      } else {
        fullAdress = `${data.address}, ${data.city}, ${data.state} ${data.zipCode}`;
      }
      await geocode(RequestType.ADDRESS, fullAdress)
        .then(async (response) => {
          if (response.status !== "OK") {
            methods.setError("address", {
              type: "custom",
              message: "Could not get Lat and Lng for this address",
            });
            setError(
              "We could not geolocate this address, please change the address and try again."
            );
            setBackdropOpen(false);
            setErrorOpen(true);
            return;
          }
          if (
            response.status === "OK" &&
            response.results[0] &&
            response.results[0].partial_match
          ) {
            methods.setError("address", {
              type: "custom",
              message: "Could not get Lat and Lng for this address",
            });
            setError(
              "We could not geolocate this address, please change the address and try again."
            );
            setBackdropOpen(false);
            setErrorOpen(true);
            return;
          }
          // console.log(response);
          const { lat, lng } = response.results[0].geometry.location;
          const propertyTypeIdString = returnPropertyTypeNumber(
            data.propertyTypeId
          );
          const propertyParkingType = returnParkingTypeNumber(
            data.parkingTypeId
          );
          const petPolicyType = returnPetPolicyNumber(data.petPolicyId);
          const body = {
            nickName: data.nickName,
            propertyCreatetypeId:
              data.propertyCreateTypeId === "rental" ? "1" : "2", ///// 1 = Rent, 2 = Sell
            price: data.price,
            address: data.address,
            apt: data.addressLine2,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            propertyTypeId: propertyTypeIdString,
            lotSize: data.lotSize,
            sqFoot: data.sqFoot,
            bathrooms: data.bathrooms,
            bedrooms: data.bedrooms,
            parkingTypeId: propertyParkingType,
            parkingSpace: data.parkingSpace,
            propertyHeadline: data.propertyHeadline,
            generalInformation: data.generalInformation,
            utilities: data.utilities,
            petPolicyId: petPolicyType,
            petPolicyDescription: data.petPolicyDescription,
            zlink: data.zlink,
            lat: lat.toString(),
            long: lng.toString(),
            stepId: 1,
          };
          // console.log(body);
          const res = await createPropertyStepOneGeneral(body);
          if (res && res.data && res.data.status === true) {
            dispatch({
              type: CreateListingActions.setPropertyId,
              payload: res.data.result.pId.toString(),
            });
            const body3 = {
              stepId: 3,
              propertyId: res.data.result.pId.toString(),
              accessInstruction: data.accessInstruction,
              exitInstruction: data.exitInstruction,
              postTourInstruction: data.postTourInstruction,
            };
            const res3 = await createPropertyStepThreeInstructions(body3);

            if (res3 && res3.data && res3.data.status === true) {
              if (featurePhoto.file !== "" && photos.length > 0) {
                let featureKey: string = uuidv4();
                let photosKeyArr: Array<string> = [];
                const featureRequest = await generatePreSignedUrl({
                  directory: S3DirectoryName.PROPERTY,
                  fileName: featureKey,
                });
                if (
                  featureRequest &&
                  featureRequest.data &&
                  featureRequest.data.status === true
                ) {
                  const featureUrl = featureRequest.data.result;
                  await axios
                    .put(featureUrl, featurePhoto.file)
                    .then((response) => {
                      // console.log(response)
                    })
                    .catch((error) => {
                      setBackdropOpen(false);
                      setErrorOpen(true);
                      return;
                    });
                }
                await Promise.all(
                  photos.map(async (photo) => {
                    let photoKey = uuidv4();
                    const res1 = await generatePreSignedUrl({
                      directory: S3DirectoryName.PROPERTY,
                      fileName: photoKey,
                    });
                    if (res1 && res1.data && res1.data.status === true) {
                      photosKeyArr.push(photoKey);
                      const photoUrl = res1.data.result;
                      await axios
                        .put(photoUrl, photo.file)
                        .then(() => {
                          // console.log(`Uploaded file ${photoKey}`)
                        })
                        .catch((error) => {
                          setBackdropOpen(false);
                          setErrorOpen(true);
                          return;
                        });
                    }
                  })
                );

                const body4 = {
                  propertyId: res.data.result.pId.toString(),
                  stepId: 2,
                  mainImg: featureKey,
                  images: [...photosKeyArr],
                };
                const res4 = await createPropertyStepTwoPhotos(body4);
                if (
                  res4 &&
                  res4.data &&
                  res4.data.status &&
                  res4.data.status === true
                ) {
                  const body5 = {
                    pId: res.data.result.pId.toString(),
                  };
                  const res5 = await makePropertyDraft(body5);
                  if (res5 && res5.data && res5.data.status === true) {
                    const body6 = {
                      stepId: 7,
                      propertyId: res.data.result.pId.toString(),
                      preRequest: "0",
                      paymentAccessOption: "0",
                      documentRequired: "0",
                    };
                    const res6 = await createPropertyStepSevenApproval(body6);
                    if (res6 && res6.data && res6.data.status === false) {
                      setBackdropOpen(false);
                      setErrorOpen(true);
                      return;
                    } else {
                      setBackdropOpen(false);
                      setSuccessOpen(true);
                    }
                  } else {
                    setBackdropOpen(false);
                    setSuccessOpen(true);
                    return;
                  }
                } else {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
                }
              } else {
                setError("We could not create the property please try again.");
                setPhotoErrorOpen(true);
                return;
              }
            }
          } else {
            setBackdropOpen(false);
            setError("We could not create the property please try again.");
            setErrorOpen(true);
            return;
          }
        })
        .catch((error: any) => {
          setBackdropOpen(false);
          setError("We could not create the property please try again.");
          setErrorOpen(true);
          return;
        });
    }
  };
  const onSubmit = (data: any) => {
    handleSubmitFunction(data)
      .then(() => {
        setBackdropOpen(false);
      })
      .catch(() => {
        setBackdropOpen(false);
        setError("We could not create the property, please try again.");
        setErrorOpen(true);
      });
  };

  return (
    <FormProvider {...methods}>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessOpen(false);
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessOpen(false);
          }}
        >
          General Tab Saved Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorOpen(false);
          }}
        >
          {error ? error : "Something went wrong."}
        </Alert>
      </Snackbar>
      <Snackbar
        open={photoErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPhotoErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPhotoErrorOpen(false);
          }}
        >
          Feature photo and at least one additional photo required.
        </Alert>
      </Snackbar>
      <Snackbar
        open={propertyIdErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPropertyIdErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPropertyIdErrorOpen(false);
          }}
        >
          No Property Id, if you already created a property go back to the main
          listings page to edit or create another property.
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};
