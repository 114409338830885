import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
   AlertColor,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   useTheme,
} from "@mui/material";
import ModalTransparentLayer from "../common/ModalTransparentLayer";
import { fadeIn, fadeOut, moveElement } from "../../utils/genericUtils";
import RoundedButton from "../common/boxlty.button";
import { ListingMultipleSelect } from "./listings.multiple.select";
import {
   addTeamMemberWithProperty,
   userInviteSaasFlow,
   adminInviteSaasFlow,
   getUserPropertyList,
   getTeamMembersAdmin,
} from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";
import { AdminInviteSaasFlow } from "../../contexts/api/api.types";

interface position {
   top: string;
   left: string;
   right: string;
   bottom: string;
}

export interface ListingType {
   propertyId: string;
   nickName: string;
}

interface ActionButtonModalProps {
   id?: string;
   isVisible: boolean;
   openPosition: position;
   closedPosition: position;
   setPanel: (value: boolean) => void;
   setSnackbar: (value: boolean) => void;
   setSnackbarStatus: (value: AlertColor) => void;
   setSnackbarMessage: (value: string) => void;
   refresh: boolean;
}

export const SuperAdminAddMemberModal = (props: ActionButtonModalProps) => {
   const theme = useTheme();
   const [modalVisible, setModalVisible] = useState(props.isVisible);
   const [isInitialRender, setIsInitialRender] = useState(true);
   const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
   const [email, setEmail] = useState("");
   const [companyNameInput, setCompanyNameInput] = useState("");
   const [listingItemArray, setListingItemArray] = useState<ListingType[]>([]);
   const [selectedListingArray, setSelectedListingArray] = useState<
      ListingType[]
   >([]);
   const [inputValue, setInputValue] = useState("");
   const [listing, setListing] = useState<string>("");
   const [accountType, setAccountType] = useState("1");
   const [primaryMangerList, setPrimaryManagerList] = useState<any[]>([]);
   const [
      selectPrimaryManagerCompanyName,
      setSelectedPrimaryManagerCompanyName,
   ] = useState("");
   const [selectedPrimaryManagerUserId, setSelectedPrimaryManagerUserId] =
      useState<number>(0);
   const { user } = useUserState();
   const addMemberTransparentModalId = "add-member-transparent-layer";
   const TransparentLayerTransitionTime = 0.3;
   const ModalTransitionTime = 0.5;
   useEffect(() => {
      if (!isInitialRender) toggle();
      setIsInitialRender(false);
      if (isInitialRender && props.isVisible == true) toggle(true);
   }, [props.isVisible, modalVisible]);

   useEffect(() => {
      const getUserListings = () => {
         getUserPropertyList(1000, 4).then((res) => {
            if (
               res &&
               res.data &&
               res.data.result &&
               res.data.result.data &&
               res.data.result.data.length > 0
            ) {
               let listingItemArray: ListingType[] = [];
               res.data.result.data.forEach(function (value: any) {
                  //console.log(value);
                  if (
                     value.propertyId !== undefined &&
                     value.propertyId !== null
                  ) {
                     const listingInstance: ListingType = {
                        propertyId: value.propertyId.toString(),
                        nickName: value.propertyName,
                     };

                     listingItemArray.push(listingInstance);
                  }
               });
               setListingItemArray(listingItemArray);
               // console.log(userIdArray);
            }
         });
      };
      getUserListings();
   }, [user?.userId]);

   useEffect(() => {
      validateButton();
   }, [email, accountType, selectedPrimaryManagerUserId, companyNameInput]);

   const validateButton = () => {
      switch (accountType) {
         case "0":
            {
               if (email.length > 3 && selectedPrimaryManagerUserId !== 0) {
                  setSaveButtonDisabled(false);
               } else {
                  setSaveButtonDisabled(true);
               }
            }
            break;
         case "1":
            {
               if (email.length > 3 && companyNameInput.length > 3) {
                  setSaveButtonDisabled(false);
               } else {
                  setSaveButtonDisabled(true);
               }
            }
            break;
         default: {
            setSaveButtonDisabled(false);
         }
      }
   };

   const getPrimaryManagersAndCompanies = async () => {
      const res = await getTeamMembersAdmin();
      if (
         res &&
         res.data &&
         res.data.result &&
         res.data.result.members &&
         res.data.result.members.length > 0
      ) {
         const allArr = res.data.result.members;
         const pmFilteredArr = allArr.filter(
            (member: any) =>
               member.isSuperAdmin === "0" && member.isPrimaryManager === "1"
         );
         setPrimaryManagerList(pmFilteredArr);
      }
   };

   useEffect(() => {
      getPrimaryManagersAndCompanies();
   }, [user, selectedPrimaryManagerUserId]);

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggle(mode?: boolean | void) {
      if (mode != null) {
         toggleModal(mode);
         toggleTransparentLayer(mode);
         setModalVisible(mode);
      } else {
         toggleModal();
         toggleTransparentLayer();
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleTransparentLayer(mode: boolean | void) {
      //get transparent layer by id
      let transparentLayer: HTMLElement = document.querySelector(
         "#" + addMemberTransparentModalId
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
         return;
      }
      //manual mode not passed
      if (modalVisible) {
         fadeOut(transparentLayer, TransparentLayerTransitionTime * 1000);
      } else {
         fadeIn(transparentLayer, TransparentLayerTransitionTime * 1000);
      }
   }

   /**
    * Greets the given name.
    * @param {string} name - The name to greet.
    * @returns {string} A greeting message.
    */
   function toggleModal(mode: boolean | void) {
      let modal: HTMLElement = document.querySelector(
         "#" + props.id
      ) as HTMLElement;
      if (mode != null && mode) {
         //mode set to true
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
         return;
      } else if (mode != null && mode === false) {
         //mode set to false
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
         return;
      }

      if (modalVisible) {
         //slide element off page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.openPosition,
            props.closedPosition
         );
      } else {
         //slide element on page
         moveElement(
            modal,
            ModalTransitionTime * 1000,
            props.closedPosition,
            props.openPosition
         );
      }
   }

   //  const alreadySelected = currentArr.find((userId) => userId === value);
   //  if (alreadySelected) {
   //     return;
   //  }
   //  currentArr.push(value);
   //  setSelected(currentArr);

   // const handleListingSelection = (selectedListing: string) => {
   //    const currentArr = [...selectedListingArray];
   //    const alreadySelected = currentArr.find(
   //       (item) => item === selectedListing
   //    );
   //    if (alreadySelected) {
   //       const index = currentArr.indexOf(alreadySelected);
   //       currentArr.splice(index, 1);
   //       setSelectedListingArray(currentArr);
   //    } else {
   //       currentArr.push(selectedListing);
   //       setSelectedListingArray(currentArr);
   //    }
   // };

   async function validateInput(input: string) {
      // Regular expression for matching email addresses
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Regular expression for matching phone numbers (basic example)
      const phoneRegex = /^[0-9]{10}$/;

      if (
         emailRegex.test(input) ||
         phoneRegex.test(input)
         // && listing.toString().length > 0
      ) {
         return true;
      }
      return false;
   }

   const useStyles = makeStyles({ name: "AddMemberModal" })((theme) => ({
      ModalComponentContainer: {
         overflow: "hidden",
         "@media (max-width: 600px)": {
            display: "none",
         },
      },
      AddMemberModal: {
         transition: `right ${ModalTransitionTime}s linear`,
         position: "absolute",
         zIndex: 25,
         backgroundColor: "white",
         // opacity : '0.7',
         borderRadius: "20px",
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
         bottom: props.closedPosition.bottom,
         right: props.closedPosition.right,
         padding: "20px",
         minHeight: "260px",
         minWidth: "300px",
         maxWidth: "400px !important",
      },
      modalHeader: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-start",
         marginBottom: "10px",
      },
      modalSubHeader: {
         display: "flex",
         alignItems: "baseline",
         justifyContent: "flex-start",
         marginBottom: "10px",
      },
      headerText: {
         margin: "0",
         textAlign: "left",
         width: "98%",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      subHeaderText: {
         margin: "0",
         textAlign: "left",
         width: "fitcontent",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
      },
      exitX: {
         marginLeft: "auto",
         fontSize: "25px",
         cursor: "pointer",
         color: theme.palette.common.lightGray,
      },
      ModalFieldRow: {
         display: "flex",
         alignItems: "center",

         // justifyContent: "space-evenly",
      },
      ModalInputBox: {
         display: "flex",
         flexDirection: "column",
         marginBottom: "18px",
         marginRight: "10px",
      },
      ModalInput: {
         border: "none",
         outline: "none",
         width: "100%",
         color: theme.palette.common.darkGray,
         fontFamily: theme.fonts.secondary.med,
         fontWeight: "100",
         textTransform: "none",
         marginTop: "16px",
         fontSize: "14px",
         paddingLeft: "2px",
         "& .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input": {
            paddingBottom: "0px !important",
         },
      },
      SelectLabel: {
         fontSize: "14px",
         color: "rgba(0, 0, 0, 0.6) !important",
         fontFamily: theme.fonts.secondary.med,
         fontWeight: "100",
         left: "-11px",
         top: "10px",
      },
      InputSubLine: {
         width: "100%",
         height: "1px",
         maxHeight: "1px",
         minHeight: "1px",
         backgroundColor: theme.palette.common.darkGray,
      },
      HalfWidthInput: {
         width: "50%",
      },
      FullWidthInput: {
         width: "100%",
         maxWidth: "100%",
      },
      SeventyFiveWidthInput: {
         width: "75%",
      },
      inputAboveListingSelection: {
         display: "flex",
         flexDirection: "column",
         marginBottom: "8px",
         marginRight: "10px",
      },
      TwentyFiveWidthInput: {
         width: "25%",
      },
      AssignLocksSubtext: {
         whiteSpace: "nowrap",
         fontSize: "13px",
         fontFamily: theme.fonts.primary.med,
         color: theme.palette.common.darkGray,
         margin: "0",
         marginLeft: "10px",
      },
      SaveSection: {
         marginTop: "150px",
         marginBottom: "10px",
      },
   }));

   let { classes } = useStyles();

   return (
      <>
         {/* Component Container */}
         <section className={classes.ModalComponentContainer}>
            {/* TransparentLayer */}
            <ModalTransparentLayer
               id={addMemberTransparentModalId}
               transitionTime={TransparentLayerTransitionTime}
            />

            {/* ModdalConatiner */}
            <section id={props.id} className={classes.AddMemberModal}>
               {/* header row */}
               <div className={classes.modalHeader}>
                  <h2 className={classes.headerText}>Add Team Member</h2>
                  <span
                     className={classes.exitX}
                     onClick={() => {
                        toggle(false);
                     }}
                  >
                     &times;
                  </span>
               </div>

               {/* Field Rows */}

               {/* Row 1 */}
               {/* <div className={classes.ModalFieldRow}>
            <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="First Name"
              />
              <div className={classes.InputSubLine}></div>
            </div>

            <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="Last Name"
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div> */}
               {/* Row 2 */}
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <input
                        className={classes.ModalInput}
                        type="email"
                        placeholder="Email/Phone No."
                        onChange={async (e) => {
                           setEmail(e.target.value);
                        }}
                     />
                     <div className={classes.InputSubLine}></div>
                  </div>

                  {/* <div
              className={[classes.ModalInputBox, classes.HalfWidthInput].join(
                " "
              )}
            >
              <input
                className={classes.ModalInput}
                type="phone"
                placeholder="Phone"
              />
              <div className={classes.InputSubLine}></div>
            </div> */}
               </div>

               {/* Row 3 Select Primary Manager or Manager */}
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <FormControl>
                        {listing === "" && (
                           <InputLabel className={classes.SelectLabel}>
                              {" "}
                              Account Type
                           </InputLabel>
                        )}
                        <Select
                           size="small"
                           variant="standard"
                           // startIcon={<AddIcon />}
                           sx={{
                              color: theme.palette.common.darkGray,
                              fontFamily: theme.fonts.secondary.med,
                              fontWeight: "100",
                              textTransform: "none",
                              // marginTop: "5px",
                           }}
                           onChange={(e) => {
                              setAccountType(e.target.value as string);
                           }}
                           className={classes.ModalInput}
                           value={accountType}
                        >
                           <MenuItem value="0">Manager</MenuItem>
                           <MenuItem value="1">Primary Manager</MenuItem>
                        </Select>
                     </FormControl>
                     {/* <div className={classes.InputSubLine}></div> */}
                  </div>
               </div>

               {/* Company Name - User Select Conditional */}
               {accountType === "1" ? (
                  <div className={classes.ModalFieldRow}>
                     <div
                        className={[
                           classes.ModalInputBox,
                           classes.FullWidthInput,
                        ].join(" ")}
                     >
                        <input
                           className={classes.ModalInput}
                           type="text"
                           placeholder="Company Name"
                           onChange={async (e) => {
                              setCompanyNameInput(e.target.value);
                           }}
                        />
                        <div className={classes.InputSubLine}></div>
                     </div>
                  </div>
               ) : (
                  <>
                     <div className={classes.ModalFieldRow}>
                        <div
                           className={[
                              classes.ModalInputBox,
                              classes.FullWidthInput,
                           ].join(" ")}
                        >
                           <FormControl>
                              <InputLabel className={classes.SelectLabel}>
                                 {" "}
                                 Primary Manager
                              </InputLabel>
                              <Select
                                 size="small"
                                 variant="standard"
                                 disabled={
                                    primaryMangerList.length === 0
                                       ? true
                                       : false
                                 }
                                 // startIcon={<AddIcon />}
                                 sx={{
                                    color: theme.palette.common.darkGray,
                                    fontFamily: theme.fonts.secondary.med,
                                    fontWeight: "100",
                                    textTransform: "none",
                                    // marginTop: "10px",
                                 }}
                                 onChange={(e) => {
                                    const value = parseInt(
                                       e.target.value as string
                                    );

                                    //console.log(value);
                                    setSelectedPrimaryManagerUserId(value);
                                    let arr = [...primaryMangerList];
                                    const selected = arr.find(
                                       (arrItem) => arrItem.userId === value
                                    );
                                    if (selected) {
                                       if (selected.company) {
                                          setSelectedPrimaryManagerCompanyName(
                                             selected.company
                                          );
                                       }
                                    }
                                 }}
                                 className={classes.ModalInput}
                                 value={selectedPrimaryManagerUserId}
                              >
                                 {primaryMangerList.map((pm: any) => (
                                    <MenuItem value={pm.userId} key={pm.userId}>
                                       {pm.firstName !== "" &&
                                       pm.lastName !== ""
                                          ? `${pm.firstName} ${pm.lastName}`
                                          : pm.email}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                           {/* <div className={classes.InputSubLine}></div> */}
                        </div>
                     </div>
                     <div className={classes.ModalFieldRow}>
                        <div
                           className={[
                              classes.FullWidthInput,
                              classes.ModalInputBox,
                           ].join(" ")}
                        >
                           <input
                              className={classes.ModalInput}
                              type="text"
                              placeholder="Company Name"
                              value={selectPrimaryManagerCompanyName}
                              readOnly
                           />
                           <div className={classes.InputSubLine}></div>
                        </div>
                     </div>
                  </>
               )}
               {/* Row 4 Assign To Listing*/}
               <div className={classes.ModalFieldRow}>
                  <div
                     className={[
                        classes.ModalInputBox,
                        classes.FullWidthInput,
                     ].join(" ")}
                  >
                     <ListingMultipleSelect
                        listingOptions={listingItemArray}
                        selectedListingArray={selectedListingArray}
                        setListingArray={setSelectedListingArray}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                     />
                  </div>
               </div>

               {/* Row 4 */}
               {/* <div className={classes.ModalFieldRow}>
            <div
              className={[
                classes.ModalInputBox,
                classes.SeventyFiveWidthInput,
              ].join(" ")}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="City"
              />
              <div className={classes.InputSubLine}></div>
            </div>

            <div
              className={[
                classes.ModalInputBox,
                classes.TwentyFiveWidthInput,
              ].join(" ")}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="State"
              />
              <div className={classes.InputSubLine}></div>
            </div>

            <div
              className={[
                classes.ModalInputBox,
                classes.TwentyFiveWidthInput,
              ].join(" ")}
            >
              <input
                className={classes.ModalInput}
                type="text"
                placeholder="Zip"
              />
              <div className={classes.InputSubLine}></div>
            </div>
          </div> */}

               {/* Assign Locks Section */}
               {/* <section className={classes.modalSubHeader}>
            <h3 className={classes.subHeaderText}>Assign Locks</h3>
            <p className={classes.AssignLocksSubtext}>
              Multiple locks can be selected
            </p>
          </section> */}

               {/* Add Lock Button */}
               {/* <div
            className={[
              classes.ModalInputBox,
              classes.TwentyFiveWidthInput,
            ].join(" ")}
          >
            <Button
              variant="text"
              size="small"
              startIcon={<AddIcon />}
              sx={{
                marginLeft: "-30px",
                color: theme.palette.common.darkGray,
                fontFamily: theme.fonts.secondary.med,
                fontWeight: "100",
              }}
            >
              Locks
            </Button>
            <div className={classes.InputSubLine}></div>
          </div> */}

               {/* Save Section */}
               <section className={classes.SaveSection}>
                  <RoundedButton
                     color={"primary"}
                     listener={async () => {
                        const valid = await validateInput(email);
                        if (valid) {
                           let requestBody: AdminInviteSaasFlow;
                           if (accountType === "1") {
                              if (companyNameInput.length < 3) {
                                 //// do some error handling, company name needed
                                 return;
                              }
                              const listingArray = selectedListingArray.map(
                                 (listing: ListingType) => {
                                    return parseInt(listing.propertyId);
                                 }
                              );
                              requestBody = {
                                 propertyId: listingArray,
                                 companyName: companyNameInput,
                                 contactInput: email,
                                 expectedRole: 1,
                                 pmUserId: null,
                              };
                           } else {
                              if (!selectedPrimaryManagerUserId) {
                                 return;
                              }
                              const listingArray = selectedListingArray.map(
                                 (listing: ListingType) => {
                                    return parseInt(listing.propertyId);
                                 }
                              );
                              requestBody = {
                                 propertyId: listingArray,
                                 companyName: null,
                                 contactInput: email,
                                 expectedRole: 0,
                                 pmUserId: selectedPrimaryManagerUserId,
                              };
                           }
                           const res = await adminInviteSaasFlow(requestBody);
                           if (res.data.result.enterpriseUpdate == 2) {
                              props.setSnackbarMessage(
                                 `Invite sent to ${email}.`
                              );
                              props.setSnackbarStatus("success");
                              props.setSnackbar(true);
                              if (props.refresh) {
                                 window.location.reload();
                              } else {
                                 toggle(false);
                              }
                           } else if (
                              res.data.result.enterpriseUpdate == 1 ||
                              res.data.result.enterpriseUpdate == 0
                           ) {
                              //requires updates
                              props.setSnackbarStatus("error");
                              props.setSnackbarMessage(res.data.message);
                              props.setSnackbar(true);
                           } else if (res.data.result.userInvited == 1) {
                              props.setSnackbarStatus("error");
                              props.setSnackbarMessage(res.data.message);
                              props.setSnackbar(true);
                           } else {
                              if (res.data.message) {
                                 props.setSnackbarStatus("error");
                                 props.setSnackbarMessage(res.data.message);
                                 props.setSnackbar(true);
                                 // toggle(false);
                              } else {
                                 props.setSnackbarStatus("error");
                                 props.setSnackbarMessage(
                                    "Invite send failed. Please try again later."
                                 );
                                 props.setSnackbar(true);
                                 // toggle(false);
                              }
                           }
                        }
                     }}
                     showArrow={false}
                     showShadow={false}
                     text={"SEND INVITE"}
                     disabled={saveButtonDisabled}
                  />
               </section>
            </section>
         </section>
      </>
   );
};
