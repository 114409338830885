import react from "react";
import { useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import TokenButton from "./token.button";

interface TokenSelectProps {
  onTotalChanged: (x: number, y: number) => void;
}

function TokenSelect(props: TokenSelectProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [token_a_active, set_token_a_active] = useState(false);
  const [token_b_active, set_token_b_active] = useState(false);
  const [token_c_active, set_token_c_active] = useState(false);
  const [disableNonManual, setDisableNonManual] = useState(false);

  useEffect(() => {
    // console.log('token a active :' + token_a_active)
    // console.log('token b active :' + token_b_active)
    // console.log('token c active :' + token_c_active)
  }, [token_a_active, token_b_active, token_c_active, disableNonManual]);

  function manualTokenOnFocus() {
    if (token_a_active || token_b_active || token_c_active)
      props.onTotalChanged(0, 0);
    set_token_a_active(false);
    set_token_b_active(false);
    set_token_c_active(false);
    setDisableNonManual(true);
  }

  function onDisableNonManual() {
    setDisableNonManual(true);
  }

  function onEnableNonManual() {
    setDisableNonManual(false);
  }

  function A() {
    if (!disableNonManual) {
      //set a active
      set_token_a_active(!token_a_active);
      updateTotalCost(1, !token_a_active);
      //props.onTotalChanged(token_a_active ? 0 : 5);

      //set b and c to false
      set_token_b_active(false);
      set_token_c_active(false);
    }
  }

  function B() {
    if (!disableNonManual) {
      //set b active
      set_token_b_active(!token_b_active);
      updateTotalCost(3, !token_b_active);
      //props.onTotalChanged(token_b_active ? 0 : 15);

      //set a and c to false
      set_token_a_active(false);
      set_token_c_active(false);
    }
  }

  function C() {
    if (!disableNonManual) {
      //set c active
      set_token_c_active(!token_c_active);
      updateTotalCost(5, !token_c_active);
      //props.onTotalChanged(token_c_active ? 0 : 25);

      //set a and b to false
      set_token_a_active(false);
      set_token_b_active(false);
    }
  }

  function updateTotalCost(numberOfTokens: number, selected: boolean) {
    var totalCost = 0;
    var discountPercent = 0;
    if (!selected) {
      props.onTotalChanged(0, 0);
    } else {
      if (numberOfTokens <= 1.0) {
        totalCost = 5 * numberOfTokens;
      } else if (numberOfTokens <= 3.0 && numberOfTokens > 1.0) {
        totalCost = 5 * numberOfTokens;
        discountPercent = 5;
      } else if (numberOfTokens <= 5.0 && numberOfTokens > 3.0) {
        totalCost = 5 * numberOfTokens;
        discountPercent = 10;
      } else if (numberOfTokens > 5.0) {
        totalCost = 5 * numberOfTokens;
        discountPercent = 15;
      }
      props.onTotalChanged(totalCost, discountPercent);
    }
  }

  //pass listeners down from toursemainde
  return (
    <>
      {/* select component container */}
      <section className={classes.SelectComponentContainer}>
        {/* select header */}
        <h4 className={classes.PurchaseTokensHeader}>Purchase Tokens*</h4>

        {/* Token Row Section */}
        <section className={classes.TokenRow}>
          <TokenButton
            onClick={A}
            active={token_a_active}
            id="token-1"
            tokenAmount={1}
          />
          <TokenButton
            onClick={B}
            active={token_b_active}
            id="token-3"
            tokenAmount={3}
          />
          <TokenButton
            onClick={C}
            active={token_c_active}
            id="token-5"
            tokenAmount={5}
          />
          <TokenButton
            onKey={(e) => {
              let el = e.target as HTMLInputElement;
              if (el != null) {
                el.value = el.value.replace(/[^0-9]/g, "");
                let n = Number.parseFloat(el.value);
                updateTotalCost(n, disableNonManual);
                //let cost = n * 5;
                //if (Number.isNaN(cost)) cost = 0.0;
                //props.onTotalChanged(cost);
              }
            }}
            disableOtherTokens={onDisableNonManual}
            enableOtherTokens={onEnableNonManual}
            onFocus={manualTokenOnFocus}
            id="manual-token-add"
            tokenAmount={0}
            manualEntry={true}
          />
        </section>

        {/* small text section */}

        <section className={classes.SubtextSection}>
          <p className={classes.Subtext1}>* 1 Token = 1 Self Tour</p>
          <p className={classes.Subtext2}>
            5 Tokens = 1 Month Listing Activation
          </p>
        </section>
      </section>
    </>
  );
}

const useStyles = makeStyles({ name: "TokensSelect" })((theme) => ({
  SelectComponentContainer: {
    paddingLeft: "20px",
    width: "100%",
    marginBottom: "15px",
  },
  PurchaseTokensHeader: {
    fontFamily: theme.fonts.secondary.med,
    fontSize: "18px",
    marginLeft: "5px",
    marginTop: "0px",
    marginBottom: "10px",
  },
  TokenRow: {
    display: "flex",
    alignItems: "center",
    //justifyContent : 'space-evenly',
    paddingLeft: "5px",
    marginBottom: "20px",
    maxWidth: "450px",
  },
  SubtextSection: {},
  Subtext1: {
    fontFamily: theme.fonts.secondary.light,
    color: theme.palette.common.lightGray,
    fontSize: "10px",
    margin: "0",
  },
  Subtext2: {
    fontFamily: theme.fonts.secondary.light,
    color: theme.palette.common.lightGray,
    fontSize: "10px",
    margin: "0",
    marginLeft: "0px",
  },
}));

export default TokenSelect;
