import React from "react";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as MobileOverlayImage } from "../../styles/assets/images/mobileoverlay.svg";
import { ReactComponent as InstashowLogo } from "../../styles/assets/logos/instashow-bar-logo.svg";
import { Grid } from "@mui/material";

export const MobileOverlay: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      className={classes.overlayContainer}
    >
      <MobileOverlayImage className={classes.overlay} />
      <div className={classes.barLogoContainer}>
        <InstashowLogo className={classes.barLogo} />
        <h1 className={classes.comingSoonText}>COMING SOON!</h1>
        <p className={classes.descriptionText}>
          The InstaShow web app is not yet mobile-compatible. Please access it
          from your computer.
        </p>
      </div>
    </Grid>
  );
};

const useStyles = makeStyles({ name: "MobileOverlay" })((theme) => ({
  overlay: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  barLogoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "15%",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  barLogo: {
    width: "60%",
    // height: "auto",
    marginBottom: '1.5rem'
  },
  overlayContainer: {
    position: "absolute",
    backgroundColor: "black",
    zIndex: 99999,
    display: "none",
    "@media (max-width: 600px)": {
      display: "flex",
    },
  },
  comingSoonText: {
    color: "white",
    fontFamily: theme.fonts.secondary.med,
    fontWeight: "400"
  },
  descriptionText: {
    color: "white",
    width: "65%",
    textAlign: "center",
  },
}));
