import React from "react";
import { Grid, IconButton, Button, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TeamTabForm } from "../../create/forms/team.tab.form";
import { CreatedTeamTabForm } from "../forms/created.team.tab.form";
import { HeaderButton } from "../../create/forms/header.button";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { UserProfileComponent } from "../../../listings/create/forms/user.profile.component";
import { UserSelectComponent } from "../../../listings/create/forms/user.select.component";
import testAvatar1 from "../../../styles/assets/images/test-avatar.jpeg";
import testAvatar2 from "../../../styles/assets/images/test-avatar3.jpeg";
import { kebabCase } from "lodash";
import {
   getUserInviteList,
   getUserTeamMembers,
   deleteProperty,
   activateProperty,
   makePropertyDraft,
   getUserProfile,
   getListingFull,
   getTeamMembersAdmin,
} from "../../../../contexts/api/api.functions";

import {
   TeamMemberTeamTab,
   Invite,
   TeamMember,
} from "../../../listings/create/forms/forms.types";
import { getPropertyInviteList } from "../../../../contexts/api/api.functions";
import {
   useSingleListingState,
   useSingleListingDispatch,
   SingleListingActions,
} from "../../../../contexts/single-listing/singleListing.context";
import { useUserState } from "../../../../contexts/user/user.context";
export const CreatedListingTeamsTab: React.FC<{
   setActive: (value: boolean) => void;
}> = ({ setActive }) => {
   const { classes } = useStyles();
   const [selectedUser, setSelectedUser] = useState<TeamMemberTeamTab | null>(
      null
   );
   const [users, setUsers] = useState<TeamMemberTeamTab[]>([]);
   const [invites, setInvites] = useState<Invite[]>([]);
   const [assigned, setAssigned] = useState<TeamMember[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const navigate = useNavigate();
   const dispatch = useSingleListingDispatch();
   const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
   const { listingType } = useSingleListingState();
   const { user } = useUserState();

   useEffect(() => {
      dispatch({
         type: SingleListingActions.setFeature,
         payload: { file: "" },
      });
      dispatch({ type: SingleListingActions.setPhotos, payload: [] });
   }, []);

   useEffect(() => {
      dispatch({
         type: SingleListingActions.setFeaturePhotoPath,
         payload: null,
      });
      dispatch({ type: SingleListingActions.setPhotoPaths, payload: null });
   }, []);

   const handleDelete = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         const res = await deleteProperty({ pId: parseInt(ListingIdParam) })
            .then(() => {
               navigate("/listings/active");
            })
            .catch((error) => {
               //console.log(error)
            });
      }
   };

   const handleArchive = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         if (!listingType || listingType === "") {
            return;
         }
         if (listingType === "archive") {
            handleMenuClose();
            return;
         }
         const res = await activateProperty({
            pId: parseInt(ListingIdParam),
            status: 0,
         })
            .then(() => {
               navigate("/listings/archived");
            })
            .catch((err) => {
               //console.log(err)
            });
      }
   };

   const handleDraft = async () => {
      if (ListingIdParam === undefined) {
         return;
      } else {
         if (!listingType || listingType === "") {
            return;
         }
         if (listingType === "draft") {
            handleMenuClose();
            return;
         }
         const res = await makePropertyDraft({ pId: parseInt(ListingIdParam) })
            .then(() => {
               navigate("/listings/draft");
            })
            .catch((err) => {
               //console.log(err)
            });
      }
   };

   const handleClick = (e: any) => {
      setAnchorEl(e.currentTarget);
   };

   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   const grabTeamMembersList = async () => {
      if (user && user.accountType) {
         if (user.accountType === 5 && user.isEnterpriseSuperUser === 1) {
            const res = await getTeamMembersAdmin();
            if (res && res.data && res.data.status === true) {
               // console.log(res.data.result);

               if (user && user.userId) {
                  const users = res.data.result.members.filter(
                     (member: any) => member.userId !== user.userId
                  );
                  setUsers([...users]);
               }
            } else {
               // console.log("could not fetch users");
            }
         } else if (
            user.accountType === 5 &&
            user.isPrimaryOwner === 1 &&
            user.isEnterpriseSuperUser === 0
         ) {
            const res = await getUserTeamMembers();
            if (res && res.data && res.data.status === true) {
               // console.log(res.data.result);

               if (user && user.userId) {
                  const users = res.data.result.members.filter(
                     (member: any) =>
                        member.userId !== user.userId &&
                        member.isPrimaryManager != "1"
                  );
                  setUsers([...users]);
               }
            }
         } else {
            const res = await getUserTeamMembers();
            if (res && res.data && res.data.status === true) {
               // console.log(res.data.result);

               if (user && user.userId) {
                  const users = res.data.result.members.filter(
                     (member: any) =>
                        member.userId !== user.userId &&
                        member.isPrimaryManager != "1"
                  );
                  setUsers([...users]);
               }
            } else {
               // console.log("could not fetch users");
            }
         }
      }
   };

   const grabPropertyInviteList = async () => {
      if (ListingIdParam) {
         const res = await getPropertyInviteList(ListingIdParam);
         if (res && res.data && res.data.status === true) {
            //console.log(res.data)
            //console.log("these are the property invites")
            const filteredArr = res.data.result.filter(
               (invite: any) => invite.isUserInvitedForThisProperty === 1
            );
            setInvites([...filteredArr]);
         } else {
            // console.log("could not fetch invites");
         }
      }
   };

   const grabAssignedMembersList = async () => {
      if (ListingIdParam) {
         const res = await getListingFull(parseInt(ListingIdParam));
         //console.log(res)
         if (res && res.data && res.data.status === true) {
            if (
               res.data.result.PropertyDetail &&
               res.data.result.PropertyDetail.teamMembers &&
               res.data.result.PropertyDetail.teamMembers.length > 0
            ) {
               const assignedMembers: TeamMember[] =
                  res.data.result.PropertyDetail.teamMembers.map(
                     (user: any) => {
                        return {
                           userId: user.userId,
                           fullName: user.userName,
                           email: user.userEmail,
                           phone: user.userMobile,
                           profileImage: user.userProfile,
                        };
                     }
                  );
               setAssigned([...assignedMembers]);
            } else {
               setAssigned([]);
            }
         }
      }
   };

   useEffect(() => {
      grabTeamMembersList();
      grabPropertyInviteList();
      grabAssignedMembersList();
   }, []);

   const setUserForProfileView = (id: string) => {
      // console.log(id);
      if (users) {
         const user = users.find((user) => user.userId.toString() === id);
         if (user) {
            setSelectedUser(user);
         }
      } else {
         return;
      }
   };

   return (
      <>
         <Grid container direction="column" className={classes.mainContainer}>
            <Grid item>
               <Grid
                  container
                  direction="row"
                  display={"flex"}
                  justifyContent="space-between"
                  // Only need this margin if menu icon button is hidden
                  // sx={{ marginBottom: '20px'}}
               >
                  <Grid item>
                     <HeaderButton
                        color="black"
                        backgroundColor="white"
                        borderColor="#707070"
                        text={listingType !== "" ? listingType : "draft"}
                        clickable={false}
                     />
                  </Grid>
                  {/* Removed in create listing, still in edit listing */}
                  <Grid item sx={{ paddingRight: "5px" }}>
                     <IconButton
                        edge="start"
                        id="type-selection-button"
                        aria-controls={open ? "type-selection-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                     >
                        <MoreHorizIcon fontSize="large" />
                     </IconButton>
                     <Menu
                        id="type-selection-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                           "aria-labelledby": "type-selection-button",
                        }}
                        sx={{ postion: "relative", right: "60px" }}
                        anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "right",
                        }}
                        transformOrigin={{
                           vertical: "top",
                           horizontal: 95,
                        }}
                     >
                        <MenuItem onClick={handleDraft}>Draft</MenuItem>
                        {user?.idVerify === 1 ? (
                           <MenuItem onClick={handleArchive}>Archive</MenuItem>
                        ) : null}
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                     </Menu>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item>
               <Grid
                  container
                  direction={"row"}
                  display="flex"
                  justifyContent={"space-between"}
               >
                  <Grid item flex={1.1}>
                     {/* Team Tab Form */}
                     <CreatedTeamTabForm
                        users={users}
                        assignedMembers={assigned}
                        grabTeamMemberList={grabTeamMembersList}
                        grabAssignedMembersList={grabAssignedMembersList}
                        getInviteList={grabPropertyInviteList}
                        setSelectedUserForProfile={setUserForProfileView}
                        invites={invites}
                     />
                  </Grid>
                  <Grid item flex={1}>
                     <UserProfileComponent user={selectedUser} />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Team-Tab" })((theme) => ({
   mainContainer: {
      paddingTop: "20px",
      paddingLeft: "15px",
      paddingRight: "10px",
      paddingBottom: "15px",
   },
   inputHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginBottom: "7px",
      textTransform: "uppercase",
   },
}));
