import React from "react";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { ForgotPasswordFirstForm } from "../../../components/forgot-password/forgot.password.first.form";
import { ReactComponent as BoxltyLogo } from "../../../styles/assets/logos/logo-on-black.svg";
import { ForgotPasswordThirdForm } from "../../../components/forgot-password/forgot.password.third.form";
import { useNavigate } from "react-router-dom";
import RegisterVerifyAdjustedComponent from "../../../components/forgot-password/verify.adjusted";
export interface IForgotPasswordForm {
  handleGoForward: () => void;
  handleGoBack: () => void;
  formStep: number;
}

const FORM_COUNT: number = 3;

export const ForgotPasswordMain: React.FC<{}> = ({}) => {
  const { classes } = useStyles();
  const [formStep, setFormStep] = useState(1);
  const [code, setCode] = useState("");
  const [emailOrPhone, setEmailOrPhone] = useState("ryan.speciale@seisan.com");
  const navigate = useNavigate();
  const handleGoForward = () => {
    if (formStep === FORM_COUNT) {
      navigate("/dashboard");
      return;
    }
    setFormStep(formStep + 1);
  };

  const handleGoBack = () => {
    if (formStep === 1) {
      navigate("/login");
      return;
    }
    setFormStep(formStep - 1);
  };

  const setEmailFromChild = (email_or_phone: string) => {
    setEmailOrPhone(email_or_phone);
  };

  const setOTPFromChild = (code: string) => {
    setCode(code);
  };

  // const handleCodeApiCall = async (emailOrPhone: string, otp: string) => {
  //   const res = await verifyForgotPasswordOTP({
  //     emailId: emailOrPhone,
  //     otp: otp,
  //   });
  //   if (res && res.data && res.data.status === true) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const handleCodeSubmit = (otp: string) => {
  //   handleGoForward();
  // };

  switch (formStep) {
    case 1:
      return (
        <div className={classes.mainContainer}>
          <div className={classes.topHeader}>
            <BoxltyLogo />
          </div>
          <div className={classes.formContainer}>
            <ForgotPasswordFirstForm
              formStep={formStep}
              handleGoBack={handleGoBack}
              handleGoForward={handleGoForward}
              setEmailOrPhone={setEmailFromChild}
            />
          </div>
        </div>
      );

    case 2:
      return (
        <div className={classes.mainContainer}>
          <div className={classes.topHeader}>
            <BoxltyLogo />
          </div>
          <div className={classes.formContainer}>
            <RegisterVerifyAdjustedComponent
              email={emailOrPhone}
              defaultValue=""
              setOTPValue={setOTPFromChild}
              onPreviousStep={() => {
                handleGoBack();
              }}
              onSubmitCode={() => {
                handleGoForward();
              }}
            />
          </div>
        </div>
      );

    case 3:
      return (
        <div className={classes.mainContainer}>
          <div className={classes.topHeader}>
            <BoxltyLogo />
          </div>
          <div className={classes.formContainer}>
            <ForgotPasswordThirdForm
              formStep={formStep}
              handleGoBack={handleGoBack}
              handleGoForward={handleGoForward}
              email={emailOrPhone}
              code={code}
            />
          </div>
        </div>
      );

    default:
      return <div></div>;
  }
};

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  topHeader: {
    backgroundColor: "black",
    height: "80px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "40px",
    "@media (max-width: 550px)": {
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "0",
    },
  },
  formContainer: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: "10%",
  },
}));
