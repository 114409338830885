import React, { SetStateAction } from "react";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
   Select,
   Chip,
   Box,
   MenuItem,
   Input,
   SelectChangeEvent,
   Checkbox,
   ListItemText,
   Autocomplete,
   TextField,
   OutlinedInput,
   FormControl,
   InputLabel,
   Stack,
} from "@mui/material";
import { ListingType } from "./super.admin.addTeamMember.modal";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
type Props = {};

export const ListingMultipleSelect: React.FC<{
   selectedListingArray: ListingType[];
   listingOptions: ListingType[];
   inputValue: string;
   setInputValue: React.Dispatch<React.SetStateAction<string>>;
   setListingArray: React.Dispatch<React.SetStateAction<ListingType[]>>;
}> = ({
   selectedListingArray,
   listingOptions,
   setListingArray,
   inputValue,
   setInputValue,
}) => {
   const { classes } = useStyles();
   const [displayValue, setDisplayValue] = useState<string[]>([]);
   const [open, setOpen] = useState(false);
   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
   const checkedIcon = <CheckBoxIcon fontSize="small" />;

   return (
      <>
         <Autocomplete
            multiple
            disableClearable
            open={open}
            classes={{
               input: classes.input,
            }}
            fullWidth
            forcePopupIcon={false}
            value={selectedListingArray}
            onChange={(event: any, newValue) => {
               setListingArray(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event: any, newInputValue) => {
               setInputValue(newInputValue);
            }}
            onClose={() => setOpen(false)}
            onOpen={() => {
               setOpen(true);
            }}
            options={listingOptions}
            renderTags={(tagValue, getTagProps) => {
               return tagValue.map((listing, index) => (
                  <Chip
                     {...getTagProps({ index })}
                     label={listing.nickName}
                     sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "nowrap",
                     }}
                  />
               ));
            }}
            isOptionEqualToValue={(option: ListingType, value: ListingType) => {
               if (option.propertyId === value.propertyId) {
                  return true;
               } else {
                  return false;
               }
            }}
            getOptionLabel={(option) => option.nickName}
            renderOption={(props, option, { selected }) => (
               <li {...props} key={option.propertyId}>
                  <Checkbox
                     icon={icon}
                     checkedIcon={checkedIcon}
                     style={{ marginRight: 8 }}
                     checked={selected}
                  />
                  {option.nickName}
               </li>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  variant="standard"
                  label="Assign To Listing (Optional)"
                  inputProps={{
                     ...params.inputProps,
                     style: {
                        padding: "0px",
                     },
                  }}
                  InputProps={{
                     ...params.InputProps,
                     style: {
                        padding: "0px",
                     },
                     endAdornment: open ? (
                        <ArrowDropUpIcon
                           fontSize="small"
                           style={{
                              cursor: "pointer",
                              color: "rgba(0, 0, 0, 0.54)",
                           }}
                           onClick={() => {
                              setOpen(false);
                           }}
                        />
                     ) : (
                        <ArrowDropDownIcon
                           fontSize="small"
                           style={{
                              cursor: "pointer",
                              color: "rgba(0, 0, 0, 0.54)",
                           }}
                           onClick={() => {
                              setOpen(true);
                           }}
                        />
                     ),
                  }}
                  InputLabelProps={{
                     ...params.InputLabelProps,
                     style: {
                        color: "#3C3C3C",
                        fontSize: "14px",
                        fontWeight: "100",
                        paddingLeft: "4px",
                     },
                  }}
                  sx={{
                     maxWidth: "290px",
                  }}
               />
            )}
         />
      </>
   );
};

const useStyles = makeStyles({ name: "Listing-Multiple-Select" })((theme) => ({
   SelectLabel: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.6) !important",
      fontFamily: theme.fonts.secondary.med,
      fontWeight: "100",
      left: "-11px",
      bottom: "15px",
   },
   inputRoot: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "0px",
      paddingLeft: "2px",
   },
   chipRoot: {
      borderRadius: "10px",
   },
   input: {
      marginTop: "0px",
   },
   root: {},
   ModalInput: {
      border: "none",
      outline: "none",
      width: "100%",
      color: theme.palette.common.darkGray,
      fontFamily: theme.fonts.secondary.med,
      fontWeight: "100",
      textTransform: "none",
      marginTop: "10px",
      fontSize: "14px",
      paddingLeft: "2px",
      "& .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input": {
         paddingBottom: "0px !important",
      },
   },
   InputSubLine: {
      width: "100%",
      height: "1px",
      maxHeight: "1px",
      minHeight: "1px",
      backgroundColor: theme.palette.common.darkGray,
   },
   ModalInputBox: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "19px",
      marginRight: "10px",
   },
   FullWidthInput: {
      width: "100%",
   },
}));
