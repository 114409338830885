import React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TimeBubble } from "./time.bubble";
import { isSameDay } from "date-fns";
import { TimeSlot } from "../primary-tabs/listings.availability.tab";
import { AvailabilityObject } from "../../../common/custom-calendar/custom.calendar";
import { UnavailableObject } from "../../../../contexts/api/api.types";
export const TimeSelectionComponent: React.FC<{ timeSlots: TimeSlot[], calendarState: AvailabilityObject[], setCalendarState: React.Dispatch<React.SetStateAction<AvailabilityObject[]>>, currentSelectedDay: AvailabilityObject, setCurrentSelectedDay: React.Dispatch<React.SetStateAction<AvailabilityObject>>, unavailableDays: UnavailableObject[], setUnavailableDays: React.Dispatch<React.SetStateAction<UnavailableObject[]>>, removedDays: UnavailableObject[], setRemovedDays:  React.Dispatch<React.SetStateAction<UnavailableObject[]>>}> = ({
  timeSlots,
  calendarState,
  setCalendarState,
  currentSelectedDay,
  setCurrentSelectedDay,
  unavailableDays,
  removedDays,
  setRemovedDays
}) => {


  const returnDateString = (date: Date) => {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    return date.toISOString().split('T')[0]
  }


  const handleClick = (start: number, end: number) => {
    const alreadySelected = currentSelectedDay.timeSlots.find(
      (timeSlot) => timeSlot.start === start && timeSlot.end === end
    );

    if (alreadySelected) {
      const index = currentSelectedDay.timeSlots.indexOf(alreadySelected);
      let updatedArr = [...currentSelectedDay.timeSlots]
      updatedArr.splice(index, 1);
      setCurrentSelectedDay({ date: currentSelectedDay.date, timeSlots: [...updatedArr] })
      if (updatedArr.length === 0) {
        const selectedDay = calendarState.find((object) => {
          if (isSameDay(object.date, currentSelectedDay.date)) {
            return object;
          }
        });
        if (selectedDay) {
          const newCalendarStateArr = [...calendarState]
          const newRemovedDays = [...removedDays];
          const existingRemovedDay = newRemovedDays.find(day => day.date === returnDateString(selectedDay.date))
          const selectedDayIndex = calendarState.indexOf(selectedDay);
          newCalendarStateArr.splice(selectedDayIndex, 1);
          setCalendarState([...newCalendarStateArr])
          if (existingRemovedDay) {
            const index = newRemovedDays.indexOf(existingRemovedDay);
            newRemovedDays[index] = { date: returnDateString(selectedDay.date), slots: []}
            setRemovedDays([...newRemovedDays])
          } else {
            newRemovedDays.push({ date: returnDateString(selectedDay.date), slots: []})
            setRemovedDays([...newRemovedDays])
          }
        } else {
          // console.log("could not find selected day all deselected")
        }
      } else {
        const selectedDay = calendarState.find((object) => {
          if (isSameDay(object.date, currentSelectedDay.date)) {
            return object;
          }
        });
        if (selectedDay) {
          const newStateArr = [...calendarState]
          const selectedDayIndex = calendarState.indexOf(selectedDay);
          newStateArr[selectedDayIndex] = { date: selectedDay.date, timeSlots: [...updatedArr] }
          setCalendarState([...newStateArr])
        } else {
          // console.log("could not find selected day")
        }
      }

    } else {
      const newSlot = { start: start, end: end };
      const currentArr = [...currentSelectedDay.timeSlots];
      currentArr.push(newSlot);
      setCurrentSelectedDay({ date: currentSelectedDay.date, timeSlots: currentArr });
      const selectedDay = calendarState.find((object) => {
        if (isSameDay(object.date, currentSelectedDay.date)) {
          return object;
        }
      });
      if (selectedDay) {
        const newStateArr = [...calendarState]
        const selectedDayIndex = calendarState.indexOf(selectedDay);
        newStateArr[selectedDayIndex] = { date: selectedDay.date, timeSlots: [...currentArr] }
        setCalendarState([...newStateArr])
      } else {
        // console.log("could not find selected day")
      }
    }
    //console.log(selected);
  };

  return (
    <>
      <Grid container direction={"column"} display="flex" gap={"20px"}>
        <Grid item>
          <Grid container direction={"row"} display="flex" gap={"15px"}>
            <Grid item>
              <TimeBubble
                startTime={8}
                endTime={9}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={9}
                endTime={10}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={10}
                endTime={11}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={"row"} display="flex" gap={"15px"}>
            <Grid item>
              <TimeBubble
                startTime={11}
                endTime={12}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={12}
                endTime={13}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={13}
                endTime={14}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={"row"} display="flex" gap={"15px"}>
            <Grid item>
              <TimeBubble
                startTime={14}
                endTime={15}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={15}
                endTime={16}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={16}
                endTime={17}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={"row"} display="flex" gap={"15px"}>
            <Grid item>
              <TimeBubble
                startTime={17}
                endTime={18}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={18}
                endTime={19}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
            <Grid item>
              <TimeBubble
                startTime={19}
                endTime={20}
                timeSlots={currentSelectedDay.timeSlots}
                handleClick={handleClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
