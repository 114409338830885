import React, { useState } from "react";
import { useEffect } from "react";
import StripeCardInput from "../../components/stripe/StripeCardInput";

/*
    This page is only so we can test without flushing out an entire page
*/

interface UpdatePaymentMethodProps {
   onCancel?: () => void;
}

function UpdateStripePaymentMethodComponent(props: UpdatePaymentMethodProps) {
   const [clientSecret, setClientSecret] = useState(null);
   const ENV = process.env.REACT_APP_ENV;

   useEffect(() => {
      //get payment intent, on change -> get new payment intent for new token amount
      let request = new XMLHttpRequest();
      request.open(
         "GET",
         ENV === "production"
            ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/setup-intent`
            : ENV === "staging"
            ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/setup-intent`
            : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/setup-intent`
      );
      const token =
         (sessionStorage.getItem("access_token")
            ? sessionStorage.getItem("access_token")
            : localStorage.getItem("access_token")) ?? "";
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("access_token", token);
      request.setRequestHeader("is_website", "true");
      if (clientSecret != null)
         request.setRequestHeader("client_secret", clientSecret);
      request.send();
      request.onload = () => {
         let response = JSON.parse(request.response);
         setClientSecret(response.setup_intent.client_secret);
      };
   }, []);

   function onPaymentMethodCreated(payment_method_id: string) {
      let request = new XMLHttpRequest();
      request.open(
         "POST",
         ENV === "production"
            ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/update-payment-method`
            : ENV === "staging"
            ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/update-payment-method`
            : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/update-payment-method`
      );
      const token =
         (sessionStorage.getItem("access_token")
            ? sessionStorage.getItem("access_token")
            : localStorage.getItem("access_token")) ?? "";
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("access_token", token);
      request.setRequestHeader("is_website", "true");
      if (clientSecret != null)
         request.setRequestHeader("client_secret", clientSecret);
      request.send(JSON.stringify({ payment_method: payment_method_id }));

      request.onload = () => {
         let response = JSON.parse(request.response);
         // console.log(response);
         if (response.success) {
         } else {
         }
      };
   }

   function onPaymentMethodFailed(error_message: string) {
      alert(error_message);
   }

   if (!clientSecret) {
      return (
         <>
            <section className="testing-bg"></section>
         </>
      );
   } else {
      return (
         <>
            <section className="testing-bg">
               <StripeCardInput
                  onCancel={props.onCancel}
                  submitButtonText={"UPDATE PAYMENT METHOD"}
                  cancelButtonText={"CANCEL"}
                  forUpdatePaymentMethod={true}
                  clientSecret={clientSecret}
                  onPaymentMethodFailed={onPaymentMethodFailed}
                  onPaymentMethodCreated={onPaymentMethodCreated}
                  onShowSnackBar={() => {}}
                  onSetErrorMessage={() => {}}
               />
            </section>
         </>
      );
   }
}

export default UpdateStripePaymentMethodComponent;
