import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { getUserProfile } from "../../../../contexts/api/api.functions";
import { TeamMemberTeamTab } from "./forms.types";

export const UserProfileComponent: React.FC<{
  user: TeamMemberTeamTab | null;
}> = ({ user }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [fullUser, setFullUser] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchUserProfile = async (id: string) => {
    setLoading(true);
    const res = await getUserProfile({ userId: id });
    if (res && res.data && res.data.status && res.data.status === true) {
      setFullUser(res.data.result);
      setLoading(false);
    } else {
      setError("There was an error fetching this user.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.userId) {
      fetchUserProfile(user.userId.toString());
    }
  }, [user]);

  useEffect(() => {
    // console.log(fullUser)
  }, [fullUser]);

  if (loading) {
    return <CircularProgress color="primary" />;
  }

  if (!fullUser) {
    return <></>;
  }

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <>
      <Grid container direction="column" display={"flex"} gap="10px">
        <Grid item>
          <div className={classes.name}>
            {`${fullUser.firstName} ${fullUser.lastName}`}
          </div>
        </Grid>
        <Grid item>
          <Grid container direction="row" display={"flex"} gap="15px">
            <Grid item>
              <img
                height={"150px"}
                width={"150px"}
                src={`https://boxlty-media.s3.amazonaws.com/user-profile/${fullUser.userImage}`}
                style={{ borderRadius: "15px", objectFit: "fill" }}
              />
            </Grid>
            <Grid item>
              <Grid container direction={"column"}>
                {fullUser.idVerify === 1 ? (
                  <Grid item sx={{ width: "65px", marginBottom: "10px" }}>
                    <div
                      style={{
                        borderRadius: "25px",
                        border: "1px solid #66E0B4",
                        fontFamily: theme.fonts.secondary.reg,
                        fontSize: "14px",
                        padding: "7px",
                      }}
                    >
                      VERIFIED
                    </div>
                  </Grid>
                ) : null}
                <Grid item sx={{ marginBottom: "15px" }}>
                  <Grid container direction={"column"} gap="5px">
                    <Grid item>
                      <div className={classes.email}>EMAIL</div>
                    </Grid>
                    <Grid item>
                      <div>{fullUser.emailId}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction={"column"} gap="5px">
                    <Grid item>
                      <div className={classes.email}>PHONE</div>
                    </Grid>
                    <Grid item>
                      <div>{formatPhoneNumber(fullUser.phone)}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({})((theme) => ({
  mainContainer: {},
  name: {
    fontSize: "18px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
  },
  email: {
    fontSize: "14px",
    color: "#A2A2A2",
  },
}));
