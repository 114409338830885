import react from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { EventEmitter } from "stream";
import { number } from "yup";

interface TokenButtonProps {
  id: string;
  manualEntry?: boolean;
  active?: boolean;
  tokenAmount?: number;
  onClick?: () => void;
  onKey?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  disableOtherTokens?: () => void;
  enableOtherTokens?: () => void;
}

function TokenButton(props: TokenButtonProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [active, setActive] = useState(props.active);

  const GreenA = "#60e0bb";
  const GreenB = "#64e29c";

  useEffect(() => {
    if (props.manualEntry && (!props.onKey || !props.id))
      throw new Error(
        "Manual entry TokenButton requires an onkeyDown listener prop and an id prop"
      );
    if (!props.manualEntry && !props.onClick)
      throw new Error("TokenButton requires onclick listener prop and id prop");

    let tokenEl: HTMLElement = document.querySelector(
      "#" + props.id
    ) as HTMLElement;
    if (props.active)
      if (tokenEl)
        tokenEl.style.background = `linear-gradient(45deg, ${GreenA}, ${GreenB})`;
    if (!props.active) if (tokenEl) tokenEl.style.background = `initial`;
  }, [props.active, active]);

  function onManualHover() {
    //hide add icon
    let icon: HTMLElement = document.querySelector(
      "#" + props.id + "-add-icon"
    ) as HTMLElement;
    icon.classList.add(classes.hidden);

    //show input
    let input: HTMLElement = document.querySelector(
      "#" + props.id + "-manual-input"
    ) as HTMLElement;
    input.classList.remove(classes.hidden);
    input.focus();
  }

  function onManualHoverLeave() {
    //hide input icon if no value

    let input: HTMLInputElement = document.querySelector(
      "#" + props.id + "-manual-input"
    ) as HTMLInputElement;
    let value: number = parseFloat(input.value);
    if (isNaN(value) || value === 0 || value === null || value === undefined) {
      input.classList.add(classes.hidden);

      //show icon
      let icon: HTMLElement = document.querySelector(
        "#" + props.id + "-add-icon"
      ) as HTMLElement;
      icon.classList.remove(classes.hidden);
      //enable other tokens
      if (props.enableOtherTokens) props.enableOtherTokens();
    } else {
      //disable other tokens
      if (props.disableOtherTokens) props.disableOtherTokens();
    }
  }

  function onTokenClick() {
    if (props.onClick) props.onClick();
  }

  function getRoot(element: HTMLInputElement) {
    return element.parentElement?.parentElement ?? null;
  }

  function onKey(e: React.MouseEvent<HTMLInputElement>) {
    if (props.onKey) props.onKey(e);
    let input: HTMLInputElement = e.target as HTMLInputElement;

    input.addEventListener("input", function (e) {
      let inputValue = input.value;
      let cleanedValue = inputValue.replace(/[^0-9]/g, "");
      if (inputValue !== cleanedValue) {
        input.value = cleanedValue;
      }
    });

    //increase max width value
    // console.log('Input length', input.value.length);
    let root = getRoot(input);
    if (!root) return;

    //if not a number remove any non digit

    let v = input.value;
    let char = v.split("").pop();
    if (char) {
      let num = Number.parseInt(char);
      if (Number.isNaN(num)) input.value = "";
      if (props.onKey) props.onKey(e);
    }

    //if there is any input retain hover effect
    if (input.value.length > 0) root.classList.add(classes.HoverEffect);
    else root.classList.remove(classes.HoverEffect);

    let default_width = "75px";
    let default_width_br = "30%";

    let expand_1 = "125px";
    let expand_1_br = "20%";

    let expand_2 = "150px";
    let expand_2_br = "15%";

    //expand input container for two digits
    if (input.value.length >= 2) {
      root.style.minWidth = expand_1;
      root.style.borderRadius = expand_1_br;
    } else if (input.value.length < 2) {
      root.style.minWidth = default_width;
      root.style.borderRadius = default_width_br;
    }

    //expand input container for three digits
    if (input.value.length >= 3) {
      root.style.minWidth = expand_2;
      root.style.borderRadius = expand_2_br;
    } else if (input.value.length >= 2) {
      root.style.minWidth = expand_1;
      root.style.borderRadius = expand_1_br;
    }
  }

  return (
    <>
      <button
        onClick={props.manualEntry ? onManualHover : onTokenClick}
        id={"token-" + props.tokenAmount}
        className={classes.TokenButtonOuter}
      >
        {props.manualEntry ? (
          <section>
            <AddIcon
              sx={{ fontSize: "10px", color: theme.palette.common.lightGray }}
              id={props.id + "-add-icon"}
              className={classes.ManualAddIcon}
            />
            <input
              onMouseLeave={(e) => {
                onKey(e);
              }}
              onFocus={props.onFocus ? props.onFocus : () => {}}
              onBlur={onManualHoverLeave}
              id={props.id + "-manual-input"}
              className={[classes.ManualInput, classes.hidden].join(" ")}
              type={"text"}
              step={"none"}
              max={999}
              placeholder={"00"}
              maxLength={3}
            />
          </section>
        ) : (
          <p className={classes.TokenAmountText}>{props.tokenAmount}</p>
        )}
        {/* Button Text */}
        <p className={classes.SubText}>Tokens</p>
      </button>
    </>
  );
}

const useStyles = makeStyles({ name: "TokensButton" })((theme) => ({
  TokenButtonOuter: {
    border: "none",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "30%",
    height: "90px",
    width: "250px",
    minWidth: "75px",
    maxWidth: "100px",
    transition: "min-width 0.15s linear",
    "&:hover": {
      boxShadow: `5px 5px 20px -10px ${theme.palette.common.darkGray}`,
    },
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: "10px",
  },
  HoverEffect: {
    boxShadow: `5px 5px 20px -10px ${theme.palette.common.darkGray}`,
  },
  TokenAmountText: {
    fontFamily: theme.fonts.secondary.med,
    fontSize: "45px",
    textAlign: "center",
    margin: "0",
    marginBottom: "1px",
  },
  SubText: {
    fontFamily: theme.fonts.secondary.med,
    fontSize: "12px",
    textAlign: "center",
    margin: "0",
  },
  ManualAddIcon: {
    height: "55px",
    width: "55px",
  },
  ManualInput: {
    height: "50px",
    fontSize: "45px",
    color: theme.palette.common.black,
    border: "none",
    borderBottom: `3px solid ${theme.palette.common.lightGray}`,
    marginBottom: "5px",
    width: "80%",
    textAlign: "center",
    "&:focus": {
      outline: "none",
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      appearance: "none",
      margin: 0,
    },
  },
  hidden: {
    display: "none !important",
  },
}));

export default TokenButton;
