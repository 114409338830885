import React from "react";
import AppBody from "../../../components/body/main.body.component";
import {
   Grid,
   Button,
   Avatar,
   Snackbar,
   Alert,
   IconButton,
   Menu,
   MenuItem,
   AlertColor,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DataTableComponent } from "../../../components/common/data.table.component";
import { useTheme } from "@mui/material";
import { useState } from "react";
import {
   GridColDef,
   GridRowSelectionModel,
   useGridApiRef,
   GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useGradient } from "../../../contexts/gradients/gradients.context";
import { useTeamState } from "../../../contexts/team/team.context";
import { TeamPageSearchInput } from "../../../components/teams/teams.search.input";
import AddIcon from "@mui/icons-material/Add";
// import { AddTeamMemberOverlay } from "../../components/teams/addteammemberoverlay.component";
import AddMemberModal from "../../../components/dashboard/AddMemberModal";
import { useUserState } from "../../../contexts/user/user.context";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
   removeAssignedTeamMember,
   removeMemberFromTeam,
   removeTeamMemberInvite,
} from "../../../contexts/api/api.functions";
import RoundedButton from "../../../components/common/boxlty.button";
const { v4: uuidv4 } = require("uuid");

type Props = {};

export const TeamsMain: React.FC<{}> = (props: Props) => {
   const theme = useTheme();
   const { primaryButtonsGradient } = useGradient();
   const { classes } = useStyles();
   const { TourType } = useParams<{ TourType: string }>();
   const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
   const [selected, setSelected] = useState<GridRowSelectionModel>([]);
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const [addPanelOpen, setAddPanelOpen] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarStatus, setSnackbarStatus] = useState<AlertColor>("success");
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [showDeletePopup, setShowDeletePopup] = useState(false);
   const exportOpen = Boolean(exportAnchorEl);
   const apiRef = useGridApiRef();
   const { teamMembers, loading } = useTeamState();
   const navigate = useNavigate();
   const { user } = useUserState();

   const handleMenuClose = () => {
      setExportAnchorEl(null);
   };

   const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      // console.log(event.currentTarget);
      setExportAnchorEl(event.currentTarget);
   };

   const closeDeletePopup = () => {
      setShowDeletePopup(false);
   };

   const handleDeleteMembers = async () => {
      //console.log("selected members", selected);
      //console.log("all team members", teamMembers);

      const membersToDelete = teamMembers.filter(
         (member) =>
            selected.includes(member.userId) || selected.includes(member.email)
      );
      //console.log("members to delete", membersToDelete);

      const promises = membersToDelete.map(async (member) => {
         if (member.status === "2") {
            const response = await removeMemberFromTeam({
               input: member?.email.toString(),
            });
            if (!response || response.data.status === false) {
               return;
            }
         } else if (member.status === "1") {
            const response = await removeTeamMemberInvite({
               input: member?.email.toString(),
            });
            if (!response || response.data.status === false) {
               return;
            }
         }
         // Add more conditions as needed
      });

      await Promise.all(promises); // Wait for all promises to resolve

      // Reload the page after all asynchronous operations are complete
      window.location.reload();
   };

   /////Define Columns based on model returned by API
   const gridColumns: GridColDef[] = [
      {
         field: "teamMember",
         headerName: "Team Member",
         width: 350,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid item display={"flex"} alignItems={"center"}>
                  {params.row.userId === 0 ? (
                     <Avatar
                        style={{
                           marginRight: "5px",
                           fontSize: "14px",
                           backgroundColor: "#707070",
                        }}
                     >
                        SENT
                     </Avatar>
                  ) : (
                     <Avatar
                        src={`https://boxlty-media.s3.amazonaws.com/user-profile/${params.value.photo}`}
                        style={{ marginRight: "5px" }}
                     />
                  )}

                  <Grid item display={"flex"} flexDirection={"column"}>
                     <span
                        style={{
                           fontWeight: "bold",
                           fontSize: "18px",
                           overflowX: "auto",
                           maxWidth: "300px",
                           letterSpacing: "0.45px",
                        }}
                     >
                        {params.value.userName !== " "
                           ? params.value.userName
                           : params.value.email}
                     </span>
                     <span className={classes.teamMemberRole}>
                        {params.value.role}
                     </span>
                  </Grid>
               </Grid>
            </Grid>
         ),
         valueGetter: (params: GridValueGetterParams) => {
            let resultRole = "";
            switch (params.row.roleId) {
               case 1:
                  resultRole = "Tenant";
                  break;
               case 2:
                  if (params.row.isSuperAdmin === "1") {
                     resultRole = "Super Admin";
                     break;
                  }
                  if (params.row.isPrimaryManager === "1") {
                     resultRole = "Primary Manager";
                  } else {
                     resultRole = "Manager";
                  }
                  break;
               case 3:
                  resultRole = "Admin";
                  break;
               case 4:
                  resultRole = "Super Admin";
                  break;
               default:
                  //resultRole = ""
                  break;
            }

            return {
               photo: params.row.userImage,
               firstName: params.row.firstName,
               lastName: params.row.lastName,
               role: resultRole,
               status: params.row.status,
               userName: params.row.firstName + " " + params.row.lastName,
               email: params.row.email,
            };
         },
         valueFormatter: ({ value }) => `${value.firstName} ${value.lastName}`,
      },
      {
         field: "phone",
         headerName: "Phone",
         width: 125,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            return params.row.mobile ?? "";
         },
      },
      {
         field: "email",
         headerName: "Email",
         width: 330,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value.email}`,
         valueGetter: (params: GridValueGetterParams) => {
            return params.row.email;
         },
      },
      {
         field: "listings",
         headerName: "Listings",
         width: 100,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            return params.row.listingsCount;
         },
      },
      {
         field: "tours",
         headerName: "Tours",
         width: 100,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            return params.row.toursCount;
         },
      },
      {
         field: "primaryManager",
         headerName: "Primary Manager",
         width: 200,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            if (params.row.isPrimaryManager === "1") {
               return "N/A";
            } else {
               return `${params.row.primaryManagerFirstName} ${params.row.primaryManagerLastName}`;
            }
         },
      },
      {
         field: "company",
         headerName: "Company",
         width: 200,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            return params.row.company;
         },
      },
   ];

   return (
      <>
         <AppBody>
            <AddMemberModal
               id={"add-member-modal"}
               isVisible={addMemberModalVisible}
               openPosition={{
                  top: "0",
                  left: "0",
                  right: "0px",
                  bottom: "40vh",
               }}
               closedPosition={{
                  top: "0",
                  left: "0",
                  right: "-450px",
                  bottom: "40vh",
               }}
               setPanel={setAddPanelOpen}
               setSnackbar={setSnackbarOpen}
               setSnackbarStatus={setSnackbarStatus}
               setSnackbarMessage={setSnackbarMessage}
               refresh={true}
            />
            <Snackbar
               open={snackbarOpen}
               autoHideDuration={4000}
               sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
               onClose={() => {
                  setSnackbarOpen(false);
               }}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
               <Alert
                  onClose={() => {
                     setSnackbarOpen(false);
                  }}
                  severity={snackbarStatus}
                  color={snackbarStatus}
               >
                  {snackbarMessage}
               </Alert>
            </Snackbar>
            {showDeletePopup && (
               <div className={classes.ActionsPopupOverlay}>
                  <div className={classes.PopupCentered}>
                     {/* Popup content */}
                     <p className={classes.PopupHeader}>DELETE TEAM MEMBERS</p>
                     <p className={classes.PopupText}>
                        Delete selected members?
                     </p>
                     <div className={classes.PopupOptionsSection}>
                        <RoundedButton
                           text="CANCEL"
                           listener={closeDeletePopup}
                           boldText={true}
                        />
                        <RoundedButton
                           text="DELETE"
                           listener={handleDeleteMembers}
                           color="primary"
                           boldText={true}
                        />
                     </div>
                  </div>
               </div>
            )}
            <Grid
               container
               sx={{ height: "98%", width: "100%" }}
               className={classes.mainContainer}
               direction="column"
            >
               <Grid item>
                  <Grid
                     container
                     direction={"row"}
                     display="flex"
                     alignItems={"center"}
                     justifyContent="space-between"
                  >
                     <Grid item>
                        <span className={classes.header}>Team</span>
                     </Grid>
                     <Grid item sx={{ width: "20%" }}>
                        <TeamPageSearchInput items={teamMembers} />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item sx={{ height: "80%", width: "100%" }}>
                  <Grid
                     container
                     direction={"column"}
                     display="flex"
                     height={"100%"}
                     flexWrap={"nowrap"}
                  >
                     <Grid item sx={{ paddingBottom: "5px" }}>
                        <Grid
                           container
                           direction={"row"}
                           display={"flex"}
                           //justifyContent="space-between"
                        >
                           <Grid item width="100%">
                              <Grid
                                 container
                                 direction="row"
                                 display={"flex"}
                                 sx={{
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    height: "100%",
                                    gap: "5px",
                                 }}
                              >
                                 <Grid
                                    item
                                    sx={{
                                       alignSelf: "center",
                                       justifySelf: "flex-end",
                                       display: "flex",
                                    }}
                                 >
                                    <Grid
                                       container
                                       direction="row"
                                       display={"flex"}
                                       className={classes.actionContainer}
                                    >
                                       {user?.isPrimaryOwner == 1 && (
                                          <Grid item>
                                             <span
                                                style={{
                                                   fontSize: "17px",
                                                   fontFamily:
                                                      theme.fonts.secondary.med,
                                                   color: theme.palette.common
                                                      .medGray,
                                                   display: "flex",
                                                }}
                                             >
                                                Actions
                                             </span>
                                          </Grid>
                                       )}
                                       {user?.isPrimaryOwner == 1 && (
                                          <Grid item>
                                             <IconButton
                                                disabled={
                                                   selected &&
                                                   selected.length > 0
                                                      ? false
                                                      : true
                                                }
                                                aria-haspopup="true"
                                                id="export"
                                                aria-expanded={
                                                   exportOpen
                                                      ? "true"
                                                      : undefined
                                                }
                                                aria-controls={
                                                   exportOpen
                                                      ? "export-menu"
                                                      : undefined
                                                }
                                                onClick={handleMenuClick}
                                             >
                                                <KeyboardArrowDownIcon fontSize="medium" />
                                             </IconButton>
                                             <Menu
                                                className={classes.exportMenu}
                                                open={exportOpen}
                                                id="export-menu"
                                                anchorEl={exportAnchorEl}
                                                onClose={handleMenuClose}
                                                MenuListProps={{
                                                   "aria-labelledby": "export",
                                                }}
                                                anchorOrigin={{
                                                   vertical: "bottom",
                                                   horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                   vertical: "top",
                                                   horizontal: "center",
                                                }}
                                             >
                                                <MenuItem
                                                   className={
                                                      classes.exportMenuItem
                                                   }
                                                   onClick={() => {
                                                      apiRef.current.exportDataAsCsv();
                                                   }}
                                                >
                                                   Export
                                                </MenuItem>
                                                <MenuItem
                                                   className={
                                                      classes.exportMenuItem
                                                   }
                                                   onClick={() => {
                                                      setShowDeletePopup(true);
                                                   }}
                                                >
                                                   Delete
                                                </MenuItem>
                                             </Menu>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Grid>
                                 {user?.isPrimaryOwner == 1 && (
                                    <Grid
                                       item
                                       className={classes.buttonContainer}
                                    >
                                       <Button
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                             setAddMemberModalVisible(
                                                !addMemberModalVisible
                                             );
                                          }}
                                          classes={{
                                             endIcon: classes.startIcon,
                                          }}
                                          endIcon={<AddIcon />}
                                          sx={{
                                             borderRadius: "26px",
                                             backgroundImage: `${primaryButtonsGradient}`,
                                          }}
                                       >
                                          Team Member
                                       </Button>
                                    </Grid>
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item display={"flex"} flex={1}>
                        <DataTableComponent
                           loading={loading}
                           onRowClick={(params) => {
                              if (params.row.userId !== 0) {
                                 navigate(`/team/${params.row.userId}/details`);
                              }
                           }}
                           apiRef={apiRef}
                           getRowID={(params) =>
                              params.userId === 0 ? params.email : params.userId
                           }
                           items={
                              (teamMembers && teamMembers.length) > 0
                                 ? teamMembers
                                 : []
                           }
                           selected={selected}
                           setSelected={setSelected}
                           columns={gridColumns}
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "TeamMain" })((theme) => ({
   mainContainer: {},
   firstRow: {},
   secondRow: {},
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "38px",
      letterSpacing: ".25px",
      fontWeight: "bold",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      marginTop: "-15px",
      fontWeight: "bold",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: "bold",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "7px",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
      position: "relative",
      bottom: "7px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   teamMemberRole: {
      color: "#707070",
      fontSize: "14px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   createdDate: {
      color: "#707070",
      fontSize: "14px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "14px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   startIcon: {
      paddingBottom: "2px",
      justifySelf: "center",
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      minHeight: "200px",
      minWidth: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
}));
