import React, { useEffect } from 'react'
import { Grid, Paper, Tabs, Tab } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material'
import { Navigate, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'



type Props = {
    children?: React.ReactNode
}

export const ListingsCreateMiddleContainer:React.FC<Props> = ({ children }) => {
const { classes } = useStyles()
const { CurrentMainTab } = useParams<{ CurrentMainTab: string }>()
const { CurrentSecondaryTab } = useParams<{ CurrentSecondaryTab: string}>()
const navigate = useNavigate()




return (
    <Paper elevation={2} className={classes.paperContainer}>
      {children}
    </Paper>
  )
}


const useStyles = makeStyles({ name: "Listings-Middle-Container"})((theme) => ({
    mainContainer: {
        paddingLeft: '10px',
        height: '100%',
        border: '1px solid red',
        
    },
    pageHeader: {
        fontSize: '36px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
        textDecoration: 'underline'
    },
    sectionContainer: {

    },
    formContainer: {
        display: 'flex',
        height: '100%',
        
    },
    paperContainer: {
        height: '800px',
        display: 'flex',
        maxWidth: '100%',
        borderRadius: '26px',
        flexWrap: 'nowrap',
        overflow: 'hidden'
        // overflowY: 'auto'
    }
    

}))