import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { IListingsState } from "./listings.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useUserState } from "../user/user.context";

export enum ListingsActions {
   setListings = "setListings",
   setLoading = "setLoading",
   setError = "setError",
}

const initialState = {
   listings: [],
   loading: false,
   error: null,
};

const reducer = (state: IListingsState, action: IAction): IListingsState => {
   const newState = { ...state };
   switch (action.type) {
      case ListingsActions.setListings:
         newState.listings = action.payload;
         break;
      case ListingsActions.setLoading:
         newState.loading = action.payload;
         break;
      case ListingsActions.setError:
         newState.error = action.payload;
         break;
      default:
         return state;
   }

   return newState;
};

export const ListingsStateContext: any = React.createContext({});
export const ListingsDispatchContext: any = React.createContext({});
export const ListingsStateRefContext: any = React.createContext({});

export const useListingsState = (): IListingsState =>
   useContext(ListingsStateContext);
export const useListingsDispatch = (): Dispatch<IAction> =>
   useContext(ListingsDispatchContext);
export const useListingsStateRef = (): React.MutableRefObject<IListingsState> =>
   useContext(ListingsStateRefContext);

const middleware = [logger("User")];

export const ListingProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, userStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );
   const { user } = useUserState();

   const getUserProperties = async () => {
      dispatch({ type: ListingsActions.setLoading, payload: true });
      if (user && user.userId) {
      } else {
         dispatch({
            type: ListingsActions.setError,
            payload: "Could not load properties.",
         });
         dispatch({ type: ListingsActions.setLoading, payload: false });
      }
      dispatch({ type: ListingsActions.setLoading, payload: false });
   };

   useEffect(() => {
      getUserProperties();
   }, [user, user?.userId]);

   return (
      <ListingsStateContext.Provider value={state}>
         <ListingsDispatchContext.Provider value={dispatch}>
            <ListingsStateRefContext.Provider value={userStateRef}>
               {children}
            </ListingsStateRefContext.Provider>
         </ListingsDispatchContext.Provider>
      </ListingsStateContext.Provider>
   );
};
