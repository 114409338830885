import react from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useEffect } from "react";

interface CodeInputProps {
  id: string;
  defaultValue: string;
  onGetCode: (code: string) => void;
  onBackspace?: () => void;
  clearValue: boolean;
}

function CodeInput(props: CodeInputProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {
    let input1 = document.querySelector(
      "#" + props.id + "-1"
    ) as HTMLInputElement;
    input1.focus();

    if (props.clearValue) {
      for (let i = 0; i < 6; i++) {
        (
          document.querySelector(
            "#" + props.id + "-" + (i + 1)
          ) as HTMLInputElement
        ).value = "";
      }
    } else if (props.defaultValue != null && props.defaultValue.length == 6) {
      let chars = props.defaultValue.split("");
      for (let i = 0; i < chars.length; i++) {
        (
          document.querySelector(
            "#" + props.id + "-" + (i + 1)
          ) as HTMLInputElement
        ).value = chars[i];
      }
    }
  }, [props.clearValue]);

  function nextInput(
    event: React.KeyboardEvent<HTMLInputElement>,
    inputIndex: number
  ) {
    if (event.key !== "Backspace") {
      if (inputIndex <= 5) {
        let value: string = (event.target as HTMLInputElement).value;
        if (value.length > 0) {
          let input = document.getElementById(
            props.id + "-" + (inputIndex + 1)
          ) as HTMLInputElement;
          input.focus();
        }
      } else {
        let a: string = (
          document.querySelector("#" + props.id + "-1") as HTMLInputElement
        ).value;
        let b: string = (
          document.querySelector("#" + props.id + "-2") as HTMLInputElement
        ).value;
        let c: string = (
          document.querySelector("#" + props.id + "-3") as HTMLInputElement
        ).value;
        let d: string = (
          document.querySelector("#" + props.id + "-4") as HTMLInputElement
        ).value;
        let e: string = (
          document.querySelector("#" + props.id + "-5") as HTMLInputElement
        ).value;
        let f: string = (
          document.querySelector("#" + props.id + "-6") as HTMLInputElement
        ).value;
        let code: string = a + b + c + d + e + f;
        props.onGetCode(code);
      }
    } else {
      //focus previous
      if (inputIndex - 1 >= 1) {
        inputIndex = inputIndex - 1;
      }
      let input = document.getElementById(
        props.id + "-" + inputIndex
      ) as HTMLInputElement;
      input.focus();
      if (props.onBackspace) props.onBackspace();
    }
  }

  return (
    <div className={classes.codeInputsContainer}>
      <input
        onKeyUp={(e) => {
          nextInput(e, 1);
        }}
        className={classes.codeInputLine}
        id={props.id + "-1"}
        type="text"
        maxLength={1}
      />
      <input
        onKeyUp={(e) => {
          nextInput(e, 2);
        }}
        className={classes.codeInputLine}
        id={props.id + "-2"}
        type="text"
        maxLength={1}
      />
      <input
        onKeyUp={(e) => {
          nextInput(e, 3);
        }}
        className={classes.codeInputLine}
        id={props.id + "-3"}
        type="text"
        maxLength={1}
      />
      <input
        onKeyUp={(e) => {
          nextInput(e, 4);
        }}
        className={classes.codeInputLine}
        id={props.id + "-4"}
        type="text"
        maxLength={1}
      />
      <input
        onKeyUp={(e) => {
          nextInput(e, 5);
        }}
        className={classes.codeInputLine}
        id={props.id + "-5"}
        type="text"
        maxLength={1}
      />
      <input
        onKeyUp={(e) => {
          nextInput(e, 6);
        }}
        className={classes.codeInputLine}
        id={props.id + "-6"}
        type="text"
        maxLength={1}
      />
    </div>
  );
}

const useStyles = makeStyles({ name: "RegisterEmailComponent" })((theme) => ({
  codeInputsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  codeInputLine: {
    border: "none",
    width: "30px",
    outline: "none",
    textAlign: "center",
    borderBottom: "1px solid black",
    backgroundColor: "transparent",
    fontFamily: theme.fonts.secondary.light,
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "16px",
  },
}));

export default CodeInput;
