import React from "react";
import AppBody from "../../../components/body/main.body.component";
import { makeStyles } from "tss-react/mui";
import { IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RoundedButton from "../../../components/common/boxlty.button";
import { ReactComponent as ListingsIconActive } from "../../../styles/assets/logos/boxlty-logo-symbol.svg";
import { ReactComponent as ListingsIconInActive } from "../../../styles/assets/logos/boxlty-logo-symbol-inactive.svg";
import carrotSvg from "../../../styles/assets/Icons/carrot.svg";
import {
   useLocksDispatch,
   LocksActions,
} from "../../../contexts/locks/locks.context";
import TabbedContainer from "../../../components/common/boxlty.tabbedcontainer";
import {
   deleteLock,
   getLockDetails,
   getUserTeamMembers,
} from "../../../contexts/api/api.functions";
import { LockDetails } from "../locks.types";
import LocksDetailsInfo from "../locks.details.info";
import RoundedButtonWhite from "../../../components/common/boxlty.button.white";
import { MoreHoriz } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { BottomSubmitLockComponent } from "../bottom.submit.component.locks";
import { DeleteLockReq } from "../../../contexts/api/api.types";
import { TeamMemberTeamTab } from "../../../components/listings/create/forms/forms.types";
import { useUserState } from "../../../contexts/user/user.context";

type Props = {};

export const LocksDetails: React.FC<{}> = (props: Props) => {
   const theme = useTheme();
   const { classes } = useStyles();
   // const { LockType } = useParams<{ LockType: string }>();
   const navigate = useNavigate();
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const exportOpen = Boolean(exportAnchorEl);
   const [selected, setSelected] = useState<TeamMemberTeamTab | null>(null);
   const [saveChangesState, setSaveChangesState] = useState(false);
   const [lockNickNameState, setLockNickNameState] = useState("");
   const [companyName, setCompanyName] = useState("");
   const [lockDetail, setLockDetail] = useState<LockDetails | null>(null);
   const [showDeletePopup, setshowDeletePopup] = useState(false);
   const [showReasignPopup, setShowReasignPopup] = useState(false);
   const dispatch = useLocksDispatch();
   const { lockKey } = useParams<{ lockKey: string }>();
   const [lockUsers, setLockUsers] = useState<TeamMemberTeamTab[]>([]);
   const { user } = useUserState();

   const fetchLockDetail = async () => {
      if (lockKey !== null) {
         const res = await getLockDetails(lockKey?.toString() ?? "");
         if (res && res.data && res.data.status === true) {
            const lockPropertyIdNumber = parseInt(res.data.result.propertyId);
            setLockDetail(res.data.result);

            dispatch({
               type: LocksActions.setLockPropertyId,
               payload: lockPropertyIdNumber,
            });
            setCompanyName(res.data.result.propertyOwnerCompanyName);
            setLockNickNameState(res.data.result.lockNickname);
         }
      }
   };

   const grabTeamMembersList = async () => {
      const res = await getUserTeamMembers();
      if (res && res.data && res.data.status === true) {
         if (user && user.userId) {
            const users = res.data.result.members.filter(
               (member: any) => member.userId !== user.userId
            );
            setLockUsers([...users]);
         }
      }
   };

   // useEffect(() => {}, [showDeletePopup]);

   useEffect(() => {
      fetchLockDetail();
   }, [lockKey]);

   useEffect(() => {}, [lockDetail]);

   const handleMenuClose = () => {
      setExportAnchorEl(null);
   };

   const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setExportAnchorEl(event.currentTarget);
   };

   const handleTextFieldClick = () => {
      setSaveChangesState(true);
   };

   const handleCancelClick = () => {
      setSaveChangesState(false);
   };

   const handleOpenDeletePopup = () => {
      setshowDeletePopup(true);
   };

   const handleCloseDeletePopup = () => {
      setshowDeletePopup(false);
   };

   // const handleOpenReasignPopup = () => {
   //   setShowReasignPopup(true);
   // };

   const handleCloseReasignPopup = () => {
      setShowReasignPopup(false);
   };

   useEffect(() => {}, [showDeletePopup]);

   useEffect(() => {
      fetchLockDetail();
      grabTeamMembersList();
   }, [lockKey]);

   const handleDeleteLock = async () => {
      let lockDeviceId = lockDetail?.lockDeviceId ?? "";
      const data: DeleteLockReq = {
         lockDeviceId,
      };
      //console.log(data);
      await deleteLock(data);
      navigate("/locks");
   };

   const handleReasignLockUser = async () => {
      let lockNo = lockDetail?.lockDeviceId ?? "";
      let pId = lockDetail?.assignedUserId ?? "";
      let lockType =
         lockDetail?.masterLockTypeId === 1
            ? lockDetail?.masterLockType
            : lockDetail?.iglooLockType;
   };

   return (
      <>
         <AppBody>
            <div className={classes.PageContainer}>
               {/* Header */}
               <TextField
                  variant="standard"
                  inputProps={{
                     style: {
                        fontSize: "36px",
                        fontFamily: theme.fonts.secondary.bold,
                        fontWeight: "bold",
                     },
                  }}
                  InputProps={{ disableUnderline: true }}
                  value={lockNickNameState}
                  onChange={(e: any) => {
                     setLockNickNameState(e.target.value);
                     dispatch({
                        type: LocksActions.setLockNickName,
                        payload: e.target.value,
                     });
                  }}
                  onClick={handleTextFieldClick}
               />
               {/* Split Container */}
               <section className={classes.SplitContainer}>
                  {/* Main Section */}
                  <section className={classes.MainSection}>
                     <section
                        style={{
                           flex: 1,
                           display: "flex",
                           flexDirection: "column",
                        }}
                     >
                        <section
                           style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                           }}
                        >
                           {lockDetail?.lockImage !== undefined &&
                              lockDetail?.lockImage.length > 0 && (
                                 <img
                                    className={classes.imageStyle}
                                    src={`https://boxlty-media.s3.amazonaws.com/property-images/${lockDetail?.lockImage}`}
                                    alt="Lock"
                                 />
                              )}

                           <section
                              style={{
                                 flex: 1,
                                 display: "flex",
                                 flexDirection: "column",
                              }}
                           >
                              <div className={classes.imageBlock}>
                                 <RoundedButtonWhite
                                    text={
                                       lockDetail?.isLockAssociatedProperty ===
                                       1
                                          ? "ACTIVE"
                                          : "INACTIVE"
                                    }
                                    color="primary"
                                    activeProp={
                                       lockDetail?.isLockAssociatedProperty ===
                                       1
                                          ? true
                                          : false
                                    }
                                    showArrow={false}
                                    showShadow={true}
                                    marginLeft={"0px"}
                                    marginRight={"2px"}
                                 />
                              </div>
                              <div className={classes.textBlock}>
                                 <span className={classes.graySubFont}>
                                    {" "}
                                    ASSIGNED TO
                                 </span>
                                 <section
                                    style={{
                                       flex: 1,
                                       display: "flex",
                                       flexDirection: "row",
                                    }}
                                 >
                                    <span className={classes.mainText}>
                                       {" "}
                                       {lockDetail?.assignedFirstName}{" "}
                                       {lockDetail?.assignedLastName}
                                    </span>
                                    {/* <LockUserSelectComponent users={lockUsers} setSelected={setSelectedUser} setPopupOpen={handleOpenReasignPopup} firstName={lockDetail?.assignedFirstName ? lockDetail?.assignedFirstName : "Unassigned"} lastName={lockDetail?.assignedLastName ? lockDetail?.assignedLastName : ""} /> */}
                                    {showReasignPopup && (
                                       <div
                                          className={
                                             classes.ActionsPopupOverlay
                                          }
                                       >
                                          <div
                                             className={classes.PopupCentered}
                                          >
                                             {/* Popup content */}
                                             <p className={classes.PopupHeader}>
                                                REASSIGN LOCK
                                             </p>
                                             <p className={classes.PopupText}>
                                                Reassign{" "}
                                                {lockDetail?.lockNickname} to{" "}
                                                {selected?.firstName}{" "}
                                                {selected?.lastName}?
                                             </p>
                                             <div
                                                className={
                                                   classes.PopupOptionsSection
                                                }
                                             >
                                                <RoundedButton
                                                   text="CANCEL"
                                                   listener={
                                                      handleCloseReasignPopup
                                                   }
                                                   boldText={true}
                                                />
                                                <RoundedButton
                                                   text="REASSIGN"
                                                   listener={
                                                      handleReasignLockUser
                                                   }
                                                   color="primary"
                                                   boldText={true}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    )}

                                    <img
                                       id="alerts-arrow"
                                       className={classes.Carrot}
                                       src={carrotSvg}
                                       alt="down arrow click to expand"
                                    />
                                 </section>
                                 {/* <span className={classes.graySubFont}> COMPANY</span>
                      <span className={classes.mainText}> companyName</span> */}
                              </div>
                           </section>
                           <div>
                              <section className={classes.ThreeDots}>
                                 <IconButton
                                    edge="start"
                                    aria-haspopup="true"
                                    id="lock-delete"
                                    aria-expanded={
                                       exportOpen ? "true" : undefined
                                    }
                                    aria-controls={
                                       exportOpen
                                          ? "lock-delete-menu"
                                          : undefined
                                    }
                                    onClick={handleMenuClick}
                                 >
                                    <MoreHoriz fontSize="large" />
                                 </IconButton>
                              </section>
                              <Menu
                                 className={classes.exportMenu}
                                 open={exportOpen}
                                 id="lock-delete-menu"
                                 anchorEl={exportAnchorEl}
                                 onClose={handleMenuClose}
                                 MenuListProps={{
                                    "aria-labelledby": "lock-delete",
                                 }}
                                 anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                 }}
                                 transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                 }}
                              >
                                 <MenuItem onClick={handleOpenDeletePopup}>
                                    Delete
                                 </MenuItem>
                                 {showDeletePopup && (
                                    <div
                                       className={classes.ActionsPopupOverlay}
                                    >
                                       <div className={classes.PopupCentered}>
                                          {/* Popup content */}
                                          <p className={classes.PopupHeader}>
                                             DELETE LOCK
                                          </p>
                                          <p className={classes.PopupText}>
                                             Delete {lockDetail?.lockNickname}?
                                          </p>
                                          <div
                                             className={
                                                classes.PopupOptionsSection
                                             }
                                          >
                                             <RoundedButton
                                                text="CANCEL"
                                                listener={
                                                   handleCloseDeletePopup
                                                }
                                                boldText={true}
                                             />
                                             <RoundedButton
                                                text="DELETE"
                                                listener={handleDeleteLock}
                                                color="primary"
                                                boldText={true}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 )}
                              </Menu>
                           </div>
                        </section>
                        <div className={classes.textBlockLower}>
                           <span className={classes.graySubFont}>
                              {" "}
                              MANUFACTURER
                           </span>
                           <span className={classes.mainText}>
                              {" "}
                              {lockDetail?.lockManufacturer}
                           </span>

                           <span className={classes.graySubFont}> TYPE</span>
                           <span className={classes.mainText}>
                              {" "}
                              {lockDetail?.masterLockTypeId
                                 ? lockDetail?.masterLockType
                                 : lockDetail?.iglooLockType}
                           </span>

                           <span className={classes.graySubFont}>
                              {" "}
                              DEVICE I.D.
                           </span>
                           <span className={classes.mainText}>
                              {" "}
                              {lockDetail?.lockDeviceId}
                           </span>

                           <span className={classes.graySubFont}>
                              {" "}
                              ACTIVATION CODE
                           </span>
                           <span className={classes.mainText}>
                              {" "}
                              {lockDetail?.lockActivationCode}
                           </span>
                        </div>
                     </section>
                  </section>

                  <React.Fragment>
                     <section className={classes.SecondarySection}>
                        {/* Component to house those two sections */}
                        <TabbedContainer
                           tabs={[
                              {
                                 tabName: "Listing Details",
                                 tabIconActive: <ListingsIconActive />,
                                 tabIconInActive: <ListingsIconInActive />,
                                 tabComponent: <LocksDetailsInfo />,
                              },
                              //Hide tab options for now not part of MVP
                              //   {
                              //     tabName: "Activity",
                              //     tabIconActive: <AnalyticsIconActive />,
                              //     tabIconInActive: <AnalyticsIconInActive />,
                              //     tabComponent: (
                              //       <div >
                              //         <div >
                              //           <h1 >
                              //           Activity
                              //           </h1>
                              //         </div>

                              //       </div>
                              //     ),
                              //   },
                              //   {
                              //     tabName: "Analytics",
                              //     tabIconActive: <ActiviyIconActive />,
                              //     tabIconInActive: <ActivityIconInActive />,
                              //     tabComponent: (
                              //       <div >
                              //         <div >
                              //           <h1 >
                              //             Analytics
                              //           </h1>
                              //         </div>

                              //       </div>
                              //     ),
                              //   },
                           ]}
                           leftBorderRadius={"20px"}
                           showBoxShadow={true}
                           startingTabIndex={0}
                        />
                     </section>
                  </React.Fragment>
               </section>
               <BottomSubmitLockComponent
                  active={saveChangesState}
                  onSaveOrCancelClick={handleCancelClick}
                  lockNickName={lockNickNameState}
                  LockDetails={lockDetail}
               />
            </div>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "LocksDetails" })((theme) => ({
   mainContainer: {},
   firstRow: {},
   secondRow: {},
   imageStyle: {
      width: "148px",
      height: "148px",
      borderRadius: "10%",
      marginTop: "20px",
      marginLeft: "20px",
   },
   textBlock: {
      marginLeft: "36px",
      display: "flex",
      flexDirection: "column",
   },
   ThreeDots: {
      cursor: "pointer",
   },
   textBlockLower: {
      marginLeft: "36px",
      display: "flex",
      flexDirection: "column",
      marginTop: "21px",
   },
   imageBlock: {
      marginTop: "20px",
      marginLeft: "36px",
   },
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "38px",
      letterSpacing: ".25px",
   },
   graySubFont: {
      color: "#707070",
      fontSize: "14px",
      fontFamily: theme.fonts.primary.reg,
      marginTop: "15px",
      marginLeft: "2px",
   },
   mainText: {
      fontSize: "18px",
      fontFamily: theme.fonts.primary.reg,
      marginTop: "7px",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      display: "flex",
      marginTop: "-15px",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "7px",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   listingsCellAddress: {
      color: "#707070",
      fontSize: "14px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "14px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   MainSection: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "70%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   MainSectionRight: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "30%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
   },
   PageContainer: {
      display: "flex",
      flexDirection: "column",
   },
   SecondarySection: {
      width: "35%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "auto",
      leftBorderRadius: "20px",
      showBoxShadow: "true",
      startingTabIndex: "0",
   },
   PageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginTop: "-5px",
      marginBottom: "15px",
      fontSize: "40px",
   },
   Carrot: {
      color: "#707070",
      height: "10px",
      width: "10px",
      cursor: "pointer",
      //transition: `all ${transitionTime} linear`,
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      height: "200px",
      width: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
   },
}));
