// AccountSettingsAccount.jsx
import { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import PlanPickerPopup from "../../components/settings/billing/PlanPicker";
import PlanDetailsComponent from "../../components/settings/billing/plan-details-component";
import AddNewCardModal from "../../components/settings/billing/AddNewCardModal";
import { useUserState } from "../../contexts/user/user.context";
import { getBillingCycle } from "../../contexts/api/api.functions";

interface BillingHistory {
   createdText: string;
   paid: boolean;
   amount: string;
}

interface BillingCycle {
   start: string;
   end: string;
   baseCost: string;
}

const options: Intl.DateTimeFormatOptions = {
   year: "numeric",
   month: "short",
   day: "numeric",
};

function BillingTab() {
   const { classes } = useStyles();
   const { user } = useUserState();
   const ENV = process.env.REACT_APP_ENV;
   const [hasLoaded, setHasLoaded] = useState(false);
   const [planPickerModalVisible, setPlanPickerModalVisible] =
      useState<boolean>(false);
   const [paymentModalVisible, setPaymentModalVisible] =
      useState<boolean>(false);
   const [showBillingHistory, setShowBillingHistory] = useState<boolean>(false);
   const [forceRender, setForceRender] = useState(false);
   const [accountPlan, setAccountPlan] = useState({
      monthlyBilling: true,
      plan: "free",
   });
   //type may have to be updated later doing any for quickness
   const [billingHistory, setBillingHistory] = useState<any>(null);
   const [currentPage, setCurrentPage] = useState(1);

   const [billingCycle, setBillingCycle] = useState<BillingCycle | null>(null);

   const itemsPerPage = 8; // Number of items to display per page
   const billingCyclesAll: BillingHistory[] = [];
   const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
   const billingCycleElementsCurrentPage = (
      billingHistory ? billingHistory : billingCyclesAll
   )
      .slice(startIndex, endIndex)
      .map((billingObject: any, index: number) => (
         <>
            <div key={index} className={classes.SectionPlanRow}>
               <p className={classes.NoInfoText}>
                  Billing cycle: {billingObject.createdText}
               </p>
               {billingObject.amount !== "$0" ? (
                  <p className={classes.BillingPaidText}>
                     <span className={classes.BillingPaidText}>
                        {billingObject.paid ? "PAID" : "UNPAID"}
                     </span>
                     <span className={classes.ChargesText}>
                        {billingObject.amount}
                     </span>
                  </p>
               ) : (
                  <p className={classes.BillingPaidText}>
                     <span className={classes.BillingPaidText}>
                        {billingObject.paid ? "" : ""}
                     </span>
                     <span className={classes.ChargesText}>Trial Started</span>
                  </p>
               )}
            </div>
            {index < endIndex && <div className={classes.SectionDivider}></div>}
         </>
      ));
   const totalPages = Math.ceil(
      billingHistory
         ? billingHistory.length
         : billingCyclesAll.length / itemsPerPage
   );

   useEffect(() => {
      let monthlyBilling = true;
      if (user?.monthlyAnnually !== "0" && user?.monthlyAnnually !== "1") {
         monthlyBilling = false;
      }
      switch (user?.accountType ?? 1) {
         case 1:
            setAccountPlan({
               monthlyBilling: monthlyBilling,
               plan: "free",
            });
            break;
         case 2:
            setAccountPlan({
               monthlyBilling: monthlyBilling,
               plan: "plus",
            });
            break;
         case 3:
            setAccountPlan({
               monthlyBilling: monthlyBilling,
               plan: "business Tier 1",
            });
            break;
         case 4:
            setAccountPlan({
               monthlyBilling: monthlyBilling,
               plan: "business Tier 2",
            });
            break;
         case 5:
            setAccountPlan({
               monthlyBilling: monthlyBilling,
               plan: "enterprise",
            });
            break;
         default:
            setAccountPlan({
               monthlyBilling: monthlyBilling,
               plan: "free",
            });
            break;
      }
      setHasLoaded(true);
   }, [user]);

   useEffect(() => {
      if (
         user?.accountType &&
         user?.accountType !== 0 &&
         user?.accountType !== 1
      ) {
         GetBillingHistory();
         GetBillingCycle();
      }
      //GetBillingCycle();
   }, [planPickerModalVisible, showBillingHistory, forceRender]);

   const handleNextPage = () => {
      if (currentPage < totalPages) {
         setCurrentPage(currentPage + 1);
      }
   };

   const handlePreviousPage = () => {
      if (currentPage > 1) {
         setCurrentPage(currentPage - 1);
      }
   };

   const billingCyclesRecent = billingHistory
      ? billingHistory.slice(0, 4)
      : billingCyclesAll.slice(0, 4);

   const billingCycleElementsRecent = billingCyclesRecent.map(
      (billingObject: any, index: number) => (
         <>
            <div key={index} className={classes.SectionPlanRow}>
               <p className={classes.NoInfoText}>
                  Billing cycle: {billingObject.createdText}
               </p>
               {billingObject.amount !== "$0" ? (
                  <p className={classes.BillingPaidText}>
                     <span className={classes.BillingPaidText}>
                        {billingObject.paid ? "PAID" : "UNPAID"}
                     </span>
                     <span className={classes.ChargesText}>
                        {billingObject.amount}
                     </span>
                  </p>
               ) : (
                  <p className={classes.BillingPaidText}>
                     <span className={classes.BillingPaidText}>
                        {billingObject.paid ? "" : ""}
                     </span>
                     <span className={classes.ChargesText}>Trial Started</span>
                  </p>
               )}
            </div>
            {index < billingCyclesRecent.length - 1 && (
               <div className={classes.SectionDivider}></div>
            )}
         </>
      )
   );

   const updateAccountPlan = (newPlan: any) => {
      //console.log("update account plan");
      //console.log(newPlan);
      setAccountPlan(newPlan);
      //console.log(accountPlan);
   };

   const GetBillingHistory = () => {
      let request = new XMLHttpRequest();
      request.open(
         "GET",
         ENV === "production"
            ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/invoices`
            : ENV === "staging"
            ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/invoices`
            : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/invoices`
      );
      const token =
         (sessionStorage.getItem("access_token")
            ? sessionStorage.getItem("access_token")
            : localStorage.getItem("access_token")) ?? "";
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET_DEV;
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("access_token", token);
      request.setRequestHeader("is_website", "true");
      if (clientSecret != null)
         request.setRequestHeader("client_secret", clientSecret);
      request.send();
      request.onload = () => {
         let response = JSON.parse(request.response);
         let formatted_invoices = FormatBillingHistory(response.invoices);
         setBillingHistory(formatted_invoices);
         if (!forceRender) {
            setForceRender(true);
         }
      };
   };

   const GetBillingCycle = async () => {
      let response = await getBillingCycle();
      let billingCycle: BillingCycle = {
         start: response.data.data.start,
         end: response.data.data.end,
         baseCost: response.data.data.baseCost.toString(),
      };
      const differenceInMilliseconds =
         new Date(billingCycle.end).getTime() -
         new Date(billingCycle.start).getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      //Only time cycle would be 7 days is a trial
      if (differenceInDays === 7) {
         billingCycle.baseCost = "00.00";
      }
      setBillingCycle(billingCycle);
   };

   const FormatBillingHistory = (array_of_invoices: any) => {
      let results: BillingHistory[] = [];
      array_of_invoices.forEach((invoice: any) => {
         let billing_history: BillingHistory = {
            createdText: new Date(invoice.created * 1000).toDateString(),
            paid: invoice.paid,
            amount: "$" + invoice.amount_paid / 100,
         };
         results.push(billing_history);
      });
      return results;
   };

   return (
      <>
         {/* Billing Tab outer */}
         <section className={classes.BillingTabOuter}>
            {/* Top Section */}
            {!showBillingHistory && (
               <section className={classes.Section}>
                  <PlanDetailsComponent
                     StatusName={accountPlan.plan}
                     MonthChosen={accountPlan.monthlyBilling}
                     PaymentCardType=""
                     onUpgradeClick={() =>
                        setPlanPickerModalVisible(!planPickerModalVisible)
                     }
                     onChangePaymentClick={() =>
                        setPaymentModalVisible(!paymentModalVisible)
                     }
                     FinsishedLoading={hasLoaded}
                  />
               </section>
            )}
            <AddNewCardModal
               id={"payment-modal"}
               isVisible={paymentModalVisible}
               openPosition={{
                  top: "0",
                  left: "0",
                  right: "0px",
                  bottom: "40vh",
               }}
               closedPosition={{
                  top: "0",
                  left: "0",
                  right: "-450px",
                  bottom: "40vh",
               }}
            />

            {/* Middle Section */}
            {!showBillingHistory && (
               <section className={classes.Section}>
                  <h5 className={classes.SectionTitle}>Current charges</h5>
                  {accountPlan.plan !== "free" ? (
                     <p className={classes.SectionInfoText}>
                        Billing cycle:{" "}
                        {billingCycle?.start
                           ? new Intl.DateTimeFormat("en-US", options).format(
                                new Date(billingCycle?.start)
                             )
                           : ""}{" "}
                        –{" "}
                        {billingCycle?.end
                           ? new Intl.DateTimeFormat("en-US", options).format(
                                new Date(billingCycle?.end)
                             )
                           : ""}
                     </p>
                  ) : (
                     <p className={classes.NoInfoText}>
                        You have no recurring charges
                     </p>
                  )}
                  {accountPlan.plan !== "free" && (
                     <>
                        <div className={classes.SectionPlanRow}>
                           <p className={classes.NoInfoText}>
                              Subscription Fee
                           </p>
                           <p className={classes.ChargesText}>
                              ${billingCycle?.baseCost}
                           </p>
                        </div>
                        <div className={classes.SectionDivider}></div>
                        <div className={classes.SectionPlanRow}>
                           <p className={classes.NoInfoText}>Tour Fees</p>
                           <p className={classes.ChargesText}>$00.00</p>
                        </div>
                        <div className={classes.SectionDivider}></div>
                        <div className={classes.SectionPlanRow}>
                           <p className={classes.NoInfoText}>Running Total</p>
                           <p className={classes.ChargesText}>
                              ${billingCycle?.baseCost}
                           </p>
                        </div>
                     </>
                  )}
               </section>
            )}

            {/* Bottom Section */}
            <section className={classes.Section}>
               <h5 className={classes.SectionTitle}>Billing history</h5>
               {accountPlan.plan == "free" ? (
                  <p className={classes.NoInfoText}>
                     There is no billing history
                  </p>
               ) : (
                  <>
                     {showBillingHistory
                        ? billingCycleElementsCurrentPage
                        : billingCycleElementsRecent}
                     {showBillingHistory && (
                        <div className={classes.PageLocation}>
                           {currentPage !== 1 && (
                              <span
                                 className={classes.PageArrow}
                                 onClick={handlePreviousPage}
                              >
                                 {"<"}
                              </span>
                           )}
                           <span className={classes.SectionTitle}>
                              {" "}
                              {(currentPage - 1) * itemsPerPage + 1}-
                              {Math.min(
                                 currentPage * itemsPerPage,
                                 billingHistory
                                    ? billingHistory.length
                                    : billingCyclesAll.length
                              )}{" "}
                              of{" "}
                              {billingHistory
                                 ? billingHistory.length
                                 : billingCyclesAll.length}{" "}
                           </span>
                           {currentPage !== totalPages && (
                              <span
                                 className={classes.PageArrow}
                                 onClick={handleNextPage}
                              >
                                 {">"}
                              </span>
                           )}
                        </div>
                     )}
                     <p
                        className={classes.AllHistoryText}
                        onClick={() => {
                           setShowBillingHistory(!showBillingHistory);
                        }}
                     >
                        {!showBillingHistory
                           ? "View all billing history"
                           : "Back"}
                     </p>
                  </>
               )}
            </section>
         </section>

         {/* Plan picker modal */}
         <PlanPickerPopup
            isVisible={planPickerModalVisible}
            currentPlan={accountPlan.plan}
            monthlyBilling={accountPlan.monthlyBilling}
            onAccountPlanChange={updateAccountPlan}
         />
      </>
   );
}

const useStyles = makeStyles({ name: "billing settings styles" })((theme) => ({
   BillingTabOuter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "100%",
   },
   Section: {
      backgroundColor: "white",
      padding: "5px",
      paddingTop: "8px",
      paddingLeft: "15px",
      borderRadius: "15px",
      minWidth: "400px",
      minHeight: "100px",
      marginTop: "15px",
      marginBottom: "15px",
      boxShadow: "2px 3px 15px -5px black",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "600px",
      maxHeight: "500px",
   },
   SectionTitle: {
      margin: "0",
      fontWeight: "500",
      fontFamily: theme.fonts.primary.med,
      fontSize: "16px",
      marginBottom: "5px",
   },
   SectionPlanRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "5px",
      marginBottom: "5px",
   },
   MonthlyText: {
      fontSize: "18px",
      fontWeight: "400",
      fontFamily: theme.fonts.secondary.reg,
   },
   AccountPlanText: {
      textTransform: "capitalize",
      margin: "0",
      fontSize: "22px",
   },
   PlanPriceText: {
      margin: "0",
      marginLeft: "auto",
      marginRight: "20px",
      fontSize: "28px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
   },
   SectionDivider: {
      backgroundColor: theme.palette.common.lightGray,
      height: "1px",
      minHeight: "1px",
      maxHeight: "1px",
      width: "97%",
      marginBottom: "5px",
   },
   AvailableTokensText: {
      fontSize: "16px",
      margin: "0",
      color: "#707070",
   },
   PurchaseTokensText: {
      fontFamily: theme.fonts.primary.reg,
      color: theme.palette.common.primary,
      fontSize: "14px",
      margin: "0",
      marginLeft: "auto",
      marginRight: "20px",
   },
   UpgradeButtonContainer: {
      marginTop: "10px",
      marginLeft: "auto",
      marginRight: "10px",
      marginBottom: "10px",
   },
   UpgradeButtonText: {
      margin: "0",
      marginTop: "3px",
      display: "flex",
      alignItems: "center",
      justifyContent: " center",
      textAlign: "center",
      height: "100%",
      fontSize: "16px",
      width: "100%",
      fontFamily: theme.fonts.secondary.light,
      letterSpacing: "0.5px",
      marginRight: "5px",
   },
   NoInfoText: {
      margin: "0",
      fontSize: "16px",
      color: theme.palette.common.medGray,
      fontFamily: theme.fonts.primary.reg,
   },
   AllHistoryText: {
      margin: "10px 0px 10px 0px",
      fontSize: "16px",
      color: theme.palette.common.primary,
      fontFamily: theme.fonts.primary.reg,
      cursor: "pointer",
   },
   SectionInfoText: {
      fontSize: "16px",
      fontFamily: theme.fonts.primary.reg,
   },
   ChargesText: {
      margin: "10px 20px 10px 0px",
      //marginRight: "20px",
      fontSize: "16px",
      fontWeight: "100",
      color: theme.palette.common.medGray,
      fontFamily: theme.fonts.primary.reg,
   },
   BillingPaidText: {
      fontSize: "12px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      color: "#39b54a",
      margin: "5px 5px 5px 0px",
   },
   BillingUnPaidText: {
      fontSize: "12px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      color: "#red",
      margin: "5px 5px 5px 0px",
   },
   PageLocation: {
      marginLeft: "auto",
      marginRight: "20px",
      marginTop: "10px",
   },
   PageArrow: {
      cursor: "pointer",
      fontSize: "20px",
   },
}));

export default BillingTab;
