import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as RentIcon } from "../../styles/assets/Icons/rent-icon-dark.svg";
import { ReactComponent as ManageIcon } from "../../styles/assets/Icons/manage-icon.svg";
import RoundedButton from "../common/boxlty.button";
import EastIcon from "@mui/icons-material/East";
import StarIcon from "../../styles/assets/Icons/star.svg";

interface BusinessTierPlanCardProps {
  StatusName: string;
  ActiveListings: string;
  CostPerMonth: string;
  CostPerMonthYear: string;
  CurrentPlan?: string;
  MonthChosen: boolean;
  NextStepOption1: number;
  SetChosenPlan: Function;
  MonthlyOption: boolean;
  ListingNumbers: string;
}

function RegisterBusinessTierPlanCardComponent(
  props: BusinessTierPlanCardProps
) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {}, []);

  return (
    <Grid
      container
      direction={"column"}
      justifyContent="center"
      className={`${classes.BTCardContainer}`}
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        className={classes.HeaderContainer}
      >
        <h2 className={classes.TitleText}>{props.ListingNumbers}</h2>
      </Grid>
      <Grid
        container
        direction={"column"}
        className={classes.CardBodyContainer}
      >
        <div className={`${classes.PriceContainer}`}>
          <p className={`${classes.StartsAt}`}>STARTS AT</p>
          <p className={classes.CostStartText}>
            {props.MonthChosen && (
              <span className={classes.CostText}>{props.CostPerMonth}</span>
            )}
            {!props.MonthChosen && (
              <span className={classes.CostText}>{props.CostPerMonthYear}</span>
            )}
            /mo*
          </p>
          <p className={`${classes.DaysFree}`}>*First 7 days free</p>
        </div>
        <button
          className={
            props.CurrentPlan?.toLowerCase() === props.StatusName.toLowerCase()
              ? classes.RoundedButtonDisabled
              : classes.RoundedButton
          }
          disabled={props.CurrentPlan === props.StatusName}
          //className={classes.RoundedButton}
          onClick={() => {
            props.SetChosenPlan(
              props.StatusName,
              props.MonthChosen,
              props.NextStepOption1
            );
          }}
        >
          <div className={classes.InnerButton}>
            <span className={classes.ButtonText}>
              {props.StatusName.toLowerCase() ==
              props.CurrentPlan?.toLowerCase()
                ? "CURRENT PLAN"
                : "SELECT"}
            </span>
          </div>
        </button>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({ name: "RegisterWelcomeInstaShowComponent" })(
  (theme) => ({
    TitleText: {
      alignSelf: "center",
      fontSize: "1.8rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textAlign: "center",
    },
    DetailsText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    NextButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "85px",
    },
    HeaderContainer: {
      backgroundColor: "black",
      borderTopLeftRadius: "25px",
      borderTopRightRadius: "25px",
      color: "white",
      zIndex: "1",
    },
    CardBodyContainer: {
      backgroundColor: "white",
      height: "170px",
      borderBottomRightRadius: "25px",
      borderBottomLeftRadius: "25px",
      boxShadow: "6px 6px 12px #00000014",
      alignItems: "center",
    },
    BTCardContainer: {
      width: "330px",
      margin: "1.2rem",
      marginBottom: "2.5rem",
      position: "relative",
      marginTop: "2.0rem",
    },
    BTCardContainerPopular: {
      border: "3px solid #64E29A",
      borderRadius: "25px",
    },
    CardBackground: {
      backgroundColor: "#64E29A",
      borderTopLeftRadius: "25px",
      borderTopRightRadius: "25px",
      width: "324px",
      height: "75px",
      position: "absolute",
      top: "-40px",
      left: "-3px",
      zIndex: "0",
      border: "3px solid #64E29A",
      textAlign: "center",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "14px",
      justifyContent: "center",
    },
    PopularText: {
      marginTop: "10px",
    },
    RoundedButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background:
        "linear-gradient(45deg," + theme.palette.primary.main + ",#B8519E)",
      borderRadius: "10px",
      padding: "1px",
      width: "90%",
      cursor: "pointer",
      height: "46px",
      color: "white",
      transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
      marginBottom: "0.4rem",
    },
    RoundedButtonDisabled: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #aaaaaa",
      //borderColor: theme.palette.primary.main,
      backgroundColor: "transparent",
      borderRadius: "10px",
      padding: "1px",
      width: "90%",
      cursor: "pointer",
      height: "46px",
      color: "#aaaaaa",
      transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
      marginBottom: "0.4rem",
    },
    InnerButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "11px",
      paddingRight: "11px",
    },
    ButtonText: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "19px",
      letterSpacing: "1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    CardText: {
      alignSelf: "baseline",
      marginLeft: "6%",
      marginRight: "6%",
      marginTop: "0.5rem",
      whiteSpace: "pre-wrap",
    },
    DescriptionText: {
      alignSelf: "baseline",
      marginLeft: "6%",
      marginTop: "0.5rem",
      whiteSpace: "pre-wrap",
      height: "40px",
    },
    ListingsTeamsText: {
      alignSelf: "baseline",
      marginLeft: "6%",
      whiteSpace: "pre-wrap",
      height: "40px",
    },
    CardTextPrice: {
      alignSelf: "baseline",
      marginLeft: "6%",
      marginTop: "0.5rem",
      whiteSpace: "pre-wrap",
      marginBottom: "0",
    },
    CostStartText: {
      alignSelf: "baseline",
      marginTop: "0",
      whiteSpace: "pre-wrap",
      marginBottom: "0",
    },
    CardTextBold: {
      fontWeight: "bold",
    },
    CostText: {
      fontSize: "2.5rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    ArrowText: {
      marginTop: ".1rem !important",
    },
    Arrow: {
      color: "#39b54a",
    },
    StartsAt: {
      marginBottom: "0",
      fontSize: "10px",
      fontFamily: theme.fonts.primary.reg,
      color: theme.palette.common.medGray,
      alignSelf: "baseline",
    },
    DaysFree: {
      fontSize: "12px",
      fontStyle: "italic",
      color: theme.palette.common.medGray,
      marginTop: "0",
      alignSelf: "baseline",
    },
    PriceContainer: {
      marginTop: "0px",
      marginBottom: "10px",
      marginLeft: "6%",
      alignSelf: "baseline",
      height: "90px",
    },
    HiddenText: {
      visibility: "hidden",
    },
  })
);

export default RegisterBusinessTierPlanCardComponent;
