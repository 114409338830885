import CloseIcon from "@mui/icons-material/Close";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Checkbox, Divider, Grid,
  IconButton, Menu,
  MenuItem, Paper, useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { activateProperty, deleteProperty, makePropertyDraft } from "../../../../contexts/api/api.functions";
import { useGradient } from "../../../../contexts/gradients/gradients.context";
import { SingleListingActions, useSingleListingDispatch, useSingleListingState } from "../../../../contexts/single-listing/singleListing.context";
import { ReactComponent as PropertyTeam } from "../../../../styles/assets/Icons/property-team.svg";
import { ReactComponent as RentOrBuy } from "../../../../styles/assets/Icons/rent-or-buy.svg";
import { HeaderButton } from "../../create/forms/header.button";
import CreatedConflictModal from "../forms/created.conflict.modal";
import { useUserState } from "../../../../contexts/user/user.context";
export enum FeeOptions {
  Team = "1",
  Visitor = "2"
}

export const CreatedListingPreApprovalTab: React.FC<{ setActive: (value: boolean) => void }> = ({ setActive }) => {
  const { classes } = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [conflictModalOpen, setConflictModalOpen] = useState(false);
  const [preApproval, setPreApproval] = useState(false);
  const [fee, setFee] = useState<null | FeeOptions>(null);
  const theme = useTheme();
  const { user } = useUserState()
  const { ListingIdParam } = useParams<{ ListingIdParam: string }>()
  const navigate = useNavigate()
  const [advancedNoticeState, setAdvancedNoticeState] = useState("0");
  const { createdAdvancedNotice } = useSingleListingState();
  const { primaryButtonsGradient } = useGradient();
  const { setValue, formState, } = useFormContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [letter, setLetter] = useState(false)
  const [paystub, setPaystub] = useState(false)
  const [credit, setCredit] = useState(false)
  const methods = useFormContext()
  const dispatch = useSingleListingDispatch()
  const preApp = methods.watch('preRequest');
  const payment = methods.watch('paymentAccessOption');
  const document = methods.watch('documentRequired');
  const { listingType } = useSingleListingState()

  useEffect(() => {
    dispatch({ type: SingleListingActions.setFeature, payload: { file: "" } })
    dispatch({ type: SingleListingActions.setPhotos, payload: [] })
  }, [])

  useEffect(() => {
    dispatch({ type: SingleListingActions.setFeaturePhotoPath, payload: null })
    dispatch({ type: SingleListingActions.setPhotoPaths, payload: null })
  }, [])

  const handleDelete = async () => {
    if (ListingIdParam === undefined) {
      return
    } else {
      const res = await deleteProperty({ pId: parseInt(ListingIdParam) })
        .then(() => {
          navigate('/listings/active')
        })
        .catch((error) => {
          // console.log(error)
        })
    }
  }

  const handleArchive = async () => {
    if (ListingIdParam === undefined) {
      return
    } else {
      if (!listingType || listingType === "") {
        return
      }
      if (listingType === "archive") {
        handleMenuClose()
        return
      }
      const res = await activateProperty({ pId: parseInt(ListingIdParam), status: 0 })
        .then(() => {
          navigate('/listings/archived')
        })
        .catch((err) => {
          //console.log(err)
        })
    }
  }

  const handleDraft = async () => {
    if (ListingIdParam === undefined) {
      return
    } else {
      if (!listingType || listingType === "") {
        return
      }
      if (listingType === "draft") {
        handleMenuClose()
        return
      }
      const res = await makePropertyDraft({ pId: parseInt(ListingIdParam) })
        .then(() => {
          navigate('/listings/draft')
        })
        .catch((err) => {
          //console.log(err)
        })
    }
  }

  useEffect(() => {
    setAdvancedNoticeState(createdAdvancedNotice);
  }, [createdAdvancedNotice]);

  const handleSetVisitor = () => {
    setFee(FeeOptions.Visitor);
    setActive(true)
  };

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (preApp) {
      if (preApp === "1") {
        setPreApproval(true)
      }
    }
  }, [preApp])

  useEffect(() => {
    if (payment) {
      if (payment === "1") {
        setFee(FeeOptions.Team)
      }
      if (payment === "2") {
        setFee(FeeOptions.Visitor)
      }
    }
  }, [payment])


  useEffect(() => {
    if (document !== "0") {
      setCheckboxValue(document)
    }
  }, [document])



  useEffect(() => {
    const value = preApproval ? "1" : "0";
    setValue("preRequest", value)
  }, [preApproval])

  useEffect(() => {
    if (fee) {
      setValue("paymentAccessOption", fee)
    }
  }, [fee])

  const handleSetTeam = () => {
    setFee(FeeOptions.Team);
    setActive(true)
  };

  const handleCheckboxValue = () => {
    if (letter && !paystub && !credit) {
      setValue("documentRequired", "1")
    } else if (!letter && paystub && !credit) {
      setValue("documentRequired", "2")
    } else if (!letter && !paystub && credit) {
      setValue("documentRequired", "3")
    } else if (!letter && !paystub && !credit) {
      setValue("documentRequired", "0")
    }
  }

  const setCheckboxValue = (value: string) => {
    if (value === "0") {
      return
    }
    if (value === "1") {
      setLetter(true)
      setPaystub(false)
      setCredit(false)
    }
    if (value === "2") {
      setPaystub(true)
      setLetter(false)
      setCredit(false)
    }
    if (value === "3") {
      setCredit(true)
      setLetter(false)
      setPaystub(false)
    }
  }

  useEffect(() => {
    handleCheckboxValue()
  }, [letter, paystub, credit])

  useEffect(() => {
    if (createdAdvancedNotice && createdAdvancedNotice === "1") {
      if (preApproval === true) {
        setConflictModalOpen(true)
      }
    }
  }, [createdAdvancedNotice, preApproval])

  return (
    <>
      <Grid
        container
        direction={"column"}
        display="flex"
        className={classes.mainContainer}
      >
        <CreatedConflictModal
          id={"pre-approval-conflict-modal"}
          isVisible={conflictModalOpen}
          setIsVisible={setConflictModalOpen}
          setPreApproval={setPreApproval}
          value={advancedNoticeState}
          setValue={setAdvancedNoticeState}
          openPosition={{ top: "0", left: "0", right: "0", bottom: "40vh" }}
          closedPosition={{
            top: "0",
            left: "0",
            right: "-450px",
            bottom: "40vh",
          }}
        />
        <Grid item>
          <Grid
            container
            direction="row"
            display={"flex"}
            justifyContent="space-between"
            // Only need this margin if menu icon button is hidden
            sx={{ marginBottom: '20px' }}
          >
            <Grid item>
              <HeaderButton
                color="black"
                backgroundColor="white"
                borderColor="#707070"
                text={listingType !== "" ? listingType : "draft"}
                clickable={false}
              />
            </Grid>

            <Grid item sx={{ paddingRight: "5px" }}>
              <IconButton
                edge="start"
                id="type-selection-button"
                aria-controls={open ? "type-selection-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreHorizIcon fontSize="large" />
              </IconButton>
              <Menu
                id="type-selection-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{ "aria-labelledby": "type-selection-button" }}
                sx={{ postion: "relative", right: "60px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: 95,
                }}
              >
                <MenuItem onClick={handleDraft}>Draft</MenuItem>
                {user?.idVerify === 1 ? <MenuItem onClick={handleArchive}>Archive</MenuItem> : null}
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={"row"} display="flex">
            <Grid item>
              <Grid container direction={"column"} gap="20px">
                <Grid item>
                  <Grid container direction={"row"}>
                    <Grid item>
                      <div className={classes.preHeader}>
                        Pre-Approval Required*
                      </div>
                    </Grid>
                    <Grid item>
                      <InfoOutlined
                        style={{
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setModalOpen(!modalOpen);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.container}>
                    <div
                      className={
                        preApproval ? classes.active : classes.innactive
                      }
                      style={{
                        background: preApproval
                          ? `${primaryButtonsGradient}`
                          : "#EAEAEA",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!preApproval) {
                          setPreApproval(true);
                          if (createdAdvancedNotice === "1") {
                            setConflictModalOpen(true);
                          }
                          setActive(true)
                        } else {
                          return;
                        }
                      }}
                    >
                      YES
                    </div>
                    <div
                      className={
                        preApproval ? classes.innactive : classes.active
                      }
                      style={{
                        background: preApproval
                          ? "#EAEAEA"
                          : `${primaryButtonsGradient}`,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (preApproval) {
                          setPreApproval(false);
                          if (conflictModalOpen) {
                            setConflictModalOpen(false);
                          }
                          setActive(true)
                        } else {
                          return;
                        }
                      }}
                    >
                      NO
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  {preApproval ? (
                    <Grid container direction={"column"} display={"flex"}>
                      <Grid item>
                        <div className={classes.documentHeader}>
                          Required Document
                        </div>
                      </Grid>
                      <Grid item sx={{ marginTop: "10px" }}>
                        <Grid container direction={"column"} display="flex">
                          <Grid item>
                            <Grid container direction="row" display={"flex"}>
                              <Grid item>
                                <Checkbox
                                  size="medium"
                                  disableTouchRipple
                                  disableFocusRipple
                                  checked={letter}
                                  disableRipple
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setCredit(false)
                                      setPaystub(false)
                                      setLetter(true)
                                      setActive(true)
                                    } else if (e.target.checked === false) {
                                      setLetter(false)
                                      setActive(true)
                                    }
                                  }}
                                  sx={{ color: theme.palette.common.lightGray }}
                                />
                              </Grid>
                              <Grid item sx={{ alignSelf: "center" }}>
                                <div className={classes.checkBoxHeader}>
                                  Pre-approval letter
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" display={"flex"}>
                              <Grid item>
                                <Checkbox
                                  size="medium"
                                  disableFocusRipple
                                  disableRipple
                                  disableTouchRipple
                                  checked={paystub}
                                  sx={{ color: theme.palette.common.lightGray }}
                                  onChange={(e) => {
                                    //console.log(e.target.checked)
                                    if (e.target.checked === true) {
                                      setCredit(false)
                                      setPaystub(true)
                                      setLetter(false)
                                      setActive(true)
                                    } else if (e.target.checked === false) {
                                      setPaystub(false)
                                      setActive(true)
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item sx={{ alignSelf: "center" }}>
                                <div className={classes.checkBoxHeader}>
                                  Pay stub
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" display={"flex"}>
                              <Grid item>
                                <Checkbox
                                  size="medium"
                                  disableFocusRipple
                                  disableRipple
                                  disableTouchRipple
                                  checked={credit}
                                  sx={{ color: theme.palette.common.lightGray }}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setCredit(true)
                                      setPaystub(false)
                                      setLetter(false)
                                      setActive(true)
                                    } else if (e.target.checked === false) {
                                      setCredit(false)
                                      setActive(true)
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item sx={{ alignSelf: "center" }}>
                                <div className={classes.checkBoxHeader}>
                                  Credit report
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.modal}
              sx={{ visibility: modalOpen ? "visible" : "hidden" }}
            >
              <Paper elevation={3} className={classes.paper}>
                <Grid container direction={"column"} display="flex">
                  <Grid item display={"flex"} sx={{ alignSelf: "flex-end" }}>
                    <CloseIcon
                      fontSize="small"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                      style={{
                        color: theme.palette.common.medGray,
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    sx={{ paddingLeft: "10px", marginBottom: "15px" }}
                  >
                    <div
                      style={{
                        fontFamily: theme.fonts.secondary.bold,
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      Pre-Approval
                    </div>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <Divider
                      style={{
                        borderBottomWidth: "2px",
                        borderBottomColor: "#A2A2A2",
                        opacity: 1,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        lineHeight: "1.5",
                        color: theme.palette.common.medGray,
                        fontSize: "16px",
                        marginLeft: "10px",
                      }}
                    >
                      Pre-approval requires visitors to upload documents like
                      Pre-approval Letters, Pay Stubs, or Credit Reports for you
                      review. With pre-approval set to 'Yes', you can't use
                      'InstaShow' as Advanced Notice, as all tours need your
                      approval.
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ marginTop: "20px", marginBottom: "20px", marginRight: "45px" }}
        >
          <Divider
            style={{
              color: "#A2A2A2",
              borderBottomWidth: "2px",
              borderBottomColor: "#A2A2A2",
              opacity: 1,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container direction={"column"} display="flex">
            <Grid item sx={{ marginBottom: "7px" }}>
              <div className={classes.preHeader}>Self Tour Access Fees*</div>
            </Grid>
            <Grid item sx={{ width: "85%", marginBottom: "10px" }}>
              <div style={{ fontSize: "16px", lineHeight: 1.5 }}>
                Select who is responsible for the access fee to this property.
                If Property Team is selected, you will be charged for the access
                fees.
              </div>
            </Grid>
            <Grid item>
              <Grid container direction="row" display={"flex"} gap="15px">
                <Grid
                  item
                  sx={{
                    cursor: "pointer",
                    padding: "10px",
                    border:
                      fee === FeeOptions.Visitor
                        ? `1.5px solid ${theme.palette.primary.main}`
                        : "1.5px solid white",
                    borderRadius: "25px",
                  }}
                  onClick={handleSetVisitor}
                >
                  <RentOrBuy />
                </Grid>
                <Grid
                  item
                  sx={{
                    cursor: "pointer",
                    padding: "10px",
                    border:
                      fee === FeeOptions.Team
                        ? `1.5px solid ${theme.palette.primary.main}`
                        : "1.5px solid white",
                    borderRadius: "25px",
                  }}
                  onClick={handleSetTeam}
                >
                  <PropertyTeam />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Pre-Approval Tab" })((theme) => ({
  mainContainer: {
    paddingTop: "20px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "15px",
  },
  preHeader: {
    fontSize: "20px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    marginRight: "5px",
  },
  documentHeader: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  modal: {
    marginLeft: "5px",
    position: "relative",
    bottom: "60px",
  },
  paper: {
    height: "250px",
    borderRadius: "25px",
    padding: "10px",
    width: "320px",
    display: "flex",
  },
  checkBoxHeader: {
    fontSize: "18px",
    color: "#707070",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: "10px",
    width: "130px",
    borderRadius: "25px",
    backgroundColor: "#EAEAEA",
  },
  active: {
    display: "flex",
    padding: "10px",
    borderRadius: "25px",
    width: "60px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    justifyContent: "center",
    color: "white",
  },
  innactive: {
    display: "flex",
    padding: "10px",
    width: "60px",
    color: "#707070",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    justifyContent: "center",
    backgroundColor: "#EAEAEA",
    borderRadius: "25px",
  },
}));

