import React from "react";
import { Grid, TextField, Select, MenuItem } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useState, useEffect } from "react";
import axios from "axios";
import { ConstructionOutlined } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
export const AccessExitForm: React.FC<{}> = ({}) => {
  const { classes } = useStyles();
  const [subPropertyType, setSubPropertyType] = useState("");
  const [parkingType, setParkingType] = useState("");
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    // console.log(errors)
  }, [errors]);

  return (
    <>
      <Grid
        container
        direction={"column"}
        display="flex"
        gap={"15px"}
        sx={{ marginTop: "10px", width: "85%" }}
      >
        <Grid item>
          <div className={classes.formHeader}>Access/Exit Instructions</div>
        </Grid>
        <Grid item>
          <span className={classes.inputHeader}>
            Access Instructions* {`(200 character limit)`}
          </span>
          <TextField
            inputProps={{
              maxLength: 200,
              style: {
                paddingLeft: "5px",
                fontSize: "18px",
                color: "#707070",
                opacity: "1 ",
              },
            }}
            {...register("accessInstruction")}
            className={errors.accessInstruction ? classes.errorBorder : ""}
            sx={{
              marginTop: "7px",
              input: { "&::placeholder": { opacity: 1 } },
            }}
            placeholder=""
            fullWidth
            multiline
            rows={4}
            variant="standard"
            classes={{ root: classes.filledInput }}
            InputProps={{ disableUnderline: false }}
            size="small"
          />
          {errors.accessInstruction && (
            <p className={classes.errorText}>
              {String(errors.accessInstruction.message)}
            </p>
          )}
        </Grid>
        <Grid item>
          <span className={classes.inputHeader}>
            Exit Instructions* {`(200 character limit)`}
          </span>
          <TextField
            inputProps={{
              maxLength: 200,
              style: {
                paddingLeft: "5px",
                fontSize: "18px",
                color: "#707070",
                opacity: "1 ",
              },
            }}
            {...register("exitInstruction")}
            className={errors.exitInstruction ? classes.errorBorder : ""}
            sx={{
              marginTop: "7px",
              input: { "&::placeholder": { opacity: 1 } },
            }}
            placeholder=""
            fullWidth
            multiline
            rows={4}
            variant="standard"
            classes={{ root: classes.filledInput }}
            InputProps={{ disableUnderline: false }}
            size="small"
          />
          {errors.exitInstruction && (
            <p className={classes.errorText}>
              {String(errors.exitInstruction.message)}
            </p>
          )}
        </Grid>
        <Grid item>
          <Grid item>
            <span className={classes.inputHeader}>
              Post Tour Instructions* {`(200 character limit)`}
            </span>
            <TextField
              inputProps={{
                maxLength: 200,
                style: {
                  paddingLeft: "5px",
                  fontSize: "18px",
                  color: "#707070",
                  opacity: "1 ",
                },
              }}
              {...register("postTourInstruction")}
              className={errors.postTourInstruction ? classes.errorBorder : ""}
              sx={{
                marginTop: "7px",
                input: { "&::placeholder": { opacity: 1 } },
              }}
              placeholder=""
              fullWidth
              multiline
              rows={4}
              variant="standard"
              classes={{ root: classes.filledInput }}
              InputProps={{ disableUnderline: false }}
              size="small"
            />
            {errors.postTourInstruction && (
              <p className={classes.errorText}>
                {String(errors.postTourInstruction.message)}
              </p>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Access-Exit-Form" })((theme) => ({
  mainContainer: {},
  formHeader: {
    fontSize: "24px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
  },
  inputHeader: {
    fontFamily: theme.fonts.secondary.reg,
    color: "#A2A2A2",
    marginBottom: "7px",
    textTransform: "uppercase",
  },
  filledInput: {
    background: "#EAEAEA",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  errorBorder: {
    border: "2px solid red",
  },
}));
