import React from "react";
import {
   DataGrid,
   GridRowsProp,
   GridColDef,
   GridRowSelectionModel,
   GridRowParams,
} from "@mui/x-data-grid";
import { makeStyles } from "tss-react/mui";
import SquareIcon from "@mui/icons-material/Square";
import { ReactComponent as CheckedIcon } from "../../styles/assets/logos/checked.svg";
/// For more info visit https://mui.com/x/react-data-grid/getting-started/#installation
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import {
   GridCallbackDetails,
   GridMenuParams,
   MuiEvent,
} from "@mui/x-data-grid";
import { CircularProgress, Stack } from "@mui/material";
interface IDataTableComponentProps {
   /// Column Definitions
   columns: GridColDef[];
   selected: GridRowSelectionModel;
   setSelected: React.Dispatch<GridRowSelectionModel>;
   /// Rows Items, make sure to include id property
   items: GridRowsProp;
   apiRef: React.MutableRefObject<GridApiCommunity>;
   loading?: boolean;
   getRowID?: (params: any) => any;
   onRowClick?: (
      params: GridRowParams,
      event: MuiEvent,
      details: GridCallbackDetails
   ) => void;
}

export const DataTableComponent: React.FC<IDataTableComponentProps> = ({
   items,
   loading,
   columns,
   selected,
   setSelected,
   apiRef,
   onRowClick,
   getRowID,
}) => {
   const { classes } = useStyles();

   return (
      <>
         <div style={{ height: "75vh", width: "100%" }}>
            <DataGrid
               apiRef={apiRef}
               getRowHeight={() => "auto"}
               loading={loading}
               getRowId={getRowID}
               hideFooterSelectedRowCount
               onRowClick={onRowClick}
               rowSelectionModel={selected}
               onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelected(newRowSelectionModel);
               }}
               slotProps={{}}
               classes={{
                  main: classes.main,
                  row: classes.row,
                  cell: classes.cell,
                  iconSeparator: classes.iconSeperator,
                  iconButtonContainer: classes.iconButtonContainer,
                  columnSeparator: classes.columnSeparator,
                  columnHeaders: classes.columnHeaders,
                  columnHeader: classes.columnHeader,
               }}
               rows={items ? items : []}
               columns={columns}
               checkboxSelection
               disableColumnFilter
               disableRowSelectionOnClick
               disableColumnMenu
               sx={{
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
               }}
               slots={{
                  noRowsOverlay: () => (
                     <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                     >
                        No entries
                     </Stack>
                  ),
                  noResultsOverlay: () => (
                     <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                     >
                        No entries
                     </Stack>
                  ),
               }}
            />
         </div>
      </>
   );
};

const useStyles = makeStyles({ name: "DataTableComponent" })((theme) => ({
   columnSeparator: {
      color: "red",
   },
   columnHeader: {
      color: "#3C3C3C",
      fontSize: "20px",
      letterSpacing: "0.5px",
      fontFamily: theme.fonts.secondary.med,
      ":focus-within": {
         outline: "none !important",
      },
   },
   columnHeaderTitleContainer: {},
   row: {
      paddingTop: "2px",
      paddingBottom: "2px",
      cursor: "pointer",
      "&.Mui-selected": {
         backgroundColor: "#EAEAEA !important",
      },
   },
   cell: {
      ":focus-within": {
         outline: "none !important",
      },
   },
   iconSeperator: {
      display: "none",
   },
   columnHeaders: {
      backgroundColor: "#EAEAEA",
   },
   iconButtonContainer: {
      display: "none",
   },
   main: {
      borderTopLeftRadius: "25px",
      borderTopRightRadius: "25px",
      backgroundColor: "white",
   },
}));
