import { AvailabilityObject } from "../../../common/custom-calendar/custom.calendar";
import { TimeSlot } from "../primary-tabs/listings.availability.tab";
import { add } from 'date-fns'


export const calculateDaysAll = (start: Date, end: Date) => {
    for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};

export const generateAvailabilityObjectforEveryday = (timeSlots: TimeSlot[]) => {
    const dates = calculateDaysAll(new Date(), add(new Date(), { months: 1, days: 7 }));
    const days: AvailabilityObject[] = dates.map((date) => {
        return { date: date, timeSlots: timeSlots }
    })
    return days;
}


export function getAllDatesForSpecificWeekdaysInCurrentAndNextMonth(weekdays: number[]): Date[] {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(currentMonth + 1);
  
    const dates: Date[] = [];
  
    const isWeekdayIncluded = (date: Date, weekdays: number[]) => weekdays.includes(date.getDay());
  
    // Get dates for the current month
    while (currentDate.getMonth() === currentMonth) {
      if (isWeekdayIncluded(currentDate, weekdays)) {
        dates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Get dates for the next month
    currentDate.setMonth(nextMonth.getMonth());
    while (currentDate.getMonth() === nextMonth.getMonth()) {
      if (isWeekdayIncluded(currentDate, weekdays)) {
        dates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return dates;
  }
  

  