import { DirectionsRailwayFilledOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import mailIcon from "../../styles/assets/logos/mail-icon.svg";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";
import CodeInput from "../registration-components/CodeInput";
import {
  verifyForgotPasswordOTP,
  forgotPasswordGetOTP,
} from "../../contexts/api/api.functions";
import { Snackbar, Alert } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
interface RegisterVerifyProps {
  email: string;
  defaultValue: string;
  onPreviousStep: () => void;
  onSubmitCode: (code: string) => void;
  setOTPValue: (code: string) => void;
}

function RegisterVerifyAdjustedComponent(props: RegisterVerifyProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [displayError, setDisplayError] = useState("");
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [code, setCode] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [clearInput, setClearInput] = useState(false);

  useEffect(() => {
    if (props.defaultValue != "" && props.defaultValue != null) {
      let defaultValid = props.defaultValue.length == 6;
      setNextButtonDisabled(!defaultValid);
      setCode(props.defaultValue);
    }
  }, [code, props.defaultValue]);

  const resendCode = async () => {
    const res = await forgotPasswordGetOTP({ input: props.email });
    if (res.data && res.data.status && res.data.status === true) {
      setSnackBarOpen(true);
    } else {
      ////display some error
    }
  };

  async function validateCode() {
    let isValid = true;
    setBackdropOpen(true);
    if (code.length == 6) {
      const res = await verifyForgotPasswordOTP({
        emailId: props.email,
        otp: code,
      });
      if (res.data && res.data.status && res.data.status === true) {
        isValid = true;
      } else if (res.data && res.data.status && res.data.status === false) {
        isValid = false;
        setDisplayError(res.data.message);
      } else {
        isValid = false;
        setDisplayError("Something went wrong.");
      }
      //some sort of api call to check the code here
      //let isValid = await verifyCode(code);
    } else {
      isValid = false;
    }
    setBackdropOpen(false);
    return isValid;
  }

  async function nextStep() {
    if (await validateCode()) {
      props.setOTPValue(code);
      props.onSubmitCode(code);
    } else {
      setErrorSnackBarOpen(true);
    }
  }

  function disableNext() {
    setNextButtonDisabled(true);
  }

  function getCode(code: string) {
    setCode(code);
    if (code.length == 6) setNextButtonDisabled(false);
  }

  return (
    <section className={classes.registerVerifycontainer}>
      <h2 className={classes.registerVerifyHeader}>Enter the six digit code</h2>
      <h2 className={classes.registerVerifyHeader}>
        sent to you at: {props.email}
      </h2>
      <Backdrop
        open={backdropOpen}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setSnackBarOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setSnackBarOpen(false);
          }}
          severity="success"
          color="success"
        >
          Code Resent!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackBarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setErrorSnackBarOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setSnackBarOpen(false);
          }}
          severity="error"
          color="error"
        >
          Code could not be verified, please request a new code or try again.
        </Alert>
      </Snackbar>
      <div className={classes.codeInputSection}>
        <CodeInput
          id="register-code-input"
          defaultValue={props.defaultValue}
          onGetCode={getCode}
          onBackspace={disableNext}
          clearValue={clearInput}
        />
        <div className={classes.spacer}></div>
        <RoundedButton
          listener={() => {
            resendCode();
            setResendDisabled(true);
            setClearInput(true);
          }}
          boldText={false}
          showShadow={false}
          showArrow={false}
          arrowDirection={"left"}
          color="transparent"
          text="RESEND"
          disabled={resendDisabled}
        />
      </div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={props.onPreviousStep}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          disabled={nextButtonDisabled}
          listener={nextStep}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterVerifyAdjustedComponent" })(
  (theme) => ({
    registerVerifycontainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      height: "100%",
    },
    codeInputSection: {
      marginTop: "50px",
      marginBottom: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    spacer: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    registerVerifyHeader: {
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.bold,
      marginBottom: "75px",
      margin: "0",
    },
    registerInputBoxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    registerButtonsContainer: {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    },
    registerMailIcon: {
      height: "10px",
      width: "auto",
      marginRight: "5px",
    },
    registerVerifyInput: {
      border: "none",
      outline: "none",
      width: "80%",
      height: "20px",
      cursor: "text",
      backgroundColor: "transparent",
    },
    registerDividerLine: {
      height: "1px",
      width: "100%",
      backgroundColor: theme.palette.common.darkGray,
      marginBottom: "100px",
    },
    registerButtonSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
  })
);

export default RegisterVerifyAdjustedComponent;
