import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useGradient } from '../../../contexts/gradients/gradients.context'
import { makeStyles } from 'tss-react/mui'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material'
import { useCreateListingDispatch, useCreateListingState } from '../../../contexts/create-listing/create-listing.context'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

export const BottomSubmitComponent: React.FC<{ active: boolean }> = ({ active }) => {
const { inProgressGradient } = useGradient()  
const { classes } = useStyles()
const theme = useTheme()
const navigate = useNavigate()
// const { formState } = useFormContext()

// useEffect(() => {
//     // console.log(formState.errors)
// }, [formState.errors])

return (
    <>
        <Grid container direction={'row'}>
            <Grid item sx={{ background: `${inProgressGradient}`, display: active ? 'flex' : 'none', height: '100px' }} className={classes.mainContainer} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', right: '125px', gap: '20px'}}>
                    <Button type='submit' variant='contained'  classes={{ root: classes.saveActive, disabled: classes.saveDisabled}}>
                        SAVE CHANGES
                    </Button>
                    <Button variant='contained' onClick={() => { navigate('/listings/active')}}  classes={{ root: classes.cancelActive}}>
                        CANCEL
                    </Button>
                </div>
            </Grid>
        </Grid>
    </>
  )
}


const useStyles = makeStyles({ name: 'bottom-submit-component'})(theme => ({
    mainContainer: {
        
        width: '100%',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 0,
    },
    saveActive: {
        fontFamily: theme.fonts.secondary.med,
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: '#3A6250',
            color: 'white'
        }
    },
    saveDisabled: {

    },
    cancelActive: {
        fontFamily: theme.fonts.secondary.med,
        backgroundColor: '#A2A2A2',
        color: 'white',
        '&:hover': {
            backgroundColor: '#A2A2A2',
            color: 'white',
        }
    },
    cancelDisabled: {

    }
})) 