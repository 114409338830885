// AccountSettingsAccount.jsx
import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ModalTransparentLayer from "../../common/ModalTransparentLayer";
import BillingFrequencySlider from "./BillingFrequencySlider";
import { fadeIn, fadeOut } from "../../../utils/genericUtils";
import { Button } from "@mui/material";
import { useGradient } from "../../../contexts/gradients/gradients.context";
import RegisterPlanCardComponent from "../../registration-components/register-plancard-component";
import RegisterMonthYearOptionComponent from "../../registration-components/register-monthyearoption-component";
import CloseIcon from "@mui/icons-material/Close";
import RegisterBusinessTierPlanCardComponent from "../../registration-components/register-businesstier-component";
import zIndex from "@mui/material/styles/zIndex";
import StripeCheckoutSession from "../../../pages/checkout/StripeCheckoutSession";

interface PlanPickerProps {
  onShow?: () => void;
  onHide?: () => void;
  isVisible: boolean;
  currentPlan: string;
  monthlyBilling: boolean;
  onAccountPlanChange: (updatedAccountPlan: {
    monthlyBilling: boolean;
    plan: string;
  }) => void;
}

function PlanPickerPopup(props: PlanPickerProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const { primaryButtonsGradient } = useGradient();

  const [accountPlan, setAccountPlan] = useState({
    monthlyBilling: props.monthlyBilling,
    plan: props.currentPlan,
  });

  // Update the accountPlan and call the callback function
  const handlePlanChange = (newPlan: string) => {
    if (newPlan == "business") {
      setShowBusinessTiers(true);
    } else {
      const updatedAccountPlan = {
        monthlyBilling: monthlyOption ? true : false,
        plan: newPlan,
      };
      // console.log("handle plan change");
      // console.log(updatedAccountPlan);
      // Update the accountPlan state and call the callback function
      setAccountPlan(updatedAccountPlan);
      props.onAccountPlanChange(updatedAccountPlan);
      toggle(false);
    }
  };

  const transitionTime: number = 0.3;
  const planPickerModalId = "plan-picker-modal";
  const planPickerTransparentLayerId = "plan-picker-tranparent-layer";

  const [modalVisible, setModalVisible] = useState<boolean>(props.isVisible);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [monthlyOption, setMonthlyOption] = useState(false);
  const [showBusinessTiers, setShowBusinessTiers] = useState(false);
  const [showStripeCheckout, setShowStripeCheckout] = useState(false);
  const [planId, setPlanId] = useState<number>(0);

  useEffect(() => {
    if (!isInitialRender) toggle();
    setIsInitialRender(false);
  }, [props.isVisible, modalVisible]);

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggle(mode?: boolean | void) {
    if (mode != null) {
      toggleModal(mode);
      toggleTransparentLayer(mode);
      setModalVisible(mode);
      setShowBusinessTiers(mode);
    } else {
      toggleModal();
      toggleTransparentLayer();
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleTransparentLayer(mode: boolean | void) {
    //get transparent layer by id
    let transparentLayer: HTMLElement = document.querySelector(
      "#" + planPickerTransparentLayerId
    ) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      fadeIn(transparentLayer, transitionTime * 1000);
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      fadeOut(transparentLayer, transitionTime * 1000);
      return;
    }
    //manual mode not passed
    if (modalVisible) {
      fadeOut(transparentLayer, transitionTime * 1000);
    } else {
      fadeIn(transparentLayer, transitionTime * 1000);
    }
  }

  /**
   * Greets the given name.
   * @param {string} name - The name to greet.
   * @returns {string} A greeting message.
   */
  function toggleModal(mode: boolean | void) {
    let modal: HTMLElement = document.querySelector(
      "#" + planPickerModalId
    ) as HTMLElement;
    if (mode != null && mode) {
      //mode set to true
      //fade element on page
      fadeIn(modal, transitionTime * 1000);
      return;
    } else if (mode != null && mode === false) {
      //mode set to false
      //fade element off page
      fadeOut(modal, transitionTime * 1000);
      return;
    }

    if (modalVisible) {
      //fade element off page
      fadeOut(modal, transitionTime * 1000);
    } else {
      //fade element on page
      fadeIn(modal, transitionTime * 1000);
    }
  }

  return (
    <>
      {/* Plan Picker Component Container */}
      <section>
        {/* Transparent Layer */}
        <ModalTransparentLayer
          id={planPickerTransparentLayerId}
          transitionTime={transitionTime}
        />

        {/* Modal */}
        <section id={planPickerModalId} className={classes.PlanModalOuter}>
          {showStripeCheckout && (
            <StripeCheckoutSession
              status={"sell"}
              plan={planId}
              isMonthly={monthlyOption}
              upgrade={true}
            />
          )}
          {!showStripeCheckout && (
            <div className={classes.ScrollDiv}>
              {/* Header */}
              <button
                className={classes.CloseButton}
                onClick={() => {
                  //setShowBusinessTiers(false);
                  toggle(false);
                }}
              >
                <CloseIcon />
              </button>
              <h1 className={classes.ModalHeader}>
                Upgrade now to start using advanced features!
              </h1>

              {/* Monthly / Yearly toggle */}

              <section>
                {/* Label Row */}
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  className={classes.PlanTextContainer}
                >
                  <p
                    className={
                      monthlyOption
                        ? `${classes.PlanText} ${classes.PlanTextSelected}`
                        : `${classes.PlanText}`
                    }
                  >
                    Cancel anytime
                  </p>
                  <p
                    className={
                      !monthlyOption
                        ? `${classes.PlanText} ${classes.PlanTextSelected}`
                        : `${classes.PlanText}`
                    }
                  >
                    Save up to 33%
                  </p>
                </Grid>
                <RegisterMonthYearOptionComponent
                  MonthChosen={monthlyOption}
                  SetMonthChoice={setMonthlyOption}
                />
              </section>

              {/* Plan Types */}
              <section>
                {showBusinessTiers && (
                  <>
                    <div className={classes.BusinessTiersHeader}>
                      <Button onClick={() => setShowBusinessTiers(false)}>
                        {"< "}Back to all plans
                      </Button>
                    </div>
                    <Grid container direction={"row"} justifyContent="center">
                      <RegisterBusinessTierPlanCardComponent
                        StatusName="Business Tier 1"
                        ActiveListings="1"
                        CostPerMonth="$49.99"
                        CostPerMonthYear="$39.99"
                        MonthChosen={monthlyOption}
                        SetChosenPlan={() => {
                          setPlanId(3);
                          setShowStripeCheckout(true);
                        }}
                        NextStepOption1={3}
                        MonthlyOption={monthlyOption}
                        ListingNumbers="1-5 Active Listings"
                        CurrentPlan={props.currentPlan}
                      />
                      <RegisterBusinessTierPlanCardComponent
                        StatusName="Business Tier 2"
                        ActiveListings="1"
                        CostPerMonth="$99.99"
                        CostPerMonthYear="$89.99"
                        MonthChosen={monthlyOption}
                        SetChosenPlan={() => {
                          setPlanId(4);
                          setShowStripeCheckout(true);
                        }}
                        NextStepOption1={4}
                        MonthlyOption={monthlyOption}
                        ListingNumbers="6-9 Active Listings"
                        CurrentPlan={props.currentPlan}
                      />
                    </Grid>
                  </>
                )}
                {!showBusinessTiers && (
                  <Grid container direction={"row"} justifyContent="center">
                    <RegisterPlanCardComponent
                      StatusName="Free"
                      StatusDescription={`The fastest way to try InstaShow®
            `}
                      ActiveListings="1"
                      CostPerMonth="FREE"
                      CostPerMonthYear="FREE"
                      ButtonText={
                        props.currentPlan == "free"
                          ? "CURRENT PLAN"
                          : "GET STARTED"
                      }
                      CurrentPlan={props.currentPlan}
                      MonthChosen={monthlyOption}
                      SetMonthChosen={setMonthlyOption}
                      SetChosenPlan={() => handlePlanChange("free")}
                      NextStepOption1={1}
                      MonthlyOption={monthlyOption}
                    />
                    <RegisterPlanCardComponent
                      StatusName="Plus"
                      StatusDescription="Save more time using InstaShow® on more than one property"
                      ActiveListings="1-4"
                      CostPerMonth="$29.99"
                      CostPerMonthYear="$19.99"
                      ButtonText={
                        props.currentPlan == "plus"
                          ? "CURRENT PLAN"
                          : props.currentPlan == "business Tier 1" ||
                            "business Tier 2" ||
                            "enterprise"
                          ? "GET STARTED"
                          : "START FREE TRIAL"
                      }
                      CurrentPlan={props.currentPlan}
                      MonthChosen={monthlyOption}
                      SetMonthChosen={setMonthlyOption}
                      SetChosenPlan={() => {
                        setPlanId(2);
                        setShowStripeCheckout(true);
                      }}
                      NextStepOption1={2}
                      MonthlyOption={monthlyOption}
                    />
                    <RegisterPlanCardComponent
                      StatusName="Business"
                      StatusDescription="Scale your business and collaborate with your team"
                      ActiveListings="1-9"
                      CostPerMonth="$49.99"
                      CostPerMonthYear="$39.99"
                      ButtonText={
                        props.currentPlan == "business Tier 1" ||
                        props.currentPlan == "business Tier 2"
                          ? "CHANGE TIER"
                          : props.currentPlan == "plus" || "enterprise"
                          ? "GET STARTED"
                          : "START FREE TRIAL"
                      }
                      CurrentPlan={props.currentPlan}
                      MonthChosen={monthlyOption}
                      SetMonthChosen={setMonthlyOption}
                      SetChosenPlan={() => handlePlanChange("business")}
                      NextStepOption1={2}
                      MonthlyOption={monthlyOption}
                    />
                    {props.currentPlan != "free" && (
                      <RegisterPlanCardComponent
                        StatusName="Enterprise"
                        StatusDescription="Built for larger real estate portfolios"
                        ActiveListings="10+"
                        CostPerMonth="$0.00"
                        CostPerMonthYear="$0.00"
                        ButtonText={
                          props.currentPlan == "enterprise"
                            ? "CURRENT PLAN"
                            : "CONTACT SALES"
                        }
                        CurrentPlan={props.currentPlan}
                        MonthChosen={monthlyOption}
                        SetMonthChosen={setMonthlyOption}
                        SetChosenPlan={() => {
                          setPlanId(5);
                          setShowStripeCheckout(true);
                        }}
                        NextStepOption1={3}
                        MonthlyOption={monthlyOption}
                      />
                    )}
                  </Grid>
                )}
              </section>
            </div>
          )}
        </section>
      </section>
    </>
  );
}

const useStyles = makeStyles({ name: "Plan Picker Popup" })((theme) => ({
  PlanModalOuter: {
    backgroundColor: "#f5f6f6",
    padding: "20px",
    borderRadius: "20px",
    width: "80%",
    minWidth: "300px",
    minHeight: "100px",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    top: "52%",
    left: "52%",
    transform: "translate(-50%, -50%)", // Center the modal
    maxHeight: "82%",
    //overflow: "auto",
    //top: "20%",
    zIndex: "20",
  },
  ModalHeader: {
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    margin: "5px",
    fontSize: "35px",
    marginBottom: "25px",
  },
  PlanTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly", // Add this to evenly space the child elements
    flexWrap: "wrap", // Allow items to wrap to the next line if there isn't enough horizontal space
    gap: "25px", // Add some spacing between child elements
    marginTop: "20px",
  },

  PlanOption: {
    width: "250px", // Adjust the width based on the number of options (3 options)
    minHeight: "400px",
    backgroundColor: "#000000",
    borderRadius: "25px",
    //margin: "20px",
    boxShadow: "6px 6px 12px #00000014;",
  },
  PlanOptionTitle: {
    color: theme.palette.common.white,
    textAlign: "center",
    height: "40px",
    marginTop: "10px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    fontSize: "32px",
  },
  PlanOptionDetailsContainer: {
    backgroundColor: theme.palette.common.white,
    height: "350px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
  },
  PlanOptionDetailsSection: {
    margin: "10px",
  },
  PlanOptionDescription: {
    fontFamily: theme.fonts.primary.med,
    fontSize: "16px",
    textAlign: "left",
    height: "25px",
    paddingTop: "20px",
  },
  PlanPriceText: {
    margin: "0",
    marginLeft: "auto",
    marginRight: "20px",
    fontSize: "32px",
  },
  MonthlyText: {
    fontSize: "18px",
    fontWeight: "400",
  },
  UpgradeButtonContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
  },
  PlanText: {
    fontSize: "12px",
    width: "50%",
    textAlign: "center",
    fontWeight: "bold",
    color: "#bebebe",
  },
  PlanTextSelected: {
    color: "black",
  },
  PlanTextContainer: {
    width: "250px",
    alignItems: "center",
  },
  CloseButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    padding: "0px",
    zIndex: "1",
  },
  ScrollDiv: {
    overflowY: "auto",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    marginTop: "20px",
  },
  BusinessTiersHeader: {
    textAlign: "left",
  },
}));

export default PlanPickerPopup;
