import React from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCreateListingState, useCreateListingDispatch, CreateListingActions } from "../../../contexts/create-listing/create-listing.context";
import { createPropertyStepSevenApproval } from "../../../contexts/api/api.functions";
import { useNavigate } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";
import { useState, useEffect } from 'react'
import { Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material'
import { useTheme } from "@mui/material";
import axios from "axios";
import { S3DirectoryName } from "../../../contexts/api/api.types";
import { getListing, generatePreSignedUrl, createPropertyStepTwoPhotos } from "../../../contexts/api/api.functions";
const { v4: uuidv4 } = require('uuid');
export const createListingApprovalDefaultValues = {
  preRequest: "0",  //// 0 Not Required - 1 Required
  paymentAccessOption: "0", /// 1 Property Team - 2 Visitor
  documentRequired: "0"  /// 1 Pre-Approval Letter - 2 Pay Stubs - 3 Credit Report

};

export const ListingCreateFormContextApproval: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const schema = yup.object().shape({
    preRequest: yup.string(),
    paymentAccessOption: yup.string(),
    documentRequired: yup.string(),
  });
  const theme = useTheme()
  const { propertyId, featurePhoto, featurePhotoPath, photoPaths, photos } = useCreateListingState()
  const navigate = useNavigate()
  const dispatch = useCreateListingDispatch()
  const [successOpen, setSuccessOpen] = useState(false)
  const [photoErrorOpen, setPhotoErrorOpen] = useState(false)
  const [error, setError] = useState("")
  const [errorOpen, setErrorOpen] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false)
  const methods = useForm({
    defaultValues: createListingApprovalDefaultValues,
    resolver: yupResolver(schema),
  });



  const fetchAndUpdateValues = async () => {
    if (propertyId) {
      setBackdropOpen(true)
      const res = await getListing(parseInt(propertyId));
      if (res && res.data && res.data.status === true) {
        if (res.data.result[0].screen7[0].propertyId === parseInt(propertyId)) {
          if (res.data.result[0].screen7[0].propertyPaymentAccessOption !== null || "") {
            const value = res.data.result[0].screen7[0].propertyPaymentAccessOption;
            methods.setValue('paymentAccessOption', value);
          }

          if (res.data.result[0].screen7[0].propertyPreApprovalReq !== null || "0") {
            const value = res.data.result[0].screen7[0].propertyPreApprovalReq;
            methods.setValue('preRequest', value);
          }

          if (res.data.result[0].screen7[0].propertyPreApprovalReq !== null || "0" && res.data.result[0].screen7[0].propertyDocumentReq !== null || "0") {
            const value = res.data.result[0].screen7[0].propertyDocumentReq;
            methods.setValue('documentRequired', value);
          }

          if (res.data.result[0].screen2 && res.data.result[0].screen2.length > 0) {
            if (res.data.result[0].screen2[0].propertyFeaturePhoto !== null || "") {
              const value = res.data.result[0].screen2[0].propertyFeaturePhoto;
              dispatch({ type: CreateListingActions.setFeaturePhotoPath, payload: value })
            }

            if (res.data.result[0].screen2[0].propertyPhoto !== null || "") {
              const paths = [];
              for (var i = 0; i < res.data.result[0].screen2.length; i++) {
                const value = res.data.result[0].screen2[i].propertyPhoto;
                paths.push(value)
              }
              dispatch({ type: CreateListingActions.setPhotoPaths, payload: [...paths] })
            }
          }


        }
      }
      setBackdropOpen(false)
    } else {
      return
    }
  }


  useEffect(() => {
    fetchAndUpdateValues()
  }, [propertyId])


  const onSubmit = async (data: any) => {
    if (!propertyId) {
      setPropertyIdErrorOpen(true)
      return
    }
    if (photoPaths && photoPaths.length === 0) {
      if (photos.length === 0) {
        setPhotoErrorOpen(true)
        return
      }
    }
    if (!featurePhotoPath) {
      if (!featurePhoto) {
        setPhotoErrorOpen(true)
        return
      } else if (featurePhoto && featurePhoto.file === "") {
        setPhotoErrorOpen(true)
        return
      }
    }
    if (data.paymentAccessOption === "") {
      setError("You must select a payment option.")
      setErrorOpen(true)
      return
    }
    if (data.paymentAccessOption && data.paymentAccessOption === "0" || "") {
      setError("You must select a payment option.")
      setErrorOpen(true)
      return
    }
    if (data.preRequest === "1" && data.documentRequired === "0") {
      setError("You must select a document if opting for pre-approval.")
      setErrorOpen(true)
      return
    }
    setBackdropOpen(true)
    const body = {
      propertyId: propertyId!.toString(),
      stepId: 7,
      preRequest: data.preRequest,
      paymentAccessOption: data.paymentAccessOption,
      documentRequired: data.documentRequired,
    }
    const res = await createPropertyStepSevenApproval(body);
    if (res && res.data && res.data.status && res.data.status === true) {
      if (featurePhotoPath && photoPaths && photoPaths.length > 0 && photos.length === 0) {
        const bodyPhotos = {
          propertyId: propertyId!.toString(),
          stepId: 2,
          mainImg: featurePhotoPath,
          images: [...photoPaths]
        }
        const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos)
        if (resPhoto && resPhoto.data && resPhoto.data.status === true) {
          setBackdropOpen(false)
          setSuccessOpen(true)
          return
        } else {
          setBackdropOpen(false)
          setError("We could not update the photos, please try again.")
          setErrorOpen(true)
          return
        }
      } else if (featurePhoto.file !== "" && photos.length > 0) {
        if (!propertyId) {
          return
        }
        let featureKey: string = uuidv4();
        let photosKeyArr: Array<string> = [];
        if (featurePhotoPath === null) {
          const featureRequest = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: featureKey });
          if (featureRequest && featureRequest.data && featureRequest.data.status === true) {
            const featureUrl = featureRequest.data.result;
            await axios.put(featureUrl, featurePhoto.file)
              .then((response) => {
                // console.log(response)

              })
              .catch((error) => {
                setBackdropOpen(false)
                setError("We could not update the photos, please try again.")
                setErrorOpen(true)
                return
              })
          }
        }
        await Promise.all(
          photos.map(async (photo) => {
            let photoKey = uuidv4()

            const res1 = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: photoKey })
            if (res1 && res1.data && res1.data.status === true) {
              photosKeyArr.push(photoKey);
              const photoUrl = res1.data.result;
              await axios.put(photoUrl, photo.file)
                .then(() => {
                  // console.log(`Uploaded file ${photoKey}`)
                })
                .catch((error) => {
                  setBackdropOpen(false)
                  setError("We could not update the photos, please try again.")
                  setErrorOpen(true)
                  return
                })
            }
          })
        )

        const body4 = {
          propertyId: propertyId.toString(),
          stepId: 2,
          mainImg: featureKey,
          images: [...photosKeyArr]
        }
        const res4 = await createPropertyStepTwoPhotos(body4)
        if (res4 && res4.data && res4.data.status && res4.data.status === true) {
          setBackdropOpen(false)
          setSuccessOpen(true)
        } else {
          setBackdropOpen(false)
          setError("We could not update the photos, please try again.")
          setErrorOpen(true)
          return
        }
      } else if (featurePhotoPath && photos.length > 0) {
        if (!propertyId) {
          return
        }
        setBackdropOpen(true)
        let photosKeyArr: Array<string> = [];
        await Promise.all(
          photos.map(async (photo) => {
            let photoKey = uuidv4()

            const res1 = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: photoKey })
            if (res1 && res1.data && res1.data.status === true) {
              photosKeyArr.push(photoKey);
              const photoUrl = res1.data.result;
              await axios.put(photoUrl, photo.file)
                .then(() => {
                  // console.log(`Uploaded file ${photoKey}`)
                })
                .catch((error) => {
                  setBackdropOpen(false)
                  setError("We could not update the photos, please try again.")
                  setErrorOpen(true)
                  return
                })
            }
          })
        )
        let body4: any;
        if (photoPaths) {
          body4 = {
            propertyId: propertyId.toString(),
            stepId: 2,
            mainImg: featurePhotoPath,
            images: [...photoPaths, ...photosKeyArr]
          }
        } else {
          body4 = {
            propertyId: propertyId.toString(),
            stepId: 2,
            mainImg: featurePhotoPath,
            images: [...photosKeyArr]
          }
        }
        const res4 = await createPropertyStepTwoPhotos(body4)
        if (res4 && res4.data && res4.data.status && res4.data.status === true) {
          setBackdropOpen(false)
          setSuccessOpen(true)
          return
        } else {
          setBackdropOpen(false)
          setError("We could not update the photos, please try again.")
          setErrorOpen(true)
          return
        }
      } else if (featurePhoto.file !== "" && photoPaths) {
        let featureKey: string = uuidv4();
        const featureRequest = await generatePreSignedUrl({ directory: S3DirectoryName.PROPERTY, fileName: featureKey });
        if (featureRequest && featureRequest.data && featureRequest.data.status === true) {
          const featureUrl = featureRequest.data.result;
          await axios.put(featureUrl, featurePhoto.file)
            .then((response) => {
              // console.log(response)

            })
            .catch((error) => {
              setBackdropOpen(false)
              setError("We could not update the photos, please try again.")
              setErrorOpen(true)
              return
            })
        }
        const body5 = {
          propertyId: propertyId.toString(),
          stepId: 2,
          mainImg: featureKey,
          images: [...photoPaths]
        }
        const res5 = await createPropertyStepTwoPhotos(body5)
        if (res5 && res5.data && res5.data.status && res5.data.status === true) {
          setBackdropOpen(false)
          setSuccessOpen(true)
          return
        } else {
          setBackdropOpen(false)
          setError("We could not update the photos, please try again.")
          setErrorOpen(true)
          return
        }
      } else {
        setBackdropOpen(false)
        setSuccessOpen(true)
      }

    } else {
      setBackdropOpen(false)
      setError("We could not update the property, please try again.")
      setErrorOpen(true)
      return
    }
  };

  return (
    <FormProvider {...methods}>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessOpen(false);
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessOpen(false);
          }}
        >
          Pre-Approval and Fees Tab Saved Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={photoErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPhotoErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPhotoErrorOpen(false);
          }}
        >
          Feature photo and at least one additional photo required.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorOpen(false);
          }}
        >
          {error ? error : "Something went wrong, please try again."}
        </Alert>
      </Snackbar>
      <Snackbar
        open={propertyIdErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPropertyIdErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPropertyIdErrorOpen(false);
          }}
        >
          No Property Id, if you already created a property go back to the main listings page to edit or create another property.
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}

      >
        <CircularProgress color="primary" />
      </Backdrop>
      <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};