import React, { useEffect } from 'react'
import { SingleListingActions, useSingleListingDispatch, useSingleListingState, useSingleListingStateRef } from '../../../contexts/single-listing/singleListing.context'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'


export const SingleListingWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { propertyId } = useSingleListingState()
  const dispatch = useSingleListingDispatch()
  const { ListingIdParam } = useParams<{ ListingIdParam: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!propertyId) {
        dispatch({ type: SingleListingActions.setRedirectedBack, payload: true})
        navigate("/listings/active")
    }
    if (ListingIdParam && propertyId) {
        if (ListingIdParam !== propertyId.toString()) {
            dispatch({ type: SingleListingActions.setRedirectedBack, payload: true })
            navigate("/listings/active")
        }
    }
  }, [ListingIdParam, propertyId])

  return (
    <>
        {children}
    </>
  )
}