import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import {
   TextField,
   Grid,
   InputAdornment,
   CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as LockIcon } from "../../styles/assets/logos/locked-icon.svg";
import RoundedButton from "../common/boxlty.button";
import { forgotPasswordGetOTP } from "../../contexts/api/api.functions";
import { Backdrop } from "@mui/material";

export const ForgotPasswordFirstForm: React.FC<{
   handleGoForward: () => void;
   handleGoBack: () => void;
   formStep: number;
   setEmailOrPhone: (email_or_phone: string) => void;
}> = ({ formStep, handleGoBack, handleGoForward, setEmailOrPhone }) => {
   const [placeholder, setPlaceholder] = useState("Email or Mobile Phone");
   const [backdropOpen, setBackdropOpen] = useState(false);
   const [displayError, setDisplayError] = useState("");
   const { classes } = useStyles({
      placeholderColor:
         placeholder === "Email or Mobile Phone" ? "#a2a2a2" : "red",
   });

   const schema = yup
      .object()
      .shape({
         email_or_phone: yup
            .string()
            .required("Email/Phone is required.")
            .test("email_or_phone", "Email / Phone is invalid", (value) => {
               return (
                  validateEmail(value) || validatePhone(parseInt(value ?? "0"))
               );
            }),
      })
      .required();

   const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { errors, isSubmitting },
   } = useForm({
      resolver: yupResolver(schema),
   });

   const validateEmail = (email: string | undefined) => {
      return yup
         .string()
         .email("Please provide a valid email/phone.")
         .isValidSync(email);
   };

   const validatePhone = (phone: number | undefined) => {
      return yup
         .number()
         .typeError("Please provide a valid email/phone.")
         .integer("Please provide a valid phone number.")
         .positive("Please provide a valid phone number.")
         .test((phone) => {
            return phone &&
               phone.toString().length >= 8 &&
               phone.toString().length <= 14
               ? true
               : false;
         })
         .isValidSync(phone);
   };

   const field = watch("email_or_phone");

   const onSubmitEmailOrPhone = async (data: any) => {
      setBackdropOpen(true);
      reset();
      const res = await forgotPasswordGetOTP({ input: data.email_or_phone });

      if (res.data && res.data.status && res.data.status === true) {
         setPlaceholder("Email or Mobile Phone");
         setEmailOrPhone(data.email_or_phone);
         setBackdropOpen(false);
         handleGoForward();
      } else {
         setBackdropOpen(false);
         setDisplayError(res.data.message);
      }
      // handleGoForward()
   };

   useEffect(() => {
      if (
         errors !== undefined &&
         errors.email_or_phone !== undefined &&
         errors.email_or_phone.message
      ) {
         setPlaceholder(errors.email_or_phone.message);
      }
   }, [errors.email_or_phone]);

   useEffect(() => {
      if (isSubmitting) {
         setPlaceholder("Email or Mobile Phone");
      }
   }, [isSubmitting]);

   return (
      <div>
         <form onSubmit={handleSubmit(onSubmitEmailOrPhone)}>
            <Grid
               container
               direction={"column"}
               className={classes.mainContainer}
            >
               <Backdrop
                  open={backdropOpen}
                  sx={{
                     color: "#fff",
                     zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
               >
                  <CircularProgress color="primary" />
               </Backdrop>
               <Grid item width={"100%"}>
                  <Grid
                     container
                     direction={"column"}
                     className={classes.topContainer}
                  >
                     <h2 className={classes.header}>Forgot Password</h2>
                     <Grid
                        container
                        direction="column"
                        display={"flex"}
                        className={classes.fieldContainer}
                     >
                        <Grid item sx={{ width: "100%" }}>
                           <Grid
                              container
                              direction={"column"}
                              sx={{ gap: "10px" }}
                           >
                              <TextField
                                 variant="standard"
                                 error={
                                    placeholder !== "Email or Mobile Phone"
                                       ? true
                                       : false
                                 }
                                 fullWidth
                                 className={classes.textFieldReg}
                                 placeholder={placeholder}
                                 {...register("email_or_phone")}
                                 type={"text"}
                                 inputProps={{
                                    style: {
                                       alignItems: "center",
                                       justifyContent: "center",
                                       display: "flex",
                                       paddingLeft: "5px",
                                    },
                                 }}
                                 InputProps={{
                                    classes: { input: classes.input },
                                    startAdornment: (
                                       <InputAdornment
                                          position="start"
                                          sx={{
                                             height: "15px",
                                             position: "relative",
                                             bottom: "2px",
                                             left: "5px",
                                          }}
                                       >
                                          {<LockIcon />}
                                       </InputAdornment>
                                    ),
                                 }}
                                 sx={{
                                    "& .MuiInput-underline:before": {
                                       borderBottom: "2px solid #A2A2A2",
                                    },
                                    "&& .MuiInput-underline:hover:before": {
                                       borderBottom: "2px solid #A2A2A2",
                                    },
                                    "& .MuiInput-underline:after": {
                                       borderBottom: "2px solid #A2A2A2",
                                    },
                                 }}
                              />
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item>
                  <Grid
                     container
                     direction="row"
                     className={classes.bottomContainer}
                  >
                     <Grid item>
                        <RoundedButton
                           id={"back-button-1"}
                           listener={() => {
                              handleGoBack();
                           }}
                           boldText={false}
                           showShadow={false}
                           showArrow={true}
                           arrowDirection={"left"}
                           color="transparent"
                           text="BACK"
                           type="button"
                        />
                     </Grid>
                     <Grid item>
                        <RoundedButton
                           id={"next-button-1"}
                           disabled={
                              field !== undefined && field.length > 7
                                 ? false
                                 : true
                           }
                           type="submit"
                           listener={() => {
                              setTimeout(() => {
                                 reset();
                              }, 100);
                           }}
                           boldText={false}
                           showShadow={true}
                           showArrow={true}
                           arrowDirection={"right"}
                           color="primary"
                           text="NEXT"
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </div>
   );
};

const useStyles = makeStyles<{ placeholderColor: string }>({
   name: "ForgotPasswordFirst",
})((theme, { placeholderColor }) => ({
   mainContainer: {
      gap: "175px",
      padding: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   topContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "30px",
   },
   bottomContainer: {},
   header: {
      color: "black",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
   },
   nextButton: {
      backgroundColor: theme.palette.common.lightGray,
   },
   labelFocused: {},
   labelReg: {
      color: theme.palette.common.medGray,
      paddingLeft: "30px",
   },
   textFieldReg: {
      "&:placeholder": {
         fontSize: "8px",
      },
   },
   textFieldFocused: {
      borderRadius: "25px",
      boxShadow: "4px 4px 7px 0px #A2A2A2",
      height: "45px",
   },
   adornmentStart: {
      paddingLeft: "5px",
   },
   adornmentEnd: {
      paddingRight: "5px",
   },
   underline: {},
   fieldContainer: {
      alignItems: "center",
      justifyContent: "center",
      gap: "30px",
   },
   input: {
      "&::placeholder": {
         color: placeholderColor,
         opacity: placeholderColor === "#a2a2a2" ? 0.9 : 1,
         textOverflow: "ellipsis",
      },
   },
}));
