import React, { FormEvent, useEffect, useState, useRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
   loadStripe,
   Stripe,
   StripeElements,
   StripePaymentElement,
   StripePaymentElementOptions,
} from "@stripe/stripe-js";
import RoundedButton from "../common/boxlty.button";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface StripeCardInputProps {
   clientSecret: string;
   onSetErrorMessage: (string: string) => void;
   onShowSnackBar: (boolean: boolean) => void;
   forUpdatePaymentMethod?: boolean;
   onPaymentMethodCreated?: (payment_method_id: string) => void;
   onPaymentMethodFailed?: (error: string) => void;
   onCancel?: () => void;
   submitButtonText?: string;
   cancelButtonText?: string;
}

function StripeCardInput(props: StripeCardInputProps) {
   const { classes } = useStyles();
   const theme = useTheme();
   const [stripe, setStripe] = useState<Stripe | null>(null);
   const elements = useRef<StripeElements | null>(null);
   const [refresh, setRefresh] = useState(false);
   const ENV = process.env.REACT_APP_ENV;

   // Initialize Stripe when the component mounts
   useEffect(() => {
      async function initializeStripe() {
         const stripeInstance = await loadStripe(
            ENV === "production"
               ? `pk_live_CP0lH53WSjsixpGt1SpT7AIR00r3hsFu9Z`
               : ENV === "staging"
               ? `pk_test_IUJ8eV56KDoc180JLErXs4MI00LS8rcTzN`
               : `pk_test_IUJ8eV56KDoc180JLErXs4MI00LS8rcTzN`
         );
         setStripe(stripeInstance);
      }

      if (stripe === null) {
         initializeStripe();
      }
   }, [stripe]);

   // Create the payment element once the Stripe instance is available
   useEffect(() => {
      if (stripe && props.clientSecret) {
         elements.current = stripe.elements({
            clientSecret: props.clientSecret,
         });

         const paymentElementOptions: StripePaymentElementOptions = {
            layout: "tabs",
         };

         const paymentElement: StripePaymentElement = elements.current.create(
            "payment",
            paymentElementOptions
         );
         paymentElement.mount("#payment-element");

         // Handle any events or callbacks related to the payment element here
         paymentElement.on("change", (event) => {
            // Handle changes in the payment element
         });
      }
      console.count(props.clientSecret);
   }, [stripe, props.clientSecret]);

   async function handleSubmit(e: FormEvent) {
      e.preventDefault();
      if (stripe === null || elements.current === null) return;

      if (
         props.forUpdatePaymentMethod != null &&
         props.forUpdatePaymentMethod == true
      ) {
         const { error, setupIntent } = await stripe.confirmSetup({
            elements: elements.current,
            confirmParams: {
               return_url: window.location.href,
            },
            redirect: "if_required",
         });

         if (
            setupIntent &&
            setupIntent.payment_method &&
            props.onPaymentMethodCreated
         ) {
            props.onPaymentMethodCreated(setupIntent.payment_method.toString());
            //close the update payment method component
            if (props.onCancel) props.onCancel();
            return;
         }

         if (
            !setupIntent &&
            error &&
            error.message &&
            props.onPaymentMethodFailed
         ) {
            props.onPaymentMethodFailed(error.message.toString());
            return;
         }

         return;
      } else {
         const { error } = await stripe.confirmPayment({
            elements: elements.current,
            confirmParams: {
               return_url: window.location.href,
            },
         });

         if (error) {
            // console.log('Stripe Confirm Payment Error: ', error);
            if (error.type === "card_error") {
               // Set snackbar alert here
               if (props.onSetErrorMessage)
                  props.onSetErrorMessage(error.message?.toString() ?? "");
               if (props.onShowSnackBar) props.onShowSnackBar(true);
            }
            // console.log(props.clientSecret);
         }
      }
   }

   if (stripe === null) return <></>;

   return (
      <form
         onSubmit={(e) => {
            handleSubmit(e);
         }}
      >
         <div id="payment-element"></div>
         <div className={classes.CreditCardSubmitContainer}>
            <RoundedButton
               type={"submit"}
               marginLeft="0px"
               marginRight="25px"
               color={"transparent"}
               disabled={false}
               listener={() => {
                  //console.log("submit payment clicked");
               }}
               text={
                  props.submitButtonText
                     ? props.submitButtonText
                     : "SUBMIT PAYMENT"
               }
               showArrow={false}
               showShadow={false}
            />

            {props.onCancel ? (
               <RoundedButton
                  type={"submit"}
                  marginLeft="0px"
                  marginRight="0px"
                  color={"transparent"}
                  disabled={false}
                  listener={props.onCancel}
                  text={
                     props.cancelButtonText ? props.cancelButtonText : "Cancel"
                  }
                  showArrow={false}
                  showShadow={false}
               />
            ) : (
               <></>
            )}
         </div>
      </form>
   );
}

const useStyles = makeStyles({ name: "Payment Element" })((theme) => ({
   CreditCardSubmitContainer: {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
   },
}));

export default StripeCardInput;
