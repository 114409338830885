import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { IUserState } from "./user.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { userLogin, userLoginSession, userLogout } from "./user.functions";
import { getUserProfile } from "../api/api.functions";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";

export enum UserActions {
   accessToken = "accessToken",
   refreshToken = "refreshToken",
   userId = "userId",
   login = "login",
   loginSession = "loginSession",
   logout = "logout",
   user = "user", ///// For Super Admins user.accountType, Enterprise Non-Lender = 5 Lender = 6
   loading = "loading",
   error = "error",
   visitCount = "visit_count",
}

const initialState = {
   accessToken: null,
   refreshToken: null,
   user: null,
   loading: false,
   error: null,
   userId: null,
   visitCount: null,
};

const reducer = (state: IUserState, action: IAction): IUserState => {
   const newState = { ...state };
   switch (action.type) {
      case UserActions.accessToken:
         newState.accessToken = action.payload;
         break;
      case UserActions.refreshToken:
         newState.refreshToken = action.payload;
         break;
      case UserActions.userId:
         newState.userId = action.payload;
         break;
      case UserActions.user:
         newState.user = action.payload;
         break;
      case UserActions.loading:
         newState.loading = action.payload;
         break;
      case UserActions.error:
         newState.error = action.payload;
         break;

      default:
         return state;
   }

   return newState;
};

export const UserStateContext: any = React.createContext({});
export const UserDispatchContext: any = React.createContext({});
export const UserStateRefContext: any = React.createContext({});

export const useUserState = (): IUserState => useContext(UserStateContext);
export const useUserDispatch = (): Dispatch<IAction> =>
   useContext(UserDispatchContext);
export const useUserStateRef = (): React.MutableRefObject<IUserState> =>
   useContext(UserStateRefContext);

const middleware = [logger("User"), userLoginSession, userLogin, userLogout];

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, userStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );

   const getFullUser = async () => {
      if (
         getStoredItem(StorageKeys.access_token) &&
         getStoredItem(StorageKeys.userId)
      ) {
         const userId = getStoredItem(StorageKeys.userId);
         if (userId) {
            const res = await getUserProfile({ userId: userId });
            if (res && res.data && res.data.status === true) {
               dispatch({ type: UserActions.user, payload: res.data.result });
               // console.log("full user loaded");
            }
         }
      }
   };

   useEffect(() => {
      getFullUser();
   }, [state.accessToken]);
   return (
      <UserStateContext.Provider value={state}>
         <UserDispatchContext.Provider value={dispatch}>
            <UserStateRefContext.Provider value={userStateRef}>
               {children}
            </UserStateRefContext.Provider>
         </UserDispatchContext.Provider>
      </UserStateContext.Provider>
   );
};

// const middleware = [logger("User"), userLoginSession, userLogin, userLogout];

// export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
//   children,
// }) => {
//   const [state, dispatch, userStateRef] = useEnhancedReducer(
//     reducer,
//     initialState,
//     (state) => ({ ...state }),
//     middleware
//   );
//   const {} = useApi();

// };
