import React from 'react'
import { Controller } from "react-hook-form";
import { Select, SxProps } from '@mui/material'
type Props = {
    name: string,
    control: any,
    label?: string,
    defaultValue?: string,
    children: React.ReactNode,
    variant?: string,
    disableUnderline?: boolean,
    className?: string,
    sx?: SxProps
}

export const ReactHookFormSelect: React.FC<Props> = ({ name, control, defaultValue, children, className, disableUnderline, variant, sx }) => {
    const labelId = `${name}-label`;
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref }}) => (
                <Select variant="standard" disableUnderline className={className} onChange={onChange} onBlur={onBlur} value={value} sx={sx}>
                    {children}
                </Select>
            )}

        />
    )
}