import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as RentIcon } from "../../styles/assets/Icons/rent-icon-dark.svg";
import { ReactComponent as ManageIcon } from "../../styles/assets/Icons/manage-icon.svg";
import RoundedButton from "../common/boxlty.button";
import RegisterPlanCardComponent from "./register-plancard-component";

interface MonthYearOptionProps {
  MonthChosen: boolean;
  SetMonthChoice: Function;
}

function RegisterMonthYearOptionComponent(props: MonthYearOptionProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {}, []);

  const setOption = (choice: boolean) => {
    props.SetMonthChoice(choice);
  };

  return (
    <Grid container direction={"row"} className={classes.OptionContainer}>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        className={
          props.MonthChosen
            ? `${classes.Option} ${classes.SelectedOption}`
            : `${classes.Option}`
        }
        onClick={() => setOption(true)}
      >
        <p
          className={
            !props.MonthChosen
              ? `${classes.OptionText} ${classes.OptionTextSelected}`
              : `${classes.OptionText}`
          }
        >
          MONTHLY BILLING
        </p>
      </Grid>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        className={
          !props.MonthChosen
            ? `${classes.Option} ${classes.SelectedOption}`
            : `${classes.Option}`
        }
        onClick={() => setOption(false)}
      >
        <p
          className={
            props.MonthChosen
              ? `${classes.OptionText} ${classes.OptionTextSelected}`
              : `${classes.OptionText}`
          }
        >
          YEARLY BILLING
        </p>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({ name: "RegisterWelcomeInstaShowComponent" })(
  (theme) => ({
    OptionContainer: {
      width: "250px",
      height: "35px",
      borderRadius: "25px",
      backgroundColor: "#eaeaea",
    },
    Option: {
      width: "50%",
      height: "100%",
      borderRadius: "25px",
      background: "transparent",
      // background: "linear-gradient(45deg,#7CE7F5FC,#61D7E2)",
      cursor: "pointer",
    },
    SelectedOption: {
      background: "linear-gradient(45deg,#7CE7F5FC,#61D7E2) !important",
    },
    OptionText: {
      fontSize: "13px",
      textAlign: "center",
      color: "black",
      fontWeight: "bold",
    },
    OptionTextSelected: {
      color: "#bebebe",
    },
  })
);

export default RegisterMonthYearOptionComponent;
