import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { TextField, Grid, InputAdornment } from "@mui/material";
import { ReactComponent as LockIcon } from "../../styles/assets/logos/locked-icon.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RoundedButton from "../common/boxlty.button";
import { useNavigate } from "react-router-dom";
import { changePasswordAfterVerify } from "../../contexts/api/api.functions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
export const ForgotPasswordThirdForm: React.FC<{
   handleGoForward: () => void;
   handleGoBack: () => void;
   formStep: number;
   email: string;
   code: string;
}> = ({ formStep, handleGoBack, handleGoForward, email, code }) => {
   const navigate = useNavigate();
   const [placeholderPassword, setPlaceholderPassword] =
      useState("New Password");
   const [placeholderConfirm, setPlaceholderConfirm] =
      useState("Retype Password");
   const [passwordType, setPasswordType] = useState("password");
   const [confirmType, setConfirmType] = useState("password");
   const [snackBarOpen, setSnackBarOpen] = useState(false);
   const [backdropOpen, setBackdropOpen] = useState(false);
   const { classes } = useStyles({
      placeholderColorPassword:
         placeholderPassword === "New Password" ? "#a2a2a2" : "red",
      placeholderColorConfirm:
         placeholderConfirm === "Retype Password" ? "#a2a2a2" : "red",
   });
   const schema = yup
      .object()
      .shape({
         password: yup
            .string()
            .required()
            .min(8, "Password must be a minimum of 8 characters.")
            .matches(
               /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
               "Password must contain 1 uppercase, 1 lowercase, and 1 special character."
            ),
         confirmPassword: yup
            .string()
            .test("passwords-match", "Passwords must match.", function (value) {
               return this.parent.password === value;
            })
            .required(),
      })
      .required();

   const onSubmitResetPassword = async (data: any) => {
      setBackdropOpen(true);
      const res = await changePasswordAfterVerify({
         input: email,
         otp: code,
         password: data.confirmPassword,
      });
      if (res.data && res.data.status === true) {
         setSnackBarOpen(true);
         setTimeout(() => {
            navigate("/login");
         }, 5000);
      } else {
         setBackdropOpen(false);
         return;
      }
   };

   const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { errors, isSubmitting },
   } = useForm({
      resolver: yupResolver(schema),
   });

   useEffect(() => {
      if (
         errors !== undefined &&
         errors.password !== undefined &&
         errors.password.message
      ) {
         setPlaceholderPassword(errors.password.message);
      }
   }, [errors.password]);

   useEffect(() => {
      if (
         errors !== undefined &&
         errors.confirmPassword !== undefined &&
         errors.confirmPassword.message
      ) {
         setPlaceholderPassword(errors.confirmPassword.message);
      }
   }, [errors.confirmPassword]);

   useEffect(() => {
      if (isSubmitting) {
         setPlaceholderPassword("New Password");
         setPlaceholderConfirm("Retype Password");
      }
   }, [isSubmitting]);

   const passwordField = watch("password");
   const confirmField = watch("confirmPassword");

   return (
      <div>
         <form onSubmit={handleSubmit(onSubmitResetPassword)}>
            <Grid
               container
               direction={"column"}
               className={classes.mainContainer}
            >
               <Backdrop
                  open={backdropOpen}
                  sx={{
                     color: "#fff",
                     zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
               >
                  <CircularProgress color="primary" />
               </Backdrop>
               <Snackbar
                  open={snackBarOpen}
                  autoHideDuration={4000}
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
                  onClose={() => {
                     setSnackBarOpen(false);
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               >
                  <Alert
                     onClose={() => {
                        setSnackBarOpen(false);
                     }}
                     severity="success"
                     color="success"
                  >
                     Password Changed! You will now be redirected to login.
                  </Alert>
               </Snackbar>
               <Grid item>
                  <Grid
                     container
                     direction={"column"}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     className={classes.topContainer}
                  >
                     <h2 className="header">Reset Password</h2>

                     <Grid
                        container
                        direction={"column"}
                        display="flex"
                        className={classes.fieldContainer}
                     >
                        <Grid item sx={{ width: "100%" }}>
                           <TextField
                              variant="standard"
                              fullWidth
                              error={
                                 placeholderPassword !== "New Password"
                                    ? true
                                    : false
                              }
                              className={classes.textFieldReg}
                              placeholder={placeholderPassword}
                              {...register("password")}
                              type={passwordType}
                              inputProps={{
                                 style: {
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    paddingLeft: "5px",
                                 },
                              }}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position="start"
                                       sx={{
                                          height: "15px",
                                          position: "relative",
                                          bottom: "2px",
                                          left: "5px",
                                       }}
                                    >
                                       {<LockIcon />}
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment
                                       position="end"
                                       onClick={() => {
                                          if (passwordType === "password") {
                                             setPasswordType("text");
                                          } else {
                                             setPasswordType("password");
                                          }
                                       }}
                                       sx={{ cursor: "pointer" }}
                                    >
                                       {passwordType === "password" ? (
                                          <VisibilityIcon />
                                       ) : (
                                          <VisibilityOffIcon />
                                       )}
                                    </InputAdornment>
                                 ),
                                 classes: { input: classes.inputPassword },
                              }}
                              sx={{
                                 "& .MuiInput-underline:before": {
                                    borderBottom: "2px solid #A2A2A2",
                                 },
                                 "&& .MuiInput-underline:hover:before": {
                                    borderBottom: "2px solid #A2A2A2",
                                 },
                                 "& .MuiInput-underline:after": {
                                    borderBottom: "2px solid #A2A2A2",
                                 },
                              }}
                           />
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                           <TextField
                              variant="standard"
                              fullWidth
                              error={
                                 placeholderConfirm !== "Retype Password"
                                    ? true
                                    : false
                              }
                              className={classes.textFieldReg}
                              placeholder={placeholderConfirm}
                              {...register("confirmPassword")}
                              type={confirmType}
                              inputProps={{
                                 style: {
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    paddingLeft: "5px",
                                 },
                              }}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position="start"
                                       sx={{
                                          height: "15px",
                                          position: "relative",
                                          bottom: "2px",
                                          left: "5px",
                                       }}
                                    >
                                       {<LockIcon />}
                                    </InputAdornment>
                                 ),
                                 endAdornment: (
                                    <InputAdornment
                                       position="end"
                                       sx={{ cursor: "pointer" }}
                                       onClick={() => {
                                          if (confirmType === "password") {
                                             setConfirmType("text");
                                          } else {
                                             setConfirmType("password");
                                          }
                                       }}
                                    >
                                       {confirmType === "password" ? (
                                          <VisibilityIcon />
                                       ) : (
                                          <VisibilityOffIcon />
                                       )}
                                    </InputAdornment>
                                 ),
                                 classes: { input: classes.inputConfirm },
                              }}
                              sx={{
                                 "& .MuiInput-underline:before": {
                                    borderBottom: "2px solid #A2A2A2",
                                 },
                                 "&& .MuiInput-underline:hover:before": {
                                    borderBottom: "2px solid #A2A2A2",
                                 },
                                 "& .MuiInput-underline:after": {
                                    borderBottom: "2px solid #A2A2A2",
                                 },
                              }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item>
                  <Grid
                     container
                     direction="row"
                     className={classes.bottomContainer}
                  >
                     <Grid item>
                        <RoundedButton
                           id={"back-button-3"}
                           listener={() => {
                              handleGoBack();
                           }}
                           boldText={false}
                           showShadow={false}
                           showArrow={true}
                           arrowDirection={"left"}
                           color="transparent"
                           text="BACK"
                           type="button"
                        />
                     </Grid>
                     <Grid item>
                        <RoundedButton
                           id={"next-button-3"}
                           disabled={
                              passwordField !== undefined &&
                              confirmField !== undefined &&
                              passwordField.length >= 8 &&
                              confirmField.length >= 8
                                 ? false
                                 : true
                           }
                           type="submit"
                           listener={() => {
                              setTimeout(() => {
                                 reset();
                              }, 100);
                           }}
                           boldText={false}
                           showShadow={true}
                           showArrow={true}
                           arrowDirection={"right"}
                           color="primary"
                           text="NEXT"
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </div>
   );
};

const useStyles = makeStyles<{
   placeholderColorPassword: string;
   placeholderColorConfirm: string;
}>({ name: "ForgotPasswordFirst" })(
   (theme, { placeholderColorPassword, placeholderColorConfirm }) => ({
      mainContainer: {
         gap: "117px",
         padding: theme.spacing(1),
      },
      topContainer: {
         display: "flex",

         alignItems: "center",
         justifyContent: "center",
         gap: "30px",
      },
      bottomContainer: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      header: {
         color: "black",
         fontFamily: theme.fonts.secondary.bold,
         fontWeight: "bold",
         fontSize: "24px",
         alignItems: "center",
         display: "flex",
         justifyContent: "center",
         textAlign: "center",
      },
      nextButton: {
         background: theme.palette.common.lightGray,
      },
      fieldContainer: {
         alignItems: "center",
         justifyContent: "center",
         gap: "30px",
      },
      labelReg: {
         color: theme.palette.common.medGray,
         paddingLeft: "30px",
      },
      textFieldReg: {
         "&:placeholder": {
            fontSize: "8px",
         },
      },
      textFieldFocused: {
         borderRadius: "25px",
         boxShadow: "4px 4px 7px 0px #A2A2A2",
         height: "45px",
      },
      adornmentStart: {
         paddingLeft: "5px",
      },
      adornmentEnd: {
         paddingRight: "5px",
      },
      underline: {},
      inputPassword: {
         "&::placeholder": {
            color: placeholderColorPassword,
            opacity: placeholderColorPassword === "#a2a2a2" ? 0.9 : 1,
            textOverflow: "ellipsis",
         },
      },
      inputConfirm: {
         "&::placeholder": {
            color: placeholderColorConfirm,
            opacity: placeholderColorConfirm === "#a2a2a2" ? 0.9 : 1,
            textOverflow: "ellipsis",
         },
      },
   })
);
