import React, { useEffect } from "react";
import {
   Grid,
   Tabs,
   Tab,
   Button,
   TextField,
   Snackbar,
   Alert,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "@mui/material";
import { ListingPreviewModal } from "../preview/listing.preview.modal";
import {
   useSingleListingDispatch,
   useSingleListingState,
   SingleListingActions,
} from "../../../contexts/single-listing/singleListing.context";
import { createListingDefaultValues } from "./listing.created.general.form.context";
import { CreatePropertyGeneral } from "../../../contexts/create-listing/create-listing.types";
import { activateProperty } from "../../../contexts/api/api.functions";
import { useUserState } from "../../../contexts/user/user.context";
import FeatureUnavailableModal from "../../common/featureunavailable.modal";
export interface PreviewListingObject {
   nickName: string;
   propertyCreateTypeId: string;
   price: string;
   address: string;
   city: string;
   state: string;
   zipCode: string;
   propertyTypeId: string;
   lotSize: string;
   sqFoot: string;
   bathrooms: string;
   bedrooms: string;
   parkingTypeId: string;
   parkingSpace: string;
   propertyHeadline: string;
   generalInformation: string;
   utilities: string;
   petPolicyId: string;
   petPolicyDescription: string;
   zlink: string;
   accessInstruction: string;
   exitInstruction: string;
   postTourInstruction: string;
   propertyId: string;
}

export const ListingsCreatedTop: React.FC<{
   setActive: (value: boolean) => void;
}> = ({ setActive }) => {
   const { classes } = useStyles();
   const { user } = useUserState();
   const { ListingIdParam } = useParams<{ ListingIdParam: string }>();
   const { CreatedCurrentMainTab } = useParams<{
      CreatedCurrentMainTab: string;
   }>();
   const { CreatedCurrentSecondaryTab } = useParams<{
      CreatedCurrentSecondaryTab: string;
   }>();
   const [showUpgradePanel, setShowUpgradePanel] = useState(false);
   const [showInput, setShowInput] = useState(false);
   const [previewOpen, setPreviewOpen] = useState(false);
   const [preview, setPreview] = useState<CreatePropertyGeneral>(
      createListingDefaultValues
   );
   const [previewPhotosCreated, setPreviewPhotosCreated] = useState<string[]>(
      []
   );
   const { photos, featurePhoto, listingType, canActivate } =
      useSingleListingState();
   const navigate = useNavigate();
   const theme = useTheme();
   const { nickName, previewObject, featurePhotoPath, photoPaths } =
      useSingleListingState();
   const dispatch = useSingleListingDispatch();

   const handleClick = () => {
      setShowInput(true);
   };

   const handleClickAway = () => {
      setShowInput(false);
   };

   const handleModalClose = () => {
      setPreviewOpen(false);
   };

   const setSavedPhotos = () => {
      let photosArr: Array<string> = [];
      if (featurePhotoPath) {
         photosArr.push(featurePhotoPath);
      }
      if (photoPaths && photoPaths.length > 0) {
         for (let i = 0; i < photoPaths.length; i++) {
            photosArr.push(photoPaths[i]);
         }
      }
      const fullArr: Array<string> = [...photosArr];
      setPreviewPhotosCreated(fullArr);
   };

   useEffect(() => {
      setSavedPhotos();
   }, [photoPaths, featurePhotoPath]);

   const handleSetOpen = () => {
      const values = previewObject;
      setPreview(values);

      setPreviewOpen(true);
   };

   const handleActivate = async () => {
      setShowUpgradePanel(false);
      if (ListingIdParam === undefined) {
         return;
      } else {
         if (listingType === "" || null) {
            return;
         }
         if (listingType === "active") {
            return;
         }
         if (canActivate === false) {
            return;
         }
         await activateProperty({
            pId: parseInt(ListingIdParam),
            status: 1,
         })
            .then((data: any) => {
               if (
                  data != null &&
                  data.data != null &&
                  data.data.result != null &&
                  data.data.result.enterpriseUpdate !== undefined
               ) {
                  switch (data.data.result.enterpriseUpdate) {
                     case 0:
                        setShowUpgradePanel(true);
                        break;
                     case 1:
                        setShowUpgradePanel(true);
                        break;
                     default:
                        navigate("/listings/active");
                        break;
                  }
               } else {
                  setShowUpgradePanel(true);
               }
            })
            .catch((err) => {
               console.log(err);
            });
      }
   };

   return (
      <>
         <FeatureUnavailableModal
            id={"feature-unavailable-modal"}
            isVisible={showUpgradePanel}
            setIsVisible={setShowUpgradePanel}
            openPosition={{ top: "0", left: "0", right: "0", bottom: "40vh" }}
            closedPosition={{
               top: "0",
               left: "0",
               right: "-450px",
               bottom: "40vh",
            }}
         />
         <Grid
            container
            direction="row"
            display="flex"
            justifyContent={"space-between"}
         >
            <ListingPreviewModal
               open={previewOpen}
               created={true}
               photosArray={[...previewPhotosCreated]}
               onClose={handleModalClose}
               listing={preview}
            />
            <Grid item>
               <Grid container direction="column">
                  <Grid item width={"700px"}>
                     <TextField
                        variant="standard"
                        fullWidth
                        inputProps={{
                           style: {
                              fontSize: "36px",
                              fontFamily: theme.fonts.secondary.bold,
                              fontWeight: "bold",
                              // textDecoration: "underline",
                           },
                        }}
                        InputProps={{ disableUnderline: true, readOnly: true }}
                        value={nickName}
                     />
                  </Grid>
                  <Grid item sx={{ marginBottom: "10px" }}>
                     <Tabs
                        value={CreatedCurrentMainTab}
                        classes={{
                           root: classes.tabsRoot,
                           flexContainer: classes.tabsFlex,
                           indicator: classes.tabsIndicator,
                        }}
                     >
                        <Tab
                           label="GENERAL"
                           value="general"
                           disableFocusRipple
                           disableRipple
                           onClick={() => {
                              setActive(false);
                              navigate(
                                 `/listings/listing/${ListingIdParam}/general/${CreatedCurrentSecondaryTab}`
                              );
                           }}
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label="TEAM"
                           value="team"
                           disableFocusRipple
                           disableRipple
                           onClick={() => {
                              setActive(false);
                              navigate(
                                 `/listings/listing/${ListingIdParam}/team/${CreatedCurrentSecondaryTab}`
                              );
                           }}
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label="AVAILABILITY"
                           value="availability"
                           disableFocusRipple
                           disableRipple
                           onClick={() => {
                              setActive(false);
                              navigate(
                                 `/listings/listing/${ListingIdParam}/availability/${CreatedCurrentSecondaryTab}`
                              );
                           }}
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label="HARDWARE"
                           value="hardware"
                           disableFocusRipple
                           disableRipple
                           onClick={() => {
                              setActive(false);
                              navigate(
                                 `/listings/listing/${ListingIdParam}/hardware/${CreatedCurrentSecondaryTab}`
                              );
                           }}
                           classes={{ root: classes.tabSingleRoot }}
                        />
                        <Tab
                           label={`PRE-APPROVAL & FEES`}
                           value="pre-approval"
                           disableFocusRipple
                           disableRipple
                           onClick={() => {
                              setActive(false);
                              navigate(
                                 `/listings/listing/${ListingIdParam}/pre-approval/${CreatedCurrentSecondaryTab}`
                              );
                           }}
                           classes={{ root: classes.tabSingleRoot }}
                        />
                     </Tabs>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item sx={{ paddingRight: "20px" }}>
               <Grid container direction={"row"} gap={"15px"}>
                  {listingType !== "active" &&
                  user?.idVerify &&
                  user?.idVerify === 1 &&
                  canActivate ? (
                     <Grid item>
                        <Button
                           variant="contained"
                           classes={{ disabled: classes.activateDisabled }}
                           disableElevation
                           disabled={false}
                           onClick={handleActivate}
                        >
                           Activate
                        </Button>
                     </Grid>
                  ) : listingType !== "active" &&
                    user?.idVerify &&
                    user?.idVerify === 1 &&
                    !canActivate ? (
                     <Grid item>
                        <Button
                           variant="contained"
                           classes={{ disabled: classes.activateDisabled }}
                           disableElevation
                           disabled={true}
                           onClick={handleActivate}
                        >
                           Activate
                        </Button>
                     </Grid>
                  ) : null}
                  <Grid item>
                     <Button
                        variant="contained"
                        onClick={() => {
                           handleSetOpen();
                        }}
                        disableElevation
                        disabled={false}
                        classes={{
                           root: classes.previewRoot,
                           disabled: classes.previewDisabled,
                        }}
                     >
                        PREVIEW
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Listings-Create-Top" })((theme) => ({
   pageHeader: {
      fontSize: "36px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textDecoration: "underline",
   },
   activateDisabled: {
      backgroundColor: "#F5F6F6 !important",
      border: "1px solid rgba(0, 0, 0, 0.26) !important",
   },
   previewRoot: {
      backgroundColor: "#F5F6F6",
      border: "1px solid #707070",
      color: "#707070",
      "&:hover": {
         backgroundColor: "#F5F6F6",
         border: "1px solid #707070",
         color: "#707070",
      },
   },
   previewDisabled: {
      backgroundColor: "#F5F6F6 !important",
      border: "1px solid rgba(0, 0, 0, 0.26) !important",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      display: "flex",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
}));
