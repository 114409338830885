import React, {
   useContext,
   useRef,
   useEffect,
   Dispatch,
   useCallback,
} from "react";
import { IAction } from "../common/storebase.types";
import { ICreateListingState } from "./create-listing.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useApi } from "../api/api.context";
import jwtDecode from "jwt-decode";

export enum CreateListingActions {
   setIsNew = "setIsNew",
   setCanActivate = "setCanActivate",
   setLoading = "setLoading",
   setFeature = "setFeature",
   setFeaturePhotoError = "setFeaturePhotoError",
   setPhotos = "setPhotos",
   setAddPhotosError = "setAddPhotosError",
   setAdvancedNotice = "setAdvancedNotice",
   setAvailabilityStatus = "setAvailabilityStatus",
   setNickName = "setnickName",
   setPropertyId = "setPropertyId",
   setPreview = "setPreview",
   setLockImage = "setLockImage",
   setDoorImage = "setDoorImage",
   setLockImagePath = "setLockImagePath",
   setDoorImagePath = "setDoorImagePath",
   setFeaturePhotoPath = "setFeaturePhotoPath",
   setPhotoPaths = "setPhotoPaths",
   setPropertyScheduleState = "setPropertyScheduleState",
   reset = "reset",
}

const initialState = {
   isNew: true,
   canActivate: false,
   loading: false,
   photos: [],
   featurePhoto: {
      file: "",
   },
   featurePhotoError: false,
   advancedNotice: "1",
   availabilityStatus: {
      unavailableDates: [],
      weekDays: [],
      timeInterval: [],
   },
   nickName: "Property Nickname",
   propertyId: null,
   lockImage: null,
   doorImage: null,
   lockImagePath: null,
   doorImagePath: null,
   featurePhotoPath: null,
   photoPaths: null,
   propertyScheduleState: [],
   previewObject: {
      nickName: "Property Nickname",
      propertyCreateTypeId: "single-family",
      price: "",
      address: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      propertyTypeId: "rental",
      lotSize: "",
      sqFoot: "",
      bathrooms: "",
      bedrooms: "",
      parkingTypeId: "dedicated-garage",
      parkingSpace: "",
      propertyHeadline: "",
      generalInformation: "",
      utilities: "",
      petPolicyId: "allowed",
      petPolicyDescription: "",
      zlink: "",
      accessInstruction: "",
      exitInstruction: "",
      postTourInstruction: "",
      propertyId: "",
   },
};

const reducer = (
   state: ICreateListingState,
   action: IAction
): ICreateListingState => {
   let newState = { ...state };
   switch (action.type) {
      case CreateListingActions.setIsNew:
         newState.isNew = action.payload;
         break;
      case CreateListingActions.setLoading:
         newState.loading = action.payload;
         break;
      case CreateListingActions.setPhotos:
         newState.photos = action.payload;
         break;
      case CreateListingActions.setFeature:
         newState.featurePhoto = action.payload;
         break;
      case CreateListingActions.setFeaturePhotoError:
         newState.featurePhotoError = action.payload;
         break;
      case CreateListingActions.reset:
         newState = initialState;
         break;
      case CreateListingActions.setPropertyScheduleState:
         newState.propertyScheduleState = action.payload;
         break;
      case CreateListingActions.setAdvancedNotice:
         newState.advancedNotice = action.payload;
         break;
      case CreateListingActions.setAvailabilityStatus:
         newState.availabilityStatus = action.payload;
         break;
      case CreateListingActions.setNickName:
         newState.nickName = action.payload;
         break;
      case CreateListingActions.setPropertyId:
         newState.propertyId = action.payload;
         break;
      case CreateListingActions.setPreview:
         newState.previewObject = action.payload;
         break;
      case CreateListingActions.setDoorImage:
         newState.doorImage = action.payload;
         break;
      case CreateListingActions.setLockImage:
         newState.lockImage = action.payload;
         break;
      case CreateListingActions.setLockImagePath:
         newState.lockImagePath = action.payload;
         break;
      case CreateListingActions.setDoorImagePath:
         newState.doorImagePath = action.payload;
         break;
      case CreateListingActions.setFeaturePhotoPath:
         newState.featurePhotoPath = action.payload;
         break;
      case CreateListingActions.setPhotoPaths:
         newState.photoPaths = action.payload;
         break;
      case CreateListingActions.setCanActivate:
         newState.canActivate = action.payload;
         break;
      default:
         return state;
   }

   return newState;
};

export const CreateListingStateContext: any = React.createContext({});
export const CreateListingDispatchContext: any = React.createContext({});
export const CreateListingStateRefContext: any = React.createContext({});

export const useCreateListingState = (): ICreateListingState =>
   useContext(CreateListingStateContext);
export const useCreateListingDispatch = (): Dispatch<IAction> =>
   useContext(CreateListingDispatchContext);
export const useCreateListingStateRef =
   (): React.MutableRefObject<ICreateListingState> =>
      useContext(CreateListingStateRefContext);

const middleware = [logger("User")];

export const CreateListingsProvider: React.FC<{
   children: React.ReactNode;
}> = ({ children }) => {
   const [state, dispatch, createStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );

   return (
      <CreateListingStateContext.Provider value={state}>
         <CreateListingDispatchContext.Provider value={dispatch}>
            <CreateListingStateRefContext.Provider value={createStateRef}>
               {children}
            </CreateListingStateRefContext.Provider>
         </CreateListingDispatchContext.Provider>
      </CreateListingStateContext.Provider>
   );
};
