import React from 'react'
import CreateListingBody from '../../../components/body/create.listing.body'
import { ListingsCreatedTop } from '../../../components/listings/single/listing.created.top'
import { Grid } from '@mui/material'
import { ListingsCreateMiddleContainer } from '../../../components/listings/create/listings.middle.container'
import { ListingsCreateRightContainer } from '../../../components/listings/create/listings.right.container'
import { CreatedMiddleFormSwitch } from '../../../components/listings/single/listing.created.middle.form.swtich'
import { CreatedPhotosTab } from '../../../components/listings/single/secondary-tabs/created.photos.tab'
import { BottomSubmitComponent } from '../../../components/listings/create/bottom.submit.component'
import { useFormContext } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSingleListingState } from '../../../contexts/single-listing/singleListing.context'

export const SingleListingMain: React.FC<{}> = ({ }) => {
    

    

    

    return (
        <CreateListingBody>
            <CreatedMiddleFormSwitch />
        </CreateListingBody>
    )
}