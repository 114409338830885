import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as BoxltyLogoFull } from "../../../styles/assets/logos/logo-on-black.svg";
import { LoginForm } from "../../../components/login/login.form";
import { useUserState } from "../../../contexts/user/user.context";
import { getStoredItem } from "../../../contexts/common/localstorage";
import { StorageKeys } from "../../../contexts/common/storeapp.types";
import { useNavigate } from "react-router-dom";
export const LoginMain: React.FC<{}> = ({}) => {
   const [backdropOpen, setBackdropOpen] = useState(false);
   const theme = useTheme();
   const { classes } = useStyles();
   const { accessToken, refreshToken, user } = useUserState();
   const accessTokenStorage = getStoredItem(StorageKeys.access_token);
   const refreshTokenStorage = getStoredItem(StorageKeys.refresh_token);
   const navigate = useNavigate();
   useEffect(() => {
      if (user?.isRenter === 1) {
         navigate("/welcomeinstashow?status=buy");
      }
      // else if (accessToken || accessTokenStorage) {
      //   navigate("/dashboard");
      // }
   }, [accessToken, accessTokenStorage]);

   return (
      <div className={classes.mainContainer}>
         <Backdrop
            open={backdropOpen}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
         >
            <CircularProgress color="primary" />
         </Backdrop>
         <div className={classes.logoContainer}>
            <Grid item>
               <BoxltyLogoFull
                  className={classes.logoImage}
                  onClick={() =>
                     (window.location.href = "https://instashow.app")
                  }
               />
            </Grid>
         </div>
         <div className={classes.bodyContainer}>
            <div className={classes.loginContainer}>
               <div className={classes.formContainer}>
                  <LoginForm setBackdrop={setBackdropOpen} />
               </div>
            </div>
         </div>
      </div>
   );
};

const useStyles = makeStyles({ name: "Login" })((theme) => ({
   mainContainer: {
      height: "100vh",
      margin: "55px",
      display: "flex",
      flexDirection: "column",
      gap: "35px",
      "@media (max-width: 430px)": {
         margin: "20px",
      },
   },
   logoContainer: {
      display: "flex",
      paddingLeft: "60px",
      alignItems: "bottom",
      "@media (max-width: 550px)": {
         justifyContent: "center",
         alignItems: "center",
         paddingLeft: "0",
      },
   },
   bodyContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      gap: "60px",
      overflowY: "auto",
   },
   itemContainer: {
      display: "flex",
   },
   marketingSpace: {
      backgroundColor: theme.palette.common.medGray,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1.5,
      fontSize: "38px",
   },
   marketingSpaceHeader: {
      fontFamily: theme.fonts.primary.reg,
      fontWeight: "bold",
      color: "white",
   },
   loginContainer: {
      backgroundColor: "white",
      flex: 1,
      borderRadius: "25px",
      height: "90%",
      maxHeight: "675px",
      minHeight: "555px",
      width: "7rem",
      maxWidth: "500px",
      minWidth: "318px",
   },
   formContainer: {
      padding: theme.spacing(3),
      height: "95%",
   },
   logoImage: {
      height: "30px",
      width: "auto",
      cursor: "pointer",
   },
}));
