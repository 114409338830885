import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";

interface AlertsPopupComponentProps {
  id: string;
  alertIndex: number;
  lockName: string;
  hidden?: boolean;
}

let width = "275px";
let height = "200px";

function AlertsPopupComponent(props: AlertsPopupComponentProps) {
  const { classes } = useStyles();

  useEffect(() => {
    setFloatingPosition();
  }, []);

  window.addEventListener("resize", () => {
    setFloatingPosition();
  });

  function setFloatingPosition() {
    try {
      let thisComponent = document.querySelector("#" + props.id) as HTMLElement;
      let alertsComponent = document.querySelector(
        "#alerts-expand-target"
      ) as HTMLElement;
      let thisWidth = Number.parseFloat(width);
      let alertsWidth = Number.parseFloat(
        window.getComputedStyle(alertsComponent).width
      );

      //set right position - alerts component position
      let rect = alertsComponent.getBoundingClientRect();
      thisComponent.style.left =
        rect.right - (alertsWidth / 3 + thisWidth + thisWidth / 2) + "px";

      //align vertically with clicked option
      let alertOptionsContainer: HTMLElement = document.querySelector(
        "section[data-expand-shelf]"
      ) as HTMLElement;
      let alertOptions: NodeListOf<HTMLElement> =
        alertOptionsContainer.querySelectorAll("div");
      let selectedOption = alertOptions[props.alertIndex];

      let optionRect = selectedOption.getBoundingClientRect();
      thisComponent.style.top = optionRect.top + "px";
    } catch (error) {}
  }

  function hideAlertComponent() {
    //console.log('#' + props.id)
    let thisComponent = document.querySelector("#" + props.id) as HTMLElement;
    //console.log(thisComponent);
    thisComponent.style.display = "none";
  }

  function showAlertComponent() {
    setFloatingPosition();
    let thisComponent = document.querySelector("#" + props.id) as HTMLElement;
    thisComponent.style.display = "none";
  }

  /*
    The format of this component is subject to change for different types of notifications
    the only example so far is a request for a tour notification
    */
  return (
    <>
      <section id={props.id} className={classes.alertsPopupComponent}>
        {/* exit button row */}
        <div className={classes.alertPopupComponentExitRow}>
          <span className={classes.ExitX} onClick={hideAlertComponent}>
            &times;
          </span>
        </div>
        {/* content row */}
        <section className={classes.contentRow}>
          <section className={classes.AlertFromRow}>
            <h4 className={classes.LockAlertHeader}>Lock Nickname</h4>
          </section>
          {/* This part could vary depending on the type of alert/notificaiton coming back */}
          {/* Time Information */}
          <section className={classes.BodySection}>
            <p className={classes.BodyText}>
              {
                "We've detected a problem connecting to your lock device. Please check the device to ensure it has sufficeint battery power and is not defective. If the issue persists, contact support for assistance. "
              }
            </p>
          </section>

          {/* buttons */}
          <section className={classes.ButtonsSection}>
            <RoundedButton
              text={"GO TO LOCK"}
              color={"primary"}
              showArrow={false}
              showShadow={true}
              marginLeft={"0px"}
              marginRight={"2px"}
            />
          </section>
        </section>
      </section>
    </>
  );
}

// CSS

const useStyles = makeStyles({ name: "AlertsPopupComponent" })((theme) => ({
  alertsPopupComponent: {
    display: "none",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "15px",
    position: "absolute",
    overflow: "hidden",
    maxHeight: height,
    minHeight: height,
    maxWidth: width,
    minWidth: width,
    padding: "0px",
    right: "0",
    zIndex: 20,
  },
  alertPopupComponentExitRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "96%",
    height: "25px",
  },
  ExitX: {
    cursor: "pointer",
    fontSize: "25px",
    fontWeight: "100",
  },
  contentRow: {
    paddingLeft: "25px",
  },
  AlertFromRow: {
    display: "flex",
    alignItems: "center",
    height: "25px",
  },

  BodySection: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    height: "65px",
    width: "230px",
  },
  LockAlertHeader: {
    fontWeight: "bold",
    margin: "0",
    fontFamily: theme.fonts.primary.bold,
  },
  BodyText: {
    fontSize: "12px",
    fontWeight: "med",
    marginBottom: "1px",
    marginTop: "1px",
    lineHeight: "15px",
  },
  ButtonsSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "30px",
    maxWidth: "200px",
  },
}));

export default AlertsPopupComponent;
