import { DirectionsRailwayFilledOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import RoundedButton from "../common/boxlty.button";

function RegisterRealtorInformationComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    if (props.defaultValue.length !== 0 && props.defaultValue != null) {
      let defaultValid = validateLicense(
        props.defaultValue[0],
        props.defaultValue[1]
      );
      setNextButtonDisabled(!defaultValid);
    }
  }, [props.defaultValue]);

  function setNextToActive(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) {
    let license: string = (
      document.querySelector("#register-license-input") as HTMLInputElement
    ).value;
    let nrds: string = (
      document.querySelector("#register-nrds-input") as HTMLInputElement
    ).value;
    if (license.length > 0 && nrds.length > 0) {
      //set button to active
      setNextButtonDisabled(false);
    } else {
      //set to inactive
      setNextButtonDisabled(true);
    }
  }

  function validateLicense(license: string, nrdsId: string) {
    if (license === "" || license === null || license === undefined) {
      // Return true because the button disabled calls this on load
      return false;
    }

    let isValid = true;
    let licenseInput: HTMLInputElement = document.querySelector(
      "#register-license-input"
    ) as HTMLInputElement;
    let nrdsInput: HTMLInputElement = document.querySelector(
      "#register-nrds-input"
    ) as HTMLInputElement;
    let line: HTMLElement = document.querySelector(
      "#divider-line"
    ) as HTMLInputElement;

    if (license.length === 0) {
      isValid = false;
      let validationMessage = "License Name is required";
      licenseInput.value = "";
      licenseInput.placeholder = validationMessage;
      licenseInput.style.color = "red"; // Apply styling for validation message
      line.style.backgroundColor = "red";
    } else {
      line.style.backgroundColor = theme.palette.common.darkGray;
    }

    if (nrdsId.length === 0) {
      isValid = false;
      let validationMessage = "NRDS ID is required";
      nrdsInput.value = "";
      nrdsInput.placeholder = validationMessage;
      nrdsInput.style.color = "red"; // Apply styling for validation message
      line.style.backgroundColor = "red";
    } else {
      line.style.backgroundColor = theme.palette.common.darkGray;
    }

    return isValid;
  }

  function nextStep() {
    let license: string = (
      document.querySelector("#register-license-input") as HTMLInputElement
    ).value;
    let nrdsId: string = (
      document.querySelector("#register-nrds-input") as HTMLInputElement
    ).value;
    if (validateLicense(license, nrdsId)) {
      //remove any spaces
      if (props.onSubmitRealtorInfo)
        props.onSubmitRealtorInfo([license, nrdsId], props.nextStepOption1);
    }
  }

  const onKeyDownCheck = (event: any) => {
    // Allow backspace (key code 8)
    if (event.keyCode === 8) {
      return;
    }

    // Define a regular expression pattern that allows only positive numbers.
    const validInputPattern = /^\d*$/;

    // Get the input value from the event's key and current input value.
    const inputValue = event.target.value + event.key;

    // Check if the input value matches the valid pattern.
    if (!validInputPattern.test(inputValue)) {
      event.preventDefault();
    }
  };

  return (
    <section className={classes.registerLicensecontainer}>
      <h2 className={classes.registerLicenseHeader}>Realtor® information</h2>

      <div className={classes.outerInputBox}>
        <div className={classes.registerInputBoxContainer}>
          <input
            onChange={(e) => {
              setNextToActive(e);
            }}
            onKeyDown={(e) => {
              onKeyDownCheck(e);
            }}
            maxLength={9}
            className={classes.registerLicenseInput}
            type="text"
            id="register-license-input"
            placeholder="License Number"
            defaultValue={props.defaultValue[0]}
          />
        </div>
        <div id="divider-line" className={classes.registerDividerLine}></div>
      </div>

      <div className={classes.outerInputBox}>
        <div className={classes.registerInputBoxContainer}>
          <input
            onChange={(e) => {
              setNextToActive(e);
            }}
            onKeyDown={(e) => {
              onKeyDownCheck(e);
            }}
            maxLength={9}
            className={classes.registerLicenseInput}
            type="text"
            id="register-nrds-input"
            placeholder="NRDS ID Number"
            defaultValue={props.defaultValue[1]}
          />
        </div>
        <div id="divider-line" className={classes.registerDividerLine}></div>
      </div>

      <div className={classes.registerButtonsContainer}>
        <RoundedButton
          id={"back"}
          listener={() => {
            props.onPreviousStep(props.backStepOption1);
          }}
          boldText={false}
          showShadow={false}
          showArrow={true}
          arrowDirection={"left"}
          color="transparent"
          text="BACK"
        />

        <RoundedButton
          id={"next"}
          disabled={nextButtonDisabled}
          listener={nextStep}
          boldText={false}
          showShadow={true}
          showArrow={true}
          arrowDirection={"right"}
          color="primary"
          text="NEXT"
        />
      </div>
    </section>
  );
}

const useStyles = makeStyles({ name: "RegisterLicenseComponent" })((theme) => ({
  registerLicensecontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    marginTop: "10%",
    "@media (max-height: 660px)": {
      marginTop: "4%",
    },
  },

  registerLicenseHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px !important",
    margin: "0",
    textAlign: "center",
  },
  outerInputBox: {
    height: "45px",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  registerButtonsContainer: {
    marginTop: "145px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerLicenseInput: {
    border: "none",
    outline: "none",
    width: "100%",
    paddingLeft: "5px",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: theme.palette.common.lightGray, // Replace this with your desired color
      fontWeight: "bold",
    },
    fontSize: "16px",
  },
  registerDividerLine: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: "100px",
    marginTop: "3px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

export default RegisterRealtorInformationComponent;
