import React from "react";
import AppBody from "../../../../components/body/main.body.component";
import {
   Grid,
   Tabs,
   Tab,
   Button,
   Avatar,
   Snackbar,
   Alert,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DataTableComponent } from "../../../../components/common/data.table.component";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import {
   GridRowsProp,
   GridColDef,
   GridRowSelectionModel,
   useGridApiRef,
} from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { ListingsPageSearchInput } from "../../../../components/listings/view/listings.search.input";
import { useGradient } from "../../../../contexts/gradients/gradients.context";
import { getLenderProperties } from "../../../../contexts/api/api.functions";
import { GridRowParams, MuiEvent, GridCallbackDetails } from "@mui/x-data-grid";
import { useUserState } from "../../../../contexts/user/user.context";
import { format } from "date-fns";
import {
   useSingleListingState,
   useSingleListingDispatch,
   SingleListingActions,
} from "../../../../contexts/single-listing/singleListing.context";

type Props = {};

export const LenderListingsViewingMain: React.FC<{}> = (props: Props) => {
   const theme = useTheme();
   const { user } = useUserState();
   const { primaryButtonsGradient } = useGradient();
   const { classes } = useStyles();
   const { ListingType } = useParams<{ ListingType: string }>();
   const { redirectedBack } = useSingleListingState();
   const dispatch = useSingleListingDispatch();
   const [typeValue, setTypeValue] = useState(ListingType);
   const [selected, setSelected] = useState<GridRowSelectionModel>([]);
   const [allListings, setAllListings] = useState<GridRowsProp>([]);
   const [allListingData, setAllListingData] = useState<any>([]);
   const navigate = useNavigate();
   const apiRef = useGridApiRef();
   const singleListingDispatch = useSingleListingDispatch();
   const [activeCount, setActiveCount] = useState(0);
   const [draftCount, setDraftCount] = useState(0);
   const [archivedCount, setArchivedCount] = useState(0);

   useEffect(() => {
      updateListings();
   }, [user?.userId]);

   useEffect(() => {
      updateListings();
   }, [typeValue]);

   const updateListings = () => {
      async function fetchListingData() {
         switch (typeValue) {
            case "active":
               return await getLenderProperties(1000, 1);
            case "drafts":
               return await getLenderProperties(1000, 2);
            case "archived":
               return await getLenderProperties(1000, 3);
            default:
               break;
         }
      }
      fetchListingData()
         .then((response: any) => {
            if (response && response.data && response.data.status === true) {
               let listingsToAdd = response.data.result.data;
               setListingCount(response.data.result);
               setAllListingData(response.data.result.data);

               listingsToAdd.forEach(async (element: any) => {
                  element.id = element.propertyId;

                  element.nickName = element.propertyName;
                  element.listing = {
                     nickName: element.propertyName,
                     address:
                        element.propertyAddress +
                        " " +
                        element.propertyCity +
                        " " +
                        element.propertyState +
                        " " +
                        element.propertyZipCode,
                     propertyImage: element.propertyThumbnail,
                  };
                  element.created = {
                     createdAt: element.createdDate,
                     createdFirstName: element.createFirstName,
                     createdLastName: element.createLastName,
                  };
                  element.updated = {
                     createdAt: element.createdDate,
                     createdFirstName: element.createFirstName,
                     createdLastName: element.createLastName,
                  };
                  element.price = formatter.format(element.propertySalePrice);
                  element.partner = {
                     partnerFirstName: element.partnerFirstName,
                     partnerLastName: element.partnerLastName,
                  };
               });
               return listingsToAdd;
            }
         })
         .then((data) => {
            setAllListings(data);
         });
   };

   const setListingCount = (data: any) => {
      setActiveCount(data.totalActiveCount);
      setDraftCount(data.totalDraftCount);
      setArchivedCount(data.totalDeactivatedCount);
   };

   const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
   });

   /////Define Columns based on model returned by API
   const gridColumns: GridColDef[] = [
      {
         field: "listing",
         headerName: "Listing",
         width: 450,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               flexWrap={"nowrap"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid item>
                  <Avatar
                     className={classes.imgPreview}
                     variant="rounded"
                     src={`https://boxlty-media.s3.amazonaws.com/property-images/${params.value.propertyImage}`}
                  />
               </Grid>
               <Grid item display={"flex"} flexDirection={"column"}>
                  <span className={classes.listingsCellHeader}>
                     {params.value.nickName}
                  </span>
                  <span className={classes.listingsCellAddress}>
                     {params.value.address}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value.nickName}, ${value.address}`,
      },
      { field: "price", headerName: "Price", width: 125 },
      {
         field: "partner",
         headerName: "Partner",
         width: 225,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               flexWrap={"nowrap"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid item display={"flex"} flexDirection={"column"}>
                  <span style={{ fontSize: "18px" }}>
                     {params.value.partnerFirstName}{" "}
                     {params.value.partnerLastName}
                  </span>
               </Grid>
            </Grid>
         ),
      },
      {
         field: "teamMembers",
         headerName: "Team",
         width: 200,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               flexWrap={"nowrap"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid
                  item
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
               >
                  {params.value
                     ? params.value.map((teamMember: any, index: number) => {
                          if (index === 0 || index === 1) {
                             return (
                                <Avatar
                                   src={`https://boxlty-media.s3.amazonaws.com/user-profile/${teamMember.userProfile}`}
                                   alt={`Team Member ${index + 1}`}
                                   className={classes.avatarTeam}
                                />
                             );
                          } else if (index === params.value.length - 1) {
                             return (
                                <Avatar className={classes.avatarTeam}>
                                   +{index - 1}
                                </Avatar>
                             );
                          }
                       })
                     : null}
               </Grid>
            </Grid>
         ),
      },
      {
         field: "created",
         headerName: "Created",
         width: 185,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               flexWrap={"nowrap"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid item display={"flex"} flexDirection={"column"}>
                  <span className={classes.listingsCellHeader}>
                     {params.value.createdFirstName}{" "}
                     {params.value.createdLastName}
                  </span>
                  <span className={classes.listingsCellAddress}>
                     {format(new Date(`${params.value.createdAt}`), "P")}
                  </span>
               </Grid>
            </Grid>
         ),
      },
      {
         field: "updated",
         headerName: "Updated",
         width: 185,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               flexWrap={"nowrap"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid item display={"flex"} flexDirection={"column"}>
                  <span className={classes.listingsCellHeader}>
                     {params.value.createdFirstName}{" "}
                     {params.value.createdLastName}
                  </span>
                  <span className={classes.listingsCellAddress}>
                     {format(new Date(`${params.value.createdAt}`), "P")}
                  </span>
               </Grid>
            </Grid>
         ),
      },
   ];

   const dataHandleRowClick = (
      params: GridRowParams,
      event: MuiEvent,
      details: GridCallbackDetails
   ) => {
      singleListingDispatch({
         type: SingleListingActions.setPropertyId,
         payload: params.id,
      });
      singleListingDispatch({
         type: SingleListingActions.setFavoriteId,
         payload: params.row.favoriteId,
      });
      navigate(`/listings/${params.id}/details`);
   };

   return (
      <>
         <AppBody>
            <Grid
               container
               sx={{ height: "98%", width: "100%" }}
               className={classes.mainContainer}
               direction="column"
            >
               <Snackbar
                  open={redirectedBack}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  autoHideDuration={6000}
                  onClose={() => {
                     dispatch({
                        type: SingleListingActions.setRedirectedBack,
                        payload: false,
                     });
                  }}
               >
                  <Alert
                     severity="error"
                     onClose={() => {
                        dispatch({
                           type: SingleListingActions.setRedirectedBack,
                           payload: false,
                        });
                     }}
                  >
                     Please select a property from one of the available options
                     in the table.
                  </Alert>
               </Snackbar>
               <Grid item>
                  <Grid
                     container
                     direction={"row"}
                     display="flex"
                     alignItems={"center"}
                     justifyContent="space-between"
                  >
                     <Grid item>
                        <span className={classes.header}>Listings</span>
                     </Grid>
                     <Grid item sx={{ width: "20%" }}>
                        <ListingsPageSearchInput items={allListingData} />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item sx={{ height: "80%", width: "100%" }}>
                  <Grid
                     container
                     direction={"column"}
                     display="flex"
                     height={"100%"}
                     flexWrap={"nowrap"}
                  >
                     <Grid item sx={{ paddingBottom: "5px" }}>
                        <Grid
                           container
                           direction={"row"}
                           display={"flex"}
                           justifyContent="space-between"
                        >
                           <Grid item sx={{ marginLeft: "15px" }}>
                              <Tabs
                                 value={ListingType}
                                 classes={{
                                    root: classes.tabsRoot,
                                    flexContainer: classes.tabsFlex,
                                    indicator: classes.tabsIndicator,
                                 }}
                              >
                                 <Tab
                                    label={"Active(" + activeCount + ")"}
                                    disableFocusRipple
                                    disableRipple
                                    value="active"
                                    onClick={() => {
                                       setTypeValue("active");
                                       navigate("/listings/active");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 <Tab
                                    label={"Draft(" + draftCount + ")"}
                                    disableFocusRipple
                                    disableRipple
                                    value="drafts"
                                    onClick={() => {
                                       setTypeValue("drafts");
                                       navigate("/listings/drafts");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 <Tab
                                    label={"Archived(" + archivedCount + ")"}
                                    disableFocusRipple
                                    disableRipple
                                    value="archived"
                                    onClick={() => {
                                       setTypeValue("archived");
                                       navigate("/listings/archived");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                              </Tabs>
                           </Grid>
                           <Grid item width="300px">
                              <Grid
                                 container
                                 direction="row"
                                 display={"flex"}
                                 sx={{
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    height: "100%",
                                    gap: "5px",
                                 }}
                              >
                                 <Grid item className={classes.buttonContainer}>
                                    <Button
                                       variant={
                                          selected && selected.length > 0
                                             ? "contained"
                                             : "outlined"
                                       }
                                       disabled={
                                          selected && selected.length > 0
                                             ? false
                                             : true
                                       }
                                       size="small"
                                       onClick={() => {
                                          apiRef.current.exportDataAsCsv();
                                       }}
                                       classes={{ endIcon: classes.startICon }}
                                       sx={{
                                          borderRadius: "26px",
                                          backgroundImage:
                                             selected.length > 0
                                                ? `${primaryButtonsGradient}`
                                                : theme.palette.common.white,
                                          paddingTop: "6px",
                                       }}
                                    >
                                       EXPORT
                                    </Button>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item display={"flex"} sx={{ height: "600px" }}>
                        <DataTableComponent
                           apiRef={apiRef}
                           onRowClick={dataHandleRowClick}
                           items={allListings}
                           selected={selected}
                           setSelected={setSelected}
                           columns={gridColumns}
                        />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "ListingsMain" })((theme) => ({
   mainContainer: {
      display: "flex",
      flexWrap: "nowrap",
      // overflow: "auto",
   },
   firstRow: {},
   secondRow: {},
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "38px",
      letterSpacing: ".25px",
      fontWeight: "bold",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      fontWeight: "bold",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: "bold",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   startICon: {
      paddingBottom: "2px",
      justifySelf: "center",
   },

   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
   },

   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "18px",
      letterSpacing: "0.45px",
   },
   imgPreview: {
      marginTop: "3px",
   },
   priceLine: {
      color: "black",
      fontSize: "18px",
   },
   primaryManagerLine: {
      color: "black",
      fontSize: "18px",
   },
   listingsCellAddress: {
      color: "#707070",
      fontSize: "16px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "18px",
      letterSpacing: "0.45px",
   },
   price: {
      overflow: "auto",
   },
   createdDate: {
      color: "#707070",
      fontSize: "16px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "18px",
      letterSpacing: "0.45px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "16px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   avatarTeam: {
      margin: "3px",
   },
}));
