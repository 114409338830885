import React from "react";

import { Grid, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import { CreatePropertyGeneral } from "../../../contexts/create-listing/create-listing.types";

// id?: string
// nickName?: string;
// address?: string;
// city?: string;
// state?: string;
// zipCode?: string;
// zLink?: string;
// accessType?: string;
// type?: string;
// accessNote?: string;
// exitNote?: string;
// images?: string[];
// price?: string;
// size?: string;
// notes?: string;
// description?: string;
// bedrooms?: number;
// bathrooms?: number;
// carParkings?: number;
// swimmingPools?: number

type PreviewListingProps = {
  listing: CreatePropertyGeneral;
};

export const PreviewListingDetails: React.FC<PreviewListingProps> = ({
  listing,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        direction={"column"}
        display="flex"
        gap={"7px"}
        className={classes.mainContainer}
      >
        <Grid item>
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            ${listing.price !== "" ? `${listing.price}` : null}
          </span>
          {listing.propertyTypeId !== "" &&
          listing.propertyTypeId === "rental" ? (
            <span style={{ fontWeight: "bold", fontSize: "20px" }}> / </span>
          ) : null}
          {listing.propertyTypeId !== "" &&
          listing.propertyTypeId === "rental" ? (
            <span style={{ fontWeight: "bold", fontSize: "20px" }}> month</span>
          ) : null}
        </Grid>
        <Grid item>
          <span className={classes.detailsText}>
            {listing.address !== "" ? listing.address : null}{" "}
          </span>
        </Grid>
        {listing.addressLine2 !== "" && (
          <Grid item>
            <span className={classes.detailsText}>
              {listing.addressLine2 !== "" ? listing.addressLine2 : null}
            </span>
          </Grid>
        )}
        <Grid item>
          <span
            className={classes.detailsText}
          >{`${listing.city}, ${listing.state} ${listing.zipCode}`}</span>
        </Grid>
        <Grid item sx={{ marginTop: "10px" }}>
          <span className={classes.detailsText}>
            {`${listing.bedrooms?.toString()} Bedrooms`} &#x2022;{" "}
            {`${listing.bathrooms?.toString()} Baths`} &#x2022;{" "}
            {`${listing.sqFoot} sq. ft.`}
          </span>
        </Grid>
        <Grid item>
          <span
            className={classes.detailsText}
          >{`${listing.parkingSpace} ${listing.parkingTypeId}`}</span>
        </Grid>
        <Grid item sx={{ marginBottom: "15px" }}>
          <span className={classes.detailsText}>
            {listing.propertyId !== ""
              ? `Boxlty ID: ${listing.propertyId}`
              : null}
          </span>
        </Grid>
        <Grid item>
          <Divider
            sx={{ borderBottomWidth: "2px", color: "#A7AAAC", width: "90%" }}
          />
        </Grid>
        <Grid
          item
          sx={{ paddingTop: "15px", paddingBottom: "15px", width: "90%" }}
        >
          <span
            className={classes.descriptionText}
          >{`${listing.propertyHeadline}`}</span>
        </Grid>
        <Grid item>
          <Divider
            sx={{ borderBottomWidth: "2px", color: "#A7AAAC", width: "90%" }}
          />
        </Grid>
        <Grid item sx={{ marginTop: "10px" }}>
          <span className={classes.descriptionText}>{`Property Details`}</span>
        </Grid>
        <Grid item sx={{ width: "90%" }}>
          <span
            className={classes.generalInformation}
          >{`${listing.generalInformation}`}</span>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "preview-listing-details" })((theme) => ({
  mainContainer: {
    paddingLeft: "15px",
    paddingTop: "5px",
    paddingBottom: "15px",
  },
  detailsText: {
    fontSize: "17px",
  },
  descriptionText: {
    fontFamily: theme.fonts.secondary.bold,
    fontSize: "20px",
    fontWeight: "bold",
    letterSpacing: "0.55px",
  },
  generalInformation: {
    lineHeight: 1.35,
    fontSize: "17px",
  },
}));
