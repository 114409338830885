import React from "react";
import { useUserState } from "../../../contexts/user/user.context";
import { ListingsViewingMain } from "./non-enterprise/listings.viewing.main";
import { ListingsViewingMainAdminPrimaryManager } from "./non-lender/listings.viewing.admin.pm.main";
import { LenderListingsViewingMain } from "./lender/lender.listing.viewing.main";

export const ListingsViewMainSwitch: React.FC<{}> = () => {
   const { user } = useUserState();

   //If Super admin show admin components, else default to non-enterprise view
   if (user) {
      switch (user?.accountType) {
         case 5:
            return <ListingsViewingMainAdminPrimaryManager />;

         case 6:
            if (user?.isEnterpriseSuperUser === 1) {
               return <LenderListingsViewingMain />;
            }
            return <ListingsViewingMain />;

         default:
            return <ListingsViewingMain />;
      }
   } else {
      return <></>;
   }
};
