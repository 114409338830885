import { useState, useCallback, useRef, Dispatch, MutableRefObject } from 'react'
export type StateDispatch<A = any, S = any> = (action: A) => S
export type MiddlewareLogic<A = any, R = any> = (next: StateDispatch<A, R>) => StateDispatch<A, R>
export type Middleware<S = any, A = any, D = any, R = any> = (state: Readonly<S>, dispatch: StateDispatch<A, R>, dependencies?: Readonly<D>) => MiddlewareLogic<A, R>

export type PropGetter<S = any, P = any> = (state: Readonly<S>) => P
export type Watcher<S = any, A = any> = (state: Readonly<S>, dispatch: StateDispatch<A>) => () => void

export function useEnhancedReducer<S = any, A = any, D = any>(
  reducer: (state: S, action: A) => S,
  initialState: S,
  initializer?: (state: S) => S,
  middleware?: Middleware[],
  dependencies?: D
): [S, Dispatch<A>, MutableRefObject<S>] {
  const [state, setState] = useState<S>(initializer ? initializer(initialState) : initialState)
  const ref = useRef<S>(state)
  ref.current = state
  const depRef = useRef<D>()
  depRef.current = dependencies
  const queue = useRef<A[]>([])

  const dispatch: Dispatch<A> = useCallback(
    (a: A) => {
      const runDispatch = (action: A): void => {
        const s = ref.current

        const chain = middleware?.map((m) => m(s as Readonly<S>, dispatch, depRef.current as Readonly<D>))

        let mw: MiddlewareLogic<A> | undefined
        if (chain && chain.length === 1) {
          mw = chain[0]
        } else if (chain && chain.length > 1) {
          mw = chain?.reduce((a, b) => (...args) => a(b(...args)))
        }
        const finalDispatch = (action: A): S => {
          const newState = reducer(ref.current, action)
          ref.current = newState
          setState(newState)
          return newState
        }
        let d = finalDispatch
        if (mw) {
          d = mw(finalDispatch)
        }
        d(action)
        queue.current = queue.current.filter((q) => q !== action)
        if (queue.current.length) {
          runDispatch(queue.current[0])
        }
      }

      queue.current.push(a)
      if (queue.current.length === 1) {
        runDispatch(a)
      }
    },
    [setState, middleware, reducer, ref, depRef]
  )

  return [state, dispatch, ref]
}
