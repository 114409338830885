import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { IToursState } from "./tours.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useUserState } from "../user/user.context";
import {
   getUserToursOwner,
   getNonLenderEntepriseTours,
   getLenderTours,
} from "../api/api.functions";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
import { useParams } from "react-router-dom";
import { ToursFilter1, ToursFilter2 } from "../api/api.types";
import { Tour } from "./tours.context.types";

export enum ToursActions {
   setTours = "setTours",
   setToursAll = "setToursAll",
   setError = "setError",
   setLoading = "setLoading",
   upcomingCount = "upcomingCount",
   todayCount = "todayCount",
   tomorrowCount = "tomorrowCount",
   completedCount = "completedCount",
   allCount = "allCount",
   setToursDetailsPropertyId = "setToursDetailsPropertyId",
   setTourDetailsCompanyName = "setTourDetailsCompanyName",
   setEnterpriseNonLenderTourData = "setEnterpriseNonLenderToursData",
   setEnterpriseNonLenderCounts = "setEnterpriseNonLenderCounts",
}

const initialState = {
   tours: [],
   toursAll: [],
   loading: false,
   error: "",
   upcomingCount: 0,
   todayCount: 0,
   tomorrowCount: 0,
   completedCount: 0,
   allCount: 0,
   tourDetailsPropertyId: null,
   tourDetailsCompanyName: null,
   enterpriseNonLenderToursData: {
      tours: [],
      toursAll: [],
      tourDetailsPropertyId: null,
      tourDetailsCompanyName: null,
   },
   enterpriseNonLenderCounts: {
      upcomingCount: 0,
      todayCount: 0,
      tomorrowCount: 0,
      completedCount: 0,
      allCount: 0,
   },
};

const reducer = (state: IToursState, action: IAction): IToursState => {
   const newState = { ...state };
   switch (action.type) {
      case ToursActions.setTours:
         newState.tours = action.payload;
         break;
      case ToursActions.setError:
         newState.error = action.payload;
         break;
      case ToursActions.setLoading:
         newState.loading = action.payload;
         break;
      case ToursActions.setToursAll:
         newState.toursAll = action.payload;
         break;
      case ToursActions.upcomingCount:
         newState.upcomingCount = action.payload;
         break;
      case ToursActions.todayCount:
         newState.todayCount = action.payload;
         break;
      case ToursActions.tomorrowCount:
         newState.tomorrowCount = action.payload;
         break;
      case ToursActions.completedCount:
         newState.completedCount = action.payload;
         break;
      case ToursActions.allCount:
         newState.allCount = action.payload;
         break;
      case ToursActions.setToursDetailsPropertyId:
         newState.tourDetailsPropertyId = action.payload;
         break;
      case ToursActions.setTourDetailsCompanyName:
         newState.tourDetailsCompanyName = action.payload;
         break;
      case ToursActions.setEnterpriseNonLenderTourData:
         newState.enterpriseNonLenderToursData = action.payload;
         break;
      case ToursActions.setEnterpriseNonLenderCounts:
         newState.enterpriseNonLenderCounts = action.payload;
         break;
      default:
         return state;
   }

   return newState;
};

export const ToursStateContext: any = React.createContext({});
export const ToursDispatchContext: any = React.createContext({});
export const ToursStateRefContext: any = React.createContext({});

export const useToursState = (): IToursState => useContext(ToursStateContext);
export const useToursDispatch = (): Dispatch<IAction> =>
   useContext(ToursDispatchContext);
export const useToursStateRef = (): React.MutableRefObject<IToursState> =>
   useContext(ToursStateRefContext);

const middleware = [logger("User")];

export const ToursProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, toursStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );
   const { TourType } = useParams<{ TourType: string }>();
   const { user } = useUserState();

   const getTourCounts = async () => {
      let upcoming: number = 0;
      let today: number = 0;
      let tommorrow: number = 0;
      let completed: number = 0;
      let all: number = 0;
      const dataAll1 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.UPCOMING,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll2 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.TODAY,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll3 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.TOMMORROW,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll4 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.PAST60DAYS,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const res1 = await getToursSwitch(dataAll1);
      if (res1 && res1.data && res1.data.status === true) {
         if (res1.data.result.totalCount) {
            upcoming = returnUniqueTours(res1.data.result.data).length;
         } else {
            upcoming = 0;
         }
      }
      const res2 = await getToursSwitch(dataAll2);
      if (res2 && res2.data && res2.data.status === true) {
         if (res2.data.result.totalCount) {
            today = returnUniqueTours(res2.data.result.data).length;
         } else {
            today = 0;
         }
      }
      const res3 = await getToursSwitch(dataAll3);
      if (res3 && res3.data && res3.data.status === true) {
         if (res3.data.result.totalCount) {
            tommorrow = returnUniqueTours(res3.data.result.data).length;
         } else {
            tommorrow = 0;
         }
      }
      const res4 = await getToursSwitch(dataAll4);
      if (res4 && res4.data && res4.data.status === true) {
         if (res4.data.result.totalCount) {
            completed = returnUniqueTours(res4.data.result.data).length;
         } else {
            completed = 0;
         }
      }
      let toursAll: Tour[] = [];
      toursAll = [
         ...(res1.data.result.data ?? []),
         ...(res2.data.result.data ?? []),
         ...(res3.data.result.data ?? []),
         ...(res4.data.result.data ?? []),
      ];
      const uniqueBookingIds = new Set<number>();
      const filteredBookings = toursAll.filter((booking) => {
         if (!uniqueBookingIds.has(booking.bookingId)) {
            uniqueBookingIds.add(booking.bookingId);
            return true;
         }
         return false;
      });
      all = filteredBookings.length;
      dispatch({ type: ToursActions.upcomingCount, payload: upcoming });
      dispatch({ type: ToursActions.todayCount, payload: today });
      dispatch({ type: ToursActions.tomorrowCount, payload: tommorrow });
      dispatch({ type: ToursActions.completedCount, payload: completed });
      dispatch({ type: ToursActions.allCount, payload: all });
   };

   const returnUniqueTours = (toursArray: Tour[]) => {
      const uniqueBookingIds = new Set<number>();
      const filteredBookings = toursArray.filter((booking) => {
         if (!uniqueBookingIds.has(booking.bookingId)) {
            uniqueBookingIds.add(booking.bookingId);
            return true;
         }
         return false;
      });

      return filteredBookings;
   };

   const getUserTours = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         switch (TourType) {
            case "upcoming":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataUpcoming = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.UPCOMING,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res1 = await getToursSwitch(dataUpcoming);
               if (res1 && res1.data && res1.data.status === true) {
                  if (res1.data.message === "Data Not Found") {
                     dispatch({ type: ToursActions.setTours, payload: [] });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res1.data.result.data)
                     dispatch({
                        type: ToursActions.setTours,
                        payload: res1.data.result.data,
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "today":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataToday = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TODAY,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res2 = await getToursSwitch(dataToday);

               if (res2 && res2.data && res2.data.status === true) {
                  if (res2.data.message === "Data Not Found") {
                     dispatch({ type: ToursActions.setTours, payload: [] });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  } else {
                     // console.log(res2.data.result)
                     dispatch({
                        type: ToursActions.setTours,
                        payload: res2.data.result.data,
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "tomorrow":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataTomorrow = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TOMMORROW,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res3 = await getToursSwitch(dataTomorrow);

               if (res3 && res3.data && res3.data.status === true) {
                  if (res3.data.message === "Data Not Found") {
                     dispatch({ type: ToursActions.setTours, payload: [] });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res3.data.result.data)
                     dispatch({
                        type: ToursActions.setTours,
                        payload: res3.data.result.data,
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "completed":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataCompleted = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.PAST60DAYS,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res4 = await getToursSwitch(dataCompleted);

               if (res4 && res4.data && res4.data.status === true) {
                  if (res4.data.message === "Data Not Found") {
                     dispatch({ type: ToursActions.setTours, payload: [] });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res4.data.result.data)
                     dispatch({
                        type: ToursActions.setTours,
                        payload: res4.data.result.data,
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "all":
               let toursAll: Tour[] = [];

               let toursUpcoming: Tour[] = [];
               let toursToday: Tour[] = [];
               let toursTomorrow: Tour[] = [];
               let toursCompleted: Tour[] = [];
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataAll1 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.UPCOMING,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll2 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TODAY,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll3 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TOMMORROW,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll4 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.PAST60DAYS,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };

               const res5 = await getToursSwitch(dataAll1);
               if (res5 && res5.data && res5.data.status === true) {
                  if (res5.data.message === "Data Not Found") {
                     toursUpcoming = [];
                  } else {
                     toursUpcoming = res5.data.result.data;
                  }
               }
               const res6 = await getToursSwitch(dataAll2);
               if (res6 && res6.data && res6.data.status === true) {
                  if (res6.data.message === "Data Not Found") {
                     toursToday = [];
                  } else {
                     toursToday = res6.data.result.data;
                  }
               }
               const res7 = await getToursSwitch(dataAll3);
               if (res7 && res7.data && res7.data.status === true) {
                  if (res7.data.message === "Data Not Found") {
                     toursTomorrow = [];
                  } else {
                     toursTomorrow = res7.data.result.data;
                  }
               }
               const res8 = await getToursSwitch(dataAll4);
               if (res8 && res8.data && res8.data.status === true) {
                  if (res8.data.message === "Data Not Found") {
                     toursCompleted = [];
                  } else {
                     toursCompleted = res8.data.result.data;
                  }
               }
               toursAll = [
                  ...toursUpcoming,
                  ...toursToday,
                  ...toursTomorrow,
                  ...toursCompleted,
               ];
               const uniqueBookingIds = new Set<number>();
               const filteredBookings = toursAll.filter((booking) => {
                  if (!uniqueBookingIds.has(booking.bookingId)) {
                     uniqueBookingIds.add(booking.bookingId);
                     return true;
                  }
                  return false;
               });
               // console.log("tours all ^^^^")
               dispatch({
                  type: ToursActions.setTours,
                  payload: [...filteredBookings],
               });
               dispatch({
                  type: ToursActions.setLoading,
                  payload: false,
               });
               break;
            default:
            // console.log("Not correct filters")
         }
      }
   };

   const getTourCountsNonLenderAdminOrPM = async () => {
      let upcoming: number = 0;
      let today: number = 0;
      let tommorrow: number = 0;
      let completed: number = 0;
      let all: number = 0;
      const dataAll1 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.UPCOMING,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll2 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.TODAY,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll3 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.TOMMORROW,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll4 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.PAST60DAYS,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const res1 = await getNonLenderEntepriseTours(dataAll1);
      if (res1 && res1.data && res1.data.status === true) {
         if (res1.data.result.data) {
            upcoming = returnUniqueTours(res1.data.result.data).length;
         } else {
            upcoming = 0;
         }
      }
      const res2 = await getNonLenderEntepriseTours(dataAll2);
      if (res2 && res2.data && res2.data.status === true) {
         if (res2.data.result.data) {
            today = returnUniqueTours(res2.data.result.data).length;
         } else {
            today = 0;
         }
      }
      const res3 = await getNonLenderEntepriseTours(dataAll3);
      if (res3 && res3.data && res3.data.status === true) {
         if (res3.data.result.data) {
            tommorrow = returnUniqueTours(res3.data.result.data).length;
         } else {
            tommorrow = 0;
         }
      }
      const res4 = await getNonLenderEntepriseTours(dataAll4);
      if (res4 && res4.data && res4.data.status === true) {
         if (res4.data.result.data) {
            completed = returnUniqueTours(res4.data.result.data).length;
         } else {
            completed = 0;
         }
      }
      let toursAll: Tour[] = [];
      toursAll = [
         ...(res1.data.result.data ?? []),
         ...(res2.data.result.data ?? []),
         ...(res3.data.result.data ?? []),
         ...(res4.data.result.data ?? []),
      ];
      const uniqueBookingIds = new Set<number>();
      const filteredBookings = toursAll.filter((booking) => {
         if (!uniqueBookingIds.has(booking.bookingId)) {
            uniqueBookingIds.add(booking.bookingId);
            return true;
         }
         return false;
      });
      all = filteredBookings.length;
      dispatch({
         type: ToursActions.setEnterpriseNonLenderCounts,
         payload: {
            upcomingCount: upcoming,
            todayCount: today,
            tomorrowCount: tommorrow,
            completedCount: completed,
            allCount: all,
         },
      });
   };

   const getTourCountsNonLenderManager = async () => {
      let upcoming: number = 0;
      let today: number = 0;
      let tommorrow: number = 0;
      let completed: number = 0;
      let all: number = 0;
      const dataAll1 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.UPCOMING,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll2 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.TODAY,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll3 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.TOMMORROW,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const dataAll4 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.PAST60DAYS,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const res1 = await getUserToursOwner(dataAll1);
      if (res1 && res1.data && res1.data.status === true) {
         if (res1.data.result.data) {
            upcoming = returnUniqueTours(res1.data.result.data).length;
         } else {
            upcoming = 0;
         }
      }
      const res2 = await getUserToursOwner(dataAll2);
      if (res2 && res2.data && res2.data.status === true) {
         if (res2.data.result.data) {
            today = returnUniqueTours(res2.data.result.data).length;
         } else {
            today = 0;
         }
      }
      const res3 = await getUserToursOwner(dataAll3);
      if (res3 && res3.data && res3.data.status === true) {
         if (res3.data.result.data) {
            tommorrow = returnUniqueTours(res3.data.result.data).length;
         } else {
            tommorrow = 0;
         }
      }
      const res4 = await getUserToursOwner(dataAll4);
      if (res4 && res4.data && res4.data.status === true) {
         if (res4.data.result.data) {
            completed = returnUniqueTours(res4.data.result.data).length;
         } else {
            completed = 0;
         }
      }
      let toursAll: Tour[] = [];
      toursAll = [
         ...(res1.data.result.data ?? []),
         ...(res2.data.result.data ?? []),
         ...(res3.data.result.data ?? []),
         ...(res4.data.result.data ?? []),
      ];
      const uniqueBookingIds = new Set<number>();
      const filteredBookings = toursAll.filter((booking) => {
         if (!uniqueBookingIds.has(booking.bookingId)) {
            uniqueBookingIds.add(booking.bookingId);
            return true;
         }
         return false;
      });
      all = filteredBookings.length;
      dispatch({
         type: ToursActions.setEnterpriseNonLenderCounts,
         payload: {
            upcomingCount: upcoming,
            todayCount: today,
            tomorrowCount: tommorrow,
            completedCount: completed,
            allCount: all,
         },
      });
   };

   const getNonLenderEnterpriseToursManager = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         switch (TourType) {
            case "upcoming":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataUpcoming = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.UPCOMING,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res1 = await getUserToursOwner(dataUpcoming);
               if (res1 && res1.data && res1.data.status === true) {
                  if (res1.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res1.data.result.data)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res1.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "today":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataToday = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TODAY,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res2 = await getUserToursOwner(dataToday);

               if (res2 && res2.data && res2.data.status === true) {
                  if (res2.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  } else {
                     // console.log(res2.data.result)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res2.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "tomorrow":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataTomorrow = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TOMMORROW,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res3 = await getUserToursOwner(dataTomorrow);

               if (res3 && res3.data && res3.data.status === true) {
                  if (res3.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res3.data.result.data)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res3.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "completed":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataCompleted = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.PAST60DAYS,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res4 = await getUserToursOwner(dataCompleted);

               if (res4 && res4.data && res4.data.status === true) {
                  if (res4.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res4.data.result.data)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res4.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "all":
               let toursAll: Tour[] = [];

               let toursUpcoming: Tour[] = [];
               let toursToday: Tour[] = [];
               let toursTomorrow: Tour[] = [];
               let toursCompleted: Tour[] = [];
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataAll1 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.UPCOMING,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll2 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TODAY,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll3 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TOMMORROW,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll4 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.PAST60DAYS,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };

               const res5 = await getUserToursOwner(dataAll1);
               if (res5 && res5.data && res5.data.status === true) {
                  if (res5.data.message === "Data Not Found") {
                     toursUpcoming = [];
                  } else {
                     toursUpcoming = res5.data.result.data;
                  }
               }
               const res6 = await getUserToursOwner(dataAll2);
               if (res6 && res6.data && res6.data.status === true) {
                  if (res6.data.message === "Data Not Found") {
                     toursToday = [];
                  } else {
                     toursToday = res6.data.result.data;
                  }
               }
               const res7 = await getUserToursOwner(dataAll3);
               if (res7 && res7.data && res7.data.status === true) {
                  if (res7.data.message === "Data Not Found") {
                     toursTomorrow = [];
                  } else {
                     toursTomorrow = res7.data.result.data;
                  }
               }
               const res8 = await getUserToursOwner(dataAll4);
               if (res8 && res8.data && res8.data.status === true) {
                  if (res8.data.message === "Data Not Found") {
                     toursCompleted = [];
                  } else {
                     toursCompleted = res8.data.result.data;
                  }
               }
               toursAll = [
                  ...toursUpcoming,
                  ...toursToday,
                  ...toursTomorrow,
                  ...toursCompleted,
               ];
               const uniqueBookingIds = new Set<number>();
               const filteredBookings = toursAll.filter((booking) => {
                  if (!uniqueBookingIds.has(booking.bookingId)) {
                     uniqueBookingIds.add(booking.bookingId);
                     return true;
                  }
                  return false;
               });
               // console.log("tours all ^^^^")
               dispatch({
                  type: ToursActions.setEnterpriseNonLenderTourData,
                  payload: {
                     ...state.enterpriseNonLenderToursData,
                     tours: [...filteredBookings],
                  },
               });
               dispatch({
                  type: ToursActions.setLoading,
                  payload: false,
               });
               break;
            default:
            // console.log("Not correct filters")
         }
      }
   };

   const getNonLenderEnterpriseToursAdminOrPm = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         switch (TourType) {
            case "upcoming":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataUpcoming = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.UPCOMING,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res1 = await getNonLenderEntepriseTours(dataUpcoming);
               if (res1 && res1.data && res1.data.status === true) {
                  if (res1.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res1.data.result.data)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res1.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "today":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataToday = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TODAY,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res2 = await getNonLenderEntepriseTours(dataToday);

               if (res2 && res2.data && res2.data.status === true) {
                  if (res2.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  } else {
                     // console.log(res2.data.result)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res2.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "tomorrow":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataTomorrow = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TOMMORROW,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res3 = await getNonLenderEntepriseTours(dataTomorrow);

               if (res3 && res3.data && res3.data.status === true) {
                  if (res3.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res3.data.result.data)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res3.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "completed":
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataCompleted = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.PAST60DAYS,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const res4 = await getNonLenderEntepriseTours(dataCompleted);

               if (res4 && res4.data && res4.data.status === true) {
                  if (res4.data.message === "Data Not Found") {
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: [],
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                     return;
                  } else {
                     // console.log(res4.data.result.data)
                     dispatch({
                        type: ToursActions.setEnterpriseNonLenderTourData,
                        payload: {
                           ...state.enterpriseNonLenderToursData,
                           tours: res4.data.result.data,
                        },
                     });
                     dispatch({
                        type: ToursActions.setLoading,
                        payload: false,
                     });
                  }
               }
               break;
            case "all":
               let toursAll: Tour[] = [];

               let toursUpcoming: Tour[] = [];
               let toursToday: Tour[] = [];
               let toursTomorrow: Tour[] = [];
               let toursCompleted: Tour[] = [];
               dispatch({
                  type: ToursActions.setLoading,
                  payload: true,
               });
               const dataAll1 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.UPCOMING,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll2 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TODAY,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll3 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.TOMMORROW,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };
               const dataAll4 = {
                  filter1: ToursFilter1.BYDATE,
                  filter2: ToursFilter2.PAST60DAYS,
                  searchText: "",
                  itemsPerPage: 500,
                  page: 1,
               };

               const res5 = await getNonLenderEntepriseTours(dataAll1);
               if (res5 && res5.data && res5.data.status === true) {
                  if (res5.data.message === "Data Not Found") {
                     toursUpcoming = [];
                  } else {
                     toursUpcoming = res5.data.result.data;
                  }
               }
               const res6 = await getNonLenderEntepriseTours(dataAll2);
               if (res6 && res6.data && res6.data.status === true) {
                  if (res6.data.message === "Data Not Found") {
                     toursToday = [];
                  } else {
                     toursToday = res6.data.result.data;
                  }
               }
               const res7 = await getNonLenderEntepriseTours(dataAll3);
               if (res7 && res7.data && res7.data.status === true) {
                  if (res7.data.message === "Data Not Found") {
                     toursTomorrow = [];
                  } else {
                     toursTomorrow = res7.data.result.data;
                  }
               }
               const res8 = await getNonLenderEntepriseTours(dataAll4);
               if (res8 && res8.data && res8.data.status === true) {
                  if (res8.data.message === "Data Not Found") {
                     toursCompleted = [];
                  } else {
                     toursCompleted = res8.data.result.data;
                  }
               }
               toursAll = [
                  ...toursUpcoming,
                  ...toursToday,
                  ...toursTomorrow,
                  ...toursCompleted,
               ];
               const uniqueBookingIds = new Set<number>();
               const filteredBookings = toursAll.filter((booking) => {
                  if (!uniqueBookingIds.has(booking.bookingId)) {
                     uniqueBookingIds.add(booking.bookingId);
                     return true;
                  }
                  return false;
               });
               // console.log("tours all ^^^^")
               dispatch({
                  type: ToursActions.setEnterpriseNonLenderTourData,
                  payload: {
                     ...state.enterpriseNonLenderToursData,
                     tours: [...filteredBookings],
                  },
               });
               dispatch({
                  type: ToursActions.setLoading,
                  payload: false,
               });
               break;
            default:
            // console.log("Not correct filters")
         }
      }
   };

   const getToursSwitch = (data: any) => {
      if (user?.accountType == 6 && user?.isEnterpriseSuperUser) {
         return getLenderTours(data);
      } else {
         return getUserToursOwner(data);
      }
   };

   useEffect(() => {
      if (user) {
         if (user.accountType === 5) {
            if (user.isEnterpriseSuperUser === 1 || user.isPrimaryOwner === 1) {
               getTourCountsNonLenderAdminOrPM();
            } else {
               getTourCountsNonLenderManager();
            }
         } else {
            getTourCounts();
         }
      }
   }, [user, user?.userId]);

   useEffect(() => {
      if (user) {
         if (user.accountType === 5) {
            if (user.isEnterpriseSuperUser === 1 || user.isPrimaryOwner === 1) {
               getNonLenderEnterpriseToursAdminOrPm();
            } else {
               getNonLenderEnterpriseToursManager();
            }
         } else {
            getUserTours();
         }
      }
   }, [user, user?.userId, TourType]);

   return (
      <ToursStateContext.Provider value={state}>
         <ToursDispatchContext.Provider value={dispatch}>
            <ToursStateRefContext.Provider value={toursStateRef}>
               {children}
            </ToursStateRefContext.Provider>
         </ToursDispatchContext.Provider>
      </ToursStateContext.Provider>
   );
};
