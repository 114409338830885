import { createTheme } from '@mui/material/styles';
import '../fonts/font.css'

export const mainTheme = createTheme({
    palette: {
        common: {
            primary: '#D63F94',
            secondary: '#000000',
            lightGray: '#A2A2A2',
            medGray: '#707070',
            darkGray: '#3C3C3C',
        },
        background: {
            default: '#F5F6F6',
        },
        primary: {
            main: "#D63F94"
        },
        error: {
            main: "#ff0000"
        },
        secondary: {
            main: "#000000"
        },
        alert: {
            main: "#EC394B"
        },
        inProgress: {
            main: "#64E29A"
        },
        pending: {
            main: ""
        },
        scheduled: {
            main: ""
        },
        label: {
            main: "#707070"
        },
        

    },
    typography: {
        fontFamily: `'Roboto'`
    },
    fonts: {
        primary: {
            reg: 'Roboto',
            med: 'Roboto, Medium',
            bold: 'Roboto, Bold'
        },
        secondary: {
            reg: 'Roboto',
            med: 'Roboto, Medium',
            bold: 'Roboto, Bold'
        }
    },
    breakpoints: {

    },
    components: {
        MuiButton: {
            defaultProps: {
                sx: {
                    borderRadius: '25px'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#707070',
                    
                    
                },
                
            
            }
        }
    }
})