// AccountSettingsAccount.jsx
import React, { useEffect, useRef, useState } from "react";
import AppBody from "../../components/body/main.body.component.jsx";
import { Alert, Grid, Snackbar, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import testAvatarImage from "../../styles/assets/images/test-avatar.jpeg";
import { FileUpload } from "@mui/icons-material";
import lockIcon from "../../styles/assets/logos/lock.png";
import RoundedButton from "../../components/common/boxlty.button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import { useGradient } from "../../contexts/gradients/gradients.context";
import { UpdatePasswordReq } from "../../contexts/api/api.types";
import { updatePassword } from "../../contexts/api/api.functions";
import { useUserState } from "../../contexts/user/user.context";

const PasswordTab = () => {
  const { classes } = useStyles();
  const { user } = useUserState();
  const theme = useTheme();
  const [resetButtonDisabled, setResetButtonDisabled] = useState(true);
  const [currentPasswordInputValue, setCurrentPasswordInputValue] =
    useState("");
  const [newPasswordInputValue, setNewPasswordInputValue] = useState("");
  const [retypePasswordInputValue, setRetypePasswordInputValue] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReTypePassword, setShowReTypePassword] = useState(false);
  const { primaryButtonsGradient } = useGradient();
  const [validationMessage, setValidationMessage] = useState("");
  const [showSnackbar, setShowSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [snackBarSuccess, setSnackBarSuccess] = useState<boolean>(true);

  useEffect(() => {}, [showSnackbar, snackBarText]);

  function setNextToActive(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) {
    let currentPasswordInput: HTMLInputElement = document.querySelector(
      "#current-password-input"
    ) as HTMLInputElement;
    let newPasswordInput: HTMLInputElement = document.querySelector(
      "#new-password-input"
    ) as HTMLInputElement;
    let confirmPasswordInput: HTMLInputElement = document.querySelector(
      "#new-password-input-confirm"
    ) as HTMLInputElement;

    if ((event.target as HTMLInputElement).value.length >= 8) {
      //set button to active

      if (
        currentPasswordInput.value.length >= 8 &&
        newPasswordInput.value.length >= 8 &&
        confirmPasswordInput.value.length >= 8
      ) {
        setResetButtonDisabled(false);
      }
    } else {
      //set to inactive
      setResetButtonDisabled(true);
    }
  }

  async function ResetPassword() {
    // First, validate the password fields
    if (validatePasswordFields()) {
      const currentPwd = currentPasswordInputValue;
      const newPwd = newPasswordInputValue;
      const userId = user?.userId.toString() ?? "";
      // Create a data object with the old and new passwords
      const data: UpdatePasswordReq = {
        currentPwd,
        newPwd,
      };

      try {
        // Call the updatePassword API function
        const response = await updatePassword(data);
        //console.log(response.data);

        // Check the response and handle success or error accordingly
        if (response && response.data.status === true) {
          // Password update was successful
          // console.log("Password updated successfully");
          setShowSnackBar(true);
          setSnackBarSuccess(true);
          setSnackBarText(response.data.message);
        } else {
          // Password update failed, handle the error
          console.error("Password update failed: ", response.data);
          setShowSnackBar(true);
          setSnackBarSuccess(false);
          setSnackBarText("Password update failed: " + response.data.message);
        }
      } catch (error) {
        // Handle any network or other errors
        console.error("An error occurred while updating the password:", error);
      }
    }
  }

  function validatePasswordFields() {
    let isValid: boolean = true;
    let currentpasswordInput: HTMLInputElement = document.querySelector(
      "#current-password-input"
    ) as HTMLInputElement;
    let newPasswordInput: HTMLInputElement = document.querySelector(
      "#new-password-input"
    ) as HTMLInputElement;
    let confirmPasswordInput: HTMLInputElement = document.querySelector(
      "#new-password-input-confirm"
    ) as HTMLInputElement;
    let line1: HTMLElement = document.querySelector(
      "#divider-line-1"
    ) as HTMLInputElement;
    let line2: HTMLElement = document.querySelector(
      "#divider-line-2"
    ) as HTMLInputElement;
    let line3: HTMLElement = document.querySelector(
      "#divider-line-3"
    ) as HTMLInputElement;

    // Define the criteria for the password
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*]/;

    setValidationMessage("");
    currentpasswordInput.classList.remove(classes.invalidInput);
    newPasswordInput.classList.remove(classes.invalidInput);
    confirmPasswordInput.classList.remove(classes.invalidInput);
    line1.style.backgroundColor = theme.palette.common.darkGray;
    line2.style.backgroundColor = theme.palette.common.darkGray;
    line3.style.backgroundColor = theme.palette.common.darkGray;

    // Validate the password length
    if (newPasswordInput.value.length < minLength) {
      isValid = false;
      currentpasswordInput.value = "";
      newPasswordInput.value = "";
      confirmPasswordInput.value = "";
      setValidationMessage("Must be 8+ characters long");

      line1.style.backgroundColor = "red";
      line2.style.backgroundColor = "red";
      line3.style.backgroundColor = "red";
      return isValid;
    }

    // Validate the presence of an uppercase letter
    if (!uppercaseRegex.test(newPasswordInput.value)) {
      isValid = false;
      currentpasswordInput.value = "";
      newPasswordInput.value = "";
      confirmPasswordInput.value = "";
      setValidationMessage("Must contain one uppercase letter");
      currentpasswordInput.classList.add(classes.invalidInput);
      newPasswordInput.classList.add(classes.invalidInput);
      confirmPasswordInput.classList.add(classes.invalidInput);
      line1.style.backgroundColor = "red";
      line2.style.backgroundColor = "red";
      line3.style.backgroundColor = "red";
      return isValid;
    }

    // Validate the presence of a lowercase letter
    if (!lowercaseRegex.test(newPasswordInput.value)) {
      isValid = false;
      currentpasswordInput.value = "";
      newPasswordInput.value = "";
      confirmPasswordInput.value = "";
      setValidationMessage("Must contain one lowercase letter");
      line1.style.backgroundColor = "red";
      line2.style.backgroundColor = "red";
      line3.style.backgroundColor = "red";
      return isValid;
    }

    // Validate the presence of a special character
    if (!specialCharRegex.test(newPasswordInput.value)) {
      isValid = false;
      currentpasswordInput.value = "";
      newPasswordInput.value = "";
      confirmPasswordInput.value = "";
      setValidationMessage("Must contain one special character");
      currentpasswordInput.classList.add(classes.invalidInput);
      newPasswordInput.classList.add(classes.invalidInput);
      confirmPasswordInput.classList.add(classes.invalidInput);
      line1.style.backgroundColor = "red";
      line2.style.backgroundColor = "red";
      line3.style.backgroundColor = "red";
      return isValid;
    }

    // Validate the password and confirm password match
    if (newPasswordInput.value !== confirmPasswordInput.value) {
      isValid = false;
      //currentpasswordInput.value = "";
      newPasswordInput.value = "";
      confirmPasswordInput.value = "";
      setValidationMessage("Passwords do not match");
      //currentpasswordInput.classList.add(classes.invalidInput);
      newPasswordInput.classList.add(classes.invalidInput);
      confirmPasswordInput.classList.add(classes.invalidInput);
      //line1.style.backgroundColor = "red";
      line2.style.backgroundColor = "red";
      line3.style.backgroundColor = "red";
      return isValid;
    }

    currentpasswordInput.value = "";
    newPasswordInput.value = "";
    confirmPasswordInput.value = "";

    return isValid;
  }

  return (
    <section className={classes.DashboardBlockedSection}>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
          position: "absolute",
        }}
        onClose={() => {
          setShowSnackBar(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            setShowSnackBar(false);
          }}
          severity={snackBarSuccess ? "success" : "error"}
          color={snackBarSuccess ? "success" : "error"}
        >
          {snackBarText}
        </Alert>
      </Snackbar>
      <div className={classes.InnerItemContainer}>
        <div className={classes.InnerItemSideLeft}>
          <div className={classes.InnerItemSideSubSection}>
            <h2 className={classes.InnerItemsSideSubText}>
              Create a new password that is at least 8 characters long with one
              uppercase, one lowercase and one special character.
            </h2>
          </div>
          <div className={classes.InnerItemSideSubSection}>
            <div className={classes.outerInputBox}>
              <div
                className={`${classes.inputHeader} ${
                  currentPasswordInputValue ? classes.showInputHeader : ""
                }`}
              >
                CURRENT PASSWORD
              </div>
              <div className={classes.registerInputBoxContainer}>
                <img
                  className={classes.registerMailIcon}
                  src={lockIcon}
                  alt=""
                />
                <input
                  onChange={(e) => {
                    setNextToActive(e);
                    setCurrentPasswordInputValue(e.target.value); // Update inputValue state
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setCurrentPasswordInputValue(""); // Reset inputValue state if the input is empty onBlur
                    }
                  }}
                  onKeyDown={(e) => {
                    setNextToActive(e);
                  }}
                  className={classes.registerEmailInput}
                  type={showCurrentPassword ? "text" : "password"} // Toggle between text and password type
                  id="current-password-input"
                  placeholder="Current Password"
                  defaultValue=""
                />
                {currentPasswordInputValue &&
                  (showCurrentPassword ? (
                    <VisibilityOffIcon
                      onClick={() => setShowCurrentPassword(false)} // Hide the password
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setShowCurrentPassword(true)} // Show the password
                    />
                  ))}
              </div>
              <div
                id="divider-line-1"
                className={classes.registerDividerLine}
              ></div>
            </div>

            <div className={classes.outerInputBox}>
              <div
                className={`${classes.inputHeader} ${
                  newPasswordInputValue ? classes.showInputHeader : ""
                }`}
              >
                NEW PASSWORD
              </div>
              <div className={classes.registerInputBoxContainer}>
                <img
                  className={classes.registerMailIcon}
                  src={lockIcon}
                  alt=""
                />
                <input
                  onChange={(e) => {
                    setNextToActive(e);
                    setNewPasswordInputValue(e.target.value); // Update inputValue state
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setNewPasswordInputValue(""); // Reset inputValue state if the input is empty onBlur
                    }
                  }}
                  onKeyDown={(e) => {
                    setNextToActive(e);
                  }}
                  className={classes.registerEmailInput}
                  type={showNewPassword ? "text" : "password"} // Toggle between text and password type
                  id="new-password-input"
                  placeholder="New Password"
                  defaultValue=""
                />
                {newPasswordInputValue &&
                  (showNewPassword ? (
                    <VisibilityOffIcon
                      onClick={() => setShowNewPassword(false)} // Hide the password
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setShowNewPassword(true)} // Show the password
                    />
                  ))}
              </div>
              <div
                id="divider-line-2"
                className={classes.registerDividerLine}
              ></div>
            </div>

            <div className={classes.outerInputBox}>
              <div
                className={`${classes.inputHeader} ${
                  retypePasswordInputValue ? classes.showInputHeader : ""
                }`}
              >
                RE-TYPE PASSWORD
              </div>
              <div className={classes.registerInputBoxContainer}>
                <img
                  className={classes.registerMailIcon}
                  src={lockIcon}
                  alt=""
                />
                <input
                  onChange={(e) => {
                    setNextToActive(e);
                    setRetypePasswordInputValue(e.target.value); // Update inputValue state
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setRetypePasswordInputValue(""); // Reset inputValue state if the input is empty onBlur
                    }
                  }}
                  onKeyDown={(e) => {
                    setNextToActive(e);
                  }}
                  className={classes.registerEmailInput}
                  type={showReTypePassword ? "text" : "password"} // Toggle between text and password type
                  id="new-password-input-confirm"
                  placeholder="Re-Type Password"
                  defaultValue=""
                />
                {retypePasswordInputValue &&
                  (showReTypePassword ? (
                    <VisibilityOffIcon
                      onClick={() => setShowReTypePassword(false)} // Hide the password
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setShowReTypePassword(true)} // Show the password
                    />
                  ))}
              </div>
              <div
                id="divider-line-3"
                className={classes.registerDividerLine}
              ></div>
            </div>

            <p className={classes.validationMessage}>{validationMessage}</p>

            <Button
              id={"reset"}
              disabled={resetButtonDisabled}
              //variant="contained"
              size="small"
              onClick={() => {
                ResetPassword();
              }}
              sx={{
                height: "36px",
                width: "75px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "26px",
                border: resetButtonDisabled
                  ? `1px solid ${theme.palette.common.lightGray}`
                  : "",
                backgroundImage: resetButtonDisabled
                  ? "transparent"
                  : `${primaryButtonsGradient}`,
                color: resetButtonDisabled
                  ? theme.palette.common.lightGray
                  : theme.palette.common.white,
              }}
            >
              <p className={classes.UpgradeButtonText}>RESET</p>
            </Button>
          </div>
        </div>
        {/* <div className={classes.InnerItemSideRight}>
          <div className={classes.ProfileImageContainer}>
            <img
              className={classes.ProfileImage}
              src={testAvatarImage}
              alt="Image Here"
              style={{ borderRadius: "15px" }}
            />
          </div>
        </div> */}
      </div>
    </section>
  );
};

const useStyles = makeStyles({ name: "DashboardStyles" })((theme) => ({
  DashboardOuterLayout: {
    display: "flex",
    alignItems: "flex-start",
    //border: '1px dashed orange',
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  ItemOneContainer: {
    //border: '1px dashed red',
    width: "80%",
    height: "100%",
  },
  ItemTwoContainer: {
    marginLeft: "auto",
  },
  DashboardTitle: {
    fontFamily: theme.fonts.secondary.bold,
    fontSize: "40px",
    letterSpacing: "1px",
    fontWeight: "bold",
    margin: 0,
    marginBottom: "20px",
  },
  DashboardBlockedSection: {
    backgroundColor: "white",
    padding: "5px",
    marginTop: "10px",
    marginBottom: "30px",
    borderRadius: "15px",
    height: "100%",
    width: "60%",
    maxWidth: "1200px",
    minWidth: "300px",
    boxShadow: "6px 6px 12px #00000014;",
    position: "relative",
  },
  InnerItemContainer: {
    width: "95%",
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: "20px",
    paddingTop: "15px",
    paddingRight: "20px",
  },
  InnerItemSideLeft: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  InnerItemSideRight: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  InnerItemSideMainHeader: {
    margin: "5px",
    fontFamily: theme.fonts.secondary.bold,
    fontWeight: "bold",
    fontSize: "20px",
  },
  InnerItemSideMainSubheader: {
    margin: "5px",
    fontFamily: theme.fonts.secondary.primary_extralight,
  },
  InnerItemSideSubSection: {
    marginLeft: "5px",
    marginBottom: "25px",
  },
  InnerItemsSideSubHeader: {
    fontSize: "14px",
    color: "#a2a2a2",
    marginBottom: "0px",
  },
  InnerItemsSideSubText: {
    fontSize: "18px",
    marginTop: "2px",
    marginBottom: "2px",
    fontWeight: "normal",
  },
  ProfileImageContainer: {
    marginTop: "5px",
    marginLeft: "auto",
    marginBottom: "5px",
    height: "150px",
    width: "150px",
    overflow: "hidden",
  },
  ProfileImage: {
    height: "150px",
    width: "150px",
    objectFit: "cover",
  },
  PhoneNumListing: {
    marginTop: "-10px",
    color: "#a2a2a2",
  },
  CalendarContainer: {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    transform: "scale(0.9)",
    position: "relative",
    bottom: "15px",
    left: "35px",
  },
  ItemTwoInnerCointainer: {
    paddingLeft: "20px",
    width: "100%",
  },
  ActionButtonsConatiner: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    justifyItems: "space-evenly",
  },
  subHeader: {
    color: "#A2A2A2",
    fontSize: "14px",
    marginBottom: "5px",
  },
  registerEmailcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    height: "100%",
    marginTop: "10%",
    minHeight: "100vh",
  },
  registerEmailHeader: {
    fontWeight: "bold",
    fontFamily: theme.fonts.secondary.bold,
    marginBottom: "75px",
  },
  outerInputBox: {
    height: "70px",
  },
  registerInputBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  registerButtonsContainer: {
    marginTop: "120px",
    display: "flex",
    alignItems: "center",
  },
  registerMailIcon: {
    height: "10px",
    width: "auto",
    marginRight: "5px",
  },
  registerEmailInput: {
    border: "none",
    outline: "none",
    width: "150px",
    height: "20px",
    cursor: "text",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: theme.palette.common.lightGray, // Replace this with your desired color
    },
  },
  registerDividerLine: {
    height: "1px",
    width: "200px",
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: "100px",
  },
  registerButtonSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  invalidInput: {
    "&::placeholder": {
      color: "red", // Replace this with your desired color
    },
  },
  inputHeader: {
    fontSize: "12px",
    color: "#a2a2a2",
    visibility: "hidden",
  },
  showInputHeader: {
    visibility: "visible",
  },
  UpgradeButtonText: {
    margin: "0",
    marginTop: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: " center",
    textAlign: "center",
    height: "100%",
    fontSize: "16px",
    width: "100%",
    fontFamily: theme.fonts.secondary.light,
    letterSpacing: "0.5px",
  },
  validationMessage: {
    color: "red",
    marginBottom: "20px",
    height: "19px",
  },
}));

export default PasswordTab;
