import React from 'react'



const NotFound: React.FC<{ }> = ({ }) => {
  return (
    <div style={{ color: 'white'}}>Not Found</div>
  )
}

export default NotFound