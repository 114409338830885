import React, {
  useContext,
  useRef,
  useEffect,
  Dispatch,
  useCallback,
} from "react";
import { IAction } from "../common/storebase.types";
import { ISingleListingState } from "./singleListing.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { resetRedirectValue } from "./singleListing.functions";

export enum SingleListingActions {
  setCanActivate = "setCanActivate",
  setListing = "setListing",
  setFeature = "setFeature",
  setPhotos = "setPhotos",
  setLoading = "setLoading",
  setRedirectedBack = "setRedirectedBack",
  reset = "reset",
  setCreatedAdvancedNotice = "setCreatedAdvancedNotice",
  setAvailabilityStatus = "setAvailabilityStatus",
  setNickName = "setnickName",
  setPropertyId = "setPropertyId",
  setPreview = "setPreview",
  setLockImage = "setLockImage",
  setDoorImage = "setDoorImage",
  setLockImagePath = "setLockImagePath",
  setDoorImagePath = "setDoorImagePath",
  setFeaturePhotoPath = "setFeaturePhotoPath",
  setPhotoPaths = "setPhotoPaths",
  setListingType = "setListingType",
  setFavoriteId = "setFavoriteId",
}

const initialState = {
  loading: false,
  canActivate: false,
  featurePhoto: { file: "" },
  photos: [],
  listing: null,
  createdAdvancedNotice: "1",
  availabilityStatus: {
    unavailableDates: [],
    weekDays: [],
    timeInterval: [],
  },
  nickName: "Property Nickname",
  propertyId: null,
  lockImage: null,
  doorImage: null,
  lockImagePath: null,
  doorImagePath: null,
  featurePhotoPath: null,
  redirectedBack: false,
  photoPaths: null,
  previewObject: {
    nickName: "Property Nickname",
    propertyCreateTypeId: "single-family",
    price: "",
    address: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    propertyTypeId: "rental",
    lotSize: "",
    sqFoot: "",
    bathrooms: "",
    bedrooms: "",
    parkingTypeId: "dedicated-garage",
    parkingSpace: "",
    propertyHeadline: "",
    generalInformation: "",
    utilities: "",
    petPolicyId: "allowed",
    petPolicyDescription: "",
    zlink: "",
    accessInstruction: "",
    exitInstruction: "",
    postTourInstruction: "",
    propertyId: "",
    favoriteId: 0,
  },
  listingType: "",
  favoriteId: 0,
};

const reducer = (
  state: ISingleListingState,
  action: IAction
): ISingleListingState => {
  let newState = { ...state };
  switch (action.type) {
    case SingleListingActions.setLoading:
      newState.loading = action.payload;
      break;
    case SingleListingActions.setListing:
      newState.listing = action.payload;
      break;
    case SingleListingActions.setFeature:
      newState.featurePhoto = action.payload;
      break;
    case SingleListingActions.setPhotos:
      newState.photos = action.payload;
      break;
    case SingleListingActions.setCreatedAdvancedNotice:
      newState.createdAdvancedNotice = action.payload;
      break;
    case SingleListingActions.setNickName:
      newState.nickName = action.payload;
      break;
    case SingleListingActions.setPropertyId:
      newState.propertyId = action.payload;
      break;
    case SingleListingActions.setPreview:
      newState.previewObject = action.payload;
      break;
    case SingleListingActions.setDoorImage:
      newState.doorImage = action.payload;
      break;
    case SingleListingActions.setLockImage:
      newState.lockImage = action.payload;
      break;
    case SingleListingActions.setLockImagePath:
      newState.lockImagePath = action.payload;
      break;
    case SingleListingActions.setDoorImagePath:
      newState.doorImagePath = action.payload;
      break;
    case SingleListingActions.setFeaturePhotoPath:
      newState.featurePhotoPath = action.payload;
      break;
    case SingleListingActions.setPhotoPaths:
      newState.photoPaths = action.payload;
      break;
    case SingleListingActions.setListingType:
      newState.listingType = action.payload;
      break;
    case SingleListingActions.setRedirectedBack:
      newState.redirectedBack = action.payload;
      break;
    case SingleListingActions.setAvailabilityStatus:
      newState.availabilityStatus = action.payload;
      break;
    case SingleListingActions.setFavoriteId:
      newState.favoriteId = action.payload;
      break;
    case SingleListingActions.setCanActivate:
      newState.canActivate = action.payload;
      break;
    default:
      return state;
  }

  return newState;
};

export const SingleListingStateContext: any = React.createContext({});
export const SingleListingDispatchContext: any = React.createContext({});
export const SingleListingStateRefContext: any = React.createContext({});

export const useSingleListingState = (): ISingleListingState =>
  useContext(SingleListingStateContext);
export const useSingleListingDispatch = (): Dispatch<IAction> =>
  useContext(SingleListingDispatchContext);
export const useSingleListingStateRef =
  (): React.MutableRefObject<ISingleListingState> =>
    useContext(SingleListingStateRefContext);

const middleware = [logger("User")];

export const SingleListingsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch, singleStateRef] = useEnhancedReducer(
    reducer,
    initialState,
    (state) => ({ ...state }),
    middleware
  );

  return (
    <SingleListingStateContext.Provider value={state}>
      <SingleListingDispatchContext.Provider value={dispatch}>
        <SingleListingStateRefContext.Provider value={singleStateRef}>
          {children}
        </SingleListingStateRefContext.Provider>
      </SingleListingDispatchContext.Provider>
    </SingleListingStateContext.Provider>
  );
};
