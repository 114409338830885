import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { ILeadsState } from "./leads.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useUserState } from "../user/user.context";
import { getLenderLeads, getTeamMembers } from "../api/api.functions";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
export enum LeadsActions {
   setLeads = "setLeads",
   setError = "setError",
   setLoading = "setLoading",
   setLeadDetailsId = "setLeadDetailsId",
}
const initialState = {
   leads: [],
   loading: false,
   error: "",
   leadDetailsId: null,
};
const reducer = (state: ILeadsState, action: IAction): ILeadsState => {
   const newState = { ...state };
   switch (action.type) {
      case LeadsActions.setLeads:
         newState.leads = action.payload;
         break;
      case LeadsActions.setError:
         newState.error = action.payload;
         break;
      case LeadsActions.setLoading:
         newState.loading = action.payload;
         break;
      case LeadsActions.setLeadDetailsId:
         newState.leadDetailsId = action.payload;
         break;
      default:
         return state;
   }
   return newState;
};
export const LeadsStateContext: any = React.createContext({});
export const LeadsDispatchContext: any = React.createContext({});
export const LeadsStateRefContext: any = React.createContext({});
export const useLeadsState = (): ILeadsState => useContext(LeadsStateContext);
export const useLeadsDispatch = (): Dispatch<IAction> =>
   useContext(LeadsDispatchContext);
export const useLeadsStateRef = (): React.MutableRefObject<ILeadsState> =>
   useContext(LeadsStateRefContext);
const middleware = [logger("User")];
export const LeadsProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, leadStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );
   const { user } = useUserState();
   const getLeads = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         dispatch({
            type: LeadsActions.setLoading,
            payload: true,
         });
         const res = await getLenderLeads();
         dispatch({
            type: LeadsActions.setLeads,
            payload: res.data.result.members,
         });
         dispatch({
            type: LeadsActions.setLoading,
            payload: false,
         });
      }
   };
   useEffect(() => {
      //console.log(user);
      if (user && user?.accountType === 6 && user?.isEnterpriseSuperUser == 1) {
         getLeads();
      }
   }, [user, user?.userId]);
   useEffect(() => {
      //  getUserTours();
   }, [user, user?.userId]);
   return (
      <LeadsStateContext.Provider value={state}>
         <LeadsDispatchContext.Provider value={dispatch}>
            <LeadsStateRefContext.Provider value={leadStateRef}>
               {children}
            </LeadsStateRefContext.Provider>
         </LeadsDispatchContext.Provider>
      </LeadsStateContext.Provider>
   );
};
