import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import BoxltyCheckbox from "../../../common/boxlty.checkbox";
import { useTheme } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  parseTime,
  convertHours,
  pad,
  calculate_time_slot,
} from "../../../../utils/getHourSlots";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

export const AvailabilityTabTopForm: React.FC<{
  setStart: (value: any) => void;
  setEnd: (value: any) => void;
  start: any;
  end: any;
  setActive?: (value: boolean) => void;
}> = ({ setStart, setEnd, start, end, setActive }) => {
  const { classes } = useStyles();
  const { register, watch, setValue } = useFormContext();
  const theme = useTheme();
  let contextWeekdays = watch("weekDays");
  let contextRecure = watch("isRecure");
  let contextTimeSlots = watch("timeInterval");
  const [everyDay, setEveryDay] = useState(false);
  const [dayState, setDayState] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const setAllTrue = () => {
    setDayState({
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });
    setValue("weekDays", [1, 2, 3, 4, 5, 6, 7]);
    setEveryDay(true);
  };

  const setAllFalse = () => {
    setDayState({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
    setValue("weekDays", []);
    setEveryDay(false);
  };

  const returnWeekDays = (data: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  }) => {
    let daysArr: Array<number> = [];
    if (data.sunday === true) {
      daysArr.push(7);
    }
    if (data.monday === true) {
      daysArr.push(1);
    }
    if (data.tuesday === true) {
      daysArr.push(2);
    }
    if (data.wednesday === true) {
      daysArr.push(3);
    }
    if (data.thursday === true) {
      daysArr.push(4);
    }
    if (data.friday === true) {
      daysArr.push(5);
    }
    if (data.saturday === true) {
      daysArr.push(6);
    }

    return daysArr;
  };

  const returnDayState = (weekDays: number[]) => {
    const newDayState = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    for (var i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === 7) {
        newDayState.sunday = true;
      }
      if (weekDays[i] === 1) {
        newDayState.monday = true;
      }
      if (weekDays[i] === 2) {
        newDayState.tuesday = true;
      }
      if (weekDays[i] === 3) {
        newDayState.wednesday = true;
      }
      if (weekDays[i] === 4) {
        newDayState.thursday = true;
      }
      if (weekDays[i] === 5) {
        newDayState.friday = true;
      }
      if (weekDays[i] === 6) {
        newDayState.saturday = true;
      }
    }
    return newDayState;
  };

  useEffect(() => {
    const newDayState = returnDayState(contextWeekdays);
    setDayState(newDayState);
  }, [contextWeekdays]);

  useEffect(() => {
    if (contextRecure === "3") {
      setEveryDay(true);
    } else if (contextRecure === "0") {
      setEveryDay(false);
    }
  }, [contextRecure]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDayState({
      ...dayState,
      [event.target.name]: event.target.checked,
    });
    const arr = returnWeekDays({
      ...dayState,
      [event.target.name]: event.target.checked,
    });
    setValue("weekDays", [...arr]);
    if (setActive) {
      setActive(true);
    }
  };

  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
    dayState;

  return (
    <>
      <Grid container direction="column" display={"flex"} width={"100%"}>
        <Grid item>
          <div className={classes.formHeader}>Property Availability</div>
        </Grid>
        <Grid item sx={{ marginTop: "10px" }}>
          <div>Choose the days and times that this property is available.</div>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.topContainer}
            display={"flex"}
            justifyContent="space-between"
          >
            <Grid item flex={1} className={classes.checkboxContainer}>
              <Grid
                container
                direction="column"
                display={"flex"}
                sx={{ marginTop: "10px" }}
                gap="10px"
              >
                <Grid item>
                  <Grid
                    container
                    direction={"row"}
                    display="flex"
                    gap={"1px"}
                    sx={{ paddingLeft: "15px", alignItems: "center" }}
                  >
                    <Grid item>
                      <Checkbox
                        value={everyDay}
                        checked={everyDay}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setAllTrue();
                            if (setActive) {
                              setActive(true);
                            }
                          } else if (e.target.checked === false) {
                            setAllFalse();
                            if (setActive) {
                              setActive(true);
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <div style={{ fontWeight: "bold", fontSize: "19px" }}>
                        Every Day
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" display={"flex"}>
                    <Box>
                      <FormControl>
                        <FormGroup sx={{ display: "flex" }} row>
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={monday}
                                onChange={handleChange}
                                name="monday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Mon
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={tuesday}
                                onChange={handleChange}
                                name="tuesday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Tue
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={wednesday}
                                onChange={handleChange}
                                name="wednesday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Wed
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={thursday}
                                onChange={handleChange}
                                name="thursday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Thu
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={friday}
                                onChange={handleChange}
                                name="friday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Fri
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={saturday}
                                onChange={handleChange}
                                name="saturday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Sat
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.labelComponent}
                            control={
                              <Checkbox
                                checked={sunday}
                                onChange={handleChange}
                                name="sunday"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "18px" }}>
                                Sun
                              </Typography>
                            }
                            labelPlacement="bottom"
                          />
                          {/* <FormControlLabel className={classes.labelComponent} control={<Checkbox />} label="Mon" labelPlacement="bottom" /> */}
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flex={0.8} className={classes.timeContainer}>
              <Grid
                container
                direction="column"
                display={"flex"}
                sx={{ marginTop: "15px" }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    display="flex"
                    justifyContent={"space-between"}
                    gap="5px"
                  >
                    <Grid item flex={1}>
                      <div style={{ paddingLeft: "3px", fontWeight: "bold" }}>
                        Access start time
                      </div>
                      <TimePicker
                        minTime={dayjs().set("hour", 8).minute(0).second(0)}
                        maxTime={dayjs().set("hour", 19)}
                        skipDisabled
                        minutesStep={60}
                        sx={{ marginTop: "5px", border: "none !important" }}
                        value={start}
                        onChange={(value) => {
                          console.log(value);
                          setStart(value);
                          if (setActive) {
                            setActive(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item flex={1}>
                      <div style={{ paddingLeft: "3px", fontWeight: "bold" }}>
                        Access end time
                      </div>
                      <TimePicker
                        minTime={dayjs().set("hour", 9).minute(0).second(0)}
                        maxTime={dayjs().set("hour", 20)}
                        skipDisabled
                        minutesStep={60}
                        sx={{ marginTop: "5px" }}
                        value={end}
                        onChange={(value) => {
                          setEnd(value);
                          if (setActive) {
                            setActive(true);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item justifyContent={"center"} alignItems="center">
                  <Grid
                    container
                    direction="row"
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Grid item>
                      <div style={{ fontWeight: "bold" }}>Customize Times</div>
                    </Grid>
                    <Grid item>
                      <IconButton>
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </Grid>
                    <Grid item sx={{ position: "relative", top: "2px" }}>
                      <InfoOutlinedIcon
                        sx={{
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({ name: "Availability-Tab-Top-Form" })(
  (theme) => ({
    mainContainer: {},
    formHeader: {
      fontSize: "20px",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
    },
    inputHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginBottom: "7px",
      textTransform: "uppercase",
    },
    filledInput: {
      background: "#EAEAEA",
    },
    labelComponent: {
      marginLeft: 0,
      marginRight: 0,
    },
    checkboxLabel: {
      marginTop: "5px",
    },
    topContainer: {
      flexDirection: "row",
      "@media screen and (max-width: 1420px)": {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    checkboxContainer: {},
    timeContainer: {},
  })
);
