import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { IPartnersState } from "./partners.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useUserState } from "../user/user.context";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";
import { getLenderPartners } from "../api/api.functions";
import { useParams } from "react-router-dom";

export enum PartnersActions {
   setPartners = "setPartners",
   setError = "setError",
   setLoading = "setLoading",
   setPartnerDetailsId = "setPartnerDetailsId",
   setActiveCount = "setActiveCount",
   setArchivedCount = "setArchivedCount",
}

const initialState = {
   partners: [],
   loading: false,
   error: "",
   partnerDetailsId: null,
   activeCount: 0,
   archivedCount: 0,
};

const reducer = (state: IPartnersState, action: IAction): IPartnersState => {
   const newState = { ...state };
   switch (action.type) {
      case PartnersActions.setPartners:
         newState.partners = action.payload;
         break;
      case PartnersActions.setError:
         newState.error = action.payload;
         break;
      case PartnersActions.setLoading:
         newState.loading = action.payload;
         break;
      case PartnersActions.setPartnerDetailsId:
         newState.partnerDetailsId = action.payload;
         break;
      case PartnersActions.setActiveCount:
         newState.activeCount = action.payload;
         break;
      case PartnersActions.setArchivedCount:
         newState.archivedCount = action.payload;
         break;
      default:
         return state;
   }

   return newState;
};

export const PartnersStateContext: any = React.createContext({});
export const PartnersDispatchContext: any = React.createContext({});
export const PartnersStateRefContext: any = React.createContext({});

export const usePartnersState = (): IPartnersState =>
   useContext(PartnersStateContext);
export const usePartnersDispatch = (): Dispatch<IAction> =>
   useContext(PartnersDispatchContext);
export const usePartnersStateRef = (): React.MutableRefObject<IPartnersState> =>
   useContext(PartnersStateRefContext);

const middleware = [logger("User")];

export const PartnersProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, partnerStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );
   const { user } = useUserState();
   const { PartnerType } = useParams<{ PartnerType: string }>();

   const getPartners = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         dispatch({
            type: PartnersActions.setLoading,
            payload: true,
         });
         const res = await getLenderPartners();
         // console.log(res.data.message);
         if (res.data.message && res.data.message !== "No record found") {
            const allPartners = res.data.result.members;
            const activePartners = res.data.result.members.filter(
               (partner: any) => partner.isArchived === "0"
            );
            const archivedPartners = res.data.result.members.filter(
               (partner: any) => partner.isArchived === "1"
            );
            if (PartnerType === "active") {
               dispatch({
                  type: PartnersActions.setPartners,
                  payload: activePartners,
               });
            } else if (PartnerType === "archived") {
               dispatch({
                  type: PartnersActions.setPartners,
                  payload: archivedPartners,
               });
            } else {
               dispatch({
                  type: PartnersActions.setPartners,
                  payload: allPartners,
               });
            }
            dispatch({
               type: PartnersActions.setActiveCount,
               payload: activePartners.length,
            });
            dispatch({
               type: PartnersActions.setArchivedCount,
               payload: archivedPartners.length,
            });
         }
         dispatch({
            type: PartnersActions.setLoading,
            payload: false,
         });
      }
   };

   useEffect(() => {
      //console.log(user);
      if (
         user &&
         user?.accountType === 6 &&
         user?.isEnterpriseSuperUser === 1
      ) {
         getPartners();
      }
   }, [user, user?.userId, PartnerType]);

   useEffect(() => {
      //  getUserTours();
   }, [user, user?.userId]);

   return (
      <PartnersStateContext.Provider value={state}>
         <PartnersDispatchContext.Provider value={dispatch}>
            <PartnersStateRefContext.Provider value={partnerStateRef}>
               {children}
            </PartnersStateRefContext.Provider>
         </PartnersDispatchContext.Provider>
      </PartnersStateContext.Provider>
   );
};
