import React, { SetStateAction } from 'react'
import { useState, useEffect } from 'react'
import { format } from 'date-fns';
import { isSameDay } from 'date-fns';
import { DayClickEventHandler } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';
import { add } from 'date-fns';
import 'react-day-picker/dist/style.css';
import './style-overrides.css';

import { TimeSlot } from '../../listings/create/primary-tabs/listings.availability.tab';
import { ConstructionOutlined } from '@mui/icons-material';


export interface AvailabilityObject {
  date: Date,
  timeSlots: Array<TimeSlot>
}




export const CustomCalendar: React.FC<{ state: AvailabilityObject[], setState: React.Dispatch<SetStateAction<AvailabilityObject[]>>, currentDay: AvailabilityObject, setCurrentDay: React.Dispatch<SetStateAction<AvailabilityObject>>, timeSlots: TimeSlot[], setActive?: (value: boolean) => void }> = ({ state, setState, currentDay, setCurrentDay, timeSlots, setActive}) => {
const defaultMonth = new Date();
const defaultToMonth = add(new Date(), {
  months: 1
})

const customModifierStyle = { backgroundColor: "#EFC22C"}

const handleDayClick: DayClickEventHandler = (day, modifiers) => {
  
  if (modifiers.selected) {
    // console.log(day)
    const selectedDay = state.find((object) => {
      if (isSameDay(object.date, day)) {
        return object;
      }
    });
    
    
    if (selectedDay !== undefined) {
      setCurrentDay(selectedDay)
      if (setActive) {
        setActive(true)
      }
    }
  }
};

  
return (
    <DayPicker mode='multiple' onDayClick={handleDayClick} selected={state.map(day => day.date)} modifiers={{ custom: state.filter(day => day.timeSlots.length !== timeSlots.length).map(day => day.date)}} modifiersStyles={{ custom: customModifierStyle }} fromMonth={defaultMonth} toMonth={defaultToMonth} />
  )
}