import React from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  createPropertyStepFourSchedule,
  generatePreSignedUrl,
  createPropertyStepTwoPhotos,
} from "../../../contexts/api/api.functions";
import {
  CreateListingActions,
  useCreateListingDispatch,
  useCreateListingState,
} from "../../../contexts/create-listing/create-listing.context";
import { Snackbar, Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material";
import { getListing } from "../../../contexts/api/api.functions";
import { S3DirectoryName } from "../../../contexts/api/api.types";
import axios from "axios";
const { v4: uuidv4 } = require("uuid");
export const createListingDefaultAvailabilityValues = {
  noticeTime: 0,
  unavailableDates: [],
  isRecure: "0",
  weekDays: [],
  timeInterval: [],
};

export const ListingCreateFormContextAvilability: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  const { propertyId, featurePhoto, featurePhotoPath, photoPaths, photos } =
    useCreateListingState();
  const [error, setError] = useState<null | string>(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [photoErrorOpen, setPhotoErrorOpen] = useState(false);
  const [propertyIdErrorOpen, setPropertyIdErrorOpen] = useState(false);
  const [timeIntervalErrorOpen, setTimeIntervalErrorOpen] = useState(false);
  const { advancedNotice, availabilityStatus } = useCreateListingState();
  const dispatch = useCreateListingDispatch();
  const schema = yup.object().shape({
    noticeTime: yup.number(),
    unavailableDates: yup.array().of(
      yup.object().shape({
        date: yup.string(),
        slots: yup.array().of(yup.number()),
      })
    ),
    isRecure: yup.string(),
    weekDays: yup.array().of(yup.number()),
    timeInterval: yup.array().of(yup.number()),
  });

  const methods = useForm({
    defaultValues: createListingDefaultAvailabilityValues,
    resolver: yupResolver(schema),
  });

  const fetchAndUpdateValues = async () => {
    if (propertyId) {
      setBackdropOpen(true);
      const res = await getListing(parseInt(propertyId));
      if (res && res.data && res.data.status && res.data.status === true) {
        if (res.data.result[0].screen1[0].propertyId === parseInt(propertyId)) {
          if (
            res.data.result[0].screen4.weekDays &&
            res.data.result[0].screen4.weekDays.length > 0
          ) {
            const value = convertWeekdaysForComponent(
              res.data.result[0].screen4.weekDays
            );
            methods.setValue("weekDays", value);
            dispatch({
              type: CreateListingActions.setAvailabilityStatus,
              payload: { ...availabilityStatus, weekDays: value },
            });
          }

          if (
            res.data.result[0].screen4.timeSlots &&
            res.data.result[0].screen4.timeSlots.length > 0
          ) {
            const value = res.data.result[0].screen4.timeSlots;
            methods.setValue("timeInterval", value);
            dispatch({
              type: CreateListingActions.setAvailabilityStatus,
              payload: { ...availabilityStatus, timeInterval: value },
            });
          }

          if (
            res.data.result[0].screen4.unAvailableDates &&
            res.data.result[0].screen4.unAvailableDates.length > 0
          ) {
            const value = res.data.result[0].screen4.unAvailableDates;
            methods.setValue("unavailableDates", value);
            dispatch({
              type: CreateListingActions.setAvailabilityStatus,
              payload: { ...availabilityStatus, unavailableDates: value },
            });
          }

          if (
            res.data.result[0].screen4.noticeTime &&
            res.data.result[0].screen4.noticeTime !== null
          ) {
            const value = res.data.result[0].screen4.noticeTime;
            dispatch({
              type: CreateListingActions.setAdvancedNotice,
              payload: value.toString(),
            });
          }

          if (
            res.data.result[0].screen4.weekDays.length === 7 &&
            res.data.result[0].screen4.unAvailableDates.length === 0
          ) {
            const value = "3";
            methods.setValue("isRecure", value);
          }

          if (
            res.data.result[0].screen2 &&
            res.data.result[0].screen2.length > 0
          ) {
            if (
              res.data.result[0].screen2[0].propertyFeaturePhoto !== null ||
              ""
            ) {
              const value = res.data.result[0].screen2[0].propertyFeaturePhoto;
              dispatch({
                type: CreateListingActions.setFeaturePhotoPath,
                payload: value,
              });
            }

            if (res.data.result[0].screen2[0].propertyPhoto !== null || "") {
              const paths = [];
              for (var i = 0; i < res.data.result[0].screen2.length; i++) {
                const value = res.data.result[0].screen2[i].propertyPhoto;
                paths.push(value);
              }
              dispatch({
                type: CreateListingActions.setPhotoPaths,
                payload: [...paths],
              });
            }
          }
        }
      }
      setBackdropOpen(false);
    } else {
      return;
    }
  };

  useEffect(() => {
    fetchAndUpdateValues();
  }, [propertyId]);

  const convertWeekdaysForApi = (weekDays: number[]): number[] => {
    let apiDays = [];
    for (var i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === 1) {
        apiDays.push(1);
      }
      if (weekDays[i] === 2) {
        apiDays.push(2);
      }
      if (weekDays[i] === 3) {
        apiDays.push(3);
      }
      if (weekDays[i] === 4) {
        apiDays.push(4);
      }
      if (weekDays[i] === 5) {
        apiDays.push(5);
      }
      if (weekDays[i] === 6) {
        apiDays.push(6);
      }
      if (weekDays[i] === 7) {
        apiDays.push(7);
      }
    }
    return apiDays;
  };

  const convertWeekdaysForComponent = (weekDays: string[]) => {
    let componentDays = [];
    for (var i = 0; i < weekDays.length; i++) {
      if (weekDays[i] === "1") {
        componentDays.push(1);
      }
      if (weekDays[i] === "2") {
        componentDays.push(2);
      }
      if (weekDays[i] === "3") {
        componentDays.push(3);
      }
      if (weekDays[i] === "4") {
        componentDays.push(4);
      }
      if (weekDays[i] === "5") {
        componentDays.push(5);
      }
      if (weekDays[i] === "6") {
        componentDays.push(6);
      }
      if (weekDays[i] === "7") {
        componentDays.push(7);
      }
    }
    return componentDays;
  };

  const handleSubmitFunction = async (data: any) => {
    if (!propertyId) {
      setPropertyIdErrorOpen(true);
    }
    if (photoPaths && photoPaths.length === 0) {
      if (photos.length === 0) {
        setPhotoErrorOpen(true);
        return;
      }
    }
    if (!featurePhotoPath) {
      if (!featurePhoto) {
        setPhotoErrorOpen(true);
        return;
      } else if (featurePhoto && featurePhoto.file === "") {
        setPhotoErrorOpen(true);
        return;
      }
    }
    if (data.timeInterval.length < 1) {
      setTimeIntervalErrorOpen(true);
      return;
    }
    setBackdropOpen(true);
    const body = {
      propertyId: propertyId!,
      stepId: 4,
      noticeTime: parseInt(advancedNotice),
      isForceUpdate: 0,
      unavaiableDates: data.unavailableDates,
      isRecure:
        data.weekDays.length === 7 && data.unavailableDates.length < 1
          ? "3"
          : "0",
      weekDays: convertWeekdaysForApi(data.weekDays),
      timeInterval: data.timeInterval,
    };
    dispatch({
      type: CreateListingActions.setAvailabilityStatus,
      payload: {
        unavailableDates: data.unavailableDates,
        weekDays: data.weekDays,
        timeInterval: data.timeInterval,
      },
    });
    const res = await createPropertyStepFourSchedule(body).catch((error) => {
      setBackdropOpen(false);
      setError("We could not update the property, please try again.");
      setErrorOpen(true);
      return;
    });
    if (res && res.data && res.data.status && res.data.status === true) {
      if (
        featurePhotoPath &&
        photoPaths &&
        photoPaths.length > 0 &&
        photos.length === 0
      ) {
        const bodyPhotos = {
          propertyId: propertyId!.toString(),
          stepId: 2,
          mainImg: featurePhotoPath,
          images: [...photoPaths],
        };
        const resPhoto = await createPropertyStepTwoPhotos(bodyPhotos);
        if (resPhoto && resPhoto.data && resPhoto.data.status === true) {
          setBackdropOpen(false);
          setSuccessOpen(true);
          return;
        } else {
          setBackdropOpen(false);
          setError("We could not update the photos, please try again.");
          setErrorOpen(true);
          return;
        }
      } else if (featurePhoto.file !== "" && photos.length > 0) {
        if (!propertyId) {
          return;
        }
        let featureKey: string = uuidv4();
        let photosKeyArr: Array<string> = [];
        if (featurePhotoPath === null) {
          const featureRequest = await generatePreSignedUrl({
            directory: S3DirectoryName.PROPERTY,
            fileName: featureKey,
          });
          if (
            featureRequest &&
            featureRequest.data &&
            featureRequest.data.status === true
          ) {
            const featureUrl = featureRequest.data.result;
            await axios
              .put(featureUrl, featurePhoto.file)
              .then((response) => {})
              .catch((error) => {
                setBackdropOpen(false);
                setError("We could not update the photos, please try again.");
                setErrorOpen(true);
                return;
              });
          }
        }
        await Promise.all(
          photos.map(async (photo) => {
            let photoKey = uuidv4();

            const res1 = await generatePreSignedUrl({
              directory: S3DirectoryName.PROPERTY,
              fileName: photoKey,
            });
            if (res1 && res1.data && res1.data.status === true) {
              photosKeyArr.push(photoKey);
              const photoUrl = res1.data.result;
              await axios
                .put(photoUrl, photo.file)
                .then(() => {
                  // console.log(`Uploaded file ${photoKey}`)
                })
                .catch((error) => {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
                });
            }
          })
        );

        const body4 = {
          propertyId: propertyId.toString(),
          stepId: 2,
          mainImg: featureKey,
          images: [...photosKeyArr],
        };
        const res4 = await createPropertyStepTwoPhotos(body4);
        if (
          res4 &&
          res4.data &&
          res4.data.status &&
          res4.data.status === true
        ) {
          setBackdropOpen(false);
          setSuccessOpen(true);
          return;
        } else {
          setBackdropOpen(false);
          setError("We could not update the photos, please try again.");
          setErrorOpen(true);
          return;
        }
      } else if (featurePhotoPath && photos.length > 0) {
        if (!propertyId) {
          return;
        }
        setBackdropOpen(true);
        let photosKeyArr: Array<string> = [];
        await Promise.all(
          photos.map(async (photo) => {
            let photoKey = uuidv4();

            const res1 = await generatePreSignedUrl({
              directory: S3DirectoryName.PROPERTY,
              fileName: photoKey,
            });
            if (res1 && res1.data && res1.data.status === true) {
              photosKeyArr.push(photoKey);
              const photoUrl = res1.data.result;
              await axios
                .put(photoUrl, photo.file)
                .then(() => {
                  // console.log(`Uploaded file ${photoKey}`)
                })
                .catch((error) => {
                  setBackdropOpen(false);
                  setError("We could not update the photos, please try again.");
                  setErrorOpen(true);
                  return;
                });
            }
          })
        );
        let body4: any;
        if (photoPaths) {
          body4 = {
            propertyId: propertyId.toString(),
            stepId: 2,
            mainImg: featurePhotoPath,
            images: [...photoPaths, ...photosKeyArr],
          };
        } else {
          body4 = {
            propertyId: propertyId.toString(),
            stepId: 2,
            mainImg: featurePhotoPath,
            images: [...photosKeyArr],
          };
        }
        const res4 = await createPropertyStepTwoPhotos(body4);
        if (
          res4 &&
          res4.data &&
          res4.data.status &&
          res4.data.status === true
        ) {
          setBackdropOpen(false);
          setSuccessOpen(true);
          return;
        } else {
          setBackdropOpen(false);
          setError("We could not update the photos, please try again.");
          setErrorOpen(true);
          return;
        }
      } else if (
        featurePhoto.file !== "" &&
        photoPaths &&
        propertyId !== null
      ) {
        let featureKey: string = uuidv4();
        const featureRequest = await generatePreSignedUrl({
          directory: S3DirectoryName.PROPERTY,
          fileName: featureKey,
        });
        if (
          featureRequest &&
          featureRequest.data &&
          featureRequest.data.status === true
        ) {
          const featureUrl = featureRequest.data.result;
          await axios
            .put(featureUrl, featurePhoto.file)
            .then((response) => {
              // console.log(response)
            })
            .catch((error) => {
              setBackdropOpen(false);
              setError("We could not update the photos, please try again.");
              setErrorOpen(true);
              return;
            });
        }
        const propertyIdValue = propertyId;
        const body5 = {
          propertyId: propertyIdValue,
          stepId: 2,
          mainImg: featureKey,
          images: [...photoPaths],
        };
        const res5 = await createPropertyStepTwoPhotos(body5);
        if (
          res5 &&
          res5.data &&
          res5.data.status &&
          res5.data.status === true
        ) {
          setBackdropOpen(false);
          setSuccessOpen(true);
          return;
        } else {
          setBackdropOpen(false);
          setError("We could not update the photos, please try again.");
          setErrorOpen(true);
          return;
        }
      } else {
        setBackdropOpen(false);
        setSuccessOpen(true);
        return;
      }
    } else {
      if (res && res.data && res.data.status && res.data.status === false) {
        if (res.data.message) {
          setError(res.data.message);
          setBackdropOpen(false);
          setErrorOpen(true);
          return;
        } else {
          setError("We could not update the property, please try again.");
          setBackdropOpen(false);
          setErrorOpen(true);
          return;
        }
      } else {
        setError("We could not update the property, please try again.");
        setBackdropOpen(false);
        setErrorOpen(true);
        return;
      }
    }
  };

  const onSubmit = (data: any) => {
    handleSubmitFunction(data)
      .then(() => {
        setBackdropOpen(false);
      })
      .catch((error) => {
        setError("We could not update the property, please try again.");
        setBackdropOpen(false);
        setErrorOpen(true);
      });
  };

  return (
    <FormProvider {...methods}>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessOpen(false);
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSuccessOpen(false);
          }}
        >
          Availability Tab Saved Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={photoErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPhotoErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPhotoErrorOpen(false);
          }}
        >
          Feature photo and at least one additional photo required.
        </Alert>
      </Snackbar>
      <Snackbar
        open={timeIntervalErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setTimeIntervalErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setTimeIntervalErrorOpen(false);
          }}
        >
          Access start time and end time are not valid
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorOpen(false);
          }}
        >
          {error ? error : "Something went wrong, please try again."}
        </Alert>
      </Snackbar>
      <Snackbar
        open={propertyIdErrorOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setPropertyIdErrorOpen(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setPropertyIdErrorOpen(false);
          }}
        >
          No Property Id, if you already created a property go back to the main
          listings page to edit or create another property.
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <form style={{ width: "100%" }} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};
