import React from "react";
import {
   Grid,
   Button,
   IconButton,
   AlertColor,
   Snackbar,
   Alert,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { FileUpload } from "../../../common/file.upload.component";
import { useState, useEffect } from "react";
import { PreviewImage } from "../../../common/preview.image";
import { HeaderButton } from "../forms/header.button";
import { Divider } from "@mui/material";
import { ReactComponent as BlueToothLock } from "../../../../styles/assets/Icons/bluetooth-lock.svg";
import { ReactComponent as InstructionsButton } from "../../../../styles/assets/Icons/instructions-button.svg";
import AddCameraModal from "../../../dashboard/AddCameraModal";
import { ReactComponent as AddLock } from "../../../../styles/assets/Icons/qa-add-lock.svg";
import AddLockModal from "../../../dashboard/AddLockModal";
import { LockSelectComponent } from "../forms/lock.select.component";
import {
   createPropertyStepFiveAssignLock,
   generatePreSignedUrl,
   getUserLockList,
} from "../../../../contexts/api/api.functions";
import { useFormContext } from "react-hook-form";
import { Lock } from "../forms/forms.types";
import AddIcon from "@mui/icons-material/Add";
import { BoxltyButtonV2 } from "../../../common/boxlty.button.v2";
import { useTheme } from "@mui/material";
import { ManageModal } from "../forms/manage.in-app.modal";
import {
   useCreateListingState,
   useCreateListingDispatch,
   CreateListingActions,
} from "../../../../contexts/create-listing/create-listing.context";
import {
   CreatePropertyRequest5AssignLock,
   S3DirectoryName,
} from "../../../../contexts/api/api.types";
import axios from "axios";
import { useUserState } from "../../../../contexts/user/user.context";
import { ReactComponent as InstructionsIcon } from "../../../../styles/assets/Icons/instructions-icon.svg";
import { ListingLockInstructionsComponent } from "../forms/listing.lock.instructions.component";
const { v4: uuidv4 } = require("uuid");
type Props = {};

export const enum AccessChoice {
   INSTRUCTIONS,
   LOCK,
}

export const ListingsHardwareTab: React.FC<{}> = (props: Props) => {
   const { classes } = useStyles();
   const [lockLocationFile, setLockLocationFile] = useState<File | null>(null);
   const [doorLocationFile, setDoorLocationFile] = useState<File | null>(null);
   const [addLockModalVisible, setAddLockModalVisible] = useState(false);
   const [addCameraModalVisible, setAddCameraModalVisible] = useState(false);
   const [manageModalOpen, setManageModalOpen] = useState(false);
   const [accessChoiceState, setAccessChoiceState] =
      useState<AccessChoice | null>(null);
   const [accessChoiceErrorOpen, setAccessChoiceErrorOpen] = useState(false);
   const { watch, setValue } = useFormContext();
   const [locks, setLocks] = useState<Lock[]>([]);
   const [snackBarMessage, setSnackBarMessage] = useState("");
   const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
   const [createSnackBarOpen, setCreateSnackBarOpen] = useState(false);
   const [selectedLock, setSelectedLock] = useState<string>("0");
   const [fullSelectedLock, setFullSelectedLock] = useState<Lock | null>();
   const dispatch = useCreateListingDispatch();
   const { lockImagePath, doorImagePath, propertyId } = useCreateListingState();
   const theme = useTheme();
   const { user } = useUserState();

   const fetchAssociatedLocks = async () => {
      const res = await getUserLockList();
      if (res && res.data && res.data.status === true) {
         setLocks(res.data.result);
      }
   };

   // useEffect(() => {
   //    console.log(accessChoiceState);
   //    console.log("state");
   // }, [accessChoiceState]);

   useEffect(() => {
      dispatch({
         type: CreateListingActions.setFeature,
         payload: { file: "" },
      });
      dispatch({ type: CreateListingActions.setPhotos, payload: [] });
   }, []);

   useEffect(() => {
      dispatch({
         type: CreateListingActions.setFeaturePhotoPath,
         payload: null,
      });
      dispatch({ type: CreateListingActions.setPhotoPaths, payload: [] });
   }, []);

   const contextLockKey = watch("lockKey");
   const contextLockTypeId = watch("lockTypeId");
   const contextIglooLockTypeId = watch("igloolockTypeId");
   const contextMasterLockTypeId = watch("masterLockTypeId");
   const contextAccessChoice = watch("accessChoice");

   useEffect(() => {
      if (contextLockKey) {
         const full = locks.filter((lock) => {
            const key = lock.lockKey;
            return key === contextLockKey;
         });
         if (full && full.length > 0) {
            // console.log(full[0])
            setSelectedLock(full[0].id.toString());
         }
      }
   }, [contextLockKey, locks]);

   useEffect(() => {
      if (contextAccessChoice !== null) {
         setAccessChoiceState(contextAccessChoice);
      }
   }, [contextAccessChoice]);

   useEffect(() => {
      if (accessChoiceState !== null) {
         setValue("accessChoice", accessChoiceState);
      }
   }, [accessChoiceState]);

   const handleSaveDeletePhoto = async (lock: boolean) => {
      if (!propertyId) {
         return;
      }

      if (contextAccessChoice === null) {
         setAccessChoiceErrorOpen(true);
         return;
      }

      let newLockImageKey: string | null = null;
      let newDoorImageKey: string | null = null;
      let lockImgKey: string = uuidv4();
      let doorImgKey: string = uuidv4();
      if (contextAccessChoice === AccessChoice.LOCK) {
         if (lockLocationFile) {
            const lockImageRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: lockImgKey,
            });
            if (
               lockImageRequest &&
               lockImageRequest.data &&
               lockImageRequest.data.status === true
            ) {
               const lockImgUrl = lockImageRequest.data.result;
               await axios
                  .put(lockImgUrl, lockLocationFile)
                  .then(() => {
                     newLockImageKey = lockImgKey;
                  })
                  .catch((error) => {});
            }
         }
         if (doorLocationFile) {
            const doorImageRequest = await generatePreSignedUrl({
               directory: S3DirectoryName.PROPERTY,
               fileName: doorImgKey,
            });
            if (
               doorImageRequest &&
               doorImageRequest.data &&
               doorImageRequest.data.status === true
            ) {
               const lockImgUrl = doorImageRequest.data.result;
               await axios
                  .put(lockImgUrl, lockLocationFile)
                  .then(() => {
                     newDoorImageKey = doorImgKey;
                  })
                  .catch((error) => {});
            }
         }
      }
      let body: CreatePropertyRequest5AssignLock;
      if (contextAccessChoice === AccessChoice.LOCK) {
         body = {
            stepId: 5,
            propertyId: propertyId,
            lockTypeId: contextLockTypeId.toString(),
            lockKey: contextLockKey,
            doorImg: lock ? doorImagePath : "",
            lockImg: lock ? "" : lockImagePath,
            igloolockTypeId: contextIglooLockTypeId
               ? contextIglooLockTypeId
               : "",
            masterlockTypeId: contextMasterLockTypeId
               ? contextMasterLockTypeId
               : "",
            accessChoice: 1,
         };
      } else {
         body = {
            stepId: 5,
            propertyId: propertyId,
            lockTypeId: "",
            lockKey: "",
            doorImg: "",
            lockImg: "",
            igloolockTypeId: "",
            masterlockTypeId: "",
            accessChoice: 0,
         };
      }

      // const body: CreatePropertyRequest5AssignLock = {
      //    stepId: 5,
      //    propertyId: propertyId,
      //    lockTypeId: contextLockTypeId.toString(),
      //    lockKey: contextLockKey,
      //    doorImg: lock ? doorImagePath : "",
      //    lockImg: lock ? "" : lockImagePath,
      //    igloolockTypeId: contextIglooLockTypeId ? contextIglooLockTypeId : "",
      //    masterlockTypeId: contextMasterLockTypeId
      //       ? contextMasterLockTypeId
      //       : "",
      // };
      const res = await createPropertyStepFiveAssignLock(body);
      if (res && res.data && res.data.status === true) {
         return;
      } else {
         return;
      }
   };

   useEffect(() => {
      if (selectedLock !== "0") {
         const full = locks.filter((lock) => {
            const id = lock.id.toString();
            return id === selectedLock;
         });
         if (full && full.length === 1) {
            setFullSelectedLock(full[0]);
         }
      } else {
         //console.log("no lock selected");
         setFullSelectedLock(null);
      }
   }, [selectedLock]);

   useEffect(() => {
      // console.log(doorImagePath)
   }, [doorImagePath]);

   useEffect(() => {
      if (lockLocationFile !== null) {
         dispatch({
            type: CreateListingActions.setLockImage,
            payload: lockLocationFile,
         });
      }
   }, [lockLocationFile]);

   useEffect(() => {
      if (doorLocationFile !== null) {
         dispatch({
            type: CreateListingActions.setDoorImage,
            payload: doorLocationFile,
         });
      }
   }, [doorLocationFile]);

   useEffect(() => {
      if (fullSelectedLock) {
         setValue("lockTypeId", fullSelectedLock.lockTypeId);
         setValue("lockKey", fullSelectedLock.lockKey);
         if (fullSelectedLock.iglooLockTypeId) {
            setValue("iglooLockTypeId", fullSelectedLock.iglooLockTypeId);
         }
         if (fullSelectedLock.masterLockTypeId) {
            setValue("masterLockTypeId", fullSelectedLock.masterLockTypeId);
         }
      } else {
         //console.log("unassign lock");
         //set lockTypeId to 0 to unassign
         setValue("lockTypeId", 0);
         setValue("lockKey", "");
         setValue("iglooLockTypeId", "");
         setValue("masterLockTypeId", "");
      }
   }, [fullSelectedLock]);

   // const deleteLockImageInDatabase = async () => {
   //   const body = {

   //   }
   // }
   const setAccessChoiceInstructions = () => {
      setAccessChoiceState(AccessChoice.INSTRUCTIONS);
   };

   const setAccessChoiceLock = () => {
      setAccessChoiceState(AccessChoice.LOCK);
   };

   const setSnackbarMessage = (value: string) => {
      setSnackBarMessage(value);
   };

   const setSelectedLockFunc = (value: string) => {
      setSelectedLock(value);
   };

   const setSnackbarType = (value: AlertColor) => {
      setSnackBarType(value);
   };

   const setSnackbarOpen = (value: boolean) => {
      setCreateSnackBarOpen(value);
   };

   const setAddLockVisible = (value: boolean) => {
      setAddLockModalVisible(value);
   };

   useEffect(() => {
      fetchAssociatedLocks();
   }, []);

   return (
      <>
         <Grid
            display={"flex"}
            direction="column"
            container
            className={classes.mainContainer}
         >
            <Snackbar
               open={createSnackBarOpen}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               autoHideDuration={6000}
               onClose={() => {
                  setCreateSnackBarOpen(false);
               }}
            >
               <Alert
                  severity={snackBarType}
                  onClose={() => {
                     setCreateSnackBarOpen(false);
                  }}
               >
                  {snackBarMessage}
               </Alert>
            </Snackbar>
            <Snackbar
               open={accessChoiceErrorOpen}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
               autoHideDuration={6000}
               onClose={() => {
                  setAccessChoiceErrorOpen(false);
               }}
            >
               <Alert
                  severity="error"
                  onClose={() => {
                     setAccessChoiceErrorOpen(false);
                  }}
               >
                  Access choice is required, please select an option and try
                  again.
               </Alert>
            </Snackbar>
            <AddLockModal
               id={"add-lock-modal-create-listing"}
               isVisible={addLockModalVisible}
               refresh={false}
               setSnackbarMessage={setSnackbarMessage}
               setSnackbarType={setSnackbarType}
               setSnackbar={setSnackbarOpen}
               setPanel={setAddLockVisible}
               fetch={fetchAssociatedLocks}
               openPosition={{
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "20vh",
               }}
               closedPosition={{
                  top: "0",
                  left: "0",
                  right: "-450px",
                  bottom: "20vh",
               }}
            />

            <Grid item>
               <Grid
                  container
                  direction="row"
                  display={"flex"}
                  justifyContent="space-between"
                  // Only need this margin if menu icon button is hidden
                  sx={{ marginBottom: "20px" }}
               >
                  <Grid item>
                     <HeaderButton
                        color="black"
                        backgroundColor="white"
                        borderColor="#707070"
                        text="DRAFT"
                        clickable={true}
                     />
                  </Grid>
               </Grid>
            </Grid>
            <Grid item className={classes.accessChoiceHeaderContainer}>
               <div className={classes.accessChoiceHeaderText}>
                  How will visitors access your listing?
               </div>
            </Grid>
            <Grid
               item
               className={classes.listingLockInstructionsComponentContainer}
            >
               <ListingLockInstructionsComponent
                  setAccessChoiceInstructions={setAccessChoiceInstructions}
                  setAccessChoiceLock={setAccessChoiceLock}
                  accessChoice={accessChoiceState}
               />
            </Grid>
            {accessChoiceState === AccessChoice.LOCK ? (
               <Grid item sx={{ paddingRight: "40px" }}>
                  <Grid
                     container
                     direction="row"
                     justifyContent={"space-between"}
                     display="flex"
                     flexWrap="nowrap"
                  >
                     {locks.length > 0 ? (
                        <Grid item>
                           <Grid
                              container
                              direction={"column"}
                              display="flex"
                              gap={"45px"}
                           >
                              <Grid item>
                                 <Grid
                                    container
                                    direction="column"
                                    display="flex"
                                    gap={"10px"}
                                 >
                                    <Grid item>
                                       <div className={classes.existingHeader}>
                                          Lock
                                       </div>
                                    </Grid>
                                    <Grid item>
                                       <LockSelectComponent
                                          locks={locks}
                                          selected={selectedLock}
                                          setSelected={setSelectedLockFunc}
                                       />
                                    </Grid>
                                 </Grid>
                              </Grid>
                              <Grid item>
                                 {(user?.accountType !== 6 ||
                                    user?.isEnterpriseSuperUser === 1) && (
                                    <BoxltyButtonV2
                                       text="ADD A NEW LOCK"
                                       endIcon={<AddIcon fontSize="small" />}
                                       width="175px"
                                       backgroundColor="white"
                                       borderColor={
                                          theme.palette.common.lightGray
                                       }
                                       textColor={
                                          theme.palette.common.lightGray
                                       }
                                       disabled={false}
                                       onClick={() => {
                                          //setManageModalOpen(true)
                                          setAddLockModalVisible(
                                             !addLockModalVisible
                                          );
                                       }}
                                    />
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     ) : (
                        <Grid item>
                           {(user?.accountType !== 6 ||
                              user?.isEnterpriseSuperUser === 1) && (
                              <IconButton
                                 disableRipple
                                 disableFocusRipple
                                 onClick={() => {
                                    setAddLockModalVisible(
                                       !addLockModalVisible
                                    );
                                 }}
                              >
                                 <AddLock />
                              </IconButton>
                           )}
                        </Grid>
                     )}
                     <Grid item>
                        <Grid
                           container
                           direction={"row"}
                           display="flex"
                           gap={"30px"}
                        >
                           <Grid item>
                              <Grid
                                 container
                                 direction={"column"}
                                 display="flex"
                              >
                                 <Grid item>
                                    <div
                                       className={classes.inputHeader}
                                    >{`LOCK LOCATION*`}</div>
                                 </Grid>
                                 <Grid item>
                                    {lockImagePath !== null ? (
                                       <img
                                          height="155px"
                                          width="155px"
                                          src={`https://boxlty-media.s3.amazonaws.com/property-images/${lockImagePath}`}
                                          alt="Lock"
                                       />
                                    ) : lockLocationFile !== null ? (
                                       <PreviewImage
                                          showMenu={false}
                                          clearImage={() => {
                                             setLockLocationFile(null);
                                          }}
                                          height="155px"
                                          width="155px"
                                          file={URL.createObjectURL(
                                             lockLocationFile
                                          )}
                                       />
                                    ) : (
                                       <FileUpload
                                          id="lock-location"
                                          height="125px"
                                          width="125px"
                                          file={lockLocationFile}
                                          setFile={setLockLocationFile}
                                       />
                                    )}
                                 </Grid>
                                 <Grid
                                    item
                                    sx={{
                                       display: "flex",
                                       alignSelf: "center",
                                    }}
                                 >
                                    <div
                                       className={classes.inputSubHeader}
                                       onClick={() => {
                                          dispatch({
                                             type: CreateListingActions.setLockImage,
                                             payload: null,
                                          });
                                          dispatch({
                                             type: CreateListingActions.setLockImagePath,
                                             payload: null,
                                          });
                                          setLockLocationFile(null);
                                          handleSaveDeletePhoto(true);
                                       }}
                                    >
                                       <div className={classes.deleteImgText}>
                                          Delete Image
                                       </div>
                                    </div>
                                 </Grid>
                              </Grid>
                           </Grid>
                           <Grid item>
                              <Grid
                                 container
                                 direction={"column"}
                                 display="flex"
                              >
                                 <Grid item>
                                    <div
                                       className={classes.inputHeader}
                                    >{`DOOR LOCATION`}</div>
                                 </Grid>
                                 <Grid item>
                                    {doorImagePath !== null ? (
                                       <img
                                          height="155px"
                                          width="155px"
                                          src={`https://boxlty-media.s3.amazonaws.com/property-images/${doorImagePath}`}
                                          alt="Door"
                                       />
                                    ) : doorLocationFile !== null ? (
                                       <PreviewImage
                                          showMenu={false}
                                          clearImage={() => {
                                             setDoorLocationFile(null);
                                          }}
                                          height="155px"
                                          width="155px"
                                          file={URL.createObjectURL(
                                             doorLocationFile
                                          )}
                                       />
                                    ) : (
                                       <FileUpload
                                          id="lock-location"
                                          height="125px"
                                          width="125px"
                                          file={doorLocationFile}
                                          setFile={setDoorLocationFile}
                                       />
                                    )}
                                 </Grid>
                                 <Grid
                                    item
                                    sx={{
                                       display: "flex",
                                       alignSelf: "center",
                                    }}
                                 >
                                    <div
                                       className={classes.inputSubHeader}
                                       onClick={() => {
                                          setDoorLocationFile(null);
                                          dispatch({
                                             type: CreateListingActions.setDoorImage,
                                             payload: null,
                                          });
                                          dispatch({
                                             type: CreateListingActions.setDoorImagePath,
                                             payload: null,
                                          });
                                          handleSaveDeletePhoto(false);
                                       }}
                                    >
                                       <div className={classes.deleteImgText}>
                                          Delete Image
                                       </div>
                                    </div>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            ) : null}
            <Grid item sx={{ marginTop: "15px", width: "94%" }}>
               <Divider sx={{ borderBottomWidth: "2px" }} />
            </Grid>
            {/* <Grid item sx={{ marginTop: '15px' }}>
          <IconButton disableFocusRipple disableRipple onClick={() => { setAddCameraModalVisible(!addCameraModalVisible)}}>
            <AddCamera />
          </IconButton>
          <AddCameraModal id={'add-camera-modal-create-listing'}
            isVisible={addCameraModalVisible}
            openPosition={{ top: '0', left: '0', right: '0px', bottom: '20vh' }}
            closedPosition={{ top: '0', left: '0', right: '-350px', bottom: '20vh' }} />
        </Grid> */}
         </Grid>
      </>
   );
};

const useStyles = makeStyles({ name: "Listing-Hardware-Tab" })((theme) => ({
   mainContainer: {
      paddingTop: "20px",
      paddingLeft: "15px",
      paddingRight: "10px",
      paddingBottom: "15px",
   },
   inputHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginBottom: "7px",
      textTransform: "uppercase",
      fontSize: "14px",
   },
   inputSubHeader: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#A2A2A2",
      marginTop: "8px",
      fontSize: "12px",
      textDecoration: "underline",
      cursor: "pointer",
   },
   deleteImgText: {
      fontFamily: theme.fonts.secondary.reg,
      color: "#707070",
      fontSize: "14px",
   },
   existingHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "20px",
   },
   accessChoiceHeaderContainer: {
      marginBottom: "20px",
   },
   accessChoiceHeaderText: {
      fontSize: "22px",
      fontFamily: theme.fonts.primary.bold,
      fontWeight: "bold",
   },
   listingLockInstructionsComponentContainer: {
      marginBottom: "40px",
   },
}));
