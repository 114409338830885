import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import ArrowLeftSVG from "../../styles/assets/logos/ArrowLeft";
import ArrowRightSVG from "../../styles/assets/logos/ArrowRight";

enum colors {
   "white",
   "transparent",
   "primary",
   "#3C3C3C",
}

interface RoundedButtonProps {
   id?: string;
   disabled?: boolean;
   listener?: () => void;
   forceUpdate?: () => void;
   text?: string;
   color?: keyof typeof colors;
   showArrow?: boolean;
   arrowDirection?: string;
   showShadow?: boolean;
   boldText?: boolean;
   type?: any;
   marginLeft?: string;
   marginRight?: string;
}
function RoundedButton(props: RoundedButtonProps) {
   const theme = useTheme();

   useEffect(() => {
      if (props.disabled != null && props.disabled !== undefined) {
         if (props.disabled === false) {
            let text = document.querySelector(
               "#button-" + props.id + "-text"
            ) as HTMLElement;
            let arrow = document.querySelector(
               "#button-" + props.id + "-arrow"
            ) as HTMLElement;

            if (text) {
               text.style.color = "white";
            } else {
            }

            if (arrow) {
               arrow.style.color = "white";
            } else {
            }
         } else {
            let text = document.querySelector(
               "#button-" + props.id + "-text"
            ) as HTMLElement;
            let arrow = document.querySelector(
               "#button-" + props.id + "-arrow"
            ) as HTMLElement;

            if (text) {
               text.style.color = theme.palette.common.medGray;
               // do the same for the arrow
            } else {
            }

            if (arrow) {
               arrow.style.color = theme.palette.common.medGray;
            } else {
            }
         }
      }
   }, [props]);

   let useStyles = makeStyles({ name: "RoundedButtonStyles" })((theme) => ({
      RoundedButton: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         border: "1px solid",
         borderColor:
            props.color === "primary"
               ? theme.palette.primary.main
               : props.color === "transparent"
               ? theme.palette.common.medGray
               : "#f0ecec",
         background:
            props.color === "primary"
               ? "linear-gradient(45deg," +
                 theme.palette.primary.main +
                 ",#B8519E)"
               : props.color === "transparent"
               ? "transparent"
               : "white",
         borderRadius: "15px",
         padding: "1px",
         minWidth: "75px",
         cursor: "pointer",
         boxShadow: props.showShadow ? "6px 6px 5px #00000014" : "none",
         minHeight: "25px",
         marginLeft: props.marginLeft ? props.marginLeft : "10px",
         marginRight: props.marginRight ? props.marginRight : "10px",
         color:
            props.color === "primary" ? "white" : theme.palette.common.darkGray,
         //transition: 'all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55)', // Drastic cubic bezier for bouncing effect
         transition: "all 0.2s linear", // Drastic cubic bezier for bouncing effect
         "&:disabled": {
            background: "transparent",
            borderColor: theme.palette.common.darkGray,
            color: theme.palette.common.darkGray + " !important ",
            opacity: "0.5",
         },
         "&:not(:disabled):hover": {
            // Add styles for the hover state here
            // For example, you can change the background color:
            //transform: 'scale(1.01)'
            padding: "3px",
            paddingTop: "-6px",
            paddingBottom: "3px",
            //paddingTop : '5.5px',
            //paddingBottom : '5.5px',
            // Add any other hover state styles you want
         },

         // Rest of your styles
      },
      innerButton: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         paddingLeft: "11px",
         paddingRight: "11px",
      },
      buttonArrow: {
         //color: (props.disabled === true ? theme.palette.common.darkGray : props.color === 'primary' ? 'white' : theme.palette.common.darkGray),
         fontFamily: theme.fonts.secondary.reg,
         fontSize: "15px",
         display: props.showArrow ? "flex" : "none",
      },
      buttonArrowImg: {
         height: "10px",
         width: "auto",
      },
      buttonText: {
         //color: (props.disabled === false && props.color === 'primary') ? theme.palette.common.primary : theme.palette.common.darkGray,
         fontFamily: props.boldText
            ? theme.fonts.secondary.bold
            : theme.fonts.secondary.reg,
         //font : ('normal normal medium 14px/19px' + props.boldText ? theme.fonts.secondary.bold : theme.fonts.secondary.reg),
         fontSize: "11px",
         //lineHeight: '19px',
         letterSpacing: "1px",
         marginRight: props.showArrow
            ? props.arrowDirection === "right"
               ? "5px"
               : "0px"
            : "0px",
         marginLeft: props.showArrow
            ? props.arrowDirection === "left"
               ? "5px"
               : "0px"
            : "0px",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
   }));

   let { classes } = useStyles();

   return (
      <button
         type={props.type}
         disabled={props.disabled ? props.disabled : false}
         id={props.id ? props.id : ""}
         className={classes.RoundedButton}
         onClick={props.listener ? props.listener : () => {}}
      >
         <div className={classes.innerButton}>
            {props.showArrow ? (
               props.arrowDirection === "right" ? (
                  <>
                     <span
                        id={props.id ? "button-" + props.id + "-text" : ""}
                        className={classes.buttonText}
                     >
                        {props.text}
                     </span>
                     <span
                        id={props.id ? "button-" + props.id + "-arrow" : ""}
                        className={classes.buttonArrow}
                     >
                        <ArrowRightSVG />
                     </span>
                  </>
               ) : (
                  <>
                     <span
                        id={props.id ? "button-" + props.id + "-arrow" : ""}
                        className={classes.buttonArrow}
                     >
                        <ArrowLeftSVG />
                     </span>
                     <span
                        id={props.id ? "button-" + props.id + "-text" : ""}
                        className={classes.buttonText}
                     >
                        {props.text}
                     </span>
                  </>
               )
            ) : (
               <span
                  id={props.id ? "button-" + props.id + "-text" : ""}
                  className={classes.buttonText}
               >
                  {props.text}
               </span>
            )}
         </div>
      </button>
   );
}

export default RoundedButton;
