import { StorageKeys } from "./storeapp.types";

export const getStoredItem = (key: StorageKeys) => {
  try {
    let item = window.localStorage.getItem(key);
    if (!item) {
      item = window.sessionStorage.getItem(key);
    }
    if (item) {
      return item;
    }
  } catch (err) {
    //console.log(`Error getting stored ${key}: err`);
  }
  return null;
};

export const setStoredItem = (
  key: StorageKeys,
  data: any,
  session: boolean = false
) => {
  try {
    if (session) {
      return window.sessionStorage.setItem(key, data.toString());
    } else {
      return window.localStorage.setItem(key, data.toString());
    }
  } catch (err) {
    // console.log(`Error setting stored ${key}: ${data} Error: ${err}`)
  }
};

export const clearStoredItem = (key: StorageKeys) => {
  try {
    window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
  } catch (error) {
    //console.log(`Error removing stored ${key}: err`);
  }
};
