import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Backdrop, Grid, useTheme } from "@mui/material";
import RegisterPlanCardComponent from "./register-plancard-component";
import RegisterMonthYearOptionComponent from "./register-monthyearoption-component";
import RegisterBusinessTierPlanCardComponent from "./register-businesstier-component";
import { ReactComponent as CloseIcon } from "../../styles/assets/Icons/close-button-2.svg";

function RegisterChoosePlanComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [monthlyOption, setMonthlyOption] = useState(false);
  const [backdropOpen, setBackDropOpen] = useState(false);
  // const [subscriptsSet, setSubscriptsSet] = useState(false);

  useEffect(() => {}, []);

  return (
    <div className={classes.BodyContainer}>
      <Backdrop
        open={backdropOpen}
        sx={{ color: "#000000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent="center"
          className={classes.BusinessTierContainer}
        >
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              setBackDropOpen(false);
            }}
          />
          <h2 className={classes.BusinessTierTitleText}>
            Which business tier is right for you?
          </h2>
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            // className={classes.BusinessTierContainer}
          >
            <RegisterBusinessTierPlanCardComponent
              StatusName="Business Tier 1"
              ActiveListings="1"
              CostPerMonth="$49.99"
              CostPerMonthYear="$39.99"
              MonthChosen={monthlyOption}
              SetChosenPlan={props.onSubmitChoosePlan}
              NextStepOption1={props.nextStepOption1}
              MonthlyOption={monthlyOption}
              ListingNumbers="1-5 Active Listings"
            />
            <RegisterBusinessTierPlanCardComponent
              StatusName="Business Tier 2"
              ActiveListings="1"
              CostPerMonth="$99.99"
              CostPerMonthYear="$79.99"
              MonthChosen={monthlyOption}
              SetChosenPlan={props.onSubmitChoosePlan}
              NextStepOption1={props.nextStepOption1}
              MonthlyOption={monthlyOption}
              ListingNumbers="6-9 Active Listings"
            />
          </Grid>
        </Grid>
      </Backdrop>

      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems="center"
      >
        <h2 className={classes.ChoosePlanTitleText}>
          Choose the plan that is right for you.
        </h2>
        <Grid
          container
          direction={"row"}
          justifyContent="center"
          className={classes.PlanTextContainer}
        >
          <p
            className={
              monthlyOption
                ? `${classes.PlanText} ${classes.PlanTextSelected}`
                : `${classes.PlanText}`
            }
          >
            Cancel anytime
          </p>
          <p
            className={
              !monthlyOption
                ? `${classes.PlanText} ${classes.PlanTextSelected}`
                : `${classes.PlanText}`
            }
          >
            Save up to 33%
          </p>
        </Grid>
        <RegisterMonthYearOptionComponent
          MonthChosen={monthlyOption}
          SetMonthChoice={setMonthlyOption}
        />
        <Grid container direction={"row"} justifyContent="center">
          <RegisterPlanCardComponent
            StatusName="Free"
            StatusDescription={`The fastest way to try InstaShow®
            `}
            ActiveListings="1"
            CostPerMonth="FREE"
            CostPerMonthYear="FREE"
            ButtonText="GET STARTED"
            MonthChosen={monthlyOption}
            SetMonthChosen={setMonthlyOption}
            SetChosenPlan={props.onSubmitChoosePlan}
            NextStepOption1={props.nextStepOption1}
            MonthlyOption={monthlyOption}
            CurrentPlan="undefined"
          />
          <RegisterPlanCardComponent
            StatusName="Plus"
            StatusDescription="Save more time using InstaShow® on more than one property"
            ActiveListings="1-4"
            CostPerMonth="$29.99"
            CostPerMonthYear="$19.99"
            ButtonText="START FREE TRIAL"
            MonthChosen={monthlyOption}
            SetMonthChosen={setMonthlyOption}
            SetChosenPlan={props.onSubmitChoosePlan}
            NextStepOption1={props.nextStepOption1}
            MonthlyOption={monthlyOption}
            CurrentPlan="undefined"
          />
          <RegisterPlanCardComponent
            StatusName="Business"
            StatusDescription="Scale your business and collaborate with your team"
            ActiveListings="1-9"
            CostPerMonth="$49.99"
            CostPerMonthYear="$39.99"
            ButtonText="START FREE TRIAL"
            MonthChosen={monthlyOption}
            SetMonthChosen={setMonthlyOption}
            SetChosenPlan={props.onSubmitChoosePlan}
            NextStepOption1={props.nextStepOption1}
            MonthlyOption={monthlyOption}
            SetBackdrop={setBackDropOpen}
            CurrentPlan="undefined"
          />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({ name: "RegisterWelcomeInstaShowComponent" })(
  (theme) => ({
    BodyContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: "35px",
    },
    RentIcon: {
      height: "60px",
      width: "auto",
    },
    TitleText: {
      alignSelf: "center",
      fontSize: "1.8rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textAlign: "center",
    },
    ChoosePlanTitleText: {
      alignSelf: "center",
      fontSize: "1.8rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textAlign: "center",
      "@media (max-width: 660px)": {
        width: "19rem",
      },
    },
    BusinessTierTitleText: {
      alignSelf: "center",
      fontSize: "1.8rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textAlign: "center",
      paddingTop: "25px",
    },
    DetailsText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    StoreIcon: {
      height: "75px",
      width: "auto",
      cursor: "pointer",
    },
    DownloadText: {
      marginTop: "4rem",
      alignSelf: "center",
      textAlign: "center",
      fontSize: ".85rem",
      color: "#707070",
    },
    IconText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: ".8rem",
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.reg,
      marginTop: "15px",
      marginBottom: "0",
    },
    ClickableContainer: {
      width: "150px",
      display: "flex",
      justifyContent: "center",
      height: "140px",
      backgroundColor: "white",
      borderRadius: "25px",
      margin: "15px",
      cursor: "pointer",
    },
    IconContainer: {
      marginTop: "2.5rem",
    },
    Bordered: {
      border: "1px solid #D63F94",
    },
    NextButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "85px",
    },
    PlanText: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      fontWeight: "bold",
      color: "#bebebe",
    },
    PlanTextSelected: {
      color: "black",
    },
    PlanTextContainer: {
      width: "250px",
      alignItems: "center",
    },
    BusinessTierContainer: {
      width: "760px",
      height: "auto",
      alignItems: "center",
      backgroundColor: "#F5F6F6",
      borderRadius: "15px",
      position: "relative",
    },
    closeIcon: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
    },
  })
);

export default RegisterChoosePlanComponent;
