import React from "react";
import { useEffect, useState } from "react";
import {
   useTheme,
   Grid,
   Drawer,
   List,
   SxProps,
   IconButton,
   Divider,
   Avatar,
} from "@mui/material";
import {
   PageBodyContainer,
   PageContainer,
} from "../../../layouts/genric.layouts";
import { ReactComponent as BoxltyFullLogo } from "../../../styles/assets/logos/logo-on-black.svg";
import { ReactComponent as BoxltySmallLogo } from "../../../styles/assets/logos/instashow-icon-pink.svg";
import { ReactComponent as CollapseIcon } from "../../../styles/assets/logos/collapse-icon.svg";
import { ReactComponent as ExpandIcon } from "../../../styles/assets/logos/expand-icon.svg";
import { ReactComponent as PlayStore } from "../../../styles/assets/logos/play-store.svg";
import { ReactComponent as AppStore } from "../../../styles/assets/logos/app-store.svg";
import { ReactComponent as AppleSmall } from "../../../styles/assets/logos/apple-small.svg";
import { ReactComponent as GoogleSmall } from "../../../styles/assets/logos/google-small.svg";
import {
   lenderPrimaryNavList,
   lenderSecondaryNavList,
   primaryNavList,
   secondaryNavList,
   shopNavItem,
} from "./nav.types";
import { MainNavItem } from "./nav.item";
import { makeStyles } from "tss-react/mui";
import {
   useNavState,
   useNavDispatch,
   NavActions,
} from "../../../contexts/nav/nav.context";
import { NavMenu } from "./nav.menu";
import { useUserState } from "../../../contexts/user/user.context";
import RoundedButton from "../../common/boxlty.button";
import { PreviewImage } from "../../common/preview.image";
export const MainNav: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const theme = useTheme();
   const [expandDisplay, setExpandDisplay] = useState("none");
   const [iconDisplay, setIconDisplay] = useState("flex");
   const [collapsedHover, setCollapsedHover] = useState(false);
   const { open } = useNavState();
   const { classes } = useStyles({ open: open });
   const dispatch = useNavDispatch();
   const [avatarAnchorEl, setAvatarAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const avatarOpen = Boolean(avatarAnchorEl);
   const { user } = useUserState();

   useEffect(() => {
      // console.log(user);
   }, []);

   const handleMenuClick = (e: any) => {
      setAvatarAnchorEl(e.currentTarget);
   };

   const expandHandler = () => {
      if (open === true) {
         dispatch({ type: NavActions.setClose });
      } else {
         dispatch({ type: NavActions.setOpen });
      }
   };

   const handleGoToBilling = () => {
      window.location.href = "/settings/billing";
   };

   const ReturnSVG: React.FC = () => {
      if (collapsedHover === true) {
         return <ExpandIcon />;
      } else {
         return <BoxltySmallLogo />;
      }
   };

   return (
      <Grid container className={classes.mainContainer} direction={"column"}>
         <Grid
            item
            className={classes.header}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
         >
            <Grid container className={classes.headerContainer}>
               <Grid item>
                  <Grid
                     container
                     className={classes.logoContainer}
                     direction={"row"}
                     style={{
                        gap: "10px",
                     }}
                  >
                     <Grid
                        className={classes.logoParent}
                        item
                        onMouseEnter={() => {
                           setExpandDisplay("flex");
                           setIconDisplay("none");
                        }}
                        onMouseLeave={() => {
                           setCollapsedHover(false);
                        }}
                     >
                        {open ? (
                           <BoxltyFullLogo className={classes.logoImage} />
                        ) : null}
                        {open ? null : (
                           <div
                              onMouseEnter={() => {
                                 setCollapsedHover(true);
                              }}
                              onMouseLeave={() => {
                                 setCollapsedHover(false);
                              }}
                              onClick={expandHandler}
                              className={classes.collapsedDiv}
                           >
                              {collapsedHover ? (
                                 <ExpandIcon
                                    style={{ height: "25px", width: "25px" }}
                                 />
                              ) : (
                                 <BoxltySmallLogo
                                    style={{
                                       position: "relative",
                                       bottom: "3.5px",
                                    }}
                                 />
                              )}
                           </div>
                        )}
                     </Grid>
                     <Grid item sx={{ alignSelf: "center" }}>
                        <IconButton onClick={expandHandler}>
                           {open ? <CollapseIcon /> : null}
                        </IconButton>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item>
                  <Grid container className={classes.iconContainer}>
                     <Grid
                        container
                        direction="row"
                        style={{ gap: "30px" }}
                        className={classes.iconSubContainer}
                     >
                        <Grid item>
                           {user?.accountType === 0 ||
                           user?.accountType === 1 ? (
                              <RoundedButton
                                 text={"UPGRADE"}
                                 color={"primary"}
                                 showArrow={false}
                                 showShadow={true}
                                 marginLeft={"0px"}
                                 marginRight={"2px"}
                                 listener={() => {
                                    handleGoToBilling();
                                 }}
                              />
                           ) : (
                              (user?.accountType === 5 ||
                                 user?.accountType === 6) &&
                              user?.companyLogo !== "" && (
                                 <>
                                    <PreviewImage
                                       height="50px"
                                       width="150px"
                                       file={`https://boxlty-media.s3.amazonaws.com/company-logos/${user?.companyLogo}`}
                                       showMenu={false}
                                       clearImage={() => {}}
                                    />
                                 </>
                              )
                           )}
                        </Grid>
                        <Grid item>
                           <Avatar
                              id="avatar"
                              onClick={handleMenuClick}
                              aria-haspopup="true"
                              aria-expanded={avatarOpen ? "true" : undefined}
                              aria-controls={
                                 avatarOpen ? "avatar-menu" : undefined
                              }
                              sx={{
                                 bgColor: theme.palette.common.lightGray,
                                 cursor: "pointer",
                                 fontFamily: theme.fonts.secondary.med,
                                 fontSize: "17px",
                              }}
                              src={`https://boxlty-media.s3.amazonaws.com/user-profile/${user?.userImage}`}
                           ></Avatar>
                           <NavMenu
                              avatarAnchorEl={avatarAnchorEl}
                              handleMenuClick={handleMenuClick}
                              avatarOpen={avatarOpen}
                              setAvatarAnchorEl={setAvatarAnchorEl}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

         <Grid item className={classes.secondaryContainer}>
            <Grid container direction={"row"}>
               <Grid
                  item
                  className={classes.drawerContainer}
                  sx={{
                     width: open ? "225px" : "90px",
                     transitionProperty: "width",
                     transitionDuration: "0.6s",
                     transitionTimingFunction: "ease-out",
                  }}
               >
                  <Grid
                     container
                     direction="column"
                     display={"flex"}
                     justifyContent={"space-between"}
                  >
                     <Grid item>
                        <Grid
                           container
                           direction="column"
                           className={classes.itemContainer}
                        >
                           {user?.accountType == 6 &&
                           user?.isEnterpriseSuperUser == 1
                              ? lenderPrimaryNavList.map((item) => (
                                   <MainNavItem
                                      key={item.url}
                                      icon={item.icon}
                                      display={item.display}
                                      url={item.url}
                                   />
                                ))
                              : primaryNavList.map((item) => (
                                   <MainNavItem
                                      key={item.url}
                                      icon={item.icon}
                                      display={item.display}
                                      url={item.url}
                                   />
                                ))}
                           <Grid item paddingLeft="20px">
                              <Divider flexItem className={classes.divider} />
                           </Grid>
                           {user?.accountType === 0 || user?.accountType === 1
                              ? secondaryNavList.map((item) => (
                                   <MainNavItem
                                      key={item.url}
                                      icon={item.icon}
                                      display={item.display}
                                      url={item.url}
                                   />
                                ))
                              : user?.accountType == 6 &&
                                user?.isEnterpriseSuperUser == 1
                              ? lenderSecondaryNavList
                                   .filter((item) => item.display !== "Tokens")
                                   .map((item) => (
                                      <MainNavItem
                                         key={item.url}
                                         icon={item.icon}
                                         display={item.display}
                                         url={item.url}
                                      />
                                   ))
                              : secondaryNavList
                                   .filter((item) => item.display !== "Tokens")
                                   .map((item) => (
                                      <MainNavItem
                                         key={item.url}
                                         icon={item.icon}
                                         display={item.display}
                                         url={item.url}
                                      />
                                   ))}
                        </Grid>
                     </Grid>
                     <Grid item>
                        <Grid
                           container
                           className={classes.bottomNavContainer}
                           direction={"column"}
                        >
                           <Grid item>
                              <Grid
                                 container
                                 direction={"column"}
                                 sx={{ gap: "20px" }}
                              >
                                 <Grid item>
                                    {shopNavItem.map((item) => (
                                       <MainNavItem
                                          url={item.url}
                                          icon={item.icon}
                                          display={item.display}
                                          key={item.url}
                                          external
                                       />
                                    ))}
                                 </Grid>
                                 <Grid item className={classes.appStoreItem}>
                                    <Grid
                                       container
                                       direction={"column"}
                                       display={"flex"}
                                       className={classes.appStoreContainer}
                                    >
                                       <Grid item>
                                          {open ? (
                                             <AppStore
                                                onClick={() => {
                                                   window.open(
                                                      "https://apps.apple.com/us/app/boxlty-self-tours/id1480841099",
                                                      "_blank"
                                                   );
                                                }}
                                                style={{ cursor: "pointer" }}
                                             />
                                          ) : (
                                             <AppleSmall
                                                height={"25.5px"}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                   window.open(
                                                      "https://apps.apple.com/us/app/boxlty-self-tours/id1480841099",
                                                      "_blank"
                                                   );
                                                }}
                                             />
                                          )}
                                       </Grid>
                                       <Grid item>
                                          {open ? (
                                             <PlayStore
                                                onClick={() => {
                                                   window.open(
                                                      "https://play.google.com/store/apps/details?id=com.boxlty.ta",
                                                      "_blank"
                                                   );
                                                }}
                                                style={{ cursor: "pointer" }}
                                             />
                                          ) : (
                                             <GoogleSmall
                                                height={"25.5px"}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                   window.open(
                                                      "https://play.google.com/store/apps/details?id=com.boxlty.ta",
                                                      "_blank"
                                                   );
                                                }}
                                             />
                                          )}
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                           {/* <Grid item className={classes.chatBubbleContainer}>
                                        <div style={{ color: 'white' }}>Chat?</div>
                                    </Grid> */}
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item className={classes.childContainer}>
                  {children}
               </Grid>
            </Grid>
         </Grid>
      </Grid>
   );
};

const useStyles = makeStyles<{ open: boolean }>({ name: "MainNav" })(
   (theme, open, expandDisplay) => ({
      mainContainer: {
         height: "100%",
         maxHeight: "100%",
         width: "100%",
         display: "flex",
         flexWrap: "nowrap",
      },
      header: {
         backgroundColor: theme.palette.common.black,
         paddingLeft: theme.spacing(5),
         paddingRight: theme.spacing(5),
         display: "flex",
         height: "64px",
         zIndex: 24,
      },
      headerContainer: {
         display: "flex",
         justifyContent: "space-between",
      },
      expandIcon: {
         visibility: "hidden",
         "&:hover": {
            visibility: "visible",
         },
      },
      boxltySmall: {
         display: "flex",
         "&:hover": {
            display: "none",
         },
      },
      logoContainer: {
         display: "flex",
         paddingTop: theme.spacing(2),
         height: "100%",
      },
      iconContainer: {
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         height: "100%",
      },
      iconSubContainer: {
         justifyContent: "center",
         alignItems: "center",
         display: "flex",
      },
      drawerContainer: {
         height: "100%",
         backgroundColor: "black",
         display: "flex",
      },
      itemContainer: {
         display: "flex",
         marginTop: theme.spacing(8),
         paddingLeft: open ? theme.spacing(2.7) : theme.spacing(1),
         alignItems: "flex-start",
         gap: "20px",
         width: "116%",
      },
      list: {
         paddingTop: "20px",
      },
      dividerContainer: {},
      divider: {
         width: "65px",
         color: "#707070",
         borderBottomWidth: "1px",
         backgroundColor: "#707070",
      },
      listElement: {},
      chatBubbleContainer: {
         paddingLeft: open ? theme.spacing(2.7) : theme.spacing(1),
      },
      secondaryContainer: {
         display: "flex",
         flex: 1,
         overflowY: "auto",
      },
      bottomNavContainer: {
         paddingLeft: open ? theme.spacing(2.7) : theme.spacing(1),
      },
      childContainer: {
         display: "flex",
         flex: 1,
         backgroundColor: theme.palette.common.black,
         overflowX: "hidden",
      },
      children: {
         backgroundColor: theme.palette.background.default,
         borderRadius: "25px",
         paddingLeft: "5px",
         height: "100%",
      },
      collapsedDiv: {
         display: "flex",
         height: "40px",
         width: "40px",
         position: "relative",
         right: "5px",
         alignItems: "center",
         justifyContent: "center",
         borderRadius: "50%",
         cursor: "pointer",
         "&:hover": {
            backgroundColor: "white",
         },
      },
      appStoreItem: {
         padding: "10px",
         paddingLeft: "20px",
         marginBottom: "20px",
      },
      appStoreContainer: {
         gap: "15px",
      },
      logoImage: {
         height: "30px",
         width: "auto",
      },
      logoParent: {
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
      },
   })
);
