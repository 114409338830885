import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Grid, MenuItem, Select, useTheme } from '@mui/material';
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { SingleListingActions, useSingleListingDispatch, useSingleListingState } from "../../../../contexts/single-listing/singleListing.context";
import { ReactComponent as InstaShow } from '../../../../styles/assets/Icons/instashow.svg';
export const CreatedAdvancedNoticeForm: React.FC<{ setActive: (value: boolean) => void }> = ({ setActive }) => {
    const { classes } = useStyles()
    const theme = useTheme()
    const { createdAdvancedNotice } = useSingleListingState()
    const dispatchCreated = useSingleListingDispatch()
    const { ListingIdParam } = useParams<{ ListingIdParam: string }>();


    return (
        <>
            <Grid container direction='row' display={'flex'} width={"100%"} gap="35px">
                <Grid item>
                    <Grid container direction='row' display={'flex'} alignItems='center' gap={'10px'}>
                        <Grid item>
                            <div className={classes.formHeader}>Advanced Notice</div>
                        </Grid>
                        <Grid item>
                            <InfoOutlinedIcon sx={{ cursor: 'pointer', color: theme.palette.common.primary }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ position: 'relative', bottom: '1px' }}>
                    <Grid container direction='row' display={'flex'} alignItems='center' gap={'10px'}>
                        <Grid item>
                            <Select variant="standard" disableUnderline value={createdAdvancedNotice} className={classes.instaShow} onChange={(e) => {
                                if (ListingIdParam) {
                                    dispatchCreated({ type: SingleListingActions.setCreatedAdvancedNotice, payload: e.target.value })
                                    if (setActive) {
                                        setActive(true)
                                    }
                                }
                            }}>
                                <MenuItem value="1" className={classes.instaShow}>{`InstaShow`}&reg;</MenuItem>
                                <MenuItem value="4">1 Hour</MenuItem>
                                <MenuItem value="5">2 Hours</MenuItem>
                                <MenuItem value="6">3 Hours</MenuItem>
                                <MenuItem value="7">4 Hours</MenuItem>
                                <MenuItem value="8">5 Hours</MenuItem>
                                <MenuItem value="9">6 Hours</MenuItem>
                                <MenuItem value="10">12 Hours</MenuItem>
                                <MenuItem value="18">24 Hours</MenuItem>
                                <MenuItem value="11">36 Hours</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <InstaShow style={{ height: '25px', width: '25px' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles({ name: "Custom-Availability-Form" })((theme) => ({
    mainContainer: {

    },
    formHeader: {
        fontSize: '20px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
    },
    instaShow: {
        fontSize: '17px',
        fontFamily: theme.fonts.secondary.bold,
        fontWeight: "bold",
    },
    inputHeader: {
        fontFamily: theme.fonts.secondary.reg,
        color: '#A2A2A2',
        marginBottom: '7px',
        textTransform: 'uppercase'
    },
    filledInput: {
        background: '#EAEAEA'
    },
    labelComponent: {
        marginLeft: 0,
        marginRight: 0
    },
    checkboxLabel: {
        marginTop: '5px'
    },
    timeSelectHeader: {

    }
}))