import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as RentIcon } from "../../styles/assets/Icons/rent-icon-dark.svg";
import { ReactComponent as ManageIcon } from "../../styles/assets/Icons/manage-icon.svg";
import RoundedButton from "../common/boxlty.button";

function RegisterWhyUseBoxltyComponent(props: any) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [hasBorder1, setHasBorder1] = useState(false);
  const [hasBorder2, setHasBorder2] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    if (hasBorder1 || hasBorder2) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [props.defaultValue, hasBorder1, hasBorder2]);

  const handleDiv1Click = () => {
    setHasBorder1(!hasBorder1);
    setHasBorder2(false);
  };

  const handleDiv2Click = () => {
    setHasBorder2(!hasBorder2);
    setHasBorder1(false);
  };

  const submitWhyUseBoxlty = () => {
    if (hasBorder1) {
      if (props.onSubmitWhyUse)
        props.onSubmitWhyUse(props.nextStepOption1, "buy");
    } else if (hasBorder2) {
      if (props.onSubmitWhyUse)
        props.onSubmitWhyUse(props.nextStepOption2, "sell");
    }
  };

  return (
    <div className={classes.BodyContainer}>
      <Grid container direction={"column"} justifyContent="center">
        <h2 className={classes.TitleText}>
          Why do you want to use InstaShow?
        </h2>
        <p className={classes.DetailsText}>Choose one</p>
        <Grid
          container
          direction={"row"}
          justifyContent="center"
          className={classes.IconContainer}
        >
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            className={`${classes.ClickableContainer} ${
              hasBorder1 ? classes.Bordered : ""
            }`}
            onClick={handleDiv1Click}
          >
            <RentIcon className={classes.RentIcon} />
            <p className={classes.IconText}>
              RENT OR BUY
              <br />A HOME
            </p>
          </Grid>
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            className={`${classes.ClickableContainer} ${
              hasBorder2 ? classes.Bordered : ""
            }`}
            onClick={handleDiv2Click}
          >
            <ManageIcon className={classes.RentIcon} />
            <p className={classes.IconText}>
              MANAGE OR SELL
              <br />
              PROPERTIES
            </p>
          </Grid>
        </Grid>
        <div className={classes.NextButtonContainer}>
          <RoundedButton
            id={"next"}
            listener={submitWhyUseBoxlty}
            disabled={nextButtonDisabled}
            boldText={false}
            showShadow={true}
            showArrow={true}
            arrowDirection={"right"}
            color="primary"
            text="NEXT"
          />
        </div>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({ name: "RegisterWelcomeInstaShowComponent" })(
  (theme) => ({
    BodyContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "10%",
      "@media (max-height: 660px)": {
        marginTop: "4%",
      },
    },
    RentIcon: {
      height: "60px",
      width: "auto",
    },
    TitleText: {
      alignSelf: "center",
      fontSize: "1.8rem",
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      textAlign: "center",
    },
    DetailsText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    StoreIcon: {
      height: "75px",
      width: "auto",
      cursor: "pointer",
    },
    DownloadText: {
      marginTop: "4rem",
      alignSelf: "center",
      textAlign: "center",
      fontSize: ".85rem",
      color: "#707070",
    },
    IconText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: ".8rem",
      fontWeight: "bold",
      fontFamily: theme.fonts.secondary.reg,
      marginTop: "15px",
      marginBottom: "0",
    },
    ClickableContainer: {
      width: "150px",
      display: "flex",
      justifyContent: "center",
      height: "140px",
      backgroundColor: "white",
      borderRadius: "25px",
      margin: "15px",
      cursor: "pointer",
    },
    IconContainer: {
      marginTop: "1.5rem",
    },
    Bordered: {
      border: "1px solid #D63F94",
    },
    NextButtonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3rem",
      marginBottom: "20px",
    },
  })
);

export default RegisterWhyUseBoxltyComponent;
