import React from "react";
import { useState, useEffect } from "react";
import AppBody from "../../components/body/main.body.component.jsx";
import {
   Alert,
   AlertColor,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   Snackbar,
   useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import ScheduledToursList from "../../components/dashboard/ScheduledToursList";
import AlertsComponent from "../../components/dashboard/AlertsComponent";
import {
   getSpecificDateTours,
   getUserAlerts,
   getUserProfile,
   getLenderDashboard,
} from "../../contexts/api/api.functions";
import {
   UserActions,
   useUserDispatch,
   useUserState,
} from "../../contexts/user/user.context";
import { useNavigate } from "react-router-dom";
import { AlertResponse, ToursResponse } from "../../contexts/api/api.types.js";
import dayjs, { Dayjs } from "dayjs";
import addListingActionIcon from "../../styles/assets/Buttons/qa-add listing.svg";
import ActionButton from "../../components/dashboard/ActionButton";
import AddMemberModal from "../../components/dashboard/AddMemberModal";
import addUserActionIcon from "../../styles/assets/Icons/qa-add-group.svg";
import addLockActionIcon from "../../styles/assets/Buttons/qa-add-lock.svg";
import addPartnerIcon from "../../styles/assets/Icons/add-partner-large.svg";
import AddLockModal from "../../components/dashboard/AddLockModal";
import { getStoredItem } from "../../contexts/common/localstorage";
import { StorageKeys } from "../../contexts/common/storeapp.types";
import HorizontalBars from "./horizontal.barchart";
import AddPartnerModal from "../../components/dashboard/AddPartnerModal";
import AddLockPartnerModal from "../../components/dashboard/AddLockPartnerModal";

interface DataPoint {
   Name: string;
   Leads: number;
   Locks: number;
   Listings: number;
   Tours: number;
}

export const LenderDashboardMain: React.FC<{}> = () => {
   const { classes } = useStyles();
   const theme = useTheme();
   const router = useNavigate();
   //Calendar Reference: https://mui.com/x/react-date-pickers/date-calendar/
   const { user } = useUserState();
   const [addListingModalVisible, setAddListingModalVisible] = useState(false);
   const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
   const [addLockModalVisible, setAddLockModalVisible] = useState(false);
   const [addCameraModalVisible, setAddCameraModalVisible] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarStatus, setSnackbarStatus] = useState<AlertColor>("success");
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
   const [alertCount, setAlertCount] = useState(0);
   const [alertItems, setAlertItems] = useState<AlertResponse[]>([]);
   const [upcomingTours, setUpcomingTours] = useState<ToursResponse[]>([]);
   const [chosenDate] = useState(new Date().toISOString().split("T")[0]);
   const [retrievedFullUser, setRetrievedFullUser] = useState(false);
   const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
      dayjs(new Date())
   );
   const [dateTitle, setDateTitle] = useState(
      dayjs(new Date()).format("MMMM DD, YYYY")
   );
   const [graphData, setGraphData] = useState("Leads");
   const [dataSet, setDataSet] = useState<any>([]);
   const dispatch = useUserDispatch();

   const setSnackbarType = (value: AlertColor) => {
      setSnackBarType(value);
   };

   useEffect(() => {
      if (user?.isRenter === 1) {
         router("/welcomeinstashow?status=buy");
      }

      if (!retrievedFullUser) {
         getFullUser();
         lenderDashboard();
         setRetrievedFullUser(true);
      }
   });

   const getFullUser = async () => {
      if (
         getStoredItem(StorageKeys.access_token) &&
         getStoredItem(StorageKeys.userId)
      ) {
         const userId = getStoredItem(StorageKeys.userId);
         if (userId) {
            const res = await getUserProfile({ userId: userId });
            if (res && res.data && res.data.status === true) {
               dispatch({ type: UserActions.user, payload: res.data.result });
               // console.log("full user loaded");
            }
         }
      }
   };

   const lenderDashboard = async () => {
      const res = await getLenderDashboard();
      if (res && res.data && res.data.status === true) {
         //dispatch({ type: UserActions.user, payload: res.data.result });
         //(res);
         const dataPoints: any = res.data.result.members.map((item: any) => ({
            Name:
               item.userId.toString() +
               ":" +
               (item.userName.trim() !== "" ? item.userName : item.email),
            Leads: item.leadsCount,
            Locks: item.locksCount,
            Listings: item.listingsCount,
            Tours: item.toursCount,
         }));
         setDataSet(dataPoints);
      }
   };

   return (
      <>
         <AppBody>
            <Snackbar
               open={snackbarOpen}
               autoHideDuration={4000}
               sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
               onClose={() => {
                  setSnackbarOpen(false);
               }}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
               <Alert
                  onClose={() => {
                     setSnackbarOpen(false);
                  }}
                  severity={snackbarStatus}
                  color={snackbarStatus}
               >
                  {snackbarMessage}
               </Alert>
            </Snackbar>
            <section className={classes.DashboardOuterLayout}>
               {/* item 1 */}
               <section className={classes.ItemOneContainer}>
                  <h1 className={classes.DashboardTitle}>Dashboard</h1>
                  {/* Top Section with calendar */}
                  <section className={classes.DashboardBlockedSection}>
                     {/* flex container */}
                     <div className={classes.InnerItemContainer}>
                        {/* left */}
                        <div className={classes.InnerItemSide}>
                           <h2 className={classes.InnerItemSideMainHeader}>
                              {graphData}
                           </h2>
                           <div>
                              <HorizontalBars
                                 dataKey={graphData}
                                 dataSet={dataSet}
                              />
                           </div>
                        </div>
                        {/* right */}
                        <div
                           style={{
                              marginLeft: "auto",
                              padding: "10px",
                              marginRight: "20px",
                           }}
                        >
                           <FormControl fullWidth>
                              <InputLabel id="graph-data-select-label">
                                 Data Type
                              </InputLabel>
                              <Select
                                 labelId="graph-data-select-label"
                                 id="graph-data-select"
                                 value={graphData}
                                 label="Data Type"
                                 onChange={(e) => {
                                    setGraphData(e.target.value);
                                 }}
                              >
                                 <MenuItem value="Leads">Leads</MenuItem>
                                 <MenuItem value="Listings">Listings</MenuItem>
                                 <MenuItem value="Tours">Tours</MenuItem>
                              </Select>
                           </FormControl>
                        </div>
                     </div>
                  </section>
                  <section className={classes.DashboardBlockedSection}>
                     {/* flex container */}
                     <div className={classes.InnerItemContainer}>
                        {/* left */}
                        <div className={classes.InnerItemSide}>
                           <h2 className={classes.InnerItemSideMainHeader}>
                              Locks
                           </h2>

                           <div>
                              <HorizontalBars
                                 dataKey="Locks"
                                 dataSet={dataSet}
                              />
                           </div>
                        </div>
                     </div>
                  </section>
                  {/* Bottom Section with bar chart */}
               </section>
               {/* item 2 */}
               <section className={classes.ItemTwoContainer}>
                  {/* Alerts */}
                  {alertCount !== 0 ? (
                     <AlertsComponent
                        alertCount={alertCount}
                        alertItems={alertItems}
                        key={"alertComponent"}
                        setAlertCount={setAlertCount}
                     />
                  ) : null}
                  {/* <div className={classes.ActionButtonsConatiner}> */}
                  {/* Action Buttons */}
                  <Grid
                     container
                     flexDirection={"column"}
                     alignItems={"center"}
                     className={classes.ActionButtonsConatiner}
                  >
                     <ActionButton
                        iconSrc={addUserActionIcon}
                        onClick={() => {
                           setAddMemberModalVisible(!addMemberModalVisible);
                        }}
                     />
                  </Grid>

                  <AddPartnerModal
                     id={"add-partner-modal"}
                     isVisible={addMemberModalVisible}
                     openPosition={{
                        top: "0",
                        left: "0",
                        right: "0px",
                        bottom: "40vh",
                     }}
                     closedPosition={{
                        top: "0",
                        left: "0",
                        right: "-450px",
                        bottom: "40vh",
                     }}
                     setPanel={setAddMemberModalVisible}
                     setSnackbar={setSnackbarOpen}
                     setSnackbarStatus={setSnackbarStatus}
                     setSnackbarMessage={setSnackbarMessage}
                     refresh={false}
                  />
                  <ActionButton
                     iconSrc={addLockActionIcon}
                     onClick={() => {
                        setAddLockModalVisible(!addLockModalVisible);
                     }}
                  />

                  <AddLockPartnerModal
                     id={"add-lock-modal"}
                     isVisible={addLockModalVisible}
                     openPosition={{
                        top: "0",
                        left: "0",
                        right: "0px",
                        bottom: "25vh",
                     }}
                     closedPosition={{
                        top: "0",
                        left: "0",
                        right: "-450px",
                        bottom: "25vh",
                     }}
                     setPanel={setAddLockModalVisible}
                     setSnackbarType={setSnackbarType}
                     setSnackbar={setSnackbarOpen}
                     setSnackbarMessage={setSnackbarMessage}
                     refresh={false}
                  />

                  {/* <ActionButton iconSrc={addCameraActionIcon} onClick={() => { setAddCameraModalVisible(!addCameraModalVisible) }}/>
              <AddCameraModal
                id={'add-camera-modal'}
                isVisible={addCameraModalVisible}
                openPosition={{ top: '0', left: '0', right: '0px', bottom: '20vh' }}
                closedPosition={{ top: '0', left: '0', right: '-450px', bottom: '20vh' }}
              /> */}
                  {/* </div> */}
               </section>
            </section>
         </AppBody>
      </>
   );
};
const useStyles = makeStyles({ name: "DashboardStyles" })((theme) => ({
   DashboardOuterLayout: {
      display: "flex",
      alignItems: "flex-start",
      //border: '1px dashed orange',
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
   },
   ItemOneContainer: {
      //border: '1px dashed red',
      width: "80%",
   },
   ItemTwoContainer: {
      marginLeft: "auto",
   },
   DashboardTitle: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "38px",
      letterSpacing: ".25px",
      fontWeight: "bold",
      margin: 0,
      marginBottom: "20px",
   },
   DashboardBlockedSection: {
      backgroundColor: "white",
      padding: "5px",
      marginTop: "30px",
      marginBottom: "30px",
      borderRadius: "15px",
      minHeight: "325px",
      maxHeight: "360px",
      width: "100%",
      maxWidth: "1200px",
      minWidth: "300px",
      boxShadow: "1px 2px 10px 3px rgba(169, 169, 169, .5)",
   },
   InnerItemContainer: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      paddingLeft: "20px",
   },
   InnerItemSide: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
   },
   InnerItemSideMainHeader: {
      margin: "5px",
      fontFamily: theme.fonts.secondary.light,
      marginLeft: "55px",
   },
   InnerItemsSideSubHeader: {
      fontSize: "15px",
   },
   CalendarContainer: {
      overflow: "auto",
      display: "flex",
      alignItems: "center",
      transform: "scale(0.9)",
      position: "relative",
      bottom: "15px",
      left: "35px",
      padding: "5px",
      maxHeight: "335px",
      overflowY: "hidden",
   },
   ItemTwoInnerCointainer: {
      paddingLeft: "20px",
      width: "100%",
   },
   ActionButtonsConatiner: {
      marginTop: "72px",
      marginRight: "7px",
      display: "flex",
      flexDirection: "column",
      justifyItems: "space-evenly",
   },
}));
