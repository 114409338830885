import React from "react";
import { useUserState } from "../../contexts/user/user.context";
import { LocksMain } from "./non-lender/locks.main";
import { LenderLocksMain } from "./lender/lender.locks.main";
import { LocksMainAdminNonLender } from "./enterprise/non-lender/locks.admin.non-lender.main";

export const LocksViewMainSwitch: React.FC = () => {
   const { user } = useUserState();

   switch (user?.accountType) {
      //// Enterprise Primary Manager
      case 5:
         if (user?.isEnterpriseSuperUser === 1) {
            return <LocksMainAdminNonLender />;
         }
         return <LocksMain />;
      //// Enterprise Lender
      case 6:
         if (user?.isEnterpriseSuperUser === 1) {
            return <LenderLocksMain />;
         }
         return <LocksMain />;

      //// Non-Enterprise
      default:
         return <LocksMain />;
   }
};
