import react from "react";
import { useEffect, useState, useRef } from "react";
import AppBody from "../../components/body/main.body.component.jsx";
import { AlertColor, Divider, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";
import {
   useUserDispatch,
   useUserState,
   UserActions,
} from "../../contexts/user/user.context";
import { getUserProfile } from "../../contexts/api/api.functions";
import TokenSelect from "../../components/tokens/token.select";
import CreditCardForm from "../../components/common/boxlty.cardInput";
import TokensHistoryList from "../../components/tokens/token.historyList";
import { generateUUID } from "../../utils/genericUtils";
import StripeCardInput from "../../components/stripe/StripeCardInput";
import RoundedButton from "../../components/common/boxlty.button";
import { Alert, Snackbar } from "@mui/material";
import AddCouponModal from "../../components/tokens/addcoupon.component";

interface TokenHistory {
   id: string;
   text: string;
   positive: boolean;
   tokenAmount: number;
}

type CouponType = {
   type: string;
   amount: number;
   code: string;
};

function TokensPageMain() {
   const { classes } = useStyles();
   const theme = useTheme();
   const [userTokens, setUserTokens] = useState<any>("00");
   const [tokenHistory, setTokenHistory] = useState<TokenHistory[]>([]);
   const [total, setTotal] = useState<number>(0.0);
   const [totalWithDiscounts, setTotalWithDiscounts] = useState<number>(0.0);
   const [discountAmount, setDiscountAmount] = useState<number>(0);
   const [coupon, setCoupon] = useState<CouponType>({
      type: "",
      amount: 0,
      code: "",
   });
   const [clientSecret, setClientSecret] = useState<any>(null);
   const totalChanged = useRef<boolean>(false);
   const [responseMessage, setResponseMessage] = useState("");
   const { user, accessToken } = useUserState();
   const [addCouponModalVisible, setAddCouponModalVisible] = useState(false);
   const [snackBarOpen, setSnackBarOpen] = useState(false);
   const [snackBarMessage, setSnackBarMessage] = useState("");
   const [snackBarType, setSnackBarType] = useState<AlertColor>("success");

   const ENV = process.env.REACT_APP_ENV;

   useEffect(() => {
      //on success POST add tokens to account
      try {
         setTimeout(() => {
            GetTokenCount();
            GetTokenHistory();
         }, 100);
      } catch (e) {}

      if (clientSecret == null || totalChanged.current === true) {
         //get payment intent, on change -> get new payment intent for new token amount
         let request = new XMLHttpRequest();
         request.open(
            "POST",
            ENV === "production"
               ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/purchase-token-intent`
               : ENV === "staging"
               ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/purchase-token-intent`
               : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/purchase-token-intent`
         );
         const token =
            (sessionStorage.getItem("access_token")
               ? sessionStorage.getItem("access_token")
               : localStorage.getItem("access_token")) ?? "";
         const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
         request.setRequestHeader("Content-Type", "application/json");
         request.setRequestHeader("access_token", token);
         request.setRequestHeader("is_website", "true");
         if (clientSecret != null)
            request.setRequestHeader("client_secret", clientSecret);
         //this is the minimum amount I can do by default to display the ui
         if (total < 1) return;
         if (coupon.code == "") {
            //console.log("no coupon");
            request.send(
               JSON.stringify({
                  user_id: user?.userId,
                  amount: total.toFixed(2).toString(),
                  quantity: total / 5,
               })
            );
         } else {
            //console.log("coupon sent");
            request.send(
               JSON.stringify({
                  user_id: user?.userId,
                  amount: total.toFixed(2).toString(),
                  quantity: total / 5,
                  coupon: coupon.code,
               })
            );
         }
         request.onload = () => {
            let response = JSON.parse(request.response);
            if (response.success) {
               setClientSecret(response.payment_intent.client_secret);
            } else {
               // console.log("Get Payment Intent Response: ", response);
            }
            //reset to false
            totalChanged.current = false;
         };
      }
   }, [total, coupon, clientSecret, userTokens]);

   function onAmountChange(total: number, discountPercent: number) {
      setTotal(total);
      setDiscountAmount(discountPercent);
      var modifiedTotal = total * (1 - discountPercent / 100);
      if (coupon.type == "Fixed") {
         modifiedTotal = modifiedTotal - coupon.amount;
      } else if (coupon.type == "Percent") {
         modifiedTotal = modifiedTotal * (1 - coupon.amount / 100);
      }
      if (modifiedTotal < 0) {
         modifiedTotal = 0;
      }
      setTotalWithDiscounts(modifiedTotal);
      totalChanged.current = true;
   }

   function onCouponApplied(type: string, amount: number, code: string) {
      //console.log(type, amount);
      setCoupon({ type, amount, code });
      var modifiedTotal = total * (1 - discountAmount / 100);
      if (type == "Fixed") {
         modifiedTotal = modifiedTotal - amount;
      } else if (type == "Percent") {
         modifiedTotal = modifiedTotal * (1 - amount / 100);
      }
      if (modifiedTotal < 0) {
         modifiedTotal = 0;
      }
      setTotalWithDiscounts(modifiedTotal);
      totalChanged.current = true;
   }

   const GetTokenCount = async () => {
      try {
         // console.log("USER COUNT: ", user?.userId.toString());
         const response = await getUserProfile({
            userId: user?.userId.toString(),
         });
         const userProfileData = response.data.result;
         // console.log(userProfileData);
         // Update the state with the retrieved user profile data
         setUserTokens(userProfileData.visitCount);
         // console.log(userData.dateOfBirth);
      } catch (error) {
         // Handle API request error here
         console.error("Error fetching user profile:", error);
      }
   };

   function GetTokenHistory() {
      let request = new XMLHttpRequest();
      request.open(
         "GET",
         ENV === "production"
            ? `${process.env.REACT_APP_DESKTOP_PROD}/v2/setting/token-history`
            : ENV === "staging"
            ? `${process.env.REACT_APP_DESKTOP_STAGING}/v2/setting/token-history`
            : `${process.env.REACT_APP_DESKTOP_DEV}/v2/setting/token-history`
      );
      const token =
         (sessionStorage.getItem("access_token")
            ? sessionStorage.getItem("access_token")
            : localStorage.getItem("access_token")) ?? "";
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("access_token", token);
      request.setRequestHeader("is_website", "true");
      if (clientSecret != null)
         request.setRequestHeader("client_secret", clientSecret);
      request.send();
      request.onload = () => {
         let response = JSON.parse(request.response);
         // console.log("Get Token history Response: ", response);
         if (response.success) {
            let tokenHistory: TokenHistory[] = [];
            response.token_history.forEach((t: any) => {
               let history: TokenHistory = {
                  id: t.id,
                  positive: t.amount > 0 ? true : false,
                  text: t.description,
                  tokenAmount: t.quantity,
               };
               tokenHistory.push(history);
            });
            setTokenHistory(tokenHistory);
         } else {
         }
      };
   }

   return (
      <>
         <AppBody>
            <Snackbar
               open={snackBarOpen}
               autoHideDuration={4000}
               sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
               onClose={() => {
                  setSnackBarOpen(false);
               }}
               anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
               <Alert
                  onClose={() => {
                     setSnackBarOpen(false);
                  }}
                  severity={snackBarType}
                  color={snackBarType}
               >
                  {snackBarMessage}
               </Alert>
            </Snackbar>
            {/* Tokens Page Container */}

            <section>
               {/* Page Header */}
               <h1 className={classes.TokensPageHeader}>Tokens</h1>
               {/* Split Container */}
               <section className={classes.SplitContainer}>
                  {/* Main section */}
                  <section className={classes.MainSection}>
                     {/* You have x tokens Header */}
                     <h1 className={classes.TokenCountHeader}>
                        You have {userTokens} tokens
                     </h1>
                     {/* Tokens Select component */}
                     <TokenSelect onTotalChanged={onAmountChange} />

                     {/* Checkout Component */}
                     <section className={classes.CreditCardInputSection}>
                        {/* Total Section */}
                        <div>
                           <p className={classes.TokenTotalPreText}>TOTAL: </p>
                           <div className={classes.TokenCostContainer}>
                              <h2 className={classes.TokenTotalHeader}>
                                 ${totalWithDiscounts.toFixed(2)}
                              </h2>
                              {discountAmount > 0 && (
                                 <h2 className={classes.TokenDiscountHeader}>
                                    SAVE ${discountAmount}%
                                 </h2>
                              )}
                           </div>
                           {coupon.type != "" && (
                              <div className={classes.ActiveCouponText}>
                                 {coupon.type == "Fixed"
                                    ? `$${coupon.amount} OFF COUPON`
                                    : `${coupon.amount}% OFF COUPON`}
                              </div>
                           )}
                           <div
                              className={classes.ApplyCouponText}
                              onClick={() => {
                                 if (coupon.type == "") {
                                    setAddCouponModalVisible(
                                       !addCouponModalVisible
                                    );
                                 } else {
                                    onCouponApplied("", 0, "");
                                 }
                              }}
                           >
                              {coupon.type == ""
                                 ? "Apply Coupon Code"
                                 : "Remove Coupon"}
                           </div>

                           <AddCouponModal
                              id={"add-coupon-modal"}
                              isVisible={addCouponModalVisible}
                              openPosition={{
                                 top: "0",
                                 left: "0",
                                 right: "0px",
                                 bottom: "50vh",
                              }}
                              closedPosition={{
                                 top: "0",
                                 left: "0",
                                 right: "-450px",
                                 bottom: "50vh",
                              }}
                              setPanel={setAddCouponModalVisible}
                              setSnackbar={setSnackBarOpen}
                              setSnackbarMessage={setSnackBarMessage}
                              setSnackbarType={setSnackBarType}
                              onCouponApplied={onCouponApplied}
                              refresh={false}
                           />
                        </div>

                        <Divider />
                        {/* Card Information Header */}
                        <div>
                           {clientSecret ? (
                              <>
                                 <h3>Card Information</h3>
                                 <StripeCardInput
                                    onShowSnackBar={setSnackBarOpen}
                                    onSetErrorMessage={setResponseMessage}
                                    clientSecret={clientSecret}
                                 />
                              </>
                           ) : (
                              <h1></h1>
                           )}
                        </div>
                     </section>
                  </section>

                  {/* SecondarySection */}
                  <section className={classes.SecondarySection}>
                     <h1 className={classes.TokenHistoryHeader}>
                        Token History
                     </h1>
                     <TokensHistoryList data={tokenHistory} />
                  </section>
               </section>
            </section>
         </AppBody>
      </>
   );
}

const useStyles = makeStyles({ name: "TokensPage" })((theme) => ({
   TokensPageConatiner: {
      overflowY: "auto",
   },
   TokensPageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "40px",
      letterSpacing: "1px",
      fontWeight: "bold",
      margin: 0,
      marginBottom: "20px",
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
   },
   MainSection: {
      minHeight: "80vh",
      width: "85%",
      borderRadius: "20px",
      padding: "20px",
      paddingLeft: "35px",
      backgroundColor: "white",
      boxShadow: `0px 0px 25px -10px ${theme.palette.common.darkGray}`,
      marginRight: "10px",
      maxHeight: "80vh",
      overflowX: "hidden",
      overflowY: "auto",
   },
   SecondarySection: {
      minHeight: "80vh",
      width: "35%",
      borderRadius: "20px",
      padding: "20px",
      paddingLeft: "35px",
      backgroundColor: "white",
      boxShadow: `0px 0px 25px -10px ${theme.palette.common.darkGray}`,
      marginLeft: "auto",
   },
   TokenCountHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "30px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
      margin: 0,
      marginBottom: "10px",
   },
   TokenTotalPreText: {
      fontSize: "11px",
      color: "darkgray",
      fontweight: "bold",
      margin: "0",
   },
   TokenCostContainer: {
      display: "flex",
      alignItems: " center",
      flexDirection: "row",
   },
   TokenTotalHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      color: theme.palette.common.primary,
      fontSize: "35px",
      margin: "0",
      marginRight: "15px",
   },
   ApplyCouponText: {
      color: theme.palette.common.primary,
      fontSize: "12px",
      margin: "0",
      marginTop: "5px",
      marginBottom: "5px",
      textDecoration: "underline",
      cursor: "pointer",
   },
   ActiveCouponText: {
      color: theme.palette.common.black,
      fontSize: "12px",
      margin: "0",
      marginTop: "5px",
      marginBottom: "5px",
   },
   TokenDiscountHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      color: theme.palette.common.black,
      fontSize: "15px",
      margin: "0",
      textAlign: "center",
   },
   CreditCardInputSection: {
      width: "80%",
   },
   TokenHistoryHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "30px",
      margin: "0",
      marginBottom: "10px",
   },
   TokenHistoryList: {
      listStyle: "none",
      padding: "0",
      margin: " 0",
      "& li": {
         marginBottom: "5px",
         fontFamily: theme.fonts.secondary.med,
         fontSize: "12px",
      },
   },
   CreditCardSubmitContainer: {
      marginTop: "20px",
   },
}));

export default TokensPageMain;
