import react, { ReactElement, useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Alert, useTheme } from "@mui/material";
import carrotSvg from "./../../styles/assets/Icons/carrot.svg";
// import { getNotifications } from '../../contexts/api/api.functions';
import {
  useUserDispatch,
  useUserState,
  UserActions,
} from "../../contexts/user/user.context";
import { fadeIn, fadeOut, generateUUID } from "../../utils/genericUtils";
import { cron } from "../../utils/genericUtils";
import { min } from "lodash";
import AlertsPopupComponent from "./AlertsPopupComponent";
import { AlertResponse, readNotification } from "../../contexts/api/api.types";
import { updateUserAlertRead } from "../../contexts/api/api.functions";
import { AlertTypes } from "./AlertsComponent";

const minHeight = "35px";
let maxHeight = "600px";
const transitionTime = "0.3s";

interface alert {
  id: string;
  profilePictureUrl: string;
  firstName: string;
  lastName: string;
  date: Date;
  startTime: Date;
  endTime: Date;
}

interface alertItemProps {
  alertItem: AlertResponse;
  setAlertCount: React.Dispatch<React.SetStateAction<number>>;
  alertCount: number;
  alerts: AlertResponse[];
}

function AlertTextItem(props: alertItemProps) {
  const { classes } = useStyles();
  const [unread, setUnread] = useState(true);
  const [activeStatus, setActiveStatus] = useState(false);
  //   const theme = useTheme();

  useEffect(() => {}, [activeStatus]);

  // useEffect(() => {}, []);

  //   function formatPopupElements(): ReactElement[] {
  //     let elements: ReactElement[] = [];
  //     if (alerts.length == 0) {
  //       //default alerts to a sample
  //       let sample1: alert = {
  //         id: "alert-" + generateUUID(),
  //         profilePictureUrl: "https://thispersondoesnotexist.com/",
  //         firstName: "John",
  //         lastName: "Doe",
  //         date: new Date(),
  //         startTime: new Date(),
  //         endTime: new Date(),
  //       };

  //       let sample2: alert = {
  //         id: "alert-" + generateUUID(),
  //         profilePictureUrl: "https://thispersondoesnotexist.com/",
  //         firstName: "Mary",
  //         lastName: "Doe",
  //         date: new Date(),
  //         startTime: new Date(),
  //         endTime: new Date(),
  //       };

  //       let sampleAlerts: alert[] = [sample1, sample2];
  //       setAlerts(sampleAlerts);
  //       printAlerts();

  //       let index: number = 0;
  //       sampleAlerts.forEach((alert) => {
  //         elements.push(
  //           <AlertsPopupComponent
  //             id={alert.id}
  //             alertIndex={index}
  //             profilePictureUrl={alert.profilePictureUrl}
  //             firstName={alert.firstName}
  //             lastName={alert.lastName}
  //             date={alert.date}
  //             startTime={alert.startTime}
  //             endTime={alert.endTime}
  //           />
  //         );
  //         index++;
  //       });
  //     } else {
  //       let index: number = 0;
  //       alerts.forEach((alert) => {
  //         elements.push(
  //           <AlertsPopupComponent
  //             id={alert.id}
  //             alertIndex={index}
  //             profilePictureUrl={alert.profilePictureUrl}
  //             firstName={alert.firstName}
  //             lastName={alert.lastName}
  //             date={alert.date}
  //             startTime={alert.startTime}
  //             endTime={alert.endTime}
  //           />
  //         );
  //         index++;
  //       });
  //     }
  //     return elements;
  //   }

  //   function hideAllAlertPopups() {
  //     alerts.forEach((alert) => {
  //       let id = "#" + alert.id;
  //       let alertPopup = document.querySelector(id) as HTMLElement;
  //       if (alertPopup) alertPopup.style.display = "none";
  //     });
  //   }

  function showAlertPopup(alertIndex: Number) {
    try {
      if (props.alerts == null) return;
      props.alerts.forEach((alert) => {
        if(Object.values(AlertTypes).includes(alert.notificationTypeId))
        {
          let id = "#alert-" + alert.notificationId;
          let alertPopup = document.querySelector(id) as HTMLElement;
          if (alert.notificationId === alertIndex) {
            alertPopup.style.display = "flex";

            alertPopup.style.top = '100px';
            setActiveStatus(true);
          } 
          else alertPopup.style.display = "none";
        }
      });
    } catch (error) {
      // Handle the error here
      // console.log(error);
    }
  }

  function setInactive() {
    const elements = document.querySelectorAll(".alert-textItem");

    elements.forEach((element) => {
      element.classList.remove("css-1nnjsl-AlertsComponent-activeTag");
    });
    setActiveStatus(true);
  }
  // function setActiveAlertID(alertIndex: Number) {
  //   try{
  //   if (props.alerts == null) return;
  //   props.alerts.forEach((alert) => {
  //     let id = "#alert-" + alert.notificationId;
  //     if(alert.notificationId === alertIndex) {
  //       setActiveStatus(id)
  //     }
  //   });}
  //   catch (error){
  //     // Handle the error here
  //     console.log(error);
  //   }
  // }

  async function readAlert(id: number) {
    const body: readNotification = {
      id: id,
      actionType: 0,
    };
    let res = await updateUserAlertRead(body);
    if (res && res.data && res.data.status) {
      props.setAlertCount(props.alertCount - 1);
      setUnread(false);
    }
  }

  return (
    <>
      {/* Display list of alerts here */}
      <div
        className={`${classes.alertTag} alert-textItem ${
          activeStatus ? classes.activeTag : ""
        }`}
      >
        <p
          className={classes.alertTagText}
          onClick={() => {
            setInactive();
            showAlertPopup(props.alertItem.notificationId);
            //make specific alert active porbably using some sort of alertID
            //setActiveAlertID(props.alertItem.notificationId)

            // if (unread) {
            //   readAlert(props.alertItem.notificationId);
            // }
          }}
        >
          {props.alertItem.notificationTitle}
        </p>
      </div>
    </>
  );
}

const alertRed = "#EC394B";

const useStyles = makeStyles({ name: "AlertsComponent" })((theme) => ({
  TransparentLayer: {
    display: "none",
    backgroundColor: "rgba(0,0,0,0.8)",
    position: "fixed",
    opacity: "0.5",
    top: 0,
    left: 0,
    zIndex: "20",
    minHeight: "100vh",
    minWidth: "100vw",
    transition: `opacity ${transitionTime} linear`,
  },
  AlertPopupsContainer: {
    display: "none",
    transition: `opacity ${transitionTime} linear`,
    opacity: 0,
  },
  ExpandShelf: {
    backgroundColor: "transparent",
    borderRadius: "15px",
    width: "170px",
    //border: '0.1px solid lightgray',
    position: "relative",
    zIndex: "1",
    bottom: "30px",
    paddingTop: "50px",
    paddingBottom: "5px",
  },
  AlertsComponentContainer: {
    padding: "10px",
    width: "150px",
    height: "15px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: alertRed,
    position: "relative",
    zIndex: "2",
  },
  AlertcountCircle: {
    backgroundColor: "white",
    padding: "5px",
    color: alertRed,
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "100%",
    height: "10px",
    width: "10px",
    boxShadow: "0px 0px 5px -1px black",
  },
  AlertsText: {
    color: "white",
    fontWeight: "bold",
    fontSize: "13px",
    fontFamily: theme.fonts.secondary.bold,
    letterSpacing: "1px",
  },
  Carrot: {
    color: "white",
    height: "10px",
    width: "10px",
    cursor: "pointer",
    transition: `all ${transitionTime} linear`,
  },
  alertTag: {
    display: "flex",
    cursor: "pointer",
    // height: "20px",
    color: theme.palette.common.medGray,
    "&:hover": {
      backgroundColor: "lightgray", //theme.palette.common.lightGray,
      color: theme.palette.common.primary,
    },
    "&:active": {
      backgroundColor: "lightgray", //theme.palette.common.lightGray,
      color: theme.palette.common.primary,
    },
    width: "90%",
    margin: "auto",
    marginBottom: "none",
    whiteSpace: "normal" /* Prevent text from wrapping to the next line */,
    // overflow: "hidden" /* Hide any overflowing content */,
    // textOverflow:
    //   "ellipsis" /* Display an ellipsis (...) for overflowed text */,
  },
  alertTagText: {
    fontFamily: theme.fonts.secondary.light,
    fontSize: "13px",
    margin: "2px",
    textAlign: "left",
    width: "100%",
    marginRight: "10px",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    padding: "4px",
  },
  activeTag: {
    backgroundColor: "lightgray", //theme.palette.common.lightGray,
    color: theme.palette.common.primary,
  },
}));

export default AlertTextItem;
