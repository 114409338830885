import React, { useContext, useEffect, Dispatch } from "react";
import { IAction } from "../common/storebase.types";
import { ITeamState } from "./team.context.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useUserState } from "../user/user.context";
import { getTeamMembers, getTeamMembersAdmin } from "../api/api.functions";
import { getStoredItem } from "../common/localstorage";
import { StorageKeys } from "../common/storeapp.types";

export enum TeamActions {
   setTeamMembers = "setTeamMembers",
   setError = "setError",
   setLoading = "setLoading",
   setTeamDetailsId = "setTeamDetailsId",
}

const initialState = {
   teamMembers: [],
   loading: false,
   error: "",
   teamDetailsId: null,
};

const reducer = (state: ITeamState, action: IAction): ITeamState => {
   const newState = { ...state };
   switch (action.type) {
      case TeamActions.setTeamMembers:
         newState.teamMembers = action.payload;
         break;
      case TeamActions.setError:
         newState.error = action.payload;
         break;
      case TeamActions.setLoading:
         newState.loading = action.payload;
         break;
      case TeamActions.setTeamDetailsId:
         newState.teamDetailsId = action.payload;
         break;
      default:
         return state;
   }

   return newState;
};

export const TeamStateContext: any = React.createContext({});
export const TeamDispatchContext: any = React.createContext({});
export const TeamStateRefContext: any = React.createContext({});

export const useTeamState = (): ITeamState => useContext(TeamStateContext);
export const useTeamDispatch = (): Dispatch<IAction> =>
   useContext(TeamDispatchContext);
export const useTeamStateRef = (): React.MutableRefObject<ITeamState> =>
   useContext(TeamStateRefContext);

const middleware = [logger("User")];

export const TeamProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch, teamStateRef] = useEnhancedReducer(
      reducer,
      initialState,
      (state) => ({ ...state }),
      middleware
   );
   const { user } = useUserState();

   const getTeamAdmin = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         dispatch({
            type: TeamActions.setLoading,
            payload: true,
         });
         const res = await getTeamMembersAdmin();

         dispatch({
            type: TeamActions.setTeamMembers,
            payload: res.data.result.members,
         });
         dispatch({
            type: TeamActions.setLoading,
            payload: false,
         });
      }
   };

   const getTeam = async () => {
      if (getStoredItem(StorageKeys.access_token)) {
         dispatch({
            type: TeamActions.setLoading,
            payload: true,
         });
         const res = await getTeamMembers();
         //console.log(res);
         dispatch({
            type: TeamActions.setTeamMembers,
            payload: res.data.result.members,
         });
         dispatch({
            type: TeamActions.setLoading,
            payload: false,
         });
      }
   };

   useEffect(() => {
      if (
         user &&
         user?.accountType === 5 &&
         user?.isEnterpriseSuperUser === 1
      ) {
         getTeamAdmin();
      } else if (
         user &&
         user?.accountType === 6 &&
         user?.isEnterpriseSuperUser == "1"
      ) {
         getTeamAdmin();
      } else if (user) {
         getTeam();
      }
   }, [user, user?.userId]);

   return (
      <TeamStateContext.Provider value={state}>
         <TeamDispatchContext.Provider value={dispatch}>
            <TeamStateRefContext.Provider value={teamStateRef}>
               {children}
            </TeamStateRefContext.Provider>
         </TeamDispatchContext.Provider>
      </TeamStateContext.Provider>
   );
};
